<template>
    <div :style="$grid('200px-300px-1')">

        <span>
            Relationship To Customer?
        </span>
        
        <InputRichDropdown
            v-model:saturn="localCustomerInfo.relationship"
            :list="filteredCustomerRelationships"
            :valueMap="(x: ToListItems<CustomersRelationship>) => x.value"
            :display="(x: ToListItems<CustomersRelationship>) => x.display"
            label="Customers Relationship"
            @change="handleChange"
            nothingSelected="Select relationship"
            :invalid="
                !!validation?.customersInfo.relationship?.$silentErrors?.length
            "
        >
            <template
                #validation
                v-if="
                    !!validation?.customersInfo.relationship?.$silentErrors
                        ?.length
                "
            >
                {{
                    validation?.customersInfo.relationship.$silentErrors[0]
                        ?.$message
                }}
            </template>
        </InputRichDropdown>

        <InputTextbox
            v-if="localCustomerInfo.relationship == CustomersRelationship.Other"
            label="Relationship Description"
            v-model:saturn="localCustomerInfo.relationshipDescription"
            placeholder=""
            :invalid="
                !!validation?.customersInfo.relationshipDescription
                    ?.$silentErrors?.length
            "
        >
            <template
                #validation
                v-if="
                    !!validation?.customersInfo.relationshipDescription
                        ?.$silentErrors?.length
                "
            >
                {{
                    validation?.customersInfo.relationshipDescription
                        .$silentErrors[0].$message
                }}
            </template>
        </InputTextbox>
    </div>
</template>
<script setup lang="ts">
// Classes & Interfaces
import { CustomersRelationship } from "@core/classes/SharedEnums";
import { FIMenuCustomersInfo } from "@core/classes/FIMenuCustomersInfo";

// Helpers
import { computed, ref, watch } from "vue";
import CustomersRelationshipHelper from "@core/enums/CustomerRelationshipEnumsHelper";
import { ToListItems } from "@core/helpers/enum-helper";

// Components
import InputRichDropdown from "@core/components/InputRichDropdown.vue";
import InputTextbox from "@core/components/InputTextbox.vue";

export interface ICustomerRelationshipCRUDProps {
    customerInfo: FIMenuCustomersInfo;
    validation: any;
    areAnyOfTheCustomersABusiness: boolean;
}

const props = defineProps<ICustomerRelationshipCRUDProps>();
const emit = defineEmits<{
    (event: "update:customerInfo", value: FIMenuCustomersInfo): void;
}>();

// Create a local copy of the prop
const localCustomerInfo = ref({ ...props.customerInfo });

// Compute filtered list based on isBusiness flag
const filteredCustomerRelationships = computed(() => {
    return CustomersRelationshipHelper.getFilteredList(props.areAnyOfTheCustomersABusiness);
});

const handleChange = (newStatus: CustomersRelationship) => {
    if (newStatus != CustomersRelationship.Other)
        localCustomerInfo.value.relationshipDescription = null;
};

// Watch for changes in the local data and emit updates to the parent
watch(
    () => localCustomerInfo.value,
    (newValue) => {
        emit("update:customerInfo", newValue);
    },
    { deep: true }
);
</script>
<style scoped></style>
