<template>
    <div class="terms-and-conditions-container">
        <div class="modal-container">
            <header>
                <h2>Terms and Conditions</h2>
                <button @click="closeTermsAndConditionsModal"><i class="fas fa-times"></i></button>
            </header>
            <p>
                <span>{{ localization.legalDisclosure[fimenu.language] }} </span>
                <span v-if="fimenu.dealType == 'Finance' && fimenu.loanTerms.term1.term && fimenu.loanTerms.term1.sellrate">{{ localization.termBasedFront[fimenu.language] + fimenu.loanTerms.term1.term + localization.termBasedMiddle[fimenu.language] + fimenu.loanTerms.term1.sellrate }}%. </span>
                <span v-if="fimenu.dealType == 'Finance' && fimenu.loanTerms.term2.term && fimenu.loanTerms.term2.sellrate">{{ localization.termBasedFront[fimenu.language] + fimenu.loanTerms.term2.term + localization.termBasedMiddle[fimenu.language] + fimenu.loanTerms.term2.sellrate }}%. </span>
                <span v-if="fimenu.dealType == 'Finance' && fimenu.loanTerms.term3.term && fimenu.loanTerms.term3.sellrate">{{ localization.termBasedFront[fimenu.language] + fimenu.loanTerms.term3.term + localization.termBasedMiddle[fimenu.language] + fimenu.loanTerms.term3.sellrate }}%. </span>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TermsAndConditionsModal",
        props: ['localization', 'fimenu', 'closeTermsAndConditionsModal'],
    }
</script>

