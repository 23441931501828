<template>
    <div :class="{'card-wrapper': true, 'hover-react': onHoverReact}" @click="emitClickEvent">
        <slot />
    </div>
</template>
<script lang="ts">
    import { defineComponent } from 'vue'
    export default defineComponent({
        name: 'Card',
        emits: ['click'],
        props: {
            onHoverReact: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                cardImageError: false,
             };
        },
        computed: {

        },
        methods: {
            emitClickEvent(e: Event) {
                this.$emit('click', e)
            }
        }
    });
</script>
<style scoped>
    .card-wrapper {
        min-width: 150px;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
        border: 1px solid var(--border-color);
        border-radius: var(--default-border-radius);
    }
    .card-wrapper.hover-react:hover {
        background-color: var(--black-10percent);
    }


</style>