<template>
    <div class="modal-container modal-info modal-control-number">
        <div class="modal-header">
            <span>Temp Tag. Control Number</span>
        </div>
        <div class="modal-body">
            <div :style="$grid('1-1')">
                <InputTextbox label="Control Number"
                              v-model:saturn="controlNumber"
                              placeholder="Control Number">
                </InputTextbox>
            </div>
        </div>
        <div class="modal-footer">
            <button class="button-unwind" @click="cancel()">{{ modal.passedData.cancelText }}</button>
            <button class="button-save" @click="close()">{{ modal.passedData.acceptText }}</button>
        </div>
    </div>
</template>

<script>
    import $modal from '@core/services/modal'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import util from '@core/services/util'

    export default {
        name: "modalSetControlNumber",
        props: ['modal'],
        data() {
            return {
                controlNumber: null,
                plateTransactions: this.modal.passedData.plateTransactions
            }
        },
        computed: {
            util() {
                return util
            },

        },
        methods: {
            close() {
                if (this.plateTransactions.some(deal => deal.controlNumber == this.controlNumber)) return util.toastr("error", "Invalid Control Number", "This control number has already been used.");

                $modal.close(this.controlNumber);
            },
            cancel() {
                $modal.cancel();
            }
        },
        components: {
            InputTextbox,
        }
    };
</script>
<style>
    .modal-container.modal-info.modal-control-number{
        width: fit-content;
    }
</style>
