<template>
    <div class="fimenupaperworkpage">
        <PopupSelector v-if="signer == null" :data="signerOptions" :onSelect="selectSigner"
            :title="'Who is signing?'" />
        <PanelESign v-if="showSignaturePad && windowWidth > 1200"
                    :name="personType == 1 ? fimenu.customer.fullName : personType == 2 ? fimenu.coCustomer.fullName : '\u00A0'"
                    :cancelHandler="cancelSignature"
                    :submitHandler="submitSignature" />
        <ESignMobile v-if="showSignaturePad && windowWidth <= 1200"
            :name="personType == 1 ? fimenu.customer.fullName : personType == 2 ? fimenu.coCustomer.fullName : '\u00A0'"
            :cancelHandler="cancelSignature"
            :submitHandler="submitSignature" />
        <div id="scaleContainer">
            <div class="documents-navigation">
                <div class="documents-nav-prev">
                    <button type="button" class="button button-simple" @click="handlePrevious"
                        :disabled="!isPrevAllowed()">
                        <span class="icon-pad-right"><i class="fas fa-chevron-circle-left"></i></span>
                        <span>Previous</span>
                    </button>

                </div>
                <div class="documents-nav-middle">
                    <button type="button" class="button button-simple button-width-auto" @click="clearAndRestart">
                        <span class="icon-pad-right"><i class="fas fa-times-circle"></i></span>
                        <span>Clear & Restart</span>
                    </button>
                </div>
                <div class="documents-nav-next">
                    <button type="button" class="button button-simple"
                        @click="loadDocument(nextAvailableDocForCurrentSigner())" :disabled="!isNextAllowed()">
                        <span>Next</span>
                        <span class="icon-pad-left"><i class="fas fa-chevron-circle-right"></i></span>
                    </button>
                </div>
            </div>
            <div class="document-container" ref="documentContainer" :style="{margin: 'auto'}">
                <div class="pdf-loading-container" v-show="isBusy">
                    <div class="pdf-loading-wrapper">
                        <div class="pdf-loading-body">
                            <div><i class="fas fa-cog fa-spin"></i></div>
                            <p>Loading Document</p>
                        </div>
                    </div>
                </div>

                <div v-if="pdfDoc && pdfDoc.docToSign && signer != null">
                    <PDFViewer ref="pdfViewer" :src="pdfDoc.docToSign" :overlays="pdfDoc.overlays" headers="headers"
                        @rendered="rendered" :fimenu="fimenu" :signer="signer"></PDFViewer>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import PDFViewer from '@core/components/PDFViewer.vue'
    import $modal from '@core/services/modal'
    import modalSignatureAgreement from '@core/modals/modalSignatureAgreement.vue'
    import modalInfo from '@core/modals/modalInfo.vue'
    import util from '@core/services/util'
    import settings from 'settings'
    import api from '@core/services/api'
    import ENUMS from "@core/classes/Enums"
    import PanelESign from '@core/components/PanelESign.vue'
    import ESignMobile from '@core/components/ESignMobile.vue'
    import PopupSelector from '@core/components/PopupSelector.vue'
    import moment from 'moment'
    import LogRocket from 'logrocket';

    export default {
        name: "FIMenuPaperwork",
        props: ['fimenu', 'sectionName', 'goToSection', 'getWindowRef'],
        data() {
            return {
                signatureWidth: 600,
                signatureHeight: 115,
                paperwork: null,
                packet: null,
                isBusy: false,
                pdfDoc: null,
                pdfDocTemp: null,
                pdfDocIndexTemp: null,
                pdfDocIndex: null,
                showSignaturePad: false,
                personType: null,
                postSignatureMethod: null,
                signatureData: {
                    _isEmpty: true
                },
                initialsData: {
                    _isEmpty: true
                },
                signer: null,
                signerOptions: [],
                usingSockets: this.$meetingHub.connected,
                windowWidth: window.innerWidth,
            };
        },
        computed: {
            signatureDisabled() {
                return this.signatureData._isEmpty || this.signaturePoints < 20
            },
            signatureButtonDisabled() {
                return this.signatureDisabled || this.initialsDisabled
            },
            initialsDisabled() {
                return this.initialsData._isEmpty || this.initialsPoints < 20
            },
            signaturePoints() {
                if (this.signatureData._data) {
                    let total = 0
                    this.signatureData._data.forEach(d => total += d.points.length)
                    return total;
                } else {
                    return 0
                }
            },
            initialsPoints() {
                if (this.initialsData._data) {
                    let total = 0
                    this.initialsData._data.forEach(d => total += d.points.length)
                    return total;
                } else {
                    return 0
                }
            },
            customerBrowserWidth() {
                return window.innerWidth;
            }
        },
        created() {
            if (this.usingSockets) {
                this.$meetingHub.saveCustomerViewed(4, moment(), null)

                this.MeetingHubBus.on('receiveDocument', (doc) => { // Deprecated.
                    this.pdfDocTemp.docToSign = doc
                    this.pdfDoc = this.pdfDocTemp;
                    this.pdfDocIndex = this.pdfDocIndexTemp;

                    console.log("Receviing a new doc ", this.pdfDoc, this.pdfDocIndex)
                })
            } else {
                //this.getWindowRef().alert("Please move the window before accepting or declining the terms.")
            }
        },
        mounted() {
            this.signer = this.fimenu.hasCoSigner ? null : ENUMS.PERSON_TYPES.CUSTOMER
            this.paperwork = this.fimenu.paperwork;
            this.packet = this.paperwork?.currentPacket();

            this.EventBus.on('showSignaturePad', this.onShowSignaturePad);
            this.EventBus.on('signatureReceived', this.onSignatureReceived);
            this.EventBus.on('signatureSigned', this.loadNextDocument);

            if (this.usingSockets) {
                this.$refs.documentContainer.addEventListener('scroll', (e) => {

                    this.$meetingHub.sendScrollEvent({
                        scrollCssSelector: '.document-container',
                        scrollTop: e.currentTarget.scrollTop
                    });

                });
            }

            if (!this.fimenu.hasCoSigner) {
                this.clearAndRestart();
            } else {
                this.getSigners()
            }
            window.addEventListener('resize', this.updateWindowWidth);
        },
        beforeUnmount(){
            this.EventBus.off('showSignaturePad', this.onShowSignaturePad);
            this.EventBus.off('signatureReceived', this.onSignatureReceived);
            this.EventBus.off('signatureSigned', this.loadNextDocument);
            window.removeEventListener('resize', this.updateWindowWidth);
        },
        updated() {
            if (this.usingSockets) {
                this.$nextTick(function () {
                    console.log('FIMENUPAPERWORK UPDATED!!');
                    this.EventBus.emit('sendHTMLToManager', {
                        pdfIdentifier: this.pdfDoc?.filledDoc
                    });
                })
            }
        },
        methods: {
            getSigners() {
                //Loading the available signers.
                this.signer = this.fimenu.hasCoSigner ? null : ENUMS.PERSON_TYPES.CUSTOMER
                this.signerOptions = []

                let CustomerType = {
                    [ENUMS.PERSON_TYPES.CUSTOMER]: {
                        name: 'customer',
                        signedEverything: false
                    },
                    [ENUMS.PERSON_TYPES.COCUSTOMER]: {
                        name: 'coCustomer',
                        signedEverything: false
                    },
                }

                Object.keys(CustomerType).map((customerEnum, index) => {
                    if (this.fimenu[CustomerType[customerEnum].name]) { //Check if the customer type exist (customer, cocustomer) in FIMENU. Ex. this.fimenu.customer

                        const customer = this.fimenu[CustomerType[customerEnum].name]
                        const nextUnsignedDocument = this.packet.documents.findIndex(d => !this.isDocSignedBySignerOrDone(d, customerEnum)); //If there's no available document to sign for this type of signer, it'll return -1.
                        const labels = [customer?.prefix, customer?.fullName || customer?.firstName, customer?.suffix].filter(Boolean); //Check for whatever labels we need and actually exist.
                        CustomerType[customerEnum].signedEverything = nextUnsignedDocument > -1 ? false : true

                        this.signerOptions.push({ // Pushes the signers to the table that will parse and show them up.
                            label: labels,
                            value: customerEnum,
                            description: (customerEnum == ENUMS.PERSON_TYPES.CUSTOMER && "Customer") || (customerEnum == ENUMS.PERSON_TYPES.COCUSTOMER && "Co Customer"),
                            disabled: CustomerType[customerEnum].signedEverything
                        });
                    }
                })

                if (Object.keys(CustomerType).some((customerEnum) => !CustomerType[customerEnum].signedEverything)) return

                if (this.usingSockets) {
                    this.$meetingHub.finishAllPaperwork('');
                    this.$router.push('/meetingroom/' + this.$route.params.code + '/stand-by?type=purchase');
                } else {
                    this.EventBus.emit('allPaperworkSigned');
                }

            },
            rendered(renderedPageData) {
                this.isBusy = false;
                if (this.usingSockets) {
                    this.$nextTick(function () {
                        console.log('FIMENUPAPERWORK RENDERED A PAGE!!');

                        this.EventBus.emit('sendHTMLToManager', {
                            getUpdatedHTML: (htmlContainer) => this.updateHTMLWithRenderedPageContainer(htmlContainer, renderedPageData),
                            pdfIdentifier: this.pdfDoc.filledDoc
                        });
                    })
                }

                //if (this.isBusy) {
                //    this.EventBus.emit('showSignaturePad', { postFunction: this.onSignaturePadSigned, personType: this.signer });
                //}
            },
            updateHTMLWithRenderedPageContainer(htmlContainer, renderedPageData) {
                // Remove all page-containers within the pdf-container div, except the most recently rendered
                console.log('updateHTMLWithRenderedPageContainer');
                if (this.pdfDoc && this.pdfDoc.docToSign && this.signer != null) {

                    //find in the html element, the CURRENT pdf page and remove the other
                    let pdfContainer = htmlContainer.querySelector('.pdf-container');

                    if (pdfContainer) {
                        [...pdfContainer.children].forEach(childEl => {
                            if (childEl.id != renderedPageData.index) {
                                pdfContainer.removeChild(childEl)
                            }
                        })
                    }
                }
                return htmlContainer;
            },
            getSectionIndicator() {
                return { icon: "fa-signature", class: "none" };
            },
            onShowSignaturePad(signaturePadData) {
                console.log("What is signaturePadData", signaturePadData)
                let openSignaturePad = false

                if ((signaturePadData.personType == ENUMS.PERSON_TYPES.CUSTOMER) || (signaturePadData.personType == ENUMS.PERSON_TYPES.COCUSTOMER)) {
                    openSignaturePad = true;
                }

                if (openSignaturePad) {
                    this.showSignaturePad = true;
                    this.personType = signaturePadData.personType;
                    if (signaturePadData.postFunction) this.postSignatureMethod = signaturePadData.postFunction
                    else {
                        this.postSignatureMethod = null;
                    }
                }

            },
            onSignatureReceived(signatureInfo) {
                console.log("SIGNATURE RECEIVED.", signatureInfo)
                let {personType, imageSrc, initialsSrc, skip} = signatureInfo
                if (personType == ENUMS.PERSON_TYPES.CUSTOMER) {
                    this.fimenu.customer.signature = imageSrc;
                    this.fimenu.customer.initials = initialsSrc;
                    if (this.usingSockets) this.$meetingHub.sendClientSignatureInitials('customer', imageSrc, initialsSrc)
                }
                else if (personType == ENUMS.PERSON_TYPES.COCUSTOMER) {
                    this.fimenu.coCustomer.signature = imageSrc;
                    this.fimenu.coCustomer.initials = initialsSrc;

                    if (this.usingSockets) this.$meetingHub.sendClientSignatureInitials('coCustomer', imageSrc, initialsSrc)
                }
                imageSrc = typeof imageSrc == 'string' ? "0" : null;
                initialsSrc = typeof initialsSrc == 'string' ? "0" : null;

                //Traverse all unisgned signatures of the same person and set the signatures imageSrc with the one just signed
                this.setSignatureImageForAllUnsignedSignaturesOfPersonType(personType, imageSrc, initialsSrc);
                //this.setSignatureImageForAllUnsignedSignaturesOfPersonType(signature.personType, signature.imageSrc, signature.initialsSrc);
                this.loadNextDocument(skip)
            },

            resetSignature(signature) {
                console.log("SIGNATURES RESETTED.")

                if (signature.personType == ENUMS.PERSON_TYPES.CUSTOMER) {
                    this.fimenu.customer.signature = null;
                    this.fimenu.customer.initials = null;
                    if (this.usingSockets) {
                        this.$meetingHub.sendClientSignatureInitials('customer', null, null)
                    }
                }
                else if (signature.personType == ENUMS.PERSON_TYPES.COCUSTOMER) {
                    this.fimenu.coCustomer.signature = null;
                    this.fimenu.coCustomer.initials = null;

                    if (this.usingSockets) this.$meetingHub.sendClientSignatureInitials('coCustomer', null, null)
                }
                signature.imageSrc = typeof signature.imageSrc == 'string' ? "0" : null;
                signature.initialsSrc = typeof signature.initialsSrc == 'string' ? "0" : null;

                //Traverse all unisgned signatures of the same person and set the signatures imageSrc with the one just signed
                this.setSignatureImageForAllUnsignedSignaturesOfPersonType(signature.personType, null, null);
                //this.setSignatureImageForAllUnsignedSignaturesOfPersonType(signature.personType, signature.imageSrc, signature.initialsSrc);
            },
            nextAvailableDocForCurrentSigner() { // Next available document.

                //We're looping and checking for an index higher than the one we currently have, if the document has an actual file and if this signer actually needs to sign.
                let iddxx1 = this.packet.documents.findIndex((doc, idx) => idx > this.pdfDocIndex && doc.filledDoc && doc.overlays.some((overlay) => {

                    if (overlay.overlayType == ENUMS.OVERLAY_TYPES.SIGNATURE) {
                        if (overlay.signature.personType == ENUMS.PERSON_TYPES.CUSTOMER) return this.signer == ENUMS.PERSON_TYPES.CUSTOMER
                        if (overlay.signature.personType == ENUMS.PERSON_TYPES.COCUSTOMER) return this.signer == ENUMS.PERSON_TYPES.COCUSTOMER
                    }

                    if (overlay.overlayType == ENUMS.OVERLAY_TYPES.INITIALS) {
                        if (overlay.initials.personType == ENUMS.PERSON_TYPES.CUSTOMER) return this.signer == ENUMS.PERSON_TYPES.CUSTOMER
                        if (overlay.initials.personType == ENUMS.PERSON_TYPES.COCUSTOMER) return this.signer == ENUMS.PERSON_TYPES.COCUSTOMER
                    }

                    return false
                }));

                return iddxx1;
            },
            nextDoc() { // Looks for next available document regardless of the signer.
                let iddxx1 = this.packet.documents.findIndex((doc, idx) => idx > this.pdfDocIndex && doc.filledDoc);
                return iddxx1;
            },
            prevDoc() {
                for (var i = this.pdfDocIndex - 1; i > -1; i--) {
                    if (this.packet.documents[i].filledDoc && this.requiresToSignDocument(this.packet.documents[i], this.signer)) {
                        return i;
                    }
                }

                return this.pdfDocIndex;
            },
            async loadDocument(pdfDocIndex) {

                //SCROLL TO TOP
                this.$refs.documentContainer.scrollTop = 0;

                if (pdfDocIndex < 0) return this.signer = null;

                this.isBusy = true;
                this.pdfDoc = null;

                let pdfDoc = this.packet.documents[pdfDocIndex];

                if (!this.usingSockets) {
                    this.packet.documents.forEach(doc => doc.isOpen = false);
                    pdfDoc.isOpen = true;
                } else {
                    this.$meetingHub.sendDocumentIsOpen(pdfDocIndex, true)
                }


                if (this.usingSockets) {
                    this.pdfDocTemp = pdfDoc;
                    this.pdfDocIndexTemp = pdfDocIndex;

                    // This will load the document from API.
                    //this.$meetingHub.requestDownloadDocument(pdfDoc.filledDoc);

                    const splitResult = pdfDoc.filledDoc.split('/')

                    const documentName = splitResult[splitResult.length - 1]
                    const dateStamp = splitResult[1]

                    let requestAttempts = 0;
                    const maxRequestAttempts = 3;

                    const customerDownloadDoc = async() => {
                        try {
                            console.log(`Attempting to download customer document - request attempt: ${requestAttempts}`);
                            requestAttempts += 1;
                            const response = await api.fimenu.customerDownloadDocument(documentName, this.$meetingHub.code, this.$meetingHub.accessKey, dateStamp, this.$meetingHub.userId)
                            if(!response?.data || !response?.data?.payload?.pdfDocument || response.status !== 200) {
                                throw new Error('customer download document failed with status', response.status);
                            }

                            pdfDoc.docToSign = response.data.payload.pdfDocument;
                            this.pdfDoc = pdfDoc;
                            this.pdfDocIndex = pdfDocIndex;

                        } catch(error) {
                            LogRocket.track('Customer Download Document Error');
                            if(requestAttempts < maxRequestAttempts) {
                                console.log('Reattempting to download customer document');
                                await customerDownloadDoc();
                            } else {
                                console.error(error);
                            }
                        }

                    }

                    await customerDownloadDoc();


                } else {
                    api.fimenu.getDocument(pdfDoc.filledDoc).then(response => {
                        //MODIFY THE FILE URL TO GET IT FROM THE API
                        pdfDoc.docToSign = response.data;

                        //GET DOCUMENT
                        this.pdfDoc = pdfDoc;
                        this.pdfDocIndex = pdfDocIndex;

                    });
                }

            },
            setSignatureDateStamp(doc) {

                const signSdate = moment().utc().format()

                if (this.signer == ENUMS.PERSON_TYPES.CUSTOMER ) {
                    if (doc.signatureInfo) {
                        doc.signatureInfo.customerSignDate = signSdate
                    } else {
                        doc.signatureInfo = {
                            customerSignDate: signSdate
                        }
                    }
                } else if (this.signer == ENUMS.PERSON_TYPES.COCUSTOMER) {
                    if (doc.signatureInfo) {
                        doc.signatureInfo.coCustomerSignDate = signSdate
                    } else {
                        doc.signatureInfo = {
                            coCustomerSignDate: signSdate
                        }
                    }
                }
            },
            loadNextDocument(skip = true) {
                if (this.packet) {
                    // -1 means that all the documents were signed by the current signer.
                    let pdfDocIndex = this.packet.documents.findIndex(d => !this.isDocSignedBySignerOrDone(d, this.signer));

                    if (this.pdfDoc) {
                        if (!skip) this.setSignatureDateStamp(this.packet.documents[this.pdfDocIndex])
                        let docIsFullySigned = this.isDocSigned(this.pdfDoc); // Check if both parties signed this document
                        let isDocFullySignedBySigner = this.isDocSignedBySignerOrDone(this.pdfDoc, this.signer); // Check if this signer completed signing this oument.

                        if (docIsFullySigned) {
                            this.pdfDoc.status = ENUMS.PaperworkDocumentStatus.SigningInProgress;
                            if (this.usingSockets) this.$meetingHub.paperworkSigned(this.pdfDocIndex, this.pdfDoc) // Sends the overlays (signatures) to the FIMenu Web App. This keeps the signature status updated. If signer is not included, means both signed.
                        }
                        else if (isDocFullySignedBySigner) {
                            this.pdfDoc.status = ENUMS.PaperworkDocumentStatus.PartiallySigned;
                            if (this.usingSockets) this.$meetingHub.paperworkSigned(this.pdfDocIndex, this.pdfDoc, this.signer) // Sends the overlays (signatures) to the FIMenu Web App. This keeps the signature status updated.
                        }
                    }

                    if (pdfDocIndex > -1 && this.packet.documents[pdfDocIndex] === this.pdfDoc) { //
                        //DO NOTHING... THERE ARE STILL MORE SIGNATURES TO SIGN  (We're on the same document we must sign.)
                    }
                    else if (pdfDocIndex > -1) { // pdfDocIndex is the index of the page/document we will now sign. If it exist, load it.
                        console.log("UPDATE SIGNATURE STAMP", skip)
                        if (!skip) this.setSignatureDateStamp(this.packet.documents[this.pdfDocIndex])
                        this.loadDocument(pdfDocIndex);
                    } else {
                        const allDocumentsSignedByAllParties = this.allDocumentsSignedByAllParties()

                        if (allDocumentsSignedByAllParties) {
                            //var popup = window.open("https://www.connectcdk.com/#/swys/signingroomDevice", "Signing Room", "width=" + screen.availWidth + ",height=" + screen.availHeight);
                            this.setSignatureDateStamp(this.packet.documents[this.pdfDocIndex])


                            if (this.usingSockets) {
                                this.$meetingHub.finishAllPaperwork('')
                                this.$router.push('/meetingroom/' + this.$route.params.code + '/stand-by?type=purchase');
                            } else if (this.$global.customerWindow) {
                                this.EventBus.emit('allPaperworkSigned');
                                this.$global.customerWindow.close();
                                this.$global.customerWindow = null
                            }
                        } else if (this.isDocSignedBySignerOrDone(this.pdfDoc, this.signer)) { // Check if this signer has signed all his documents.
                            this.setSignatureDateStamp(this.packet.documents[this.pdfDocIndex])

                            //this.signer = this.signer == ENUMS.PERSON_TYPES.CUSTOMER == this.signer ? ENUMS.PERSON_TYPES.COCUSTOMER : ENUMS.PERSON_TYPES.CUSTOMER

                            this.$meetingHub.saveFIMenu();
                            this.getSigners()

                        }
                    }

                }
            },
            cancelSignature() {
                this.showSignaturePad = false;
                this.signer = null;
            },
            submitSignature(signatureObj, initialsObj) {
                let signature = signatureObj.toDataURL();
                let initials = initialsObj.toDataURL();
                if (!signature.isEmpty && !initials.isEmpty) {
                    this.showSignaturePad = false;
                    if (this.postSignatureMethod)
                        this.postSignatureMethod(signature, initials);
                }
            },
            isDocSigned(doc) {
                if (doc?.overlays)
                    return doc.overlays.filter(o => o.signature || o.initials).every(o => (o.signature && o.signature.isSigned == true) || (o.initials && o.initials.isSigned == true));
                else
                    return true;
            },
            isDocSignedBySignerOrDone(doc, signer) { // Checks if the customer requires to sign or not, and returns true if he doesn't have to sign or has signed.
                if (doc?.overlays) {
                    const signerOverlays = [...doc.overlays.filter((overlay) => overlay?.signature?.personType == signer || overlay?.initials?.personType == signer)]

                    if (doc.filledDoc == null) return true

                    for (const overlay of signerOverlays) {
                        if (overlay.overlayType == ENUMS.OVERLAY_TYPES.SIGNATURE && !overlay.signature.isSigned) return false
                        if (overlay.overlayType == ENUMS.OVERLAY_TYPES.INITIALS && !overlay.initials?.isSigned) return false
                    }
                }
                return true;

            },
            clearAndRestart() {
                if (!this.fimenu.customer) return;
                if (this.signer == ENUMS.PERSON_TYPES.CUSTOMER) {
                    this.fimenu.customer.signature = null;
                    this.fimenu.customer.initials = null;
                } else if (this.signer == ENUMS.PERSON_TYPES.COCUSTOMER) {
                    this.fimenu.coCustomer.signature = null;
                    this.fimenu.coCustomer.initials = null;
                }

                this.packet.documents.forEach((doc, index) => {
                    if (doc.signatureInfo?.customerSignDate && this.signer == ENUMS.PERSON_TYPES.CUSTOMER) doc.signatureInfo.customerSignDate = null
                    if (doc.signatureInfo?.coCustomerSignDate && this.signer == ENUMS.PERSON_TYPES.COCUSTOMER) doc.signatureInfo.coCustomerSignDate = null

                    if (doc.overlays) {
                        doc.overlays.forEach(overlay => {
                            if (overlay.signature != null && (overlay.signature.personType == this.signer)) {
                                overlay.signature.imageSrc = null;
                                overlay.signature.isSigned = false;
                            }

                            if (overlay.initials != null && (overlay.initials.personType == this.signer)) {
                                overlay.initials.imageSrc = null;
                                overlay.initials.isSigned = false;
                            }
                        });
                    }

                    if (doc.status == ENUMS.PaperworkDocumentStatus.SigningInProgress || doc.status == ENUMS.PaperworkDocumentStatus.PartiallySigned) {// Filter for partially signed and signed status.
                        if (this.fimenu.hasCoSigner) {
                            const notActualSigner = this.signer == ENUMS.PERSON_TYPES.CUSTOMER ? ENUMS.PERSON_TYPES.COCUSTOMER : ENUMS.PERSON_TYPES.CUSTOMER
                            const requiresToSignContrarySigner = this.requiresToSignDocument(doc, notActualSigner)
                            const requiresToSignCurrentSigner = this.requiresToSignDocument(doc, this.signer)

                            if (requiresToSignContrarySigner) { // if "not current signer" requires to sign this document.
                                const hasSigned = this.isDocSignedBySignerOrDone(doc, notActualSigner)

                                if (hasSigned && requiresToSignCurrentSigner) doc.status = ENUMS.PaperworkDocumentStatus.PartiallySigned;
                                else if (requiresToSignCurrentSigner) doc.status = ENUMS.PaperworkDocumentStatus.ReadyToBeSigned;

                            } else if (requiresToSignCurrentSigner) { // if only "current signer" requires to sign this document.
                                doc.status = ENUMS.PaperworkDocumentStatus.ReadyToBeSigned;
                            }
                        } else { // If we don't have co signer, just make it ready to be signed.

                            doc.status = ENUMS.PaperworkDocumentStatus.ReadyToBeSigned;
                        }
                        if (this.usingSockets) {
                            this.$meetingHub.paperworkSigned(index, doc)
                        }
                    }

                });

                //[ENUMS.PERSON_TYPES.CUSTOMER, ENUMS.PERSON_TYPES.COCUSTOMER].map((persontype =>{
                this.resetSignature({
                    imageSrc: null,
                    initialsSrc: null,
                    personType: this.signer
                });
                //}))

                if (this.usingSockets) {
                    this.setSignatureImageForAllUnsignedSignaturesOfPersonType(this.signer, null, null)
                }

                if (this.fimenu.hasCoSigner) {
                    this.getSigners()
                    this.isBusy = false
                } else {
                    this.loadNextDocument();

                }
            },
            setSignatureImageForAllUnsignedSignaturesOfPersonType(personType, signatureImage = null, initialsImg = null) {

                this.packet.documents.forEach(doc => {
                    if (doc.overlays && doc.overlays.length > 0) {
                        doc.overlays.forEach(overlay => {
                            if (overlay.signature && overlay.signature.isSigned == false && overlay.signature.personType == personType) {
                                overlay.signature.imageSrc = signatureImage;
                            }
                            if (overlay.initials && overlay.initials.isSigned == false && overlay.initials.personType == personType) {
                                overlay.initials.imageSrc = initialsImg;
                            }
                        });
                    }
                });
            },
            handlePrevious(event) {
                if (this.pdfDocIndex == 0 && this.fimenu.hasCoSigner) return (this.signer = null);

                return this.loadDocument(this.prevDoc());
            },
            isPrevAllowed() {
                if (this.isBusy || (!this.fimenu.hasCoSigner && this.pdfDocIndex == 0)) return false;
                else return true;
            },
            isNextAllowed() {
                if (!this.pdfDoc || this.isBusy)
                    return false;
                else
                    return this.isDocSignedBySignerOrDone(this.pdfDoc, this.signer)
                //return this.isDocSigned(this.pdfDoc);
            },
            requiresToSignDocument(doc, signer) {
                return doc.overlays.some((overlay) => {
                    if (overlay.initials) return overlay.initials.personType == signer
                    else if (overlay.signature) return overlay.signature.personType == signer
                })
            },
            //requireCreateSignature(signer) {
            //    const signerIdentifier = ENUMS.PERSON_TYPES.CUSTOMER == signer ? 'customer' : 'coCustomer'

            //    if (!hasSignerCreatedSignature) {
            //        this.EventBus.emit('showSignaturePad', { postFunction: this.onSignaturePadSigned, personType: signer });
            //        return true
            //    } else {
            //        this.EventBus.emit('signatureReceived', {
            //            imageSrc: this.fimenu[signerIdentifier].signature,
            //            initialsSrc: this.fimenu[signerIdentifier].initials,
            //            personType: this.signer
            //        });
            //        return false
            //    }
            //},
            onSignaturePadSigned(signature, initials) {
                if (!signature.isEmpty && !initials.isEmpty) {
                    this.EventBus.emit('signatureReceived', {
                        imageSrc: signature,
                        initialsSrc: initials,
                        personType: this.signer
                    });
                }
            },
            selectSigner(selectedSigner) {
                this.signer = parseInt(selectedSigner)
                console.log("Did the signer get set?", this.signer)
                if (!this.allDocumentsSignedBySigner(parseInt(this.signer))) {
                    this.clearAndRestart()
                    this.signer = parseInt(selectedSigner)
                    this.loadNextDocument()
                    this.EventBus.emit('showSignaturePad', { postFunction: this.onSignaturePadSigned, personType: selectedSigner });
                } else {
                    this.loadDocument(0);
                }
                this.$nextTick(function () {
                    this.isBusy = true
                })

                /* this.$nextTick(function () {
                    if(this.allDocumentsSignedBySigner(parseInt(this.signer))){
                        this.loadDocument(0);
                    } else {
                        //Check if user has signature, if not, popup signature.
                        const requireCreateSignature = this.requireCreateSignature(selectedSigner)

                        this.loadNextDocument()
                    }
                }) */
            },
            allDocumentsSignedByAllParties() {
                // -1 means that all the documents were signed by the current signer.
                return this.packet.documents.findIndex(d => !this.isDocSigned(d)) <= -1 ? true : false
            },
            allDocumentsSignedBySigner(signer) {
                // -1 means that all document were signed by both parties.
                return this.packet.documents.findIndex(d => !this.isDocSignedBySignerOrDone(d, signer)) <= -1 ? true : false
            },
            updateWindowWidth() {
                this.windowWidth = window.innerWidth;
            }
        },
        components: {
            PDFViewer,
            PanelESign,
            PopupSelector,
            ESignMobile,

        }

    };
</script>
<style>
</style>