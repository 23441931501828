<template>
    <div :class="generateClassName">
        <!--<div class="icon-container">
            <i class="fas fa-wifi" />
            <i class="fas fa-exclamation-triangle" />
            <i class="fas fa-times" />
        </div>
        <p class="connectionStatus-message">{{connectionMessage}}</p>-->
    </div>
</template>

<script>
    import { connectionStatuses } from "@core/classes/Enums"

    export default {
        name: "ConnectionSpeedTester",
        props: [],
        components: {},
        data() {
            return {
                connectionSpeed: 0,
                disconnected: false,
            }
        },
        created() {
            this.setupEventListeners();
        },

        computed: {
            generateClassName() {
                let className = "connection-status";
                className += this.connectionString();

                return className;
            },
            connectionMessage() {
                let message = "Your Connection Is"
                message += this.connectionString();

                return message;
            }
        },
        methods: {
            connectionString() {
                if (this.connectionSpeed >= connectionStatuses.indexOf("disconnected")) {
                    return " Disconnected"
                }
                else if (this.connectionSpeed >= connectionStatuses.indexOf("2g")) {
                    return " Unstable"
                }
                else if (this.connectionSpeed >= connectionStatuses.indexOf("3g")) {
                    return " Poor"
                }

                return " Stable"
            },
            updateConnectionSpeed() {
                //console.log("navigator.connection.downlink", navigator.connection.downlink);
                //console.log("navigator.connection.rtt", navigator.connection.rtt);

                const type = (!navigator.connection.downlink || !navigator.onLine) ? "disconnected" : navigator.connection.effectiveType;
                // eslint-disable-next-line no-console
                console.log(
                    `Connection type changed from ${connectionStatuses[this.connectionSpeed]} to ${type}`
                );
                this.connectionSpeed = connectionStatuses.indexOf(type);
            },
            setupEventListeners() {
                navigator.connection.addEventListener("change", this.updateConnectionSpeed);
                this.EventBus.on('check-connection-strength', this.updateConnectionSpeed);
            },
        },
        beforeUnmount(){
            this.EventBus.off('check-connection-strength', this.updateConnectionSpeed);
            navigator.connection.removeEventListener("change", this.updateConnectionSpeed);
        }
    }
</script>

<style>
    .connection-status {
        display: flex;
    }

        .connection-status .connectionStatus-message {
            font-size: 0.75rem;
            font-weight: 500;
            min-width: 180px;
            text-align: left;
        }

        .connection-status .icon-container {
            min-width: 50px;
            position: relative;
        }

        .connection-status i.fa-wifi {
            font-size: 1.5rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.2s ease-out;
        }

        .connection-status i.fa-exclamation-triangle,
        .connection-status i.fa-times {
            position: absolute;
            bottom: 0;
            right: 0;
            opacity: 0;
            transition: opacity 0.2s ease-out;
        }

        .connection-status i.fa-exclamation-triangle {
            transform: translate(-50%, -50%);
            font-size: 0.75rem;
        }

        .connection-status.Unstable i.fa-exclamation-triangle {
            color: var(--error-color);
            animation: throb infinite 1s ease-out;
        }

        .connection-status i.fa-times {
            transform: translate(-70%, -20%);
            font-size: 1rem;
        }

        .connection-status.Stable i.fa-wifi {
            color: var(--success-color);
        }

        .connection-status.Poor i.fa-wifi,
        .connection-status.Unstable i.fa-wifi {
            color: var(--print-color);
        }

        .connection-status.Disconnected i.fa-wifi {
            color: var(--error-color);
        }

        .connection-status.Disconnected i.fa-times,
        .connection-status.Poor i.fa-exclamation-triangle,
        .connection-status.Unstable i.fa-exclamation-triangle {
            opacity: 1;
        }

        .connection-status.Disconnected {
            animation: throb infinite 1s ease-out;
        }

    @keyframes throb {
        from {
            opacity: 1;
        }

        50% {
            opacity: 0.35;
        }

        to {
            opacity: 1;
        }
    }
</style>