/**
 * This method will save the deal information in local storage for a specific customer.
 *
 * @param dealId
 * @param dealNumber
 * @param storeCode
 * @param customerId
 * @param dealType check FIMenuDealType; basically if the deal is a quote or an actual deal.
 */

import { DealKind } from "@core/classes/SharedEnums";
import api from "@core/services/api";
import FIMenu from "@core/classes/FIMenu";
import $modal from '@core/services/modal';
import modalInfo from "@core/modals/modalInfo.vue";
import FIMenuCustomer from "@core/classes/FIMenuCustomer";
import settings from "settings";
import Store from "@core/classes/Store/Store";
import {DEAL_STATUS} from "@core/classes/Enums";
import util from "@core/services/util";
import LogRocket from 'logrocket';

export function AddCustomerDealToLocalStorage(dealId: string, dealNumber: number, storeCode: string, customerId: string, dealType: DealKind = DealKind.Deal) {

    const storedResult = JSON.parse(localStorage.getItem(`customer-deals-${customerId}`));
    let arrayOfDeals = []
    if (Array.isArray(storedResult)) arrayOfDeals = storedResult;

    const doesDealAlreadyExistInLocalStorage = arrayOfDeals.some(d => d.id == dealId)

    if (!doesDealAlreadyExistInLocalStorage) {

        // Add the deal to the tab, save it into local storage and switch to the newly added deal.
        arrayOfDeals.push({storeCode: storeCode, dealNumber: dealNumber, id: dealId, type: dealType});
        localStorage.setItem(`customer-deals-${customerId}`, JSON.stringify(arrayOfDeals))
    }
}

/**
 * Return an FIMenu Deal
 * @param dealId
 * returns an FIMenu.
 */
export async function GetDeal(dealId: string, type: DealKind = DealKind.Deal): Promise<FIMenu | null> {
    try {
        const response = await api.fimenu.getDeal(dealId, type);

        cdkDealCheck(response)

        if (response.data.fimenu) {
            return new FIMenu(response.data.fimenu)
        }

        return null;

    } catch(err) {
        console.error(err)
        return null;
    }
}

//CHECK THAT THIS DEAL EXISTS IN CDK FOR THIS STORE
function cdkDealCheck(getDealResponse: any) : void {
    //CDK DEAL CHECK
    const messages = getDealResponse.data.messages;
    const thisDealIsMissingFromCDK = messages.some((m: any) => m.type == 'cdk_deal_not_found');
    const dealData = getDealResponse.data.payload;

    if (thisDealIsMissingFromCDK) {
        $modal.open(modalInfo, {
            name: 'modalInfo',
            passedData: {
                title: 'Invalid Deal Number or Store Combination',
                info: `Deal ${dealData.externalDMSNumber} is not a deal in CDK for store ${dealData.storeName}. Make sure you have the right store selected.`,
                acceptText: 'OK'
            },
            postFunction: () => {
                window.location.href="customers"
            },
            backdrop: false
        });
    }
}

export async function CreateDeal (customer: FIMenuCustomer, storeCode: string, coCustomer?: FIMenuCustomer) : Promise<FIMenu | null> {
    // Takes in a customer search result

    const store = settings.userStores.find((x: Store) => x.storeCode == storeCode );

    if(store == null) return;

    try {
        const fiMenuCustomer = new FIMenuCustomer(customer);
        // Initialize the FIMenu class (Empty)
        const deal = new FIMenu({
            storeCode: storeCode,
            store: store.store,
            dealStatus: DEAL_STATUS.SALES_IN_PROCESS
        });
        deal.customer = fiMenuCustomer;

        if (coCustomer) {
            // If we have a co-customer. Lets populate it.
            try {
                const customerRecord = await api.customers.getCustomerById(coCustomer.id);
                if (customerRecord.data) {
                    deal.coCustomer.setFromCustomer(customerRecord.data);
                    deal.hasCoSigner = true;
                }
            } catch (err: any) {
                util.toastr("error", "Error", err.message ?? "Could not load CoCustomer");
                return;
            }
        }
        await deal.saveNew();

        return deal;
    } catch (err: any) {
        util.toastr(
            "error",
            "Error saving",
            err.message ?? "Unexpected server error occured. Please refresh and try again."
        );

        return null;
    }
}

export function IsDealOpenable(deal: FIMenu, kind: DealKind) {
    // Checking if is a valid quote.
    if (kind == DealKind.Quote) {
        // If the deal is not a quote itself. The its wrong.
        if(deal.dealKind != DealKind.Quote) return false;
        // If deal is past sales. Cannot be a quote.
        console.log("ASDASDASd", deal.dealStatus)
        if(deal.dealStatus > DEAL_STATUS.SALES_MANAGER_FINISHED) return false;

        return true;
    }

    if (kind == DealKind.Deal) {
        // If the deal is not a quote itself. The its wrong.
        if(deal.dealKind != DealKind.Deal) return false;
        // If deal is in finance. Cannot be a quote.
        if(deal.dealStatus <= DEAL_STATUS.SALES_MANAGER_FINISHED) return false;

        return true;
    }
}

