<template>
    <Panel title="Buyer's Order" class="panel-buyers-order">
        <template v-slot:header>
            <div class="panel-buttons">
                <span v-if="bo && user.EmployeeAccess.IsAdmin" class="bo-transaction-id">{{ bo.transactionId }}</span>

                <button :disabled="fimenu.buyersOrderIsBusy || anyBookValueInvalid" @click="reset()" :class="{'button button-edit': true, 'pulsing': !fimenu.buyersOrderIsBusy && fimenu.buyersOrderUpdateRequired}">
                    <i class="fas fa-sync" /> Reset
                </button>

                <ButtonSubmitToFinanceInstitutions v-if="submitToFinanceChannel" :deal="fimenu" />

            </div>
        </template>

        <div class="additional-amounts">
            <div class="buyers-order-row" :style="buyersOrderRowGrid_3Cols">
                <div></div>
                <div>Deal Type</div>
                <div class="amt">
                    <InputRichDropdown v-model:saturn="fimenu.dealType"
                                       :list="dealTypes"
                                       @change="dealTypeChanged()"
                                       :invalid="!fimenu.dealType"
                                       cssClass="deal-type-dropdown">
                        <template #validation>
                            <div v-if="!fimenu.dealType">Deal Type is required</div>
                        </template>
                    </InputRichDropdown>
                </div>
            </div>

            <div class="buyers-order-row" :style="buyersOrderRowGrid_3Cols">
                <div></div>
                <div>Miles Per Year</div>
                <div class="amt">
                    <InputNumber v-model:saturn="fimenu.vehicle.milesPerYear"
                                 :precision="0"
                                 :invalid="util.isNull(fimenuValidation) ? false : fimenuValidation.vehicle.milesPerYear.$invalid"
                                 @blur="() => saveLockedDeal(util.isNull(fimenuValidation) ? false : fimenuValidation.vehicle.milesPerYear.$invalid)"
                                 :min="0">
                        <template #validation>
                            <div v-if="!util.isNull(fimenuValidation) && !fimenuValidation.vehicle.milesPerYear.required">Miles Per Year is required</div>
                        </template>
                    </InputNumber>
                </div>
            </div>

            <BuyersOrderBookValues :bo="bo"
                                   :vehicle="fimenu.vehicle"
                                   bucketType="Vehicle Values"
                                   :showItems="true"
                                   :taxable="false"
                                   addNewItemPrompt="Add"
                                   :costLockCondition="() => fimenu.isDealLocked()"
                                   :isVehicleNew="fimenu.isNew()"
                                   :validation="fimenuValidation"
                                   @onItemClick="handleEditBookValue"
                                   @onAddNewItemClick="handleAddBookValue"
                                   @blur="(isInvalid) => saveLockedDeal(isInvalid)" />

        </div>
        <div v-if="bookValuesValidationMessages" class="bo-placeholder">
            <h4 v-if="bookValuesValidationMessages">
                {{ bookValuesValidationMessages.Message }}
            </h4>
        </div>
        <div v-else-if="!canDisplayBO" class="bo-placeholder">
            <h4>Missing values to generate Buyer's Order</h4>
        </div>
        <IsBusySectionComponent v-else-if="fimenu.buyersOrderIsBusy" />
        <div v-else-if="bo">
            <div class="buyers-order-grid fade-in-fast">
                <div v-if="bo.isError()" class="buyers-order-grid fade-in-fast panel-body">
                    <div class="buyers-order-error">
                        <div class="buyers-order-error-title">
                            Buyers Order Error
                        </div>
                        <div class="buyers-order-error-subtitle">
                            An error has occurred generating a buyers order. Verify all the information entered is correct.
                        </div>

                        <div class="buyers-order-error-code">{{ bo.errorCode }}</div>
                        <div class="buyers-order-error-message">{{ bo.errorMessage }}</div>

                        <div class="buyers-order-error-actions">
                            <div>
                                <br />You can fix the issue above and click RESET, or you can create the buyers order manually.
                            </div>

                            <div class="buyers-order-error-manual-button">
                                <button @click="createEmptyBuyersOrder()">Manual Buyers Order</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else class="bo-body">

                    <div class="buyers-order-row buyers-order-column-headers" :style="buyersOrderRowGrid_5Cols">
                        <div></div>
                        <div></div>
                        <div>Amount</div>
                        <div>Cost</div>
                        <div>Profit</div>
                    </div>

                    <div class="buyers-order-row" :style="buyersOrderRowGrid_6Cols" :class="{'buyers-order-taxable-row': bo.isSellingPriceTaxed()}">
                        <div></div>
                        <div>Selling Price</div>

                        <div>+</div>
                        <div class="amt">
                            <InputCurrency v-model:saturn="bo.sellingPrice"
                                           :modelValue="bo.sellingPrice"
                                           @change="recalculate()"
                                           placeholder="Selling Price"
                                           :precision="2"
                                           iconSide="left"
                                           :invalid="v$.bo.sellingPrice.$invalid">
                                <template #validation>
                                    <div v-if="v$.bo.sellingPrice.required.$invalid">Selling Price is required</div>
                                    <div v-if="v$.bo.sellingPrice.minValue.$invalid">Selling Price amount invalid.</div>
                                </template>
                            </InputCurrency>
                        </div>
                        <div class="amt ignore-deal-lock">
                            <InputCurrency v-model:saturn="bo.invoiceAmount"
                                           :precision="2"
                                           :disabled="isInvoiceAmountDisabled"
                                           @change="() => recalculate(fimenu.isDealLocked())"
                                           iconSide="left"
                                           :invalid="v$.bo.invoiceAmount.$invalid"
                                           @blur="() => saveLockedDeal(v$.bo.invoiceAmount.$invalid)">
                                <template #validation>
                                    <div v-if="v$.bo.invoiceAmount.required.$invalid">Actual cost is required</div>
                                    <div v-if="v$.bo.invoiceAmount.minValue.$invalid">Actual cost amount invalid.</div>
                                </template>
                            </InputCurrency>
                        </div>
                        <div class="amt">
                            <InputCurrency :saturn="bo.sellingPriceProfit()"
                                           :precision="2"
                                           iconSide="left"
                                           :disabled="true">
                            </InputCurrency>
                        </div>
                    </div>

                    <div class="taxable-items">
                        <div class="taxable-indicator" :class="{'buyers-order-taxable-include-selling-price': bo.isSellingPriceTaxed()}">TAXABLE</div>
                        <fieldset class="taxable-content" :disabled="isBusyAddEdit">
                            <BuyersOrderBucket :bo="bo" :sourceData="fimenu.tradeIns" bucketType="Trades" :taxable="true" @change="() => recalculate(fimenu.isDealLocked())" :costLockCondition="() => fimenu.lockByDealStatus() || fimenu.isDealPassedFinance()" @blur="(isInvalid) => saveLockedDeal(isInvalid)" />
                            <BuyersOrderBucket :bo="bo" bucketType="Products" :taxable="true" @change="() => recalculate(fimenu.isDealLocked())" :showItems="true" @onItemClick="addEditItem" @onAddNewItemClick="addEditItem" addNewItemPrompt="Manage Preloads" :costLockCondition="() => fimenu.isDealLocked()" />
                            <BuyersOrderBucket :bo="bo" bucketType="Fees" :taxable="true" @change="() => recalculate(fimenu.isDealLocked())" @onItemClick="addEditItem" @onAddNewItemClick="addEditItem" addNewItemPrompt="Add New Fee" :costLockCondition="() => fimenu.isDealPassedFinance()" />
                            <BuyersOrderBucket :bo="bo" bucketType="Additions" :taxable="true" @change="() => recalculate(fimenu.isDealLocked())" @onItemClick="addEditItem" @onAddNewItemClick="addEditItem" addNewItemPrompt="Add New Addition" :costLockCondition="() => fimenu.isDealPassedFinance()" />
                            <BuyersOrderBucket :bo="bo" :sourceData="bo" bucketType="Rebates" :taxable="true" @change="() => recalculate(fimenu.isDealLocked())" :hideSubTotal="true" :costLockCondition="() => fimenu.isDealLocked()" />
                            <BuyersOrderBucket :bo="bo" :sourceData="bo" bucketType="Cash Down" :taxable="true" @change="() => recalculate(fimenu.isDealLocked())" :hideSubTotal="true" :costLockCondition="() => fimenu.isDealLocked()" />
                        </fieldset>
                    </div>

                    <div class="buyers-order-row ignore-all-locks" :style="buyersOrderRowGrid_6Cols">
                        <button class="button-span ignore-all-locks header-collapse-button" @click="toggleTaxes()">
                            <i :class="['far', (taxesIsOpen) ? 'fa-minus-square' : 'fa-plus-square']" />
                        </button>
                        <div class="taxes-total">
                            <span class="subtotal-container">Taxes Total</span>
                            <span class="new-tax-line">
                                <button v-show="taxesIsOpen"
                                        class="button button-edit"
                                        @click="addEditTaxLine()"
                                        :disabled="fimenu.isDealLocked() || fimenu.isDealPassedFinance()">

                                    <i class="fas fa-plus" />
                                    New Tax Line
                                </button>
                            </span>
                        </div>

                        <div>+</div>
                        <div class="amt subtotal-container">
                            <InputCurrency :saturn="bo.finalNumbers.taxTotal"
                                           :disabled="true"
                                           :precision="2"
                                           iconSide="left"
                                           :negative="true">
                            </InputCurrency>
                        </div>
                        <div></div>
                        <div></div>
                    </div>
                    <div v-if="taxesIsOpen" class="ignore-all-locks">
                        <div v-for="taxLine in bo.taxGroups" :key="taxLine.name" class="buyers-order-row" :style="$grid('42px-1-30px-115px-125px-125px', '0px 5px')">
                            <div></div>
                            <div class="taxes-total">
                                <button class="button-link" @click="addEditTaxLine(taxLine)">
                                    <span v-if="bo.dealType == 'Lease'">Upfront</span> {{ taxLine.name }} ({{ taxLine.rate.toFixed(3) }}%)
                                </button>
                            </div>

                            <div>+</div>
                            <div class="amt">
                                <InputCurrency :saturn="taxLine.taxTotal"
                                               :disabled="true"
                                               :precision="2"
                                               iconSide="left"
                                               :negative="true">
                                </InputCurrency>
                            </div>
                            <div></div>
                            <div></div>
                        </div>

                        <div v-if="bo.isLease()" class="lease-monthly-use">

                            <div v-if="bo.isLeaseMonthlyUse">
                                <div v-for="taxLine in bo.taxGroups" :key="taxLine.name" class="buyers-order-row" :style="$grid('42px-1-30px-115px-125px-125px', '0px 5px')">
                                    <div></div>
                                    <div class="taxes-total">
                                        <span>CCR</span> {{ taxLine.name }}
                                    </div>
                                    <div>+</div>
                                    <div class="amt">
                                        <InputCurrency :saturn="taxLine.leaseInfo.ccrTaxAmount"
                                                       :disabled="true"
                                                       :precision="2"
                                                       iconSide="left"
                                                       :negative="true">
                                        </InputCurrency>
                                    </div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                            <div v-else class="buyers-order-row" :style="$grid('42px-1-30px-115px-125px-125px', '0px 5px')">
                                <div></div>
                                <div class="taxes-total">
                                    Cap Cost Reduction Tax
                                </div>

                                <div>+</div>
                                <div class="amt">
                                    <InputCurrency :saturn="bo.finalNumbers.leaseFinalNumbers.capCostReductionTaxAmount"
                                                   :disabled="true"
                                                   :precision="2"
                                                   iconSide="left"
                                                   :negative="true">
                                    </InputCurrency>
                                </div>
                                <div></div>
                                <div></div>
                            </div>

                            <div v-if="bo.isLeaseMonthlyUseNY" class="buyers-order-row" :style="$grid('42px-1-30px-115px-125px-125px', '0px 5px')">
                                <div></div>
                                <div class="taxes-total">
                                    Upfront Monthly Use Tax
                                </div>

                                <div>+</div>
                                <div class="amt">
                                    <InputCurrency :saturn="bo.finalNumbers.leaseFinalNumbers.monthlyUseTaxTotal"
                                                   :disabled="true"
                                                   :precision="2"
                                                   iconSide="left"
                                                   :negative="true">
                                    </InputCurrency>
                                </div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>

                    </div>
                    <fieldset class="taxable-content" :disabled="isBusyAddEdit">

                        <BuyersOrderBucket :bo="bo" :sourceData="fimenu.tradeIns" bucketType="Trades" :taxable="false" @change="() => recalculate(fimenu.isDealLocked())" :costLockCondition="() => fimenu.lockByDealStatus() || fimenu.isDealPassedFinance()" />

                        <BuyersOrderBucket :bo="bo" bucketType="Products" :taxable="false" @change="() => recalculate(fimenu.isDealLocked())" :showItems="true" :costLockCondition="() => fimenu.isDealLocked() || fimenu.isDealPassedFinance()" />

                        <BuyersOrderBucket :bo="bo" bucketType="Fees" :taxable="false" @change="() => recalculate(fimenu.isDealLocked())" @onItemClick="addEditItem" @onAddNewItemClick="addEditItem" addNewItemPrompt="Add New Fee" :costLockCondition="() => fimenu.isDealPassedFinance()" :slotContainerProperties="getSlotNames()">

                            <template #1115>
                                <div></div>
                                <div>Security Deposit Amount</div>
                                <InputNumber v-model:saturn="bo.security.refundableSecurityDepositCount"
                                             iconSide="left"
                                             :disabled="!selectedLenderVersion"
                                             @change="calculateSecurityDepositAmount"
                                             :min="0">
                                </InputNumber>
                                <div></div>
                                <InputCurrency :saturn="bo.security.refundableSecurityDepositAmount"
                                               :precision="2"
                                               iconSide="left"
                                               disabled
                                               :min="0">
                                </InputCurrency>

                            </template>

                        </BuyersOrderBucket>



                        <BuyersOrderBucket :bo="bo" bucketType="Additions" :taxable="false" @change="() => recalculate(fimenu.isDealLocked())" @onItemClick="addEditItem" @onAddNewItemClick="addEditItem" addNewItemPrompt="Add New Addition" :costLockCondition="() => fimenu.isDealPassedFinance()" />
                        <BuyersOrderBucket :bo="bo" :sourceData="bo" bucketType="Rebates" :taxable="false" @change="() => recalculate(fimenu.isDealLocked())" :hideSubTotal="true" :costLockCondition="() => fimenu.isDealLocked()" />
                        <BuyersOrderBucket :bo="bo" :sourceData="bo" bucketType="Cash Down" :taxable="false" @change="() => recalculate(fimenu.isDealLocked())" :hideSubTotal="true" :costLockCondition="() => fimenu.isDealLocked()" />
                    </fieldset>
                    <div v-if="bo.isLease()" class="lease-monthly-use">
                        <div class="buyers-order-row" :style="buyersOrderRowGrid_5Cols">
                            <div></div>
                            <div>
                                <span class="subtotal-container">First Payment</span>
                            </div>
                            <div class="amt subtotal-container">
                                <InputCurrency :saturn="bo.finalNumbers.leaseFinalNumbers.firstPaymentWithTax"
                                               :disabled="true"
                                               :precision="2"
                                               iconSide="left"
                                               :negative="true">
                                </InputCurrency>
                            </div>

                            <div class="amt subtotal-container"></div>
                            <div class="amt subtotal-container"></div>
                        </div>
                    </div>

                    <div class="buyers-order-row" :style="buyersOrderRowGrid_5Cols ">
                        <div></div>
                        <div>
                            <button v-if="user.EmployeeAccess.IsAdmin" class="button-link subtotal-container ignore-all-locks" @click="showFinalNumbers()">Total Out the Door</button>
                            <span v-else class="subtotal-container">Total Out the Door</span>
                        </div>

                        <div class="amt subtotal-container">
                            <InputCurrency :saturn="bo.finalNumbers.total"
                                           :disabled="true"
                                           :precision="2"
                                           iconSide="left"
                                           :negative="true">
                            </InputCurrency>
                        </div>
                        <div class="amt subtotal-container">
                            <InputCurrency :saturn="bo.finalNumbers.totalCost"
                                           :disabled="true"
                                           :precision="2"
                                           iconSide="left"
                                           :negative="true">
                            </InputCurrency>
                        </div>
                        <div class="amt subtotal-container">
                            <InputCurrency :saturn="bo.finalNumbers.totalProfit"
                                           :disabled="true"
                                           :precision="2"
                                           iconSide="left"
                                           :negative="true">
                            </InputCurrency>
                        </div>
                    </div>

                    <div v-if="bo.isLease()" class="lease-monthly-use">
                        <br /><br />

                        <div class="buyers-order-row" :style="buyersOrderRowGrid_6Cols">
                            <div></div>
                            <div>
                                <span v-if="bo.finalNumbers.leaseFinalNumbers.capCostReduction <= 0" class="subtotal-container">Inception Deficiency</span>
                                <span v-else class="subtotal-container">Cap Cost Reduction</span>
                            </div>
                            <div></div>
                            <div class="amt subtotal-container"></div>
                            <div class="amt subtotal-container"></div>
                            <div class="amt subtotal-container">
                                <InputCurrency :saturn="Math.abs(bo.finalNumbers.leaseFinalNumbers.capCostReduction)"
                                               :disabled="true"
                                               :precision="2"
                                               iconSide="left"
                                               :negative="true">
                                </InputCurrency>
                            </div>
                        </div>

                        <div class="buyers-order-row" :style="buyersOrderRowGrid_6Cols">
                            <button class="button-span ignore-all-locks header-collapse-button" @click="toggleInceptions()">
                                <i :class="['far', (inceptionsIsOpen) ? 'fa-minus-square' : 'fa-plus-square']" />
                            </button>
                            <div>
                                <span class="subtotal-container">Inceptions</span>
                            </div>

                            <div></div>
                            <div class="amt subtotal-container">
                            </div>
                            <div class="amt subtotal-container">
                            </div>
                            <div class="amt subtotal-container">
                                <InputCurrency :saturn="bo.finalNumbers.leaseFinalNumbers.inceptions"
                                               :disabled="true"
                                               :precision="2"
                                               iconSide="left"
                                               :negative="true">
                                </InputCurrency>
                            </div>
                        </div>
                        <div v-if="inceptionsIsOpen">
                            <div v-for="inceptionItem in bo.finalNumbers.leaseFinalNumbers.inceptionsBreakdown" :key="inceptionItem.name" class="buyers-order-row" :style="$grid('42px-1-30px-115px-125px-115px', '0px 5px')">
                                <div></div>
                                <div class="taxes-total">
                                    {{ inceptionItem.name }}
                                </div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div class="amt">
                                    <InputCurrency :saturn="inceptionItem.amount"
                                                   :disabled="true"
                                                   :precision="2"
                                                   iconSide="left"
                                                   :negative="true">
                                    </InputCurrency>
                                </div>
                            </div>
                        </div>

                        <div v-if="bo.isLeaseMonthlyUse" class="buyers-order-row" :style="buyersOrderRowGrid_6Cols">
                            <button class="button-span ignore-all-locks header-collapse-button" @click="toggleMonthlyUseInfo()">
                                <i :class="['far', (monthlyUseInfoIsOpen) ? 'fa-minus-square' : 'fa-plus-square']" />
                            </button>
                            <div>
                                <span class="subtotal-container">Monthly Use Tax ({{ bo.totalSalesTaxRate.toFixed(2) }}%)</span>
                            </div>

                            <div></div>
                            <div class="amt subtotal-container">
                            </div>
                            <div class="amt">
                                <InputCurrency :saturn="bo.finalNumbers.leaseFinalNumbers.monthlyUseTaxMonthlyTotal"
                                               :disabled="true"
                                               :precision="2"
                                               iconSide="left"
                                               :negative="true">
                                </InputCurrency>
                            </div>
                            <div class="amt">
                                <InputCurrency :saturn="bo.finalNumbers.leaseFinalNumbers.monthlyUseTaxTotal"
                                               :disabled="true"
                                               :precision="2"
                                               iconSide="left"
                                               :negative="true">
                                </InputCurrency>
                            </div>
                        </div>

                        <div v-if="monthlyUseInfoIsOpen">
                            <div v-for="monthlyUseItem in bo.finalNumbers.leaseFinalNumbers.monthlyUseTaxInfo" :key="monthlyUseItem.taxLineName" class="buyers-order-row" :style="$grid('42px-1-30px-115px-125px-115px', '0px 5px')">
                                <div></div>
                                <div class="taxes-total">
                                    Monthly Use {{ monthlyUseItem.taxLineName }} ({{ monthlyUseItem.taxLineRate.toFixed(2) }}%)
                                </div>
                                <div></div>
                                <div></div>
                                <div class="amt">
                                    <InputCurrency :saturn="monthlyUseItem.taxLineMonthlyTaxAmount"
                                                   :disabled="true"
                                                   :precision="2"
                                                   iconSide="left"
                                                   :negative="true">
                                    </InputCurrency>
                                </div>
                                <div class="amt">
                                    <InputCurrency :saturn="monthlyUseItem.taxLineTotalTaxAmount"
                                                   :disabled="true"
                                                   :precision="2"
                                                   iconSide="left"
                                                   :negative="true">
                                    </InputCurrency>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Panel>
</template>

<script>
    import util, { EventBusCore } from '@core/services/util'
    import api from '@core/services/api';
    import auth from '@core/services/auth';
    import BuyersOrderHelper from '@core/helpers/buyers-order-helper'

    import InputCurrency from '@core/components/InputCurrency.vue'
    import InputNumber from '@core/components/InputNumber.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import Panel from '@core/components/Panel.vue'

    import $modal from '@core/services/modal'
    import modalAddEditAddition from '@/modals/modalAddEditAddition.vue'
    import modalAddEditFee from '@/modals/modalAddEditFee.vue'
    import modalAddEditTaxLine from '@/modals/modalAddEditTaxLine.vue'
    import modalPre from '@/modals/modalPre.vue'

    import { required, minValue } from '@vuelidate/validators'
    import BuyersOrderBucket from '@/components/buyers-order/BuyersOrderBucket.vue'
    import ENUMS from "@core/classes/Enums"
    import IsBusySectionComponent from '@core/components/IsBusySectionComponent.vue'

    import { createBookValue, editBookValue } from '@/helpers/fimenuvehicle-helper'
    import { BookValue } from '@core/classes/SharedEnums';
    import ButtonSubmitToFinanceInstitutions from '@/components/ButtonSubmitToFinanceInstitutions.vue'
    import BuyersOrderBookValues from '@/components/buyers-order/BuyersOrderBookValues.vue';
    import { getValidationForBookValues } from '@/helpers/fimenuvehicle-helper';
    import modalAddEditPreloads from '@/modals/modalAddEditPreloads.vue'
    import { useVuelidate } from '@vuelidate/core';
    import { VehicleBookValue } from '@core/classes/VehicleBookValue';

    export default {
        name: "PanelBuyersOrder",
        props: ['fimenu', 'fimenuValidation', 'submitToFinanceChannel'],
        setup() {
            // New setup for vuelidate. Swap $v with v$
            return { v$: useVuelidate({ $scope: false }) }
        },
        data() {
            return {
                bo: null,
                isBusyAddEdit: false,
                taxesIsOpen: false,
                inceptionsIsOpen: false,
                monthlyUseInfoIsOpen: false
            }
        },
        computed: {
            ENUMS() {
                return ENUMS;
            },
            user() {
                return auth.getTokenPayload();
            },
            util() {
                return util;
            },
            BuyersOrderHelper() {
                return BuyersOrderHelper
            },
            buyersOrderRowGrid_6Cols() {
                return this.$grid('25px-1-30px-125px-125px-125px', '0px 5px');
            },
            buyersOrderRowGrid_5Cols() {
                return this.$grid('25px-1-125px-125px-125px', '0px 5px');
            },
            buyersOrderRowGrid_3Cols() {
                return this.$grid('25px-1-125px', '0px 5px');
            },
            dealTypes() {
                return ENUMS.DEAL_TYPES.toList().map(dt => dt.display);
            },
            selectedLenderVersion() {
                if (!this.fimenu.lender.lenderCode) return;

                const foundLender = this.$global.Lenders.find(lender => lender.lenderCode === this.fimenu.lender?.lenderCode);
                if (!foundLender) {
                    util.toastr("error", "Getting Lender Security Deposit Amount Settings", `We are unable to match the lender code ${this.fimenu.lender.lenderCode}`)
                    return;
                }
                return foundLender.versions.find(version => version.versionLabel === this.fimenu.store.storeState) ?? foundLender.versions[0];
            },
            lenderDealType() {
                return this.fimenu.isLease() ? "lease" : this.fimenu.isFinance() ? "retail" : "draft";
            },
            getInvoiceOrWholeSaleLabel() {
                return this.fimenu.inventoryType == 'New' ? 'Invoice' : 'Wholesale'
            },
            isInvoiceAmountDisabled() {
                return this.fimenu.lockByDealStatus() || this.fimenu.isDealPassedFinance();
            },
            canDisplayBO() {
                return this.fimenu.dealType;
            },
            cannotDisplayBOMessage() {
                if (this.fimenu.vehicle.bookValues.length <= 0)
                    return "You must have at least one vehicle value";

                if (this.fimenu.isNew() && this.fimenu.vehicle.bookValues.some(x => x.source == BookValue.Manufacturer))
                    return "MSRP is required. Please click 'Add' and choose 'Manufacturer' from the options.";

                return null;
            },
            getBookValueValidationResult() {
                const validations = getValidationForBookValues(this.fimenu);
                return Object.entries(validations).find(([, validation]) => !validation.valid()) || null;
            },
            anyBookValueInvalid() {
                return !!this.getBookValueValidationResult;
            },
            bookValuesValidationMessages() {
                const invalidValidation = this.getBookValueValidationResult;
                return invalidValidation ? invalidValidation[1] : null; // Return the validation object or null
            }
        },
        async created() {
            if (this.fimenu.buyersOrderEnabled) {
                this.EventBus.on('buyersOrderUpdated', this.loadData);
                EventBusCore.on('buyersOrderUpdated', this.loadData);

                this.EventBus.on('recalculateBuyersOrder', () => { this.recalculate(); });
                this.loadData();
            }
        },
        methods: {
            getSlotNames() {
                return this.fimenu.isLease() ? ['1115'] : []
            },
            calculateSecurityDepositAmount() {
                console.count('calculateSecurityDepositAmount')

                if (!this.selectedLenderVersion) return;

                let roundedTo = this.selectedLenderVersion.lease?.securityDepositRoundToNearestAmount;
                if (!roundedTo) {
                    util.toastr("error", "Missing Lender Setting", `Lender Setting for "${this.fimenu.lender.lenderName}" is missing the 'Rounded Security Deposit Amount' for lease deals. Using default value of {$75} for rounding.`, 10000)
                    roundedTo = 75;
                }

                //SAVE THE SETTING FROM LENDER
                this.bo.security.roundedTo = roundedTo;

                //this.bo.calculateRefundableSecurityDepositAmount(roundedTo);

                //const securityDepositFeeIndex = this.bo.fees.findIndex(fee => fee.titleId === "1115");
                //if (securityDepositFeeIndex > 0) this.bo.fees[securityDepositFeeIndex].amount = this.bo.security.refundableSecurityDepositAmount;

                this.recalculate(this.fimenu.isDealLocked());
            },
            onBlurMilesPerYear() {
                if (this.fimenuValidation?.vehicle.milesPerYear.$invalid) return
                this.fimenu.buyersOrderUpdateRequiredRightAway = true
            },
            showFinalNumbers() {
                $modal.open(modalPre, { name: 'modalPre', passedData: this.bo, backdrop: true });
            },
            createEmptyBuyersOrder() {
                if (!this.bo) return;

                this.bo.clearAndCreateAnEmptyBuyersOrder();
                this.recalculate();
            },
            handleAddBookValuePrePostFunction(newBookValues = [])
            {   
                const currentBO = this.fimenu.getCurrentBuyersOrder();
                const hasMSRPChanged = this.hasMSRPChanged(newBookValues);
                
                newBookValues.forEach(bv => { // Loop through the newly updated values.
                    // Get the memory location of the value to modify.
                    const bookValues = this.fimenu.vehicle.bookValues;
                    const bookValueToUpdate = bookValues.find((x) => x.source == bv.source);
                    
                    // If the value exists; modify its content.
                    if (bookValueToUpdate) {
                        bookValueToUpdate.retail = bv.retail;
                        bookValueToUpdate.wholesale = bv.wholesale;
                    } else {
                        // If we don't find it. Lets create it?
                        bookValues.push(new VehicleBookValue(bv))
                    }
                })
                
                if(!currentBO) return this.loadData();
                
                if(hasMSRPChanged) this.reset();
            },  
            hasMSRPChanged(newBookValues = []) {
                // Extract the current and new MSRP values
                const currentMSRP = this.fimenu.vehicle.bookValues.find(x => x.source === BookValue.Manufacturer)?.retail;
                const newMSRP = newBookValues.find(x => x.source === BookValue.Manufacturer)?.retail;


                if (!newMSRP) return false;

                // If both are undefined or equal, return early to avoid resetting unnecessarily
                if (currentMSRP === newMSRP) return false;

                // Otherwise, trigger reset logic
                return true;
            },
            handleAddBookValue(itemInfo) {
                switch (itemInfo.row) {
                    case "Vehicle Values":
                        // Opens a modal to create a book value for the specified vehicle.
                        createBookValue(this.fimenu.vehicle.bookValues, this.fimenu.inventoryType, this.handleAddBookValuePrePostFunction)

                        break;
                    default:
                        break;
                }
            },
            handleEditBookValue(itemInfo) {
                switch (itemInfo.row) {
                    case "Vehicle Values":
                        // Opens a modal to create a book value for the specified vehicle.
                        editBookValue(this.fimenu.vehicle.bookValues, this.fimenu.inventoryType, [itemInfo.item.source], true, this.handleAddBookValuePrePostFunction)

                        break;
                    default:
                        break;
                }
            },
            async addEditItem(itemInfo) {
                let store = this.fimenu.store
                //update fimenu store with overrides
                if (!itemInfo.item) {
                    this.isBusyAddEdit = true
                    let response = await api.settings.getStore(this.fimenu.storeCode, JSON.stringify({ fimenu: this.fimenu.getSanitizedData() }))
                    store = response.data
                    this.isBusyAddEdit = false
                }

                //FIND CORREPSONDING FEE
                if (itemInfo.bucketType.toLowerCase() == 'fees') {
                    $modal.open(modalAddEditFee, {
                        name: 'modalAddEditFee',
                        backdrop: false,
                        bo: this.bo,
                        storeFees: store.defaultFees,
                        itemInfo,
                        isDealLocked: this.fimenu.isDealLocked(),
                        postFunction: (updatedBO) => {
                            this.fimenu.upsertBuyersOrder(updatedBO);
                            this.loadData(this.fimenu.isDealLocked());
                            this.saveLockedDeal();
                        },
                        isModalDisabled: this.fimenu.isDealPassedFinance() || this.fimenu.isSpectator,
                    });
                }

                if (itemInfo.bucketType.toLowerCase() == 'additions') {
                    $modal.open(modalAddEditAddition, {
                        name: 'modalAddEditAddition',
                        backdrop: false,
                        bo: this.bo,
                        storeAdditions: store.additions,
                        itemInfo,
                        isDealLocked: this.fimenu.isDealLocked(),
                        postFunction: (updatedBO) => {
                            this.fimenu.upsertBuyersOrder(updatedBO);
                            this.loadData(this.fimenu.isDealLocked());
                            this.saveLockedDeal();
                        },
                        isModalDisabled: this.fimenu.isDealPassedFinance(),
                    });
                }

                if (itemInfo.bucketType.toLowerCase() == 'products') {
                    $modal.open(modalAddEditPreloads, {
                        name: 'modalAddEditPreloads',
                        backdrop: false,
                        bo: this.bo,
                        fimenu: this.fimenu,
                        itemInfo: itemInfo
                    });
                }
            },
            addEditTaxLine(taxLine) {
                $modal.open(modalAddEditTaxLine, {
                    name: 'modalAddEditTaxLine',
                    backdrop: false,
                    bo: this.bo,
                    taxLine: taxLine,
                    postFunction: (updatedBO) => {
                        // eslint-disable-next-line no-console
                        console.log('updatedBO', updatedBO);
                        this.fimenu.upsertBuyersOrder(updatedBO);
                        this.loadData();
                    },
                    isModalDisabled: this.fimenu.isDealLocked() || this.fimenu.isDealPassedFinance(),
                });

            },
            toggleTaxes() {
                this.taxesIsOpen = !this.taxesIsOpen;
            },
            toggleInceptions() {
                this.inceptionsIsOpen = !this.inceptionsIsOpen;
            },
            toggleMonthlyUseInfo() {
                this.monthlyUseInfoIsOpen = !this.monthlyUseInfoIsOpen;
            },
            dealTypeChanged() {
                this.fimenu.buyersOrders.forEach(bo => {
                    bo.isCurrentBuyersOrder = false
                })

                //this.loadData();
                this.fimenu.buyersOrderUpdateRequiredRightAway = true;

                this.EventBus.emit("FIMenu:DealTypeChanged")
            },
            async reset() {
                await BuyersOrderHelper.updateBuyersOrder(this.fimenu);
            },
            recalculateDebounced(recalcProfits = false) {
                if (!this.bo.isError()) {
                    let coverageTerm = this.fimenu.getSelectedCoverageTerm();
                    let dealTerm = this.fimenu.getSelectedDealTerm();

                    //FOR LEASE, SINCE NOW WE HAVE A BUYERS ORDER RIGHT NEXT TO TERMS
                    //WE USE THE FIRST DEAL TERM AS THE "SELECTED" DEAL TERM
                    if (this.fimenu.dealType == 'Lease' && !dealTerm) {
                        dealTerm = this.fimenu.getFirstDealTerm();
                    }

                    if (recalcProfits) {
                        this.bo.recalculateBuyersOrderProfits();
                    }
                    else {
                        this.bo.recalculateBuyersOrder(this.fimenu, dealTerm, coverageTerm, 'FINAL');

                        if (this.bo.isValid() && this.bo.areAllBucketsValid()) {
                            this.fimenu.otdBase = this.bo.acceptDeclineBuyersOrder?.finalNumbers?.total; // without preloads + doc stamps
                            this.fimenu.otd = this.bo.baseBuyersOrder?.finalNumbers?.total; // with preloads + doc stamps
                            this.fimenu.otdFinal = this.bo.finalNumbers.total;
                            this.EventBus.emit("recalculate")
                        }
                    }
                }
            },
            recalculate(recalcProfits = false, debounce = true) {
                if (debounce) {
                    util.debounce(this.recalculateDebounced, 500, recalcProfits)
                }
                else {
                    this.recalculateDebounced(recalcProfits)
                }
            },
            loadData(recalcProfits = false) {
                let currentBuyerOrder = this.fimenu.getCurrentBuyersOrder();

                if (currentBuyerOrder) {
                    currentBuyerOrder.isCurrentBuyersOrder = true;
                    this.bo = currentBuyerOrder;

                    if (!this.fimenu.isDealLocked() || recalcProfits) {
                        this.recalculate(recalcProfits, false);
                    }
                }
                else {

                    console.error('COULD NOT FIND BUYERS ORDER')
                    this.reset();
                }
            },
            saveLockedDeal(isInvalid = false) {
                if (!isInvalid && this.fimenu.isDealLocked()) {
                    this.fimenu.save();
                }
            },
        },
        validations() {
            return {
                bo: {
                    sellingPrice: {
                        required, minValue: minValue(0)
                    },
                    invoiceAmount: {
                        required, minValue: minValue(0)
                    },
                    errorCode: {
                        isValid: () => !this.bo.errorCode
                    }
                },
            }
        },
        components: {
            Panel,
            InputCurrency,
            InputRichDropdown,
            InputNumber,
            BuyersOrderBucket,
            IsBusySectionComponent,
            ButtonSubmitToFinanceInstitutions,
            BuyersOrderBookValues
        }

    };
</script>
<style>
    .panel-buyers-order {
        z-index: 0;
    }

        .panel-buyers-order .deal-type-dropdown {
            min-width: 100px;
        }

        .panel-buyers-order .panel-body .loading-saturn-anim.fade-in.IsBusySectionComponent-container {
            height: 100%;
            padding: 0;
        }

        .panel-buyers-order.panel {
            border-top: unset;
            height: 100%;
        }

        .panel-buyers-order > .panel-header {
            top: -5px;
            border-top: 1px solid var(--border-color);
        }

    .buyers-order-row {
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .buyers-order-grid .no-content {
        align-items: center;
        grid-template-columns: 1fr;
        padding: 10px 0;
    }

    .buyers-order-grid .thick-border-bottom,
    .buyers-order-grid .thick-border-top {
        border-color: var(--main-color);
        border-style: solid;
    }

    .buyers-order-grid .thick-border-bottom {
        border-width: 0 0 2px 0;
        margin-bottom: 10px !important;
        margin-top: -10px;
    }

    .buyers-order-grid .thick-border-top {
        border-width: 2px 0 0 0;
        margin-top: 10px !important;
    }

    .buyers-order-row.total-values {
        border-top: 1px solid var(--panelbuyersorder-grid-color-border);
        font-weight: 600;
        background-color: var(--panelbuyersorder-grid-background-color);
        margin: 0 -10px -10px;
        padding: 12px 10px 12px 10px;
    }

    .buyers-order-row > .pad-left {
        padding-left: 40px;
    }

    .buyers-order-row.subtotal, .buyers-order-row.subtotal input {
        font-weight: bold;
    }

    .buyers-order-row.subtotal {
        border-top: 2px solid #000;
        border-bottom: 2px solid #000;
        margin-bottom: -2px;
    }

    .buyers-order-row.preloads-list {
        border: none;
    }

    .buyers-order-row.product-list {
        border: none;
    }

    .buyers-order-row .preloaded-question {
        padding-right: 5px;
    }

    .taxable-items {
        background-color: var(--secondary-color);
        position: relative;
        margin: 0px -10px;
        padding: 0px 10px;
    }

    .taxable-content {
        position: relative;
        z-index: 100;
    }

    .taxable-indicator {
        position: absolute;
        /*width: 100%;
        height: 100%;*/
        top: 50%;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotateZ(90deg);
        font-size: 30px;
        color: var(--black-20percent);
        z-index: 200;
        pointer-events: none;
        margin-left: -50px;
    }

    .buyers-order-error {
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .buyers-order-error-title {
        font-size: 35px;
        font-weight: bold;
        color: var(--error-color);
    }

    .buyers-order-error-subtitle {
        font-size: 15px;
        color: var(--black-70percent);
    }

    .buyers-order-error-code {
        margin-top: 30px;
        font-weight: bold;
        color: var(--error-color);
    }

    .buyers-order-error-message {
        color: var(--error-color);
    }

    .buyers-order-taxable-row {
        background-color: var(--secondary-color);
        margin: 0px -10px;
        padding: 4px 10px;
    }

    .buyers-order-taxable-include-selling-price {
        margin-top: -40px;
        margin-left: -200px;
    }

    .new-tax-line {
        margin-left: 10px;
    }

    .panel-buttons .button-edit {
        display: flex;
        flex: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: 25px;
        padding: 0px 10px;
        width: 100%;
    }

    .panel-buyers-order .panel-buttons .InputDropdown {
        width: 120px;
    }

    .new-tax-line button.button.button-edit {
        height: 25px;
        padding: 0px 10px;
    }

    .buyers-order-error-actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 315px;
    }

    .bo-transaction-id {
        margin-right: 10px;
        min-width: 200px;
        text-align: end;
    }

    .buyers-order-column-headers {
        font-weight: bold;
        text-align: right;
        margin-top: 15px;
    }

    span.subtotal-container, .subtotal-container .span, .subtotal-container input {
        font-weight: bold;
        color: #000;
    }

    .bo-placeholder {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        color: var(--error-color)
    }

    .panel-buyers-order .flex-side-by-side {
        gap: 5px !important;
    }
</style>