<template>
    <Panel title="Deal Checklist" class="panel-deal-checklist ignore-deal-lock">
        <template #HeaderComponent>
            <button class="button" :disabled="isLoading || isPolling" @click="init">
                <i :class="{'fas fa-sync': true, 'fa-spin': isPolling}" /> Refresh
            </button>

            <!-- TODO: Should link to the checklist page on customer portal -->
            <!-- <DropdownButton label="Send Checklist Link"
                :actions="sendLinkActions"
                :disabled="fimenu.isSpectator || isLoading"
            /> -->
        </template>

        <IsBusySectionComponent v-show="isLoading" style="height:unset" />

        <section v-show="!isLoading" class="all-checklist-items">
            <!-- TODO: Temporarily hide until checklist integration is completed -->
            <!-- <div class="checklist-header">
                <div class="checklist-summary">
                    <button v-for="(person, index) in dealParticipants"
                            :key="'progress-circle-' + index"
                            :class="{'button-span': true, 'ignore-all-locks': true, 'active-person': activePerson === person.role}"
                            @click="toggleActivePerson(person.role)"
                    >
                        <ProgressCircle :class="{'all-tasks-circle': (person.role === ENUMS.PERSON_TYPES.UNKNOWN)}"
                            :label="person.name"
                            :subtitle="person.roleDisplay"
                            :isLoading="isLoading"
                            :numComplete="person.tasksCompleted"
                            :total="person.allTasks.length"
                        />
                    </button>
                </div>

                <button class="add-button" :disabled="fimenu.isSpectator" @click="openAddEditChecklistModal(null, true)">
                    <i class="fas fa-plus" />
                </button>
            </div> -->

            <RichTable :tableData="activeChecklist"
                :headers="TABLE_HEADERS"
                :isLoading="isLoading"
                noData="No pending checklist items."
            >
                <template #checkmark="row">
                    <i v-if="row._ref.isPlaidPending()" class="fas fa-question" />
                    <i v-else :class="['fas', (isItemVerified(row)) ? 'fa-check' : 'fa-times']" />
                    <span>{{ getStatusText(row) }}</span>
                </template>

                <template #sendTo="row">
                    <span v-if="row.sendTo.length < 1">{{ getParticipantByType(ENUMS.PERSON_TYPES.FIMANAGER) }}</span>
                    <template v-else>
                        <span v-for="personType in row.sendTo" :key="personType">
                            {{ getParticipantByType(personType) }}
                        </span>
                    </template>
                </template>

                <template #itemActions="row">
                    <button v-if="BitFlagHelper.hasIntersection(SaturnPermissions, row.needsVerifyBy, USER_PERMISSIONS)"
                        class="button-accept"
                        :disabled="!row.status.document || fimenu.isSpectator || row.status.isManuallyRejected"
                        @click="openChecklistReviewModal(row._ref)"
                    >
                        {{ getVerifyButtonText(row._ref) }}
                    </button>

                    <button v-else-if="row._ref.canResetPlaidIDV()" 
                        class="button-load"
                        :disabled="fimenu.isSpectator"
                        @click="resetPlaidIDV(row._ref)"
                    >
                        Reset Session
                    </button>

                    <span v-else />
                </template>

                <template #editActions="row">
                    <div v-if="row.isCustomItem || canDisplayResults(row._ref)" style="white-space: nowrap;">
                        <button v-show="canDisplayResults(row._ref)" 
                            class="button-edit button-info"
                            @click="openDisplayChecklistResultsModal(row._ref)"
                        >
                            <i class="fas fa-eye" />
                        </button>

                        <button v-show="row.isCustomItem"
                            class="button-edit"
                            :disabled="fimenu.isSpectator"
                            @click="openAddEditChecklistModal(row._ref, false)"
                        >
                            <i class="fas fa-pencil-alt" />
                        </button>

                        <button v-show="row.isCustomItem"
                            class="button-delete"
                            :disabled="fimenu.isSpectator"
                            @click="onDeleteCustomItem(row)"
                        >
                            <i class="fas fa-trash-alt" />
                        </button>
                    </div>

                    <span v-else />
                </template>
            </RichTable>
        </section>
    </Panel>
</template>

<script lang="ts">
    export interface IPanelDealChecklistProps {
        fimenu: FIMenu;
        disablePolling?: boolean;
    }

    export interface IDealParticipantTaskInfo {
        name: string;
        role: number; // ENUMS.PERSON_TYPES
        roleDisplay: string;
        allTasks: DealChecklistItem[];
        tasksCompleted: number;
    }
</script>
<script setup lang="ts">
    import { 
        computed,
        defineAsyncComponent,
        onBeforeMount,
        onBeforeUnmount,
        ref,
        toRefs, 
        watch 
    } from 'vue';

    import $modal from "@core/services/modal";
    import api from "@core/services/api";
    import auth from '@core/services/auth';
    import { BitFlagHelper } from '@core/helpers/enum-helper';
    import ChecklistHelper from '@/helpers/checklist-helper';
    import util from "@core/services/util";

    import { EmployeePermissions, SaturnPermissions } from '@core/classes/AccessEmployeeClass';
    import DealChecklistItem from "@core/classes/Checklist/DealChecklistItem";
    import { DealChecklistType } from '@core/classes/SharedEnums';
    import ENUMS from "@core/classes/Enums";
    import FIMenu from "@core/classes/FIMenu";
    import { IChecklistAddCustomRequest } from '@core/classes/Checklist/ChecklistRequests';
    import SettingsChecklistItem from '@core/classes/Checklist/SettingsChecklistItem';

    //import DropdownButton from "@/components/DropdownButton.vue";
    import IsBusySectionComponent from "@core/components/IsBusySectionComponent.vue";
    import Panel from "@core/components/Panel.vue";
    import ProgressCircle from "@/components/ProgressCircle.vue";
    import RichTable from "@core/components/RichTable.vue";
    
    import modalAddEditChecklistItemLocal, { IModalAddEditChecklistItemLocalOnClose } from "@/modals/modalAddEditChecklistItemLocal.vue";
    import modalDealChecklistReview from "@/modals/modalDealChecklistReview.vue";
    import modalDocumentPreview from '@core/modals/modalDocumentPreview.vue';
    import modalDynamicComponent from '@/modals/modalDynamicComponent.vue';
    import modalInfo from "@core/modals/modalInfo.vue";
    import modalPlaidIDVSessionHistory from '@/modals/modalPlaidIDVSessionHistory.vue';
    import modalPre from '@/modals/modalPre.vue';

    const props = defineProps<IPanelDealChecklistProps>();
    const { fimenu } = toRefs(props);

    const emit = defineEmits<{ 
        loading: [currValue: boolean],
        checklistUpdate: [currValue: DealChecklistItem[]] 
    }>();

    // =========
    // CONSTANTS
    // =========
    // #region Constants
    const TABLE_HEADERS = [
        {
            name: 'status',
            display: 'Status',
            sortable: true,
            value: (row: { _ref: DealChecklistItem }) => isItemVerified(row),
            sortValue: (row: { _ref: DealChecklistItem }) => isItemVerified(row),
            slot: 'checkmark',
            cellCssClass: (row: { _ref: DealChecklistItem }) => {
                return isItemVerified(row)
                    ? 'verified'
                    : row._ref.isPlaidPending() ? 'pending' : 'not-verified';
            }
        },
        {
            name: 'sendTo',
            display: 'Who\'s Responsible?',
            slot: 'sendTo',
            sortable: true,
            cellCssClass: 'sendTo-cell',
            value: (row: { _ref: DealChecklistItem }) => {
                if (row._ref.sendTo.length < 1) return ['NO ONE SELECTED'];

                return row._ref.sendTo.map(p => {
                    const key = util.getObjectKeyFromValue(ENUMS.PERSON_TYPES, p);

                    switch (p) {
                        case ENUMS.PERSON_TYPES.CUSTOMER: 
                            return fimenu.value.customer.fullName ?? key;
                        case ENUMS.PERSON_TYPES.COCUSTOMER: 
                            return fimenu.value.coCustomer.fullName ?? key;
                        default: 
                            return key;
                    }
                });
            }
        },
        {
            name: 'name',
            display: 'Name',
            sortable: true
        },
        {
            name: 'type',
            display: 'Type',
            sortable: true,
            value: (row: { _ref: DealChecklistItem }) => {
                const key = util.getObjectKeyFromValue(DealChecklistType, row._ref.type);
                return util.formatEnumKey(key);
            }
        },
        {
            name: 'action',
            display: '',
            slot: 'itemActions'
        },
        {
            name: 'editActions',
            display: '',
            cellCssClass: 'checklist-edit-actions',
            slot: 'editActions'
        }
    ];

    const USER = auth.getTokenPayload();
    const USER_ACCESS = EmployeePermissions.createFromPascalKeys(USER.EmployeeAccess);
    const USER_PERMISSIONS = USER_ACCESS.toFlags();
    // #endregion

    // =============
    // GENERAL STATE
    // =============
    // #region General State
    const isLoading = ref(false);

    watch(isLoading, (newValue) => {
        emit('loading', newValue);
    });


    const activePerson = ref(ENUMS.PERSON_TYPES.UNKNOWN);

    const toggleActivePerson = (personType: number) => {
        activePerson.value = (activePerson.value === personType) ? ENUMS.PERSON_TYPES.UNKNOWN : personType;
    };


    const settingsChecklist = ref<SettingsChecklistItem[]>([]);


    const checklist = ref<DealChecklistItem[]>([]);

    watch(checklist, (newValue) => {
        emit('checklistUpdate', newValue);
    });
    // #endregion

    // =================
    // DEAL PARTICIPANTS
    // =================
    // #region Deal Participants
    const dealParticipants = computed((): IDealParticipantTaskInfo[] => {
        const tasksCompleted = checklist.value.filter(c => {
            return (c.requiresManualVerification() && c.hasAnyManualVerifications()) 
                ? c.isTaskCompleteForUser(USER_PERMISSIONS)
                : c.isVerified();
        }).length;

        const dealParticipants: IDealParticipantTaskInfo[] = [
            {
                name: 'All Tasks',
                role: ENUMS.PERSON_TYPES.UNKNOWN,
                roleDisplay: 'All Tasks',
                allTasks: checklist.value,
                tasksCompleted: tasksCompleted
            }
        ];

        const customerTasks = checklist.value.filter(c => c.canSendTo(ENUMS.PERSON_TYPES.CUSTOMER));
        if (customerTasks.length > 0) {
            const customerTasksCompleted = customerTasks.filter(c => c.isTaskCompleteForCustomer()).length;

            dealParticipants.push({
                name: fimenu.value.customer.fullName?.toLowerCase() ?? 'Customer',
                role: ENUMS.PERSON_TYPES.CUSTOMER,
                roleDisplay: 'Customer',
                allTasks: customerTasks,
                tasksCompleted: customerTasksCompleted
            });
        }

        const coCustomerTasks = checklist.value.filter(c => c.canSendTo(ENUMS.PERSON_TYPES.COCUSTOMER));
        if (coCustomerTasks.length > 0 && fimenu.value.coCustomer && fimenu.value.coCustomer.cell) {
            const coCustomerTasksCompleted = coCustomerTasks.filter(c => c.isTaskCompleteForCustomer()).length;

            dealParticipants.push({
                name: fimenu.value.coCustomer.fullName?.toLowerCase() ?? 'Co-Customer',
                role: ENUMS.PERSON_TYPES.COCUSTOMER,
                roleDisplay: 'Co-Customer',
                allTasks: coCustomerTasks,
                tasksCompleted: coCustomerTasksCompleted
            });
        }

        // Only returns tasks that FI Manager can actively act on.
        const fiManagerTasks = checklist.value.filter(c => {
            return c.isPendingVerification(USER_PERMISSIONS)
                && !c.status.isManuallyRejected;
        });

        let employeeName = USER.EmployeeName?.toLowerCase() ?? 'Your Tasks';
        if (employeeName.indexOf(', ') !== -1) {
            const splitName = employeeName.split(', ');
            employeeName = `${splitName[1]} ${splitName[0]}`;
        }

        dealParticipants.push({
            name: employeeName,
            role: ENUMS.PERSON_TYPES.FIMANAGER,
            roleDisplay: 'Your Tasks',
            allTasks: fiManagerTasks,
            tasksCompleted: fiManagerTasks.filter(c => c.isTaskCompleteForUser(USER_PERMISSIONS)).length
        });

        return dealParticipants;
    });

    const activeChecklist = computed(() => {
        const participant = dealParticipants.value.find(p => p.role === activePerson.value);
        if (!participant) return checklist.value;

        return participant.allTasks;
    });

    const sendLinkActions = computed((): { label: string, role: number, click: () => void }[] => {
        const filteredParticipants = dealParticipants.value.filter(p => p.role !== ENUMS.PERSON_TYPES.FIMANAGER && p.role !== ENUMS.PERSON_TYPES.UNKNOWN);
        if (!filteredParticipants || filteredParticipants.length < 1) return [];

        return filteredParticipants.map(p => {
            return {
                label: p.name,
                role: p.role,
                click: () => sendChecklistLink(p.role)
            };
        });
    });

    const getParticipantByType = (type: number) => {
        const participant = dealParticipants.value.find(p => p.role === type);
        return participant?.name ?? util.getObjectKeyFromValue(ENUMS.PERSON_TYPES, type);
    };
    // #endregion

    // =======
    // POLLING
    // =======
    // #region Polling
    const isPolling = ref(false);
    const pollingTimeout = ref<NodeJS.Timeout>(null);
    const pollingCounter = ref(0);

    const canStopPolling = computed(() => {
        if (!dealParticipants.value || dealParticipants.value.length < 1) return true;

        const allTasksList = dealParticipants.value.find(p => p.role === ENUMS.PERSON_TYPES.UNKNOWN);
        return allTasksList.tasksCompleted === allTasksList.allTasks.length;
    });

    watch(canStopPolling, () => {
        startChecklistPolling();
    });

    const startChecklistPolling = () => {
        clearTimeout(pollingTimeout.value);
        if (canStopPolling.value || props.disablePolling) return;

        // As the counter goes up, increase polling delay exponentially
        const pollingDelay = 100 * Math.pow(2, pollingCounter.value);
        pollingTimeout.value = setTimeout(pollForChecklistUpdates, pollingDelay);
    };

    const pollForChecklistUpdates = async () => {
        isPolling.value = true;

        try {
            const { data: checkList } = await api.checklist.fimenu_getAllRequired(fimenu.value.id);

            const updatedChecklist: DealChecklistItem[] = checkList.map((c: Partial<DealChecklistItem>) => new DealChecklistItem(c));
            const anyChanges = checklist.value.some(oldC => {
                const newItem = updatedChecklist.find(newC => newC.id === oldC.id);

                if (!newItem) return true; // A new item was added
                return oldC.anyStatusChanges(newItem);
            });

            if (anyChanges) {
                pollingCounter.value = 0;
                checklist.value = updatedChecklist;
            }
            else {
                pollingCounter.value++;
            }

            startChecklistPolling();
        }
        catch (error) {
            // No response from server. Stop polling.
            console.error('ERROR POLLING FOR CHECKLIST UPDATES: ', error);
            util.toastr("error", "Error While Polling", 'An error occurred while polling for updates.');
        }
        finally {
            isPolling.value = false;
        }
    };
    // #endregion

    // ===============
    // LIFECYCLE HOOKS
    // ===============
    // #region Lifecycle Hooks
    onBeforeMount(() => {
        init();
    });

    onBeforeUnmount(() => {
        clearTimeout(pollingTimeout.value);
    });
    // #endregion

    // ===============
    // INITIALIZE DATA
    // ===============
    // #region Initialize Data
    const init = async () => {
        clearTimeout(pollingTimeout.value);
        isPolling.value = false;
        isLoading.value = true;

        try {
            const apiCallsToMake = [
                Promise.resolve(getSettingsCheckList()),
                Promise.resolve(getFIMenuChecklist())
            ];

            if (!props.disablePolling)
                apiCallsToMake.push(Promise.resolve(startChecklistPolling()));
            
            pollingCounter.value = 0;
            await Promise.allSettled(apiCallsToMake);
        }
        catch (err) {
            console.error('ERROR INITING DEAL CHECKLIST PANEL', err);
            util.toastr('error', 'Error Fetching Checklist Data', 'An error occurred when trying to fetch the checklist data.');
        }
        finally {
            isLoading.value = false;
        }
    };

    const getSettingsCheckList = async () => {
        try {
            const { data: checkList } = await api.checklist.config_getAllItems();
            settingsChecklist.value = checkList;
        } 
        catch (error) {
            console.error("ERROR FETCHING SETTINGS CHECKLIST:", error);
            util.toastr("error", "Retrieving Settings Checklist", error);
        }
    };

    const getFIMenuChecklist = async () => {
        try {
            const { data: checkList } = await api.checklist.fimenu_getAllRequired(fimenu.value.id);
            checklist.value = checkList.map((i: Partial<DealChecklistItem>) => new DealChecklistItem(i));
        }
        catch (error) {
            console.error("ERROR FETCHING FIMENU CHECKLIST:", error);
            util.toastr("error", "Retrieving FiMenu Checklist", error);
        }
    };
    // #endregion

    // ================
    // MODAL MANAGEMENT
    // ================
    // #region Modal Management
    const openDisplayChecklistResultsModal = (checklistItem: DealChecklistItem) => {
        if (checklistItem.isTypeUpload() && !!checklistItem.getDocument()) {
            $modal.open(modalDocumentPreview, {
                passedData: {
                    document: checklistItem.getDocument(),
                    title: checklistItem.name
                }
            });
        }
        else if (checklistItem.type === DealChecklistType.LaunchPlaid_IDV && !!checklistItem?.getPlaidData()?.identityVerification?.response) {
            $modal.open(modalPlaidIDVSessionHistory, {
                passedData: {
                    idvSessionList: [checklistItem.getPlaidData().identityVerification.response]
                }
            })
        }
        else if (checklistItem.type === DealChecklistType.LaunchPlaid_PayrollIncome && !!checklistItem?.getPlaidData()?.payrollIncome?.response) {
            const customerId = (checklistItem.canSendTo(ENUMS.PERSON_TYPES.COCUSTOMER)) ? props.fimenu.coCustomer.id : props.fimenu.customer.id;

            $modal.open(modalDynamicComponent, {
                passedData: {
                    title: checklistItem.name, 
                    component: defineAsyncComponent(() => import('@/components/PlaidPayrollSummaryDisplay.vue')),
                    componentProps: {
                        customerId,
                        payrollResponse: checklistItem.getPlaidData().payrollIncome.response
                    }
                }
            });
        }
        else if (checklistItem.isTypePlaid() && !!checklistItem?.getPlaidData()) {
            $modal.open(modalPre, {
                passedData: {
                    title: checklistItem.name, 
                    data: checklistItem.getPlaidData()
                }
            });
        }
        else {
            // Unhandled case. 
            throw new Error('Invalid checklist item. This item is not eligible to display results.');
        }
    };

    const openChecklistReviewModal = (checklistItem: DealChecklistItem) => {
        $modal.open(modalDealChecklistReview, {
            name: 'modalDealChecklistReview',
            passedData: {
                checklistItem: checklistItem
            },
            backdrop: false,
            postFunction: async (isVerified: boolean) => {
                if (isVerified)
                    await init();
            }
        });
    };

    const openAddEditChecklistModal = (checklistItem: DealChecklistItem, isAddAction: boolean) => {
        // Remove any settings checklist items that are already required on this deal
        const availableSettingsChecklist = settingsChecklist.value.filter(s => {
            const alreadyRequired = checklist.value.some(c => c.id === s.id);
            return !alreadyRequired;
        });

        let preBuiltUsed = true;
        if (!isAddAction && checklistItem) {
            preBuiltUsed = settingsChecklist.value.some(ac => ac.id === checklistItem.id);
        }

        const customSubtypes: string[] = [];
        checklist.value.forEach(c => {
            if (c.subtype && c.subtype.indexOf('CUSTOM') !== -1)
                customSubtypes.push(c.subtype);
        });

        $modal.open(modalAddEditChecklistItemLocal, {
            name: 'modalAddEditChecklistItemLocal',
            passedData: {
                item: checklistItem?.toSettingsChecklistItem(),
                preBuiltUsed: preBuiltUsed,
                isAddAction: isAddAction,
                preBuiltItems: availableSettingsChecklist,
                customSubtypes: customSubtypes,
            },
            backdrop: false,
            postFunction: onAddEditCustomItem,
        });
    };

    const onAddEditCustomItem = async (addEditResult: IModalAddEditChecklistItemLocalOnClose) => {
        isLoading.value = true;

        try {
            const checklistItem = addEditResult.item;
            const isAddAction = addEditResult.isAddAction;
            const usedPreBuilt = addEditResult.preBuiltUsed;

            const request: IChecklistAddCustomRequest = {
                dealId: fimenu.value.id,
                id: checklistItem.id,
                name: checklistItem.name,
                description: checklistItem.description,
                sendTo: checklistItem.sendTo,
                type: checklistItem.type,
                subtype: checklistItem.subtype,
                associations: checklistItem.associations,
                searchForExisting: false
            }

            if (isAddAction) 
                request.searchForExisting = usedPreBuilt;

            const response = (isAddAction)
                ? await api.checklist.fimenu_addCustomItem(request)
                : await api.checklist.fimenu_editCustomItem(request);

            if (!response?.data) {
                util.toastr('error', 'Error', `Unable to ${isAddAction ? 'add' : 'update'} custom item.`);
            }
            else {
                util.toastr('success', 'Success', `Successfully ${isAddAction ? 'added' : 'updated'} custom item.`);
                await init();
            }
        }
        catch (err) {
            console.error('ERROR UPLOADING CHECKLIST ITEM: ', err);
            util.toastr('error', 'Error Uploading Checklist Item', 'A server error occurred when trying to save the checklist item.');
        }
        finally {
            isLoading.value = false;
        }
    };

    const onDeleteCustomItem = async (checklistItem: DealChecklistItem) => {
        $modal.open(modalInfo, {
            name: 'modalInfo',
            passedData: {
                title: 'Delete Checklist Item',
                info: `Are you sure you want to delete custom checklist item "${checklistItem.name}"?`,
                acceptText: 'Delete',
                acceptTextClass: 'button-unwind',
                cancelText: 'Cancel',
                cancelTextClass: 'button'
            },
            backdrop: false,
            postFunction: async () => {
                isLoading.value = true;
                try {
                    const response = await api.checklist.fimenu_deleteCustomItem(fimenu.value.id, checklistItem.id);
                    if (!response?.data)
                        throw new Error('[NO RESPONSE FROM SERVER]', { cause: response });

                    util.toastr('success', 'Success', 'Successfully removed custom checklist item.');
                    await init();
                }
                catch (err) {
                    console.error('ERROR DELETING CHECKLIST ITEM: ', err);
                    util.toastr('error', 'Error Deleting Checklist Item', 'Unable to delete the checklist item due to server error.');
                }
                finally {
                    isLoading.value = false;
                }
            }
        });
    };
    // #endregion

    // =====================
    // RICHTABLE ROW HELPERS
    // =====================
    // #region RichTable Row Helpers
    const getStatusText = (rowItem: { _ref: DealChecklistItem }) => {
        const checklistItem = rowItem._ref;
        if (!checklistItem) return '';

        if (activePerson.value === ENUMS.PERSON_TYPES.FIMANAGER || activePerson.value === ENUMS.PERSON_TYPES.UNKNOWN)
            return ' ' + checklistItem.getStatusText(USER_PERMISSIONS);

        // If Customer Checklist is being shown
        return ' ' + checklistItem.getCustomerStatusText();
    };

    const isItemVerified = (rowItem: { _ref: DealChecklistItem }) => {
        const checklistItem = rowItem._ref;
        if (!checklistItem) return false;

        // If we're looking at the "All Tasks" tab
        if (activePerson.value === ENUMS.PERSON_TYPES.UNKNOWN) {
            // To prevent confusion, only show information on whether THIS user still needs to submit a manual verification if applicable.
            return (checklistItem.requiresManualVerification() && checklistItem.hasAnyManualVerifications())
                ? checklistItem.isTaskCompleteForUser(USER_PERMISSIONS)
                : checklistItem.isVerified();
        }

        // If we're looking at the "Your Tasks" tab
        if (activePerson.value === ENUMS.PERSON_TYPES.FIMANAGER)
            return checklistItem.isTaskCompleteForUser(USER_PERMISSIONS);

        // Assume this is a customer's tab
        return checklistItem.isTaskCompleteForCustomer();
    };

    const resetPlaidIDV = async (checklistItem: DealChecklistItem) => {
        await ChecklistHelper.resetPlaidIDV(
            checklistItem, 
            fimenu.value.storeCode, 
            (busyValue: boolean) => { isLoading.value = busyValue }
        );
    };

    const canDisplayResults = (checklistItem: DealChecklistItem): boolean => {
        return !!checklistItem.status.document || !!checklistItem.status.plaidData;
    }
    // #endregion

    const sendChecklistLink = async (forWhom: number) => {
        ChecklistHelper.sendLinkToCustomer(fimenu.value, forWhom, (busyValue: boolean) => { isLoading.value = busyValue });
    };

    const getVerifyButtonText = (checklistItem: DealChecklistItem): string => {
        if (checklistItem.status.isManuallyRejected)
            return 'Requires Reupload';

        return (BitFlagHelper.contains(checklistItem.status.manuallyVerifiedBy, USER_PERMISSIONS)) ? 'Re-Verify' : 'Verify';
    }
</script>
<style>
    .panel-deal-checklist .panel-header .header-component {
        display: flex;
        align-items: center;
        gap: 10px;
    }

        .panel-deal-checklist .panel-header .header-component > button {
            flex: 1;
        }

        .panel-deal-checklist .panel-header .header-component .button-dropdown-container button {
            text-transform: capitalize;
        }

    .panel-deal-checklist .checklist-summary .active-person {
        position: relative;
        border-radius: 10px 10px 0 0;
        border-bottom: 2px solid var(--main-color);
        background-color: var(--card-background-color);
        box-shadow: -2px -3px 4px 1px rgba(0, 0, 0, 0.25);
        transition: all 0.3s ease-in-out;
        z-index: -1;
    }

        .panel-deal-checklist .checklist-summary .progress-circle-container:hover,
        .panel-deal-checklist .checklist-summary .active-person .progress-circle-container {
            color: var(--main-color);
        }

    .panel-deal-checklist .checklist-summary .progress-circle-container.all-tasks-circle label small {
        visibility: hidden;
    }

    .panel-deal-checklist .verified {
        color: var(--success-color);
    }

    .panel-deal-checklist .not-verified {
        color: var(--error-color);
    }

    .panel-deal-checklist .pending {
        color: var(--print-color);
    }

    .panel-deal-checklist .all-checklist-items .checklist-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        border-bottom: 2px solid var(--main-color);
    }

        .panel-deal-checklist .all-checklist-items .checklist-header .add-button {
            width: unset;
            border-radius: var(--default-border-radius) var(--default-border-radius) 0 0;
            box-shadow: 2px 0 4px 1px var(--black-20percent);
        }

    .panel-deal-checklist .checklist-edit-actions button {
        margin: 0 5px;
        width: unset;
    }

    .panel-deal-checklist .rich-table .sendTo-cell > div > span {
        display: block;
    }
</style>