<template>
    <Panel v-bind="panelObject" class="coverage-term">
        <div class="coverage-grid">
            <div></div>
            <div></div>
            <div></div>
            <div v-for="(coverageTerm, index) in data.coverageTerms" :key="coverageTerm.term">
                <div v-if="!data.isLease() && (coverageTerm.selected || coverageTerm.preferred)" :class="{'selected-coverage-column': coverageTerm.selected, 'preferred-coverage-column': coverageTerm.preferred}" :style="moveSelectedCoverageColumn(index)"></div>
                <div v-if="data.isLease() && (coverageTerm.selected || showPreferedLease(coverageTerm))" :class="{'selected-coverage-column': coverageTerm.selected, 'preferred-coverage-column': showPreferedLease(coverageTerm)}" :style="moveSelectedCoverageColumn(index)"></div>
                <div class="coverage-terms-header-select">
                    <fieldset v-if="$global.isAdminView" class="admin-actions" :disabled="!hasSelectedDealTerm && !data.isCash()">
                        <button title="Select preferred" @click="data.selectPreferredCoverageTerm(coverageTerm.term)" :disabled="data.isLease() || coverageTerm.isCustom()">
                            <i class="fas fa-check-circle"></i>
                        </button>
                        <button title="Select coverage" @click="selectCTAsAdmin(coverageTerm.term)" class="button-save">
                            <i class="fas fa-check-circle"></i>
                        </button>
                    </fieldset>
                    <div v-if="!coverageTerm.isCustom()" class="term-min-max-wrapper">
                        <div class="term-min-max-group">
                            <TooltipComponent direction="top"
                                              :tooltip="['Lower all program types and set all prices to minimum']">
                                <template #showFromOutside>
                                    <button class="button-error term-min-max-left" @click="() => setTermProgramAndPrices(index, 'min')" :disabled="data.isDealLocked() || !isEditCoverageEnabled() || !hasTermPlanMetaData(index, 'min')">
                                        <i class="fas fa-angle-double-down"></i>
                                    </button>
                                </template>
                            </TooltipComponent>
                            <TooltipComponent direction="top"
                                              :tooltip="['Set all prices to minimum']">
                                <template #showFromOutside>
                                    <button class="button-error term-min-max-right" @click="() => setTermPrices(index, 'min')" :disabled="data.isDealLocked() || !isEditCoverageEnabled()">
                                        <i class="fas fa-angle-down"></i>
                                    </button>
                                </template>
                            </TooltipComponent>
                        </div>
                        <div class="term-min-max-group">
                            <TooltipComponent direction="top"
                                              :tooltip="['Set all prices to maximum']">
                                <template #showFromOutside>
                                    <button class="button-success term-min-max-left" @click="() => setTermPrices(index, 'max')" :disabled="data.isDealLocked() || !isEditCoverageEnabled()">
                                        <i class="fas fa-angle-up"></i>
                                    </button>
                                </template>
                            </TooltipComponent>
                            <TooltipComponent direction="top"
                                              :tooltip="['Raise all program types and set all prices to maximum']">
                                <template #showFromOutside>
                                    <button class="button-success term-min-max-right" @click="() => setTermProgramAndPrices(index, 'max')" :disabled="data.isDealLocked() || !isEditCoverageEnabled() || !hasTermPlanMetaData(index, 'max')">
                                        <i class="fas fa-angle-double-up"></i>
                                    </button>
                                </template>
                            </TooltipComponent>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <fieldset>
            <div v-for="(pkg, index) in packages.filter(p => p.products.length > 0)" :key="index">
                <div class="coverage-grid coverage-grid-header">
                    <button class="ignore-all-locks button-span header-collapse-button" @click="pkg.selected = !pkg.selected">
                        <i :class="['far', (pkg.selected) ? 'fa-minus-square' : 'fa-plus-square']" />
                    </button>
                    <span class="grid-2-column">{{ pkg.name }}</span>
                    <div v-for="coverageTerm in data.coverageTerms" :key="coverageTerm.term" class="coveragetermprice">
                        <span :class="getPackageTotalClass(pkg.products, coverageTerm.isCustom(), coverageTerm.selected)">{{ getPackageTotal(index, coverageTerm.term, priceProfit) }}</span>
                    </div>
                </div>
                <div class="coverage-terms-overflow" v-for="product in pkg.products" :key="product.type">
                    <div v-show="pkg.selected"
                         v-if="isProductIncluded(product.type)" class="collapse-item">
                        <FIMenuProduct :fimenu="data"
                                       :pkgProduct="product"
                                       :isRegulated="isProductRegulated(product.type)"
                                       :priceProfit="priceProfit"
                                       :repriceDefaultProduct="repriceDefaultProduct"
                                       :repriceOtherProductsOfType="repriceOtherProductsOfType"
                                       :isTaxable="isProductTaxable(product.type)"
                                       :showRichTableModal="showRichTableModal"
                                       :isEditCoverageModalDisabled="isBusy || data.isRatingRequired() || pkg.isMisc || !isEditCoverageEnabled() || (!data.hasApiResponse(product.type) && product.type != ENUMS.PRODUCT_TYPES.LOJACK_GPS)"
                                       :openEditCoverageModal="editCoverageTermProductHandler"
                                       :isCustomCheckboxDisabled="isBusy"
                                       :displayClass="getDisplayClass"
                                       :subprogramList="subprogramList">
                        </FIMenuProduct>
                    </div>
                </div>
            </div>
        </fieldset>
    </Panel>
</template>

<script>
    import $modal from '@core/services/modal'
    import api from '@core/services/api'
    import CoverageTerm from '@core/classes/CoverageTerm'
    import CoverageTermInfo from '@core/classes/CoverageTermInfo';
    import CoverageTermProduct from '@core/classes/CoverageTermProduct'
    import ENUMS from "@core/classes/Enums"
    import FIMenuProduct from '@/components/fimenu/FIMenuProduct.vue'
    import modalEditCoverageTermProduct from '@/modals/modalEditCoverageTermProduct.vue'
    import modalInfo from '@core/modals/modalInfo.vue'
    import modalRichTable from '@/modals/modalRichTable.vue'
    import Panel from '@core/components/Panel.vue'
    import settings from 'settings'
    import TooltipComponent from '@core/components/TooltipComponent.vue'
    import util from '@core/services/util'

    export default {
        name: "PanelCoverageTerms",
        props: ['data', 'panelObject', 'validation'],
        data() {
            return {
                coverageActionOpen: null,
                subprogramList: [],
                isBusy: false,
                priceProfit: 0,
                packages: [
                    {
                        name: "Mechanical Failure Protection", selected: false,
                        products: [
                            { type: ENUMS.PRODUCT_TYPES.SERVICE_CONTRACT, product: 'service', productPricingDetailsId: util.getDefaultGuid(), coverages: [] },
                            { type: ENUMS.PRODUCT_TYPES.MAINTENANCE, product: 'maintenance', productPricingDetailsId: util.getDefaultGuid(), coverages: [] }
                        ]
                    },
                    {
                        name: "Future Resale Value Protection", selected: false,
                        products: [
                            { type: ENUMS.PRODUCT_TYPES.TITANIUM_BUNDLE, product: 'titanium', productPricingDetailsId: util.getDefaultGuid(), coverages: [] },
                            { type: ENUMS.PRODUCT_TYPES.APPEARANCE, product: 'paint', productPricingDetailsId: util.getDefaultGuid(), coverages: [] },
                            { type: ENUMS.PRODUCT_TYPES.EXCESS_WEAR_AND_TEAR, product: 'wear', productPricingDetailsId: util.getDefaultGuid(), coverages: [], exclude: !this.data.isLease() },
                        ]
                    },
                    {
                        name: "Keeping You On The Move", selected: false,
                        products: [
                            { type: ENUMS.PRODUCT_TYPES.TITANIUM_BUNDLE, product: 'titanium', productPricingDetailsId: util.getDefaultGuid(), coverages: [] },
                            { type: ENUMS.PRODUCT_TYPES.TOWBUSTER, product: 'towbuster', productPricingDetailsId: util.getDefaultGuid(), coverages: [] },
                        ]
                    },
                    {
                        name: "Total Vehicle Loss Protection", selected: false,
                        products: [
                            { type: ENUMS.PRODUCT_TYPES.ETCH, product: 'theft', productPricingDetailsId: util.getDefaultGuid(), coverages: [] },
                            { type: ENUMS.PRODUCT_TYPES.LOJACK_GPS, product: 'skylink', productPricingDetailsId: util.getDefaultGuid(), coverages: [] },
                            { type: ENUMS.PRODUCT_TYPES.GAP, product: 'gap', productPricingDetailsId: util.getDefaultGuid(), coverages: [] },
                        ]
                    },
                    {
                        name: "Miscellaneous", selected: false, isMisc: true,
                        products: []
                    },
                ],
                products: null,
            }
        },
        computed: {
            util() {
                return util
            },
            ENUMS() {
                return ENUMS;
            },
            hasSelectedDealTerm(){
                return this.data.hasSelectedDealTerm()
            },
            anyTermSelected() {
                return this.data.coverageTerms.some(c => c.selected == true);
            },
            hasCustomerSeenCarousel() {
                return this.data.customerViewed.some(c => c.viewedType == 9);
            },
            isPaymentSheetPrinted() {
                return this.data.customerViewed.some(c => c.viewedType == 11);
            },
        },
        async created() {
            this.collapseAll(true)
            this.loadData();

            this.EventBus.on("priceProfitUpdated", (newValue) => {
                this.priceProfit = newValue;
            })
            this.EventBus.on("collapseAllProducts", (newValue) => {
                this.collapseAll(newValue);
            })

            this.$watch(() => this.data.coverageTerms, () => {
                this.setSubProgramList();
                this.populateProductsCoverages()
            }, { deep: true })
        },
        unmounted() {
            if (!this.data.isDealLocked()) {
                this.data.storeProducts.forEach(p => {
                    p.productPricingDetails.forEach(ppd => {
                        ppd.repriceOptions = null
                    })
                })
            }
        },
        methods: {
            isEditCoverageEnabled() {
                return this.hasCustomerSeenCarousel || this.isPaymentSheetPrinted
            },
            thereIsRegulatedProducts(products) {
                return products.some(p => p.isRegulated)
            },
            setVehicleOil(vehicleOil) {
                this.data.vehicleOil = vehicleOil;
            },
            getRichTableHeader(productType, data) {
                const richTableHeader = [
                    { name: "program", display: "Code", sortable: true, autoFilter: true },
                    { name: "programDescription", display: "Coverage", sortable: true, autoFilter: true, cssClass: 'wide-column-300' },
                    { name: "netTerm", display: "NET Term", sortable: true, autoFilter: true, sorted: true },
                ]

                if (data[0]?.netMiles) {
                    richTableHeader.push({ name: "netMiles", display: "NET Miles", value: (a) => a.netMiles.toLocaleString(), sortable: true, autoFilter: true })
                }

                if (data[0]?.subprogram) {
                    const subprogramDisplay = productType === ENUMS.PRODUCT_TYPES.SERVICE_CONTRACT ? "Deductible" : (productType === ENUMS.PRODUCT_TYPES.MAINTENANCE ? "Interval" : "Subprogram")
                    richTableHeader.push({ name: "subprogram", display: subprogramDisplay, value: (a) => a.subprogramDescription, sortable: true, autoFilter: true })
                }

                richTableHeader.push({ name: "msrp", display: "Price", value: (a) => parseFloat(a.msrp), sortable: true, filter: this.$filters.currency2, cssClass: 'column-number' })
                richTableHeader.push({ name: "cost", display: "Cost", value: (a) => parseFloat(a.cost), sortable: true, filter: this.$filters.currency2, cssClass: 'column-number' })
                richTableHeader.push({ name: "profit", display: "Profit", value: (a) => a.msrp ? parseFloat(a.msrp) - parseFloat(a.cost) : '', sortable: true, filter: this.$filters.currency2, cssClass: 'column-number' })

                return richTableHeader
            },
            showRichTableModal(productType, productPricingDetailsId) {
                let data = null;
                let headers = null;
                let provider = null
                if (productPricingDetailsId === util.getDefaultGuid()) {
                    provider = this.data.selectedProviders.find(sp => sp.productType === productType)?.provider
                }
                else {
                    const mp = this.data.miscellaneousProviders.find(mp => mp.productPricingDetailsId === productPricingDetailsId)
                    if (mp.provider) {
                        provider = mp.provider
                    }
                    else if (mp.productInfoAndPricing.length > 0) {
                        provider = mp.productInfoAndPricing[0].termInfo.provider
                    }
                    else {
                        provider = mp.productInfo.provider
                    }
                }

                const ppd = this.data.storeProducts.find(p => p.productType === productType)?.getPricingDetails(productPricingDetailsId)
                const productName = ppd?.name ?? "Product"
                const title = `All ${provider ?? ""} ${productName} Rates`;

                data = this.getProductProviderApiResponse(productType, ppd, provider);

                if (data) {
                    headers = this.getRichTableHeader(productType, data)
                }

                $modal.open(modalRichTable, { name: 'modalRichTable', passedData: { title, data, headers }, backdrop: true });
            },
            getProductProviderApiResponse(productType, productPricingDetails, provider, coverageTerm = null) {
                const terms = this.data.coverageTerms.filter(ct => ct.term > 0).map(ct => ct.term)
                const matchDealTermView = this.data.hasDealTerms() && productPricingDetails.matchToDealTerm

                let rates = null
                if (productPricingDetails?.pricedConfig) {
                    rates = productPricingDetails.pricedConfig.allListRates

                    if (rates && coverageTerm && coverageTerm.term !== 0 && !matchDealTermView) {
                        rates = rates.filter(r => {
                            const rateTerms = `${r.termLimit}`.split("|")
                            //filter out any rate that term does not include one of the CT terms
                            return rateTerms.some(t => terms.includes(parseInt(t)));
                        })
                    }
                }
                else if (productPricingDetails?.repriceOptions) {
                    const repriceOption = productPricingDetails.repriceOptions.find(o => o.provider === provider)
                    const termPropertyName = util.camelCase(repriceOption.termPropertyName)
                    rates = repriceOption.filteredRates

                    //if we have a CT that is not custom and the product is not matching to Deal Term View, filter by term
                    if (rates && coverageTerm && coverageTerm.term !== 0 && !matchDealTermView) {
                        rates = rates.filter(r => parseInt(r[termPropertyName]) >= coverageTerm.term);

                        const highestCoverageTerm = terms[terms.length - 1]
                        if (coverageTerm.term < highestCoverageTerm) {
                            rates = rates.filter(r => parseInt(r[termPropertyName]) < coverageTerm.term + 12);
                        }
                    }
                }

                return rates
            },
            async editCoverageTermProductHandler(product, coverageTerm, productType, productPricingDetailsId) {
                const provider = this.data.selectedProviders.find(sp => sp.productType === productType)?.provider
                const productDetails = this.data.storeProducts.find((p) => p.productType === productType);
                const pricingDetails = productDetails.getPricingDetails(productPricingDetailsId)
                const apiResponse = this.getProductProviderApiResponse(productDetails.productType, pricingDetails, provider, coverageTerm)

                if (!apiResponse) {
                    $modal.open(modalInfo, { name: 'modalInfo', passedData: { info: 'The deal data doesn\'t match the current pricing rules.', acceptText: 'OK' }, backdrop: false });
                }
                else {
                    const currentRepriceOptions = this.pricingDetails?.repriceOptions ? util.clone(this.pricingDetails.repriceOptions) : null;
                    const currentCoverageTerms = util.clone(this.data.coverageTerms)
                    const currentSelectedProviders = util.clone(this.data.selectedProviders)

                    let surchargeConfigs = []
                    if (pricingDetails.pricedConfig) {
                        pricingDetails.pricedConfig.configDetails.forEach(c => {
                            surchargeConfigs.push(...c.providerProduct.surchargeConfigs)
                        })
                    }
                    else {
                        if (provider && provider.includes('PEN-')) {
                            const PENProduct = this.$global.PENProducts.find(p => p.saturnProductType == productDetails.productType && p.providerName === provider.substring(4))
                            if (PENProduct) {
                                surchargeConfigs = PENProduct.possibleAddOns.map(ao => ({
                                    code: ao.code,
                                    altDescription: ao.description,
                                    isVisible: ao.isVisibleInModal,
                                    isDisabled: ao.isDisabledInModal,
                                }))
                            }
                        } else {
                            surchargeConfigs = pricingDetails.repriceOptions.find(o => o.provider === provider).providerProduct.surchargeConfigs
                        }
                    }

                    $modal.open(modalEditCoverageTermProduct, {
                        name: 'modalEditCoverageTermProduct',
                        passedData: {
                            showRegulationLabel: this.thereIsRegulatedProducts(this.data.storeProducts),
                            preloads: this.data.preloads,
                            coverageTerm,
                            matchDealTermView: this.data.hasDealTerms() && pricingDetails.matchToDealTerm,
                            inventoryType: this.data.isCertified() ? "Certified" : this.data.inventoryType,
                            isWarrantyValid: this.data.vehicleWarranty.warranties.basic.valid(this.data.vehicleMiles, this.data.vehicleWarranty.warrantyStartDate),
                            provider,
                            product,
                            productDetails,
                            storeProducts: this.data.storeProducts,
                            repriceProduct: (repriceObj) => this.rePriceProductInModal(productDetails, repriceObj, coverageTerm.term),
                            surchargeConfigs,
                            apiResponse,
                            vehicleOil: this.data.vehicleOil,
                            setVehicleOil: this.setVehicleOil,
                            surcharges: this.data.vehicle.vehicleSurcharges,
                            isDealLocked: this.data.isDealLocked(),
                            store: this.data.store
                        },
                        backdrop: false,
                        cancelFunction: (needsRecovery) => this.recoverCoverageTerms(needsRecovery, currentRepriceOptions, currentCoverageTerms, currentSelectedProviders, pricingDetails),
                        postFunction: () => { this.EventBus.emit('recalculate'); }
                    });
                }
            },
            rePriceProductInModal(productDetails, repriceObj, term) {
                return api.fimenu.getProductsPrice(this.data, this.data.store, this.data.storeProducts, [repriceObj]).then((response) => {

                    if (response && response.data) {
                        const productsPrice = response.data
                        settings.updateProducts(productsPrice.updatedProducts);
                        this.data.storeProducts = settings.products?.filter(p => p.stateCode == this.data.store.storeState);

                        const defaultProductStatus = productsPrice.pricesStatuses.find(ps => ps.productPricingDetailsId === util.getDefaultGuid())
                        const repriceResponse = { success: false, statusDescription: defaultProductStatus?.statusDescription }

                        if (defaultProductStatus?.isIncluded) {
                            this.data.priceProducts([repriceObj], productsPrice)
                            const newCoverageTerm = this.data.coverageTerms.find(ct => ct.term === term)
                            productDetails.getDefaultPricingDetails().repriceOptions = defaultProductStatus.repriceOptions

                            repriceResponse.success = this.data.selectedProviders.some(sp => sp.productType === repriceObj.productType && sp.provider === repriceObj.providerCodeOverride)
                            repriceResponse.repriceOptions = defaultProductStatus.repriceOptions
                            repriceResponse.newCoverageTerm = newCoverageTerm
                            repriceResponse.getProductProviderApiResponse = (nct) => this.getProductProviderApiResponse(productDetails.productType, defaultProductStatus, repriceObj.providerCodeOverride, nct)
                        }

                        return repriceResponse
                    }
                })
            },
            recoverCoverageTerms(needsRecovery, currentRepriceOptions, currentCoverageTerms, currentSelectedProviders, pricingDetails) {
                if (needsRecovery) {
                    pricingDetails.repriceOptions = currentRepriceOptions
                    this.data.coverageTerms = currentCoverageTerms.map(ct => new CoverageTerm(ct))
                    this.data.selectedProviders = currentSelectedProviders
                }
            },
            isProductIncluded(productType) {
                const isWearAndLease = productType == ENUMS.PRODUCT_TYPES.EXCESS_WEAR_AND_TEAR && this.data.isLease()
                const isNotWear = productType != ENUMS.PRODUCT_TYPES.EXCESS_WEAR_AND_TEAR

                return isWearAndLease || isNotWear
            },
            showPreferedLease(coverage) {
                if (coverage.term == 0) {
                    return false
                }
                if (!util.isNull(this.data.leaseTerms.term1.term)) {
                    return coverage.term == this.data.leaseTerms.term1.term
                }
                if (!util.isNull(this.data.leaseTerms.term2.term)) {
                    return coverage.term == this.data.leaseTerms.term2.term
                }
                if (!util.isNull(this.data.leaseTerms.term3.term)) {
                    return coverage.term == this.data.leaseTerms.term3.term
                }
            },
            collapseAll(value) {
                this.allCollapse = value
                this.packages.forEach(p => p.selected = value)

            },
            unselectCoverage(index) {
                this.data.coverageTerms[index].selected = false
                this.data.leaseTerms.acceptedTerm = null
                this.data.loanTerms.acceptedTerm = null
            },
            formatNumber(value) {
                if (value != null && typeof value !== 'undefined' && !Number.isNaN(parseInt(value))) {
                    return parseInt(value).toLocaleString();
                } else {
                    return '0';
                }
            },
            getPackageTotal(packageIndex, term, priceProfit) {
                let price = 0;
                let cost = 0;
                let profit = 0;

                this.packages[packageIndex].products.forEach(p => {
                    const ctProduct = p.coverages.find(c => c.term === term)
                    if (ctProduct?.hasProduct && !p.exclude) {
                        const productPricing = ctProduct.getHighestCostInfoAndPricing()?.termPricing ?? ctProduct.productPricing
                        if (productPricing) {
                            price += productPricing.price
                            cost += productPricing.cost
                            profit += productPricing.profit();
                        }
                    }
                })
                const packageTotal = priceProfit == 1 ? profit : (priceProfit == 2 ? cost : price);

                return util.formatPrice(packageTotal)
            },
            getPackageTotalClass(pkgProducts, isCustom, isSelected) {
                let packageTotalClass = isSelected ? 'displaypriceselected' : 'displayprice'

                if (isCustom) {
                    const validProducts = pkgProducts.filter(product => !product.exclude)
                    const numberOfSelectedProducts = validProducts.filter(product => product.coverages[product.coverages.length - 1].hasProduct).length

                    if (numberOfSelectedProducts === 0) {
                        packageTotalClass = "nothingselected"
                    } else if (numberOfSelectedProducts < validProducts.length) {
                        packageTotalClass = "partialselected"
                    }
                }

                return packageTotalClass
            },
            loadData() {
                this.setSubProgramList();
                this.setProductsDetails();
                this.populateProductsCoverages();
            },
            setSubProgramList() {
                this.subprogramList = []
                ENUMS.productTypes.forEach(pt => {
                    const apiResponse = this.data.apiResponses.find(apiR => apiR.productType == pt.code && this.data.selectedProviders.some(sp => sp.productType === apiR.productType && sp.provider === apiR.provider))
                    if (!apiResponse) {
                        return
                    }

                    const response = apiResponse.rateResponse ?? JSON.parse(apiResponse.response)
                    if (apiResponse && apiResponse.provider && response && (pt.code === ENUMS.PRODUCT_TYPES.SERVICE_CONTRACT || pt.code === ENUMS.PRODUCT_TYPES.MAINTENANCE)) {
                        this.subprogramList.push({ productType: pt.code, subprograms: [...new Set(response.rates.map(rate => rate.subprogram))] })
                    }

                })
            },
            setProductsDetails() {
                this.products = this.data.storeProducts
                this.packages.forEach(pkg => {
                    //add dynamic misc products
                    if (pkg.isMisc) {
                        pkg.products = []
                        this.data.miscellaneousProviders.forEach(mp => {
                            pkg.products.push(
                                {
                                    type: mp.productType,
                                    productPricingDetailsId: mp.productPricingDetailsId,
                                    product: ENUMS.productTypes.find(pt => pt.code === mp.productType)?.fimenuDesc,
                                    coverages: []
                                });
                        })
                    }

                    pkg.products.forEach(p => {
                        const foundProduct = this.products.find(prod => prod.productType == p.type)
                        if (foundProduct) {
                            //add display name to each product
                            const ppd = foundProduct.productPricingDetails.find(ppd => ppd.productPricingDetailsId == p.productPricingDetailsId)
                            p.name = ppd?.name ?? foundProduct.productPricingDetails[0].name
                        }
                    })
                })
            },
            populateProductsCoverages() {
                this.packages.forEach(pkg => {
                    pkg.products.forEach(p => {
                        p.coverages = []
                        this.data.coverageTerms.forEach(ct => {
                            const ctProduct = new CoverageTermProduct()
                            ctProduct.productType = p.type
                            ctProduct.productPricingDetailsId = p.productPricingDetailsId
                            ctProduct.term = ct.term
                            ctProduct.isCTSelected = (term) => this.data.coverageTerms.find(ct => ct.term === term)?.selected

                            if (pkg.isMisc) {
                                const mp = ct.getDealMiscProduct(p.productPricingDetailsId)
                                if (mp) {
                                    ctProduct.hasProduct = mp.hasProduct
                                    ctProduct.toggleProduct = async () => {
                                        mp.hasProduct = ctProduct.hasProduct
                                        this.EventBus.emit('recalculate');
                                        //await this.repriceOtherProductsOfType(p.type, mp.productPricingDetailsId)
                                    }
                                    ctProduct.productInfo = mp.productInfo
                                    ctProduct.productPricing = mp.productPricing
                                    ctProduct.productInfoAndPricing = mp.productInfoAndPricing
                                } else {
                                    ctProduct.hasProduct = false
                                }
                            } else {
                                ctProduct.hasProduct = ct[p.product]
                                ctProduct.toggleProduct = async () => {
                                    ct[p.product] = ctProduct.hasProduct
                                    if (p.type === ENUMS.PRODUCT_TYPES.SERVICE_CONTRACT) {
                                        await this.repriceOtherProductsOfType(p.type, util.getDefaultGuid())
                                    } else {
                                        this.EventBus.emit('recalculate');
                                    }
                                }
                                ctProduct.productInfo = ct[p.product + "Info"]
                                ctProduct.productPricing = ct[p.product + "Pricing"]
                                ctProduct.productInfoAndPricing = ct[p.product + "InfoAndPricing"] ?? []
                            }

                            ctProduct.isCustomCheckboxDisabled = this.isCustomCheckboxDisabled(ctProduct)
                            p.coverages.push(ctProduct)
                        })
                    })
                })
            },
            isCustomCheckboxDisabled(ctProduct) {
                const productInfo = ctProduct.productInfoAndPricing && ctProduct.productInfoAndPricing[0] ? ctProduct.productInfoAndPricing[0].termInfo : ctProduct.productInfo
                if (!productInfo.meta?.providerPricingConfig) {
                    return false
                }

                return JSON.parse(productInfo.meta.providerPricingConfig).isCustomCheckboxDisabled
            },
            async repriceDefaultProduct(productType) {
                this.isBusy = true
                await this.data.priceProducts([{ productType, excludeMisc: true }], null, true)
                this.loadData()
                this.isBusy = false
            },
            async repriceOtherProductsOfType(productType, productPricingDetailsId) {
                //reprice product and update all OTHER prices for the same product type
                this.isBusy = true
                const priceList = [{ productType, pricingDetailsIdToKeep: productPricingDetailsId }]
                await api.fimenu.getProductsPrice(this.data, this.data.store, this.data.storeProducts, priceList).then((response) => {
                    if (response && response.data) {
                        const productsPrice = response.data
                        settings.updateProducts(productsPrice.updatedProducts);
                        this.data.storeProducts = settings.products?.filter(p => p.stateCode == this.data.store.storeState);

                        productsPrice.pricesStatuses = productsPrice.pricesStatuses.filter(ps => ps.productPricingDetailsId !== productPricingDetailsId)
                        this.data.priceProducts(priceList, productsPrice, true)
                    }
                }).finally(() => {
                    this.loadData()
                    this.isBusy = false
                })
            },
            isProductRegulated(productType) {
                return this.products?.find(p => p.productType == productType)?.isRegulated
            },
            isProductTaxable(productType) {
                return this.products?.find(p => p.productType == productType)?.isTaxable
            },
            getDisplayClass(hasProduct, isCoverageTermSelected) {
                return isCoverageTermSelected && hasProduct ? 'displaypriceselected' : hasProduct ? 'displayprice' : 'displaypricestrikethrough';
            },
            moveSelectedCoverageColumn(index) {
                return {
                    left: 366 + (index * 100) + 'px'
                }
            },
            getProductRate(p, pricingDetails, ctIndex, minOrMax, provider, term) {
                let apiResponse = this.getProductProviderApiResponse(p.type, pricingDetails, provider, this.data.coverageTerms[ctIndex])
                //filter by term
                const distinctTerms = [...new Set(apiResponse.map(r => r.netTerm))]
                const termLimit = distinctTerms.find(dt => `${dt}`.split("|").every(t => parseInt(t) >= term));

                apiResponse = apiResponse.filter(t => {
                    const isBundledRate = `${t.netTerm}`.includes("|")
                    const isBundledTerm = `${termLimit}`.includes("|")

                    if (isBundledRate === isBundledTerm) {
                        return t.netTerm == termLimit
                    }
                    else if (isBundledRate) {
                        const ratePieces = `${t.netTerm}`.split("|");
                        return ratePieces.includes(`${termLimit}`)
                    }
                    else {
                        const termPieces = `${termLimit}`.split("|");
                        return termPieces.includes(`${t.netTerm}`)
                    }
                })

                const rate = apiResponse.reduce((acc, cur) => {
                    const accPrice = acc.msrp ? acc.msrp : acc.cost
                    const curPrice = cur.msrp ? cur.msrp : cur.cost

                    return (minOrMax === 'min' ? curPrice < accPrice : curPrice > accPrice) ? cur : acc
                }, apiResponse[0])

                return rate
            },
            setPkgProductPrice(pkgProduct, ctIndex, minOrMax) {
                const productDetails = this.data.storeProducts.find(product => product.productType == pkgProduct.type)
                const pricingDetails = productDetails.getDefaultPricingDetails()

                //dynamic structure
                const ctProducts = []
                if (pkgProduct.coverages[ctIndex].productInfoAndPricing.length > 0) {
                    pkgProduct.coverages[ctIndex].productInfoAndPricing.forEach(infoAndPricing => {
                        ctProducts.push({
                            productInfo: infoAndPricing.termInfo,
                            productPricing: infoAndPricing.termPricing
                        })
                    })
                }
                else {
                    ctProducts.push({
                        productInfo: pkgProduct.coverages[ctIndex].productInfo,
                        productPricing: pkgProduct.coverages[ctIndex].productPricing
                    })
                }

                //update
                ctProducts.filter(p => p).forEach(ctProduct => {
                    if (ctProduct.productInfo.meta?.pricedBundle) {
                        const productInfo = new CoverageTermInfo()
                        productInfo.meta = ctProduct.productInfo.meta
                        productInfo.setInfoWithBundle()
                        const selectedRate = productInfo.getSelectedRate(pricingDetails.pricedConfig.allListRates)

                        let price = null
                        if (minOrMax === 'min') {
                            price = pricingDetails.getMinimumPrice(ctProduct.productPricing.price, ctProduct.productPricing.cost, this.data.preloads, productDetails, this.data.storeProducts, selectedRate, productInfo.surcharges)
                        }
                        else {
                            price = pricingDetails.getMaximumPrice(ctProduct.productPricing.price, ctProduct.productPricing.cost, productDetails.isRegulated, this.data.storeProducts, selectedRate, productInfo.surcharges)
                        }
                        ctProduct.productPricing.setBundleWithPricing(price, selectedRate.cost, this.data.preloads, selectedRate, productInfo.surcharges, this.data.storeProducts, pricingDetails, minOrMax !== 'min')
                    }
                    else {
                        if (minOrMax === 'min') {
                            ctProduct.productPricing.price = pricingDetails.getMinimumPrice(ctProduct.productPricing.price, ctProduct.productPricing.cost, this.data.preloads, productDetails, this.data.storeProducts, null, ctProduct.productInfo.surcharges)
                        }
                        else {
                            ctProduct.productPricing.price = pricingDetails.getMaximumPrice(ctProduct.productPricing.price, ctProduct.productPricing.cost, productDetails.isRegulated, this.data.storeProducts, null, ctProduct.productInfo.surcharges)
                        }
                    }
                })
            },
            selectCTAsAdmin(term) {
                this.data.selectCoverageTerm(term)
                this.data.calculateBuyersOrdersWithBaseBuyersOrders = true;
                this.data.buyersOrderRecalculateRequired = true;
                this.EventBus.emit('recalculateBuyersOrder');
            },
            setTermPrices(ctIndex, minOrMax) {
                this.packages.forEach(pkg => {
                    if (!pkg.isMisc) {
                        pkg.products.forEach(p => {
                            this.setPkgProductPrice(p, ctIndex, minOrMax)
                        })
                    }
                })
                this.EventBus.emit('recalculate');
            },
            hasTermPlanMetaData(ctIndex, minOrMax) {
                return this.packages.every(pkg => pkg.isMisc || pkg.products.every(p => {
                    //dynamic structure
                    const productPricings = []
                    if (p.coverages[ctIndex].productInfoAndPricing?.length > 0) {
                        p.coverages[ctIndex].productInfoAndPricing.forEach(infoAndPricing => {
                            productPricings.push(infoAndPricing.termPricing)
                        })
                    }
                    else {
                        productPricings.push(p.coverages[ctIndex].productPricing)
                    }

                    return productPricings.every(productPricing =>
                        !productPricing?.price ||
                        productPricing.meta?.pricedBundle ||
                        (minOrMax === 'min' ? productPricing.meta?.lowestPriceTermPlanPricing : productPricing.meta?.highestPriceTermPlanPricing))
                }))
            },
            setTermProgramAndPrices(ctIndex, minOrMax) {
                this.packages.filter(pkg => !pkg.isMisc).forEach(pkg => {
                    pkg.products.forEach(p => {
                        if (p.coverages[ctIndex].hasProduct) {
                            const productDetails = this.data.storeProducts.find(prod => prod.productType === p.type)
                            const pricingDetails = productDetails.getPricingDetails(p.productPricingDetailsId)

                            //dynamic structure
                            const ctProducts = []
                            if (p.coverages[ctIndex].productInfoAndPricing.length > 0) {
                                p.coverages[ctIndex].productInfoAndPricing.forEach(infoAndPricing => {
                                    ctProducts.push({
                                        term: infoAndPricing.termMonths,
                                        productInfo: infoAndPricing.termInfo,
                                        productPricing: infoAndPricing.termPricing
                                    })
                                })
                            }
                            else {
                                ctProducts.push({
                                    term: this.data.coverageTerms[ctIndex].term,
                                    productInfo: p.coverages[ctIndex].productInfo,
                                    productPricing: p.coverages[ctIndex].productPricing
                                })
                            }

                            //update
                            ctProducts.forEach(ctp => {
                                if (ctp.productInfo.meta?.pricedBundle) {
                                    const rate = this.getProductRate(p, pricingDetails, ctIndex, minOrMax, ctp.productInfo.provider, ctp.term)
                                    const selectedSurcharges = []

                                    rate.surcharges
                                        .filter(s => pricingDetails.pricedConfig.configDetails.some(c => c.providerProduct.surchargeConfigs
                                            .some(sc => {
                                                if (sc.code != s.code) return false;
                                                // If defaultValue is null, it implies the existence of a mapped vehicle surcharge
                                                if (!sc.defaultValue.HasValue) {
                                                    return this.data.vehicle.getSurchargeByCode(sc.vehicleSurchargeMapping)?.value;
                                                }

                                                // If we opted in to this flag, add surcharge based on defaultValue
                                                if (sc.isDisabled || sc.useDefaultOnBulkUpdate) {
                                                    return sc.defaultValue;
                                                }

                                                // Otherwise, always add the surcharge unless it's the lowest pricing plan selector
                                                return minOrMax !== 'min' && sc.defaultValue;
                                            })))
                                        .forEach(s => {
                                            selectedSurcharges.push(s.code)
                                        });

                                    ctp.productInfo.setBundleWithInfo(rate.meta.bundledProvider, rate, selectedSurcharges, pricingDetails)
                                    ctp.productPricing.setBundleWithPricing(rate.msrp ?? rate.cost, rate.cost, this.data.preloads, rate, selectedSurcharges, this.data.storeProducts, pricingDetails, minOrMax !== 'min')
                                }
                                else {
                                    if (ctp.productInfo) {
                                        const metaProductInfo = JSON.parse(minOrMax === 'min' ? ctp.productInfo.meta.lowestPriceTermPlanInfo : ctp.productInfo.meta.highestPriceTermPlanInfo)
                                        ctp.productInfo.provider = metaProductInfo.provider
                                        ctp.productInfo.program.code = metaProductInfo.program.code
                                        ctp.productInfo.program.description = metaProductInfo.program.description
                                        ctp.productInfo.termLimit = metaProductInfo.termLimit
                                        ctp.productInfo.milesLimit = metaProductInfo.milesLimit
                                        ctp.productInfo.netTerm = metaProductInfo.netTerm
                                        ctp.productInfo.netMiles = metaProductInfo.netMiles
                                        ctp.productInfo.subprogram = metaProductInfo.subprogram
                                        ctp.productInfo.surcharges = metaProductInfo.surcharges
                                    }

                                    if (ctp.productPricing) {
                                        const metaProductPricing = JSON.parse(minOrMax === 'min' ? ctp.productPricing.meta.lowestPriceTermPlanPricing : ctp.productPricing.meta.highestPriceTermPlanPricing)
                                        ctp.productPricing.price = metaProductPricing.price
                                        ctp.productPricing.cost = metaProductPricing.cost
                                        ctp.productPricing.taxRate = metaProductPricing.taxRate
                                    }
                                }
                            })

                            this.setPkgProductPrice(p, ctIndex, minOrMax)
                        }
                    })
                })
                this.EventBus.emit('recalculate');
            }
        },
        components: {
            Panel,
            FIMenuProduct,
            TooltipComponent
        }
    };
</script>

<style>
    .coverage-term {
        flex: 1;
    }

        .coverage-term .coverage-terms-header-select .admin-actions {
            display: flex;
            width: 100%;
            margin-bottom: 5px;
        }

            .coverage-term .coverage-terms-header-select .admin-actions button {
                padding: 0;
            }

        .coverage-term .term-min-max-wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            column-gap: 2px;
        }

        .coverage-term .term-min-max-group {
            display: flex;
            justify-content: center;
            align-items: center;
        }

            .coverage-term .term-min-max-group .term-min-max-left {
                border-top-left-radius: 3px;
                border-top-right-radius: 0px;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 0px;
                border-right: 1px solid var(--background-color);
            }

            .coverage-term .term-min-max-group .term-min-max-right {
                border-top-left-radius: 0px;
                border-top-right-radius: 3px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 3px;
            }

        .coverage-term .term-min-max-wrapper button {
            padding-left: 10px;
            padding-right: 10px;
            height: 30px;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box !important;
        }

            .coverage-term .term-min-max-wrapper button:disabled {
                border: none !important;
            }

        .coverage-term .term-min-max-wrapper .term-min-max-group .term-min-max-left:disabled {
            border-right: 1px solid var(--background-color) !important;
        }

        .coverage-term .panel-header {
            display: none
        }

        .coverage-term .panel-body {
            overflow: auto;
            height: 100% !important;
        }

            .coverage-term .panel-body fieldset {
                height: 34px !important;
            }
</style>