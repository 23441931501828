<template>
    <div class="fimenu-customer-meetings">
        <div :class="connectionContainerClassName">
            <div class="sidemenu-mouse-listener" />
            <button :class="{'toggle-customer-meetings': true, 'hint-click': !isSideMenuVisible}" @click="toggleSideMenuView">
                <i class="fas fa-angle-double-down" />
            </button>

            <PanelCustomerVideoChat :fimenu="fimenu"
                                    :customerScreenActions="customerScreenActions"
                                    :paymentSheetDisabled="paymentSheetDisabled"
                                    :peopleInLobby="peopleInLobby"
            />

            <PanelCustomerLive :isSideMenuVisible="isSideMenuVisible" />
        </div>
    </div>
</template>

<script>
    import FIMenu from '@core/classes/FIMenu';
    import PanelCustomerLive from '@/components/PanelCustomerLive.vue'
    import PanelCustomerVideoChat from '@/components/PanelCustomerVideoChat.vue';
    import { useVuelidate } from '@vuelidate/core';

    export default {
        name: 'FIMenuCustomerMeetings',
        components: {
            PanelCustomerLive,
            PanelCustomerVideoChat
        },
        props: {
            fimenu: {
                type: FIMenu,
                required: true
            },
            customerScreenActions: {
                type: Object,   // Expects: {openCustomerPopup: Function, openPrintPaymentSheetModal: Function, openPaperworkErrorsModal: Function}
                required: true
            },
            paymentSheetDisabled: {
                type: Boolean,
                required: true
            },
            peopleInLobby: {
                type: Array,
                required: true
            },
        },
        setup() {
            // New setup for vuelidate. Swap $v with v$
            return {v$: useVuelidate({$scope: false})}

        },
        data() {
            return {
                meetings: [],
                meetingCodeEncoded: null,
                employeeId: null,
                employeeCode: null,
                isSideMenuVisible: true,
            }
        },
        created() {
            this.meetingHelper.dealNumber = this.fimenu.dealNumber;
            this.meetingHelper.storeCode = this.fimenu.store.storeCode;
        },
        computed: {
            connectionContainerClassName() {
                let className = "signalr-notes fade-in";
                if (!this.isSideMenuVisible) className += " hidden";

                return className;
            },
        },
        methods: {
            toggleSideMenuView() {
                this.isSideMenuVisible = !this.isSideMenuVisible;
            },
        }
    }
</script>

<style scoped>
    .fimenu-customer-meetings:has(.signalr-notes.hidden) button.toggle-customer-meetings {
        transform: rotateY(180deg);
        left: -15px;
        width: 15px;
    }

    .fimenu-customer-meetings .signalr-notes:not(.hidden) .sidemenu-mouse-listener {
        display: none;
    }

    .fimenu-customer-meetings .sidemenu-mouse-listener {
        width: 50px;
        position: absolute;
        right: 100%;
        top: 0;
        bottom: 0;
        z-index: 1;
    }

    .fimenu-customer-meetings:has(.sidemenu-mouse-listener:hover) button.toggle-customer-meetings,
    .fimenu-customer-meetings:has(.signalr-notes.hidden) button.toggle-customer-meetings:hover {
        width: 50px;
        left: -50px;
    }

    .fimenu-customer-meetings button.toggle-customer-meetings {
        border: 1px solid var(--main-color);
        width: 15px;
        position: absolute;
        top: 0%;
        left: 0;
        z-index: 11;
        height: 100px;
        border-radius: 4px;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        padding: 0px 2px;
        transition: 50ms all ease-in-out;
    }

        .fimenu-customer-meetings button.toggle-customer-meetings:hover {
            width: 20px;
        }


    .toggle-customer-meetings .fa-angle-double-down {
        transform: rotate(-90deg);
    }

    .fimenu-customer-meetings .multi-stream-recorder {
        position: absolute;
        top: 200px;
        left: 0;
    }
</style>