<template>
    <div class="FIMenuProduct-wrapper coverage-grid">
        <div></div>
        <button v-if="!isRichTableModalDisabled" class="button-link ignore-all-locks" @click="showRichTableModal(pkgProduct.type, pkgProduct.productPricingDetailsId)">
            <span>{{ pkgProduct.name }}</span>
        </button>
        <span v-else class="misc-prod-desc">{{ pkgProduct.name }}</span>
        <div class="preload-value">
            <div v-if="$global.isAdminView && pkgProduct.productPricingDetailsId == util.getDefaultGuid()" class="admin-actions">
                <button class="button-load" title="Refresh product prices" @click="() => repriceDefaultProduct(pkgProduct.type)" :disabled="isEditCoverageModalDisabled">
                    <i class="fas fa-sync"></i>
                </button>
                <button title="Refresh misc products prices for type" @click="() => repriceOtherProductsOfType(pkgProduct.type, util.getDefaultGuid())" :disabled="isEditCoverageModalDisabled">
                    <i class="fas fa-sync"></i>
                </button>
            </div>
            {{ preloadValue() }}
        </div>
        <div class="coveragetermprice" v-for="(coverage, index) in pkgProduct.coverages" :key="index">
            <TooltipComponent v-if="coverage.hasProduct || hasTerm(coverage) || getPrice(coverage) != null || (coverage.term === 0 && pkgProduct.coverages.some(c => c.hasProduct))"
                              :class="showClass(coverage) + ' clickable ignore-deal-lock'"
                              :tooltip="getTooltipText(coverage)">
                <template #showFromOutside>
                    <button :class="{'button-inactive': isEditCoverageModalDisabled, 'button-link': !isEditCoverageModalDisabled, 'no-product': !coverage.hasProduct}" @click="() => openModal(coverage.term)">
                        {{ getDisplayAmount(coverage) }}
                    </button>
                </template>
            </TooltipComponent>
            <button v-if="$global.isAdminView && canCopyMiscToCustom(coverage)" @click="() => copyMiscToCustom(coverage)" class="admin-copy-to-custom" title="Copy to custom">
                <i class="fas fa-copy" />
            </button>
            <div v-if="coverage.term === 0 && getPrice(coverage) > 0" class="customCoverageInfo">
                <InputCheckbox
                    v-model="coverage.hasProduct"
                    @update:modelValue="coverage.toggleProduct"
                    :disabled="isCustomCheckboxDisabled || coverage.isCustomCheckboxDisabled"
                >
                </InputCheckbox>
            </div>
        </div>
    </div>
</template>

<script>
    import $util from '@core/services/util'
    import CoverageTermInfo from '@core/classes/CoverageTermInfo';
    import ENUMS from "@core/classes/Enums"
    import InputCheckbox from '@core/components/InputCheckbox.vue'
    import TooltipComponent from '@core/components/TooltipComponent.vue'

    export default {
        name: "FIMenuProduct",
        props: ['fimenu', 'pkgProduct', 'priceProfit', 'repriceDefaultProduct', 'repriceOtherProductsOfType', 'isRegulated', 'isTaxable', 'isRichTableModalDisabled', 'showRichTableModal', 'isEditCoverageModalDisabled', 'openEditCoverageModal', 'isCustomCheckboxDisabled', 'displayClass', 'subprogramList'],
        data() {
            return {
                checked: false,
            };
        },
        computed: {
            util() {
                return $util;
            },
            ENUMS() {
                return ENUMS;
            },
            isMisc(){
                return this.pkgProduct.productPricingDetailsId !== this.util.getDefaultGuid()
            }

        },

        methods: {
            hasTerm(productInfo) {
                return productInfo?.termLimit != null
            },
            getPrice(coverageProduct) {
                if (coverageProduct.productInfoAndPricing.length === 0) {
                    return coverageProduct.productPricing?.price
                }
                else {
                    return coverageProduct.productInfoAndPricing && coverageProduct.productInfoAndPricing[0]?.termPricing.price
                }
            },
            preloadValue() {
                const value = this.fimenu.preloads.find(p => p.productType == this.pkgProduct.type)
                return value && value.isSelected ? $util.formatPrice(value.price) : "-"
            },
            canCopyMiscToCustom(coverage){
                return this.isMisc && coverage.term !== 0 && this.pkgProduct.coverages.some(c => c.term === 0 && this.getPrice(c) == null)
            },
            copyMiscToCustom(coverage){
                const baseMiscProduct = this.fimenu.coverageTerms.find(ct => ct.term === coverage.term).getDealMiscProduct(coverage.productPricingDetailsId)

                //Add to custom
                this.fimenu.coverageTerms.find(ct => ct.term === 0).miscellaneousProducts.push(baseMiscProduct)
            },
            getTooltipText(coverageProduct) {
                let productInfo = coverageProduct.productInfo
                if (productInfo.meta?.pricedBundle) {
                    productInfo = new CoverageTermInfo(null, null, null, null, null, null, null, null, coverageProduct.productInfo.meta)
                    productInfo.setInfoWithBundle()
                }

                if (!this.hasTerm(productInfo)) {
                    return []
                }

                const response = []
                const defaultLender = $util.getDefaultProductLenderMappings(this.pkgProduct.type, productInfo.provider, this.fimenu.store)
                const programObject = defaultLender?.find(dl => dl.planCode.trim() == productInfo.program.code?.trim())

                const ppd = this.fimenu.storeProducts.find(p => p.productType == this.pkgProduct.type)?.getPricingDetails(this.pkgProduct.productPricingDetailsId);
                const rateList = ppd?.pricedConfig ? ppd.pricedConfig.allListRates : ppd?.repriceOptions?.[0]?.filteredRates;
                const selectedRate = rateList ? productInfo.getSelectedRate(rateList) : null;

                response.push({ label: "Term: ", value: productInfo.netTerm ?? productInfo.termLimit + " Months" })
                response.push({ label: "Program: ", value: programObject?.planDescription ?? productInfo.program.description })
                response.push({ label: "Miles Limit: ", value: productInfo.netMiles ? productInfo.netMiles !== -1 ? productInfo.netMiles?.toLocaleString() : "NO EXPIRATION" : productInfo.milesLimit?.toLocaleString() })
                const tempSubprogramList = this.subprogramList.find(tsp => tsp.productType == this.pkgProduct.type)
                if (tempSubprogramList) {
                    const subprogram = tempSubprogramList.subprograms.find(sp => sp.trim() == productInfo.subprogram.trim());

                    if (subprogram && selectedRate?.subprogramDescription) {
                        switch(this.pkgProduct.type) {
                            case ENUMS.PRODUCT_TYPES.SERVICE_CONTRACT: {
                                const subprogramDescription = (productInfo.provider && productInfo.provider.includes("PEN-"))
                                    ? `${subprogram}`.startsWith("$") ? subprogram : "$" + subprogram
                                    : selectedRate.subprogramDescription;

                                response.push({ label: "Deductible: ", value: subprogramDescription });
                                break;
                            }
                            case ENUMS.PRODUCT_TYPES.MAINTENANCE: {
                                response.push({ label: "Interval: ", value: selectedRate.subprogramDescription });
                                break;
                            }
                        }
                    }
                }

                return response.filter(r => !$util.isNull(r.value)).map(r => r.label + r.value)
            },
            openModal(term) {
                if (!this.isEditCoverageModalDisabled) {
                    this.openEditCoverageModal(this.pkgProduct.product, this.fimenu.coverageTerms.find(ct => ct.term === term), this.pkgProduct.type, this.pkgProduct.productPricingDetailsId)
                }
            },
            showClass(coverageProduct) {
                const isCTSelected = coverageProduct.isCTSelected(coverageProduct.term)
                return this.isEditCoverageModalDisabled ? this.displayClass(coverageProduct.hasProduct, isCTSelected) + " disabled" : this.displayClass(coverageProduct.hasProduct, isCTSelected)
            },
            getDisplayAmount(coverageProduct) {
                let displayAmount = "$0.00"

                if (this.hasTerm(coverageProduct.productInfo) || this.getPrice(coverageProduct) != null) {
                    let pricing = null
                    if (coverageProduct.productInfoAndPricing.length === 0) {
                        pricing = coverageProduct.productPricing
                    }
                    else {
                        const highestCostGapCoverage = coverageProduct.getHighestCostInfoAndPricing()
                        if (highestCostGapCoverage) {
                            pricing = highestCostGapCoverage.termPricing
                        }
                    }

                    displayAmount = $util.formatPrice(pricing ? this.priceProfit == 1 ? pricing.profit()
                        : this.priceProfit == 2 ? pricing.cost
                            : pricing.price : 0);
                }

                return displayAmount
            },
        },
        components: {
            InputCheckbox: InputCheckbox,
            TooltipComponent: TooltipComponent
        }
    };
</script>
<style>
    .coverage-grid .coveragetermprice .button-link {
        color: inherit;
    }

    .coverage-grid .coveragetermprice .no-product {
        text-decoration: line-through underline;
    }

    .coverage-grid .coveragetermprice .custom-checkbox {
        display: flex;
        align-items: center;
    }

    .coverage-grid .coveragetermprice .admin-actions {
        margin-left: 5px;
        display: flex;
        align-items: center;
        width: calc(100% - 5px);
    }

    button.button-inactive {
        width: fit-content;
        height: inherit;
        padding: inherit;
        border: none;
        background-color: transparent;
        box-shadow: none;
        color: inherit;
        font-size: inherit;
        cursor: auto;
    }

    .FIMenuProduct-wrapper .admin-actions {
        display: flex;
        align-items: center;
        margin-right: 5px;
    }

        .FIMenuProduct-wrapper .admin-actions button {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px !important;
            min-width: unset;
            font-size: 10px;
        }

    .FIMenuProduct-wrapper .admin-copy-to-custom {
        margin-left: 5px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px !important;
        min-width: unset;
        font-size: 10px;
    }
</style>