<template>
    <div class="fimenu-signatures-page">
        <div class="fimenu-signatures">
            <div class="fimenu-signature">
                <VueSignaturePad width="600px" height="200px" ref="buyerSignature" />
            </div>
            <div class="fimenu-signature">
                <VueSignaturePad width="600px" height="200px" ref="coBuyerSignature" />
            </div>
        </div>
        <div class="fimenu-submit-signature">
            <button class="button-customer" @click="submitSignatures()">Accept</button>
        </div>
    </div>
</template>


<script>

    export default {
        name: "FIMenuSignatures",
        props: ['fimenu', 'sectionName', 'customerView'],
        data() {
            return {
                isBusy: false,
                currentDateTime: null
            };
        },
        computed: {

        },
        created() {
            this.loadData();
        },
        methods: {
            submitSignatures() {
                let buyerSignature = this.$refs.buyerSignature.saveSignature();
                console.log('buyerSignature', buyerSignature);

                let coBuyerSignature = this.$refs.coBuyerSignature.saveSignature();
                console.log('coBuyerSignature', coBuyerSignature);
            }

        },
        components: {

        }
    };
</script>
<style>

</style>