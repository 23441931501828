<template>
    <div class="modal-container ModalAddEditFee">
        <div class="modal-header">
            Fee Details <span v-if="$global.isAdminView && fee">{{fee.titleId}} - ({{bucket.originalTitleId}})</span>
        </div>
        <div class="modal-body" v-if="bucket">
            <fieldset :disabled="modal.isModalDisabled">
                <Panel title="Basic Info">
                    <template v-slot:HeaderComponent>
                        <div class="panel-buttons">
                            <InputRichDropdown v-if="isNewFee"
                                               :height="35"
                                               v-model:saturn="selectedFee"
                                               :list="storeFees"
                                               :valueMap="(pf) => pf"
                                               :display="(pf) => (pf.name ? pf.name : 'CUSTOM FEE')"
                                               @change="onFeeTypeSelected"
                                               nothingSelected="-- Fee Type --">
                            </InputRichDropdown>
                        </div>
                    </template>
                    <div :style="$grid('1')" class="no-margin-grid">
                        <InputTextbox label="Description"
                                      v-model:saturn="fee.name"
                                      placeholder="Description"
                                      :invalid="v$.fee.name.$invalid"
                                      :disabled="!fee.isEditableDesc || (isNewFee && !selectedFee) || (modal.isDealLocked && fee.itemized)">
                        </InputTextbox>

                        <div :style="$grid('1-1-1')" class="no-margin-grid">
                            <InputCurrency label="Fee Amount"
                                           v-model:saturn="fee.amount"
                                           :precision="2"
                                           iconSide="left"
                                           :invalid="v$.fee.amount.$invalid"
                                           :disabled="!fee.isEditableAmount || (isNewFee && !selectedFee) || modal.isDealLocked">
                            </InputCurrency>

                            <InputCurrency label="Fee Cost"
                                           v-model:saturn="fee.cost"
                                           :precision="2"
                                           iconSide="left"
                                           :invalid="v$.fee.cost.$invalid"
                                           :disabled="!fee.isEditableCost || (isNewFee && !selectedFee)">
                            </InputCurrency>

                            <InputRichDropdown v-if="!isNewFee || selectedFee"
                                               label="Itemized on Buyer's Order"
                                               class="itemized"
                                               v-model:saturn="fee.itemized"
                                               :list="itemizedOptions"
                                               :valueMap="(o) => o.value"
                                               :display="(o) => o.display"
                                               :disabled="!fee.isEditableItemized || (fee.isEditableItemized && modal.isDealLocked)">
                            </InputRichDropdown>
                        </div>
                    </div>
                </Panel>

                <Panel title="Tax Lines to Apply">
                    <fieldset class="no-margin-grid" :style="$grid('1')" :disabled="modal.isDealLocked">
                        <div class="no-margin-grid" :style="$grid('5-1')" v-for="(taxLine, taxLineIndex) in bo.taxGroups" :key="taxLine.name">
                            <InputCheckbox :modelValue="isInThisTaxLine(taxLineIndex)" @click="toggleTaxLine(taxLineIndex)" class="panel-header-collapse-button description" :label="taxLine.name"></InputCheckbox>

                            <div class="amt">
                                <InputNumber v-model:saturn="taxLine.rate"
                                             placeholder="Tax Rate"
                                             :precision="2"
                                             :isPercent="true"
                                             :disabled="true">
                                </InputNumber>
                            </div>
                        </div>
                    </fieldset>
                </Panel>
            </fieldset>
        </div>
        <div class="modal-footer modal-footer-split">
            <div>
                <button v-if="!isNewFee" class="button-unwind" @click="deleteFee()" :disabled="modal.isDealLocked && (fee.amount || fee.itemized)">Delete Fee</button>
            </div>
            <div v-if="isEditable">
                <button class="button-error" @click="cancel()">Cancel</button>
                <button class="button-save" @click="save()" :disabled="modal.isModalDisabled || v$.$invalid || (modal.isDealLocked && fee.itemized && fee.isEditableItemized && isNewFee)">Save</button>
            </div>
            <div v-else>
                <button class="button-error" @click="cancel()">Close</button>
            </div>

        </div>
    </div>
</template>


<script>
    import $modal from '@core/services/modal'
    import FIMenuBuyersOrder from '@core/classes/FIMenuBuyersOrder'
    import FIMenuBuyersOrderBucketItem from '@core/classes/FIMenuBuyersOrderBucketItem'
    import StoreFee from '@core/classes/Store/StoreFee'
    import InputCheckbox from '@core/components/InputCheckbox.vue'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import InputCurrency from '@core/components/InputCurrency.vue'
    import InputNumber from '@core/components/InputNumber.vue'
    import Panel from '@core/components/Panel.vue'
    import { required, between, minValue, maxValue } from '@vuelidate/validators'
    import { useVuelidate } from '@vuelidate/core';
    import util from '@core/services/util'
    import FIMenuBuyersOrderBucket from '../../../vue-core/classes/FIMenuBuyersOrderBucket'

    export default {
        name: "modalAddEditFee",
        props: ['modal'],
        setup(){
            // New setup for vuelidate. Swap $v with v$
            return {v$: useVuelidate({$scope: false})}
        },
        data() {
            return {
                bo: null,
                fee: null,
                feeIndex: null,
                bucket: null,
                originalName: null,
                isNewFee: false,
                selectedFee: null,
                storeFees: null,
                itemizedOptions: [
                    {
                        value: true,
                        display: "Yes"
                    },
                    {
                        value: false,
                        display: "No"
                    }
                ],
            }
        },
        computed: {
            isEditable() {
                return this.fee.baseType == 'Fixed';
            }
        },
        validations() {

            return {
                fee: this.fee.validation(this.bo)
            };

        },
        created() {
            this.bo = new FIMenuBuyersOrder(this.modal.bo);

            //STORE DEFAULT FEES
            //WE ARE NOT ALLOWING CUSTOM FEES DUE TO ACCOUNTING MAPPING LOGIC
            //let customFee = new StoreFee({
            //    name: null,
            //    amount: null,
            //    cost: null,
            //    profitFlag: ENUMS.PROFIT_FLAGS.NONE,
            //    isEditableDesc: true,
            //    isEditableCost: true,
            //    isEditableAmount: true,
            //    preloaded: false,
            //    titleId: null
            //});

            //WE ONLY WANT TO ALLOW TO ADD THE SAME FEE ONCE, NAME MUST BE UNIQUE
            //let allFeeNamesAlreadyUsed = this.bo.fees.map(item => item.name);
            //this.storeFees = this.modal.storeFees.filter(f => !allFeeNamesAlreadyUsed.includes(f.name)).map(f => new StoreFee(f));
            this.storeFees = this.modal.storeFees.map(f => new StoreFee(f));
            //this.storeFees.push(customFee)


            //IF NEW FEE, CREATE IT AND CREATE A BUCKET FOR IT
            if (!this.modal.itemInfo.item) {

                this.isNewFee = true;

                //CREATE NEW FEE AND NEW BUCKET
                this.bucket = this.bo.addNewDefaultBucket(this.modal.itemInfo.bucketType, 0, 0, null, null, null);
                this.fee = this.bo.addNewDefaultFee(this.bucket.titleId, true, true, true);

            }
            else {

                this.isNewFee = false;

                //GET FEE
                this.fee = this.bo.fees.find(f => f.name == this.modal.itemInfo.item.name);
                this.originalName = this.fee.name

                //GET BUCKET CONTAINING THIS FEE
                this.bucket = this.bo.buckets.find(b => b.titleId == this.fee.titleId);

                if (!this.bucket) {
                    this.bucket = this.bo.addNewDefaultBucket(this.modal.itemInfo.bucketType, 0, 0, null, this.fee.titleId);
                }
            }

            this.feeIndex = this.bo.fees.findIndex(tg => tg === this.fee);

            this.$watch(() => this.fee, () => {
                this.bo.isManuallyChanged = true;
            }, { deep: true });
        },
        methods: {
            onFeeTypeSelected() {
                //Nothing selected
                if (!this.selectedFee) {
                    this.clearFeeDetails();
                    return;
                }

                //FEE INFO
                this.fee.name = this.selectedFee.name;
                this.fee.amount = this.selectedFee.amount;
                this.fee.cost = this.selectedFee.cost;
                this.fee.isEditableDesc = this.selectedFee.isEditableDesc;
                this.fee.isEditableCost = this.selectedFee.isEditableCost;
                this.fee.isEditableAmount = this.selectedFee.isEditableAmount;
                this.fee.isEditableItemized = this.selectedFee.isEditableItemized;
                this.fee.itemized = this.fee.isEditableItemized ? false : this.selectedFee.itemized;

                //SET UP BUCKET & TITLE ID
                let bucket = null;
                if (this.selectedFee.titleId) {
                    bucket = this.bo.buckets.find(b => b.titleId == this.selectedFee.titleId);
                }

                if (!bucket) {
                    bucket = this.bo.addNewDefaultBucket(this.modal.itemInfo.bucketType, 0, 0, null, this.selectedFee.titleId);
                }

                this.bucket = bucket
                this.fee.titleId = this.bucket.titleId;
            },

            //GIVEN A taxLineIndex RETURNS TRUE IF THE CURRENT ITEM'S IS TAXED ON THAT TAX LINE
            isInThisTaxLine(taxLineIndex) {

                const taxIndex = this.bo.isLease() ? this.bucket.upfrontTaxIndex : this.bucket.taxIndex;  //Which tax index? (check if the bucket is upfront)
                const feeTaxBinaryArray = util.taxIndexToBinaryArray(taxIndex);
                const isInThisTaxLine = (feeTaxBinaryArray[taxLineIndex] == '1');
                return isInThisTaxLine;

            },

            //TOGGLES AN BUCKET ITEM'S TAX LINE
            //EITHER SETTING SO THAT IT GETS TAXED
            //OR SETTING IT SO THAT IT DOES NOT GET TAXED
            //ON THE PROVIDED TAX LINE
            toggleTaxLine(taxLineIndex) {
                const isSelected = !this.isInThisTaxLine(taxLineIndex);

                const originalBucketData = new FIMenuBuyersOrderBucket(this.bucket);

                //GET THE NEW TAX INDEX THIS ITEM WILL HAVE AFTER TOGGLE
                let newTaxIndex = this.bucket.taxIndex;
                let newUpfrontTaxIndex = this.bucket.upfrontTaxIndex;

                if (this.bo.isLease()) {
                    //GET THE NEW UPFRONT TAX INDEX THIS ITEM WILL HAVE AFTER TOGGLE
                    const upfrontFeeTaxBinaryArray = util.taxIndexToBinaryArray(this.bucket.upfrontTaxIndex);
                    upfrontFeeTaxBinaryArray[taxLineIndex] = isSelected ? '1' : '0';
                    newUpfrontTaxIndex = util.binaryArrayToTaxIndex(upfrontFeeTaxBinaryArray);
                }
                else {
                    //GET THE NEW TAX INDEX THIS ITEM WILL HAVE AFTER TOGGLE
                    const feeTaxBinaryArray = util.taxIndexToBinaryArray(this.bucket.taxIndex);
                    feeTaxBinaryArray[taxLineIndex] = isSelected ? '1' : '0';
                    newTaxIndex = util.binaryArrayToTaxIndex(feeTaxBinaryArray);
                }

                //CREATE NEW BUCKET WITH THE NEW TAX INDEX
                this.bucket = this.bo.addNewDefaultBucket(this.modal.itemInfo.bucketType, newTaxIndex, newUpfrontTaxIndex, originalBucketData, null, this.bucket.originalTitleId);
                this.fee.titleId = this.bucket.titleId;
            },
            clearFeeDetails() {
                this.fee.name = null;
                this.fee.amount = null;
                this.fee.cost = null;
            },
            deleteFee() {
                const result = confirm('Are you sure you want to delete this fee?');
                if (result) {
                    this.bo.removeFee(this.feeIndex);
                    this.bucket.removeItem(this.originalName)
                    $modal.close(this.bo);
                }
            },
            cancel() {
                $modal.cancel();
            },
            save() {
                let item = this.bucket.items.find(i => i.name == this.originalName)
                if (item) {
                    item.name = this.fee.name
                    item.cost = this.fee.cost
                }
                else {
                    item = new FIMenuBuyersOrderBucketItem({
                        amount: this.fee.amount,
                        cost: this.fee.cost,
                        profitFlag: this.fee.profitFlag,
                        name: this.fee.name,
                        sign: 1,
                        metadata: this.fee.metadata ?? null,
                        bucket: this.bucket
                    });

                    this.bucket.items.push(item);
                }

                item.profit = this.fee.amount - this.fee.cost;

                $modal.close(this.bo);
            }
        },
        components: {
            InputTextbox,
            InputRichDropdown,
            Panel,
            InputNumber,
            InputCurrency,
            InputCheckbox
        }
    };
</script>
<style>
    .bucket-list {
        margin-left: 15px;
    }

    .bucket-type-title {
        height: 25px;
    }

    .ModalAddEditFee .description {
        justify-content: flex-start;
    }

    .ModalAddEditFee .panel-header .InputDropdown .input-component .saturn-textbox select {
        padding: 5px 30px 5px 15px;
    }
</style>
