<template>
    <div>
        <div class="buyers-order-row" :style="$grid(hideProfits ? '25px-1-30px-125px' : '25px-1-30px-125px-125px-125px', '0px 5px') " v-if="!hideSubTotal">
            <button class="button-span ignore-all-locks header-collapse-button" @click="toggle">
                <i :class="['far', (isOpen) ? 'fa-minus-square' : 'fa-plus-square']" />
            </button>
            <div>
                <span class="subtotal-container">{{ bucketType }}</span>
                <span v-if="addNewItemPrompt" class="new-tax-line ignore-deal-lock">
                    <button class="button button-edit" @click="onAddNewItemClick()" v-show="isOpen" :disabled="costLockCondition()"><i class="fas fa-plus"></i> {{ addNewItemPrompt }}</button>
                </span>
            </div>
            <div class="bo-bucket-sign">
                <div v-if="!areBucketsValid" class="error-text bo-bucket-error"><i class="fas fa-exclamation-circle"></i></div>
                <span v-if="sign == 1">+</span>
                <span v-else-if="sign == -1">-</span>
                <span v-else>+</span>
            </div>
            <div class="amt subtotal-container">
                <InputCurrency :saturn="bucketTypeSubtotalABS"
                               :disabled="true"
                               :precision="2"
                               iconSide="left"
                               :negative="true"
                               :invalid="!areBucketsValid">
                    <template #validation>
                        <div v-if="!areBucketsValid">
                            Allowance is Required
                        </div>
                    </template>
                </InputCurrency>
            </div>
            <div class="amt subtotal-container" v-show="!hideProfits">
                <InputCurrency v-if="(bucketTypeCost != null || showCostAndProfitEvenIfNull)"
                               :saturn="bucketTypeCost"
                               :disabled="true"
                               :precision="2"
                               iconSide="left"
                               :negative="true"
                               :invalid="!areBucketsValid">
                    <template #validation>
                        <div v-if="!areBucketsValid">
                            Actual Cash Value is Required
                        </div>
                    </template>
                </InputCurrency>
            </div>
            <div class="amt subtotal-container" v-show="!hideProfits">
                <InputCurrency v-if="(bucketTypeProfit != null || showCostAndProfitEvenIfNull)"
                               :saturn="bucketTypeProfit"
                               :disabled="true"
                               :precision="2"
                               iconSide="left"
                               :negative="true">
                </InputCurrency>
            </div>
        </div>
        <div v-if="isOpen">
            <div class="buyers-order-row" v-for="item in items" :key="item.name" :style="$grid(hideSubTotal ? hideProfits? '25px-1-30px-125px' : '25px-1-30px-125px-125px-125px' : hideProfits ? '25px-1-30px-115px': '25px-1-30px-115px-125px-125px', '0px 5px') ">
                <template v-if="getSlotName(item.name)">
                    <slot :name="getSlotName(item.name)"></slot>
                </template>
                <template v-else>
                    <div>
                        <span v-if="shouldWeShowUpFrontOrCappedIndicator()" class="buyers-order-upfront-capped-icon">
                            <span :title="item.isFront ? 'Upfront' : 'Capped'">
                                <Pill :label="item.isFront ? 'U' : 'C' " :status="item.isFront ? 'success' : 'warning'" :size="10" :includeDot="false"></Pill>
                            </span>
                        </span>
                    </div>

                    <component :is="(isClickable()) ? 'button' : 'div'" @click="onItemClick(item)" :class="{'bucket-item-name-container': true, 'button-link': isClickable(), 'ignore-all-locks': true}">
                        <div class="item-text">
                            <div class="bucket-item-name">
                                {{ item.name }}
                            </div>
                            <div class="amt preload-amt">
                                <span v-if="item.metadata && item.metadata.preloadAmount">Preloaded: {{ $filters.currency2(item.metadata.preloadAmount) }}</span>
                                <div class="rollup-container">
                                    <Pill v-if="item.metadata && item.metadata.rollupTitle" :label="item.metadata.rollupTitle" status="info" :size="10" :includeDot="false"></Pill>
                                </div>
                            </div>
                        </div>
                        <div v-if="item.metadata && item.metadata.subName" class="bucket-item-subname">
                            {{ item.metadata.subName }}
                        </div>

                    </component>

                    <div>
                        <span v-if="item.sign == 1">+</span>
                        <span v-else-if="item.sign == -1">-</span>
                    </div>
                    <div class="amt">
                        <InputCurrency v-model:saturn="item.amount"
                                       :modelValue="item.amount"
                                       :disabled="!item.allowChangeAmount"
                                       :precision="2"
                                       iconSide="left"
                                       @change="updateSourceAmount(item)"
                                       :negative="true"
                                       :invalid="!item.isAmountValid()">
                            <template #validation>
                                <div v-if="!item.isAmountValid()">
                                    Allowance is Required
                                </div>
                            </template>
                        </InputCurrency>
                    </div>
                    <div class="amt ignore-deal-lock" v-show="!hideProfits">
                        <InputCurrency v-if="(item.cost != null || item.showCostAndProfitEvenIfNull)"
                                       v-model:saturn="item.cost"
                                       :disabled="isClickable() || item.allowChangeCost == false || costLockCondition()"
                                       :precision="2"
                                    iconSide="left"
                                       @change="updateSourceCost(item)"
                                       :negative="true"
                                       :invalid="!item.isCostValid()"
                                       @blur="() => onBlurCost(!item.isCostValid())">
                            <template #validation>
                                <div v-if="!item.isCostValid()">
                                    Actual Cash Value is Required
                                </div>
                            </template>
                        </InputCurrency>
                    </div>
                    <div class="amt" v-show="!hideProfits">
                        <InputCurrency v-if="(item.profit != null || item.showCostAndProfitEvenIfNull)"
                                       v-model:saturn="item.profit"
                                       :disabled="true"
                                       :precision="2"
                                       iconSide="left"
                                       :negative="true">
                        </InputCurrency>
                    </div>
                    <div v-if="!hideSubTotal"></div>
                </template>

            </div>
        </div>

    </div>
</template>


<script>

    import InputCurrency from '@core/components/InputCurrency.vue'
    import Pill from '@core/components/Pill.vue';
    import util from '@core/services/util'

    export default {
        name: "BuyersOrderBucket",
        props: ['bo', 'bucketType', 'taxable', 'showItems', 'hideSubTotal', 'addNewItemPrompt', 'hideProfits', 'costLockCondition', 'slotContainerProperties', 'sourceData'],
        emits:['change', 'onItemClick', 'onAddNewItemClick', 'blur'],
        data() {
            return {
                isOpen: false,
                actionInfo: null
            }
        },
        computed: {
            bucketTypeSubtotalABS() {
                return Math.abs(this.bucketTypeSubtotal);
            },
            bucketTypeSubtotal() {
                return this.buckets.map(b => b.bucketTotal).reduce((a, b) => a + b, 0);
            },
            bucketTypeCost() {

                if (this.buckets.every(b => b.cost == null))
                    return null;

                return this.buckets.map(b => b.cost).reduce((a, b) => a + b, 0);
            },
            bucketTypeProfit() {

                if (this.buckets.every(b => b.profit == null))
                    return null;

                return this.buckets.map(b => b.profit).reduce((a, b) => a + b, 0);
            },
            bucketTypeHasItems() {
                return this.buckets.some(b => b.items() && b.items().length > 0);
            },
            showCostAndProfitEvenIfNull() {
                return this.buckets.some(b => b.items.some(i => i.showCostAndProfitEvenIfNull == true));
            },
            sign() {
                if (this.bucketTypeSubtotal > 0)
                    return 1;
                else if (this.bucketTypeSubtotal < 0)
                    return -1;
                else
                    return 0;
            },
            buckets() {
                return this.bo.getBuckets(this.bucketType, this.hasProp('taxable') ? this.taxable : null);
            },
            areBucketsValid() {
                return this.bo.areBucketsValid(this.buckets);
            },
            items() {
                return this.buckets.reduce((allItems, bucket) => {
                    return allItems.concat(bucket.items);
                }, [])
                    .sort((a, b) => {
                        if (util.hasValue(a.metadata)
                            && util.hasValue(a.metadata.order)
                            && util.hasValue(b.metadata)
                            && util.hasValue(b.metadata.order)) {

                            if (a.metadata.order > b.metadata.order)
                                return 1;
                            else
                                return -1
                        }
                        else {
                            return 0;
                        }
                    });
            },
        },
        created() {

            if (this.hideSubTotal || !this.areBucketsValid)
                this.isOpen = true;
        },
        methods: {
            getSlotName(itemName) {
                const bucket = this.buckets.find(bucket => bucket.items.some(item => item.name === itemName));
                if (!bucket || !this.slotContainerProperties?.includes(bucket.name)) return;

                return bucket.name;
            },
            shouldWeShowUpFrontOrCappedIndicator() {
                return this.bo.isLease();
            },
            hasProp(name) {
                return this.$props[name] !== undefined;
            },
            isClickable() {
                if (this.bucketType.toLowerCase() == 'fees' || this.bucketType.toLowerCase() == 'additions') {
                    return true;
                }
                else {
                    return false;
                }
            },
            onItemClick(item) {
                if (this.isClickable()) {
                    this.$emit("onItemClick", { bucketType: this.bucketType, item: item });
                }
            },
            onAddNewItemClick(item) {
                this.$emit("onAddNewItemClick", { bucketType: this.bucketType });
            },
            toggle() {
                this.isOpen = !this.isOpen;
            },
            itemsWithAmount(items) {
                return items; // items.filter(item => item.amount > 0 || this.showItems);
            },
            updateSourceAmount(item) {
                if (this.sourceData) {
                    item.updateSourceAmount(this.sourceData, this.bucketType, this.bo);
                    this.$emit('change');
                }
            },
            updateSourceCost(item) {
                if (this.sourceData) {
                    item.updateSourceCost(this.sourceData, this.bucketType, this.bo);
                    this.$emit('change');
                }
            },
            onBlurCost(isCostInvalid) {
                this.$emit('blur', isCostInvalid);
            },
        },
        components: {
            InputCurrency,
            Pill
        }
    };
</script>

<style>
    .preload-amt {
        padding-right: 10px;
    }

    .preload-amt .rollup-container {
        display: flex;
        justify-content: center;
    }

    .preload-amt .rollup-container .pill-container {
        width: 50px;
        justify-content: center !important;
    }

    .fa-stack.fa-wrapper {
        position: relative;
    }

    span.buyers-order-upfront-capped-icon {
        font-size: 19px;
        display: inline-block;
    }

    .buyers-order-upfront-capped-icon:hover {
        cursor: pointer;
    }

    i.fa-upfront {
        font-size: 8px;
        top: 4px;
        position: absolute;
    }

    .fa-capped {
        background-color: black;
        top: 16px;
        left: -6px;
        position: absolute;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .fa-capped i {
        font-size: 8px;
        color: white;
    }
    .bucket-item-name {
        width: 100%
    }

    .bucket-item-name-container.clickable {
        color: var(--main-color);
    }

    .bucket-item-name-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100% !important;
    }

    .bucket-item-name-container .item-text {
        display:flex;
        align-items: center;
        padding-right: 5px;
        width: 100%;
    }

    .bucket-item-subname {
        position: relative;
        font-size: 13px;
        color: var(--black-60percent);
        overflow: hidden;
        width: 100%;
    }
        .bucket-item-subname .content {
            position: absolute;
            text-wrap: nowrap;
        }

    .bo-bucket-sign {
        position: relative;
    }

    .bo-bucket-error {
        position: absolute;
        right: 45px;
    }
</style>