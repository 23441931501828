<template>
    <div :class="{'app-container': true, 'development' : isUserInDev} " @click="bodyClicked">

        <MultiStreamRecorder ref="multiStream"/>
        <notifications group="saturn" dangerouslySetInnerHtml/>
        <ModalManager />

        <TheHeader />
        <TheNav />

        <div class="routed-page">
            <router-view
                :key="$route.fullPath"
                :meetingsWithWaitingCustomers="meetingsWithWaitingCustomers"
            />
        </div>

        <button
            class="support-button"
            @click="clickHelp
        ">
            <i class="fas fa-question-circle" />
            Help
        </button>

        <div
            v-if="settings.environmentName != 'PRODUCTION'"
            class="staging-overlay"
        />
    </div>
</template>

<script>
    import auth from "@core/services/auth";
    import util, { EventBusCore } from "@core/services/util";
    import api from "@core/services/api";
    import settings from "settings";
    import LogRocket from "logrocket";
    import TheNav from "@/components/TheNav.vue";
    import TheHeader from "@/components/TheHeader.vue";
    import ModalManager from "@core/components/ModalManager.vue";
    import MultiStreamRecorder from '@core/components/MultiStreamRecorder.vue';
    import modalHelp from "@core/modals/modalHelp.vue";
    import $modal from "@core/services/modal";

    export default {
        name: "app",
        data() {
            return {
                currentQuery: null,
                routeWatch: null,
                meetingsWithWaitingCustomers: [],
                helpOpen: false,
            };
        },
        components: {
            TheHeader,
            TheNav,
            ModalManager,
            MultiStreamRecorder,
        },
        computed: {
            payload() {
                return auth.getTokenPayload();
            },
            disableAll() {
                return this.$global.disableAllInput;
            },
            settings() {
                return settings;
            },
            isUserInDev() {
                return this.settings.environmentName === "DEVELOPMENT";
            },
        },
        mounted() {
            if (/iPAD|iPHONE|iPOD|OS|MAC/.test(navigator.userAgent.toUpperCase())) {
                console.log("This is an iOS device.");
                 document.getElementById('app').classList.add('ipadMode')
            }
        },
        async created() {
            EventBusCore.on("toastr", (notifData) => {
                const {title, type, duration, text} = notifData

                this.$notify({
                    group: "saturn",
                    title: title + "<div style='position: absolute; top: 7px; right: 15px;'>x</div>",
                    type: type,
                    duration: duration,
                    text: util.formatToastrText(text),
                    closeOnClick: true,
                });
            });

            //to not allow multiple help modals open at the same time
            this.EventBus.on("helpOpen", () => {
                this.helpOpen = false;
            });

            if (localStorage.getItem("Saturn-Theme") == "") {
                localStorage.setItem("Saturn-Theme", this.payload.EmployeeTheme);
            }

            document.body.setAttribute("class", localStorage.getItem("Saturn-Theme"));

            const colorScheme = window.getComputedStyle(document.body)?.getPropertyValue("color-scheme");
            document.body.classList.add(colorScheme === "dark" ? "dark-palette" : "light-palette");

            // Check meeting singleton to see if there are any meetings without an fimanager in them (customer is waiting)
             setInterval(async () => {
                 const response = await api.meeting.meetingsWithWaitingCustomers();
                 if (response !== null || response !== undefined) {
                     this.meetingsWithWaitingCustomers = response.data ?? [];
                 }
                 //console.log('checking meeting singleton for meetings with waiting customers', this.meetingsWithWaitingCustomers);

             }, 5000)
            this.setupLogRocket();
        },
        methods: {
            clickHelp() {
                if (!this.helpOpen) {
                    this.helpOpen = true;
                    $modal.open(modalHelp, {
                        name: "modalHelp",
                        passedData: { login: true, isOpen: this.helpOpen },
                        postFunction: () => {
                            return;
                        },
                        backdrop: false,
                    });
                }
            },
            bodyClicked(e) {
                this.EventBus.emit("bodyClicked", e);
            },
            setupLogRocket() {
                //login - name - date - User_Session

                //Explicit Start new session if we refresh after 24hr period
                if (util.getCookie("_lr_saturn_init") === null) {
                    LogRocket.startNewSession();
                }

                LogRocket.identify(
                    `${this.payload.EmployeeLogin} - ${this.payload.EmployeeName} - ${util
                        .toMoment(Date.now())
                        .format("MM/DD/YYYY")} - User_Session`,
                    {
                        user: this.payload.EmployeeLogin,
                        name: this.payload.EmployeeName,
                        employeeCode: this.payload.EmployeeCode,
                        email: this.payload.EmployeeEmail,
                        storeCode: this.$global.selectedStore.storeCode,
                        environment: settings.environmentName,
                    }
                );
            },
        },
    };
</script>

<style>
    .support-button {
        width: 70px;
        position: fixed;
        bottom: 10px;
        right: 10px;
        /*big number good*/
        z-index: 9999999999999999999;
        color: var(--background-color);
        background: var(--main-color);
        text-decoration: none;
        font-size: 15px;
        width: 4%;
        height: 40px;
        border-radius: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 5px;
        padding-right: 5px;
        gap: 5px;
    }

    body:has(.modals-container:not(:empty)) footer {
        z-index: 1;
    }

    div#app {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .app-container .staging-overlay {
        pointer-events: none;
        box-shadow: inset 0 0 5px 5px orangered;
        animation: 1s ease-in-out 1 show-staging-overlay;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999999999999999;
    }

    @keyframes show-staging-overlay {
        from {
            box-shadow: inset 0 0 5px -2px orangered;
        }

        to {
        }
    }

    body:has(.app-container.development) iframe#launcher-frame {
        display: none;
    }

    .app-container .routed-page {
        --nav-width-padded: calc(var(--nav-width) + 15px);
        width: calc(100% - var(--nav-width-padded));
        height: calc(100% - var(--header-height) - var(--footer-height));
        overflow: auto;
    }

    .routed-page h1 {
        margin-top: 0;
        color: var(--third-color);
    }
</style>
