<template>
    <Panel title="Verified Insurance" class="panel-autocomplete-insurance">
        <template #HeaderComponent>
            <LoaderComponent v-if="isBusy" />
        </template>

        <div v-if="!verifiedPolicy">
            <p v-if="isBusy">Loading...</p>
            <p v-else>Insurance could not be verified by AutoComplete.</p>
        </div>

        <div v-else>
            <section class="insurance-header">
                <div class="insurance-basic-info">
                    <div class="policy-name">
                        <h1>{{ verifiedPolicy.carrierName }}</h1>
                        <span>{{ verifiedPolicy.carrierPolicyNumber }}</span>
                    </div>
                    <div class="flex-group">
                        <div class="field-value">
                            <b>Effective:</b>
                            <span>{{ formatDate(verifiedPolicy.effectiveDate) }}</span>
                        </div>
                        <div class="field-value">
                            <b>Expires:</b>
                            <span>{{ formatDate(verifiedPolicy.expiryDate) }}</span>
                        </div>
                    </div>
                </div>

                <div class="insurance-vehicles flex-group">
                    <div v-for="vehicle in verifiedPolicy.vehicles" :key="vehicle.vin" class="covered-vehicle">
                        <b>{{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}</b>
                        <span>{{ vehicle.vin }}</span>
                    </div>
                </div>
            </section>

            <div class="prices-container">
                <section class="insurance-coverage">
                    <h1>Coverages</h1>

                    <div :style="$grid('1-1')">
                        <div v-if="comprehensive">
                            <h2>
                                <i :class="['far', (comprehensive.hasCoverage) ? 'fa-check-circle' : 'fa-times-circle']" />
                                Comprehensive
                            </h2>

                            <div v-if="comprehensive.hasCoverage" class="field-group">
                                <div class="field-value">
                                    <b>Deductible:</b>
                                    <span>{{ formatPrice(comprehensive.deductible) }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="collision">
                            <h2>
                                <i :class="['far', (collision.hasCoverage) ? 'fa-check-circle' : 'fa-times-circle']" />
                                Collision
                            </h2>

                            <div v-if="collision.hasCoverage" class="field-group">
                                <div class="field-value">
                                    <b>Deductible:</b>
                                    <span>{{ formatPrice(collision.deductible) }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="!comprehensive && !collision">
                            <b>No coverage info found.</b>
                        </div>
                    </div>
                </section>
                <section class="insurance-liability">
                    <h1>Liability</h1>

                    <div :style="$grid('1-1')">
                        <div v-if="bodilyInjury">
                            <h2>Bodily Injury</h2>

                            <div class="field-group">
                                <div class="field-value">
                                    <b>{{ formatPrice(bodilyInjury.perIncidentLimit) }}</b>
                                    <span>per incident</span>
                                </div>
                                <div class="field-value">
                                    <b>{{ formatPrice(bodilyInjury.perPersonLimit) }}</b>
                                    <span>per person</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="propertyDamage">
                            <h2>Property Damage</h2>

                            <div class="field-group">
                                <div class="field-value">
                                    <b>{{ formatPrice(propertyDamage.perIncidentLimit) }}</b>
                                    <span>per incident</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="!bodilyInjury && !propertyDamage">
                            <b>No liability info found.</b>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </Panel>
</template>
<script setup lang="ts">
    import { computed, onBeforeMount, ref } from 'vue';
    import api from '@core/services/api';
    import util from '@core/services/util';

    import { AutoCompleteDeductible, AutoCompleteDetailedPolicy, AutoCompleteLiability } from '@core/classes/Insurance/AutoCompleteModels';
    import { AutoCompleteExchange } from '@core/classes/Insurance/AutoCompleteData';
    import { AutoCompleteVerificationResponse } from '@core/classes/Insurance/AutoCompleteResponses';
    import DocumentAssociation from '@core/classes/DocumentAssociation';
    import ENUMS from '@core/classes/Enums';
    
    import LoaderComponent from '@core/components/LoaderComponent.vue';
    import Panel from '@core/components/Panel.vue';

    interface IPanelAutoCompleteInsuranceProps {
        customerId?: string;

        /** If you want to pass in the autoComplete data externally */
        autoCompleteVerification?: AutoCompleteVerificationResponse;
    }

    const props = defineProps<IPanelAutoCompleteInsuranceProps>();

    const customerAssociation = computed((): DocumentAssociation => {
        return new DocumentAssociation({ type: ENUMS.DOC_ASSOCIATION.Customer, id: props.customerId });
    });

    const autoCompleteData = ref<AutoCompleteVerificationResponse>(null);

    const verifiedPolicy = computed((): AutoCompleteDetailedPolicy => {
        const policy = autoCompleteData.value?.person?.policies[0];

        // If carrier name isn't populated, assume we received an empty object
        return (policy?.carrierName) ? policy : null;
    });

    const comprehensive = computed((): AutoCompleteDeductible => {
        return verifiedPolicy.value?.coverages?.comprehensive;
    });

    const collision = computed((): AutoCompleteDeductible => {
        return verifiedPolicy.value?.coverages?.collision;
    });

    const bodilyInjury = computed((): AutoCompleteLiability =>{
        return verifiedPolicy.value?.coverages?.bodilyInjuryLiability;
    });

    const propertyDamage = computed((): AutoCompleteLiability => {
        return verifiedPolicy.value?.coverages?.propertyDamageLiability;
    });

    const isBusy = ref(false);

    onBeforeMount(async () => {
        if (props.autoCompleteVerification)
            autoCompleteData.value = props.autoCompleteVerification;
        else 
            await loadData();
    });

    const loadData = async () => {
        isBusy.value = true;

        try {
            const { data: responseData } = await api.autoComplete.findLatestExchangeResults([customerAssociation.value], true, AutoCompleteExchange.Verification);
            autoCompleteData.value = responseData;
        }
        catch (err) {
            console.error(err);
            util.toastr('error', 'Error Loading Insurance', 'An error occurred when trying to fetch insurance data.');
        }
        finally {
            isBusy.value = false;
        }
    };

    const formatDate = (dateStr: string|Date): string => {
        if (util.isNullOrEmpty(dateStr)) return '[NOT FOUND]';
        return new Date(dateStr).toLocaleDateString('en-US', { dateStyle: 'medium' });
    };

    const formatPrice = (cents: number): string => {
        if (!cents) return '$0.00';
        return `$${Number(cents / 100).toLocaleString(undefined, { maximumFractionDigits: 2 })}`;
    };
</script>
<style>
    .panel-autocomplete-insurance h1 {
        margin: unset;
        font-size: 2rem;
        color: var(--main-color);
    }

    .panel-autocomplete-insurance h2 {
        margin: unset;
        font-size: 1.5rem;
    }

    .panel-autocomplete-insurance .flex-group {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .panel-autocomplete-insurance .insurance-header {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

        .panel-autocomplete-insurance .insurance-header .insurance-basic-info {
            min-width: 320px;
        }

        .panel-autocomplete-insurance .insurance-header .policy-name {
            display: flex;
            gap: 10px;
            align-items: center;
        }

            .panel-autocomplete-insurance .insurance-header .policy-name h1 {
                text-transform: uppercase;
                padding-right: 1rem;
                border-right: 1px solid var(--border-color);
            }

            .panel-autocomplete-insurance .insurance-header .policy-name span {
                font-weight: bold;
            }

    .panel-autocomplete-insurance .insurance-vehicles .covered-vehicle {
        padding: 10px;
        background-color: var(--main-color);
        color: var(--button-color);
        border-radius: 10px;
        text-transform: capitalize;
    }

        .panel-autocomplete-insurance .insurance-vehicles .covered-vehicle > * {
            display: block;
        }

    .panel-autocomplete-insurance .field-value {
        display: flex;
        gap: 5px;
    }

    .panel-autocomplete-insurance .prices-container {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }

        .panel-autocomplete-insurance .prices-container > * {
            flex: 1;
            min-width: 450px;
        }

    .panel-autocomplete-insurance .insurance-coverage .fa-check-circle {
        color: var(--success-color);
    }

    .panel-autocomplete-insurance .insurance-coverage .fa-times-circle {
        color: var(--error-color);
    }

    .panel-autocomplete-insurance .insurance-coverage,
    .panel-autocomplete-insurance .insurance-liability {
        padding: 10px;
        background-color: var(--card-background-color);
        border-radius: 10px;
        border: 1px solid var(--border-color);
    }
</style>