<template>
    <div v-if="showDashboard" class="page">
        <h1>Dashboard</h1>
        <hr />
        <!--<h2>Sales</h2>
        <div class="grid">
            <div class="grid-box">
                <div class="dashboard-label">Cash Deals</div>
                <div class="dashboard-data">$100,000</div>
            </div>
            <div class="grid-box">
                <div class="dashboard-label">Finance Deals</div>
                <div class="dashboard-data">$100,000</div>
            </div>
            <div class="grid-box">
                <div class="dashboard-label">Lease Deals</div>
                <div class="dashboard-data">$100,000</div>
            </div>
        </div>

        <h2>Accounting</h2>
        <div class="grid">
            <div class="grid-box">
                <div class="dashboard-label">Sales</div>
                <div class="dashboard-data">$100,000</div>
            </div>
            <div class="grid-box">
                <div class="dashboard-label">Sales</div>
                <div class="dashboard-data">$100,000</div>
            </div>
            <div class="grid-box">
                <div class="dashboard-label">Sales</div>
                <div class="dashboard-data">$100,000</div>
            </div>
            <div class="grid-box">
                <div class="dashboard-label">Sales</div>
                <div class="dashboard-data">$100,000</div>
            </div>
        </div>-->
    </div>
</template>


<script>
    import auth from '@core/services/auth';

    export default {
        name: "Dashboard",
        data() {
            return {
                showDashboard: false
            };
        },
        computed: {
        },
        created() {
            if (auth.hasAccessToPermission('SalesPerson')) {
                this.$router.push("/customers")
            }
            else if (auth.hasAccessToPermission('UserMgt')) {
                this.$router.push("/admin/users")
            }
            else if (auth.hasAccessToPermission('TitlingClerk')){
                this.$router.push("/fimenudeals")
            }
            else {
                this.showDashboard = true
            }
        },
        methods: {

        },
        components: {

        }
    };
</script>

<style>
    .grid {
        display: inline-grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .grid-box {
        margin-right: 15px;
        border: 1px solid var(--main-color);
        padding: 15px;
        border-radius: 5px;
        border-left: 8px solid var(--main-color);
    }

    .dashboard-label {
        font-weight: 100;
        margin-bottom: 20px;
    }

    .dashboard-data {
        font-weight: bold;
        font-size: 25px;
    }
</style>
