<template>
    <div class="floating-menu-item menu-list" @click="action">
        <div class="inner-container">
            <span class="fa-stack fa-1x">
                <i :class="icon + ' fa-stack-1x menu-icon'" />
            </span>
            <span class="option-text">{{ label }}</span>
        </div>
    </div>

</template>
<script>
    export default {
        name: "FloatingMenuItem",
        props: {
                action: {
                    required: true,
                    type: Function
                },
                icon: {
                    required: false,
                    type: String,
                    default: ""
                },
                label: {
                    required: true,
                    type: String
                }
            },
    }
</script>

<style>
    /* .floating-menu-item{

    }

    .floating-menu-item {
        position: relative;
        display: flex;
        align-items: center;
        gap: 5px;
    } */


    .floating-menu-item.menu-list {
        padding: 5px 3px;
        font-size: 11pt;
        transition: var(--transition);
    }

    .floating-menu-item.menu-list:nth-child(2n) {
        background: var(--richtable-alternating-background-color);
    }

    .floating-menu-item.menu-list:last-of-type {
        border-bottom: none;
    }

    .floating-menu-item.menu-list .inner-container {
        padding: 3px 2px;
        color: var(--text-color);
        transition: var(--transition);
        width: 100%;
    }

    .floating-menu-item.menu-list:hover {
        background-color: var(--user-info-container-background-color);
        cursor: pointer;
    }

        .floating-menu-item.menu-list:hover .inner-container {
            color: var(--main-color);
        }

    .floating-menu-item.menu-list .inner-container .option-text {
        margin-left: 5px;
        font-weight: 500;
    }

    .floating-menu-item.menu-list .inner-container .menu-icon {
        position: relative;
    }

    .floating-menu-item.menu-list .inner-container .menu-icon::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 80%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: var(--black-30percent);
        border-radius: 4px;
        z-index: -1;
    }
</style>