<template>
    <div>
		<BookValueEntry
			v-for="(bv, index) in bookValues"
			:key="bv.source"
			:isVehicleNew="isVehicleNew"
			:bookValueEntry="bv"
			:disableDropDown="disableDropDown"
			:dropDownValues="dropDownValues"
			:validation="GetEntryValidation(index)"
			ref="BV-Entry"
		/>
    </div>
</template>
<script lang="ts">

interface ValidatedComponent extends Element {
    v$: any; // Specify the type according to your validation structure
}

// Type guard to check if a component is a ValidatedComponent
function isValidatedComponent(ref: any): ref is ValidatedComponent {
    return ref && 'v$' in ref; // Check if $v exists in the ref
}

import BookValueEntry, {BookValueDropDown} from '@/components/BookValueEntry.vue';
import {defineComponent, PropType} from 'vue'
import { GetBookValueDropdownValues } from '@/helpers/fimenuvehicle-helper';
import {VehicleBookValue} from '@core/classes/VehicleBookValue';

export default defineComponent ({
    name: 'BookValueCRUD',
	props: {
		bookValues: Array as PropType<VehicleBookValue[]>,
		disableDropDown: {
			type: Boolean,
			default: false
		},
		dropDownValues: {
			type: Array as PropType<BookValueDropDown[] | null>,
			default: null
		},
		isVehicleNew: {
			type: Boolean,
			default: false
		},
		validation: {
			type: Object,
			default: null,
			required: false
		}
	},
    components: {BookValueEntry},
    created() {},
    computed: {
		BookValueCompanies(): BookValueDropDown[] {
			return this.dropDownValues ?? GetBookValueDropdownValues()
		},
		AnyEntryIsInvalid(): boolean {
			// New setup for vuelidate. Swap $v with v$
			const entryRef = this.$refs["BV-Entry"];

			// Function to check validation on each ref
			function checkValidation(ref: any): boolean{
				return ref.v$ ? ref.v$.$invalid : false; // Access the $invalid property
			};

			// Handle array of refs
			if (Array.isArray(entryRef)) {
				// Use type guard to filter ValidatedComponents
				return entryRef.some(ref => isValidatedComponent(ref) && checkValidation(ref));
			} else {
				// Handle single ref with type guard
				return isValidatedComponent(entryRef) ? checkValidation(entryRef) : false;
			}
		}
	},
    methods: {
		GetEntryValidation(index: number): object | null {
			return this.validation?.[index];
		}
	},
})
</script>
<style scoped>

</style>