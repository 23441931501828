<template>
	<IsBusySectionComponent v-if="busyLoadingData || isLoading"/>
	<div class="customer-body" v-else>
		<AccordionInfoApplication
			v-if="((isCustomerCreating && selectedStoreCode) || (selectedCustomerId)) && displayCreateLead"
			:store="selectedStore"
			v-model:prefilledData="prefilledData"
			:applicationSent="leadCreated"
			:createLead="createLead"
			v-model:isLoading="busyLoadingData"
			:validation="v$"
		/>

		<PanelCustomer
			v-else-if="(isCustomerCreating && selectedStoreCode) || (selectedCustomerId && customer && selectedStoreCode) && displayPanelCustomer"
			v-model:customer="customer"
			:fimenu="metadata"
			:isCustomerTypeDisabled="!!customer.id"
			:fallbackzipcode="customer.zip"
			:panelObject="{ title: '' }"
			:validation="v$.customer"
			:hide="hide"
			:panelView="true"
			:needsCreditSection="true"
			:viewOnly="false"
			:displayFullSSN="true"
			:isRenderedInCustomerPage="true"
			:openDeal="openDeal"
			ref="panelCustomer"
		/>

		<!-- <AccordionCustomerQuotes
				v-if="customer && customer.id && selectedStoreCode && displayPanelCustomer"
				:Customer="customer"
				:Store="metadata.store"
				:OpenDeal="openDeal"
			/> -->

		<!-- <AccordionCustomerDeals
			v-if="customer && customer.id && selectedStoreCode && displayPanelCustomer"
			:Customer="customer"
			:Store="metadata.store"
			:OpenDeal="openDeal"
		/> -->

		<div v-else-if="!selectedStoreCode || !displayPanelCustomer" style="width: 100%; text-align: center">
			<h1 v-if="!selectedStoreCode">Please select a store</h1>
			<h1 v-else-if="!displayPanelCustomer">Please select a customer</h1>
		</div>

	</div>
</template>

<script lang="ts">
	import { defineAsyncComponent, defineComponent, PropType } from "vue";
	import { required, requiredIf } from "@vuelidate/validators";
	import AccordionInfoApplication from "@/components/panel/AccordionInfoApplication.vue";
	import api from "@core/services/api";
	import ApplicationsPreFilledData from "@core/classes/Applications/ApplicationsPreFilledData";
	import ENUMS from "@core/classes/Enums";
	import FIMenuCustomer from "@core/classes/FIMenuCustomer";
	import IsBusySectionComponent from "@/components/IsBusySectionComponent.vue";
	import LogRocket from "logrocket";
	import settings from "settings";
	import {useVuelidate} from "@vuelidate/core";
	import util from "@core/services/util";

	export default defineComponent({
		name: "CustomerDetails",
		emits:['update:isLoading', 'update:customerStores','update:selectedCustomerId', 'update:selectedStoreCode', 'update:selectedStore'],
		props: {
			selectedStoreCode: {
				type: String || null,
				default: null
			},
			selectedStore: {
				type: Object as PropType<any>,
				default: null
			},
			customer: {
				type: Object as PropType<FIMenuCustomer>,
				default: () => new FIMenuCustomer()
			},
            metadata: {
                type: Object,
                required: true
            },
            selectedCustomerId: {
                type: String || null,
                default: null
            },
			customerStores: {
				type: Array as PropType<string[]>,
				default: (): string[] => []
			},
			createCustomer: {
				type: Function,
				required: true
			},
			deals: {
				type: Array as PropType<object[]>,
				required: true,
				default: (): object[] => []
			},
			maxDealsInTab: {
				type: Number,
				default: 5
			},
			isLoading: {
				type: Boolean,
				required: true
			},
			openDeal: {
				type: Function,
				required: true
			},
			isCustomerCreating: {
				type: Boolean,
				required: true
			}

		},
		setup(){
			return { v$: useVuelidate({$scope: false})}
		},
		data() {
			return {
				prefilledData: new ApplicationsPreFilledData(),
				leadCreated: false,
				hide: ["driversLicense"],
				busyLoadingData: false
			};
		},
		methods: {

			unsavedRedflags() {
				return (this.$refs as any)?.panelCustomer?.hasUnsavedRedflagsChanges;
			},
			async loadData() {

                this.$emit('update:isLoading', true);

				try {
					const { data: retrievedCustomer } = await api.customers.getCustomerById(this.selectedCustomerId);

					if (retrievedCustomer) {
						this.customer.setFromCustomer(retrievedCustomer);
						// Hide stores that the FIManager doesnt have access to.
						const customerStores = retrievedCustomer.storeCodes.filter((customerStoreCode: string) => {
							return settings.userStores.find((store: any) => store.storeCode == customerStoreCode);
						});

						this.$emit('update:customerStores', customerStores);
					}

					if (this.selectedStoreCode) {
						this.metadata.storeCode = this.selectedStoreCode;
						this.metadata.store = settings.userStores.find((x: any) => x.storeCode == this.selectedStoreCode);
						this.metadata.buyersOrderEnabled = this.metadata.store.storeSettings.buyersOrderEnabled;
						this.$emit('update:selectedStore', this.metadata.store);
					}

					await this.customer.getAndSetCustomerApplication();
				} catch (error: any) {
					util.toastr("error", "Retrieving Customer", error.message || error);

					console.error(error);
				} finally {
                    this.$emit('update:isLoading', false);
				}
			},
			handleRequestSwitchCustomerTab(tabName: string, customerId: string) {
				util.setQueryParm("customerId", customerId, this.$router);
				this.$emit('update:selectedCustomerId', customerId);
				(this.$refs as any).tabMenu.switchTab(tabName); // Should technically be the last one.
			},
			async createLead() {
				try {
					this.$emit('update:isLoading', true);
					if (this.displayCreateLead) {
						// Creates lead and cusotmer application

						const response = await api.applications
							.createApplication({
								baseUrl: window.location.host,
								isInStore: false,
								prefilledData: { ...this.prefilledData, storeCode: this.selectedStoreCode },
								createAppParams: {
									language: this.prefilledData.language,
									leadSource: "instore",
								}
							})
							.catch((err: any) => {

								console.error(err);
								util.toastr("error", "Error", "Could not create customer.");
							})
							.then((response: any) => {
								if (!response) {
									return util.toastr("error", "Error", "Could not create customer.");
								}

								const leadId = response.data?.leadId;
								LogRocket.track(`Lead_Created`, {
									leadId: leadId,
									customerCell: this.prefilledData.phoneNumber,
									customerEmail: this.prefilledData.emailAddress,
									customerDob: this.prefilledData.dateOfBirth,
									storeName: this.metadata.store.storeName,
									environment: settings.environmentName
								});
								this.leadCreated = true;
								util.toastr("success", "Success", "Customer created and email sent to client.");
							});
					} else if (this.displayPanelCustomer) {
						// Creates customer.
						await this.createCustomer();
					}
				} catch (err) {

					console.error(err);
				} finally {
					this.$router.push({
						name: "customers",
						query: { ct: "leads" }
					});

					this.$emit('update:isLoading', false);
				}
			},

		},
		computed: {
			storeCanRetrieveApplication(): boolean {
				return (
					this.selectedStore?.storeSettings?.customerApplication ==
					ENUMS.CUSTOMER_RETRIEVAL_STATUS.RetrieveApplicationEnabled
				);
			},
			hasCustomer(): boolean {
				return !!this.customer.id;
			},
			hasCustomerApplication(): boolean {
				return !!this.customer?.customerApplication?.id;
			},
			hasAgreedTerms(): boolean {
				return this.customer.customerApplication?.hasAgreedTerms();
			},
			userStores(): boolean {
				return settings.userStores;
			},
			displayCreateLead(): boolean {
				return (
					!!this.selectedStoreCode &&
					!!this.selectedStore &&
					!this.hasCustomer &&
					this.storeCanRetrieveApplication &&
					!(this as any).$global.isAdminView
				);
			},
			displayPanelCustomer(): boolean {
				return !!this.selectedStoreCode && (!!this.selectedCustomerId || this.isCustomerCreating);
			},

		},
		validations() {

			return {
				customer: this.customer.validation(null, FIMenuCustomer.validationTypes.CUSTOMER_CREATION),

				prefilledData: {
					...this.prefilledData.validation()
				},
			};
		},

		components: {
			PanelCustomer: defineAsyncComponent(() => import("@/components/PanelCustomer.vue")),
			AccordionInfoApplication,
			IsBusySectionComponent
		}
	});
</script>
<style scoped>

	.customer-information-wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;
	}
</style>
