import VAutoLookupResponse, { PropertyToBookType } from "@core/classes/Responses/VAutoLookupResponse";
import $modal from "@core/services/modal";
import { BookValue } from "@core/classes/SharedEnums";
import FIMenu from "@core/classes/FIMenu";
import FIMenuVehicle from "@core/classes/FIMenuVehicle";
import ModalAddBookValue from "@/modals/modalAddBookValue.vue";
import util from "@core/services/util";
import { VehicleBookValue } from "@core/classes/VehicleBookValue";

export function CreateBookValues(vaResponse: VAutoLookupResponse | null): VehicleBookValue[] {
    const values: VehicleBookValue[] = [];
    if (vaResponse == null) return values;

    const wholeValues: (keyof VAutoLookupResponse)[] = ["bbWholeSale", "mmrWholesale"];
    const retailValues: (keyof VAutoLookupResponse)[] = ["jdPowerRetail"];

    wholeValues.forEach(val => {
        const value = vaResponse[val];
        if (typeof value != "number" || value <= 0) return;
        if (!PropertyToBookType[val]) return; // This get the source from a mapping.

        values.push(
            new VehicleBookValue({
                source: PropertyToBookType[val],
                retail: null,
                wholesale: value,
            }),
        );
    });

    retailValues.forEach(val => {
        const value = vaResponse[val];
        if (typeof value != "number" || value <= 0) return;
        if (!PropertyToBookType[val]) return; // This get the source from a mapping.

        const existingType = values.find(x => x.source == PropertyToBookType[val]);

        if (existingType) {
            existingType.retail = value;
        } else {
            values.push(
                new VehicleBookValue({
                    source: PropertyToBookType[val],
                    retail: value,
                    wholesale: null,
                }),
            );
        }
    });

    return values;
}

export function GetBookValueDropdownValues(
    bookValuesToNotInclude: BookValue[] = [],
): { display: string; value: BookValue }[] {
    const bookValueEnumObj = util.convertEnumToObject(BookValue); // Convert enum to object
    const bookValues = Object.entries(bookValueEnumObj); // Get entries as [key, value] pairs

    return bookValues
        .map(([display, value]) => ({ display, value })) // Create objects with display and value
        .filter(({ value }) => {
            const isExcluded = bookValuesToNotInclude.includes(value); // Check if value is in exclusion list
            const isDefault = value === BookValue.Default; // Check if value is the default

            return !isExcluded && !isDefault; // Exclude values that are either in the exclusion list or default
        });
}
export function createBookValue(
    bookValues: VehicleBookValue[],
    inventoryType: string,
    prePostFunction: (a?: any) => void = null,
    postFunction: (param?: any) => void = () => {},
) {
    // Execute on modal close.
    const defaultPostFunction = (bookValuesResponse: VehicleBookValue[]) => {
        if (prePostFunction) {
            prePostFunction(bookValuesResponse);
        } else {
            bookValuesResponse.forEach(bv => {
                // Loop through the newly updated values.
                // Get the memory location of the value to modify.
                const bookValueToUpdate = bookValues.find(
                    (x: VehicleBookValue) => x.source == bv.source,
                ) as VehicleBookValue | null;

                // If the value exists; modify its content.
                if (bookValueToUpdate) {
                    bookValueToUpdate.retail = bv.retail;
                    bookValueToUpdate.wholesale = bv.wholesale;
                } else {
                    // If we don't find it. Lets create it?
                    bookValues.push(new VehicleBookValue(bv));
                }
            });
        }

        if (postFunction) {
            postFunction(bookValuesResponse);
        }
    };

    $modal.open(ModalAddBookValue, {
        passedData: {
            inventoryType,
            bookValues: [],
            dropDownValues: GetBookValueDropdownValues(bookValues.map(x => x.source)),
        },
        postFunction: defaultPostFunction,
    });
}

export function editBookValue(
    bookValues: VehicleBookValue[],
    inventoryType: string,
    typesToEdit: BookValue[],
    displayDeleteFees: boolean = false,
    prePostFunction: (param?: any) => void = null,
    postFunction: (param?: any) => void = () => {},
): void {
    // Execute on modal close.
    const defaultPostFunction = (bookValuesResponse: VehicleBookValue[]) => {
        if (prePostFunction) {
            prePostFunction(bookValuesResponse);
        } else {
            bookValuesResponse.forEach(bv => {
                // Loop through the newly updated values.
                // Get the memory location of the value to modify.
                const bookValueToUpdate = bookValues.find(
                    (x: VehicleBookValue) => x.source == bv.source,
                ) as VehicleBookValue | null;

                // If the value exists; modify its content.
                if (bookValueToUpdate) {
                    bookValueToUpdate.retail = bv.retail;
                    bookValueToUpdate.wholesale = bv.wholesale;
                } else {
                    // If we don't find it. Lets create it?
                    bookValues.push(new VehicleBookValue(bv));
                }
            });
        }

        postFunction(bookValuesResponse);
    };

    // Filter out the values we DON'T want to edit. Only keep the ones passed in the typesToEdit parameter.
    const filteredBookValues = bookValues.filter(x => typesToEdit.includes(x.source));

    /**
     * Removes all entries from `bookValues` that match entries in `filteredBookValues` by comparing the `source` property.
     * Iterates over `filteredBookValues`, finds corresponding matches in `bookValues`, and removes them using `splice()`.
     * @example
     * deleteFees(); // Removes all matching entries in bookValues based on filteredBookValues.
     */
    const deleteFees = () => {
        filteredBookValues.forEach(a => {
            const index = bookValues.findIndex(bv => bv.source === a.source);
            if (index >= 0) {
                bookValues.splice(index, 1);
            }
        });
    };

    $modal.open(ModalAddBookValue, {
        passedData: {
            bookValues: filteredBookValues,
            inventoryType,
            disableDropDown: true,
            deleteFees: displayDeleteFees ? deleteFees : null,
        },
        postFunction: defaultPostFunction,
    });
}

export function EditBookValues(vehicle: FIMenuVehicle, inventoryType: string) {
    // Execute on modal close.
    const postFunction = (bookValues: VehicleBookValue[]) => {
        vehicle.bookValues = bookValues;
    };

    $modal.open(ModalAddBookValue, {
        passedData: { vehicle, inventoryType },
        postFunction,
    });
}

export function getValidationForBookValues(deal: FIMenu) {
    return {
        newVehicleHasManufacturer: {
            valid: () => {
                if (deal.isNew()) {
                    if (!deal.getManufacturerValues()?.retail) return false;
                    if (!deal.getManufacturerValues()?.wholesale) return false;
                }
                return true;
            },
            Message: "Please provide both MSRP and Invoice values (MSRP) to proceed",
        },
        haveMinimumOneBookValue: {
            valid: () => {
                return deal.vehicle.bookValues.length > 0;
            },
            Message: "At least one vehicle book value is required to proceed.",
        },
        haveMinimOneBookValueNewOrUsed: {
            valid: () => {
                if (deal.isNew()) {
                    return deal.vehicle.bookValues.find(
                        (x: VehicleBookValue) => x.source == BookValue.Manufacturer && !!x.retail && !!x.wholesale,
                    );
                } else {
                    return deal.vehicle.bookValues.find((x: VehicleBookValue) => !!x.retail && !!x.wholesale);
                }
            },
            Message: deal.isNew()
                ? "You're missing the manufacturer book value"
                : "You're missing at least one book value.",
        },
    };
}
