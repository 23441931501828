<template>
    <div class="progress-circle-container" :style="circleColor">
        <span class="progress-circle-outer" ref="progressCircle">
            <span class="progress-circle-inner">
                <img v-if="isLoading" src="@static/img/SaturnLogoAnim.svg" />
                <b v-else>{{ innerText }}</b>
            </span>
        </span>
        <label v-if="label">
            <b>{{ label }}</b>
            <small v-if="subtitle" style="display:block;">{{ subtitle }}</small>
        </label>
    </div>
</template>

<script>


    export default {
        name: "ProgressCircle",
        props: {
            isLoading: Boolean,
            label: String,
            subtitle: String,
            numComplete: Number,
            total: Number,
            diameter: String, // CSS Unit
            innerCircleText: String, // Overrides text displayed inside the progress circle
        },
        data() {
            return { };
        },
        computed: {
            circleColor() {
                const percentComplete = (this.total === 0) ? 0 : this.numComplete / this.total;
                if (percentComplete === 0) return '--circle-color: var(--error-color)';
                if (percentComplete < 1) return '--circle-color: var(--print-color)';
                return '--circle-color: var(--success-color)';
            },
            innerText() {
                if (this.innerCircleText) return this.innerCircleText;
                return `${this.numComplete}/${this.total}`;
            }
        },
        created() {
            this.$watch(() => this.diameter, (newVal) => {
                if (!this.$refs.progressCircle || !newVal) return;

                this.$refs.progressCircle.style.width = newVal;
                this.$refs.progressCircle.style.height = newVal;
            });

            this.$watch(() => this.numComplete / this.total, (newVal) => {
                if (!this.$refs.progressCircle) return;
                this.$refs.progressCircle.style.setProperty('--degrees', `${this.percentToDegrees(newVal)}deg`);
            });
        },
        mounted() {
            if (this.$refs.progressCircle && this.diameter) {
                this.$refs.progressCircle.style.width = this.diameter;
                this.$refs.progressCircle.style.height = this.diameter;
            }
        },
        methods: {
            percentToDegrees(percent) {
                if (Number.isNaN(percent)) return 0;
                return percent * 360;
            },
        },
        components: { },
    };
</script>
<style>

    .progress-circle-container {
        --checklist-status-color: var(--error-color);
        display: inline-block;
        margin: 10px;
        cursor: pointer;
    }

        .progress-circle-container .progress-circle-outer {
            --degrees: 0deg;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px;
            width: 60px;
            padding: 1px;
            margin: auto;
            border-radius: 50%;
            background: conic-gradient(var(--circle-color) var(--degrees), transparent calc(var(--degrees) + 0.5deg) 100%);
            color: var(--circle-color);
        }

            .progress-circle-container .progress-circle-outer::before {
                content: "";
                position: absolute;
                height: 100%;
                width: 100%;
                border-radius: 50%;
                background-color: var(--border-color);
                z-index: -1;
            }

            .progress-circle-container .progress-circle-outer .progress-circle-inner {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 80%;
                width: 80%;
                border-radius: 50%;
                background: var(--background-color);
            }

                .progress-circle-container .progress-circle-outer .progress-circle-inner img {
                    width: 70%;
                    opacity: 0.5;
                }

        .progress-circle-container label {
            text-transform: capitalize;
        }
</style>