<template>
    <div class="PanelDealAddresses">
        <div v-for="(address, index) in addresses" :key="index">
            <div class="radio-button-inline" :invalid="util.isNull(validation) ? false : validation.dealAddressesQuestions.$invalid" :style="$grid('411px-1')">
                <span>{{ addresses[index].question }}</span>
                <div class="no-padding-grid">
                    <InputRadio label="Yes"
                                :valueMatch="true"
                                v-model="addresses[index].answer"
                                @update:modelValue="(val) => answer(val, index, address)"
                                :isButton="true"/>
                    <InputRadio label="No"
                                :valueMatch="false"
                                v-model="addresses[index].answer"
                                @update:modelValue="(val) => answer(val, index, address)"
                                :isButton="true"/>
                </div>
            </div>

            <Panel :title="address.title" v-if="address.answer" :action="address.action" >
                <AddressComponent :data="fimenu.dealAddresses[indexInDealAddresses(address.type)]"
                                  :validation="validation.dealAddresses[indexInDealAddresses(address.type)]"
                                  :searchable="true"
                                  :panelView="true"
                                  :showCounty="true" />
            </Panel>
        </div>
    </div>

</template>

<script>
    import AddressComponent from '@/components/AddressComponent.vue'
    import ENUMS from "@core/classes/Enums"
    import InputRadio from '@core/components/InputRadio.vue'
    import Panel from '@core/components/Panel.vue'
    import util from '@core/services/util'

    export default {
        name: "PanelDealAddresses",
        props: ['fimenu', 'validation', 'disabled'],
        data() {
            return {
                addresses: [],
                functions: [this.copyFromMailing, this.copyFromRegistration, () => { return; }]
            }
        },
        computed: {
            util() {
                return util;
            }
        },
        created() {
            this.init()
        },
        mounted() {
            // Keep radio buttons & addresses synced with FI Menu updates
            if (this.$dealRoomHub.connected) {
                this.$watch(() => this.fimenu.dealAddressesQuestions, (newVal) => {
                    for (let i = 0; i < newVal.length; i++) {
                        if (this.addresses[i])
                            this.addresses[i].answer = newVal[i].answer;
                        
                        if (this.addresses[i])
                            this.addresses[i].values = newVal[i].values;
                    }
                }, { deep: true });
            }
        },
        methods: {
            init() {
                const editableAddresses = ENUMS.addressType.filter(a =>
                    a.type == ENUMS.addressTypeEnum.MAILING_ADDRESS ||
                    a.type == ENUMS.addressTypeEnum.REGISTRATION_ADDRESS ||
                    a.type == ENUMS.addressTypeEnum.GARAGING_ADDRESS
                );

                this.addresses = editableAddresses.map((addr, i) => {
                    const isSelectedInDeal = this.indexInDealAddresses(addr.type) > -1;
                    const dealAddress = this.fimenu.getAddress(addr.type, null);


                    return {
                        question: "Is " + addr.description + " different from Customer Address?",
                        title: addr.description,
                        answer: isSelectedInDeal,
                        action: [],
                        copy: this.functions[i],
                        values: dealAddress,
                        type: addr.type
                    };

                });

                this.populateCopyButtons()
                this.fimenu.dealAddressesQuestions = this.addresses
            },
            answer(isSelected, index, address) {
                if (this.disabled)
                    return;

                if (isSelected) {
                    this.fimenu.dealAddresses.push(address.values)
                }
                else {
                    const indexInDeal = this.indexInDealAddresses(address.type);
                    if (indexInDeal > -1) {
                        this.fimenu.dealAddresses.splice(indexInDeal, 1);
                    }
                }

                this.populateCopyButtons()
            },
            copyFromMailing(index) {
                this.copyNewValues(index, this.addresses[0].values)
            },
            copyFromRegistration(index) {
                this.copyNewValues(index, this.addresses[1].values)
            },
            indexInDealAddresses(type) {
                return this.fimenu.dealAddresses.findIndex(address => address.addressType == type)
            },

            copyNewValues(index, newObject) {
                this.fimenu.dealAddresses[index].address = newObject.address
                this.fimenu.dealAddresses[index].zip = newObject.zip
                this.fimenu.dealAddresses[index].state = newObject.state
                this.fimenu.dealAddresses[index].city = newObject.city
                this.fimenu.dealAddresses[index].addressExtra = newObject.addressExtra
                this.fimenu.dealAddresses[index].county = newObject.county
            },
            populateCopyButtons() {
                this.addresses.map(mail => {
                    mail.action = []
                })
                for (let i = 0; i <= this.addresses.length - 1; i++) {
                    if (this.addresses[i].answer) {
                        if (this.addresses[i + 1]) {
                            this.addresses[i + 1].action.push({ text: 'Copy Address from ' + this.addresses[i].title, onClick: () => { this.addresses[i].copy(this.indexInDealAddresses(i + 1)) } })
                        }
                        if (this.addresses[i + 2]) {
                            this.addresses[i + 2].action.push({ text: 'Copy Address from ' + this.addresses[i].title, onClick: () => { this.addresses[i].copy(this.indexInDealAddresses(i + 2)) } })
                        }
                    }
                }
            }

        },
        components: {
            Panel,
            AddressComponent,
            InputRadio
        }
    };
</script>
<style>
</style>