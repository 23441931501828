<template>
    <button
        @click="handleSubmit"
        :disabled="isLoading || buttonIsDisabled"
        :class="{ 'no-wrap-button': true, 'ignore-deal-lock': !dealHasPeperwork, 'r1button': true }"
    >
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin" />
        <template v-else>
            {{ hasExistingDealJacket ? "Update deal on RouteOne" : "Submit to RouteOne" }}
        </template>
    </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import FIMenu from "@core/classes/FIMenu";
import { SubmitDealJacket } from "@/helpers/finance-channels-helper";
import util from "@core/services/util";

export default defineComponent({
    name: "ButtonSubmitToFinanceInstitutions",
    components: {},
    props: {
        deal: {
            type: Object as PropType<FIMenu>,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        hasExistingDealJacket(): boolean {
            return this.deal.dealJacket?.providerId != null;
        },
        dealHasPeperwork(): boolean {
            return this.deal.paperwork?.currentPacket() != null;
        },
        buttonIsDisabled() {
            // If we don't have a deal type don't let them submit.
            if (this.deal.dealType == null) return true;

            // If we don't have a lender don't let them submit.
            if (this.deal.lender?.lenderCode == null) return true;

            return false;
        },
    },
    methods: {
        //Everything is being try-catched inside their own method.
        async handleSubmit(): Promise<void> {
            if(await this.deal.checkForFraud()) return

            this.isLoading = true;
            await this.deal.save(); // We must save the deal before submitting to ensure FIMenu is updated.

            const providersDealJacketId = await SubmitDealJacket(this.deal);
            const successful = !util.isNullOrEmpty(providersDealJacketId);

            if (successful && this.deal.dealJacket.providerId != providersDealJacketId) {
                this.deal.dealJacket.providerId = providersDealJacketId;
                await this.deal.save();
            }

            this.isLoading = false;
        },
    },
});
</script>
<style scoped>
.no-wrap-button {
    white-space: nowrap;
}

.r1button {
    padding: 2px 10px;
}
</style>
