<template>
    <div :style="$grid('1')">
        <div :style="$grid('1')" class="customer-section-container">
            <div class="swap-container">
                <button :disabled="!fimenu.hasCoSigner || isCustomerAndCoCustomerLinked"
                        class="button-swap"
                        @click="swap()">
                    <i class="fas fa-retweet"></i> Swap
                </button>
            </div>
            <TabMenu :tabs="tabs"
                     :initialTab="tabs[0]"
                     ref="tabMenu">
                     <template v-for="(tab, index) in tabs" #[`tab-head-${tab}`] class="haha2" :key="index">
                         <div :invalidTab="tab == tabs[0] ? v$.fimenu.customer.$invalid : v$.fimenu.coCustomer.$invalid">
                             <span>{{ tab }}</span>

                             <button v-if="fimenu.hasCoSigner && tab == 'Co-Customer' && (!isCustomerAndCoCustomerLinked || fimenu.customerApplication == ENUMS.CUSTOMER_APPLICATION_STATUS.RetrieveApplicationDisabled)"
                                     class="close-button no-shadow"
                                     @click="confirmDeletion">
                                 <i class="far fa-times-circle"></i>
                             </button>
                         </div>
                     </template>

                     <template v-for="(tab, index) in tabs" #[`tab-panel-${tab}`] :key="index">
                         <div>
                             <PanelCustomer
                                v-if="tab == 'Customer'"
                                v-model:customer="fimenu.customer"
                                customerType="customer"
                                :fimenu="fimenu"
                                :fallbackzipcode="fimenu.store.storeZip"
                                :panelObject="{title: customerGetTitle(fimenu.customer), allowRetrieveApplication: true}"
                                :validation="v$.fimenu.customer"
                                :panelView="true"
                                :needsTaxExemptQuestion="true"
                                :isRenderedInCustomerPage="false"
                                ref="customer"
                            />
                            <PanelCustomer v-else
                                           v-model:customer="fimenu.coCustomer"
                                           customerType="coCustomer"
                                           :isCustomerTypeDisabled="fimenu.customer.isEntity"
                                           :fimenu="fimenu"
                                           :fallbackzipcode="fimenu.store.storeZip"
                                           :panelObject="{title: customerGetTitle(fimenu.coCustomer), allowRetrieveApplication: true}"
                                           :validation="v$.fimenu.coCustomer"
                                           :panelView="true"
                                           :needsTaxExemptQuestion="true"
                                           :isRenderedInCustomerPage="false"
                                            ref="coCustomer"
                                       >
                            </PanelCustomer>
                        </div>
                    </template>
            </TabMenu>
        </div>

        <div v-if="!fimenu.hasCoSigner"
             class="radio-button-inline"
             :invalid="v$.fimenu.hasCoSigner.$invalid">
            <span>Is there a co-signer?</span>
            <InputRadio label="Yes" :valueMatch="true" v-model="fimenu.hasCoSigner" :isButton="true" />
            <InputRadio label="No" :valueMatch="false" v-model="fimenu.hasCoSigner" :isButton="true" />
        </div>
    </div>
</template>

<script>
    import util from '@core/services/util'
    import $modal from '@core/services/modal'
    import FIMenuCustomer from '@core/classes/FIMenuCustomer'

    import TabMenu from '@/components/TabMenu.vue'
    import modalInfo from '@core/modals/modalInfo.vue'
    import InputRadio from '@core/components/InputRadio.vue'
    import Application from '@core/classes/Applications/Application'
    import ApplicationsQuoteAppData from '@core/classes/Applications/ApplicationsQuoteAppData'
    import PanelCustomer from '@/components/PanelCustomer.vue'
    import { useVuelidate } from '@vuelidate/core';
    import ENUMS from "@core/classes/Enums"

    export default {
        name: 'PanelCustomerSection',
        props: ['fimenu'],
        setup(){
            // New setup for vuelidate. Swap $v with v$
            return { v$: useVuelidate({$scope: false}) }
        },
        data() {
            return {}
        },
        validations() {
            const fimenuValidation = this.fimenu.validation()

            const validations = {
                fimenu: {
                    customer: fimenuValidation.customer,
                    hasCoSigner: fimenuValidation.hasCoSigner,
                    coCustomer: fimenuValidation.coCustomer,
                },
            }

            return validations;
        },
        computed: {
            tabs() {
                return this.fimenu.hasCoSigner ? ['Customer', 'Co-Customer'] : ['Customer']
            },
            isCustomerAndCoCustomerLinked() {

                if (!this.fimenu.customer.customerApplication ||
                    !this.fimenu.coCustomer.customerApplication ||
                    this.fimenu.customer.customerApplication?.id == null ||
                    this.fimenu.coCustomer.customerApplication?.businessApplicationId == null
                ) {
                    return false
                }

                return (
                    this.fimenu.customer.customerApplication?.id ==
                    this.fimenu.coCustomer.customerApplication?.businessApplicationId
                )
            },
            ENUMS() {
                return ENUMS
            },
            anyCustomerIsInEditMode() {
                const isCustomerArray = Array.isArray(this.$refs.customer)
                const isCoCustomerArray = Array.isArray(this.$refs.coCustomer)

                const customerEditMode = isCustomerArray && !!this.$refs.customer[0]?.editMode
                const coCustomerEditMode = isCoCustomerArray && !!this.$refs.coCustomer[0]?.editMode

                return customerEditMode || coCustomerEditMode
            },
            isCoCustomerMainCustomer() {
                return  this.$route.query?.customerId == this.fimenu.coCustomer.id;
            }
        },
        methods: {
            swap() {
                const tempCustomer = this.fimenu.customer
                this.fimenu.customer = this.fimenu.coCustomer
                this.fimenu.coCustomer = tempCustomer
            },
            confirmDeletion() {
                if (this.fimenu.hasCoSigner) {
                    $modal.open(modalInfo, {
                        name: 'modalInfo',
                        passedData: {
                            info: 'Are you sure you want to delete the Co-Customer?',
                            acceptText: 'Yes',
                            cancelText: 'Cancel',
                        },
                        backdrop: false,
                        postFunction: () => {
                            if (this.fimenu.customerApplication == ENUMS.CUSTOMER_RETRIEVAL_STATUS.RetrieveApplicationDisabled) {
                                // unlink them if they are linked.
                                if (
                                    this.fimenu.customer.customerApplication.guarantorApplicationId ==
                                    this.fimenu.coCustomer.customerApplication.id
                                ) {
                                    this.fimenu.customer.customerApplication.guarantorApplicationId = null
                                }
                            }


                            this.fimenu.coCustomer = new FIMenuCustomer();

                            this.fimenu.hasCoSigner = false;
                            setTimeout(() => {
                                this.$refs.tabMenu.switchTab(this.tabs[0])
                            }, 1)
                        },
                        cancelFunction: () => {
                            this.fimenu.hasCoSigner = true
                        },
                    })
                }
            },
            customerGetTitle(customer) {
                const fullName = customer?.getFullName()
                if (fullName) {
                    return fullName.toUpperCase()
                } else {
                    return ''
                }
            },
        },
        components: {
            TabMenu,
            InputRadio,
            PanelCustomer,
        },
    }
</script>
<style>
    .customer-section-container {
        margin-top: 10px;
        position: relative;
    }

    .button-swap {
        background-color: var(--accept-color);
        border-color: var(--accept-color);
        width: 90px;
        height: 34px;
        font-size: 15px;
        padding: 0px;
    }

    .swap-container {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 0px;
        z-index: 1;
    }
</style>
