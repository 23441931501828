<template>
    <div :style="$grid('1-1-1')">
        <InputRichDropdown
            label="Valuation Company"
            v-model:saturn="bookValueEntry.source"
            :list="BookValues"
            :valueMap="valuePredicate"
            :display="displayPredicate"
            :disabled="disableDropDown"
            :invalid="validation.source.$invalid"
            search
        >
            <template #validation>
                <div v-if="validation.source.$invalid">Valuation Company is required</div>
            </template>
        </InputRichDropdown>
        <InputCurrency
            :label="getRetailInputLabel(bookValueEntry.source)"
            v-model:saturn="bookValueEntry.retail"
            :precision="2"
            :invalid="validation.retail.$invalid"
        >
            <template #validation>
                <div v-if="validation.retail.$invalid && !bookValueEntry.retail">
                    {{ getRetailInputLabel(bookValueEntry.source) }} is required
                </div>
            </template>
        </InputCurrency>
        <InputCurrency
            :label="getWholesaleInputLabel(bookValueEntry.source)"
            v-model:saturn="bookValueEntry.wholesale"
            :precision="2"
            :invalid="validation.wholesale.$invalid"
        >
            <template #validation>
                <div v-if="validation.wholesale.$invalid && !bookValueEntry.wholesale">
                    {{ getWholesaleInputLabel(bookValueEntry.source) }} is required
                </div>
            </template>
        </InputCurrency>
    </div>
</template>
<script lang="ts">
    import { defineComponent, PropType } from "vue";
    import { BookValue } from "@core/classes/SharedEnums";
    import { GetBookValueDropdownValues } from "@/helpers/fimenuvehicle-helper";
    import InputCurrency from "@core/components/InputCurrency.vue";
    import InputRichDropdown from "@core/components/InputRichDropdown.vue";
    import { useVuelidate } from "@vuelidate/core";
    import util from "@core/services/util";
    import { VehicleBookValue } from "@core/classes/VehicleBookValue";

    export interface BookValueDropDown {
        value: number;
        display: string;
    }

    export default defineComponent({
        name: "BookValueEntry",
        props: {
            bookValueEntry: {
                type: Object as PropType<VehicleBookValue>,
                required: true,
                default: () => new VehicleBookValue(), // Provide a default value to avoid null/undefined
            },
            disableDropDown: {
                type: Boolean,
                default: false,
            },
            dropDownValues: {
                type: Array as PropType<BookValueDropDown[] | null>,
                default: null,
            },
            isVehicleNew: {
                type: Boolean,
                default: false,
            },
            validation: {
                type: Object,
                default: null,
                required: false,
            },
        },
        setup() {
            return { v$: useVuelidate() };
        },
        methods: {
            displayPredicate(val: BookValueDropDown): any {
                return val.display;
            },
            valuePredicate(val: BookValueDropDown): any {
                return val.value;
            },
            getRetailInputLabel(bookValue: BookValue) {
                return bookValue == BookValue.Manufacturer ? "MSRP" : "Retail";
            },
            getWholesaleInputLabel(bookValue: BookValue) {
                return bookValue == BookValue.Manufacturer ? "Invoice" : "Wholesale";
            },
        },
        computed: {
            BookValues(): BookValueDropDown[] {
                return this.dropDownValues ?? GetBookValueDropdownValues();
            },
            util(): any {
                return util;
            },
            bookValue(): typeof BookValue {
                return BookValue;
            },
        },
        components: { InputRichDropdown, InputCurrency },
    });
</script>
<style scoped></style>
