<template>
    <div class="PurchaseFiguresPage-container">
        <div v-if="!data.buyersOrderEnabled" :style="$grid('1-1')" class="PurchaseFiguresPage-wrapper">
            <div class="panel-left fullHeight">
                <PanelPurchaseFigures :data="data"
                                      :validation="validation"
                                      :preloadTaxes="preloadTaxes"
                                      :docStamps="docStamps" />
            </div>
            <div class="panel-right">
                <Panel title="Submit to R1">
                    <ButtonSubmitToFinanceInstitutions :deal="data" />
                </Panel>
                <PanelLender :data="data"
                             :validation="validation"
                             :panelObject="{title: 'Lender'}"
                             :panelView="true"
                             class="panel-left-flex-1 fullHeight">
                </PanelLender>
            </div>
        </div>
        <div v-else :style="$grid('65-35')" class="r1-wrapper">
            <PanelBuyersOrder :fimenu="data" :fimenuValidation="validation" ref="bo" :submitToFinanceChannel="canSubmitToR1" />

            <div class="Content-Container">
                <PanelCreditApplications v-if="displayCreditApplication"
                                         :fimenu="data"
                                         :validation="validation"
                                         :panelObject="{title: 'Lender Applications'}" />
                <PanelLender v-else :data="data"
                             :validation="validation"
                             :panelObject="{title: 'Lender'}"
                             :panelView="true"
                             class="panel-left-flex-1" />

                <PanelDealTerms v-if="displayBOPanelDealTerms"
                                :data="data"
                                :panelObject="dealTermsPanelObj()"
                                :validation="validation"
                                :setGapPricing="() => data.priceProducts([{ productType: ENUMS.PRODUCT_TYPES.GAP }], null, false)"
                                :dealSave="() => data.save()"
                                :hideTerms="true"
                                :dealTermsChangedHandler="dealTermsChangedHandler" />
            </div>

        </div>
    </div>
</template>


<script>
    import PanelLender from '@/components/PanelLender.vue'
    import PanelBuyersOrder from '@/components/PanelBuyersOrder.vue'
    import PanelCreditApplications from '@/components/PanelCreditApplications.vue'
    import PanelPurchaseFigures from '@/components/PanelPurchaseFigures.vue'
    import ENUMS from "@core/classes/Enums"
    import util from '@core/services/util';
    import { DisplayCreditApplication } from '@/helpers/finance-channels-helper'
    import PanelDealTerms from '@/components/PanelDealTerms.vue'
    import Panel from '@core/components/Panel.vue'
    import ButtonSubmitToFinanceInstitutions from '@/components/ButtonSubmitToFinanceInstitutions.vue'

    export default {
        name: "PurchaseFiguresPage",
        props: ['data', 'validation'],
        data() {
            return {
                preloadTaxes: 0,
                docStamps: 0,
            }
        },
        computed: {
            ENUMS() {
                return ENUMS;
            },
            util() {
                return util;
            },
            dealTypes() {
                return ENUMS.DEAL_TYPES.toList().map(dt => dt.display)
            },
            displayCreditApplication() {
                return DisplayCreditApplication(this.data)
            },
            displayBOPanelDealTerms() {
                const fimenu = this.data;
                const bo = fimenu.getCurrentBuyersOrder();

                return fimenu.dealType != null                                          &&
                    fimenu.hasCoverageTerms                                             &&
                    (DisplayCreditApplication(this.data) ||fimenu.lender.lenderCode)    &&
                    fimenu.buyersOrderEnabled                                           &&
                    (bo && bo.isValid())                                                &&
                    bo.areAllBucketsValid();
            },
            canSubmitToR1() {
                // If the deal is cash. We cannot submit the deal to the lenders.
                if (this.data.isCash()) return false;

                // Wether we can send deals or not based on the store settings.
                return !!(this.data.store?.storeSettings?.submitCustomersToFinanaceInstitutions == true)
            }
        },
        async created() {

            this.loadData();

            this.$watch(() => this.data.preloads, () => {
                this.recalculate();
            }, { deep: true });

            this.$watch(() => this.data.additions, () => {
                this.recalculate();
            }, { deep: true });

            if (!this.data.buyersOrderEnabled) {
                this.$watch(() => this.data.otdBase, () => {
                    this.recalculate()
                }, { deep: true })
            }

            this.EventBus.on("FIMenu:DealTypeChanged", this.handleDealTypechange)
        },

        methods: {
            recalculate() {
                const fimenu = this.data;
                if (fimenu.buyersOrderEnabled) {
                    this.$refs.bo.recalculate();
                }
                else {
                    this.calculateOTD();
                }
            },

            //RECALCULATE BUYERS ORDER WHEN DEAL TERM CHANGE
            dealTermsChangedHandler(){
                const fimenu = this.data;
                if (fimenu.buyersOrderEnabled) {
                    this.$refs.bo.recalculate();
                }
            },
            handleDealTypechange() {
                // This method must clear the lender, otherwise validation will allow users to continue if they have previously selected a lender.

                //Clear the lender in the deal. This will reset the customer in the deal.
                this.data.lender.clear();

                // If we have any credit apps to clear.
                if (this.data.dealJacket.applications?.length) {
                    // Clear selected credit apps.
                    this.data.dealJacket.applications.forEach(c => {
                        if (c.selected) c.selected = false;
                    });
                }



            },
            calculateOTD() {
                const finalNumbers = this.data.finalNumbers(this.$global.selectedStore.storeState, null, null, false)
                this.preloadTaxes = finalNumbers.baseAmountTaxes
                this.docStamps = finalNumbers.docStamps
                this.data.otd = finalNumbers.baseAmount + this.docStamps
            },
            loadData() {
                const fimenu = this.data;
                if (fimenu.buyersOrderEnabled) {
                    //IF WE DONT HAVE A BUYERS ORDER, GET IT
                    if (!fimenu.getCurrentBuyersOrder()) {
                        fimenu.buyersOrderUpdateRequiredRightAway = true;
                    }
                }
                else {
                    this.calculateOTD()
                }
            },
            dealTermsPanelObj() {
                let title;
                if (this.data.isDraft())
                    title = 'Draft Terms';
                else if (this.data.isCash())
                    title = 'Cash Terms';
                else if (this.data.isFinance())
                    title = 'Loan Terms';
                else
                    title = 'Lease Terms';

                return { title: title, action: [] };
            },
        },
        beforeUnmount(){
            this.EventBus.off("FIMenu:DealTypeChanged", this.handleDealTypechange)
        },
        components: {
            Panel,
            PanelLender,
            PanelBuyersOrder,
            PanelCreditApplications,
            PanelPurchaseFigures,
            PanelDealTerms,
            ButtonSubmitToFinanceInstitutions
        }
    };
</script>
<style>
    .PurchaseFiguresPage-container .panel-left {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }

    .r1-wrapper {
        display: flex;
        flex-direction: row;
        height: 100% !important;
        overflow: hidden;
    }

    .PurchaseFiguresPage-container .r1-wrapper > * {
        height: 100% !important;
        overflow: hidden;
    }

    .PurchaseFiguresPage-container .panel-left-flex-1 {
        flex: 1;
    }

    .PurchaseFiguresPage-container .buyers-order-row.preloads-list {
        grid-template-columns: 1fr 150px !important;
    }

    .PurchaseFiguresPage-container .buyers-order-row:nth-child(2n) {
        background: var(--richtable-alternating-background-color);
    }

    .fullHeight {
        height: 100%
    }

    .PurchaseFiguresPage-container .buyers-order-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between
    }

    .PurchaseFiguresPage-wrapper {
        height: 100%;
    }

    .PurchaseFiguresPage-container .PurchaseFiguresPage-wrapper .panel-right {
        display: flex;
        gap: 15px;
        flex-direction: column;
        height: 100%;
    }

    .PurchaseFiguresPage-container .Content-Container {
        display: flex;
        flex-direction: column;

    }
    .PurchaseFiguresPage-container .Content-Container > :first-child {
        height: calc(100% - 240px); 
    }
    .PurchaseFiguresPage-container .Content-Container > :nth-child(2) {
        height: 235px;
    }
    .PurchaseFiguresPage-container .Content-Container > :only-child {
        height: 100%;
    }
</style>