<template>
    <div class="modal-container">
        <div class="modal-header">
            <h3 style="margin: 0">Send Invitation for a Remote Meeting</h3>

        </div>
        <div class="modal-body">
            <div class="booking-section">
                <div class="schedule-now-or-later">
                    <InputRadio label="Start Meeting Now"
                                v-model="startMeetingNow"
                                :valueMatch="true">
                    </InputRadio>
                    <InputRadio label="Schedule Meeting"
                                v-model="startMeetingNow"
                                :valueMatch="false">
                    </InputRadio>
                </div>
                    <div class="date-time-selection" v-if="!startMeetingNow">
                        <InputDate label="Appointment Date"
                                   v-model:saturn="scheduledDate"
                                   :invalid="v$.scheduledDate.$invalid">
                            <template #validation>
                                <div v-if="v$.scheduledDate.required.$invalid">Appointment Date is required</div>
                                <div v-else>Appointment Date must be in the future</div>
                            </template>
                        </InputDate>

                        <div class="time-input">
                            <input :class="{ 'invalid': v$.scheduledTime.$invalid }" type="time" v-model="scheduledTime" min="openingTime" max="closingTime" step="300" @mouseover="toggleShowError(true, $event)"
                                   @mouseleave="toggleShowError(false, $event)" />
                            <div class="saturn-textbox-error-message" v-if="errorHover && v$.scheduledTime.$invalid">
                                <span v-if="v$.scheduledTime.required.$invalid">Appointment Time is required</span>
                                <span v-else>Appointment Time must be in the future</span>
                            </div>
                        </div>

                    </div>
            </div>
            <div class="send-invitation-method">
                <InputRichDropdown label="Invitee"
                               :list="inviteeOptions"
                               :display="(c) => c.text"
                               :valueMap="(c) => c.value"
                               v-model:saturn="invitee"
                               :invalid="v$.invitee.$invalid">
                    <template #validation>
                        <div v-if="v$.invitee.required.$invalid">Invitee is required</div>
                    </template>
                </InputRichDropdown>
                <InputRichDropdown label="Send Invitation Link"
                               :list="inviteOptions"
                               v-model:saturn="sendEmailOrText"
                               :invalid="v$.sendEmailOrText.$invalid">
                    <template #validation>
                        <div v-if="v$.sendEmailOrText.required.$invalid">Send Invitation Link is required</div>
                    </template>
                </InputRichDropdown>
            </div>
        </div>
        <div class="modal-footer">
            <button class="button button-unwind" @click="cancel()">Cancel</button>
            <button class="button button-save" @click="confirm()" :disabled="v$.$invalid">Confirm</button>
        </div>

    </div>
</template>

<script>
    import $modal from '@core/services/modal';
    import InputDate from '@core/components/InputDate.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import InputRadio from '@core/components/InputRadio.vue'
    import util from '@core/services/util'
    import { required, requiredIf } from '@vuelidate/validators'
    import { useVuelidate } from '@vuelidate/core'
    import { dateInFuture } from '@core/services/custom-validators'


    export default {
        name: 'modalCustomerMeetingInvitation',
        props: ['modal'],
        setup(){
            return { v$: useVuelidate({$scope: false}) }
        },
        data() {
            return {
                inviteOptions: ["Email", "Text", "Email and Text"],
                inviteeOptions: [],
                scheduledDate: '',
                scheduledTime: '',
                currentDateTime: util.toMoment(new Date()),
                startMeetingNow: true,
                sendEmailOrText: null,
                invitee: null,
                errorHover: false,

            }
        },
        created() {
            this.invitee = this.modal.passedData.who;
            if(this.modal.passedData.customer.fullName !== null) {
                this.inviteeOptions.push({
                    text: util.toProperCase(this.modal.passedData.customer.fullName) + ' (Customer)',
                    value: "customer"
                })
            }
            if(this.modal.passedData.coCustomer.fullName !== null) {
                this.inviteeOptions.push({
                    text: util.toProperCase(this.modal.passedData.coCustomer.fullName) + ' (Co-customer)',
                    value: "coCustomer"
                })
            }

        },
        computed: {
            scheduledDateTime() {
                return util.toMoment(util.toMoment(this.scheduledDate).format('YYYY-MM-DD') + 'T' + this.scheduledTime);
            }
        },
        methods: {
            cancel() {
                $modal.cancel();
            },
            confirm() {
                $modal.close({
                    startMeetingNow: this.startMeetingNow,
                    scheduledDateTime: this.startMeetingNow ? this.currentDateTime : this.scheduledDateTime,
                    sendEmailOrText: this.sendEmailOrText,
                    who: this.invitee
                    //who: this.modal.passedData.who // commenting this out for version 1 - we are getting this from customerMeetingDropdown for version 2
                })
            },
            toggleShowError(event) {
                this.errorHover = !this.errorHover;
            },
            capitalizeFirstLetter(str) {
                str = str.toLowerCase();

                const words = str.split(" ");

                for (let i = 0; i < words.length; i++) {
                    // Capitalize the first letter of each word
                    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
                }

                return words.join(" ");
            },

        },
        validations() {
            const validations = {
                scheduledDate: {
                    required: requiredIf(() => !this.startMeetingNow),
                    isValid: () => !dateInFuture(this.currentDateTime, this.scheduledDateTime)
                },
                scheduledTime: {
                    required: requiredIf(() => !this.startMeetingNow),
                    isValid: () => !dateInFuture(this.currentDateTime, this.scheduledDateTime)
                },
                sendEmailOrText: { required },
                invitee: { required },
            }
            return validations;
        },
        watch: {
            startMeetingNow(newValue, oldValue) {
                if (newValue === true) {
                    this.scheduledDate = '';
                    this.scheduledTime = '';
                }
            }
        },
        components: {
            InputDate,
            InputRichDropdown,
            InputRadio,
        },

    }
</script>

<style scoped>
    .modal-footer .button-close {
        background: var(--error);
    }

    .modal-body .send-invitation-method {
        display: flex;
        justify-content: center;
        padding: 20px 0;
        width: 100%;
        gap: 30px;
    }

        .modal-body .send-invitation-method .InputRichDropdown {
            width: 30%;
        }

        .modal-body .booking-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            width: 100%;

            /* Hiding booking section until scheduling is implemented */
            display: none;
        }

    .modal-body .booking-section .schedule-now-or-later {
        display: flex;
        /*flex-direction: column;*/
        width: 330px;
        gap: 24px;
        margin-bottom: 24px;
    }
    .date-time-selection {
        display: flex;
        justify-content: center;
        gap: 12px;
        width: 100%;
        margin-bottom: 20px;
    }
    input[type="time"] {
        height: 40px;
        max-width: 175px;
    }

    .time-input {
        position: relative;
    }

        .saturn-textbox-error-message {
            background: var(--error-color);
            z-index: 1000;
            color: white;
            position: absolute;
            top: 100%;
            right: 2px;
            padding: 2px 10px;
            border-radius: 4px;
            border-top-left-radius: unset;
            border-top-right-radius: unset;
            transform-origin: left top;
            transform: scaleY(0);
            animation: expandable 0.1s ease-out forwards;
            max-width: calc(100% - 25px);
            font-size: 0.85rem;
        }

    .invalid {
        border-color: var(--error-color);
    }




</style>