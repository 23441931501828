<template>
    <div class="modalInfo">
        <div class="modal-container modal-info modal-medium modal-help" :style="{zIndex: '9999999999'}">
            <div class="modal-header">
                Create Support Ticket
                <InputRichDropdown label="Ticket Type"
                    v-model:saturn="selectedSupportType"
                    :list="supportTypeList"
                    :value="(p:any) => p"
                    :display="(p:any) => p"
                    :invalid="v$.selectedSupportType.$invalid"
                    search>
                    <template #validation>
                        <div v-if="v$.selectedSupportType.required.$invalid">Ticket Type is required</div>
                    </template>
                </InputRichDropdown>
            </div>
            <IsBusySectionComponent v-if="isBusy" class="modal-help-body"/>
            <div v-else-if="selectedSupportType == 'Default'" class="modal-help-body">
                <div class="modal-field">
                    <InputTextbox
                        placeholder="Subject"
                        label="Subject"
                        :multiline="false"
                        v-model:saturn="defaultRequest.subject"
                        :invalid="v$.defaultRequest.subject.$invalid">
                        <template #validation>
                            <div v-if="v$.defaultRequest.subject.required.$invalid">Subject is required</div>
                        </template>
                    </InputTextbox>
                </div>
                <div v-if="!login" class="modal-field">
                    <InputTextbox
                        placeholder="Email"
                        label="Email"
                        :multiline="false"
                        v-model:saturn="defaultRequest.email"
                        :invalid="v$.defaultRequest.email.$invalid">
                        <template #validation>
                            <div v-if="v$.defaultRequest.email.required.$invalid">Email is required</div>
                            <div v-if="v$.defaultRequest.email.email.$invalid">Needs to be a valid email</div>
                        </template>
                    </InputTextbox>
                </div>
                <div class="modal-field">
                    <InputTextbox
                        placeholder="Please enter DETAILED information on the issue. "
                        label="Description"
                        :multiline="true"
                        :rows="8"
                        v-model:saturn="defaultRequest.description"
                        :invalid="v$.defaultRequest.description.$invalid">
                    <template #validation>
                        <div v-if="v$.defaultRequest.description.required.$invalid">Description is required</div>
                    </template>
                    </InputTextbox>
                </div>
                <div style="margin-bottom: 15px;">
                    If assistance is needed with forms please email forms@saturndms.com
                </div>
            </div>
            <div v-else-if="selectedSupportType == 'User-Creation'" class="modal-help-body">
                <div :style="$grid('1-1')">
                    <InputTextbox
                        placeholder="Employee Code"
                        label="Employee Code"
                        :invalid="v$.userRequest.employeeCode.$invalid"
                        v-model:saturn="userRequest.employeeCode">
                    <template #validation>
                        <div v-if="v$.userRequest.employeeCode.required.$invalid">Employee code is required</div>
                    </template>
                    </InputTextbox>
                    <InputRichDropdown v-if="login" label="Position"
                        v-model:saturn="userRequest.position"
                        :list="positionList"
                        :value="(p:any) => p"
                        :display="(p:any) => p"
                        :invalid="v$.userRequest.position.$invalid"
                        search>
                    <template #validation>
                        <div v-if="v$.userRequest.position.required.$invalid">Position is required</div>
                    </template>
                    </InputRichDropdown>
                    <InputTextbox v-else 
                        label="Position"
                        placeholder="Position"
                        v-model:saturn="userRequest.position"
                        :invalid="v$.userRequest.position.$invalid">
                    <template #validation>
                        <div v-if="v$.userRequest.position.required.$invalid">Position is required</div>
                    </template>
                    </InputTextbox>
                    <InputTextbox
                        placeholder="First Name"
                        label="First Name"
                        :invalid="v$.userRequest.firstName.$invalid"
                        v-model:saturn="userRequest.firstName">
                    <template #validation>
                        <div v-if="v$.userRequest.firstName.required.$invalid"> First name is required</div>
                    </template>
                    </InputTextbox>
                    <InputTextbox
                        placeholder="Last Name"
                        label="Last Name"
                        :invalid="v$.userRequest.lastName.$invalid"
                       v-model:saturn="userRequest.lastName">
                    <template #validation>
                        <div v-if="v$.userRequest.lastName.required.$invalid"> Last name is required</div>
                    </template>
                    </InputTextbox>
                    <InputPhone label="Cell Phone Number"
                        type="tel"
                        v-model:saturn="userRequest.cellNumber"
                        :invalid="v$.userRequest.cellNumber.$invalid"
                        placeholder="Cell Phone Number">
                    <template #validation>
                        <div v-if="v$.userRequest.cellNumber.$invalid"> Please enter a valid phone number</div>
                    </template>
                    </InputPhone>
                    <InputPhone label="Dial Pad Number"
                        type="tel"
                        v-model:saturn="userRequest.workNumber"
                        :invalid="v$.userRequest.workNumber.$invalid"
                        placeholder="Dial Pad Number">
                    <template #validation>
                        <div v-if="v$.userRequest.workNumber.required.$invalid"> A valid phone number is required</div>
                        <div v-if="v$.userRequest.workNumber.minLength.$invalid"> A valid phone number is required</div>
                    </template>
                    </InputPhone>
                    <InputTextbox label="Email"
                        type="email"
                        v-model:saturn="userRequest.email"
                        :invalid="v$.userRequest.email.$invalid"
                        placeholder="Email">
                    <template #validation>
                        <div v-if="v$.userRequest.email.required.$invalid"> Email is required </div>
                        <div v-if="v$.userRequest.email.email.$invalid"> Needs to be a valid email </div>
                    </template>
                    </InputTextbox>
                    <InputTextbox
                        placeholder="Stores to Access"
                        label="Stores to Access"
                        :invalid="v$.userRequest.storesToAccess.$invalid"
                        v-model:saturn="userRequest.storesToAccess">
                        <template #validation>
                            <div v-if="v$.userRequest.storesToAccess.required.$invalid">Stores to access is required</div>
                        </template>
                    </InputTextbox>
                </div>
            </div>
            <div v-else-if="selectedSupportType == 'Lender-Creation'" class="modal-help-body">
                <div :style="$grid('1-1')">
                    <InputTextbox
                        placeholder="DMS Lender Code"
                        label="DMS Lender Code"
                        :invalid="v$.lenderRequest.lenderCode.$invalid"
                        v-model:saturn="lenderRequest.lenderCode">
                    <template #validation>
                        <div v-if="v$.lenderRequest.lenderCode.required.$invalid">Lender code is required</div>
                    </template>
                    </InputTextbox>
                    <InputTextbox
                        placeholder="Lienholder Name"
                        label="Lienholder Name"
                        :invalid="v$.lenderRequest.lienholderName.$invalid"
                        v-model:saturn="lenderRequest.lienholderName">
                    <template #validation>
                        <div v-if="v$.lenderRequest.lienholderName.required.$invalid">Lienholder name is required</div>
                    </template>
                    </InputTextbox>
                    <InputTextbox
                        placeholder="Legal Name"
                        label="Legal Name"
                        :invalid="v$.lenderRequest.legalName.$invalid"
                        v-model:saturn="lenderRequest.legalName">
                    <template #validation>
                        <div v-if="v$.lenderRequest.legalName.required.$invalid">Legal name is required</div>
                    </template>
                    </InputTextbox>
                    <InputRichDropdown label="Lender Type"
                        v-model:saturn="lenderRequest.lenderType"
                        :list="lenderTypeList"
                        :value="(l:any) => l"
                        :display="(l:any) => l"
                        :invalid="v$.lenderRequest.lenderType.$invalid"
                        search>
                    <template #validation>
                        <div v-if="v$.lenderRequest.lenderType.required.$invalid">Lender type is required</div>
                    </template>
                    </InputRichDropdown>
                    <InputTextbox
                        placeholder="ELT #"
                        label="ELT #"
                        v-model:saturn="lenderRequest.eltNum">
                    </InputTextbox>
                    <InputTextbox
                        placeholder="Reserve Split"
                        label="Reserve Split"
                        :invalid="v$.lenderRequest.reserveSplit.$invalid"
                        v-model:saturn="lenderRequest.reserveSplit">
                    <template #validation>
                        <div v-if="v$.lenderRequest.reserveSplit.required.$invalid">Reserve split is required</div>
                    </template>
                    </InputTextbox>
                </div>
                <div :style="$grid('1')">
                    <InputTextbox
                        placeholder="Address"
                        label="Address"
                        :invalid="v$.lenderRequest.address.$invalid"
                        v-model:saturn="lenderRequest.address">
                    <template #validation>
                        <div v-if="v$.lenderRequest.address.required.$invalid">Address is required</div>
                    </template>
                    </InputTextbox>
                </div>

            </div>
            <div v-else class="modal-help-body" style="margin-bottom: 15px;">
                Please choose a ticket type
            </div>
            <div class="modal-footer">
                <button class="button-unwind" :disabled="isBusy" @click="cancel()">Cancel</button>
                <button class="button-save" :disabled="sendDisabled" @click="close()">Send</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { required, requiredIf, email, minLength, maxLength } from '@vuelidate/validators'
    import $modal from '@core/services/modal'
    import api from '@core/services/api';
    import auth from '@core/services/auth';
    import InputPhone from '@core/components/InputPhone.vue';
    import InputRichDropdown from '@core/components/InputRichDropdown.vue';
    import InputTextbox from '@core/components/InputTextbox.vue';
    import IsBusySectionComponent from '@core/components/IsBusySectionComponent.vue'
    import LogRocket from "logrocket";
    import settings from 'settings';
    import { useVuelidate } from '@vuelidate/core'
    import util from '@core/services/util'

    export default {
        name: "modalHelp",
        props: ['modal'],
        setup(){
            return { v$: useVuelidate({$scope: false}) }
        },
        data() {
            return {
                route: null,
                login: false,
                username: null,
                isBusy: false,
                selectedSupportType: null,
                supportTypeList: ["User-Creation", "Lender-Creation", "Default"],
                lenderTypeList: ["Finance", "Lease", "Draft"],
                userRequest:{firstName: null, lastName: null, cellNumber:null, workNumber:null, email:null, employeeCode: null, position: null, storesToAccess: null},
                lenderRequest:{ lenderCode: null, lienholderName: null, legalName: null, address: null, lenderType: null, eltNum: null, reserveSplit: null},
                defaultRequest:{email: null, subject: null, description: null},
                positionList: null,
            };
        },
        validations() {
            let validations ={
                selectedSupportType: { required },
                defaultRequest: {
                    email:{ required:  requiredIf(() => !this.login), email },
                    description: { required },
                    subject: { required },
                },
                userRequest: {
                    employeeCode: { required },
                    firstName: { required },
                    lastName: { required },
                    cellNumber: { minLength: minLength(10), maxLength: maxLength(10) },
                    workNumber: { required, minLength: minLength(10), maxLength: maxLength(10) },
                    email: { required, email },
                    position: { required },
                    storesToAccess: { required },
                },
                lenderRequest: {
                    lenderCode: { required },
                    lienholderName: { required },
                    legalName: { required },
                    address: { required },
                    lenderType: { required },
                    eltNum: {},
                    reserveSplit: { required },
                },
            };
            return validations;
        },
        computed: {
			user() {
                if(this.login)
                    return auth?.getTokenPayload();

				return null;
			},
            settings() {
                return settings
            },
            sendDisabled(){
                if(this.selectedSupportType == 'User-Creation') {
                    return this.v$.userRequest.$invalid;
                }
                else if(this.selectedSupportType == 'Lender-Creation') {
                    return this.v$.lenderRequest.$invalid;
                }
                else {
                    return this.v$.defaultRequest.$invalid;
                }
            }
        },
        created() {
            this.route = this.modal.passedData.route ?? window.location.href;
            this.login = this.modal.passedData.login;
            this.username = this.modal.passedData.username;
            this.loadPositions();
        },
        methods: {
            async close() {
                this.isBusy = true;

                if (this.selectedSupportType == "Default"){
                    let getHelpDataResponse = null;

                    let storeCode = this.$global?.dealNumber ? this.$global?.selectedStore?.storeCode: null;
                    let storeName = this.$global?.dealNumber ? this.$global?.selectedStore?.storeName: null;

                    //getting all neccessary data for sending a help request when user is logged in
                    if(this.login){
                        const getHelpDataRequest = {
                            userCode: this.user.EmployeeCode,
                            storeCode: storeCode,
                        };
                        getHelpDataResponse = await api.utilities.getHelpData(getHelpDataRequest);
                    }

                    let supportURL;
                    LogRocket.getSessionURL(sessionURL => {
                        supportURL = sessionURL;
                    });

                    let request = {
                        route: this.route,
                        email : this.user?.EmployeeEmail ?? this.defaultRequest.email,
                        subject : this.defaultRequest.subject,
                        description  : this.defaultRequest.description,
                        logrocketURL : supportURL ?? "N/A",
                        name : this.user?.EmployeeName ?? "N/A",
                        dealNumber : this.$global?.dealNumber ?? "N/A",
                        store : storeCode ?? "N/A",
                        storeName : storeName ?? "N/A",
                        phoneNumber : getHelpDataResponse?.data.phoneNumber ?? "N/A",
                        username: this.username ?? "N/A",
                        cc: getHelpDataResponse?.data.financeDirectorsEmails  ?? [],
                    };

                    await api.utilities.sendHelp(request);

                    if(this.login){
                        util.toastr('success', 'Success', 'Support Ticket Sent');
                    }
                    else{
                        setTimeout(()=>{
                            window.location.href = '/login';
                        },1000);
                    }
                }
                else if (this.selectedSupportType == "User-Creation"){

                    let request = {

                        requesterName: this.user?.EmployeeName ?? "N/A",
                        employeeCode: this.userRequest.employeeCode,
                        position: this.userRequest.position,
                        firstName: this.userRequest.firstName,
                        lastName: this.userRequest.lastName,
                        cellNumber: !util.isNullOrEmpty(this.userRequest.cellNumber) ? this.userRequest.cellNumber : "N/A",
                        workNumber: this.userRequest.workNumber,
                        email: this.userRequest.email,
                        store: "idk",
                    };


                    await api.utilities.sendHelpUserRequest(request);
                    util.toastr('success', 'Success', 'User Request Sent');
                }
                else if (this.selectedSupportType == "Lender-Creation"){

                    let supportURL = null;

                    if(this.login){
                        const getHelpDataRequest: { userCode: string, storeCode: string } = {
                            userCode: this.user.EmployeeCode,
                            storeCode: null,
                        };

                        var getHelpDataResponse = await api.utilities.getHelpData(getHelpDataRequest);

                        LogRocket.getSessionURL(sessionURL => {
                            supportURL = sessionURL;
                        });
                    }

                    let request = {
                        requesterName: this.user?.EmployeeName ?? "N/A",
                        requesterEmail: this.user?.EmployeeEmail ?? "N/A",
                        requesterPhone: getHelpDataResponse?.data.phoneNumber ?? "N/A",
                        lenderCode: this.lenderRequest.lenderCode,
                        lienholderName: this.lenderRequest.lenderCode,
                        legalName: this.lenderRequest.legalName,
                        address: this.lenderRequest.address,
                        lenderType: this.lenderRequest.lenderType,
                        eltNum: this.lenderRequest.eltNum,
                        reserveSplit: this.lenderRequest.reserveSplit,
                        logrocketURL: supportURL ?? "N/A",
                    };

                    await api.utilities.sendHelpLenderRequest(request);
                    util.toastr('success', 'Success', 'Lender Request Sent');
                }

                this.EventBus.emit("helpOpen");
                $modal.close();
                //$modal.close(this.additionalData);
            },
            async loadPositions() {
                this.isBusy = true;

                try {
                    let response = await api.settings.getPositions();
                    if (!response?.data) return;

                    this.positionList = response.data;
                } catch (err) {
                    // eslint-disable-next-line no-console
                    console.error('SERVER ERROR: ', err);
                    util.toastr('error', 'Error!', 'An error occurred while trying to load positions.');
                } finally {
                    this.isBusy = false;
                }
            },
            cancel() {
                if(this.login){
                    $modal.cancel();
                }
                else{
                    window.location.href = '/login';
                }
                this.EventBus.emit("helpOpen");
            }
        },
        components: {
            InputTextbox,
            InputRichDropdown,
            IsBusySectionComponent,
            InputPhone,
        }
    };
</script>
<style scoped>
    .modal-help .modal-help-body{
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        margin-left: 20px;
        margin-top: 20px;
        font-size: 16px;
        height: 100%;
    }

    .modal-help .modal-help-body .modal-field {
        margin-bottom: 20px;
    }

    .modal .modal-container.modal-medium, .modal-container.modal-medium {
        width: 650px;
    }
</style>
