<template>
    <div
        :class="{ 'document-preview-container': true, 'is-loading': !isLoaded }"
        :style="mediaCssVars"
        ref="previewContainer"
    >
        <figure
            v-show="isLoaded && !loadingError"
            :class="{ 'clickable': expandOnClick }"
            @click="openDocumentPreviewModal(document)"
        >
            <video v-if="mediaType === 'VIDEO'" controls @loadeddata="onDocLoad(true)" @error="onDocLoad(false)">
                <source :src="downloadLink" :type="document.docInfo.fileType" />
                <p>Unable to display video.</p>
            </video>

            <img
                v-else-if="mediaType === 'IMAGE'"
                :src="downloadLink"
                :alt="document.docInfo.fileName"
                @load="onDocLoad(true)"
                @error="onDocLoad(false)"
            />

            <div v-else-if="mediaType === 'PDF'" class="pdf-container" ref="pdfContainer">
                <PDFPage
                    v-for="(page, index) in availablePdfPages"
                    :key="'pdfPage-' + index"
                    :page="page"
                    :width="pdfWidth"
                    @rendered="onPdfPageRender"
                    @error="onPdfPageError"
                >
                </PDFPage>
            </div>

            <div v-else class="not-supported-container">
                <p class="warning-message"><i class="fas fa-info-circle" /> File type not supported.</p>

                <p><b>File Name:</b> {{ document.docInfo.fileName }}</p>
                <p><b>File Type:</b> {{ document.docInfo.fileType }}</p>
                <p><b>Date Uploaded:</b> {{ timestampFormatted }}</p>
            </div>

            <figcaption v-show="!hideCaption">{{ docTypeDisplay }}</figcaption>
        </figure>

        <LoaderComponent v-show="!isLoaded && !loadingError" />

        <div v-if="loadingError">
            <p class="warning-message"><i class="fas fa-info-circle" /> Error loading file.</p>
        </div>
    </div>
</template>
<script lang="ts">
import * as pdfjs from "pdfjs-dist/legacy/build/pdf.mjs";
import $modal from "@core/services/modal";
import auth from "@core/services/auth";
import LoaderComponent from "@core/components/LoaderComponent.vue";
import { markRaw } from 'vue';
import modalDocumentPreview from "@core/modals/modalDocumentPreview.vue";
import PDFPage from "@core/components/PDFPage.vue";
import settings from "settings";

export default {
    name: "DocumentPreview",
    emits: ["load", "error"],
    props: {
        document: {
            type: Object, // See UploadedDocument.cs
            required: true,
        },
        mediaWidth: {
            type: String, // A CSS width value
            default: () => "100%",
        },
        mediaHeight: {
            type: String, // A CSS height value
            default: () => "100%"
        },
        caption: String,
        expandOnClick: Boolean,
        hideCaption: Boolean,
    },
    data() {
        return {
            isLoaded: false,
            loadingError: false,
            pdfPages: null,
            pdfPagesRendered: 0,
        };
    },
    computed: {
        mediaCssVars() {
            return `--media-width:${this.mediaWidth}; --media-height:${this.mediaHeight};`;
        },
        mediaType() {
            let fileType = this.document.docInfo.fileType.toLowerCase();
            if (fileType.indexOf("video") !== -1) return "VIDEO";
            else if (fileType.indexOf("image") !== -1) return "IMAGE";
            else if (fileType.indexOf("pdf") !== -1) return "PDF";
            else return "NOT SUPPORTED";
        },
        timestampFormatted() {
            return new Date(Date.parse(this.document.docInfo.timestamp)).toLocaleString("en-US", {
                dateStyle: "long",
                timeStyle: "medium",
            });
        },
        downloadLink() {
            return `${settings.apiUrl}/Documents/DownloadDocument/${this.document.id}`;
        },
        docTypeDisplay() {
            let pageCount = "";
            if (this.mediaType === "PDF" && this.availablePdfPages.length === 1)
                pageCount = `(${this.availablePdfPages.length}/${this.pdfPages.length})`;

            let captionText = "";
            let foundType = settings.lookups?.uploadedDocumentTypes?.find(
                (d: any) => d.code === this.document.docInfo.docType
            );

            if (this.caption) captionText = this.caption;
            else if (!foundType) captionText = this.document.docInfo.fileName;
            else captionText = foundType.display;

            return `${captionText} ${pageCount}`.trim();
        },
        availablePdfPages() {
            // If expand on click is enabled, only show the first page of the PDF (and the expanded modal will show all available pages)

            if (!this.pdfPages || this.pdfPages.length < 1) return [];
            return this.expandOnClick ? [this.pdfPages[0]] : this.pdfPages;
        },
        pdfWidth() {
            if (this.mediaWidth.indexOf("px") !== -1) {
                let pxIndex = this.mediaWidth.indexOf("px");
                return this.mediaWidth.substring(0, pxIndex);
            }

            // Defaults to fill container
            return this.$refs.previewContainer ? (this.$refs.previewContainer as any).offsetWidth : 1000;
        },
    },
    watch: {
        async document(newDoc) {
            let fileType = newDoc.docInfo.fileType.toLowerCase();
            if (fileType.indexOf("pdf") !== -1) await this.loadPDF();
        },
    },
    async mounted() {
        if (this.mediaType === "PDF" && this.$refs.pdfContainer) await this.loadPDF();
        else if (this.mediaType === "NOT SUPPORTED") this.isLoaded = true;
    },
    methods: {
        openDocumentPreviewModal(document: any) {
            if (!this.expandOnClick) return;

            let modalTitle = this.docTypeDisplay;
            if (this.mediaType === "PDF") modalTitle = modalTitle.substring(0, modalTitle.indexOf("("));

            $modal.open(modalDocumentPreview, {
                name: "modalDocumentPreview",
                passedData: {
                    title: modalTitle,
                    document: document,
                },
                backdrop: true,
            });
        },
        onDocLoad(loadSuccess: boolean) {
            this.isLoaded = true;
            this.loadingError = !loadSuccess;

            if (this.loadingError) this.$emit("error");
            else this.$emit("load");
        },
        async loadPDF() {
            if (this.mediaType !== "PDF") return;

            this.isLoaded = false;

            // pdfjs.disabledWorker = true;
            // pdfjs.disabledStream = true;
            pdfjs.GlobalWorkerOptions.workerSrc =  '/dist/js/pdf.worker.min.mjs';

            let dataSrc = {
                url: this.downloadLink,
                httpHeaders: {
                    "Cache-Control": "no-cache",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${auth.getToken()}`,
                },
                verbosity: 0,
            };

            const loadedPdf = markRaw(await pdfjs.getDocument(dataSrc).promise);

            const pagePromises = [...Array(loadedPdf.numPages).keys()].map(num => loadedPdf.getPage(num + 1));
            let pages = await Promise.all(pagePromises);

            // Convert to page object to function, this preserves the original obj in order to access its private members
            this.pdfPages = pages.map(page => markRaw(page));
            this.isLoaded = true;
        },
        onPdfPageRender() {
            this.pdfPagesRendered++;
        },
        onPdfPageError() {
            console.warn("PDF page discarded.");
        },
    },
    components: {
        LoaderComponent,
        PDFPage,
    },
};
</script>
<style>
@import 'pdfjs-dist/web/pdf_viewer.css';
.document-preview-container {
    --media-width: 100%;
    --media-height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.document-preview-container .warning-message {
    width: fit-content;
    margin: auto;
    padding: 5px;
    border-radius: 10px;
    background-color: var(--error-color);
    color: var(--background-color);
}

.document-preview-container .warning-message > * {
    display: inline;
}

.document-preview-container.is-loading {
    background-color: var(--border-color);
    animation: loadingAnimation 1.3s infinite;
}

.document-preview-container figure {
    position: relative;
    margin: 0;
}

.document-preview-container figure img,
.document-preview-container figure video,
.document-preview-container figure .pdf-container {
    border-radius: 10px;
    border: 1px solid var(--border-color);
}

.document-preview-container figure *:not(figcaption) {
    width: var(--media-width);
    height: var(--media-height);
}

.document-preview-container figure figcaption {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--main-color);
    border-radius: 10px 0 10px 0;
    color: white;
    font-weight: bold;
    transition: all 0.2s ease-out;
}

.document-preview-container figure:hover figcaption {
    opacity: 1;
    padding: 5px 10px;
}

.document-preview-container .not-supported-container {
    padding: 15px;
    border: 2px solid var(--border-color);
    border-radius: 10px;
    background-color: var(--button-dropdown-list-background-color);
}

.document-preview-container .spinner-container > i {
    font-size: 3em;
}

@keyframes loadingAnimation {
    0% {
        opacity: 1;
    }

    70% {
        opacity: 0.7;
    }

    100% {
        opacity: 1;
    }
}
</style>
