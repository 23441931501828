<template>
    <div :class="{'insurance-information-container': true, 'fade-in-fast': true, 'empty': insuranceQuotes.length < 1, 'active': isDragging}"
                     ref="container"
                     @mousedown="startDragging"
                     @mousemove="dragging"
                     @mouseup="stopDragging"
                     @mouseleave="stopDragging"
                     >
        <div class="insurance-information-cards">
            <CardInsurance v-for="(quote, index) of insuranceQuotes"
                :key="index"
                :quote="quote"
                :buttonAction="() => openSendLinkToCustomerModal(quote)"
                class="insurance-card"/>
        </div>
    </div>
</template>
<script lang="ts">
    import { PropType, defineComponent } from 'vue'
    import { AutoCompleteLeadResponse } from '@core/classes/Insurance/AutoCompleteResponses';
    import { AutoCompleteQuote } from '@core/classes/Insurance/AutoCompleteModels';
    import CardInsurance from '@core/components/CardInsurance.vue'

    export default defineComponent({
        name: 'InsuranceQuoteList',
        props: {
            autoCompleteQuotes: Object as PropType<AutoCompleteLeadResponse | null>,
            openSendLinkToCustomerModal: Function,
            isLoading: Boolean,
            dragSpeed: {
                type: Number,
                default: 3
            }
        },
        components: {
            CardInsurance
        },
        data() {
            return {
                isDragging: false,
                startX: 0,
                scrollLeft: 0,
            }
        },
        computed: {
            insuranceQuotes(): AutoCompleteQuote[] {
                const initialQuotes = this.autoCompleteQuotes.quotes;
                if (!initialQuotes) return [];

                return initialQuotes.toSorted((a, b) => a.effectiveMonthly - b.effectiveMonthly);
            },
        },
        methods: {
            startDragging(event: MouseEvent): void {
                const container = (this.$refs.container as HTMLElement);
                if (!container) return;

                this.isDragging = true;
                this.startX = event.pageX - container.offsetLeft;
                this.scrollLeft = container.scrollLeft;
            },
            dragging(event: MouseEvent): void {
                const container = (this.$refs.container as HTMLElement);
                if (!container || !this.isDragging) return;

                event.preventDefault();

                const x = event.pageX - container.offsetLeft;
                const walk = (x - this.startX) * this.dragSpeed;
                container.scrollLeft = this.scrollLeft - walk;
            },
            stopDragging(): void {
                this.isDragging = false;
            },
        }
    });
</script>
<style>
    .insurance-information-container {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-self: center;
        max-width: 1100px;
        height: 350px;
        box-sizing: border-box;
        overflow-x: auto;
        overflow-y: hidden;
        mask-image: linear-gradient(to right, transparent 0%, black 20px, black calc(100% - 20px), transparent 100%);
        background-color: transparent; /* Set the initial background color */
        transition: var(--transition); /* Apply transition only to the background color */
    }
    .insurance-information-container .insurance-information-cards {
        display: flex;
        max-height: 370px;
        gap: 10px;
        padding: 0px 10px;
    }
    .insurance-information-cards .insurance-card {
        width: 185px;
    }

        .insurance-information-container:not(.empty) {
            cursor: grab;
        }

        .insurance-information-container.active:not(.empty) {
            cursor: grabbing;
        }

            .insurance-information-container.active > .card-wrapper > *:not(button) {
                pointer-events: none;
            }

        .insurance-information-container::-webkit-scrollbar {
            height: 8px;
        }

        .insurance-information-container::-webkit-scrollbar-track {
            border-radius: 10px;
        }

        .insurance-information-container::-webkit-scrollbar-thumb {
            border-radius: 10px;
        }

        .insurance-information-container .default-insurance-icon {
            position: relative;
            margin: auto;
            padding-bottom: 25px;
            color: var(--main-color);
        }

            .insurance-information-container .default-insurance-icon .fa-check {
                text-shadow: 1px 1px 1px var(--main-color),
                             -1px -1px 1px var(--main-color),
                             1px -1px 1px var(--main-color);
            }

            .insurance-information-container .default-insurance-icon .fa-hand-holding {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
</style>