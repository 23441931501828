<template>
    <div class="modal-container modal-deal-video-player">
        <div class="modal-header">
            Video Player
        </div>
        <div class="modal-body">
            <VideoRecorded :logs="modal.passedData.logs"
                            :video="modal.passedData.video"
                            :fimenuId="modal.passedData.fimenuId"/>
        </div>
        <div class="modal-footer">
            <button @click="cancel">
                Close
            </button>
        </div>
    </div>
</template>


<script>
    import $modal from '@core/services/modal'
    import ENUMS from '@core/classes/Enums'
    import VideoRecorded from '@/components/VideoRecorded.vue';

    export default {
        name: "modalDealVideoPlayer",
        props: ['modal'],
        data() {
            return {

            }
        },
        computed: {

        },
        methods: {

            async close() {
                if (this.checkChecklistFunc) {
                    this.isBusy = true;
                    const canProceed = await this.checkChecklistFunc(ENUMS.FIMENU_SECTION.FINALIZE_FI);
                    this.isBusy = false;
                    if (!canProceed) return;
                }

                $modal.close(this.controlNumber);
            },
            cancel() {
                $modal.cancel();
            },
        },
        components: {
            VideoRecorded
        }
    };
</script>
<style>
    .modal-deal-video-player {
        width: 1400px !important;
    }


</style>
