<template>
    <div class="modal-container">
        <div class="modal-header">
            Manufacturer Warranty
        </div>
        <div class="modal-body">
                <div class="warranty-grid">
                    <span></span><span>Manufacturer Months</span><span>Manufacturer Miles</span><span>Remaining Months</span><span>Remaining Miles</span>
                </div>
                <div class="warranty-grid">
                    <span>
                        Basic
                    </span>
                    <InputNumber v-model:saturn="modal.passedData.fimenu.vehicleWarranty.warranties.basic.months" placeholder="Months" :precision="0"></InputNumber>
                    <InputNumber v-model:saturn="modal.passedData.fimenu.vehicleWarranty.warranties.basic.miles" placeholder="Miles" :precision="0"></InputNumber>

                    <!--<InputNumber v-model:saturn="modal.passedData.fimenu.vehicleWarranty.warranties.basic.monthsLeft()" placeholder="Months" :disabled="true" :precision="0"></InputNumber>-->
                    <!--<InputNumber v-model:saturn="modal.passedData.fimenu.vehicleWarranty.warranties.basic.milesLeft()" placeholder="Miles" :disabled="true" :precision="0"></InputNumber>-->
                    <span>{{ modal.passedData.fimenu.vehicleWarranty.warranties.basic.monthsDisplay(modal.passedData.fimenu.inventoryType, modal.passedData.fimenu.vehicleWarranty.warrantyStartDate) }}</span>
                    <span>{{ modal.passedData.fimenu.vehicleWarranty.warranties.basic.milesDisplay(modal.passedData.fimenu.inventoryType, modal.passedData.fimenu.vehicleMiles) }}</span>

                    <span v-if="modal.passedData.fimenu.vehicleWarranty && modal.passedData.fimenu.vehicleWarranty.warranties && modal.passedData.fimenu.vehicleWarranty.warranties.basic && (modal.passedData.fimenu.vehicleWarranty.warranties.basic.months || modal.passedData.fimenu.vehicleWarranty.warranties.basic.miles)" class="warrantyindicator">
                        <span v-show="modal.passedData.fimenu.vehicleWarranty.warranties.basic.valid(modal.passedData.fimenu.vehicleMiles, modal.passedData.fimenu.vehicleWarranty.warrantyStartDate)"><i class="fa fa-check"></i></span>
                        <span v-show="!modal.passedData.fimenu.vehicleWarranty.warranties.basic.valid(modal.passedData.fimenu.vehicleMiles, modal.passedData.fimenu.vehicleWarranty.warrantyStartDate)"><i class="fa fa-exclamation-circle"></i></span>
                    </span>
                </div>
                <div class="warranty-grid">
                    <span>
                        Drivetrain
                    </span>
                    <InputNumber v-model:saturn="modal.passedData.fimenu.vehicleWarranty.warranties.drivetrain.months" placeholder="Months" :precision="0"></InputNumber>
                    <InputNumber v-model:saturn="modal.passedData.fimenu.vehicleWarranty.warranties.drivetrain.miles" placeholder="Miles" :precision="0"></InputNumber>

                    <!--<InputNumber v-model:saturn="modal.passedData.fimenu.vehicleWarranty.warranties.drivetrain.monthsLeft()" placeholder="Months" :disabled="true" :precision="0"></InputNumber>-->
                    <!--<InputNumber v-model:saturn="modal.passedData.fimenu.vehicleWarranty.warranties.drivetrain.milesLeft()" placeholder="Miles" :disabled="true" :precision="0"></InputNumber>-->
                    <span>{{ modal.passedData.fimenu.vehicleWarranty.warranties.drivetrain.monthsDisplay(modal.passedData.fimenu.inventoryType, modal.passedData.fimenu.vehicleWarranty.warrantyStartDate) }}</span>
                    <span>{{ modal.passedData.fimenu.vehicleWarranty.warranties.drivetrain.milesDisplay(modal.passedData.fimenu.inventoryType, modal.passedData.fimenu.vehicleMiles) }}</span>

                    <span v-if="modal.passedData.fimenu.vehicleWarranty && modal.passedData.fimenu.vehicleWarranty.warranties && modal.passedData.fimenu.vehicleWarranty.warranties.drivetrain && (modal.passedData.fimenu.vehicleWarranty.warranties.drivetrain.months || modal.passedData.fimenu.vehicleWarranty.warranties.drivetrain.miles)" class="warrantyindicator">
                        <span v-show="modal.passedData.fimenu.vehicleWarranty.warranties.drivetrain.valid(modal.passedData.fimenu.vehicleMiles, modal.passedData.fimenu.vehicleWarranty.warrantyStartDate)"><i class="fa fa-check"></i></span>
                        <span v-show="!modal.passedData.fimenu.vehicleWarranty.warranties.drivetrain.valid(modal.passedData.fimenu.vehicleMiles, modal.passedData.fimenu.vehicleWarranty.warrantyStartDate)"><i class="fa fa-exclamation-circle"></i></span>
                    </span>
                </div>
                <div class="warranty-grid">
                    <span>
                        Roadside
                    </span>
                    <InputNumber v-model:saturn="modal.passedData.fimenu.vehicleWarranty.warranties.roadside.months" placeholder="Months" :precision="0"></InputNumber>
                    <InputNumber v-model:saturn="modal.passedData.fimenu.vehicleWarranty.warranties.roadside.miles" placeholder="Miles" :precision="0"></InputNumber>

                    <!--<InputNumber v-model:saturn="modal.passedData.fimenu.vehicleWarranty.warranties.roadside.monthsLeft()" placeholder="Months" :disabled="true" :precision="0"></InputNumber>-->
                    <!--<InputNumber v-model:saturn="modal.passedData.fimenu.vehicleWarranty.warranties.roadside.milesLeft()" placeholder="Miles" :disabled="true" :precision="0"></InputNumber>-->
                    <span>{{ modal.passedData.fimenu.vehicleWarranty.warranties.roadside.monthsDisplay(modal.passedData.fimenu.inventoryType, modal.passedData.fimenu.vehicleWarranty.warrantyStartDate) }}</span>
                    <span>{{ modal.passedData.fimenu.vehicleWarranty.warranties.roadside.milesDisplay(modal.passedData.fimenu.inventoryType, modal.passedData.fimenu.vehicleMiles) }}</span>

                    <span v-if="modal.passedData.fimenu.vehicleWarranty && modal.passedData.fimenu.vehicleWarranty.warranties && modal.passedData.fimenu.vehicleWarranty.warranties.roadside && (modal.passedData.fimenu.vehicleWarranty.warranties.roadside.months || modal.passedData.fimenu.vehicleWarranty.warranties.roadside.miles)" class="warrantyindicator">
                        <span v-show="modal.passedData.fimenu.vehicleWarranty.warranties.roadside.valid(modal.passedData.fimenu.vehicleMiles, modal.passedData.fimenu.vehicleWarranty.warrantyStartDate)"><i class="fa fa-check"></i></span>
                        <span v-show="!modal.passedData.fimenu.vehicleWarranty.warranties.roadside.valid(modal.passedData.fimenu.vehicleMiles, modal.passedData.fimenu.vehicleWarranty.warrantyStartDate)"><i class="fa fa-exclamation-circle"></i></span>
                    </span>
                </div>
                <div class="warranty-grid">
                    <span>
                        Maintenance
                    </span>
                    <InputNumber v-model:saturn="modal.passedData.fimenu.vehicleWarranty.warranties.maintenance.months" placeholder="Months" :precision="0"></InputNumber>
                    <InputNumber v-model:saturn="modal.passedData.fimenu.vehicleWarranty.warranties.maintenance.miles" placeholder="Miles" :precision="0"></InputNumber>

                    <!--<InputNumber v-model:saturn="modal.passedData.fimenu.vehicleWarranty.warranties.maintenance.monthsLeft()" placeholder="Months" :disabled="true" :precision="0"></InputNumber>-->
                    <!--<InputNumber v-model:saturn="modal.passedData.fimenu.vehicleWarranty.warranties.maintenance.milesLeft()" placeholder="Miles" :disabled="true" :precision="0"></InputNumber>-->
                    <span>{{ modal.passedData.fimenu.vehicleWarranty.warranties.maintenance.monthsDisplay(modal.passedData.fimenu.inventoryType, modal.passedData.fimenu.vehicleWarranty.warrantyStartDate) }}</span>
                    <span>{{ modal.passedData.fimenu.vehicleWarranty.warranties.maintenance.milesDisplay(modal.passedData.fimenu.inventoryType, modal.passedData.fimenu.vehicleMiles) }}</span>

                    <span v-if="modal.passedData.fimenu.vehicleWarranty && modal.passedData.fimenu.vehicleWarranty.warranties && modal.passedData.fimenu.vehicleWarranty.warranties.maintenance && (modal.passedData.fimenu.vehicleWarranty.warranties.maintenance.months || modal.passedData.fimenu.vehicleWarranty.warranties.maintenance.miles)" class="warrantyindicator">
                        <span v-show="modal.passedData.fimenu.vehicleWarranty.warranties.maintenance.valid(modal.passedData.fimenu.vehicleMiles, modal.passedData.fimenu.vehicleWarranty.warrantyStartDate)"><i class="fa fa-check"></i></span>
                        <span v-show="!modal.passedData.fimenu.vehicleWarranty.warranties.maintenance.valid(modal.passedData.fimenu.vehicleMiles, modal.passedData.fimenu.vehicleWarranty.warrantyStartDate)"><i class="fa fa-exclamation-circle"></i></span>
                    </span>
                </div>
                <div class="input-box-2column-even">
                    <InputCheckbox v-model="modal.passedData.fimenu.vehicleWarranty.isLoanerIncluded" label="Loaner Included"></InputCheckbox>
                    <InputCheckbox v-model="modal.passedData.fimenu.vehicleWarranty.isCertified" label="Certified" v-show="modal.passedData.fimenu.inventoryType === 'Used'"></InputCheckbox>
                </div>
                <div v-if="modal.passedData.fimenu && modal.passedData.fimenu.vehicle && modal.passedData.fimenu.vehicle.make!=null">
                    <button class="button-show-vehicle-edits" @click="showVehicleEdits=!showVehicleEdits"><i class="fas fa-edit"></i></button><span>{{ modal.passedData.fimenu.vehicle.fullName() }}</span>
                </div>
                <div class="input-box-2column-even" v-if="showVehicleEdits">
                    <InputTextbox label="Make" v-model:saturn="modal.passedData.fimenu.vehicle.make" placeholder="Make"></InputTextbox>
                    <InputTextbox label="Year" v-model:saturn="modal.passedData.fimenu.vehicle.modelYear" placeholder="Year"></InputTextbox>
                </div>
                <div class="input-box-2column-even" v-if="showVehicleEdits">
                    <InputTextbox label="Model" v-model:saturn="modal.passedData.fimenu.vehicle.model" placeholder="Model"></InputTextbox>
                    <InputTextbox label="Style" v-model:saturn="modal.passedData.fimenu.vehicle.style" placeholder="Style"></InputTextbox>
                </div>
        </div>
        <div class="modal-footer">
            <button :disabled="v$.$invalid" class="button-save" @click="close()">Close</button>
        </div>
    </div>
</template>


<script>
    import api from '@core/services/api'
    import $modal from '@core/services/modal'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import InputCurrency from '@core/components/InputCurrency.vue'
    import InputCheckbox from '@core/components/InputCheckbox.vue'
    import InputNumber from '@core/components/InputNumber.vue'
    import Panel from '@core/components/Panel.vue'
    import RichTable from '@core/components/RichTable.vue'
    import { required, between, minValue, maxValue } from '@vuelidate/validators'
    import { useVuelidate } from '@vuelidate/core';
    import moment from 'moment'
    import util from '@core/services/util'

    export default {
        name: "modalWarranty",
        props: ['modal'],
        setup(){
            // New setup for vuelidate. Swap $v with v$
            return {v$: useVuelidate({$scope: false})}
        },
        data() {
            return {
                showVehicleEdits: false
            }
        },
        computed: {

        },

        validations() {
            const validations = {
            }
            return validations;
        },
        methods: {
            close() {
                $modal.close();
            },
        },
        components: {
            InputTextbox: InputTextbox,
            InputNumber: InputNumber,
            InputCheckbox: InputCheckbox
        }
    };
</script>
<style>
</style>
