<template>
    <div class="modal" tabindex="-1">
        <div class="modal-backdrop" @click="backdropClose()"></div>
        <component :is="component()" v-if="component" v-bind="{modal: data}"  />
    </div>
</template>


<script>
    import $modal from '@core/services/modal'
    import '@static/css/modals.css';
    export default {
        name: "modal",
        props: ['data', 'src'],
        data() {
            return {
                component: null,
            }
        },
        computed: {

        },
        mounted() {
            this.component = this.src;
        },
        methods: {
            backdropClose() {
                if (this.data && this.data.backdrop === true) {
                    $modal.cancel();
                }
            }


        }
    };
</script>
