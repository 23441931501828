<template>
    <div class="modal-container modal-x-large modal-document-preview" style="height: 100%;">
        <div class="modal-header">
            <span>{{ title }}</span>

            <div class="buttons-container">
                <button class="button" @click="printPDF" :disabled="isBusy">
                    <i class="fas fa-circle-notch fa-spin" v-if="isBusy"/>
                        Print
                </button>
                <button class="button" @click="() => downloadDocuments()" :disabled="isBusy">
                    <i class="fas fa-circle-notch fa-spin" v-if="isBusy"/>
                        Download
                </button>

                <button v-if="fimenu.buyersOrderEnabled && displayCreditApplication" class="button" @click="sendToLender" :disabled="isBusy">
                    <i class="fas fa-circle-notch fa-spin" v-if="isBusy"/>
                        Send to RouteOne
                </button>
            </div>
        </div>

        <div class="modal-body">
            <div :style="$grid('1')">
                <IsBusySectionComponent v-if="isLoading" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"/>

                    <!-- <div v-for="([key, value], index) in pdfEntires" v-bind:key="key">

                        <PDFViewer v-if="value" ref="pdfViewer" :src="value" @rendered="pdfRenderedCallback" :class="{'hide-component': isLoading}" style="overflow-x: none" />

                    </div> -->
                    <PDFViewer v-if="mergedPDF" ref="pdfViewer" :src="mergedPDF" @rendered="pageRendered" :class="{'hide-component': isLoading}" style="overflow-x: none" />
            </div>
        </div>

        <div class="modal-footer">
            <button class="button-unwind" @click="cancel()">Close</button>
        </div>
    </div>
</template>


<script>
    import settings from 'settings';
    import api from '@core/services/api';
    import util from '@core/services/util';
    import $modal from '@core/services/modal';
    import PDFViewer from '@core/components/PDFViewer.vue'
    import IsBusySectionComponent from '@core/components/IsBusySectionComponent.vue'
    import {PDFDocument} from 'pdf-lib'
    import {DisplayCreditApplicationActions} from '@/helpers/finance-channels-helper'


    export default {
        name: 'modalPreviewPDF',
        props: ['modal'],
        data() {
            return {
                title: 'Document Preview',
                pdf: {},
                isLoading: true,
                fimenu: null,
                mergedPDF: null,
                isBusy: true,
                renderedPDFCount: 0,
                packetSelectedDocuments: [],
                uploadedSelectedDocuments: []
            };
        },
        computed: {
            pdfEntires() {
                if(this.pdf != null){
                    return Object.entries(this.pdf).map((x) => x[0])
                } else return [];
            },
            arrayOfPDFS() {
                if (this.pdf != null) {
                    return Object.values(this.pdf).map((pdfObj) => pdfObj)
                } else return []
            },
            displayCreditApplication(){
                return DisplayCreditApplicationActions(this.fimenu) && !!this.fimenu?.dealJacket?.providerId;
            }

        },
        created() {
            this.title = this.modal.passedData.title ?? 'Document Preview';
            this.pdf = this.modal.passedData.pdf;
            this.fimenu = this.modal.passedData.fimenu;
            this.packetSelectedDocuments = this.modal.passedData.packetSelectedDocuments
            this.uploadedSelectedDocuments = this.modal.passedData.uploadedSelectedDocuments

            this.loadPDF();


        },
        methods: {
            cancel() {
                $modal.cancel();
            },
            async mergePDF(arrayOfPDF) {
                // Array of PDF in base64.
                const mergedPdf = await PDFDocument.create();

                for (const base64Pdf of arrayOfPDF) {
                    const pdfBuffer = util.base64ToUint8Array(base64Pdf); // Convert base64 to Uint8Array
                    const pdf = await PDFDocument.load(pdfBuffer); // Load PDF from buffer
                    const pages = await mergedPdf.copyPages(pdf, pdf.getPageIndices()); // Copy pages

                    pages.forEach(page => mergedPdf.addPage(page)); // Add pages to merged PDF
                }

                // Serialize the PDFDocument to bytes (a Uint8Array)
                const mergedPdfBytes = await mergedPdf.save();

                // Convert Uint8Array to base64 string (browser-compatible)
                this.mergedPDF = util.uint8ArrayToBase64(mergedPdfBytes);
                this.isBusy = false;
            },
            async loadPDF() {
                this.pdf = await this.modal.passedData.loadPDFs();
                await this.mergePDF(this.arrayOfPDFS);
            },
            async downloadDocuments() {
                try {

                    this.isBusy = true;
                    const pdfBlobFile = await util.base64ToBlob(this.mergedPDF, 'application/pdf');
                    let docName = null;

                    if (this.pdfEntires.length === 1) {
                        docName = this.fimenu.paperwork?.currentPacket()?.documents?.find(document => {
                            return document.signedDoc === this.pdfEntires[0] || document.filledDoc === this.pdfEntires[0];
                        })?.description ?? null;

                        if (docName === null) {
                            docName = this.fimenu.paperwork?.uploadedDocuments?.find(x => x.id === this.pdfEntires[0])?.docInfo?.docType ?? null;
                        }
                    }
                    docName = docName ?? "Packet";
                    const fileName = `${docName} - ${this.fimenu.dealNumber} - ${this.fimenu.storeCode}`;
                    await util.downloadBlob(pdfBlobFile, `${fileName}.pdf`);


                } catch (error) {
                     
                    console.error('Error downloading PDF:', error);
                    util.toastr("error", "Error", "Could not download the file.")
                    throw error; // Propagate the error to the caller
                } finally {
                    this.isBusy = false;
                }
            },
            async printPDF() {
                try{
                    this.isBusy = true;
                    const pdfBlobFile = await util.base64ToBlob(this.mergedPDF, 'application/pdf');
                    util.printBlob(pdfBlobFile)

                }catch(err) {
                     
                    console.error(err)
                    util.toastr("error", "Error", "Could not print the file.")
                } finally {
                    this.isBusy = false
                }
            },
            async sendToLender(){
                this.isBusy = true;
                try{
                    const response = await api.financeChannels.submitDocuments(this.fimenu.id, this.packetSelectedDocuments, this.uploadedSelectedDocuments)
                    if(response.data.statusCode == 200) { // Successfully sent
                        util.toastr("success", "Success", "Document(s) successfully submitted to RouteOne.")
                    } else { // Handled error
                        const messages = response.data.messages;
                        if (messages && messages.length > 0){
                            const joinedMessages = messages.join(" ");
                            util.toastr("error", "Error", joinedMessages);
                        } else {
                            util.toastr("error", "Error", `Unexpected sever error. Files could not be sent. Error #${response?.data?.status ?? 500}`);
                        }
                    }
                } catch(err){
                    // Check if err.message exists and is not empty
                    const errorMessage = err?.message ? err.message : "An unexpected error occurred.";

                    // Display the toast notification
                    util.toastr("error", "Error", errorMessage);
                } finally {
                    this.isBusy = false;
                }

            },
            pageRendered() {
                if (this.isLoading) this.isLoading = false;
            }


        },
        components: {
            PDFViewer,
            IsBusySectionComponent
        }
    };
</script>
<style>
    .modal-document-preview .pdf-section {
        height: 625px;
        margin-right: 30px;
        position: relative;
    }

    .modal-document-preview .pdf-section .pdf-container {
        overflow-y: scroll;
    }

    .modal-document-preview .pdf-section .pdf-container .page-container {
        margin: 0;
    }

    .modal-document-preview .pdf-section .loading-container {
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-inline: 100px;
        font-weight: 500;
        font-size: 64px;
        color: var(--main-color);
    }

    .modal-document-preview .pdf-section .loading-container > * {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .modal-document-preview .pdf-section .loading-container button {
        width: 250px;
        height: 60px;
        font-size: 0.4em;
        margin-top: 20px;
    }

    .modal-document-preview .testmodel-section {
        margin-right: 30px;
    }

    .modal-document-preview .buttons-container{
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .hide-component{
        opacity: 0
    }

    .modal-document-preview .buttons-container > button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
</style>
