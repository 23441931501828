<template>
    <div v-show="isComponentVisible" class="DealStatusChangeAnimation" id="DealStatusChangeAnimation">
        <div class="image-container">
            <!--<div class="animation-title">
                <h2>{{getDepartmentName}}</h2>
            </div>-->
            <img v-if="isComponentVisible" class="paperwork-image" :src="`/dist/img/DealStatusChange/${src}.svg`" alt="Deal Status Change Animation" />
        </div>
        <button class="button-close" @click="() => hideComponent(true)">
            <i class="fas fa-times" />
        </button>
    </div>
</template>

<script>
    import ENUMS from "@core/classes/Enums"
    export default {
        name: "DealStatusChangeAnimation",
        props: {
            fimenu: {
                type: Object,
                required: true,
            }
        },
        components: {},
        data() {
            return {
                isComponentVisible: false,
                animationDuration: 6000,
                src: "",
                animationTimeout: null,
            }
        },
        created() {
            this.$watch(() => this.fimenu.dealStatus, (newVal, oldVal) => {
                // console.log("oldVal", oldVal);
                // console.log("newVal", this.dealStatuses.getDisplay(newVal));
                // console.log("oldVal", this.dealStatuses.getDisplay(oldVal));
                //We're going forward in the deal
                if (oldVal < newVal) {
                    // console.log("Forward");
                    if (newVal === this.dealStatuses.SALES_IN_PROCESS || newVal === this.dealStatuses.SALES_MANAGER_IN_PROCESS) {
                        this.src = "to-sales"
                    }
                    else if (newVal === this.dealStatuses.SALES_MANAGER_FINISHED || newVal === this.dealStatuses.FI_IN_PROCESS) {
                        this.src = "to-fi"
                    }
                    else if (newVal === this.dealStatuses.FI_FINISHED || newVal === this.dealStatuses.ACCOUNTING_IN_PROCESS) {
                        this.src = "to-acct"
                    }
                    else if (newVal === this.dealStatuses.ACCOUNTING_FINISHED || newVal === this.dealStatuses.FINALIZED) {
                        this.src = "to-finalized"
                    }
                }
                //we're reverting deal
                else {
                    // console.log("Reversing");
                    if (newVal === this.dealStatuses.SALES_IN_PROCESS) {
                        this.src = "to-sales"
                    }
                    else if (newVal === this.dealStatuses.SALES_MANAGER_IN_PROCESS) {
                        this.src = "revert-fi"
                    }
                    else if (newVal === this.dealStatuses.FI_IN_PROCESS || newVal === this.dealStatuses.FI_FINISHED) {
                        this.src = "revert-acct"
                    }
                    else if (newVal === this.dealStatuses.ACCOUNTING_IN_PROCESS || newVal === this.dealStatuses.ACCOUNTING_FINISHED) {
                        this.src = "revert-finalized"
                    }
                }

                this.showStatusChange()
            }, {});

            this.isExpanded = this.defaultExpanded ? true : false;
        },

        computed: {
            dealStatuses() {
                return ENUMS.DEAL_STATUS;
            },
            getDepartmentName() {
                if (this.fimenu.dealStatus === this.dealStatuses.SALES_FINISHED || this.fimenu.dealStatus === this.dealStatuses.FI_IN_PROCESS) {
                    return ENUMS.DEAL_STATUS.getDepartment(ENUMS.DEAL_STATUS.FI_IN_PROCESS);
                }
                else if (this.fimenu.dealStatus === this.dealStatuses.FI_FINISHED || this.fimenu.dealStatus === this.dealStatuses.ACCOUNTING_IN_PROCESS) {
                    return ENUMS.DEAL_STATUS.getDepartment(ENUMS.DEAL_STATUS.ACCOUNTING_IN_PROCESS);
                }
                else if (this.fimenu.dealStatus === this.ACCOUNTING.FI_FINISHED || this.fimenu.dealStatus === this.dealStatuses.FINALIZED) {
                    return ENUMS.DEAL_STATUS.getDepartment(ENUMS.DEAL_STATUS.FINALIZED);
                }
                return "";
            },
        },
        methods: {
            toggleComponentFadeClass() {
                const component = document.getElementById("DealStatusChangeAnimation");
                component?.classList.toggle("fadedout");
            },
            showStatusChange() {
                if (this.isComponentVisible) {
                    this.hideComponent();
                } else {
                    this.showComponent();
                }
            },
            hideComponent(isImmediateExit = false) {
                //add the fadeout class
                this.toggleComponentFadeClass();

                if (isImmediateExit) {
                    clearTimeout(this.animationTimeout)
                    this.isComponentVisible = false;
                }
                else {
                    setTimeout(() => {
                        this.isComponentVisible = false;
                    }, 500);
                }
                this.$nextTick(() => {
                    //remove the fadeout class
                    this.toggleComponentFadeClass();
                })
            },
            showComponent() {
                this.isComponentVisible = true;
                this.animationTimeout = setTimeout(() => {
                    this.showStatusChange();
                }, this.animationDuration);
            }
        }

    }
</script>

<style>

    .DealStatusChangeAnimation {
        font-family: serif;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 22;
        height: 100%;
        width: 100%;
        flex-direction: column;
        background: var(--background-color);
        user-select: none;
        opacity: 0;
        z-index: calc(infinity);
        animation: fadeIn 0.5s ease-out forwards;
    }

        .DealStatusChangeAnimation.fadedout {
            animation: fadeout 0.45s ease-out forwards !important;
        }

        .DealStatusChangeAnimation, .DealStatusChangeAnimation .image-container, .DealStatusChangeAnimation .image-container .animation-title {
            display: flex;
            align-items: center;
            justify-content: center;
        }


            .DealStatusChangeAnimation .image-container {
                position: relative;
                width: 50%;
                flex-direction: column;
            }

                .DealStatusChangeAnimation .image-container img {
                    margin-top: -100px;
                    width: 65%;
                    scale: 2;
                }

                .DealStatusChangeAnimation .image-container .animation-title {
                    width: fit-content;
                    padding: 0 50px;
                    position: absolute;
                    bottom: 100%;
                }

            .DealStatusChangeAnimation .animation-title::before {
                content: "";
                position: absolute;
                top: calc( 100% - 2px );
                width: 100%;
                height: 4px;
                border-radius: 10px;
                background-color: var(--third-color);
                transform-origin: center center;
                transform: scaleX( 0 );
                animation: line-animation 3s ease infinite alternate;
            }

            .DealStatusChangeAnimation .animation-title h2 {
                font-size: 6rem;
                animation: clip-path-reveal-1 3s ease infinite alternate;
                color: var(--main-color);
                margin: 0;
            }

            .DealStatusChangeAnimation button.button-close {
                top: 1% !important;
                right: 1% !important;
            }

    @keyframes clip-path-reveal-1 {
        0%, 25% {
            clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
        }

        50% {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        }
    }

    @keyframes line-animation {
        0% {
            transform: scaleX( 0 );
        }

        15% {
            transform: scaleX( 0 );
        }

        20%, 25% {
            transform: scaleX( 1 );
            top: calc( 100% - 2px );
        }

        50% {
            transform: scaleX( 1 );
            top: 0px;
        }

        70% {
            transform: scaleX( 0.2 );
            top: 0px;
        }

        80%, 100% {
            transform: scaleX( 0.2 );
            top: 0px;
        }
    }

    @keyframes clip-path-reveal-1 {
        0%, 25% {
            clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
        }

        50% {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        }
    }
</style>