<template>
    <div :class="{'text-error': doc.status == ENUMS.PaperworkDocumentStatus.ErrorGenerating}">
        <TooltipComponent :tooltip="errorMessage" direction="left" maxWidth="450">
            <template #showFromOutside>
                <div v-if="documentWasGenerated" :class="{'has-field-error': (doc.error != undefined && doc.error != null)}">
                    <button v-if="canSelectDocument(doc)" :class="{ 'ignore-deal-locks': !paperworkBusy }" style="border-radius: 5px; width: 55px;" @click="() => executeView(doc)" :disabled="paperworkBusy"><i class="fas fa-eye"></i></button>
                    <button v-else :class="{ 'ignore-deal-locks': !paperworkBusy }" style="border-radius: 5px; width: 55px;" @click="executeDownload" :disabled="paperworkBusy || isDownloading">
                        <i class="fas fa-circle-notch fa-spin" v-if="isDownloading" />
                        <i class="fas fa-download" v-else />
                    </button>
                </div>
                <div v-else class="messageContainer">
                    <button style="border-radius: 5px; width: 55px;" @click="() => regenerateDocument(doc)" class="button-unwind" :disabled="!$global.isAdminView">
                        <i class="fas fa-exclamation-triangle" style="color: white;"></i>
                    </button>
                </div>
            </template>
        </TooltipComponent>
    </div>
</template>

<script>
    import api from '@core/services/api';
    import auth from "@core/services/auth";
    import ENUMS from '@core/classes/Enums'
    import settings from 'settings';
    import TooltipComponent from '@core/components/TooltipComponent.vue'

    export default {
        name: "DocumentPacketActions",
        props: ['modal', "doc", "downloadDocument", "paperworkBusy", "isCurrent", "canSelectDocument", "downloadMultipleDocuments", "regenerateDocument"],
        computed: {
            ENUMS() {
                return ENUMS
            },
            user() {
                return auth.getTokenPayload();
            },
            documentWasGenerated() {
                return !!(this.doc.filledDoc || this.doc.signedDoc || this.doc.docInfo)
            },
            errorMessage() {
                let message = []

                if (!this.documentWasGenerated && this.user.EmployeeAccess.IsAdmin) message.push(...["Please click to regenerate document", ""]);
                if (this.doc?.error?.errorDescription) message.push(this.doc.error.errorDescription);

                return message
            }
        },
        created(){
        },
        data() {
            return {
                isDownloading: false
            }
        },
        components: {
            TooltipComponent
        },
        methods: {
            executeView(doc) {
                const packetDocuments = []
                const uploadedDocuments = []

                if (doc.documentGenerationType == ENUMS.DocumentGenerationType.Paperwork) {
                    packetDocuments.push(doc.signedDoc ?? doc.filledDoc)
                } else if (doc.documentGenerationType == ENUMS.DocumentGenerationType.Uploaded) {
                    uploadedDocuments.push(doc)
                }
                this.downloadMultipleDocuments(packetDocuments, uploadedDocuments)
            },
            async executeDownload() {
                this.isDownloading = true;

                try {
                    switch (this.doc.documentGenerationType) {
                        case ENUMS.DocumentGenerationType.Paperwork: {
                            const docPath = this.doc.signedDoc || this.doc.filledDoc;
                            const docDescription = this.doc.description || this.doc.typeString;
                            this.downloadDocument(
                                docPath,
                                docDescription,
                                this.doc.paperworkDocumentConfigId,
                                this.isCurrent
                            );
                            break;
                        }
                        case ENUMS.DocumentGenerationType.Uploaded: {
                            await api.documents.downloadDocument(this.doc.id, this.doc.docInfo.fileName);
                            break;
                        }
                        default:
                            // eslint-disable-next-line no-console
                            console.error('Unsupported document generation type');
                    }
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error('Error during document download:', error);
                    // Handle the error as needed
                } finally {
                    this.isDownloading = false;
                }
            }
        }
    };
</script>

