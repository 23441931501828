<template>
      <TooltipComponent v-if="doc.documentGenerationType == ENUMS.DocumentGenerationType.Paperwork" :tooltip="getProductDetails(doc)" direction="right" maxWidth="450">
        <template #showFromOutside>
            <div v-if="doc.description" >
                {{ doc.description }} <i v-if="doc.isLenderSpecific && user && user.EmployeeAccess.IsAdmin" class="fas fa-university"></i>
            </div>
            <div v-else>
                <span class="defaultCursor">{{ fimenu.getPaperDocumentName(doc) }}</span>
            </div>
            <div v-if="doc.type != ENUMS.PAPERWORK_DOCUMENT_TYPES.PaperworkDocument && doc.type != ENUMS.PAPERWORK_DOCUMENT_TYPES.AcceptDecline" class="defaultCursor">
                Provider: {{ getPaperworkDocType(doc).provider || doc.typeString }}
            </div>
            <div v-if="$global.isAdminView" class="admin-details">
                <div v-if="doc.paperworkDocumentConfigId" class="details-wrapper">
                    <div class="latest-version">
                        <strong>Config Id:</strong><span>{{ doc.paperworkDocumentConfigId }}</span>
                    </div>
                    <div class="latest-version">
                        <strong>Latest version:</strong>

                        <span v-if="extraConfigInfo">{{ extraConfigInfo.isLatest ? "Current" : extraConfigInfo.latestId }}</span>
                        <button title="More info" @click="() => setExtraConfigInfo(doc)">
                            <i class="fas fa-info"></i>
                        </button>
                    </div>
                </div>
                <span v-else><strong>Config Id:</strong> N/A</span>
            </div>
        </template>
    </TooltipComponent>
    <div v-else-if="doc.documentGenerationType == ENUMS.DocumentGenerationType.Uploaded" class="defaultCursor">
        <span class="defaultCursor">{{ doc.display }}</span>
        <br/>
        <span v-if="UploadedDocumentName">{{ UploadedDocumentName }}</span>
    </div>

</template>

<script>
    import api from "@core/services/api";
    import ENUMS from "@core/classes/Enums"
    import TooltipComponent from '@core/components/TooltipComponent.vue'
    import util from '@core/services/util';

    export default {
        name: "DocumentPacketDocumentName",
        props: ["doc", "getProductDetails", "user", "getPaperworkDocType", "documentName", "fimenu"],
        data() {
            return {
                extraConfigInfo: null
            }
        },
        computed:{
            ENUMS() {
                return ENUMS
            },
            UploadedDocumentName() { //Returns the customers names associated to the document.
                const documentAssociations = this.doc?.associations?.filter(asoc => asoc?.type == ENUMS.DOC_ASSOCIATION.Customer);
                if (!documentAssociations || documentAssociations.length === 0) return null;

                const customerNames = [];

                documentAssociations.forEach(association => {
                    const customerId = association?.id;

                    if (!util.isNullOrEmpty(customerId)) {
                        if (this.fimenu.customer?.id === customerId) {
                            const customerName = this.fimenu.customer?.getFullName()?.toUpperCase();
                            if (customerName) customerNames.push(customerName);
                        }

                        if (this.fimenu.coCustomer?.id === customerId) {
                            const coCustomerName = this.fimenu.coCustomer?.getFullName()?.toUpperCase();
                            if (coCustomerName) customerNames.push(coCustomerName);
                        }
                    }
                });

                if (customerNames.length === 0) return null;
                if (customerNames.length === 1) return `Customer(s): ${customerNames[0]}`;

                // Not sure if a document could match both customers. But the code is very flexible
                // and allows it; therefor added support for it.
                return `Customer(s): ${customerNames.join(' & ')}`;
            }
        },
        methods: {
            async setExtraConfigInfo(doc) {
                const response = await api.utilities.getActiveDocConfigForCode(doc.paperworkDocumentConfigId)
                const activeDocConfigId = response.data?.id

                this.extraConfigInfo = {
                    id: doc.paperworkDocumentConfigId,
                    isLatest: doc.paperworkDocumentConfigId === activeDocConfigId,
                    latestId: activeDocConfigId
                }
            }
        },
        components: {
            TooltipComponent
        },
    };
</script>

<style scoped>
    .admin-details{
        margin-top: 5px;
        font-size: 12px;
    }

        .admin-details .details-wrapper {
            display: flex;
            flex-direction: column;
        }

        .admin-details .details-wrapper button {
            padding: 0;
            width: 15px;
            height: 15px;
            min-height: unset;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 8px;
        }

        .admin-details .details-wrapper .latest-version {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            column-gap: 5px;
        }
</style>