<template>
    <div class="insurance-page-container">
        <div v-if="isAutoCompleteEnabled && (autoCompleteInsuranceNotFound || autoCompleteQuoteNotFound)" class="not-found-group">
            <span class="ac-not-found-warning">
                <i class="fas fa-exclamation-circle" /> {{ autoCompleteNotFoundMessage }}
            </span>

            <div class="insurance-actions fade-in-fast">
                <small v-show="verificationRequestTimestamp">
                    <b>Last Scan: </b> {{formatDate(verificationRequestTimestamp)}}
                </small>
                <button :class="{'hint-click': !isRescanDisabled, 'polling': isPollingActive}" :disabled="isRescanDisabled" @click="rerunInsuranceScan">
                    <span v-show="!isPollingActive">Rerun Scan</span>
                    <i v-show="isPollingActive" class="fas fa-sync fa-spin" />
                </button>
            </div>
        </div>

        <PanelInsurance
            :isAutoCompleteEnabled="isAutoCompleteEnabled"
            :storeCode="storeCode"
            :dealId="dealId"
            :customer="customer"
            :data="data"
            :validation="validation"
            :autoCompleteVerification="verificationData"
            :getLinkToPortal="getLinkToPortal"
            :sendPortalLinkToCustomer="sendPortalLinkToCustomer"
            :isLoading="isLoading"
        />

        <InsuranceQuoteList
            v-if="autoCompleteQuotes"
            :autoCompleteQuotes="quotesData"
            :openSendLinkToCustomerModal="openSendLinkToCustomerModal"
            :isLoading="isLoading"
        />
    </div>
</template>
<script lang="ts">
    import { defineComponent } from 'vue';
    import settings from '@/settings';

    import $modal from '@core/services/modal'
    import api from '@core/services/api';
    import util from '@core/services/util';

    import { 
        AutoCompleteLeadRequest, 
        AutoCompleteLeadResponse, 
        AutoCompleteVerificationRequest, 
        AutoCompleteVerificationResponse 
    } from '@core/classes/Insurance/AutoCompleteResponses';
    import { ApiExchange } from '@core/classes/Responses/SharedClasses';
    import { AutoCompleteData } from '@core/classes/Insurance/AutoCompleteData';
    import ENUMS from '@core/classes/Enums';
    import FIMenuCustomer from '@core/classes/FIMenuCustomer';
    import FIMenuInsurance from '@core/classes/FIMenuInsurance';

    import modalSendLinkToCustomer from '@/modals/modalSendLinkToCustomer.vue';

    import InsuranceQuoteList from '@/components/fimenu/InsuranceQuoteList.vue';
    import PanelInsurance from '@/components/PanelInsurance.vue';

    export default defineComponent({
        name: 'InsurancePage',
        components: {
            PanelInsurance,
            InsuranceQuoteList,
        },
        props: {
            isAutoCompleteEnabled: Boolean,
            storeCode: String,
            dealId: String,
            dealKind: Number,
            customer: FIMenuCustomer,
            data: FIMenuInsurance,
            validation: Object,
        },
        data() {
            return {
                autoCompleteVerification: null as ApiExchange<AutoCompleteVerificationRequest, AutoCompleteVerificationResponse>,
                autoCompleteQuotes: null as ApiExchange<AutoCompleteLeadRequest, AutoCompleteLeadResponse>,
                isLoading: false,
                isPollingActive: false,
                pollingTimeout: null,
            }
        },
        computed: {
            isRescanDisabled(): boolean {
                return this.isLoading || this.isPollingActive;
            },
            verificationRequestTimestamp(): Date {
                return this.autoCompleteVerification?.getRequestTimestamp();
            },
            verificationResponseTimestamp(): Date {
                return this.autoCompleteVerification?.getResponseTimestamp();
            },
            verificationData(): AutoCompleteVerificationResponse {
                return this.autoCompleteVerification?.getResponseData();
            },
            quotesData(): AutoCompleteLeadResponse {
                return this.autoCompleteQuotes?.getResponseData();
            },
            autoCompletePending(): boolean {
                if (!this.verificationData && !this.quotesData) return false;

                const stateIsPending = this.verificationData?.status === 'pending' || this.quotesData?.status === 'pending';
                const waitingForRescan = (this.verificationData) ? this.verificationResponseTimestamp < this.verificationRequestTimestamp : false;

                return stateIsPending || waitingForRescan;
            },
            autoCompleteInsuranceNotFound(): boolean {
                return this.verificationData?.status === 'failure';
            },
            autoCompleteQuoteNotFound(): boolean {
                return this.quotesData?.status === 'failure';
            },
            autoCompleteNotFoundMessage(): string {
                if (!this.isAutoCompleteEnabled) return null;

                const insuranceStr = this.autoCompleteInsuranceNotFound ? 'current coverage' : '';
                const quotesStr = this.autoCompleteQuoteNotFound ? 'insurance quotes' : '';
                const bothNotFound = this.autoCompleteInsuranceNotFound && this.autoCompleteQuoteNotFound;
                return `Unable to find ${insuranceStr}${bothNotFound ? ' or ' : ''}${quotesStr} for this customer.`;
            }
        },
        watch: {
            autoCompletePending(newVal) {
                this.isPollingActive = newVal;
            },
            isPollingActive(newVal) {
                if (newVal) {
                    // Start polling
                    this.pollingTimeout = setTimeout(this.loadAutoCompleteResults, 10000);
                }
                else {
                    // Stop polling
                    clearTimeout(this.pollingTimeout);
                }
            }
        },
        async created() {
            try {
                await this.loadAutoCompleteResults();
            }
            catch (err) {
                console.error('Unable to load autocomplete results: ', err);
            }
        },
        unmounted() {
            clearTimeout(this.pollingTimeout);
        },
        methods: {
            async loadAutoCompleteResults() {
                if (!this.isAutoCompleteEnabled) return;

                const associations = [
                    { type: ENUMS.DOC_ASSOCIATION.Deal, id: this.dealId },
                    { type: ENUMS.DOC_ASSOCIATION.Customer, id: this.customer.id }
                ];

                const response = await api.autoComplete.findAllLatestExchanges(associations);
                if (!response?.data) {
                    console.error('Error when retrieving AutoComplete data: ', '[NO RESPONSE]');
                    return;
                }

                const autoCompleteData = new AutoCompleteData(response.data);
                this.autoCompleteVerification = autoCompleteData.verification;
                this.autoCompleteQuotes = autoCompleteData.lead;

                if (this.isPollingActive) {
                    clearTimeout(this.pollingTimeout);
                    this.pollingTimeout = setTimeout(this.loadAutoCompleteResults, 10000);
                }
            },
            async rerunInsuranceScan() {
                this.isLoading = true;
                try {
                    // TODO: can re-reun insurance verification, but not the quote. is that what we want?
                    const response = await api.autoComplete.createVerificationFromDeal(this.dealId, this.dealKind, this.storeCode, true);
                    if (!response?.data) throw new Error('No response from server.');
                    if (response.data.message) throw new Error(response.data.message);

                    await this.loadAutoCompleteResults();
                }
                catch (err) {
                    
                    console.warn('COULD NOT START AUTOCOMPLETE CASE: ', err);
                }
                finally {
                    this.isLoading = false;
                }
            },
            async getLinkToPortal(): Promise<string> {
                const response = await api.autoComplete.getLinkToPortal(settings.appDomain, this.customer.id);
                return response?.data;
            },
            async sendPortalLinkToCustomer(sendMethod: string) : Promise<void> {
                const request = {
                    appDomain: settings.appDomain,
                    customerId: this.customer.id,
                    storeCode: this.storeCode,
                    phoneNumber: (sendMethod === 'text') ? `+1${this.customer.cell}` : null,
                    emailAddress: (sendMethod === 'email') ? this.customer.email : null
                };

                this.isLoading = true;
                try {
                    const response = await api.autoComplete.sendLinkToPortal(request);
                    if (!response?.data?.success) throw new Error('Unable to send link.');

                    util.toastr('success', 'Success', 'Successfully sent link to customer.');
                }
                catch {
                    util.toastr('error', 'Error', 'An error occurred while trying to send a link to the customer.');
                }
                finally {
                    this.isLoading = false;
                }
            },
            openSendLinkToCustomerModal() {
                $modal.open(modalSendLinkToCustomer, {
                    name: 'modalSendLinkToCustomer',
                    passedData: {
                        title: 'Send Insurance Link',
                        linkNoun: 'Insurance Upload',
                        generateLink: this.getLinkToPortal,
                        abilityToSendCodeToGM: false,
                    },
                    postFunction: this.sendPortalLinkToCustomer
                });
            },
            formatDate(date: string|Date) {
                const dateObj = (date instanceof Date) ? date : new Date(date);
                return dateObj.toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short'});
            }
        },
    });
</script>
<style scoped>
    .insurance-page-container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .insurance-page-container .not-found-group {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

        .insurance-page-container .not-found-group .ac-not-found-warning {
            width: fit-content;
            margin: 10px 0;
            padding: 10px;
            background-color: var(--print-color);
            border-radius: 10px;
            color: var(--background-color);
        }

        .insurance-page-container .not-found-group .insurance-actions {
            margin: 10px 0;
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: flex-end;
        }

        .insurance-page-container .not-found-group .insurance-actions button {
            background-color: var(--success-color);
        }
</style>