<template>
    <div
        class="container"
        :style="`margin-top: ${marginTop}px; margin-bottom: ${marginBottom}px; border-color: ${color}; height: ${height}px; background-color: ${color}`"
    />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'SoftDivider',
    props: {
        marginTop: {
            type: Number,
            default: 1 // Default top margin
        },
        marginBottom: {
            type: Number,
            default: 1 // Default bottom margin
        },
        color: {
            type: String,
            default: 'var(--main-color)' // Default to main color
        },
        height: {
            type: Number,
            default: 1 // Default height of 1px
        }
    }
});
</script>

<style scoped>
.container {
    width: 100%;
}
</style>
