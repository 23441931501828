<template>
    <Panel title="MS Vehicle Map" class="panel-MSVehicle">
        <template v-slot:header>
            <div v-if="fimenu.marketScan && fimenu.marketScan.vehicleId"><span>ID:</span><span>{{ fimenu.marketScan.vehicleId }}</span></div>
            <div class="panel-buttons">
                <button class="button panel-button button-edit" @click="refreshMSVehicle()" :disabled="isBusy"><i class="fas fa-sync"></i></button>
            </div>
        </template>

        <div :style="$grid('1')" class="panel-body no-margin-grid">
            <div v-if="fimenu.marketScan && fimenu.marketScan.vehicleId">
                <span v-if="vehicle"> {{ vehicle.yearDisplay }}</span>
                <span v-if="vehicle"> {{ makeDisplay(vehicle.makeID) }}</span>
                <span v-if="vehicle"> {{ modelDisplay(vehicle.modelID) }}</span>
                <span v-if="vehicle"> {{ vehicle.shortDescription }}</span>
            </div>
            <div v-else>
                <span>There is no vehicle mapped.</span>
            </div>
        </div>
    </Panel>
</template>

<script>
    import api from '@core/services/api'
    import util from '@core/services/util';
    import $modal from '@core/services/modal'
    import TooltipComponent from '@core/components/TooltipComponent.vue'
    import ENUMS from "@core/classes/Enums"
    import Panel from '@core/components/Panel.vue'
    import modalMSvehicles from '@core/modals/modalMSvehicles.vue'

    export default {
        name: "PanelMSVehicles",
        props: ['fimenu', 'mscanAccount'],
        data() {
            return {
                vehicle: null,
                vehicleId: null,
                marketScanMakesModels: null,
                isBusy: null,
            }
        },
        computed: {
            util() {
                return util;
            },
            ENUMS() {
                return ENUMS;
            },
        },
        async created() {
            this.isBusy = true;
            //do stuff
            const marketScanMakesModels = await api.marketScan.getMarketScanMakesModels();
            this.marketScanMakesModels = marketScanMakesModels.data;
            if (!this.fimenu.marketScan.vehicleId) this.getVehiclesForVin(this.fimenu.vin, this.fimenu.inventoryType == 'New');
            if (this.fimenu.marketScan && this.fimenu.marketScan.vehicleId) {
                this.setVehicle(this.fimenu.marketScan.vehicleId);
            }
            this.isBusy = false;
        },

        methods: {
            makeDisplay(id) {
                // console.log('id', id)
                const make = this.marketScanMakesModels.makes.find(m => m.id == id);
                if (make)
                    return make.name.replaceAll('_',' ');
                else
                    return '';
            },
            modelDisplay(id) {
                const model = this.marketScanMakesModels.models.find(m => m.id == id);
                if (model)
                    return model.name.replaceAll('_', ' ');
                else
                    return '';

            },
            refreshMSVehicle() {
                //do something to update the MS Vehicle
                this.isBusy = true;
                // console.log('setting vehicleid from vin', this.fimenu.vin);
                this.getVehiclesForVin(this.fimenu.vin, this.fimenu.inventoryType == 'New');
                this.isBusy = false;

            },
            setFimenuVehicleId(vehicleId) {
                this.vehicleId = vehicleId;
                this.fimenu.marketScan.vehicleId = vehicleId;
                // console.log('about to call getMarketScanVehicleById', vehicleId)
                this.setVehicle(vehicleId);
            },
            setVehicle(vehicleId) {
                api.marketScan.getMarketScanVehicleById(vehicleId).then((vehicleresponse) => {
                    this.vehicle = vehicleresponse.data;
                });
            },
            getVehiclesForVin(vin, isNew) {
                api.marketScan.getVehiclesByVIN(this.mscanAccount, vin, isNew)
                    .then((response) => {
                        /*eslint-disable no-console*/
                        console.log('getVehiclesByVIN response', response);
                        if (response) {
                            console.log('getVehiclesByVIN', response.data);
                            if (response.data && response.data.length == 1) {
                                // console.log('1 item', response.data[0]);
                                this.setFimenuVehicleId(response.data[0].id);
                            }
                            else if(!this.fimenu.isSpectator) {
                                $modal.open(modalMSvehicles, {
                                    name: 'modalMSvehicles',
                                    passedData: {
                                        vinMatches: response.data,
                                        vin: vin,
                                        vehicle: this.fimenu.vehicle,
                                        marketScanMakesModels: this.marketScanMakesModels,
                                        mscanAccount: this.mscanAccount
                                    },
                                    backdrop: false,
                                    postFunction: (data) => {
                                        //console.log('data', data);
                                        this.setFimenuVehicleId(data);
                                    }
                                });
                            }
                        }
                    })
                    .catch(err => {
                        console.error(err)
                    });
                    /*eslint-enable*/
            },

        },
        components: {
            Panel,
        }
    };
</script>
<style>
    .panel-MSVehicle {
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

        .panel-MSVehicle .richtable-container {
            width: 100%;
            height: 100%;
            overflow-y: auto;
        }

        .panel-MSVehicle .empty-list {
            text-align: center;
            position: relative;
        }

    .button-small {
        padding: 0px;
        margin-left: 1px;
        border-radius: 5px;
        width: 35px !important;
        min-width: 35px !important;
    }
</style>