<template>
    <div class="modal-container modal-richtable">
        <div class="modal-header">
            {{ title }}
            <SwitchButton
                :active="isRichtable"
                :keepActiveSynced="true"
                @update="() => isRichtable = !isRichtable"
                rightLabel="Richtable"
                leftLabel="JSON view"/>
        </div>
        <div class="modal-body">
            <RichTable v-if="data && isRichtable" noData="N/A" :search="search" :headers="headers" :fixedHeader="fixedHeader" :serverSide="serverSide" :tableData="data" :paging="true" :additionalHeaders="additionalHeaders">
                <template #tooltip="rate">
                    <div class="toolTip-container">
                        <TooltipComponent v-if="rate.meta" text="View" :tooltip="metaTooltipText(rate.meta)" direction="top" maxWidth="300"/>
                        <div v-else>
                            N/A
                        </div>
                    </div>
                </template>
                <template #tooltipTwo="rate">
                    <div class="toolTip-container">
                        <TooltipComponent v-if="rate.surcharges.length != 0" text="View" :tooltip="surchargesTooltipText(rate.surcharges)" direction="top" maxWidth="300" />
                        <div v-else>
                            N/A
                        </div>
                    </div>
                </template>
            </RichTable>
            <div v-else-if="data">
                <json-viewer :value="data"
                            expanded
                            copyable
                            boxed>
                    <template #copy>copy</template>
                </json-viewer>
            </div>
            <div v-else>
            </div>
        </div>
        <div class="modal-footer">
            <button class="button-unwind" @click="cancel()">Close</button>
        </div>
    </div>
</template>
<script>
    import TooltipComponent from '@core/components/TooltipComponent.vue'
    import $modal from '@core/services/modal'
    import RichTable from '@core/components/RichTable.vue'
    import SwitchButton from '@/components/SwitchButton.vue';
    import JsonViewer from 'vue-json-viewer'

    export default {
        name: "modalRateResponse",
        props: ['modal'],
        data() {
            return {
                title: null,
                data: null,
                search: null,
                headers: null,
                additionalHeaders: null,
                fixedHeader: null,
                serverSide: null,
                development: false,
                isRichtable: true,
            };
        },
        computed: {
        },
        created() {
            this.title = this.modal.passedData.title;
            this.data = this.modal.passedData.data;
            this.search = this.modal.passedData.search;
            this.headers = this.modal.passedData.headers;
            this.additionalHeaders = this.modal.passedData.additionalHeaders;
            this.fixedHeader = this.modal.passedData.fixedHeader ?? false;

            this.serverSide = this.modal.passedData.serverSide;
            if (this.serverSide) {
                const refresh = this.serverSide.refresh
                this.serverSide.refresh = (restartPage = false) => refresh(this.serverSide, this.setProperties, restartPage)
                this.serverSide.refresh()
            }

            this.development = this.$route.query.debug == 'true';
        },
        methods: {
            metaTooltipText(meta) {
                return Object.keys(meta).map(m => `${m}: ${JSON.stringify(meta[m])}`);
            },
            surchargesTooltipText(surcharges) {
                return surcharges.map( s =>  ["{",...this.metaTooltipText(s), "}"]).flat();
            },
            cancel() {
                $modal.cancel();
            },
            setProperties(serverSide) {
                this.serverSide = serverSide
                this.data = serverSide.results
            }
        },
        components: {
            RichTable,
            TooltipComponent,
            SwitchButton,
            JsonViewer,
        }
    };
</script>
<style>
</style>

