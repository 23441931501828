<template>
    <div class="modal-container modal-xx-large modal-document-preview">
        <div class="modal-header">
            <span>{{title}}</span>
            <button class="button-accept" @click="downloadDocument" :disabled="isDownloading" style="width:25%;">
                Download
            </button>
        </div>

        <div class="modal-body">
            <div class="document-metadata" :style="$grid('1-1-1')">
                <span><b>File Name: </b> {{document.docInfo.fileName}}</span>
                <span><b>Source: </b> {{document.docInfo.source}}</span>
                <span><b>Date Uploaded: </b>{{timestampFormatted}}</span>
            </div>

            <DocumentPreview :document="document" hideCaption />
        </div>

        <div class="modal-footer">
            <button class="button-unwind" @click="cancel()">Close</button>
        </div>
    </div>
</template>


<script>
    import settings from 'settings';
    import api from '@core/services/api';
    import $modal from '@core/services/modal';
    import { defineAsyncComponent } from 'vue'

    export default {
        name: "modalDocumentPreview",
        props: ['modal'],
        data() {
            return {
                title: 'Document Preview',
                document: null, // an UploadedDocument object
                isDownloading: false,
            };
        },
        computed: {
            downloadLink() {
                return `${settings.apiUrl}/Documents/DownloadDocument/${this.document.id}`;
            },
            timestampFormatted() {
                return new Date(Date.parse(this.document.docInfo.timestamp)).toLocaleString('en-US', { dateStyle: 'long', timeStyle: 'medium' });
            }
        },
        created() {
            this.title = this.modal.passedData.title ?? 'Document Preview';
            this.document = this.modal.passedData.document;
        },
        methods: {
            cancel() {
                $modal.cancel();
            },
            async downloadDocument() {
                this.isDownloading = true;
                await api.documents.downloadDocument(this.document.id, this.document.docInfo.fileName);
                this.isDownloading = false;
            }
        },
        components: {
            DocumentPreview: defineAsyncComponent(() => import('@core/components/DocumentPreview.vue'))
        }
    };
</script>
<style>
    .modal-document-preview .modal-body .document-metadata b {
        display: block;
    }

        .modal-document-preview .modal-body .preview-container {
            display: flex;
            justify-content: center;
        }
</style>
