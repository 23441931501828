<template>
    <div>
        <div
            class="buyers-order-row"
            :style="$grid(hideProfits ? '25px-1-30px-125px' : '25px-1-30px-125px-125px-125px', '0px 5px')"
            v-show="!hideSubTotal"
        >
            <button class="button-span ignore-all-locks header-collapse-button" @click="toggle">
                <i :class="['far', isOpen ? 'fa-minus-square' : 'fa-plus-square']" />
            </button>
            <div>
                <span class="subtotal-container">{{ bucketType }}</span>
                <span v-if="addNewItemPrompt" class="new-tax-line">
                    <button class="button button-edit" @click="onAddNewItemClick()" v-show="isOpen">
                        <i class="fas fa-plus"></i> {{ addNewItemPrompt }}
                    </button>
                </span>
            </div>
            <div class="bo-bucket-sign">
                <div v-if="BookValuesAreInvalid" class="error-text bo-bucket-error">
                    <i class="fas fa-exclamation-circle"></i>
                </div>
            </div>
        </div>
        <div v-if="isOpen">
            <template v-for="(item, index) in vehicle.bookValues" :key="item.name">
                <div class="buyers-order-row" :style="$grid('25px-1-30px-125px-125px-125px', '0px 5px')">
                    <div />
                    <button
                        type="button"
                        @click="onItemClick(item)"
                        :class="{ 'bucket-item-name-container': true, 'button-link': true }"
                    >
                        <div class="item-text">
                            <div class="bucket-item-name">
                                {{ BookValue[item.source] }}
                            </div>
                        </div>
                        <!-- <div  class="bucket-item-subname">

                        </div> -->
                    </button>

                    <div></div>
                    <div class="amt">
                        <!-- <InputRichDropdown
                                label="Valuation Company"
                                :saturn.sync="item.source"
                                :list="bookValuesDropDown()"
                                :value="valuePredicate"
                                :display="displayPredicate"
                                search
                            /> -->
                    </div>
                    <div class="amt">
                        <InputCurrency
                            :label="item.source == BookValue.Manufacturer ? 'MSRP' : 'Retail'"
                            v-model:saturn="item.retail"
                            :precision="2"
                            iconSide="left"
                            @change="val => (item.retail = val)"
                            :invalid="validation.vehicle.bookValues[index]?.retail.$invalid"
                            :negative="false"
                            :disabled="true"
                        >
                            <template #validation>
                                <span v-if="InvalidAll(item, 'retail')"
                                    >At least one book value with retail and wholesale is required</span
                                >
                                <span v-else-if="!validation.vehicle.bookValues[index]?.retail"
                                    >Retail price is required</span
                                >
                                <span v-else-if="validation.vehicle.bookValues[index]?.retail.valid == false"
                                    >Retail price must be higher than $0</span
                                >
                            </template>
                        </InputCurrency>
                    </div>
                    <div class="amt">
                        <InputCurrency
                            :label="item.source == BookValue.Manufacturer ? 'Invoice' : 'Wholesale'"
                            v-model:saturn="item.wholesale"
                            :precision="2"
                            iconSide="left"
                            :invalid="validation.vehicle.bookValues[index]?.wholesale.$invalid"
                            @change="val => (item.wholesale = val)"
                            :negative="false"
                            :disabled="true"
                        >
                            <template #validation>
                                <span v-if="InvalidAll(item, 'wholesale')"
                                    >At least one book value with retail and wholesale is required</span
                                >
                                <span v-else-if="!validation.vehicle.bookValues[index]?.wholesale"
                                    >Wholesale price is required</span
                                >
                                <span v-else-if="validation.vehicle.bookValues[index]?.wholesale.valid == false"
                                    >Wholesale price must be higher than $0</span
                                >
                            </template>
                        </InputCurrency>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { BookValue } from "@core/classes/SharedEnums";
    import { GetBookValueDropdownValues } from "@/helpers/fimenuvehicle-helper";
    import InputCurrency from "@core/components/InputCurrency";

    export default {
        name: "BuyersOrderBookValues",
        props: [
            "bo",
            "bucketType",
            "taxable",
            "showItems",
            "hideSubTotal",
            "addNewItemPrompt",
            "hideProfits",
            "costLockCondition",
            "slotContainerProperties",
            "vehicle",
            "isVehicleNew",
            "validation",
        ],
        emits: ["onItemClick", "onAddNewItemClick"],
        data() {
            return {
                isOpen: false,
                actionInfo: null,
            };
        },
        computed: {
            BookValue() {
                return BookValue;
            },
            BookValuesAreInvalid() {
                return this.validation.vehicle.bookValues.$invalid;
            },
            BookValuesWithoutManufacturer() {
                return this.vehicle.bookValues?.filter(x => x.source != BookValue.Manufacturer) ?? [];
            },
            AtleastOneBookValueWithRetailAndWholeSaleOnNewDeal() {
                return !this.isVehicleNew && !this.BookValuesWithoutManufacturer.some(x => !!x.retail && !!x.wholesale);
            },
        },
        created() {
            this.isOpen = this.validation.vehicle.bookValues.$invalid;

            // If vehicle is new. We must always create Manufacturer to include MSRP; as it is a required field.
            if (this.isVehicleNew) this.vehicle.getOrCreateManufacturer();
        },

        methods: {
            displayPredicate(val) {
                return val.display;
            },
            valuePredicate(val) {
                return val.value;
            },
            bookValuesDropDown() {
                return GetBookValueDropdownValues();
            },
            onItemClick(item) {
                this.$emit("onItemClick", { row: this.bucketType, item: item });
            },
            onAddNewItemClick(item) {
                this.$emit("onAddNewItemClick", { row: this.bucketType });
            },
            toggle() {
                this.isOpen = !this.isOpen;
            },
            sourceMustMatchManufacturer(values) {
                return values.some(value => value.source === BookValue.Manufacturer);
            },
            InvalidAll(item, which) {
                return (
                    item.source != BookValue.Manufacturer &&
                    !item[which] &&
                    (!item.wholesale || !item.retail) &&
                    this.AtleastOneBookValueWithRetailAndWholeSaleOnNewDeal
                );
            },
        },
        components: {
            InputCurrency,
        },
    };
</script>

<style>
    .preload-amt {
        padding-right: 10px;
    }

    .preload-amt .rollup-container {
        display: flex;
        justify-content: center;
    }

    .preload-amt .rollup-container .pill-container {
        width: 50px;
        justify-content: center !important;
    }

    .fa-stack.fa-wrapper {
        position: relative;
    }

    span.buyers-order-upfront-capped-icon {
        font-size: 19px;
        display: inline-block;
    }

    .buyers-order-upfront-capped-icon:hover {
        cursor: pointer;
    }

    i.fa-upfront {
        font-size: 8px;
        top: 4px;
        position: absolute;
    }

    .fa-capped {
        background-color: black;
        top: 16px;
        left: -6px;
        position: absolute;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .fa-capped i {
        font-size: 8px;
        color: white;
    }
    .bucket-item-name {
        width: 100%;
    }

    .bucket-item-name-container.clickable {
        color: var(--main-color);
    }

    .bucket-item-name-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100% !important;
    }

    .bucket-item-name-container .item-text {
        display: flex;
        align-items: center;
        padding-right: 5px;
        width: 100%;
    }

    .bucket-item-subname {
        position: relative;
        font-size: 13px;
        color: var(--black-60percent);
        overflow: hidden;
        width: 100%;
    }
    .bucket-item-subname .content {
        position: absolute;
        text-wrap: nowrap;
    }

    .bo-bucket-sign {
        position: relative;
    }

    .bo-bucket-error {
        position: absolute;
        right: 45px;
    }
</style>
