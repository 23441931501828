<template>
    <Panel title="Purchase Figures" class="fullHeight">
        <div class="buyers-order-grid">
            <div class="buyers-order-row" :style="$grid('250px-150px')">
                <div>Deal Type</div>
                <InputRichDropdown v-model:saturn="data.dealType"
                                   :list="dealTypes"
                                   :invalid="util.isNull(validation) ? false : validation.dealType.$invalid">
                    <template #validation>
                        <div v-if="validation.dealType.$invalid">Deal Type is required</div>
                    </template>
                </InputRichDropdown>
            </div>
            <div class="buyers-order-row" :style="$grid('250px-150px')">
                <div>Selling Price</div>
                <InputCurrency v-model:saturn="data.sellingPrice"
                               :valueMap="data.sellingPrice"
                               placeholder="Selling Price"
                               :precision="2"
                               iconSide="left"
                               :negative="false"
                               :invalid="v$.data.sellingPrice.$invalid">
                    <template #validation>
                        <div v-if="v$.data.sellingPrice.required.$invalid">Selling Price is required</div>
                    </template>
                </InputCurrency>
            </div>
            <div>
                <div class="buyers-order-row thick-border-bottom" :style="$grid('250px-150px', '0')">
                    <div>OTD w/o Products</div>
                    <div class="amt">
                        <InputCurrency v-model:saturn="data.otdBase"
                                       placeholder="OTD w/o Products"
                                       :precision="2"
                                       :invalid="util.isNull(validation) ? false : validation.otdBase.$invalid"
                                       iconSide="left"
                                       :negative="true">
                        </InputCurrency>
                    </div>
                </div>
                <PanelPreloads :fimenu="data" :validation="validation" />

                <div class="buyers-order-row thick-border-top" :style="$grid('250px-150px', '0')">
                    <div>Preloads Taxes</div>
                    <div class="amt">
                        <InputCurrency :saturn="preloadTaxes"
                                       @update:saturn="($e) => $emit('update:preloadTaxes', $e)"
                                        placeholder="Cost"
                                       :disabled="true"
                                       :precision="2"
                                       iconSide="left">
                        </InputCurrency>
                    </div>
                </div>
                <div class="buyers-order-row" :style="$grid('250px-150px', '0')" v-if="docStamps">
                    <div>Doc Stamps</div>
                    <div class="amt">
                        <InputCurrency :saturn="docStamps"
                                        @update:saturn="($e) => $emit('update:docStamps', $e)"
                                        placeholder="Doc Stamps"
                                        :disabled="true"
                                        :precision="2"
                                       iconSide="left">
                        </InputCurrency>
                    </div>
                </div>
            </div>
            <div class="buyers-order-row total-values" :style="$grid('250px-150px' , '-10px, 10px')">

                <div>OTD with Preloads</div>
                <div class="amt">
                    <InputCurrency v-model:saturn="data.otd"
                                   placeholder="Cost"
                                   :disabled="true"
                                   :precision="2"
                                   iconSide="left"
                                   :negative="true">
                    </InputCurrency>
                </div>
            </div>
        </div>
    </Panel>

</template>


<script>
    import { minValue, required } from '@vuelidate/validators'
    import ENUMS from "@core/classes/Enums"
    import InputCurrency from '@core/components/InputCurrency.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import Panel from '@core/components/Panel.vue'
    import PanelPreloads from '@/components/PanelPreloads.vue'
    import {useVuelidate} from '@vuelidate/core'
    import util from '@core/services/util';

    export default {
        name: "PanelPurchaseFigures",
        emits:['update:docStamps', 'update:preloadTaxes', ],
        props: ['data', 'validation', 'preloadTaxes', 'docStamps'],
        setup(){
            return { v$: useVuelidate({$scope: false}) }
        },
        data() {
            return {}
        },
        validations() {
            return {
                data: {
                    sellingPrice: {
                        required, minValue: minValue(0)
                    },
                },

            }
        },
        computed: {
            ENUMS() {
                return ENUMS;
            },
            util() {
                return util;
            },
            dealTypes() {
                return ENUMS.DEAL_TYPES.toList().map(dt => dt.display)
            },
        },
        methods: {

        },
        components: {
            Panel,
            InputCurrency,
            PanelPreloads,
            InputRichDropdown,
        }
    };
</script>
<style>
    .PanelPurchaseFigures-container .panel-left {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }

    .r1-wrapper {
        display: flex;
        flex-direction: row;
        height: 100% !important;
        overflow: hidden;
    }

    .PanelPurchaseFigures-container .r1-wrapper > * {
        height: 100% !important;
        overflow: hidden;
    }

    .PanelPurchaseFigures-container .panel-left-flex-1 {
        flex: 1;
    }

    .PanelPurchaseFigures-container .buyers-order-row.preloads-list {
        grid-template-columns: 1fr 150px !important;
    }

    .PanelPurchaseFigures-container .buyers-order-row:nth-child(2n) {
        background: var(--richtable-alternating-background-color);
    }

    .fullHeight {
        height: 100%
    }

    .PanelPurchaseFigures-container .buyers-order-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between
    }

    .PanelPurchaseFigures-wrapper {
        height: 100%;
    }

    .Content-Container {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 10px;
    }
</style>