<template>
    <div class="modal-container modal-medium">
        <div class="modal-header">
            Send Credit Application to {{ modal.passedData.lender.lenderName }}
        </div>
        <div class="modal-body">
            <div :style="$grid('1')">
                <h3 style="margin: 0; color: var(--third-color)">Vehicle Valuation</h3>
                <SoftDivider />
                <!-- <BookValueCRUD v-if="modal.passedData.fimenu.inventoryType != 'New'" :BookValues="BookValues" ref="BookValueCrud"/> -->

                <InputRichDropdown
                    label="Select a Valuation Company"
                    v-model:saturn="SelectedBookValue"
                    :list="BookValuesDropdownValues"
                    :valueMap="valuePredicate"
                    :display="displayPredicate"
                    :invalid="!SelectedBookValue"
                    search
                >
                <template  #validation>
                    <span v-if="!SelectedBookValue">
                        Please select which vehicle value to submit
                    </span>
                </template>
                </InputRichDropdown>
            </div>
            <div :style="$grid('1')">
                <div v-if="modal.passedData.fimenu.dealType == 'Lease'" title="Lease Term">
                    <h3 style="margin: 0; color: var(--third-color)">Lease Term</h3>
                    <SoftDivider :marginBottom="10"/>
                    <div :style="$grid('1-1-1')">
                        <InputNumber label="Term"
                                     v-model:saturn="modal.passedData.fimenu.leaseTerms.term1.term"
                                     placeholder="Months"
                                     :showIcon="false"
                                     max="999"
                                     min="0"
                                     :invalid="!modal.passedData.fimenu.leaseTerms.term1.term"
                                     >
                        </InputNumber>
                        <InputNumber label="Residual %"
                                     v-model:saturn="modal.passedData.fimenu.leaseTerms.term1.residual"
                                     placeholder="Residual"
                                     :precision="2"
                                     :isPercent="true"
                                     :disabled="!modal.passedData.fimenu.leaseTerms.term1.term"
                                     max="100"
                                     min="0"
                                     :invalid="!modal.passedData.fimenu.leaseTerms.term1.residual"
                                     >
                        </InputNumber>
                        <InputNumber label="Money Factor"
                                     v-model:saturn="modal.passedData.fimenu.leaseTerms.term1.moneyfactor"
                                     placeholder="Money Factor"
                                     :precision="5"
                                     :disabled="!modal.passedData.fimenu.leaseTerms.term1.term"
                                     :invalid="!modal.passedData.fimenu.leaseTerms.term1.moneyfactor"
                                     >
                        </InputNumber>
                    </div>
                </div>
                <div v-if="modal.passedData.fimenu.dealType == 'Finance'" title="Finance Term">
                    <h3 style="margin: 0; color: var(--third-color)">Finance Term</h3>
                    <SoftDivider :marginBottom="10" />

                    <div :style="$grid('1-1-1')">
                        <InputNumber label="Term"
                                     v-model:saturn="modal.passedData.fimenu.loanTerms.term1.term"
                                     placeholder="Months"
                                     :showIcon="false"
                                     max="999"
                                     min="0"
                                     :invalid="!modal.passedData.fimenu.loanTerms.term1.term">
                        </InputNumber>
                        <InputNumber label="Buy Rate"
                                     v-model:saturn="modal.passedData.fimenu.loanTerms.term1.buyrate"
                                     placeholder="Buy Rate"
                                     :precision="2"
                                     :disabled="!modal.passedData.fimenu.loanTerms.term1.term"
                                     :isPercent="true"
                                     :invalid="!modal.passedData.fimenu.loanTerms.term1.buyrate">
                        </InputNumber>
                        <InputNumber label="Sell Rate"
                                     v-model:saturn="modal.passedData.fimenu.loanTerms.term1.sellrate"
                                     placeholder="Sell Rate"
                                     :precision="2"
                                     :disabled="!modal.passedData.fimenu.loanTerms.term1.term"
                                     :isPercent="true"
                                     :invalid="!modal.passedData.fimenu.loanTerms.term1.sellrate"
                                     >
                        </InputNumber>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="button-unwind" @click="cancel()">Cancel</button>
            <button :disabled="saveDisabled" class="button-save" @click="close()">Send</button>
        </div>

    </div>
</template>

<script lang="ts">
    import $modal from '@core/services/modal'
    import { BookValue } from '@core/classes/SharedEnums'
    import { defineComponent } from 'vue'
    import FIMenu from '@core/classes/FIMenu'
    import { GetBookValueDropdownValues } from '@/helpers/fimenuvehicle-helper'
    import InputNumber from '@core/components/InputNumber.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import SoftDivider from '@/components/SoftDivider.vue'
    import util from '@core/services/util'
    import {VehicleBookValue} from '@core/classes/VehicleBookValue'

    interface BookValueDropDown {
        value: number;
        display: string;
    }

    export default defineComponent({
        name: "modalRouteOneSend",
        props: ['modal'],
        data() {
            return {
                BookValueCrudRef: null,
                SelectedBookValue: null
            }
        },
        computed: {
            util(): any {
                return util
            },
            saveDisabled(): boolean {
                const hasInvalidEntries = this.BookValueCrudRef && this.BookValueCrudRef.AnyEntryIsInvalid;
                const hasNoSelectedBookValue = !this.SelectedBookValue;

                if (hasInvalidEntries || hasNoSelectedBookValue) {
                    return true;
                }

                const isFinanceDeal = this.modal.passedData.fimenu.dealType === 'Finance';
                const loanTerms = this.modal.passedData.fimenu.loanTerms.term1;
                const leaseTerms = this.modal.passedData.fimenu.leaseTerms.term1;

                if (isFinanceDeal) {
                    return this.isAnyLoanTermMissing(loanTerms);
                } else {
                    return this.isAnyLeaseTermMissing(leaseTerms);
                }
            },
            BookValuesDropdownValues(): BookValueDropDown[] {
                const deal = this.modal.passedData.fimenu as FIMenu;

                return GetBookValueDropdownValues().filter(dropdownValue => deal.vehicle.bookValues.some((vehBookVal: VehicleBookValue) => dropdownValue.value == vehBookVal.source && !!vehBookVal.retail && !!vehBookVal.wholesale ))
            },
        },
        mounted() {
            if(this.$refs.BookValueCrud != null) {
                this.BookValueCrudRef = this.$refs.BookValueCrud
            }
        },

        methods: {
            close() {
                const bookValue = this.SelectedBookValue == BookValue.Default ? null : this.SelectedBookValue;

                $modal.close(bookValue);
            },
            cancel() {
                $modal.cancel();
            },
            displayPredicate(val:BookValueDropDown): any {
                return val.display
            },
            valuePredicate(val: BookValueDropDown): any {
                return val.value
            },
            isAnyLoanTermMissing(loanTerms: any): boolean {
                return loanTerms.term == null ||
                    loanTerms.buyrate == null ||
                    loanTerms.sellrate == null;
            },
            isAnyLeaseTermMissing(leaseTerms: any): boolean {
                return leaseTerms.term == null ||
                    leaseTerms.residual == null ||
                    leaseTerms.moneyfactor == null;
            }

        },
        components: {
            InputNumber,
            SoftDivider,
            InputRichDropdown

        }
    })
</script>
<style>
</style>
