<template>
    <div
        :class="{ 'meeting-user': true, 'waiting': userInLobby || isUserInPeopleInLobby, 'presenting': userPresenting && !userInLobby }">
        <div class="meeting-user-info">
            <i v-if="userPresenting" class="fas fa-user-check"></i>
            <i v-else
                :class="{ 'fas fa-circle': userConnected || userInLobby || isUserInPeopleInLobby, 'far fa-circle': !userConnected, 'waiting': userInLobby || isUserInPeopleInLobby }"></i>
            {{ fimenu[role].fullName.toLowerCase() }}
        </div>

        <div class="meeting-user-actions">
            <template v-if="!userInLobby">
                <TooltipComponent v-if="userConnected && !meetingHelper.inStore" :tooltip="['Give user control']">
                    <template #showFromOutside>
                        <button
                            :disabled="userPresenting"
                            @click="meetingHelper.grantRequestToBePresenter(role)">
                            <i :class="{ 'fas fa-key': true, 'requesting-to-present': userRequestingToPresent, 'disabled': userPresenting }"></i>
                        </button>
                    </template>
                </TooltipComponent>

                <TooltipComponent v-if="userConnected" :tooltip="['Disconnect']">
                    <template #showFromOutside>
                        <button @click="handleDisconnectParticipant">
                            <i class="fas fa-window-close"></i>
                        </button>
                    </template>
                </TooltipComponent>

                <TooltipComponent v-if="!userConnected && !isUserInPeopleInLobby" :tooltip="['Send meeting invitation']">
                    <template #showFromOutside>
                        <button @click="handleMeetingInvitation">
                            <i class="far fa-envelope"></i>
                        </button>
                    </template>
                </TooltipComponent>
            </template>

            <TooltipComponent v-if="userInLobby || (isUserInPeopleInLobby && !userConnected)" :tooltip="['Allow user into meeting']">
                <template #showFromOutside>
                    <button @click="allowUserIntoMeeting">
                        <i class="fas fa-user-plus waiting"></i>
                    </button>
                </template>
            </TooltipComponent>
        </div>
    </div>
</template>
<script>
import TooltipComponent from '@core/components/TooltipComponent.vue'

export default {
    name: 'MeetingUser',
    props: ['fimenu', 'role', 'peopleInLobby'],
    emits: ['allowUserIntoMeeting', 'handleMeetingInvitation', 'handleDisconnectParticipant'],
    methods: {
        allowUserIntoMeeting() {
            this.$emit("allowUserIntoMeeting", this.role)
        },
        handleMeetingInvitation() {
            this.$emit("handleMeetingInvitation", this.role)
        },
        handleDisconnectParticipant() {
            this.$emit("handleDisconnectParticipant", this.role)
        },
    },
    computed: {
        // Connected
        userConnected() {
            if (this.meetingHelper.currentMeetingUsers) {
                return this.meetingHelper.currentMeetingUsers.some(user => user.role === this.role);
            }
            return false;
        },

        // Lobby
        userInLobby() {
            if (this.userConnected) {
                const user = this.meetingHelper.currentMeetingUsers.find(user => user.role === this.role)
                return user?.isUserWaiting;
            }
            return false
        },
        isUserInPeopleInLobby(){
            // isUserInPeopleInLobby - used for when the fimanager is not yet in the meeting and needs to know if user with this role is already in the meeting
            if (!this.peopleInLobby || this.peopleInLobby.length <= 0) return false;
            return this.peopleInLobby.some(p => p.role === this.role)
        },

        // Presenting
        userPresenting() {
            const user = this.meetingHelper.currentMeetingUsers?.find(user => user.role === this.role)
            if (user) {
                return user.isPresenting;
            }
            return false;
        },
        userRequestingToPresent() {
            const user = this.meetingHelper.currentMeetingUsers.find(user => user.role === this.role)
            if (user && this.meetingHelper.userRequestingToPresent) {
                return user.userId === this.meetingHelper.userRequestingToPresent
            }
            return false;
        },


    },
    components: {
        TooltipComponent,
    },

}
</script>
<style>

</style>