<template>
    <div class="section-container payment">
        <button @click="showPrevSection" class="back-button">Back</button>
        <div class="section-title">{{ localization.paymentDate[fimenu.language] }}</div>
        <div class="section-date-interval">{{ localization.paymentBetween[fimenu.language] + ' ' + printDate(allDates[0]) + ' ' + localization.paymentTo[fimenu.language] + ' ' + printDate(allDates[allDates.length - 1]) }}</div>
        <div :class="{'section-options': true, 'one': daySelected && daySelected < 9, 'two': daySelected < 17 && daySelected > 8, 'three': daySelected < 25 && daySelected > 16, 'four': daySelected > 24 }" ref="buttonContainer">
            <div :class="{'change-payment-date': true, 'active': daySelected}" @click="unSelectDay">
                <div class="action">
                    <span class="fa-stack fa-2x">
                        <i class="fas fa-calendar fa-stack-2x"></i>
                        <i class="fas fa-retweet fa-stack-1x fa-inverse"></i>
                    </span>
                    <span class="text">{{ localization.paymentSelectAnotherDay[fimenu.language] }}</span>
                </div>
            </div>
            <div :class="{'container': true}">
                <div :class="{'options-spaces': true, 'center': true }" v-for="(day) in 31" :key="day">
                    <div :class="{'section-button': true, 'selected': day == daySelected, 'disabled': !isDayAvailable(day)}" @click="selectDay(day)">
                        <div class="button-value">{{ day }}</div>
                    </div>
                </div>
            </div>

        </div>
        <div :class="{'select-date': true, 'active': daySelected}">
            <div class="divider"></div>
            <div :class="{'title': true}">
                <div :class="{'active': util.isNull(dateSelected)}">
                    {{ localization.paymentSelectFirst[fimenu.language] }}
                </div>
                <div :class="{'active': !util.isNull(dateSelected)}">
                    {{ localization.paymentNext[fimenu.language] }}
                </div>
            </div>

            <div class="date-section">
                <div v-for="date in availableDates()" :class="{'date': true, 'selected': !util.isNull(dateSelected) && dateSelected.getMonth() == date.getMonth()}" @click="selectDate(date)" :key="date.getMonth()">
                    {{ printDate(date) }}
                </div>

            </div>

        </div>
        <div :class="{'continue-btn-overlay': true, 'active': !util.isNull(dateSelected)}">
            <button :class="{'continue-button': true, 'active': !util.isNull(dateSelected)}" @click="saveAndContinue">
                Continue
            </button>
        </div>
    </div>
</template>

<script>
    import util from '@core/services/util'

    export default {
        name: "PaymentSheetPaymentStart",
        props: ['stopAudioHandler', 'openSectionHandler', 'showPrevSection', 'showNextSection', 'frozen', 'fimenu', 'saveFunction', 'localization'],
        data() {
            return {
                daySelected: null,
                dateSelected: null,
                allDates: [],
                availableDays: [],
                tempSelectedDay: null,
                startDateValue: null
            }
        },
        computed: {
            util() {
                return util
            }
        },
        created() {
            this.load()
            this.$watch(() => this.fimenu.loanTerms.maxDaysToFirstPayment, function (newValue) {
                if (newValue > 45) {
                    this.load()
                }

            });

        },

        methods: {
            load() {
                const gracePeriod = 30
                const daysToCount = this.fimenu.loanTerms.maxDaysToFirstPayment - gracePeriod
                //add hours to date to prevent issues with day light savings
                this.startDateValue = this.addHours(12, new Date(this.frozen.dealDate))
                this.allDates = []
                for (let index = 0; index <= daysToCount; index++) {
                    this.allDates.push(new Date(this.startDateValue.valueOf() + ((gracePeriod + index) * 24 * 60 * 60 * 1000)))
                }
                this.availableDays = [...new Set(this.allDates.map(date => date.getDate()))]

                if (this.frozen.loanTerms.selectedDaysToFirstPayment) {
                    this.dateSelected = this.allDates[this.frozen.loanTerms.selectedDaysToFirstPayment - gracePeriod]
                    this.daySelected = this.dateSelected.getDate()

                }
            },
            addHours(numOfHours, date = new Date()) {
                date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);

                return date;
            },
            unSelectDay() {
                if (this.daySelected > 16) {
                    this.$refs.buttonContainer.style.alignItems = 'flex-end'
                } else {
                    this.$refs.buttonContainer.style.alignItems = 'flex-start'
                }
                this.tempSelectedDay = this.daySelected
                this.daySelected = null
                this.dateSelected = null
                setTimeout(() => {
                    this.tempSelectedDay = null
                }, 700)
            },
            printDate(date) {
                return date ? date.toLocaleDateString(this.fimenu.language == 'English' ? 'en-US' : 'es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) : ''
            },
            selectDay(day) {
                if (this.isDayAvailable(day)) {
                    this.daySelected = day
                    this.dateSelected = null
                }
            },
            selectDate(datePlace) {
                this.dateSelected = datePlace
            },
            isDayAvailable(day) {
                return this.availableDays.includes(day)
            },
            availableDates() {
                if (this.daySelected)
                    return this.allDates.filter(d => d.getDate() == this.daySelected)
                if (this.tempSelectedDay)
                    return this.allDates.filter(d => d.getDate() == this.tempSelectedDay)

            },
            saveAndContinue() {
                this.saveFunction((this.dateSelected.getTime() - this.startDateValue.getTime()) / (1000 * 3600 * 24))
                this.openSectionHandler()
            }
        },
        components: {

        }
    };
</script>
<style>
   /* .section-container.payment {
        justify-content: flex-start;
        padding-top: 230px;
    }

        .section-container.payment .select-date {
            position: absolute;
            bottom: 10px;
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            opacity: 0;
            z-index: -1;
            transition: opacity .4s .2s ease-in-out, bottom .4s .2s ease-in-out
        }

            .section-container.payment .select-date.active {
                bottom: 125px;
                opacity: 1;
                z-index: 9999;
                transition: opacity .5s .1s ease-in-out, bottom .5s ease-in-out
            }

            .section-container.payment .select-date .divider {
                width: 950px;
                height: 1px;
                background-color: var(--paymentsheetpaymentstart-date-background-color);
                margin-bottom: 36px;
            }

            .section-container.payment .select-date .title {
                color: var(--main-color);
                font-size: 45px;
                height: 40px;
                width: 1200px;
                display: flex;
                justify-content: center;
            }

                .section-container.payment .select-date .title div.active {
                    opacity: 1;
                    transition: opacity .5s ease-in-out
                }

                .section-container.payment .select-date .title div {
                    opacity: 0;
                    transition: opacity .5s ease-in-out;
                    position: absolute;
                }

            .section-container.payment .select-date .date-section {
                width: 100%;
                display: flex;
                justify-content: center;
                column-gap: 30px;
                margin-top: 51px;
            }

                .section-container.payment .select-date .date-section .date {
                    color: var(--main-color);
                    width: 320px;
                    border: 1px solid var(--main-color);
                    padding: 20px 0;
                    display: flex;
                    justify-content: center;
                    font-size: 21px;
                    background: var(--paymentsheet-date-background-color);
                    cursor: pointer;
                }

                    .section-container.payment .select-date .date-section .date.selected {
                        color: var(--paymentsheet-date-selected-color);
                        background: var(--main-color);
                    }

        .section-container.payment .section-date-interval {
            position: absolute;
            top: 120px;
            color: var(--paymentsheetpaymentstart-date-interval-color);
            font-size: 20px;
            font-style: italic;
            text-transform: uppercase;
        }

        .section-container.payment .section-options {
            width: 1150px;
            display: flex;
            justify-content: flex-start;
            margin-top: -60px;
            height: 582px;
            overflow: hidden;
            position: relative;
            padding: 12px 0;
            transition: height .3s .3s ease-in-out;
        }

            .section-container.payment .section-options.one,
            .section-container.payment .section-options.two {
                height: 433px;
                align-items: flex-start !important;
            }

            .section-container.payment .section-options.three,
            .section-container.payment .section-options.four {
                height: 433px;
                align-items: flex-end !important;
            }

            .section-container.payment .section-options.one,
            .section-container.payment .section-options.two,
            .section-container.payment .section-options.three,
            .section-container.payment .section-options.four {
                transition: height .3s ease-in-out;
                pointer-events: none;
            }


            .section-container.payment .section-options .change-payment-date {
                position: absolute;
                z-index: -1;
                background: rgba(40,128,144, .5);
                top: 0;
                left: 0;
                right: 0;
                height: 432px;
                display: flex;
                justify-content: center;
                align-items: center;
                pointer-events: none;
                opacity: 0;
                transition: opacity .3s ease-in-out, z-index 0s .3s linear;
            }

                .section-container.payment .section-options .change-payment-date.active {
                    pointer-events: all;
                    opacity: 1;
                    z-index: 9999;
                    transition: opacity .3s .3s ease-in-out;
                }

                .section-container.payment .section-options .change-payment-date .action {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    color: var(--section-container-button-selected-color);
                }

                    .section-container.payment .section-options .change-payment-date .action .fa-stack {
                        font-size: 40px;
                    }

                        .section-container.payment .section-options .change-payment-date .action .fa-stack .fa-stack-1x {
                            margin-top: 15px;
                            color: rgba(40,128,144, .6);
                        }

                    .section-container.payment .section-options .change-payment-date .action .text {
                        font-size: 42px;
                        margin-top: 10px;
                    }

            .section-container.payment .section-options.one .container,
            .section-container.payment .section-options.two .container,
            .section-container.payment .section-options.three .container,
            .section-container.payment .section-options.four .container {
                opacity: .3;
                transition: opacity .3s .2s ease-in-out;
            }

            .section-container.payment .section-options .container {
                flex: 1;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                row-gap: 24px;
                height: 558px;
                opacity: 1;
                transition: opacity .3s .2s ease-in-out;
            }

            .section-container.payment .section-options .options-spaces {
                width: 12.5%;
                display: flex;
                margin-top: 0;
            }

                .section-container.payment .section-options .options-spaces.center {
                    justify-content: center
                }

                .section-container.payment .section-options .options-spaces .section-button {
                    color: var(--main-color);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 120px;
                    border: 1px solid var(--main-color);
                    height: 120px;
                    justify-content: center;
                    background: var(--section-container-button-background-color);
                    cursor: pointer;
                }

                    .section-container.payment .section-options .options-spaces .section-button.disabled {
                        color: var(--section-container-button-color-disabled) !important;
                        border-color: var(--section-container-button-color-disabled) !important;
                        cursor: default;
                    }

                    .section-container.payment .section-options .options-spaces .section-button.selected {
                        background: var(--main-color);
                        color: var(--section-container-button-selected-color);
                    }

                    .section-container.payment .section-options .options-spaces .section-button .button-value {
                        font-size: 60px;
                        line-height: 0.9;
                    }*/
</style>
