<template>
    <div class="fimenugraphpage" id="scaleContainer">
        <div class="graph-image">
            <div class="graph-image-overlay graph-image-overlay-b2b-months" v-if="fimenu.vehicleWarranty.warranties.basic.valid(fimenu.vehicleMiles, fimenu.vehicleWarranty.warrantyStartDate)">{{ basicMonths() }}</div>
            <div class="graph-image-overlay graph-image-overlay-b2b-miles" v-if="fimenu.vehicleWarranty.warranties.basic.valid(fimenu.vehicleMiles, fimenu.vehicleWarranty.warrantyStartDate)">{{ basicMiles() }}</div>
            <div class="graph-image-overlay graph-image-overlay-drivetrain-months" v-if="fimenu.vehicleWarranty.warranties.drivetrain.valid(fimenu.vehicleMiles, fimenu.vehicleWarranty.warrantyStartDate)">{{ drivetrainMonths() }}</div>
            <div class="graph-image-overlay graph-image-overlay-drivetrain-miles" v-if="fimenu.vehicleWarranty.warranties.drivetrain.valid(fimenu.vehicleMiles, fimenu.vehicleWarranty.warrantyStartDate)">{{ drivetrainMiles() }}</div>
            <div class="graph-image-container">
                <img src="@static/img/Graph.English_Page_1.png" />
            </div>
        </div>
        <!--<div class="graph">
            <div class="graphwarrantysection">
                <div><span v-if="basicMonths()">{{fimenu.vehicleWarranty.warranties.basic.months}}</span><span v-else>___</span> MONTHS <span v-if="basicMiles()">{{fimenu.vehicleWarranty.warranties.basic.miles}}</span><span v-else>___</span> MILES LIMITED MANUFACTURER WARRANTY</div>
                <div><span v-if="drivetrainMonths()">{{fimenu.vehicleWarranty.warranties.drivetrain.months}}</span><span v-else>___</span> MONTHS <span v-if="drivetrainMiles()">{{fimenu.vehicleWarranty.warranties.drivetrain.miles}}</span><span v-else>___</span> MILES LIMITED POWERTRAIN WARRANTY</div>
            </div>
            <div class="graphcoveredsection">
                <div class="graphsectiontitle">COVERED</div>
                <div>
                    DEFECTS ONLY
                </div>
                <div>
                    MATERIALS = PARTS
                    WORKMANSHIP = LABOR
                    DEFECTS ARE ITEMS THAT ARE:
                    • MIS-DESIGNED
                    • MIS-BUILT
                    • MIS-INSTALLED
                </div>
            </div>
            <div class="graphnotcoveredsection">
                <div class="graphsectiontitle">NOT COVERED</div>
                <div>
                    WEAR, TEAR & USE
                </div>
                <div>
                    MAINTENANCE
                    (OIL, ROTATION, BELTS, HOSES, BRAKE PADS,
                    SPARK PLUGS, ETC…)
                    TIRES / WHEELS
                    (UNDER INFLATION, OVER INFLATION,
                    ROAD HAZARD, COSMETIC, ETC…)
                    KEYS/REMOTE
                    (DAMAGED, LOST OR STOLEN KEYS, ETC...)
                    APPEARANCE
                    (DINGS, DENTS, PAINT, INTERIOR, ETC…)
                    THEFT / ACCIDENT
                    (DAMAGE DUE TO THEFT OR ACCIDENT, ETC…)
                    ROAD SIDE ASSISTANCE
                    (RUN OUT OF FUEL, LOCK-OUT OF VEHICLE, FLAT TIRE, ETC...)
                    SERVICE LOANERS / RENTALS
                </div>
                <div>
                    MOST COMMON CAUSE OF WEAR, TEAR & USE:
                    • CLIMATE
                    • ROAD CONDITIONS
                    • PERSONAL DRIVING HABITS
                    • LACK OF OR IMPROPER MAINTENANCE
                    YOU CAN CONTROL __/ 4 THEY CAN CONTROL __/ 4
                </div>
            </div>
        </div>-->
    </div>
</template>


<script>



    export default {
        name: "FIMenuGraph",
        emits:['sectionInformationChanged'],
        props: ['fimenu', 'sectionName', 'language'],
        data() {
            return {
                isBusy: false,
            };
        },
        validations() {
            const validations = {
            }
            return validations;
        },
        computed: {

        },
        created() {

            this.loadData();
            this.$emit('sectionInformationChanged', { sectionName: this.sectionName, indicator: this.getSectionIndicator() });
        },
        methods: {
            getSectionIndicator() {
                return { icon: "fa-car-crash", class: "none" };
            },
            loadData() {
                this.isBusy = true;
                this.isBusy = false;

            },
            basicMonths() {
                return this.fimenu.vehicleWarranty.warranties.basic.monthsDisplay(this.fimenu.inventoryType, this.fimenu.vehicleWarranty.warrantyStartDate);
            },
            basicMiles() {
                return this.fimenu.vehicleWarranty.warranties.basic.milesDisplay(this.fimenu.inventoryType, this.fimenu.vehicleMiles);
            },
            drivetrainMonths() {
                return this.fimenu.vehicleWarranty.warranties.drivetrain.monthsDisplay(this.fimenu.inventoryType, this.fimenu.vehicleWarranty.warrantyStartDate);
            },
            drivetrainMiles() {
                return this.fimenu.vehicleWarranty.warranties.drivetrain.milesDisplay(this.fimenu.inventoryType, this.fimenu.vehicleMiles);
            },
        },
        components: {
            //InputTextbox: InputTextbox,
            //InputCurrency: InputCurrency,
            //InputNumber: InputNumber,
            //InputDate: InputDate,
            //InputCheckbox: InputCheckbox,
            //Panel: Panel

        }

    };
</script>
<style>

</style>