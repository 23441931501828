<template>
    <div v-if="showReverseEnvironmentButton">
        <button
            class="button button-icon-text"
            :class="`${$global.isAdminView ? 'button-pulse' : ''}`"
            @click="openPageInDifferentEnvironment"
            id="reverse-environment-button"
        >
            Open In {{ reverseEnvironmentName }}
        </button>
    </div>
</template>
<script setup lang="ts">
    import { computed, inject } from 'vue';
    import { Router } from 'vue-router';
    import { SaturnGlobalPlugin } from '@core/classes/StaticClasses';
    import settings from 'settings';
import util from '@core/services/util';

    const $global = inject<SaturnGlobalPlugin>('$global');
    const $router = inject<Router>('$router');

    const showReverseEnvironmentButton = computed(() => {
        return settings.environmentName !== 'PRODUCTION' && $global.isAdminView;
    });

    const targetURL = computed(() => $router?.resolve({ name: $router.currentRoute.value?.name, query: $router.currentRoute.value?.query })?.href);


    const reverseEnvironmentName = computed(() => {
        switch (settings.environmentName) {
            case 'STAGING': {
                return 'Development';
            }
            case 'DEVELOPMENT': {
                return 'Staging';
            }
            default: {
                return null;
            }
        }
    });

    function openPageInDifferentEnvironment() {
        if (!settings.reverseURL || settings.reverseURL == '') {
            util.toastr('info', `${reverseEnvironmentName.value} Navigation`, 'Reverse URL Is Missing');
            return;
        }
        window.open(`${settings.reverseURL + targetURL.value}`, '_blank');
    }
</script>
<style scoped>
    #reverse-environment-button {
        background-color: #414288;
        border-color: #414288;
        font-weight: bolder;
    }

    #reverse-environment-button.button-pulse {
        animation: reverse-environment-button-pulse 1.1s ease infinite;
        animation-iteration-count: 3;
    }

    @keyframes reverse-environment-button-pulse {
        50% {
            background-color: #be93e4;
            border-color: #be93e4;
            box-shadow: 3px 4px 1px rgba(0, 0, 0, 0.25)
        }
    }
</style>
