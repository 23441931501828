<template>
    <div class="modal-container modal-medium">
        <div class="modal-header">Vehicle Valuation</div>
        <div class="modal-body">
            <div :style="$grid('1')">
                <div v-if="Create">
                    <span> Book values </span>
                    <button
                        type="button"
                        class="btn btn-primary"
                        style="width: 55px; align-items: center"
                        @click="handleAddBookValue"
                    >
                        <i class="fas fa-plus" />
                    </button>
                </div>

                <BookValueCRUD
                    :isVehicleNew="InventoryType == 'New'"
                    :bookValues="BookValues"
                    :disableDropDown="DisableDropDown"
                    :dropDownValues="DropDownValues"
                    :validation="v$.BookValues"
                    ref="BookValueCrud"
                />
            </div>
        </div>

        <div class="modal-footer modal-footer-split">
            <div>
                <button class="button-unwind" @click="deleteBookValue" v-if="!!DeleteFunc">Delete</button>
            </div>
            <div>
                <button class="button-unwind" @click="cancel">Cancel</button>
                <button class="button-save" @click="close" :disabled="saveDisabled">Save</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import $modal from "@core/services/modal";
    import BookValueCRUD from "@/components/BookValueCRUD.vue";
    import { defineComponent } from "vue";
    import { GetBookValueDropdownValues } from "@/helpers/fimenuvehicle-helper";
    import { required } from "@vuelidate/validators";
    import { useVuelidate } from "@vuelidate/core";
    import util from "@core/services/util";
    import { VehicleBookValue } from "@core/classes/VehicleBookValue";

    export default defineComponent({
        name: "ModalAddBookValue",
        props: ["modal"],
        setup() {
            return { v$: useVuelidate() };
        },
        data() {
            return {
                BookValues: [],
                InventoryType: this.modal.passedData.inventoryType,
                BookValueCrudRef: null,
                Create: this.modal.passedData.create ?? false,
                DisableDropDown: false,
                DeleteFunc: null,
                DropDownValues: null,
            };
        },
        computed: {
            util(): any {
                return util;
            },
            saveDisabled(): boolean {
                return this.v$.$invalid;
            },
            canAddNewBookValue(): boolean {
                const BookValueDropDown = GetBookValueDropdownValues();

                return this.BookValues.length < BookValueDropDown.length;
            },
        },
        mounted() {
            if (this.$refs.BookValueCrud != null) {
                this.BookValueCrudRef = this.$refs.BookValueCrud;
            }
        },
        created() {
            //
            this.initBookValue();
        },
        methods: {
            close() {
                $modal.close(this.BookValues);
            },
            cancel() {
                $modal.cancel();
            },
            initBookValue() {
                // Temp solutions until we find  a better place to add bookvalues.
                this.BookValues =
                    this.modal.passedData.bookValues?.map((x: Partial<VehicleBookValue>) => new VehicleBookValue(x)) ??
                    [];
                this.DisableDropDown = this.modal.passedData.disableDropDown ?? false;
                this.DeleteFunc = this.modal.passedData.deleteFees ?? null;
                this.DropDownValues = this.modal.passedData.dropDownValues ?? null;

                const TheresNoBookValue = this.BookValues == null || this.BookValues?.length <= 0;
                if (TheresNoBookValue) this.handleAddBookValue();
            },
            handleAddBookValue() {
                const BookValueDropDown = GetBookValueDropdownValues();

                if (this.BookValues.length < BookValueDropDown.length) this.BookValues.push(new VehicleBookValue());
            },
            deleteBookValue() {
                this.DeleteFunc();
                $modal.cancel();
            },
        },
        validations() {
            return {
                BookValues: this.BookValues.map(() => ({
                    source: {
                        required,
                    },
                    retail: {
                        required,
                        valid: (val: number) => {
                            if (val <= 0) return false;
                            return true;
                        },
                    },
                    wholesale: {
                        required,
                        valid: (val: number) => {
                            if (val <= 0) return false;
                            return true;
                        },
                    },
                })),
            };
        },
        components: {
            BookValueCRUD,
        },
    });
</script>
<style scoped>
    .modal-body {
        min-height: 100px;
    }
</style>
