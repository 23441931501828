<template>
    <div class="page fimenupage" ref="fimenuPage">
        <DealStatusChangeAnimation v-if="fimenu && fimenu.buyersOrderEnabled" :fimenu="fimenu" />

        <div v-if="coBrowsingEnabled" style="height: 0px;">
            <CoBrowsingToolbar :storeCode="fimenu.storeCode"
                               :dealNumber="fimenu.dealNumber"
                               :dealId="(fimenuTemp) ? fimenuTemp.id : fimenu.id"
                               :viewingRole="viewingRole"
                               :elementToWatch="domElementToWatch"
                               :elementFacade="$refs.editorFacade"
                               :facadeScale="editorFacadeScale"
                               :environmentName="settings.environmentName"
                               :isBusyIniting="isBusyLoadingDeal"
                               @followCustomerScreen="followCustomerScreenToggle">
            </CoBrowsingToolbar>
            <div v-show="followEditor" class="editor-facade-container" ref="editorFacade" :style="editorFacadeStyle">
                <IsBusySectionComponent v-if="!editorHTMLReceived" />
                <div class="facade-mask" />

                <div v-show="followCustomerScreen" class="facade-customer" ref="customerFacadeDump" />
                <div v-show="!followCustomerScreen" class="facade-fimenu app-container" ref="editorFacadeDump" />

                <footer v-if="!followCustomerScreen">You are currently viewing the Editor's screen</footer>
            </div>
        </div>

        <div v-show="!followEditor" class="real-deal-fimenu" ref="realDeal">
            <PaperWorkCreation v-if="isCreatingPaperwork || paperworkActivationBusy || isCheckingPaperwork"
                :paperworkActionMessage="paperworkActionMessage"
            />
            <PaperWorkVoiding v-if="paperworkVoiding" />
            <CDKPushComponent v-if="openCDKPush" :data="fimenu" :postFunction="CDKPushPostFunction" :CDKPushing="CDKPushing" />

            <IsBusySectionComponent v-if="isBusyLoadingDeal || !fimenu" text="Please wait while we load the deal..." delayToShowText="3000" />

            <div v-else :class="{ 'locked-wrapper': true, 'interaction-locked': followEditor && fimenu.isSpectator }">
                <div class="deal-info">
                    <div class="deal-info-row">
                        <div class="deal-type">
                            <Pill
                                status="success"
                                :label="dealTypeText"
                                :includeDot="false"
                                :lightVersion="true"
                            />
                        </div>

                        <div class="deal-number">#{{ fimenu.dealNumber }}</div>
                    </div>
                    <div class="deal-info-row">
                        <div class="store-name">{{ fimenu.store.storeName }}</div>
                        <div>-</div>
                        <div class="deal-date">{{ fimenu.dealDate }}</div>
                    </div>
                </div>
                <div class="sidemenu-contents-header no-margin-grid">
                    <div class="panel-title fimenu-title">
                        <div v-if="fraudCheckedVisible" :class="{'deal-fraud-check': true, 'error': fraudCheckData.fraud.riskLevel === ENUMS.FRAUD_RISK_LEVEL.HIGH_RISK && !fraudCheckData.employeeId, 'warning': fraudCheckData.fraud.riskLevel === ENUMS.FRAUD_RISK_LEVEL.HIGH_RISK && fraudCheckData.employeeId}">
                            <div class="icon-container">
                                <i class="fas fa-user-secret"></i>
                            </div>
                            <div class="fraud-check-accepted">
                                <span>{{ fraudCheckData.employeeName }}</span>
                                <span class="fraud-check-date">{{ displayDateForFraudCheck(fraudCheckData.approvalDate) }}</span>
                            </div>
                        </div>
                        <div class="deal-customer">
                            <div class="customer-name">{{ fimenu.customer.fullName?.toLowerCase() }}</div>
                            <div class="cocustomer-name">{{ fimenu.coCustomer?.fullName?.toLowerCase() || 'No Co-customer'}}</div>
                        </div>
                        <fieldset class="deal-status" v-if="fimenu.buyersOrderEnabled" :disabled="fimenu.isSpectator">
                            <DealStatusIcon :fimenu="fimenu" />
                            <ButtonMoveDeal :fimenu="fimenu" />
                        </fieldset>
                    </div>
                    <ReverseEnvironmentButton v-if="$global.isAdminView" />
                    <fieldset class="buttons-container">
                        <InputNumber v-if="fimenu"
                                     label="DMS #"
                                     v-model:saturn="fimenu.externalDMSDealNumber"
                                     placeholder="DMS #"
                                     thousandSeparator=""
                                     :disabled="disableExternalDMSNumber || fimenu.isSpectator"
                                     :invalid="v$.fimenu.externalDMSDealNumber.$invalid"
                                     style="min-width: 190px;"
                                     :height="40"
                                     @blur="() => onBlurDMSNumber(v$.fimenu.externalDMSDealNumber.$invalid)">
                            <template #validation>
                                <div v-if="v$.fimenu.externalDMSDealNumber && v$.fimenu.externalDMSDealNumber.$invalid">
                                    DMS # is required
                                </div>
                            </template>
                        </InputNumber>

                        <FloatingMenu cssClass="info-button ignore-all-locks" @change="(bool) => setIsSettingsMenuOpen(bool)">
                            <template #action-button>
                                <i class="fas fa-cog" />
                            </template>

                            <template #body>
                                <fieldset :disabled="fimenu.isSpectator" class="floating-menu-body-container">
                                    <InputDate v-if="fimenu"
                                               v-model:saturn="fimenu.dealDate"
                                               :height="40"
                                               :invalid="v$.fimenu.dealDate.$invalid"
                                               placeholder="Contract Date"
                                               :disabled="(fimenu.isDealLocked() && !$global.isAdminView)"
                                               :isDateDisabled="util.isFutureDate"
                                               label="Deal Date">
                                        <template #validation>
                                            <div v-if="v$.fimenu.dealDate.required.$invalid">Contract date is required</div>
                                        </template>
                                    </InputDate>

                                    <InputRichDropdown :height="40"
                                                       label="Language"
                                                       v-model:saturn="fimenu.language"
                                                       :invalid="v$.fimenu.language.$invalid"
                                                       :list="languages"
                                                       @change="languageOnChange">
                                    </InputRichDropdown>


                                    <div v-if="$global.isAdminView" class="admin-input-fields">
                                        <InputRichDropdown label="Customer Application"
                                                           :list="CUSTOMER_RETRIEVAL_STATUS_LIST"
                                                           :valueMap="e => e.value"
                                                           v-model:saturn="fimenu.customerApplication"
                                                           :display="e => e.display" />

                                        <InputCheckbox v-model="fimenu.buyersOrderEnabled"
                                                       label="Buyers Order Enabled"
                                                       class="bo-enabled-checkbox"
                                                       @update:modelValue="reinitialize()" />

                                        <InputCheckbox v-model="fimenu.plateTransactionsEnabled"
                                                       label="DDI Enabled"
                                                       class="bo-enabled-checkbox"
                                                       @update:modelValue="reinitialize()" />
                                        <InputCheckbox v-model="fimenu.creditApplicationsEnabled"
                                                       label="Credit Applications Enabled"
                                                       class="bo-enabled-checkbox"
                                                       @update:modelValue="reinitialize()" />
                                    </div>

                                    <fieldset class="deal-employees" :disabled="(isUserSalesPerson || !canUserEditDealRoles) && !$global.isAdminView">
                                        <DealEmployeeDropdown :employees="availableEmployees" :FIMenu="fimenu" :employeeType="ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.SALES_PERSON1" />
                                        <DealEmployeeDropdown :employees="availableEmployees" :FIMenu="fimenu" :employeeType="ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.SALES_PERSON2" />
                                        <DealEmployeeDropdown :employees="availableEmployees" :FIMenu="fimenu" :employeeType="ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.SALES_MANAGER" />
                                        <DealEmployeeDropdown :employees="availableEmployees" :FIMenu="fimenu" :employeeType="ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.FI_MANAGER" />
                                    </fieldset>
                                </fieldset>
                            </template>

                        </FloatingMenu>

                        <button class="button button-icon-text button-save" @click="dealSave()" :disabled="isSavingDisabled">
                            <i class="fa fa-save" aria-hidden="true"></i>Save
                        </button>
                    </fieldset>
                    <div class="development-buttons" v-if="development">
                        <!--<a v-if="development" href="#" @click="autoPopulate()">AUTO</a>
                        <a v-if="development" href="#" @click="clearSignatures()">Clear Sigs</a>-->
                        <a v-if="development" href="#" @click="openPreModal()">Viewer</a>
                        <!--<span class="push-to-customer">Push to<br /> Customer: </span>-->
                    </div>
                </div>
                <div v-if="fimenu && !isBusyLoadingDeal" class="fimenu-basic-customer-meeting-wrapper">
                    <FIMenuBasic v-if="fimenu && !isBusyLoadingDeal"
                                 :fimenu="fimenu"
                                 sectionName="Basic"
                                 class="fade-in"
                                 name="Basic"
                                 ref="fimenuBasic"
                                 :viewingRole="viewingRole">
                    </FIMenuBasic>
                    <!--v-if="!!$refs.fimenuBasic && !!$refs.fimenuBasic.$refs.panelMenu"-->
                    <FIMenuCustomerMeetings :fimenu="fimenu"
                                            :paymentSheetDisabled="paymentSheetDisabled()"
                                            :customerScreenActions="{'openCustomerPopup': openCustomerPopup, 'openPrintPaymentSheetModal': openPrintPaymentSheetModal, 'openPaperworkErrorsModal': openPaperworkErrorsModal}">
                    </FIMenuCustomerMeetings>
                </div>
                <div v-else class="cdk-pull">
                    <template v-if="cdkConnection">
                        <img src="@static/img/cdkPull.svg" />
                        <span>Getting Deal and Information from CDK</span>
                    </template>

                    <img v-else-if="cdkConnection === false" class="loading-anim" src="@static/img/SaturnLogoAnim.svg" />
                </div>

                <PanelCustomerViewAlert v-if="customerViewAlertDisplay"
                                        :customerViewAlert="customerViewAlert"
                                        :openCustomerPopup="openCustomerPopup">
                </PanelCustomerViewAlert>
            </div>
        </div>
    </div>
</template>


<script>
    import { BookValue, DealKind, DealStatuses } from '@core/classes/SharedEnums'
    import $modal from '@core/services/modal'
    import api from '@core/services/api'
    import auth from '@core/services/auth';
    import ButtonMoveDeal from '@/components/ButtonMoveDeal.vue'
    import CDKPushComponent from '@/components/CDKPushComponent.vue'
    import CoBrowsingToolbar from '@/components/fimenu/CoBrowsingToolbar.vue'
    import DealEmployeeDropdown from '@core/components/DealEmployeeDropdown.vue'
    import DealStatusChangeAnimation from '@/components/DealStatusChangeAnimation.vue'
    import DealStatusIcon from '@/components/DealStatusIcon.vue'
    import DropdownButton from '@/components/DropdownButton.vue'
    import ENUMS from '@core/classes/Enums'
    import FIMenu from '@core/classes/FIMenu'
    import FIMenuBasic from '@/components/fimenu/FIMenuBasic.vue'
    import FIMenuCreditApplication from '@core/classes/FIMenuCreditApplication'
    import FIMenuCustomerMeetings from '@/components/fimenu/FIMenuCustomerMeetings/FIMenuCustomerMeetings.vue'
    import FIMenuCustomerViewed from '@core/classes/FIMenuCustomerViewed'
    import FIMenuForCustomerMeeting from '@core/classes/FIMenuForCustomerMeeting';
    import FIMenuGraph from '@/components/fimenu/FIMenuGraph.vue'
    import FIMenuPaperwork from '@core/components/fimenu/FIMenuPaperwork.vue'
    import FIMenuPaperworkClass from '@core/classes/FIMenuPaperwork'
    import FIMenuPresentation from '@/components/fimenu/FIMenuPresentation.vue'
    import FIMenuSignatures from '@core/components/fimenu/FIMenuSignatures.vue'
    import FIMenuVideoPlayer from '@/components/fimenu/FIMenuVideoPlayer.vue'
    import FloatingMenu from '@/components/FloatingMenu.vue'
    import InputCheckbox from '@core/components/InputCheckbox.vue'
    import InputDate from '@core/components/InputDate.vue'
    import InputNumber from '@core/components/InputNumber.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import IsBusySectionComponent from '@core/components/IsBusySectionComponent.vue'
    import LogRocket from 'logrocket';
    import modalInfo from '@core/modals/modalInfo.vue'
    import modalPaperworkErrors from '@/modals/modalPaperworkErrors.vue'
    import modalPre from '@/modals/modalPre.vue'
    import modalPrintPaymentSheet from '@/modals/modalPrintPaymentSheet.vue'
    import moment from 'moment'
    import PanelCustomerConnectionHistory from '@/components/PanelCustomerConnectionHistory.vue'
    import PanelCustomerLive from '@/components/PanelCustomerLive.vue'
    import PanelCustomerVideoChat from '@/components/PanelCustomerVideoChat.vue'
    import PanelCustomerViewAlert from '@/components/PanelCustomerViewAlert.vue'
    import PanelMenu from '@/components/PanelMenu/PanelMenu.vue'
    import PaperWorkCreation from '@/components/PaperWorkCreation.vue'
    import PaperWorkVoiding from '@/components/PaperWorkVoiding.vue'
    import PDFViewer from '@core/components/PDFViewer.vue'
    import Pill from '@core/components/Pill.vue';
    import Popup from '@/components/Popup.vue'
    import ReverseEnvironmentButton from '@core/components/_general/ReverseEnvironmentButton.vue';
    import {SetStatusToReverifyIfNeeded} from '@core/classes/FIMenuHelper';
    import settings from 'settings'
    import SoundPlayer from '@core/services/sound-player'
    import TooltipComponent from '@core/components/TooltipComponent.vue'
    import util from '@core/services/util';
    import { useVuelidate } from '@vuelidate/core'
    import VersionsLender from '@core/classes/LenderVersionClass'
import StoreClass from '@core/classes/Store/Store';
import { buyersOrderHelper } from '@core/helpers/buyers-order-helper';

    export default {
        name: "FIMenu",
        emits: ['update:fimenu'],
        props: ["fimenu"],
        setup() {
            return { v$: useVuelidate({ $scope: false }) }
        },
        data() {
            return {
                autoSaveTimeout: null,
                autoSaveInterval: null,
                fimenuTemp: null,
                coBrowsingEnabled: false,
                domElementToWatch: null,
                viewingRole: null,
                followEditor: false,
                followCustomerScreen: false,
                editorHTMLReceived: false,
                dealRoomViewerCount: 0,
                dealRoomFollowerCount: 0,
                editorScreenDimensions: {
                    screenX: 0,
                    screenY: 0,
                    customerX: 0,
                    customerY: 0,
                },
                screenResizedToggle: 0,
                dealUpdatedExternally: false,
                languages: ["English", "Spanish"],
                paperworkActivationBusy: false,
                openSectionAfterConnect: null,
                cdkConnection: null,
                googleReviewURL: null,
                openCDKPush: null,
                CDKPushing: null,
                CDKPushPostFunction: null,
                watchers: {
                    customer: null,
                    coCustomer: null,
                    customerViewed: null,
                    editorSections: null,
                    isSavingDisabled: null,
                    isConnectedCoBrowsing: null,
                },
                popupVisible: true,
                customerViewAlert: {
                    noExist: true,
                    noFullScreen: true,
                    noVisible: true
                },
                paperworkVoiding: false,
                showDropdownButtons: false,
                showDropdownButtonsForPaperwork: false,
                allowToPrintPaymentSheet: false,
                development: null,
                fimenuClone: new FIMenu(this.fimenu),
                isBusy: false,
                isFiMenuBasicBusy: false,
                isPanelFiMenuBusy: false,
                isBusyLoadingDeal: true,
                isCheckingPaperwork: false,
                popup: {
                    component: null,
                    props: null,
                    loadInstantly: true
                },
                paperworkBusy: false,
                isCreatingPaperwork: false,
                sessionId: null,
                CUSTOMER_RETRIEVAL_STATUS_LIST: [],
                isSettingsMenuOpen: false,
                availableEmployees: [], // All employees. Admins/Sales/FIManager/Directors, etc..
            };
        },
        validations() {
            const fimenuValidation = this.fimenu.validation()

            const validations = {
                fimenu: {
                    dealDate: fimenuValidation.dealDate,
                    language: fimenuValidation.language,
                    externalDMSDealNumber: fimenuValidation.externalDMSDealNumber,
                }
            }

            return validations;
        },
        computed: {
            registrationAddressState() {
                return this.fimenu.getRegistrationAddress()?.state;
            },
            fraudCheckData() {
                return this.fimenu.getCurrentFraudCheck()
            },
            fraudCheckedVisible() {
                return this.fraudCheckData && this.$global.isAdminView
            },
            DealKind() {
                return DealKind;
            },
            payload() {
                return auth.getTokenPayload();
            },
            isUserSalesPerson() {
                return util.convertPositionToDealRole(this.payload?.Position) === ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.SALES_PERSON1;
            },
            canUserEditDealRoles() {
                if (this.fimenu?.isDealLocked() || this.fimenu?.isDealFinalized()) return false;

                switch (util.convertPositionToDealRole(this.payload?.Position)) {
                    case ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.SALES_PERSON1:
                    case ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.SALES_MANAGER:
                        return this.fimenu.isDealInSales();
                    case ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.FI_MANAGER:
                        return this.fimenu.isDealInFinance();
                }

                // When deal is in accounting, no one can edit until the deal is brought back to F&I.
                return !this.fimenu.isDealInAccounting();
            },
            customerViewAlertDisplay() {
                return this.fimenu
                    && this.fimenu.isAllPaperworkSigned()
                    && this.fimenu.paperworkStatus != 3
                    && !this.fimenu.isDealLocked()
                    && !this.allowToPrintPaymentSheet
                    && !this.isBusyLoadingDeal
                    && (this.customerViewAlert.noExist || this.customerViewAlert.noFullScreen);
            },
            util() {
                return util;
            },
            anyPaperwork() {
                return this.fimenu.paperwork && this.fimenu.paperwork.packets && this.fimenu.paperwork.packets.length > 0;
            },
            docsWhereNonCustomerSignatureRequired() {
                // returns documents where customer/co-customer signature is not required
                // in the future there might be other personTypes where we want to check if signature is required
                return this.fimenu.paperwork.currentPacket().documents.filter(doc => {
                    if(doc?.overlays?.length < 1) return false;
                    
                    return doc.overlays.every(o => {
                        if(o.signature) {
                            if(o.signature.personType === ENUMS.PERSON_TYPES.CUSTOMER || o.signature.personType === ENUMS.PERSON_TYPES.COCUSTOMER) {
                                return false;
                            }
                        }
                        if(o.initials) {
                            if(o.initials.personType === ENUMS.PERSON_TYPES.CUSTOMER || o.initials.personType === ENUMS.PERSON_TYPES.COCUSTOMER) {
                                return false;
                            }
                        }
                        return true;
                    }) 
                });
            },
            paperworkActionMessage() {
                if(this.isCreatingPaperwork) return 'creating';
                if(this.paperworkActivationBusy) return 'activating';
                if(this.isCheckingPaperwork) return 'finalizing';
                return '';
            },
            anyTermSelected() {
                return this.fimenu.coverageTerms.some(c => c.selected == true);
            },
            hasCustomCoverage() {
                return this.fimenu.coverageTerms.some((t) => t.term === 0);
            },
            hasCustomerSeenVideoPresentation() {
                return this.fimenu.customerViewed.some(c => c.viewedType == 2);
            },
            hasCustomerSeenPreferredCoverageLength() {
                return this.fimenu.customerViewed.some(c => c.viewedType == 7);
            },
            hasCustomerSeenPaymentStart() {
                return this.fimenu.customerViewed.some(c => c.viewedType == 8);
            },
            hasCustomerSeenCarousel() {
                return this.fimenu.customerViewed.some(c => c.viewedType == 9);
            },
            hasPaperwork() {
                return (this.fimenu.paperwork != null
                    && this.fimenu.paperwork.currentPacket()
                    && this.fimenu.paperwork.currentPacket().documents != null
                    && Array.isArray(this.fimenu.paperwork.currentPacket().documents)
                    && this.fimenu.paperwork.currentPacket().documents.length > 0);
            },
            isSpectator() {
                if (!this.viewingRole) return false;
                return this.viewingRole !== 'Editor';
            },
            settings() {
                return settings;
            },
            editorSectionsToWatch() {
                return {
                    actualCost: this.fimenu.actualCost,
                    buyersOrders: this.fimenu.getCurrentBuyersOrder(),
                    carFax: this.fimenu.carFax,
                    coCustomer: { ...this.fimenu.coCustomer, customerApplication: null },
                    coverageTerms: this.fimenu.coverageTerms,
                    customer: { ...this.fimenu.customer, customerApplication: null },
                    customerApplication: this.fimenu.customerApplication,
                    dealAddresses: this.fimenu.dealAddresses,
                    dealAddressesQuestions: this.fimenu.dealAddressesQuestions,
                    dealJacket: this.fimenu.dealJacket,
                    dealDate: this.fimenu.dealDate,
                    dealType: this.fimenu.dealType,
                    dealStatus: this.fimenu.dealStatus,
                    employees: this.fimenu.employees,
                    externalDMSDealNumber: this.fimenu.externalDMSDealNumber,
                    fees: this.fimenu.fees,
                    hasCoSigner: this.fimenu.hasCoSigner,
                    hasOtherTradeIns: this.fimenu.hasOtherTradeIns,
                    insurance: this.fimenu.insurance,
                    invoice: this.fimenu.invoice,
                    inventoryType: this.fimenu.inventoryType,
                    language: this.fimenu.language,
                    leaseTerms: this.fimenu.leaseTerms,
                    lender: this.fimenu.lender,
                    loanTerms: this.fimenu.loanTerms,
                    msrp: this.fimenu.vehicle.getMSRP()?.retail,
                    otd: this.fimenu.otd,
                    otdBase: this.fimenu.otdBase,
                    otdFinal: this.fimenu.otdFinal,
                    paperworkStatus: this.fimenu.paperworkStatus,
                    paperworkPacket: this.fimenu?.paperwork?.currentPacket(),
                    preloads: this.fimenu.preloads,
                    rebates: this.fimenu.rebates,
                    stockNumber: this.fimenu.stockNumber,
                    tradeIns: this.fimenu.tradeIns,
                    totalSelectedProducts: this.fimenu.totalSelectedProducts,
                    totalSelectedPackages: this.fimenu.totalSelectedPackages,
                    totalProductProfit: this.fimenu.totalProductProfit,
                    vehicle: this.fimenu.vehicle,
                    vehicleMiles: this.fimenu.vehicleMiles,
                    vehicleWarranty: this.fimenu.vehicleWarranty.warranties,
                    vin: this.fimenu.vin,
                };
            },
            editorFacadeStyle() {
                if (!this.editorHTMLReceived) return `width: 90%;`;

                const dim = this.editorScreenDimensions;
                const width = (this.followCustomerScreen && dim.customerX > 0) ? dim.customerX : dim.screenX;
                const height = (this.followCustomerScreen && dim.customerY > 0) ? dim.customerY : dim.screenY;

                return `scale: ${this.followCustomerScreen ? this.customerFacadeScale : this.editorFacadeScale};
                          width: ${width}px;
                          height: ${height}px;`;
            },
            editorFacadeScale() {
                // Forces value to recalculate
                if (!this.screenResizedToggle && (this.editorScreenDimensions.screenX === 0 || this.editorScreenDimensions.screenY === 0))
                    return 0.9;

                const ourScreenWidth = this.$refs.fimenuPage?.clientWidth ?? window.innerWidth;
                const ourScreenHeight = this.$refs.fimenuPage?.clientHeight ?? window.innerHeight;

                // Editor Facade has a 5px border, need to account for this offset
                const ratioX = (ourScreenWidth - 10) / this.editorScreenDimensions.screenX;
                const ratioY = (ourScreenHeight - 10) / this.editorScreenDimensions.screenY;

                return Math.min(ratioX, ratioY);
            },
            customerFacadeScale() {
                if (!this.screenResizedToggle && (this.editorScreenDimensions.customerX === 0 || this.editorScreenDimensions.customerY === 0))
                    return 0.9;

                const ourScreenWidth = this.$refs.fimenuPage?.clientWidth ?? window.innerWidth;
                const ourScreenHeight = this.$refs.fimenuPage?.clientHeight ?? window.innerHeight;

                // Editor Facade has a 5px border, need to account for this offset
                const ratioX = (ourScreenWidth - 10) / this.editorScreenDimensions.customerX;
                const ratioY = (ourScreenHeight - 10) / this.editorScreenDimensions.customerY;

                return Math.min(ratioX, ratioY) - 0.1;
            },
            isSavingDisabled() {
                return (((this.fimenu && !this.fimenu.dealNumber) || this.isBusyLoadingDeal || this.fimenu.hasPaperworkPacket()) && !this.$global.isAdminView) || (this.coBrowsingEnabled && this.isSpectator);
            },
            ENUMS() {
                return ENUMS
            },
            storeCode() {
                return this.$route.query.storeCode ?? this.dealInformation.storeCode;
            },
            disableExternalDMSNumber() {
                return this.fimenu.buyersOrderEnabled ?
                    this.fimenu.dealStatus >= ENUMS.DEAL_STATUS.FI_FINISHED
                    :
                    this.hasPaperwork
            },
            dealTypeText(){
                return `${this.fimenu.dealType ? this.fimenu.dealType : ""} ${this.fimenu.dealKind == DealKind.Quote ? "Quote" : "Deal"}`
            }
        },
        beforeRouteLeave(to, from, next) {
            const holdUser = () => {
                alert("Deal loading is still in progress. Please allow task to finish.");
                next(false);
            }

            if (!this.fimenu) {
                holdUser();
            }
            else {
                if (settings.environmentName === "DEVELOPMENT" || (settings.environmentName !== "DEVELOPMENT" && confirm("Are you sure you want to leave this deal?"))) {
                    next();
                    return;
                } else {
                    next(false)
                }
            }
        },
        mounted() {
            window.addEventListener('beforeunload', this.handleLeaveApp);
        },
        async created() {
            this.CUSTOMER_RETRIEVAL_STATUS_LIST = ENUMS.CUSTOMER_RETRIEVAL_STATUS.toList();
            const response = await navigator.mediaDevices.enumerateDevices();
            this.cameraAvailable = response.find((device) => device.kind === 'videoinput') !== undefined ? true : false;

            SoundPlayer.reset();
            this.development = this.$route.query.debug == "true";

            if (this.fimenu) {
                //CHECK IF YOU HAVE PERMISSIONS TO ACCESS THIS STORE
                const storeToSelect = settings.userStores.find(s => s.storeCode === this.fimenu.storeCode);
                this.$global.dealNumber = this.fimenu.dealNumber;

                if (storeToSelect) {
                    this.$global.selectedStore = storeToSelect;
                    this.EventBus.emit('storeChanged', { storeCode: storeToSelect });

                    //RESET MEETING HUB
                    if (this.$meetingHub.connected && this.$meetingHub.code) {
                        this.$meetingHub.stopConnection("F&I manager created reset");
                    }

                    if (this.$dealRoomHub.connected && this.$dealRoomHub.code) {
                        this.$dealRoomHub.stopConnection();
                    }

                    //LOG ROCKET
                    this.setupLogRocket();

                    //RESET DROPDOWN BUTTONS
                    this.toggleButtonDropdown(false);
                    this.toggleButtonDropdownForPaperwork(false);
                }
                else {
                    util.toastr('error', 'Get Deal Error', 'Invalid Store - Permissions Issue');
                    this.$router.push('/customers');
                    return;
                }
            }
            else {
                util.toastr('error', 'Get Deal Error', 'Invalid Deal Number or Store');
                this.$router.push('/customers');
                return;
            }

            //GET DEAL
            this.isBusyLoadingDeal = true;
            //this.fimenu = null;
            //console.log('getDealResponse', getDealResponse);

            //LOADING + SETTING DEAL
            if (this.fimenu) {
                this.sessionId = util.getGuid();
                this.fimenuTemp = this.fimenu;

                //SET THE STORE
                this.fimenuTemp.store = this.$global.selectedStore;

                //SETUP EVENTS
                this.setupEventBus();

                this.coBrowsingEnabled = this.fimenuTemp.store.storeSettings.isDealCoBrowsingEnabled;
                if (this.coBrowsingEnabled) {
                    this.setupDealRoomHub(true);
                    this.setupCoBrowsing();
                }
                else {
                    this.initFiMenu();
                }
            }

            await this.getEmployees();


            // Watch bookValues to update MSRP.

            this.$watch(() => this.fimenu.vehicle?.bookValues, (newVal) => {
                const msrp = newVal.find(x => x.source == BookValue.Manufacturer)
                if (msrp) {
                    this.fimenu.msrp = msrp.retail;
                    this.fimenu.invoice = msrp.wholesale; // Sync invoice too.
                }
            }, { deep: true })

            this.$watch(() => this.fimenu.lender, (newValue, oldValue) => {
                if(this.fimenu.isCash()){
                    if(newValue.lenderCode == "CASH" || oldValue.lenderCode == "CASH") {
                        if(newValue.lenderCode != oldValue.lenderCode) {
                            buyersOrderHelper.resetBuyersOrderAsync(this.fimenu)
                        }
                    }
                }
            }, { deep: true })
        },
        async beforeUnmount() {
            window.removeEventListener('resize', this.windowResizeHandler);
            this.stopDealAutoSave();
            this.setupDealRoomHub(false);
            // If user is in a meeting. Disconnect them?
            if (this.meetingHelper.meetingCurrentlyOpen() && this.meetingHelper.inStore) {
                this.meetingHelper.endMeeting();
            }
            this.meetingHelper.fimanagerResetHelper();


            if (!this.isBusyLoadingDeal && !this.fimenu.isSpectator && !this.fimenu.isDealLocked()) {
                this.fimenu.save();
            }

            this.EventBus.off('paperworkStarted', this.onPaperworkStarted);
            this.EventBus.off('CDKPush', this.CDKPush);
            //ARROW FUNC WILL NOT BE CLEARED IN THE OFF
            this.EventBus.off('WaitingCDK', () => { this.openCDKPush = true; this.CDKPushing = null });
            this.EventBus.off('fimenuBasicBusy', this.setIsFiMenuBasicBusy);
            this.EventBus.off('panelFimenuBusy', this.setIsPanelFiMenuBusy);
            this.EventBus.off('CDKPushFinish', this.CDKPushFinish);
            this.EventBus.off('paperworkReady', this.onPaperworkReady);
            this.EventBus.off('openCustomerPaperwork', this.openCustomerPaperwork);
            this.EventBus.off('paperworkStartVoiding', this.onPaperworkStartVoiding);
            this.EventBus.off('paperworkEndedVoiding', this.onPaperworkEndedVoiding);
            this.EventBus.off('paperworkStartActivating', this.paperworkStartActivating);
            this.EventBus.off('paperworkEndedActivating', this.paperworkEndedActivating);
            this.EventBus.off('setFIMenu', this.setFIMenu);
            this.EventBus.off('followEditor', this.syncFollowEditorHandler);

            //Recording Events
            this.EventBus.off("restartScreenRecording", this.restartScreenRecording);
            this.EventBus.off("saveCameraRecordingStartTime", this.handleSaveCameraRecordingStartTime);
            this.EventBus.off("saveScreenRecordingStartTime", this.handleSaveScreenRecordingStartTime);
            this.EventBus.off("onLatestTimeStamp", this.handleLatestTimeStamp);
            this.EventBus.off("recordingError", this.handleRecordingError);

            this.MeetingHubBus.off('meetingRoomJoinSuccess', this.meetingRoomJoinSuccessHandler)
            this.MeetingHubBus.off('meetingRoomJoinError', this.meetingRoomJoinErrorHandler)
            this.MeetingHubBus.off('updatePaperworkToSigned', this.updatePaperworkToSignedHandler)
            this.MeetingHubBus.off('checkFinishedPaperwork', this.checkFinishedPaperworkHandler)
            this.MeetingHubBus.off('updateDraftLoanTerms', this.updateDraftLoanTermsHandler)
            this.MeetingHubBus.off('updatePreferredCoverage', this.updatePreferredCoverageHandler)
            this.MeetingHubBus.off('updateLoanDaysToPayment', this.updateLoanDaysToPaymentHandler)
            this.MeetingHubBus.off('updateSignatureAndInitials', this.updateSignatureAndInitialsHandler)
            this.MeetingHubBus.off('updateDocumentIsOpen', this.updateDocumentIsOpenHandler)
            this.MeetingHubBus.off('downloadDocument', this.downloadDocumentHandler)
            this.MeetingHubBus.off('moveToSecureRoom', this.moveToSecureRoomHandler)
            this.MeetingHubBus.off('triggerFIManagerAction', this.triggerFIManagerActionHandler)
            this.MeetingHubBus.off('triggerSaveFIMenu', this.dealSave)
            this.MeetingHubBus.off('checkIfCustomerShouldGoToPaymentSheet', this.customerPaymentSheetCheck)
            this.MeetingHubBus.off('registerCustomerViewed', this.registerCustomerViewedHandler)
            this.MeetingHubBus.off('updateMeetingHubParticipants', this.updateMeetingHubParticipantsHandler)
            this.MeetingHubBus.off('userRequestingToPresent', this.userRequestingToPresentHandler)
            this.MeetingHubBus.off('endMeeting', this.endMeeting);
            this.MeetingHubBus.off('meetingRoomError', this.meetingRoomErrorHandler);
            this.MeetingHubBus.off('reconnectingMeetingHub', this.reconnectingMeetingHubHandler);
            this.MeetingHubBus.off('reconnectedMeetingHub', this.reconnectedMeetingHubHandler)
        },
        unmounted() {
            this.$global.dealNumber = null;

            if (this.$dealRoomHub.connected || this.$dealRoomHub.reconnecting)
                this.$dealRoomHub.stopConnection();

            window.removeEventListener('beforeunload', this.handleLeaveApp)
        },
        methods: {
            async getEmployees() {
                const response = await api.settings.GetEmployeesDropdown("storeCode", this.fimenu.storeCode)
                    .catch(e => console.error("err", e));

                if (response.data) this.availableEmployees = response.data;
            },
            displayDateForFraudCheck(date) {
                const formatted = moment(date).format("MMMM D, YYYY - HH:mm");
                return formatted ?? date;
            },
            getFormattedDate(date) {
                const formatted = moment(date, "MM/DD/YYYY").format("MMMM D, YYYY");
                return formatted ?? date;
            },
            paymentSheetDisabled() {
                if (!this.$refs.fimenuBasic || !this.fimenu) return true;

                return !!(this.isFiMenuBasicBusy
                    || this.isPanelFiMenuBusy
                    || !this.fimenu.hasCoverageTerms()
                    || this.$refs.fimenuBasic.isDisabledFIMenu()
                    || this.fimenu.isDealLocked()
                    || !this.$refs.fimenuBasic.hasValidLeaseOrLoanTerms
                    || this.fimenu.isRatingRequired());
            },
            async reinitialize() {
                this.isBusyLoadingDeal = true;
                this.$nextTick(() => {
                    this.isBusyLoadingDeal = false;
                    this.isSettingsMenuOpen = false;
                });
            },
            async initFiMenu() {
                try {
                    const requests = [];

                    //flag the deal for rating if any response that is older than today
                    if (this.fimenuTemp.apiResponses?.length > 0) {
                        //get oldest rate date
                        const oldestRatingDate = this.fimenuTemp.apiResponses.reduce((oldestDate, currentResponse) => {
                            const currentDate = moment(currentResponse.responseDateTime);
                            return currentDate.isBefore(moment(oldestDate)) ? currentResponse.responseDateTime : oldestDate;
                        }, this.fimenuTemp.apiResponses[0].responseDateTime);

                        //compare only date, not time
                        if (moment().startOf('day').isAfter(moment(oldestRatingDate, 'MM/DD/YYYY').startOf('day'))) {
                            this.fimenuTemp.ratingStatus = ENUMS.RATING_STATUS.REQUIRED
                        }
                    }

                    //SET FIMENU
                    await this.setFIMenu(this.fimenuTemp)
                    this.fimenuTemp = null;

                    //LOOK AT STORE SETTINGS
                    this.googleReviewURL = this.fimenu.store.storeSettings.googleReviewsURL
                    this.coBrowsingEnabled = this.fimenu.store.storeSettings.isDealCoBrowsingEnabled;
                    this.cdkConnection = this.fimenu.store.cdkSettings.dealerId ? true : false;

                    //populate fiManagerName and fiManagerCode from jwt payload
                    if (!this.fimenu.fiManagerName || this.fimenu.fiManagerName == '') this.fimenu.fiManagerName = this.payload.EmployeeName;
                    if (!this.fimenu.fiManagerCode || this.fimenu.fiManagerCode == '') this.fimenu.fiManagerCode = this.payload.EmployeeCode;

                    //SHOW or HIDE Print Payment Sheet Button
                    const currentStore = settings.userStores.find(s => s.storeCode == this.$global.selectedStore.storeCode);
                    this.allowToPrintPaymentSheet = currentStore.storeSettings.allowToPrintPaymentSheet;
                    this.$global.maxMilesToBeConsideredNewCar = util.isNull(currentStore.storeSettings.maxMilesToBeConsideredNewCar) ? 6000 : currentStore.storeSettings.maxMilesToBeConsideredNewCar

                    //START WATCHING
                    this.watchers.customer = this.$watch(() => this.fimenu.customer, function (newValue) {
                        this.fimenu.customer.updateFullName();
                    }, { deep: true });

                    // This watch ensures the deal gets updated as Deal And Quote when it changes from quote to deal.
                    this.watchers.dealStatus = this.$watch(
                        () => this.fimenu.dealStatus,
                        (newDealStatus) => this.fimenu.handleDealStatusChange(newDealStatus)
                    );

                    this.watchers.coCustomer = this.$watch(() => this.fimenu.coCustomer, function (newValue) {
                        this.fimenu.coCustomer.updateFullName();
                    }, { deep: true });
                    this.watchers.customerViewed = this.$watch(() => this.fimenu.customerViewed, function (newValue) {
                        if (newValue[newValue.length - 1]?.videosSeen != null) {
                            this.dealSave();
                        }
                        //Check for videos completed to save deal
                        if (newValue[newValue.length - 1]?.viewedType == 10) {
                            this.dealSave();
                        }

                    }, { deep: true });
                    this.setupAutoSave();

                    //IF SOCKET VERSION, SET UP MEETING HUB
                    //console.log('SETTING UP MEETING HUB')
                    this.setupMeetingHub()

                    //update global
                    this.updatePENProducts()
                    // add lender list to global here
                    requests.push(Promise.resolve(this.updateLenders()));
                    requests.push(Promise.resolve(this.updateRouteOneCreditAppFields()));

                    //update marketscan vehicle id if the store has market scan enabled and it's a new deal
                    //if (this.$route.query.newDeal) {
                    //    let mscanEnabled = this.fimenu.store.partnerCodes.some(p => p.type === ENUMS.PARTNER_CODES.MSCAN_ACCOUNT);
                    //    /* eslint-disable no-console */
                    //    if (mscanEnabled) {
                    //        let account = this.fimenu.store.partnerCodes.filter(p => p.type === ENUMS.PARTNER_CODES.MSCAN_ACCOUNT);
                    //        if (account && account.length > 0) {
                    //            let mscanAccount = account[0].code;
                    //            if (mscanAccount != null) {
                    //                let mscanresponse = await api.marketScan.getVehiclesByVIN(mscanAccount, this.fimenu.vin, this.fimenu.inventoryType == 'New');
                    //                if (mscanresponse && mscanresponse.data && mscanresponse.data.length == 1) {
                    //                    this.fimenu.marketScan.vehicleId = mscanresponse.data[0].id;
                    //                } else {
                    //                    this.fimenu.marketScan.vehicleId = null;
                    //                }
                    //            }
                    //        }
                    //    }
                    //    /* eslint-enable */
                    //}

                    requests.push(Promise.resolve(this.fimenu.getUploadedDocuments()));

                    await Promise.all(requests);

                    util.toastr('success', 'Successfully loaded deal!', this.fimenu.dealNumber + ' Retrieved');
                }
                catch (error) {

                    console.error(error);
                    util.toastr('error', 'Error loading deal!', this.fimenu.dealNumber + ' Not Retrieved');
                }
                finally {
                    this.isBusyLoadingDeal = false;
                }
            },
            setIsSettingsMenuOpen(bool) {
                this.isSettingsMenuOpen = bool;
            },
            updatePENProducts() {
                return api.settings.PENGetSavedProducts().then((response) => {
                    if (response && response.data) {
                        const PENProvidersToSave = []
                        this.fimenu.apiResponses.forEach(r => {
                            if (r.provider.includes("PEN-")) {
                                PENProvidersToSave.push({ productType: r.productType, name: r.provider })
                            }
                        })
                        this.$global.PENProducts = response.data.filter(p => PENProvidersToSave.some(provider =>
                            provider.productType === p.saturnProductType &&
                            provider.name.substring(4) === p.providerName && p.dealerCodes.some(dc => dc.dealerId == this.fimenu.store.partnerCodes.find(pc => pc.type === ENUMS.PARTNER_CODES.PEN_DEALER_ID)?.code)
                        ))
                    }
                }).catch((err) => {
                    this.$global.PENProducts = []

                    console.error('in catch', err);
                })
            },
            async updateLenders() {
                const allFormulasToEval = [];
                for (let i = 0; i < settings.lenders.length; i++) {
                    settings.lenders[i].versions.forEach((v, vIndex) => {
                        allFormulasToEval.push({
                            formula: !v.expression ? `return false;` : v.expression,
                            varName: `${settings.lenders[i].id}|${vIndex}`,
                            //formulaEvaluationResult: ""
                        })
                    })
                }

                const model = {
                    fimenu: this.fimenu,
                    store: this.fimenu.store,
                    lender: this.fimenu.lender
                }

                if (allFormulasToEval.length > 0) {
                    const { data } = await api.utilities.evaluateExpressions(JSON.stringify(model), ENUMS.DataModel.Paperwork, allFormulasToEval);
                    const resultList = Object.entries(data).filter(r => r[1]?.result)
                    const lenderListCopy = settings.lenders.map(l => new VersionsLender(l))
                    resultList.forEach(r => {
                        const foundLender = lenderListCopy.find(l => l.id === r[0].split("|")[0]);
                        const versionIndex = r[1].result ? r[0].split("|")[1] : 0;
                        const filteredVersions = foundLender.versions.filter((v, index) => index == versionIndex)
                        foundLender.versions = filteredVersions;
                    });

                    this.$global.Lenders = lenderListCopy;
                }
            },
            updateRouteOneCreditAppFields() {
                return api.routeOne.getLatestCreditAppFieldsResponse(this.fimenu.store.storeCode).then((response) => {
                    if (response && response.data) {
                        this.$global.r1CreditAppField = response.data;
                    }
                }).catch((err) => {
                    this.$global.r1CreditAppFields = {}

                    console.error('in catch', err);
                })
            },
            languageOnChange() {
                if (this.$meetingHub.connected && this.$meetingHub.code) {

                    this.$meetingHub.changeFimenuLanguage(this.fimenu.language)
                }
            },
            getPaperworkDocType(typeString) {
                return ENUMS.paperworkDocumentTypes.find(pdt => pdt.typeString == typeString) || { contractType: "", provider: typeString };
            },
            CDKPush(e) {
                this.CDKPushPostFunction = e.postFunction
                this.CDKPushing = e.pushing
            },
            // You're probably wondering is this method async and has a next ticket, right?
            // The emit method triggers an event with a callback function that updates the state.
            // Since this callback runs asynchronously, we cannot directly wait for it to complete.
            // If you make changes immediately after emitting, they might be referencing outdated or unprocessed state.
            // Using Vue.nextTick() ensures that all DOM updates and reactivity changes triggered by the emit have been processed
            // before continuing with further code execution.
            async setFIMenu(newFIMenu) {
                this.$emit("update:fimenu", new FIMenu(newFIMenu))
                await this.$nextTick();
            },
            CDKPushFinish() {
                this.openCDKPush = false;
            },
            restoreStore() {
                this.EventBus.emit('restoreStore', { store: this.fimenu.store });
            },
            openPaperworkErrorsModal() {
                $modal.open(modalPaperworkErrors, { name: 'modalPaperworkErrors', passedData: { fimenu: this.fimenu, rootName: 'Deal #' + this.fimenu.dealNumber + ' - ' + this.fimenu.storeCode }, backdrop: false });
            },
            openPrintPaymentSheetModal() {
                $modal.open(modalPrintPaymentSheet, { name: 'modalPrintPaymentSheet', fimenu: this.fimenu, postFunction: this.postPrintPaymentSheet });
            },
            postPrintPaymentSheet() {
                this.dealSave();
            },
            onPaperworkStarted() {
                //console.log('paperworkStarted');
                this.isCreatingPaperwork = true;
                this.paperworkBusy = true;
            },
            onPaperworkReady() {
                //console.log('paperworkReady');
                this.isCreatingPaperwork = false;
                this.paperworkBusy = false;
                this.fimenu.updatePaperworkStatus()
                this.dealSave();
                //this.openPaperworkErrorsModal()
            },
            unselectCoverageTerm() {
                this.fimenu.coverageTerms.map(term => term.selected = false)
                this.fimenu.leaseTerms.acceptedTerm = null
                this.fimenu.loanTerms.acceptedTerm = null
            },
            onPaperworkStartVoiding() {
                this.paperworkVoiding = true
                if (this.$meetingHub.connected && this.$meetingHub.code) {
                    this.openCustomerPopup('paperwork-creating')
                }
            },
            onPaperworkEndedVoiding() {
                this.paperworkVoiding = false
                if (util.isNull(this.fimenu.paperwork.currentPacket())) {
                    this.unselectCoverageTerm()

                    if (this.fimenu.buyersOrderEnabled)
                        this.fimenu.moveDealToFIProcess();
                }
                this.fimenu.updatePaperworkStatus()
                SetStatusToReverifyIfNeeded(this.fimenu);
                this.dealSave();
            },
            async handleUpdateCreditApplication(creditApp) {
                // Need to check if im editor
                if (this.fimenu.isSpectator) return;

                const updatedApplication = new FIMenuCreditApplication(creditApp);
                // Find the index of the application to update.
                const applicationToUpdateIndex = this.fimenu.dealJacket.applications.findIndex(x => x.id == updatedApplication.id);

                // If we actually find the application.
                if (applicationToUpdateIndex >= 0) {
                    this.fimenu.dealJacket.applications[applicationToUpdateIndex] = updatedApplication;
                }

                // save fimenu
                await this.fimenu.save()
            },
            paperworkStartActivating() {
                this.paperworkActivationBusy = true
            },
            paperworkEndedActivating() {
                this.paperworkActivationBusy = false
                this.fimenu.updatePaperworkStatus()
                this.dealSave();
            },
            setIsFiMenuBasicBusy(isBusy) {
                this.isFiMenuBasicBusy = isBusy;
            },
            setIsPanelFiMenuBusy(isBusy) {
                this.isPanelFiMenuBusy = isBusy;
            },
            toggleButtonDropdown(value) {
                if (!util.isNull(value))
                    this.showDropdownButtons = value;
                else
                    this.showDropdownButtons = !this.showDropdownButtons;
            },
            toggleButtonDropdownForPaperwork(value) {
                if (!util.isNull(value))
                    this.showDropdownButtonsForPaperwork = value;
                else
                    this.showDropdownButtonsForPaperwork = !this.showDropdownButtonsForPaperwork;
            },
            openPreModal() {
                $modal.open(modalPre, { name: 'modalPre', passedData: { data: this.fimenu }, backdrop: true });
            },
            clearSignatures() {
                if (this.fimenu.paperwork && this.fimenu.paperwork.currentPacket() && this.fimenu.paperwork.currentPacket().documents) {
                    this.fimenu.paperwork.currentPacket().documents.forEach(doc => {
                        if (doc.overlays) {
                            doc.overlays.forEach(overlay => {
                                if (overlay.signature) {
                                    overlay.signature.isSigned = false;
                                }
                            });
                        }
                    });
                }
            },
            setupAutoSave() {
                /*eslint-disable no-console */
                console.log('DEAL AUTO PING - ENABLED')
                this.autoSaveInterval = setInterval(async () => {

                    //let response = await api.meeting.peopleWaitingInLobby(this.fimenu.dealNumber + '-' + this.$route.query.storeCode);
                    //if (response !== null || response !== undefined) {
                    //    this.peopleInLobby = response.data ?? [];
                    //}

                    if (!(this.isSavingDisabled || document.hidden || this.paperworkBusy)) {
                        //await this.dealSave();
                        console.log('DEAL AUTO PING - **PING**')
                        await this.$dealRoomHub.ping();


                    }
                }, 30000);
                /*eslint-enable */
            },
            stopDealAutoSave() {
                // eslint-disable-next-line no-console
                console.log('DEAL AUTO PING - DISABLED')
                clearInterval(this.autoSaveInterval);
            },
            async dealSave() {
                clearTimeout(this.autoSaveTimeout);

                this.fimenu.save();
            },
            setupLogRocket() {
                //if (settings.environmentName == 'PRODUCTION') {

                const payload = auth.getTokenPayload();

                LogRocket.track(`Deal_Entered`, {
                    dealNumber: this.fimenu.dealNumber,
                    storeCode: this.$global.selectedStore.storeCode,
                    environment: settings.environmentName,
                    user: payload.EmployeeLogin
                });
                //}
            },
            autoPopulate() {
                // IS this method used?

                const msrp = this.fimenu.vehicle.getOrCreateManufacturer()

                this.fimenu.dealNumber = '12345';
                this.fimenu.dealType = 'Finance';
                this.fimenu.vin = '1C4PJLDB2GW330' + Math.floor((Math.random() * 999) + 1);
                this.fimenu.customer.firstName = 'Michael';
                this.fimenu.customer.lastName = 'Jordan';
                this.fimenu.otd = 35000;
                msrp.retail = 40000;
                msrp.wholesale = 40000;
                this.fimenu.loanTerms.term1.term = 60;
                this.fimenu.loanTerms.term1.buyrate = 4;
                this.fimenu.loanTerms.term1.sellrate = 5;
                this.fimenu.customer.address = '123 St';
                this.fimenu.customer.city = 'Miami';
                this.fimenu.customer.state = 'FL';
                this.fimenu.customer.zip = '33162';
                this.fimenu.customer.email = 'fafa@fafa.com';
                this.fimenu.customer.phone = '3059999999';
                this.fimenu.customer.cell = '3059999999';
                this.fimenu.lender.lenderName = 'Ally';
                this.fimenu.lender.lenderAddress = '123 Main St';
                this.fimenu.lender.lenderCity = 'Anytown';
                this.fimenu.lender.lenderState = 'FL';
                this.fimenu.lender.lenderZip = '32801';
                this.fimenu.loanTerms.finalMonthlyPayment = 250;
                this.fimenu.vehicleOil = 0;
                this.fimenu.vehicleMiles = 15;
            },
            openCustomerPaperwork() {
                this.openCustomerPopup('paperwork');
            },
            handleLeaveApp(e) {
                if (this.meetingHelper.meetingCurrentlyOpen() && this.meetingHelper.inStore) {
                    this.meetingHelper.endMeeting();
                }

                if ((this.coBrowsingEnabled && !this.$dealRoomHub.connected) || (this.viewingRole && this.viewingRole !== 'Editor'))
                    return;

                e.returnValue = 'Prompt question before leaving';
            },
            createFimenuVersionforCustomer() {
                const store = settings.userStores.find(uS => uS.storeCode == this.fimenu.storeCode)

                const fimenuMinified = new FIMenuForCustomerMeeting(this.fimenu);
                fimenuMinified.storeCode = this.fimenu.storeCode;
                fimenuMinified.storeState = this.fimenu.store.storeState;
                fimenuMinified.googleReviewsURL = this.googleReviewURL
                fimenuMinified.pricesReady = this.fimenu.selectedProviders?.length > 0 ? true : false;
                fimenuMinified.calculatedFinalNumbers = {};
                fimenuMinified.currentPacket = this.fimenu.paperwork.currentPacket();


                const dealType = (this.fimenu.isDraft()) ? 'Finance' : this.fimenu.dealType;
                switch (dealType) {
                    case "Lease": {
                        this.fimenu.coverageTerms.forEach(cT => {
                            const terms = [];
                            this.fimenu.leaseTerms.availableTerms().forEach(aT => {
                                // filter out buyer's order since it doesn't need to be sent to customer
                                const { bo, ...filteredFinalNumbers } = this.fimenu.finalNumbers(store.storeState, aT, cT, null);;
                                terms.push({ term: aT.term, numbers: filteredFinalNumbers })
                            });
                            fimenuMinified.calculatedFinalNumbers[cT.term] = {
                                termNumbers: terms
                            }
                        });
                        const { basePayment } = this.fimenu.finalNumbers(store.storeState, this.fimenu.leaseTerms.term1, null, false)
                        fimenuMinified.disclaimerPayment = basePayment;
                        break;
                    }
                    case "Finance": {
                        this.fimenu.coverageTerms.forEach(cT => {
                            const terms = []
                            this.fimenu.loanTerms.availableTerms().forEach(aT => {
                                // filter out buyer's order since it doesn't need to be sent to customer
                                const { bo, ...filteredFinalNumbers } = this.fimenu.finalNumbers(store.storeState, aT, cT, null);;
                                terms.push({ term: aT.term, numbers: filteredFinalNumbers })
                            });
                            fimenuMinified.calculatedFinalNumbers[cT.term] = {
                                termNumbers: terms
                            }
                        })
                        const { basePayment } = this.fimenu.finalNumbers(store.storeState, this.fimenu.loanTerms.term1, null, false)
                        fimenuMinified.disclaimerPayment = basePayment;
                        break;
                    }
                    case "Cash": {
                        this.fimenu.coverageTerms.forEach(cT => {
                            const prices = this.fimenu.finalNumbers(store.storeState, null, cT, null)
                            // filter out buyer's order since it doesn't need to be sent to customer
                            prices.finalPayment = prices.finalAmount
                            const { bo, ...filteredPrices } = prices;
                            fimenuMinified.calculatedFinalNumbers[cT.term] = {
                                termNumbers: [{ term: cT.term, numbers: filteredPrices }]
                            }
                        })
                        break;
                    }
                }
                return fimenuMinified;
            },
            openCustomerPopup(section) {
                //console.log(section)
                this.toggleButtonDropdown(false);
                this.toggleButtonDropdownForPaperwork(false);
                this.popup.section = section;

                if (this.$meetingHub.connected && this.$meetingHub.code) {
                    const fimenuMinified = this.createFimenuVersionforCustomer()
                    this.fimenuClone = new FIMenu(this.fimenu)
                    console.log(`openCustomerPopup - what is fimenuClone? `, this.fimenuClone);

                    switch (section) {
                        case 'video-presentation':
                            this.$meetingHub.sendActionToCustomer(ENUMS.CUSTOMER_TRIGGERS.OPEN_VIDEO, JSON.stringify(fimenuMinified))
                            this.meetingHelper.currentMeeting?.meetingLogs.push(new FIMenuCustomerViewed({ viewedType: ENUMS.CUSTOMER_VIEWS.OPENING, viewedDateTime: moment(), extraInfo: ENUMS.customerViews[2] }))
                            break;
                        case 'payment-sheet':
                            this.$meetingHub.sendActionToCustomer(ENUMS.CUSTOMER_TRIGGERS.PAYMENT_SHEET, JSON.stringify(fimenuMinified))
                            this.meetingHelper.currentMeeting?.meetingLogs.push(new FIMenuCustomerViewed({ viewedType: ENUMS.CUSTOMER_VIEWS.OPENING, viewedDateTime: moment(), extraInfo: ENUMS.customerViews[3] }))
                            break;
                        case 'information':
                            this.$meetingHub.sendActionToCustomer(ENUMS.CUSTOMER_TRIGGERS.INFORMATION, JSON.stringify(fimenuMinified))
                            this.meetingHelper.currentMeeting?.meetingLogs.push(new FIMenuCustomerViewed({ viewedType: ENUMS.CUSTOMER_VIEWS.PROCESSING, viewedDateTime: moment(), extraInfo: ENUMS.customerViews[28] }))
                            break;
                        case 'paperwork':
                            console.log('----- fimenuMinified.currentPacket: ', fimenuMinified.currentPacket )
                            this.$meetingHub.sendActionToCustomer(ENUMS.CUSTOMER_TRIGGERS.PAPERWORK, JSON.stringify(fimenuMinified))
                            this.meetingHelper.currentMeeting?.meetingLogs.push(new FIMenuCustomerViewed({ viewedType: ENUMS.CUSTOMER_VIEWS.OPENING, viewedDateTime: moment(), extraInfo: ENUMS.customerViews[4] }))
                            break;
                        case 'paperwork-creating':
                            this.$meetingHub.sendActionToCustomer(ENUMS.CUSTOMER_TRIGGERS.PAPERWORK_SCREEN, JSON.stringify(fimenuMinified))
                            this.meetingHelper.currentMeeting?.meetingLogs.push(new FIMenuCustomerViewed({ viewedType: ENUMS.CUSTOMER_VIEWS.OPENING, viewedDateTime: moment(), extraInfo: ENUMS.customerViews[2] }))
                            break;
                        default:
                            break;
                    }
                    // eslint-disable-next-line no-console
                    console.log('### Meeting Updated ', this.meetingHelper.currentMeeting)
                    api.meeting.updateMeeting(this.meetingHelper.currentMeeting)


                }

            },
            checkIfFimenuClonedIsNullAndSet() {
                // There was a bug where fimenClone was being set to null, this function was added to fix this case
                if (!this.fimenuClone) {
                    console.log('what is fimenuClone?', this.fimenuClone)
                    LogRocket.track('fimenuClone is null');
                    this.fimenuClone = new FIMenu(this.fimenu);
                }
            },
            customerPaymentSheetCheck() {
                if (this.fimenu.selectedProviders?.length > 0) {
                    this.openCustomerPopup('payment-sheet');
                }
            },
            goToSection(sectionName) {
                //console.log('goToSection sectionName', sectionName);
                this.openCustomerPopup(sectionName);
            },
            onPopupClose() {
                SoundPlayer.shush();
                // console.log("shush called")
                if (this.$refs.fimenuBasic && !this.$refs.fimenuBasic.isDisabledCustomerInformation()) {
                    this.dealSave();
                }
                this.popup.component = null;
                this.popup.props = null;
            },
            //-----------------------------------------------------
            //MeetingHub events Handlers
            meetingRoomJoinSuccessHandler({ role, isInStoreConnection, meetingRoomCode, currentMeetingDetails }) {

                this.meetingHelper.meetingRoomJoinSuccessHandler(role, isInStoreConnection, meetingRoomCode, currentMeetingDetails);
                this.meetingHelper.customerScreenConnected = true


                if (role == 'customer' || role == 'coCustomer') {
                    // this.signalRNotes.push({ icon: 'fas fa-eye', text: 'Customer Event: ' + ENUMS.customerViews[2] });
                    this.fimenu.customerViewed.push(new FIMenuCustomerViewed({ viewedType: 2, viewedDateTime: moment() }));

                    this.meetingHelper.customerScreenConnected = true
                    this.$meetingHub.fimanagerAlreadyConnected('')

                } else if (role == 'fimanager') {
                    // this.signalRNotes.push({ icon: 'fas fa-hourglass-half', text: 'Waiting for customer to connect ...' })
                    this.$meetingHub.fimanagerAlreadyConnected('')
                }


            },
            meetingRoomJoinErrorHandler() {
                // this.signalRNotes.push('Error Connecting to Meeting Room.')
            },
            addCustomerSignaturesToLog(document, packetDateStamp) {
                const overlaysBySigner = document.overlays.filter(overlay => overlay.signature?.personType == document.signer || overlay.initials?.personType == document.signer);
                overlaysBySigner.forEach((overlay, index) => {
                    if (document.signer) {
                        if (overlay.signature && (overlay.signature.personType == ENUMS.PERSON_TYPES.CUSTOMER || overlay.signature.personType == ENUMS.PERSON_TYPES.COCUSTOMER)) {
                            this.fimenu.addToLog(packetDateStamp, `Signature Captured: ${index + 1}/${overlaysBySigner.length}`, document?.description, overlay.signature.signerName, JSON.stringify(overlay.signature.signedSessionInfo))
                        }
                        if (overlay.initials && (overlay.initials.personType == ENUMS.PERSON_TYPES.CUSTOMER || overlay.initials.personType == ENUMS.PERSON_TYPES.COCUSTOMER)) {
                            this.fimenu.addToLog(packetDateStamp, `Initials Captured: ${index + 1}/${overlaysBySigner.length}`, document?.description, overlay.initials.signerName, JSON.stringify(overlay.initials.signedSessionInfo))
                        }
                    }
                })
            },
            async updatePaperworkToSignedHandler({ pdfIndex, ...document }) {
                try {
                    const docsBeforeModification = {...this.fimenu.paperwork.currentPacket().documents};
                    // eslint-disable-next-line no-console
                    console.log("In updatePaperworkToSignedHandler", pdfIndex, document, document?.description);
                    this.addCustomerSignaturesToLog(document, this.fimenu.paperwork.currentPacket().packetDateStamp);
                    const docName = document.description ?? ""
                    //this.fimenu.paperwork.currentPacket().documents[pdfIndex].status = status /* ENUMS.PaperworkDocumentStatus.SigningInProgress */;
                    this.fimenu.paperwork.currentPacket().documents[pdfIndex].status = document.status
                    this.fimenu.paperwork.currentPacket().documents[pdfIndex].statusString = document.statusString
                    //If we ever want to have 2 signers, sign at the same time. We must modify this to only edit the current signer overlay.
                    this.fimenu.paperwork.currentPacket().documents[pdfIndex].overlays = [...document.overlays]
                    let interactionStatus;
                    switch (document.status) {
                        case ENUMS.PaperworkDocumentStatus.ReadyToBeSigned:
                            interactionStatus = ' cleared and restarted.'
                            break
                        case ENUMS.PaperworkDocumentStatus.PartiallySigned:
                            interactionStatus = ' partially signed.'
                            break
                        default:
                            interactionStatus = ' signed.'
                            break
                    }


                    const overlaysFilteredByPersonType = document.overlays.filter(overlay => {
                        if(overlay.signature) {
                            return overlay.signature.personType === document.signer;
                        }
                        if(overlay.initials) {
                            return overlay.initials.personType === document.signer;
                        }
                    });
                    const shouldSaveDoc = overlaysFilteredByPersonType.every(overlay => {
                        if(overlay.signature) {
                            return overlay.signature.isSigned;
                        }
                        if(overlay.initials) {
                            return overlay.initials.isSigned;
                        }

                    });

                    if(overlaysFilteredByPersonType.length > 0 && shouldSaveDoc) {
                        const currentDocId = this.fimenu.paperwork.currentPacket().documents[pdfIndex].id;

                        const response = await api.fimenu.saveUpdatedPaperwork(this.fimenu.id, [currentDocId], document.signer, this.fimenu.paperwork);
                        if(response?.data?.messages.length > 0) {
                            this.handleSigningError(docName);
                            // Revert document to previous state
                            this.fimenu.paperwork.currentPacket().documents = docsBeforeModification;
                            LogRocket.log('Error saving updated packets: response.request', response.data.messages[0]);
                            throw new Error(`Error saving updated packets: ${response.data.messages[0]}`);
                        } else {
                            this.fimenu.paperwork.currentPacket().documents = response.data.payload;
                            util.toastr('success', 'Paperwork Updated', docName + interactionStatus);
                            const fimenuMinified = this.createFimenuVersionforCustomer();
                            this.meetingHelper.currentMeeting?.meetingLogs.push(new FIMenuCustomerViewed({ viewedType: 22, viewedDateTime: moment(), extraInfo: docName }))
                            this.$meetingHub.sendActionToCustomer(ENUMS.CUSTOMER_TRIGGERS.LOAD_NEXT_DOCUMENT, JSON.stringify(fimenuMinified));
                        }

                    }

                    //const paperworkSigned = this.hasAllPaperworkBeenSigned()
                    //if(paperworkSigned) {
                    //    console.log('hasAllPaperworkBeenSigned', paperworkSigned)
                    //    this.dealSave();
                    //}

                } catch (error) {
                    console.error(error)
                }

            },
            async checkFinishedPaperworkHandler() {
                try {
                    this.isCheckingPaperwork = true;
                    // check for any overlays that are not signed
                    const docsNeedingSignatures = this.getDocsWithMissingSignatures();
                    console.log('docsNeedingSignatures', docsNeedingSignatures);

                    // check if there are any docs that don't require customer/co-customer signatures (these are signed by default and will need to be finalized)
                    if (this.docsWhereNonCustomerSignatureRequired && this.docsWhereNonCustomerSignatureRequired.length > 0) {
                        console.log('docsWhereNonCustomerSignatureRequired', this.docsWhereNonCustomerSignatureRequired);
                        this.docsWhereNonCustomerSignatureRequired.forEach(doc => {
                            doc.status = ENUMS.PaperworkDocumentStatus.Signed;

                        });
                        const docIds = this.docsWhereNonCustomerSignatureRequired.map(doc => doc.id);
                        const response = await api.fimenu.saveUpdatedPaperwork(this.fimenu.id, docIds, document.signer, this.fimenu.paperwork);
                        if (response?.data?.messages?.length > 0) {
                            throw new Error(`Error saving updated packets: ${ response.data.messages[0] }`);
                        } else {
                            this.fimenu.paperwork.currentPacket().documents = response.data.payload;
                        }

                        util.toastr("success", "Paperwork Saved and Finalized", "All documents have been signed and finalized", 5000)
                    }
                } catch (error) {
                    util.toastr("error", "Error Saving Document", error, 5000)
                    console.error(error);
                } finally {
                    this.isCheckingPaperwork = false;
                }

            },
            getDocsWithMissingSignatures() {
                const docsNeedingSignatures = [];
                this.fimenu.paperwork.currentPacket().documents.forEach(currentDoc => {
                    const docInfo = {
                        name: currentDoc.description,
                        missingSignatures: []
                    };
                    currentDoc.overlays.forEach(o => {
                        if(o.signature && !o.signature.isSigned) {
                            docInfo.missingSignatures.push(o.signature.signerName);
                        }
                        if(o.initials && !o.initials.isSigned) {
                            docInfo.missingSignatures.push(o.initials.signerName);
                        }
                    });
                    if(docInfo.missingSignatures.length > 0) {
                        docsNeedingSignatures.push(docInfo);
                    }
                });
                return docsNeedingSignatures;
            },
            hasAllPaperworkBeenSigned() {
                return this.fimenu.paperwork.currentPacket().documents.every(doc =>
                    doc.overlays.every(o => o?.signature?.isSigned || o?.initials?.isSigned))
            },
            async handleSigningError(docName) {
                $modal.open(modalInfo, {
                    name: 'modalInfo',
                    passedData: {
                        title: `Paperwork Signing Error`,
                        info: `Error saving document: ${docName}`,
                        additionalInfo: `The meeting has ended. Please connect customer and try again.`,
                        cancelText: 'Dismiss'
                    }
                });
                await this.meetingHelper.endMeeting();

            },
            updatePreferredCoverageHandler(term) {
                // this.signalRNotes.push({ icon: 'fas fa-check', text: 'Preferred Coverage Term Selected: ' + term })
                this.meetingHelper.currentMeeting?.meetingLogs.push(new FIMenuCustomerViewed({ viewedType: 23, viewedDateTime: moment(), extraInfo: term }))
                this.fimenu.coverageTerms.forEach(ct => {
                    ct.preferred = ct.term == term ? true : false
                })
                this.checkIfFimenuClonedIsNullAndSet();
                this.fimenuClone.coverageTerms.forEach(ct => {
                    ct.preferred = ct.term == term ? true : false
                })
            },
            updateLoanDaysToPaymentHandler(days) {
                // this.signalRNotes.push({ icon: 'fas fa-comment-dollar', text: 'Days to Next payment Selected: ' + days })
                this.meetingHelper.currentMeeting?.meetingLogs.push(new FIMenuCustomerViewed({ viewedType: 23, viewedDateTime: moment(), extraInfo: days }))
                this.fimenu.loanTerms.selectedDaysToFirstPayment = days
                this.checkIfFimenuClonedIsNullAndSet();
                this.fimenuClone.loanTerms.selectedDaysToFirstPayment = days
            },
            updateDraftLoanTermsHandler({ term, sellRate, buyRate }) {
                const loanTerms = JSON.stringify({ term: term, sellrate: sellRate, buyrate: buyRate });
                // this.signalRNotes.push({ icon: 'fas fa-comment-dollar', text: `Draft Loan Terms Inputted: ${term} Months,  ${sellRate}%/${buyRate}%` });
                this.meetingHelper.currentMeeting?.meetingLogs.push(new FIMenuCustomerViewed({ viewedType: 30, viewedDateTime: moment(), extraInfo: loanTerms }));

                this.fimenu.loanTerms.term1.term = term;
                this.fimenu.loanTerms.term1.sellrate = sellRate;
                this.fimenu.loanTerms.term1.buyrate = buyRate;

                this.checkIfFimenuClonedIsNullAndSet();
                this.fimenuClone.loanTerms.term1.term = term;
                this.fimenuClone.loanTerms.term1.sellrate = sellRate;
                this.fimenuClone.loanTerms.term1.buyrate = buyRate;
                this.EventBus.emit('recalculate');

                this.$nextTick(() => {
                    const fimenuMinified = this.createFimenuVersionforCustomer();
                    this.$meetingHub.sendActionToCustomer(ENUMS.CUSTOMER_TRIGGERS.LOAD_NEXT_DOCUMENT, JSON.stringify(fimenuMinified));
                });
            },
            async updateSignatureAndInitialsHandler(signatureInfo)  {
                const {who, signature, initials, signatureId, initialsId} = signatureInfo;
                this.fimenu[who].signature = signature;
                this.fimenu[who].initials = initials;
                const signatureBase64 = signature;
                const initialsBase64 = initials;
                this.fimenu[who].currentSignatureId = signatureId;
                this.fimenu[who].currentInitialsId = initialsId;

                if(signature) {
                    try {
                        await api.fimenu.saveCurrentSignaturesAndInitialsToAzure({
                            dealId: this.fimenu.id,
                            signatureBase64,
                            initialsBase64,
                            signatureId,
                            initialsId,
                        });
                    } catch(error) {
                        console.error(error);
                    }
                }

            },
            updateDocumentIsOpenHandler(docIndex, isOpen) {

                const currentDocumentIndex = this.fimenu.paperwork.packets.findIndex(doc => doc.isCurrent);

                this.fimenu.paperwork.packets[currentDocumentIndex].documents.forEach(doc => doc.isOpen = false);
                if (docIndex >= 0) this.fimenu.paperwork.packets[currentDocumentIndex].documents[docIndex].isOpen = isOpen;
            },
            downloadDocumentHandler(path) {
                /* eslint-disable no-console */
                console.log("Starting to download from azure ", path)
                api.fimenu.getDocument(path).then(response => {
                    //console.log('getDocument', response);
                    //MODIFY THE FILE URL TO GET IT FROM THE API
                    this.$meetingHub.sendDownloadedDocument(response.data)
                    console.log("Sent doc to customer APP ", path)
                })
                    .catch(err => console.error(err));
                /* eslint-disable */
            },
            async moveToSecureRoomHandler({ newSecureRoom }) {
                try {
                    console.log('moveToSecureRoomHandler - newSecureRoom', newSecureRoom);
                    await this.$meetingHub.leavePreConnectionRoom(newSecureRoom)
                    await this.meetingHelper.fimanagerConnectToSecureRoom(this.fimenu);
                } catch(error) {
                    console.log(error)
                }
            },
            async triggerFIManagerActionHandler({ action, fimenu }) {
                switch (action) {
                    case ENUMS.FIMANAGER_TRIGGERS.CUSTOMER_CONNECTED:
                        this.fimenu.customerViewed.push(new FIMenuCustomerViewed({ viewedType: 2, viewedDateTime: moment() }));
                        // this.meetingInfo.startedAt = this.meetingInfo.startedAt ? this.meetingInfo.startedAt : moment();
                        this.$meetingHub.fimanagerAlreadyConnected('')
                        // if (this.meetingInfo.startedAt == null) {
                        //     this.meetingInfo.startedAt = moment()
                        // }
                        this.meetingHelper.customerScreenConnected = true
                        break;
                    case ENUMS.FIMANAGER_TRIGGERS.UPDATE_FIMENU:
                        {
                            let receive = JSON.parse(fimenu)
                            console.log('triggerFIManagerActionHandler - what is fimenuClone?', this.fimenuClone);
                            await this.setFIMenu(this.fimenuClone)
                            this.fimenu.selectCoverageTerm(receive.coverageTerm)

                            if (receive.term) {
                                if (this.fimenu.dealType === 'Finance') {
                                    this.fimenu.loanTerms.acceptedTerm = receive.term;
                                }
                                else if (this.fimenu.dealType === 'Lease') {
                                    this.fimenu.leaseTerms.acceptedTerm = receive.term;
                                }
                            }

                            this.fimenu.customerViewed.push(new FIMenuCustomerViewed({ viewedType: 16, viewedDateTime: moment(), videosSeen: null }))


                            this.$nextTick(() => {
                                this.fimenu.ratingStatus = this.fimenuClone.ratingStatus
                                this.fimenu.calculateBuyersOrdersWithBaseBuyersOrders = true;
                                this.fimenu.buyersOrderRecalculateRequired = true;
                                this.EventBus.emit('recalculateBuyersOrder');
                                this.dealSave();

                            })
                            // this.signalRNotes.push({ icon: 'fas fa-redo-alt', text: 'Load Fimenu to update information after customer Interactions' })
                            break;
                        }
                }
            },
            registerCustomerViewedHandler({viewedType, viewedDateTime, videosSeen}) {
                console.log('---registerCustomerViewedHandler---', viewedType, viewedDateTime, videosSeen)
                console.log('fimenu', this.fimenu);
                console.log('fimenu.customerViewed', this.fimenu?.customerViewed);
                console.log('fimenuClone', this.fimenuClone);
                console.log('fimenuClone.customerViewed', this.fimenuClone?.customerViewed);
                this.fimenu.customerViewed.push(new FIMenuCustomerViewed({ viewedType, viewedDateTime, videosSeen }));
                this.checkIfFimenuClonedIsNullAndSet();
                this.fimenuClone.customerViewed.push(new FIMenuCustomerViewed({ viewedType, viewedDateTime, videosSeen}));
                // this.signalRNotes.push({ icon: videoSeen ? 'fas fa-film' : 'fas fa-eye', text: 'Customer Event: ' + ENUMS.customerViews[type] + (videoSeen ? ' - Video: ' + ENUMS.VIDEOS_SEEN_TITLES[videoSeen] : '') });
            },

            // DealRoomHub Functions
            setupCoBrowsing() {
                if (!this.coBrowsingEnabled) return;

                this.domElementToWatch = document.querySelector('.app-container');

                window.addEventListener('resize', this.windowResizeHandler);

                this.$watch(() => this.dealUpdatedExternally, (newVal, oldVal) => {
                    this.EventBus.emit('dealUpdatedExternally', newVal);
                    setTimeout(() => { this.dealUpdatedExternally = false; }, 100);
                });

                this.$watch(() => this.registrationAddressState, (dealState, oldVal) => {
                    const storeState = this.fimenu.store.storeState;
                    this.fimenu.outOfStateDeal = !!dealState && !!storeState ? dealState?.toLowerCase() != storeState?.toLowerCase() : null;
                })
            },
            windowResizeHandler() {
                if (!this.coBrowsingEnabled || !this.$dealRoomHub.connected || this.dealRoomViewerCount < 2) return;
                util.debounce(this.windowResizeHandlerDebounced, 100);
            },
            windowResizeHandlerDebounced() {
                this.screenResizedToggle++;
            },
            async alertDisconnectCoBrowsing() {
                await this.$dealRoomHub.alertDisconnect();
            },
            async updatedRoomInfoHandler(roomInfo, viewerInfo) {
                if (this.fimenuTemp) await this.initFiMenu();

                this.dealRoomViewerCount = roomInfo.viewerCount;
                this.dealRoomFollowerCount = roomInfo.followerCount ?? 0;

                if (this.viewingRole && this.viewingRole !== viewerInfo.role) {
                    let roleName = (viewerInfo.role === 'Spectator') ? 'Viewer' : viewerInfo.role;

                    $modal.open(modalInfo, {
                        name: 'modalInfo',
                        passedData: {
                            title: `Role Changed to ${viewerInfo.role}`,
                            info: `Your viewing role has been changed to ${roleName}`,
                            acceptText: 'OK'
                        }
                    });
                }

                this.viewingRole = viewerInfo.role;
                this.followEditor = viewerInfo.followEditor;
                this.fimenu.isSpectator = (viewerInfo.role !== "Editor");

                this.setWatchEditorSections();
            },
            viewingRoleChangedHandler(role) {
                // Close any modals that may have been opened while editing
                if (this.viewingRole === 'Editor' && role === 'Spectator') {
                    $modal.cancel();
                }

                if (this.viewingRole && this.viewingRole !== role) {
                    $modal.open(modalInfo, {
                        name: 'modalInfo',
                        passedData: {
                            title: `Role Changed to ${role}`,
                            info: `Your viewing role has been changed to ${role}`,
                            acceptText: 'OK'
                        }
                    });
                }

                this.viewingRole = role;
                if (role === 'Editor') this.followEditor = false;
                this.fimenu.isSpectator = (role !== 'Editor');
                this.setWatchEditorSections();
            },
            async dealHasBeenUpdatedHandler(newFimenu) {
                if (JSON.stringify(this.fimenu) === JSON.stringify(newFimenu)) return;

                this.dealUpdatedExternally = true;

                newFimenu.isSpectator = (this.viewingRole !== 'Editor');
                newFimenu.buyersOrderUpdateRequired = false;

                await this.setFIMenu(newFimenu);

                // These values will get reset by setFIMenu
                if (newFimenu.dealAddressesQuestions != null)
                    this.fimenu.dealAddressesQuestions = newFimenu.dealAddressesQuestions;

                if (newFimenu.ratingStatus != null)
                    this.fimenu.ratingStatus = newFimenu.ratingStatus;
            },
            async requestForDealHandler() {
                await this.$dealRoomHub.updateDealForConnection(this.fimenu);
            },
            syncFollowEditorHandler(followEditor) {
                this.followEditor = followEditor;
            },
            followCustomerScreenToggle(state) {
                this.followCustomerScreen = state;
            },
            editorHTMLUpdateHandler(editorHTML, screenXY, elementXY) {
                if (!this.editorHTMLReceived) this.editorHTMLReceived = true;
                this.$nextTick(() => {
                    if (!this.$refs.editorFacadeDump) return;

                    let oldScrollPositions = Array.from(this.$refs.editorFacadeDump.querySelectorAll('[scrollpercent]')).map((element) => element.scrollTop);

                    this.$refs.editorFacadeDump.innerHTML = editorHTML;

                    this.$refs.editorFacadeDump.style.width = `${elementXY.x}px`;
                    this.$refs.editorFacadeDump.style.height = `${elementXY.y}px`;

                    this.editorScreenDimensions.screenX = screenXY.x;
                    this.editorScreenDimensions.screenY = screenXY.y;

                    // If admin, remove cobrowsing blur censors
                    if (this.payload.EmployeeAccess.IsAdmin) {
                        let cobrowsingCensors = this.$refs.editorFacadeDump.querySelectorAll('.cobrowsing-censor');
                        cobrowsingCensors.forEach(element => {
                            element.style.filter = 'unset';
                        });
                    }

                    // Set values of textarea elements so that the value gets rendered correctly
                    const textareaElems = this.$refs.editorFacadeDump.querySelectorAll('textarea');
                    textareaElems?.forEach(elem => elem.value = elem.getAttribute('value'));

                    // Set initial scroll
                    let scrolledElements = Array.from(this.$refs.editorFacadeDump.querySelectorAll('[scrollpercent]'));
                    for (let i = 0; i < scrolledElements.length; i++) {
                        let currentElem = scrolledElements[i];

                        currentElem.scrollTop = oldScrollPositions[i] ?? 0;
                        this.$nextTick(() => {
                            let scrollAmt = currentElem.getAttribute('scrollpercent') * (currentElem.scrollHeight - currentElem.clientHeight);
                            currentElem.scrollTo({ top: scrollAmt });
                        });
                    }

                    // Set live customer view screen, if available
                    this.$refs.customerFacadeDump.innerHTML = this.$refs.editorFacadeDump.querySelector('.live-screen-container')?.outerHTML;
                    if (!this.followCustomerScreen) {
                        this.editorScreenDimensions.customerX = screenXY.x;
                        this.editorScreenDimensions.customerY = screenXY.y;
                        this.$refs.customerFacadeDump.style.width = `${screenXY.x}px`;
                        this.$refs.customerFacadeDump.style.height = `${screenXY.y}px`;
                        return;
                    }

                    let customerScreen = this.$refs.customerFacadeDump.querySelector('.live-screen-container');
                    this.$nextTick(() => {
                        let customerWidth = customerScreen.querySelector('.live-screen .customer-container, .live-screen')?.offsetWidth ?? 0;
                        let customerHeight = customerScreen.querySelector('.live-screen .customer-container, .live-screen')?.offsetHeight ?? 0;

                        this.editorScreenDimensions.customerX = (customerWidth > 0) ? customerWidth : screenXY.x;
                        this.editorScreenDimensions.customerY = (customerHeight > 0) ? customerHeight : screenXY.y;

                        this.$refs.customerFacadeDump.style.width = `${this.editorScreenDimensions.customerX}px`;
                        this.$refs.customerFacadeDump.style.height = `${this.editorScreenDimensions.customerY}px`;
                    });
                });
            },
            editorScrollUpdateHandler(targetClass, scrollPercent) {
                if (!this.followEditor) return;

                let scrolledElement = this.$refs.editorFacade.querySelector(targetClass);
                if (scrolledElement) {
                    let scrollAmt = scrollPercent * (scrolledElement.scrollHeight - scrolledElement.clientHeight);
                    scrolledElement.scrollTo({ top: scrollAmt, behavior: 'smooth' });
                }
            },
            async newCreditDecision(applicationRes, dealNumber) {

                LogRocket.log(`Received decision for ${dealNumber} and we actually have ${this.fimenu.dealNumber}`)
                if (dealNumber != this.fimenu.dealNumber) return;

                util.toastr("success", "Credit Decision", "You've received a new update for one of the credit applications", 5000)
                const application = new FIMenuCreditApplication(applicationRes)

                const applicationIndex = this.fimenu.dealJacket.applications.findIndex(x => x.id == application.id);
                if (applicationIndex >= 0) {
                    application.selected = this.fimenu.dealJacket.applications[applicationIndex].selected ?? false;
                    this.fimenu.dealJacket.applications[applicationIndex] = application;
                }
                else this.fimenu.dealJacket.applications.push(application)

                await this.fimenu.save();
            },
            async fetchLatestFIMenuHandler() {
                this.isBusyLoadingDeal = true;

                try {
                    const dealId = this.$route?.query?.t ?? this.fimenu?.id;
                    const getDealResponse = await api.fimenu.getLatestDeal(dealId);
                    await this.dealHasBeenUpdatedHandler(getDealResponse.data.fimenu);
                }
                catch (err) {
                    console.error('CoBrowsing: Error Trying to Fetch Latest FI Menu: ', err);
                    util.toastr('error', 'Error', 'An error occurred when trying to fetch the latest version of FI Menu.');
                }
                finally {
                    this.isBusyLoadingDeal = false;
                }
            },
            setWatchEditorSections() {
                if (this.fimenu.isSpectator && this.watchers.editorSections) {
                    // Unwatch
                    this.watchers.editorSections();
                    this.watchers.editorSections = null;
                }
                else if (!this.fimenu.isSpectator && !this.watchers.editorSections) {
                    this.watchers.editorSections = this.$watch(
                        () => this.editorSectionsToWatch,
                        () => {
                            if (this.dealRoomViewerCount < 2) return;
                            util.debounce(this.watchEditorSectionsDebounced, 150);
                        },
                        { deep: 4 }
                    );
                }
            },
            watchEditorSectionsDebounced() {
                if (!this.dealUpdatedExternally && !this.isFiMenuBasicBusy) {
                    this.$dealRoomHub.alertDealUpdated(this.fimenu);
                }
                else {
                    //console.log('FIMenu got updated externally.');
                    this.dealUpdatedExternally = false;
                }
            },
            async updateMeetingHubParticipantsHandler(updatedMeetingUsers) {
                await this.meetingHelper.updateMeetingHubParticipantsHandler(updatedMeetingUsers);
            },
            async customerDisconnectedHandler(updatedMeetingParticipants) {
                console.log('------ CUSTOMER DISCONNECTED HANDLER', updatedMeetingParticipants);
                await this.meetingHelper.updateMeetingHubParticipantsHandler(updatedMeetingParticipants);

                // Gets triggered if customer pressed home button or refreshes the page - this is needed to know if the customer intentionally disconnected (reconnection should not trigger fimanager to be disconnected)
                if (this.meetingHelper.inStore && this.meetingHelper.shouldMeetingEnd) {
                    await this.meetingHelper.fimanagerDisconnect();
                    this.dealSave();
                }
            },
            userRequestingToPresentHandler(userId) {
                this.meetingHelper.userRequestingToPresentHandler(userId)
            },
            endMeeting() {
                this.meetingHelper.customerScreenConnected = false;
            },
            meetingRoomErrorHandler(error) {
                console.error(`MEETING ROOM ERROR ${error} \n\n meetingHub.connected: ${this.$meetingHub.connected} \n\n connectionState: ${this.$meetingHub.connection._connectionState}`);
            },
            reconnectingMeetingHubHandler() {
                console.log('RECONNECTING IN FIMENU');
                LogRocket.track('Reconnecting to MeetingHub');
                util.toastr('warn', 'MeetingHub Connection Status', 'Attempting to reconnect...', 8000);
            },
            reconnectedMeetingHubHandler() {
                util.toastr('success', 'MeetingHub Connection Status', 'Successfully Reconnected to Meeting Room');
                this.meetingHelper.reconnectedMeetingHubHandler(this.fimenu.id);
            },
            async setupMeetingHub() {

                this.MeetingHubBus.on('meetingRoomJoinSuccess', this.meetingRoomJoinSuccessHandler)
                this.MeetingHubBus.on('meetingRoomJoinError', this.meetingRoomJoinErrorHandler)
                this.MeetingHubBus.on('updatePaperworkToSigned', this.updatePaperworkToSignedHandler)
                this.MeetingHubBus.on('checkFinishedPaperwork', this.checkFinishedPaperworkHandler)
                this.MeetingHubBus.on('updateDraftLoanTerms', this.updateDraftLoanTermsHandler)
                this.MeetingHubBus.on('updatePreferredCoverage', this.updatePreferredCoverageHandler)
                this.MeetingHubBus.on('updateLoanDaysToPayment', this.updateLoanDaysToPaymentHandler)
                this.MeetingHubBus.on('updateSignatureAndInitials', this.updateSignatureAndInitialsHandler)
                this.MeetingHubBus.on('updateDocumentIsOpen', this.updateDocumentIsOpenHandler)
                this.MeetingHubBus.on('downloadDocument', this.downloadDocumentHandler)
                this.MeetingHubBus.on('moveToSecureRoom', this.moveToSecureRoomHandler)
                this.MeetingHubBus.on('triggerFIManagerAction', this.triggerFIManagerActionHandler)
                this.MeetingHubBus.on('triggerSaveFIMenu', this.dealSave)
                this.MeetingHubBus.on('checkIfCustomerShouldGoToPaymentSheet', this.customerPaymentSheetCheck)
                this.MeetingHubBus.on('registerCustomerViewed', this.registerCustomerViewedHandler)
                this.MeetingHubBus.on('updateMeetingHubParticipants', this.updateMeetingHubParticipantsHandler)
                this.MeetingHubBus.on('userRequestingToPresent', this.userRequestingToPresentHandler)
                this.MeetingHubBus.on('endMeeting', this.endMeeting);
                this.MeetingHubBus.on('customerDisconnected', this.customerDisconnectedHandler)
                this.MeetingHubBus.on('meetingRoomError', this.meetingRoomErrorHandler);
                this.MeetingHubBus.on('reconnectingMeetingHub', this.reconnectingMeetingHubHandler);
                this.MeetingHubBus.on('reconnectedMeetingHub', this.reconnectedMeetingHubHandler)
            },
            setupDealRoomHub(isSubscribing) {
                let dealRoomSubscriptions = {
                    DealHasBeenUpdated: this.dealHasBeenUpdatedHandler,
                    RequestForDeal: this.requestForDealHandler,
                    ViewingRoleChanged: this.viewingRoleChangedHandler,
                    UpdatedRoomInfo: this.updatedRoomInfoHandler,
                    SyncFollowEditor: this.syncFollowEditorHandler,
                    EditorHTMLUpdate: this.editorHTMLUpdateHandler,
                    EditorScrollUpdate: this.editorScrollUpdateHandler,
                    FetchLatestFIMenu: this.fetchLatestFIMenuHandler,
                    SendCreditDecisionUpdate: this.newCreditDecision
                };

                if (isSubscribing)
                    this.$dealRoomHub.bulkSubscribe(dealRoomSubscriptions);
                else
                    this.$dealRoomHub.bulkUnsubscribe(dealRoomSubscriptions);
            },
            setupEventBus() {
                this.EventBus.on('paperworkStarted', this.onPaperworkStarted);
                this.EventBus.on('CDKPush', this.CDKPush);
                //ARROW FUNC WILL NOT BE CLEARED IN THE OFF
                this.EventBus.on('WaitingCDK', () => { this.openCDKPush = true; this.CDKPushing = null });
                this.EventBus.on('fimenuBasicBusy', this.setIsFiMenuBasicBusy);
                this.EventBus.on('panelFimenuBusy', this.setIsPanelFiMenuBusy);
                this.EventBus.on('CDKPushFinish', this.CDKPushFinish);
                this.EventBus.on('paperworkReady', this.onPaperworkReady);
                this.EventBus.on('openCustomerPaperwork', this.openCustomerPaperwork);
                this.EventBus.on('paperworkStartVoiding', this.onPaperworkStartVoiding);
                this.EventBus.on('paperworkEndedVoiding', this.onPaperworkEndedVoiding);
                this.EventBus.on('paperworkStartActivating', this.paperworkStartActivating);
                this.EventBus.on('paperworkEndedActivating', this.paperworkEndedActivating);
                this.EventBus.on('setFIMenu', this.setFIMenu);
                this.EventBus.on('followEditor', this.syncFollowEditorHandler);

                //Recording Events
                this.EventBus.on("restartScreenRecording", this.restartScreenRecording);
                this.EventBus.on("saveCameraRecordingStartTime", this.handleSaveCameraRecordingStartTime);
                this.EventBus.on("saveScreenRecordingStartTime", this.handleSaveScreenRecordingStartTime);
                this.EventBus.on("onLatestTimeStamp", this.handleLatestTimeStamp);
                this.EventBus.on("recordingError", this.handleRecordingError);
            },
            onBlurDMSNumber(isInvalid) {
                if (!isInvalid && this.fimenu.isDealLocked()) {
                    this.fimenu.save();
                }
            }
        },
        components: {
            PanelMenu,
            InputTextbox,
            FIMenuBasic,
            FIMenuVideoPlayer,
            FIMenuPaperwork,
            FIMenuPresentation,
            FIMenuGraph,
            FIMenuSignatures,
            CoBrowsingToolbar,
            Popup,
            PDFViewer,
            //Datepicker
            PaperWorkCreation,
            PaperWorkVoiding,
            PanelCustomerViewAlert,
            CDKPushComponent,
            PanelCustomerLive,
            DealStatusChangeAnimation,
            PanelCustomerConnectionHistory,
            PanelCustomerVideoChat,
            InputDate,
            IsBusySectionComponent,
            InputRichDropdown,
            InputNumber,
            TooltipComponent,
            DropdownButton,
            FIMenuCustomerMeetings,
            InputCheckbox,
            FloatingMenu,
            DealStatusIcon,
            ButtonMoveDeal,
            DealEmployeeDropdown,
            Pill,
            ReverseEnvironmentButton
        }
    };
</script>

<style>
    .bo-enabled-checkbox {
        font-size: 12pt;
    }

    .page.fimenupage {
        height: 100%;
    }

        .page.fimenupage .interaction-locked {
            pointer-events: none;
        }

    .editor-facade-container {
        width: fit-content;
        transform-origin: 0% 0%;
        border: 5px solid var(--error-color);
        filter: grayscale(0.8);
        transition: scale 0.3s ease-in-out;
        width: 100%;
    }

        .editor-facade-container * {
            animation: none;
        }

        .editor-facade-container > *:not(footer) {
            position: relative;
        }

        .editor-facade-container .facade-mask {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0;
            user-select: none;
            z-index: 2000;
        }

        .editor-facade-container .facade-customer .live-screen-container {
            height: 100%;
            width: 100%;
            margin-top: unset;
        }

            .editor-facade-container .facade-customer .live-screen-container .maximize {
                display: none;
            }

            .editor-facade-container .facade-customer .live-screen-container .live-screen {
                width: 100%;
                height: 100%;
                border: unset;
            }

        .editor-facade-container .facade-customer .live-screen > div {
            transform: scale(1) !important;
        }

        .editor-facade-container .facade-fimenu {
            z-index: 1;
        }

        .editor-facade-container .modal .modal-container {
            overflow: hidden;
            animation-name: unset !important;
            animation-duration: unset !important;
            transition: none !important;
            max-height: 90%;
        }

        .editor-facade-container footer {
            z-index: 0;
        }

    .fimenupage .loading-saturn-anim.fade-in.IsBusySectionComponent-container {
        height: 700px;
    }



    .panel-title.fimenu-title .vdpComponent {
        font-size: 14px
    }

    .fimenupage .fimenu-title .saturn-textbox select {
        padding: 5px 35px 5px 15px;
    }

    .sidemenu-contents-header.no-margin-grid {
        height: 58px;
        text-wrap: nowrap;
        align-items: center;
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }

    .fimenupage .sidemenu-contents-header .buttons-container {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
    }

    .fimenupage .info-button button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        padding: 5px 10px !important;
    }

    .fimenupage .panel-title.fimenu-title {
        width: 100%;
        height: 100%;
        gap: 20px;
        align-items: center;
        position: relative;
    }

        .fimenupage .panel-title.fimenu-title .deal-status {
            font-size: 0.80em;
            font-weight: 700;
            display: flex;
            align-items: center;
            gap: 10px;
            margin-left: auto
        }

    .fimenupage .FloatingMenu .admin-input-fields,
    .fimenupage .FloatingMenu .menu,
    .fimenupage .FloatingMenu .menu .floating-menu-body-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .fimenupage .locked-wrapper {
        height: 100%;
        position: relative;
    }

    .deal-employees {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .page.fimenupage .panel-title.fimenu-title .deal-customer .customer-name {
        font-size: 26px;
        text-transform: capitalize;
    }

    .page.fimenupage .panel-title.fimenu-title .deal-customer .cocustomer-name {
        font-weight: 500;
        font-size: 18px;
        text-transform: capitalize;
    }

    .page.fimenupage .deal-info {
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-100%)
    }

        .page.fimenupage .deal-info .deal-info-row {
            display: flex;
            flex-direction: row;
            justify-content: end;
            align-items: center;
            gap: 5px;
        }

        .page.fimenupage .deal-info .store-name,
        .page.fimenupage .deal-info .deal-date {
            font-size: 14px;
            color: var(--third-color)
        }

        .page.fimenupage .deal-info .deal-date {
            font-weight: 700;
        }

        .page.fimenupage .deal-info .deal-number {
            font-weight: 700;
            font-size: 22px;
        }
</style>
