<template>
    <div class="pdf-signature-pad-container">
        <IsBusyScreenComponent v-if="isBusyLoading" />

        <div class="pdf-signature-pad" ref="signaturePad">
            <div class="pdf-signature-pad-header">
                <div class="customer-name">{{name.toUpperCase()}}</div>
                <div class="signature-caption">Please draw your signature and your initials below:</div>
            </div>
            <div class="pdf-signature-pad-body">
                <div class="signature-pad">
                    <div class="pad-title">Signature</div>
                    <!-- <VueSignaturePad ref="signature" :options="{dotSize: (1.25 + 5)/2, minWidth: 1.25, maxWidth: 5 }" :class="{invalid: signatureDisabled, 'pad-container': true}" /> -->
                    <div :class="{invalid: signatureInvalid, 'pad-container': true}" ref="signatureCanvas">
                        <canvas ></canvas>
                    </div>
                    <div class="signature-line">
                        <div class="mark"><i class="fas fa-signature"></i></div>
                        <div class="line"></div>
                    </div>
                    <button class="button-custom-signature" @click="clearPad('signature')">Clear</button>
                </div>
                <div class="initials-pad">
                    <div class="pad-title">Initials</div>
                    <!-- <VueSignaturePad ref="initials" :options="{dotSize: (1.25 + 5)/2, minWidth: 1.25, maxWidth: 5 }" :class="{invalid: initialsDisabled, 'pad-container': true}" /> -->
                    <div :class="{invalid: initialsInvalid, 'pad-container': true}" ref="initialsCanvas">
                        <canvas ></canvas>
                    </div>
                    <div class="signature-line">
                        <div class="mark"><i class="fas fa-signature"></i></div>
                        <div class="line"></div>
                    </div>
                    <button class="button-custom-signature" @click="clearPad('initials')">Clear</button>
                </div>
            </div>
            <div class="pdf-signature-pad-footer">
                <button class="button-unwind button-custom-signature" @click="cancelSignature()" v-if="typeof cancelHandler == 'function'">Cancel</button>
                <button class="button-save button-custom-signature" @click="submitSignature()" :disabled="submitButtonDisabled">Submit</button>
            </div>

            <button v-if="userInfo" ref="startSigningButton" class="button-start-signing" @click="startSigning">
                <i class="fas fa-mobile-alt"></i>
                &nbsp;Sign on Phone
            </button>

            <div class="mobile-esign-overlay" v-if="mobileESignSession || hasUploadedMobileESign">
                <h3>
                    {{ hasUploadedMobileESign ? 'Preview' : isSigning ? 'Signing' : 'Scan to start signing' }}
                    <template v-if="!hasUploadedMobileESign && isSigning">
                        <span class="ellipsis-dot">.</span>
                        <span class="ellipsis-dot">&nbsp;.</span>
                        <span class="ellipsis-dot">&nbsp;.</span>
                    </template>
                </h3>

                <qrcode-vue :class="{'qr-code': true, 'expanded': !hasUploadedMobileESign && !isSigning}" :value="eSignUrl" :size="250" level="H" />

                <div :class="{'e-sign-container': true, 'expanded': hasUploadedMobileESign}" :style="$grid('2.18-.9', '0px 20px')">
                    <div class="signature">
                        <div class="caption">Signature</div>
                        <div class="reference-line">
                            <i class="fas fa-signature"></i>
                            <div class="line"></div>
                        </div>
                        <img v-if="mobileSignature" :src="mobileSignature" />
                    </div>
                    <div class="initials">
                        <div class="caption">Initials</div>
                        <div class="reference-line">
                            <i class="fas fa-signature"></i>
                            <div class="line"></div>
                        </div>
                        <img v-if="mobileInitials" :src="mobileInitials" />
                    </div>
                </div>

                <div class="button-group">
                    <button class="button-unwind" @click="cancelMobileSignature">Cancel</button>
                    <button v-if="hasUploadedMobileESign" class="button-save" @click="submitMobileSignature">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import IsBusyScreenComponent from '@core/components/IsBusyScreenComponent.vue'
    import QrcodeVue from 'qrcode.vue'
    import SignaturePad from 'signature_pad'
    import settings from 'settings'
    import api from '@core/services/api'
    import util from '@core/services/util'
    import { adjustSignatureToBaseline } from '@core/helpers/esign-helper'

    export default {
        name: 'PanelESign',
        props: ['name', 'cancelHandler', 'submitHandler', 'userInfo'],
        data() {
            return {
                signaturePad: null,
                initialsPad: null,
                initialsPoints: 0,
                signaturePoints: 0,

                isBusyLoading: false,
                isSigning: false,

                mobileESignSession: null,
                mobileSignature: null,
                mobileInitials: null,

                eSignUrl: null,
            }
        },
        computed: {
            signatureInvalid() {
                return this.signaturePoints < 20;
            },
            initialsInvalid() {
                return this.initialsPoints < 20;
            },
            submitButtonDisabled(){
                return this.signatureInvalid || this.initialsInvalid;
            },

            // signatureDisabled() {
            //     return this.signatureData._isEmpty || this.signaturePoints < 20
            // },
            // signatureButtonDisabled() {
            //     return this.signatureDisabled || this.initialsDisabled
            // },
            // initialsDisabled() {
            //     return this.initialsData._isEmpty || this.initialsPoints < 20
            // },
            // signaturePoints() {
            //     if (this.signatureData._isEmpty || !this.signatureData._data) return 0;

            //     return this.signatureData._data.reduce((total, stroke) => total + stroke.points.length, 0);
            // },
            // initialsPoints() {
            //     if (this.initialsData._isEmpty || !this.initialsData._data) return 0;

            //     return this.initialsData._data.reduce((total, stroke) => total + stroke.points.length, 0);
            // },
            hasUploadedMobileESign() {
                return !util.isNull(this.mobileSignature) && !util.isNull(this.mobileInitials);
            },
            groupId() {
                return this.userInfo ? `${this.userInfo.id}_ESIGN` : null;
            },
        },

        mounted() {
            this.$nextTick(() =>{
                // console.log('This is refs', this.$refs)
                const signatureCanvas = this.$refs?.signatureCanvas?.firstChild;
                const initialsCanvas = this.$refs?.initialsCanvas?.firstChild;

                const sigOptions = {dotSize: (1.25 + 5)/2, minWidth: 1.25, maxWidth: 5 }
                // console.log("This is the pad vars", signatureCanvas, initialsCanvas)
                if (signatureCanvas && initialsCanvas){
                    this.signaturePad = new SignaturePad(signatureCanvas, sigOptions);
                    this.initialsPad = new SignaturePad(initialsCanvas, sigOptions)

                    this.signaturePad.addEventListener('afterUpdateStroke', () => {
                        this.updatePad('signature');
                    })
                    this.initialsPad.addEventListener('afterUpdateStroke', () => {
                        this.updatePad('initials');
                    })
                    window.addEventListener('resize', this.resizeCanvas);
                    this.resizeCanvas();
                }
            })
        },
        beforeUnmount(){
            if (this.signaturePad && this.initialsPad){
                this.signaturePad.removeEventListener('afterUpdateStroke', () => {
                    this.updateSignature();
                })
                this.initialsPad.removeEventListener('afterUpdateStroke', () => {
                    this.updateInitials();
                    window.removeEventListener('resize', this.resizeCanvas);

                })
            }
        },
        methods: {
            resetMobileSigningStatus() {
                this.mobileESignSession = null;
                this.isSigning = false;
                this.mobileSignature = null;
                this.mobileInitials = null;

                this.$meetingHub.unsubscribeToGroupEvents(this.groupId);
            },
            cancelSignature() {
                if (typeof this.cancelHandler == 'function') {
                    this.cancelHandler()
                }
            },
            submitSignature() {
                if (typeof this.submitHandler == 'function') {
                    const signature = adjustSignatureToBaseline(this.$refs.signatureCanvas?.firstChild);
                    const initials = adjustSignatureToBaseline(this.$refs.initialsCanvas?.firstChild);

                    this.submitHandler(signature, initials);
                }
            },
            clearPad(pad) {
                if(this.signaturePad && pad == 'signature'){
                    this.signaturePad.clear();
                    this.signaturePoints = 0;
                } else if(this.initialsPad && pad == 'initials'){
                    this.initialsPad.clear();
                    this.initialsPoints = 0;
                }
            },
            updatePad(pad){
                if (pad == 'signature' && this.signaturePad._isEmpty === false && this.signaturePad._data.length > 0){
                    this.signaturePoints = this.signaturePad._data.reduce((acc, curr) => acc + curr.points.length, 0)
                } else if (pad == 'initials' && this.initialsPad._isEmpty === false && this.initialsPad._data.length > 0){
                    this.initialsPoints = this.initialsPad._data.reduce((acc, curr) => acc + curr.points.length, 0)
                }
            },
            resizeCanvas(){
                // console.log('Did we resize?')
                // Handle resize of canvas
                if (this.$refs.signatureCanvas && this.$refs.initialsCanvas){
                    this.$refs.signatureCanvas.firstChild.width = this.$refs.signatureCanvas.offsetWidth;
                    this.$refs.signatureCanvas.firstChild.height = this.$refs.signatureCanvas.offsetHeight;
                    this.$refs.initialsCanvas.firstChild.width = this.$refs.initialsCanvas.offsetWidth;
                    this.$refs.initialsCanvas.firstChild.height = this.$refs.initialsCanvas.offsetHeight;

                    this.clearPad('signature');
                    this.clearPad('initials');
                }
            },
            cancelMobileSignature() {
                clearTimeout(this.mobileESignSession);
                this.resetMobileSigningStatus();
            },
            submitMobileSignature() {
                if (typeof this.submitHandler == 'function') {
                    this.submitHandler(this.mobileSignature, this.mobileInitials)
                    this.resetMobileSigningStatus();
                }
            },
            async startSigning() {
                this.$refs.startSigningButton.blur();

                const request = {
                    appDomain: settings.appDomain,
                    storeCode: this.userInfo.storeCode,
                    employeeId: this.userInfo.id,
                    cellNumber: this.userInfo.cellNumber,
                };

                this.isBusyLoading = true;
                const response = await api.users.eSignMobile.getESignUrl(request);
                this.isBusyLoading = false;

                if (!response?.data) {
                    util.toastr('error', 'Error', 'Failed to generate QR code.');
                    return;
                }

                this.eSignUrl = response.data;

                this.$meetingHub.subscribeToGroupEvent('ESignStart', this.groupId, () => {
                    this.isSigning = true;
                });

                this.$meetingHub.subscribeToGroupEvent('ESignComplete', this.groupId, (uploadedData) => {
                    clearTimeout(this.mobileESignSession);

                    this.mobileSignature = uploadedData.signature;
                    this.mobileInitials = uploadedData.initials;

                    this.$meetingHub.unsubscribeToGroupEvents(this.groupId);
                });

                // Time out session after 5 minutes.
                this.mobileESignSession = setTimeout(() => {
                    util.toastr('error', 'Timeout', 'eSign session timed out.');
                    this.mobileESignSession = null;
                    this.isSigning = false;

                    this.$meetingHub.unsubscribeToGroupEvents(this.groupId);
                }, 1000 * 60 * 5);
            },
        },
        components: {
            IsBusyScreenComponent,
            QrcodeVue,
        }
    };
</script>
<style>
    .button-start-signing {
        width: unset;
        height: unset;
        font-size: 1.5em;
        position: absolute;
        padding: 10px 25px;
        top: 30px;
        right: 30px;
        transition: box-shadow 0.25s ease-out;
        box-shadow: 3px 5px 4px 1px rgba(0, 0, 0, 0.25);
    }

        .button-start-signing:hover {
            filter: brightness(110%);
            box-shadow: 2px 3px 2px 1px rgba(0, 0, 0, 0.2);
        }

    .mobile-esign-overlay {
        color: var(--main-color);
        background: var(--background-color);
        position: absolute;
        inset: 0;
        padding-inline: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        animation: 0.25s ease-in-out fadeIn;
        z-index: 99999999;
    }

        .mobile-esign-overlay h3 {
            text-align: center;
            font-size: 4em;
            font-weight: 500;
            margin: 20px 0px;
        }

            .mobile-esign-overlay h3 .ellipsis-dot {
                display: inline-block;
                animation: bobbing 3s infinite ease-in-out;
            }

                .mobile-esign-overlay h3 .ellipsis-dot:nth-child(1) {
                    animation-delay: 1s;
                }

                .mobile-esign-overlay h3 .ellipsis-dot:nth-child(2) {
                    animation-delay: 1.4s;
                }

                .mobile-esign-overlay h3 .ellipsis-dot:nth-child(3) {
                    animation-delay: 1.8s;
                }


        .mobile-esign-overlay .qr-code {
            overflow: hidden;
            opacity: 0;
            max-height: 0px;
            width: 280px;
            height: 280px;
            border-radius: 15px;
            box-shadow: var(--black-70percent) 4px 4px 8px 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: var(--transition);
            transition-duration: 0.4s;
            transition-timing-function: ease-in-out;
        }

            .mobile-esign-overlay .qr-code.expanded {
                max-height: 280px;
                opacity: 1;
            }

        .mobile-esign-overlay .e-sign-container {
            overflow: hidden;
            max-height: 0px;
            margin: 0;
            opacity: 0;
            padding: 0px;
            transition: var(--transition);
            transition-duration: 0.4s;
            transition-timing-function: ease-in-out;
        }

            .mobile-esign-overlay .e-sign-container.expanded {
                max-height: 500px;
                padding: 10px;
                opacity: 1;
            }

        .mobile-esign-overlay .button-group {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 40%;
            margin: 25px 0px;
        }

            .mobile-esign-overlay .button-group button {
                width: 40%;
        }

    @keyframes bobbing {
        30% {
            transform: translateY(-30px);
        }

        50% {
            transform: none;
        }
    }
</style>