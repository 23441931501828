import { BookValue } from "@core/classes/SharedEnums";

export default class VAutoLookupResponse {
    newUsed: string;
    certified: string;
    vehicle: string;
    stockNo: string;
    vin: string;
    odometer: number;
    color: string;
    interiorColor: string;
    age: string;
    disp: string;
    price: number;
    cost: number;
    pendingPrice: number;
    otherPrice: number;
    mktAvgPrice: number;
    jdPowerRetail: number;
    jdPowerLoan: number;
    bbWholeSale: number;
    bbFinanceAdvance: number;
    kbbAuction: number;
    mmrWholesale: number;

    constructor(init: Partial<VAutoLookupResponse>) {
        Object.assign(this, init)
    }
}


const PropertyToBookType: Partial<Record<keyof VAutoLookupResponse, BookValue>> = {
    bbWholeSale: BookValue.BlackBook,
    bbFinanceAdvance: BookValue.BlackBook,
    jdPowerRetail: BookValue.JDPowers,
    jdPowerLoan: BookValue.JDPowers,
    mmrWholesale: BookValue.MMR,
    kbbAuction: BookValue.KBB
};

export { PropertyToBookType };