import { defineAsyncComponent } from "vue";

import $modal from "@core/services/modal";
import api from "@core/services/api";
import auth from "@core/services/auth";
import settings from "settings";
import util from "@core/services/util";

import { AccessEmployeeClass, EmployeePermissions, SaturnPermissions } from "@core/classes/AccessEmployeeClass";
import { CamelKeysToPascalDeep } from "@core/classes/UtilClasses";
import DealChecklistItem from "@core/classes/Checklist/DealChecklistItem";
import ENUMS from "@core/classes/Enums";
import FIMenu from "@core/classes/FIMenu";
import { FIMenuSection } from "@core/classes/SharedEnums";
import { IDocumentVerification } from "@core/classes/UploadedDocument";

const modalDealChecklistSubset = defineAsyncComponent(() => import("@core/../saturn-app/src/modals/modalDealChecklistSubset.vue"));
const modalSendLinkToCustomer = defineAsyncComponent(() => import("@core/../saturn-app/src/modals/modalSendLinkToCustomer.vue"));

export default abstract class ChecklistHelper {
    /**
     * Checks whether there are any outstanding checklist items that need to be completed before continuing on from this FIMenu section.
     * If there are outstanding items, will open a modal displaying a list of checklist items that need to be completed.
     *
     * @export
     * @param {FIMenu} fimenu
     * @param {number} currentSection The section to check for outstanding items.
     * @return True if there's no outstanding checklist items for this current section. False if there are still items pending for completion.
     */
    static async canContinueToNextSection(fimenu: FIMenu, currentSection: FIMenuSection): Promise<boolean> {
        try {
            const response = await api.checklist.fimenu_getAllRequired(fimenu.id, currentSection);
            if (!response?.data)
                throw new Error('No response from server.', { cause: response });
            
            // No items for this section
            if (response.data.length < 1) return true;
            
            const sectionChecklist: DealChecklistItem[] = response.data.map((c: Partial<DealChecklistItem>) => new DealChecklistItem(c));
            if (sectionChecklist.every(c => c.isVerified())) return true;
            
            $modal.open(modalDealChecklistSubset, {
                name: 'modalDealChecklistSubset',
                passedData: {
                    fimenu: fimenu,
                    checklist: sectionChecklist.filter(c => !c.isVerified()),
                    additionalMessage: 'The following items need to be addressed before proceeding with the deal:',
                    lockAllActions: fimenu.isSpectator,
                    enableItemActions: true,
                }
            });
            
            return false;
        }
        catch (err) {
            console.error('Error Processing Checklist: ', err);
            util.toastr('error', 'Error Processing Checklist', 'Cannot validate outstanding checklist items. Unable to proceed to the next section.');

            return false;
        }
    };

    /**
     * Will open the `sendLinkToCustomer` modal for a specified customer, forwarding them a direct link to their checklist portal.
     *
     * @export
     * @param {FIMenu} fimenu
     * @param {number} forWhom ENUMS.PERSON_TYPES. Currently, can only specify between the customer and co-customer.
     * @param {(isBusy: boolean) => void} [isBusyCallback=null] An optional callback that will be called at the start and end of the modal's post function. 
     * Can be used to track when the API call finishes processing
     */
    static sendLinkToCustomer(fimenu: FIMenu, forWhom: number, isBusyCallback: (isBusy: boolean) => void = () => {}) {
        $modal.open(modalSendLinkToCustomer, {
            name: 'modalSendLinkToCustomer',
            passedData: {
                title: 'Send Link to Checklist',
                linkNoun: 'checklist',
                generateLink: async () => {
                    const response = await api.checklist.fimenu_getLink(settings.appDomain, fimenu.id, forWhom);
                    return response.data;
                },
                abilityToSendCodeToGM: false
            },
            postFunction: async (sendMethod: 'email'|'text') => {
                isBusyCallback(true);

                try {
                    const customerPerson = (forWhom === ENUMS.PERSON_TYPES.COCUSTOMER) ? fimenu.coCustomer : fimenu.customer;
                    const phoneNumber = (sendMethod === 'text') ? customerPerson.cell : undefined;
                    const emailAddress = (sendMethod === 'email') ? customerPerson.email : undefined;
                    
                    const response = await api.checklist.fimenu_sendLink(settings.appDomain, fimenu.storeCode, fimenu.id, forWhom, phoneNumber, emailAddress);
                    if (!response?.data?.success)
                        throw new Error('Error sending checklist link', { cause: response });

                    util.toastr('success', 'Successfully Sent Link', 'Checklist link has been successfully sent to customer.');
                }
                catch (err) {
                    console.error(`ERROR SENDING LINK AS ${sendMethod}: `, err);
                    util.toastr('error', 'Error Sending Checklist Link', 'Unable to send checklist link due to server error.');
                }
                finally {
                    isBusyCallback(false);
                }
            }
        });
    };

    /**
     * If a customer has already completed an IDV session for this checklist item, will submit a retry request for the customer's IDV session. This will reset the status
     * of this checklist item and allow the customer to complete the IDV session again.
     *
     * @export
     * @param {DealChecklistItem} checklistItem Only applicable to checklist items with type DealChecklistType.LaunchPlaid_IDV.
     * @param {string} storeCode The code of the store initiating the retry request.
     * @param {(isBusy: boolean) => void} [isBusyCallback=null] An optional callback that will be called before and after the API call. Can be used to track when the API
     * call finishes processing.
     * @return {Promise<boolean>} True if the IDV session has been successfully reset. False if otherwise.
     */
    static async resetPlaidIDV(checklistItem: DealChecklistItem, storeCode: string, isBusyCallback: (isBusy: boolean) => void = () => {}): Promise<boolean> {
        if (!checklistItem.canResetPlaidIDV()) {
            util.toastr('error', 'Invalid Action', 'Cannot reset this checklist item.');
            return false;
        }

        isBusyCallback(true);

        try {
            const idvResponse = checklistItem.getPlaidData().identityVerification.response;
            const idvAssociations = checklistItem.getPlaidData().associations;
            
            const response = await api.plaid.retryIdentityVerification(idvResponse.template.id, idvResponse.clientUserId, idvResponse.user, storeCode, idvAssociations);
            if (!response?.data || response.data.error)
                throw new Error('Error Resetting Plaid IDV Session', { cause: response?.data?.error || response });

            util.toastr('success', 'Success', 'Successfully reset Plaid session.');
            return true;
        }
        catch (err) {
            console.error('ERROR WHEN RESETTING IDV: ', err);
            util.toastr('error', 'Error Resetting IDV Session', 'An error occurred when trying to reset the IDV session.');
            return false;
        }
        finally {
            isBusyCallback(false);
        }
    };

    /**
     * Will upload a manual verification of a checklist item to Elastic. 
     *
     * @static
     * @param {boolean} isApproved Whether this item was manually accepted or manually rejected. 
     * @param {DealChecklistItem} checklistItem The checklist item you would like to submit a manual verification for
     * @param {(isBusy: boolean) => void} [isBusyCallback=() => {}]  An optional callback that will be called before and after the API call. Can be used to track when the API
     * call finishes processing.
     * @return {*}  {Promise<DealChecklistItem>} Returns the updated version of this checklist item.
     * @memberof ChecklistHelper
     */
    static async addManualVerification(isApproved: boolean, checklistItem: DealChecklistItem, isBusyCallback: (isBusy: boolean) => void = () => {}): Promise<DealChecklistItem> {
        const userData: CamelKeysToPascalDeep<AccessEmployeeClass> = auth.getTokenPayload();
        const userPermissions = EmployeePermissions.createFromPascalKeys(userData.EmployeeAccess)?.toFlags() ?? SaturnPermissions.None;

        isBusyCallback(true);

        try {
            // TODO: Right now, can only apply manual verification on uploaded documents. This logic will be an issue in the future if that ever changes.
            const doc = checklistItem.getDocument();
            if (doc) {
                const verification: IDocumentVerification = {
                    isVerified: isApproved,
                    verifiedBy: { employeeName: userData.EmployeeName, employeeNumber: userData.EmployeeCode },
                    verifierPermissions: userPermissions,
                    timestamp: new Date().toISOString()
                }
                
                checklistItem.addManualVerification(verification);

                const verificationResponse = await api.documents.addDocumentVerification(doc.id, verification);
                if (!verificationResponse?.data)
                    throw new Error('Error Saving Verification', { cause: verificationResponse });

                util.toastr('success', 'Success', 'Verification successfully saved.');
            }

            return checklistItem;
        }
        catch (err) {
            console.error('ERROR SAVING MANUAL VERIFICATION: ', err);
            util.toastr('error', 'Error Saving Verification', (err as Error)?.message ?? 'Unable to save manual verification due to server error.');
        }
        finally {
            isBusyCallback(false);
        }

        return null;
    }
}