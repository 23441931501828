<template>
    <div class="modal-container modal-small">
        <div class="modal-header">Print Payment Sheet</div>
        <div class="modal-body">
            <div>
                <div>Select Coverage Terms (Max 3)</div>
            </div>
            <div class="no-margin-grid">
                <div class="selectable-list margin-bottom-5" :style="$grid('1-1-1')">
                    <div v-for="t in terms" :key="t.term">
                        <div @click="toggleItem(t)" class="selectable-item">
                            <span class="imsd-checkbox icon-pad-right" :class="{'unchecked-checkbox': !t.isSelected}"><i class="fas fa-check"></i></span>
                            <span class="unselectable">{{ t.term === 0 ? "Custom Coverage" : t.term + ' Month' }}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <button @click="downloadPaymentSheet()" :disabled="isBusy || !(numberOfSelectedItems > 0)">
                        <span v-show="!isBusy">Download Payment Sheet</span>
                        <span v-show="isBusy"><i class="fas fa-cog fa-spin"></i></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="button-unwind" @click="cancel()">Close</button>
        </div>
    </div>
</template>


<script>
    import moment from 'moment'
    import $modal from '@core/services/modal'
    import api from '@core/services/api'
    import FIMenuHelper from '@core/classes/FIMenuHelper'
    import FIMenuCustomerViewed from '@core/classes/FIMenuCustomerViewed'

    export default {
        name: "modalPrintPaymentSheet",
        props: ['modal'],
        data() {
            return {
                isBusy: false,
                fimenu: null,
                terms: null
            };
        },
        computed: {
            api() {
                return api;
            },
            numberOfSelectedItems() {
                return this.terms.filter(i => i.isSelected).length;
            }
        },
        created() {
            this.init();
        },

        methods: {
            init() {
                this.fimenu = this.modal.fimenu;

                if (this.fimenu.dealType == 'Lease') {

                    const leaseTerms = this.fimenu.leaseTerms.availableTerms();

                    this.terms = this.fimenu.coverageTerms
                        .filter(coverageTerm => coverageTerm.term != 0)
                        .filter(coverageTerm => leaseTerms.some(leaseTerm => this.showLeaseTermCalculation(coverageTerm, leaseTerm)))
                        .map(ct => ({ isSelected: false, coverageTerm: ct, term: ct.term }));
                }
                else {
                    this.terms = this.fimenu.coverageTerms.filter(coverageTerm => coverageTerm.term != 0).map(ct => ({ isSelected: false, coverageTerm: ct, term: ct.term }));
                }


            },
            toggleItem(item) {
                if (!item.isSelected && this.numberOfSelectedItems < 3)
                    item.isSelected = true;
                else
                    item.isSelected = false;
            },
            async downloadPaymentSheet() {
                this.isBusy = true;
                const termsSelected = this.terms.filter(t => t.isSelected).map(t => t.term);
                const whatWeArePassingUp = {
                    fimenu: this.fimenu,
                    coverageTerms: termsSelected
                };

                await api.fimenu.printPaymentSheet(whatWeArePassingUp, `paymentSheet_${this.fimenu.dealNumber}.pdf`);
                this.isBusy = false;

                this.fimenu.customerViewed.push(new FIMenuCustomerViewed({ viewedType: 11, viewedDateTime: moment() })); // 11 = PaymentSheetPrinted


            },
            showLeaseTermCalculation(coverageTerm, leaseTerm) {
                return FIMenuHelper.showLeaseTermCalculation(coverageTerm, leaseTerm);
            },
            cancel() {
                $modal.close(true);
            }
        },
        components: {

        }
    };
</script>
<style>

</style>
