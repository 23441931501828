<template>
    <div class="modal-container modal-medium">
        <div class="modal-header">
            🎥 Camera and Microphone Access Required for Meetings 🎥
        </div>
        <div class="modal-body">
                <p v-if="!declinedTextShowing"><strong>Please Allow Camera and Microphone Access in Order to Proceed to Meeting</strong></p>

                <div v-else-if="declinedTextShowing">
                    <p>We would like to inform you that our website requires access to your camera and microphone during meetings for legal compliance reasons. This access enables us to accurately document interactions and maintain compliance with legal requirements.</p>
                    <p>Please be assured that your privacy is our utmost priority. Your camera and microphone feed are used solely for meeting facilitation purposes and are not shared with any third parties.</p>
                    <p>Should you have any questions or concerns regarding this matter, please do not hesitate to contact our support team for assistance.</p>
                    <p>Thank you for your cooperation and understanding.</p>
                </div>
        </div>

        <div class="modal-footer">
            <!-- <button @click="cancel()" class="button-unwind">Cancel</button> -->
            <button @click="toggleDeclinedText" class="button button-unwind">Decline</button>
            <button @click="accept()" class="button button-save">Accept</button>
        </div>
    </div>

</template>

<script>
    import $modal from '@core/services/modal'
    export default {
        name: 'modalCameraMicPermission',
        props: ['modal'],
        data() {
            return {
                declinedTextShowing: false,
            }
        },
        mounted() {
            this.$watch(() => this.meetingHelper.cameraAccessAllowed, function (newValue) {
                if (newValue) {
                    $modal.close();
                }
            }, { immediate: true });
        },
        methods: {
            toggleDeclinedText() {
                this.declinedTextShowing = !this.declinedTextShowing
            },
            accept() {
                this.meetingHelper.cameraAccessAcknowledged = true
                if (this.meetingHelper.cameraAccessAllowed) {
                    $modal.close();
                }
            }
        },

    }
</script>

<style scoped>

    p {
        font-size: 16px;
    }


</style>