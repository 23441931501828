<template>
    <div class="modal-container modal-large">
        <div class="modal-header">
            {{ modal.passedData.mode }} Rebate Details
        </div>
        <div class="modal-body">
            <div :style="$grid('1')">
                <Panel title="Basic Info">
                    <div :style="$grid('1-.75-3.25', '5px 10px')">
                        <InputTextbox label="Program Code"
                                      :disabled="modal.passedData.mode === 'Edit'"
                                      :invalid="v$.rebate.code.$invalid"
                                      v-model:saturn="rebate.code"
                                      placeholder="Program Code">
                            <template #validation>
                                <div v-if="v$.rebate.code.required.$invalid">Program Code is required.</div>
                                <div v-if="v$.rebate.code.uniqueRebateCode.$invalid">Program Code must be unique.</div>
                            </template>
                        </InputTextbox>
                        <InputTextbox label="Identifier"
                                      v-model:saturn="rebate.identifier"
                                      placeholder="Identifier">
                        </InputTextbox>
                        <InputTextbox label="Description"
                                      :invalid="v$.rebate.desc.$invalid"
                                      v-model:saturn="rebate.desc"
                                      placeholder="Description">
                            <template #validation>
                                <div v-if="v$.rebate.desc.required.$invalid">Description is required.</div>
                            </template>
                        </InputTextbox>
                    </div>
                    <div :style="$grid('1-3')">
                        <div :style="$grid('1')">
                            <InputRichDropdown label="Amount Type"
                                           v-model:saturn="rebate.amountType"
                                           :invalid="v$.rebate.amountType.$invalid"
                                           :list="amountTypes"
                                           :valueMap="(a) => a.code"
                                           :display="(a) => a.description"
                                           nothingSelected="-- Select Value --"
                                           @change="updateRebateTypeDropdown()">
                                <template #validation>
                                    <div v-if="v$.rebate.amountType.required.$invalid">Amount Type is required.</div>
                                </template>
                            </InputRichDropdown>
                            <InputCurrency v-if="isCurrency"
                                           v-model:saturn="rebate.amount"
                                           :invalid="v$.rebate.amount.$invalid"
                                           label="Rebate Amount"
                                           placeholder="Rebate Amount"
                                           :precision="2">
                                <template #validation>
                                    <div v-if="v$.rebate.amount.required.$invalid">Amount is required.</div>
                                </template>
                            </InputCurrency>
                            <InputNumber v-if="!isCurrency"
                                         label="Rate"
                                         :invalid="v$.rebate.amount.$invalid"
                                         v-model:saturn="rebate.amount"
                                         placeholder="Rebate Rate"
                                         :precision="isPercentage ? 2 : 5"
                                         :isPercent="isPercentage">
                                <template #validation>
                                    <div v-if="v$.rebate.amount.required.$invalid">Amount is required.</div>
                                </template>
                            </InputNumber>
                            <InputRichDropdown label="Rebate Type"
                                           v-model:saturn="rebate.rebateType"
                                           :invalid="v$.rebate.rebateType.$invalid"
                                           nothingSelected="-- Select Value --"
                                           :list="rebateTypes"
                                           :valueMap="(a) => a.code"
                                           :display="(a) => a.description">
                                <template #validation>
                                    <div v-if="v$.rebate.rebateType.required.$invalid">Rebate Type is required.</div>
                                    <div v-if="v$.rebate.rebateType.isValidRebateType.$invalid">Rebate Type must be valid.</div>
                                </template>
                            </InputRichDropdown>
                        </div>
                        <InputTextbox label="Qualification"
                                      v-model:saturn="rebate.qualification"
                                      :rows="8"
                                      :multiline="true">
                        </InputTextbox>
                    </div>

                </Panel>
                <Panel title="Rebate Applies To (select all that apply)">
                    <div :style="$grid('1-1-1-1')">
                        <InputCheckbox v-model="rebate.isAllDeals" label="All deals?"></InputCheckbox>
                        <InputCheckbox v-model="rebate.isCash" label="Cash deals?" :disabled="rebate.isAllDeals"></InputCheckbox>
                        <InputCheckbox v-model="rebate.isLease" label="Lease deals?" :disabled="rebate.isAllDeals"></InputCheckbox>
                        <InputCheckbox v-model="rebate.isFinance" label="Finance deals?" :disabled="rebate.isAllDeals"></InputCheckbox>
                    </div>
                    <div :style="$grid('1-1-1-1')">
                        <InputCheckbox v-model="rebate.isSubventedLease" label="Subvented Lease?" :disabled="(!rebate.isAllDeals && !rebate.isLease)"></InputCheckbox>
                        <InputCheckbox v-model="rebate.isNonSubventedLease" label="Non-Subvented Lease?" :disabled="(!rebate.isAllDeals && !rebate.isLease)"></InputCheckbox>
                        <InputCheckbox v-model="rebate.isSubventedFinance" label="Subvented Finance?" :disabled="(!rebate.isAllDeals && !rebate.isFinance)"></InputCheckbox>
                        <InputCheckbox v-model="rebate.isNonSubventedFinance" label="Non-Subvented Finance?" :disabled="(!rebate.isAllDeals && !rebate.isFinance)"></InputCheckbox>
                    </div>
                </Panel>
                <Panel title="Rebate Terms (if applicable)" class="panel-rebate-terms no-margin-grid local-panel-body">
                    <template v-slot:header>
                        <div class="panel-buttons">
                            <button class="button panel-button button-edit" @click="addEditTerm(null, 'Add')"><i class="fas fa-plus"></i> Add Term</button>
                        </div>
                    </template>
                    <div class="richtable-container">
                        <div v-if="rebate.rebateTerms && rebate.rebateTerms.length > 0">
                            <RichTable :tableData="rebate.rebateTerms" :headers="headers" />
                        </div>
                        <p v-else class="empty-list">There are currently no rebates terms for this rebate.</p>
                    </div>
                </Panel>
            </div>
        </div>
        <div class="modal-footer">
            <div>
                <button class="button-unwind" @click="cancel()">Cancel</button>
                <button class="button-save" @click="save()" :disabled="v$.$invalid">Save</button>
            </div>

        </div>
    </div>
</template>


<script>
    import $modal from '@core/services/modal'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import InputCurrency from '@core/components/InputCurrency.vue'
    import InputCheckbox from '@core/components/InputCheckbox.vue'
    import InputNumber from '@core/components/InputNumber.vue'
    import RichTable from '@core/components/RichTable.vue'
    import Panel from '@core/components/Panel.vue'
    import { required, between, minValue, maxValue } from '@vuelidate/validators'
    import { useVuelidate } from '@vuelidate/core';
    //import moment from 'moment'
    import util from '@core/services/util'
    import ENUMS from "@core/classes/Enums"


    export default {
        name: "modalAddEditRebate",
        props: ['modal'],
        setup(){
            // New setup for vuelidate. Swap $v with v$
            return {v$: useVuelidate({$scope: false})}
        },
        data() {
            return {
                rebate: null,
                amountTypes: [
                    { code: 0, description: 'Currency' },
                    { code: 1, description: 'Percentage' },
                    { code: 2, description: 'Money Factor' },
                ],
                rebateTypes: [
                    { code: 0, description: 'Consumer Cash'},
                    { code: 1, description: 'Dealer Cash'},
                    { code: 2, description: 'Money Factor'},
                    { code: 3, description: 'Residual'},
                    { code: 4, description: 'APR'},
                ],
            }
        },
        computed: {
            isCurrency() {
                return !this.rebate.amountType;
            },
            isPercentage() {
                return this.rebate.amountType === 1;
            },
            isMoneyFactor() {
                return this.rebate.amountType === 2;
            },
            ENUMS() {
                return ENUMS;
            },
            util() {
                return util;
            },
        },
        validations() {

            const uniqueRebateCode = (rebateCode, rebate) => {

                const result = !this.modal.passedData.rebates.some(r => rebateCode === r.code);
                return result;
            }
            const isValidRebateType = (rebateType, rebate) => {
                const result = (rebate.amountType === 0 && (rebateType === 0 || rebateType === 1)) || (rebate.amountType === 1 && (rebateType === 3 || rebateType === 4)) || (rebate.amountType === 2 && rebateType === 2);
                return result;
            }
            const validations = {
                rebate: {
                    desc: {
                        required
                    },
                    code: {
                        required
                    },
                    amount: {
                        required,
                        minValue: minValue(.00001)
                    },
                    amountType: { required },
                    rebateType: { required, isValidRebateType },
                }
            };
            if (this.modal.passedData.mode === 'Add')
                validations.rebate.code = { required, uniqueRebateCode };
            return validations;
        },
        created() {
            if (this.modal.passedData.mode === 'Add') {
                this.rebate = {
                    code: '',
                    amount: 0,
                    desc: '',
                    qualification: '',
                    source: 'Manual',
                    amountType: null,
                    rebateType: null,
                    isAllDeals: false,
                    isCash: false,
                    isLease: false,
                    isFinance: false,
                    isSubventedLease: false,
                    isNonSubventedLease: false,
                    isSubventedFinance: false,
                    isNonSubventedFinance: false
                }
            } else {
                this.rebate = util.clone(this.modal.passedData.rebate);
            }
            // eslint-disable-next-line no-console
            console.log('this.rebate after', this.rebate)

        },
        methods: {
            addEditTerm(row, mode) {
                // TODO:
                //either add a new row or edit the existing row
                // eslint-disable-next-line no-console
                console.log('addEditTerm', row, mode);
            },
            updateRebateTypeDropdown() {
                this.rebateTypes.forEach((r) => {
                    r.disabled = false;
                    if (this.rebate.amountType === 0) {
                        //currency amount type
                        //set anything that's not cash to disabled
                        if (r.code > 1) r.disabled = true;
                    }
                    if (this.rebate.amountType === 1) {
                        //Percentage amount type
                        //set anything that's not percentage to disabled
                        if (r.code < 3) r.disabled = true;
                    }
                    if (this.rebate.amountType === 2) {
                        //Money Factor amount type
                        //set anything that's not money factor to disabled
                        if (r.code != 2) r.disabled = true;
                    }
                })
            },
            cancel() {
                // eslint-disable-next-line no-console
                console.log('cancel');
                $modal.cancel();
            },
            save() {
                $modal.close(this.rebate);
            }
        },
        components: {
            InputTextbox: InputTextbox,
            InputRichDropdown: InputRichDropdown,
            Panel: Panel,
            InputNumber: InputNumber,
            InputCurrency: InputCurrency,
            InputCheckbox: InputCheckbox,
            RichTable: RichTable,
        }
    };
</script>
<style>
    .panel-rebate-terms {
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
        .panel-rebate-terms .richtable-container {
            width: 100%;
            height: 100%;
            overflow-y: auto;
        }

        .panel-rebate-terms .empty-list {
            text-align: center;
            position: relative;
        }
        .panel-rebate-terms .local-panel-body {
            height: 100%;
        }

</style>
