<template>
    <div class="popup-selector-container">
        <slot></slot>
        <h1 class="popup-selector-title">{{title}}</h1>
        <div class="popup-selector-buttons-container">
            <button :key="option.value" v-for="option in data" :class="{'popup-selector-button': true, 'completed': option.disabled}" @click="() => onSelect(option.value)" >
                <i class="fas fa-user popup-icon" v-if="!option.disabled"></i>
                <i class="fas fa-check popup-icon" v-else></i>

                <div class="popup-button-labels-container">
                    <span v-for="(label, index) of option.label" :key="index"> {{ label.toUpperCase( ) }}</span>
                </div>

                <span class="popup-selector-button-subtitle">{{ option.description }}</span>
            </button>
        </div>
    </div>
</template>

<!--
    Intake data sample (options)
    [{label: 'Melvin del orbe1', description: ["Customer"],  value: 1}, {label: 'Melvin del orbe2', description: ["Co-Customer"],  value: 1}]
 -->

<script>
    export default {
        name: "PopupSelector",
        props: ['data', "onSelect", "title"],
        data() {
            return {
                windowRef: null,
                gotOpened: false,
                options: [],

            };
        },
    };
</script>
<style>

</style>