<template>
    <Panel v-bind="panelObject" v-if="!data.buyersOrderEnabled || $global.isAdminView">
        <div class="final-payment-info">
            <div class="titles" :style="$grid((data.dealType == 'Finance' ? '1-2-2-3-2-3-1' : data.dealType == 'Lease' ? '1-2-3-2-3' : '1-2-2'),'5px 10px') ">
                <span class="boldedspan"></span>
                <span class="boldedspan text-right">OTD</span>
                <span class="boldedspan text-right" v-if="data.dealType == 'Finance'">Doc Stamps</span>
                <span class="boldedspan text-right">OTD w/Products</span>
                <span class="boldedspan text-right" v-if="data.dealType != 'Cash'">Base Payment</span>
                <span class="boldedspan text-right" v-if="data.dealType != 'Cash'">Payment w/Products</span>
                <span class="boldedspan text-right" v-if="data.dealType == 'Finance'">Days to 1st Payment</span>
            </div>
            <div class="saturn-values" :style="$grid((data.dealType == 'Finance' ? '1-2-2-3-2-3-1' : data.dealType == 'Lease' ? '1-2-3-2-3' : '1-2-2'),'5px 10px') " v-if="!(settings.environmentName === 'PRODUCTION')">
                <span class="boldedspan">Saturn</span>
                <span class="displaypricebold">{{ formatPrice(finalNumbers.baseAmount) }}</span>
                <span class="displaypricebold" v-if="data.dealType == 'Finance'">{{ formatPrice(finalNumbers.docStamps) }}</span>
                <span class="displaypricebold">{{ formatPrice(finalNumbers.finalAmount) }}</span>
                <span class="displaypricebold" v-if="data.dealType != 'Cash'">{{ formatPrice(finalNumbers.basePayment) }}</span>
                <span class="displaypricebold" v-if="data.dealType != 'Cash'">{{ formatPrice(finalNumbers.finalPayment) }}</span>
            </div>
            <div class="cdk-values" :style="$grid((data.dealType == 'Finance' ? '1-2-2-3-2-3-1' : data.dealType == 'Lease' ? '1-2-3-2-3' : '1-2-2'),'5px 10px')">
                <span class="boldedspan">CDK</span>
                <span class="displaypricebold">{{ formatPrice(finalNumbersCDK.baseAmount) }}</span>
                <span class="displaypricebold" v-if="data.dealType == 'Finance'">{{ formatPrice(finalNumbersCDK.docStamps) }}</span>
                <div>
                    <InputCurrency v-model:saturn="data.otdFinal"
                                   placeholder="Final OTD"
                                   :invalid="util.isNull(validation) ? false : validation.otdFinal.$invalid"
                                   :precision="2"
                                   :negative="true"
                                   iconSide="left"
                                   @blur="recalculateReserveProfits"
                                   :disabled="data.hasPaperworkPacket() || isUpdatingReserve">
                        <template #validation>
                            <div v-if="!util.isNull(validation) && validation.otdFinal.required?.$invalid">Final OTD is required</div>
                            <div v-if="!util.isNull(validation) && validation.otdFinal.between?.$invalid && !(data.isCash() || data.isDraft())">Final OTD doesn't match calculations.</div>
                        </template>
                    </InputCurrency>
                </div>
                <span class="displaypricebold" v-if="data.dealType != 'Cash'">{{ formatPrice(finalNumbersCDK.basePayment) }}</span>
                <div v-if="data.dealType != 'Cash'">
                    <InputCurrency v-model:saturn="data.loanTerms.finalMonthlyPayment"
                                   placeholder="Final Payment Amount"
                                   :invalid="util.isNull(validation) ? false : validation.loanTerms.finalMonthlyPayment.$invalid"
                                   :precision="2"
                                   iconSide="left"
                                   :disabled="data.hasPaperworkPacket()"
                                   v-if="data.dealType == 'Finance'">
                        <template #validation>
                            <div v-if="!util.isNull(validation) && validation.loanTerms.finalMonthlyPayment.required?.$invalid">Payment w/Products is required</div>
                            <div v-if="!util.isNull(validation) && validation.loanTerms.finalMonthlyPayment.between?.$invalid">Payment doesn't match calculations.</div>
                        </template>
                    </InputCurrency>
                    <InputCurrency v-model:saturn="data.leaseTerms.finalMonthlyPayment"
                                   placeholder="Final Payment Amount"
                                   :invalid="util.isNull(validation) ? false : validation.leaseTerms.finalMonthlyPayment.$invalid"
                                   :precision="2"
                                   iconSide="left"
                                   :disabled="data.hasPaperworkPacket()"
                                   v-if="data.dealType == 'Lease'">
                        <template #validation>
                            <div v-if="!util.isNull(validation) && validation.leaseTerms.finalMonthlyPayment.required?.$invalid">Payment w/Products is required</div>
                            <div v-if="!util.isNull(validation) && validation.leaseTerms.finalMonthlyPayment.between?.$invalid">Payment doesn't match calculations.</div>
                        </template>
                    </InputCurrency>
                </div>
                <span v-if="data.dealType == 'Finance'" class="displaypricebold"> {{ data.loanTerms.selectedDaysToFirstPayment }}</span>
            </div>
        </div>
    </Panel>
</template>

<script>
    import CoverageTerm from '@core/classes/CoverageTerm'
    import FIMenuPaymentAmount from '@core/classes/FIMenuPaymentAmount'
    import InputCurrency from '@core/components/InputCurrency.vue'
    import LenderHelper from '@core/helpers/lender-helper'
    import Panel from '@core/components/Panel.vue'
    import settings from 'settings'
    import util from '@core/services/util'

    export default {
        name: "PanelFinalInfo",
        emits: ['update'],
        props: ['data', 'panelObject', 'validation'],
        data() {
            return {
                util: null,
                fimenu: null,
                isUpdatingReserve: false,
            }
        },
        computed: {
            settings() { return settings; },
            finalNumbers() {
                return this.getFinalNumbers(false)
            },
            finalNumbersCDK() {
                if (this.data.buyersOrderEnabled) return this.finalNumbers

                return this.getFinalNumbers(true)
            }
        },
        created() {
            this.util = util
        },

        methods: {
            formatPrice(value) {
                if (value != null && typeof value !== 'undefined' && !Number.isNaN(parseFloat(value))) {
                    return '$' + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,").toString()
                } else {
                    return '$0.00';
                }
            },
            getFinalNumbers(isFinal) {
                const fimenu = this.data;
                const state = this.$global.selectedStore.storeState;
                const leaseOrLoanTerm = fimenu.dealType == 'Finance' ? fimenu.loanTerms.getAcceptedTerm() : fimenu.leaseTerms.getAcceptedTerm();
                const ct = fimenu.coverageTerms.filter(c => c.selected == true)[0];
                const coverageTerm = new CoverageTerm(ct);
                return fimenu.finalNumbers(state, leaseOrLoanTerm, coverageTerm, isFinal);
            },
            async recalculateReserveProfits() {
                if (!this.data.otdFinal) {
                    this.data.nonBuyersOrderFinalNumbers.reserveProfitSplit = null;
                    this.data.nonBuyersOrderFinalNumbers.reserveProfitFlat = null;
                    return
                }
                //Reset profit values before reassigning
                if (this.data.dealType !== 'Cash') {
                    this.data.nonBuyersOrderFinalNumbers.previousReserveToShow = this.data.nonBuyersOrderFinalNumbers.reserveProfitSplit > this.data.nonBuyersOrderFinalNumbers.reserveProfitFlat ? this.data.nonBuyersOrderFinalNumbers.reserveProfitSplit : this.data.nonBuyersOrderFinalNumbers.reserveProfitFlat
                    this.data.nonBuyersOrderFinalNumbers.reserveProfitSplit = null;
                    this.data.nonBuyersOrderFinalNumbers.reserveProfitFlat = null;

                    this.fimenu = this.data;
                    const state = this.$global.selectedStore.storeState;
                    const leaseOrLoanTerm = this.fimenu.dealType == 'Finance' ? this.fimenu.loanTerms.getAcceptedTerm() : this.fimenu.leaseTerms.getAcceptedTerm();
                    const ct = this.fimenu.coverageTerms.filter(c => c.selected == true)[0];
                    const coverageTerm = new CoverageTerm(ct);
                    const finalNumbers = this.fimenu.finalNumbers(state, leaseOrLoanTerm, coverageTerm, true);
                    let dealTerm;
                    if (this.data.leaseTerms.acceptedTerm !== null) {
                        dealTerm = this.data.leaseTerms['term' + this.data.leaseTerms.acceptedTerm].term
                    } else if (this.data.loanTerms.acceptedTerm !== null) {
                        dealTerm = this.data.loanTerms['term' + this.data.loanTerms.acceptedTerm].term
                    }

                    const newPaymentAmount = [new FIMenuPaymentAmount({
                        amountFinanced: finalNumbers.finalAmount,
                        coverageTerm: coverageTerm.term,
                        dealTerm: dealTerm,
                        sellRate: leaseOrLoanTerm.sellrate,
                        paymentAtSellRate: finalNumbers.finalPayment,
                        paymentAtSellRateWithTax: finalNumbers.finalPayment,
                        buyRate: leaseOrLoanTerm.buyrate,
                        paymentAtBuyRate: finalNumbers.finalPaymentAtBuyRate,
                        paymentAtBuyRateWithTax: finalNumbers.finalPaymentAtBuyRate,
                        isFinal: true
                    })];

                    this.isUpdatingReserve = true;
                    const finalPaymentAmount = await LenderHelper.getReserveProfitResults(this.fimenu, this.$global.Lenders, this.fimenu.lender.lenderCode, newPaymentAmount);
                    this.isUpdatingReserve = false;
                    this.$emit('update', true)
                    setTimeout(() => {
                        this.$emit('update', false)
                    }, 2500)
                    const reserveProfitSplit = finalPaymentAmount[0]?.reserveProfitSplit ?? null;
                    const reserveProfitFlat = finalPaymentAmount[0]?.reserveProfitFlat ?? null;

                    if (reserveProfitSplit !== null) {
                        this.data.nonBuyersOrderFinalNumbers.reserveProfitSplit = Number(reserveProfitSplit.toFixed(2));
                    }
                    if (reserveProfitFlat !== null) {
                        this.data.nonBuyersOrderFinalNumbers.reserveProfitFlat = Number(reserveProfitFlat.toFixed(2));
                    }

                    this.data.setReserveProfit();
                }
            },
        },
        components: {
            Panel: Panel,
            InputCurrency: InputCurrency
        }
    };
</script>