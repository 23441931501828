<template>
    <div class="fimenuvideoplayerpage" id="scaleContainer">
        <div class="deal-information">
            <div class="deal-information-vin"><span>{{ fimenu.vin }}</span></div>
            <div class="deal-information-vehicle"><span>{{ fimenu.vehicle.fullName() }}</span></div>
        </div>
        <div class="video-presentation" v-show="!startPresentation">
            <button class="button button-begin-presentation" @click="startVideoPresentation()">
                <i class="fas fa-play"></i>
            </button>
        </div>
        <div v-if="isBusy">
            <div>Loading</div>
            <div>
                <i class="fas fa-circle-notch fa-spin fa-4x"></i>
            </div>
        </div>
        <div class="video-group" v-if="startPresentation">
            <div class="video-container">
                <div class="video-player-container">
                    <div class="video-overlays-background" v-show="anyOverlaysShown()"></div>
                    <div class="video-player">
                        <div v-if="currVideo.overlays">
                            <component v-for="overlay in currVideo.overlays.filter(o => o.component)" :key="overlay.uid" :style="overlay.style" :is="overlay.component" v-bind="overlay.componentProps" :overlay="overlay" :video="currVideo" v-show="overlay.show" class="overlay-item" />
                        </div>
                        <video class="vid_player"
                               controls
                               :id="vid"
                               :name="vid"
                               :src="'/dist/vid/videosWithCaptions/' + currVideo.fileName"
                               oncontextmenu="return false;"
                               disablePictureInPicture
                               controlsList="nodownload noplaybackrate"
                               ref="videoRef"></video>

                    </div>
                </div>
            </div>

            <div class="overlay-container">
                <img src="@static/vid/overlay/images/English/CLIMATE-NO.png" />
                <img src="@static/vid/overlay/images/English/CLIMATE-YES.png" />
                <img src="@static/vid/overlay/images/English/CLIMATE.png" />
                <img src="@static/vid/overlay/images/English/COST-NO.png" />
                <img src="@static/vid/overlay/images/English/COST-YES.png" />
                <img src="@static/vid/overlay/images/English/COST.png" />
                <img src="@static/vid/overlay/images/English/DRIVING-NO.png" />
                <img src="@static/vid/overlay/images/English/DRIVING-YES.png" />
                <img src="@static/vid/overlay/images/English/DRIVING.png" />
                <img src="@static/vid/overlay/images/English/LIABILITY-NO.png" />
                <img src="@static/vid/overlay/images/English/LIABILITY-YES.png" />
                <img src="@static/vid/overlay/images/English/LIABILITY.png" />
                <img src="@static/vid/overlay/images/English/MAINTENANCE-NO.png" />
                <img src="@static/vid/overlay/images/English/MAINTENANCE-YES.png" />
                <img src="@static/vid/overlay/images/English/MAINTENANCE.png" />
                <img src="@static/vid/overlay/images/English/ROAD-NO.png" />
                <img src="@static/vid/overlay/images/English/ROAD-YES.png" />
                <img src="@static/vid/overlay/images/English/ROAD.png" />

                <img src="@static/vid/overlay/images/Spanish/CLIMATE-NO.png" />
                <img src="@static/vid/overlay/images/Spanish/CLIMATE-YES.png" />
                <img src="@static/vid/overlay/images/Spanish/CLIMATE.png" />
                <img src="@static/vid/overlay/images/Spanish/COST-NO.png" />
                <img src="@static/vid/overlay/images/Spanish/COST-YES.png" />
                <img src="@static/vid/overlay/images/Spanish/COST.png" />
                <img src="@static/vid/overlay/images/Spanish/DRIVING-NO.png" />
                <img src="@static/vid/overlay/images/Spanish/DRIVING-YES.png" />
                <img src="@static/vid/overlay/images/Spanish/DRIVING.png" />
                <img src="@static/vid/overlay/images/Spanish/LIABILITY-NO.png" />
                <img src="@static/vid/overlay/images/Spanish/LIABILITY-YES.png" />
                <img src="@static/vid/overlay/images/Spanish/LIABILITY.png" />
                <img src="@static/vid/overlay/images/Spanish/MAINTENANCE-NO.png" />
                <img src="@static/vid/overlay/images/Spanish/MAINTENANCE-YES.png" />
                <img src="@static/vid/overlay/images/Spanish/MAINTENANCE.png" />
                <img src="@static/vid/overlay/images/Spanish/ROAD-NO.png" />
                <img src="@static/vid/overlay/images/Spanish/ROAD-YES.png" />
                <img src="@static/vid/overlay/images/Spanish/ROAD.png" />
            </div>
        </div>


        <!--<div v-if="currVideo">{{currVideo.fileName}}</div>-->
        <!--<div>
            <button @click="startVideoPresentation()">Restart</button>
        </div>-->
        <!--<div>
            <pre>{{this.currVideo}}</pre>
        </div>-->
    </div>
</template>


<script>

    import ButtonsOverlay from '@/components/fimenu/overlays/buttons-overlay.vue'
    import ImagesOverlay from '@/components/fimenu/overlays/image-overlay.vue'
    import ClickableImageOverlay from '@/components/fimenu/overlays/clickable-image-overlay.vue'

    import VideoOverlay from '@core/classes/VideoOverlay'
    import FIMenuVideoList from '@core/classes/FIMenuVideoList'
    import FIMenuCustomerViewed from '@core/classes/FIMenuCustomerViewed'
    import moment from 'moment'
    import settings from 'settings'

    export default {
        name: "FIMenuVideoPlayer",
        props: ['fimenu', 'sectionName', 'language', 'goToSection', 'customerViewAlert', "getWindowRef"],
        data() {
            return {
                startPresentation: false,
                isBusy: false,
                currVideo: null,
                videoList: null,
                eventHandlers: {
                    timeupdate: null,
                    seeking: null,
                    ended: null
                },
                video: null,
                vid: null,
                development: null,
                firstVideoViewed: false
            };
        },
        mounted() {
            this.vid = 'vid' + this._uid;
        },
        computed: {
            settings() {
                return settings;
            }
        },
        created() {
            this.$watch(() => this.customerViewAlert, (newValue) => {
                if (this.currVideo) {
                    if (newValue.noExist || newValue.noFullScreen || newValue.noVisible) {
                        this.video.pause()
                    } else {
                        this.video.play()
                    }
                }
            }, { deep: true })
            this.$watch(() => this.startPresentation, function (newValue) {
                if (newValue == true) {
                    this.$nextTick(() => {
                        this.setupVideoListeners();
                    })
                }
            }, { deep: true });
        },
        methods: {
            anyOverlaysShown() {
                if (this.currVideo && this.currVideo.overlays && this.currVideo.overlays.length > 0)
                    return this.currVideo.overlays.some(o => o.component && (o.component.name == 'clickable-div-overlay' || o.component.name == 'clickable-image-overlay' || o.component.name == 'buttons-overlay') && o.show);
                else
                    return false;
            },
            startVideoPresentation() {
                this.setupVideoList();
                this.startPresentation = true;

                // console.log('this.videoList', this.videoList);

            },
            setupVideoList() {

                //GET ALL VIDEOS
                const allVideos = new FIMenuVideoList(this.fimenu, {
                    playNextVideo: this.playNextVideo
                });

                //LOGIC TO SELECT WHICH VIDEOS TO PLAY
                this.videoList = [];

                const bumper2Bumper = this.fimenu.vehicleWarranty.warranties.basic.valid(this.fimenu.vehicleMiles, this.fimenu.vehicleWarranty.warrantyStartDate);
                const drivetrain = this.fimenu.vehicleWarranty.warranties.drivetrain.valid(this.fimenu.vehicleMiles, this.fimenu.vehicleWarranty.warrantyStartDate);
                const roadside = this.fimenu.vehicleWarranty.warranties.roadside.valid(this.fimenu.vehicleMiles, this.fimenu.vehicleWarranty.warrantyStartDate)
                const loaner = this.fimenu.vehicleWarranty.isLoanerIncluded;
                const isCash = this.fimenu.dealType.toLowerCase() == 'cash';
                const isLease = this.fimenu.dealType.toLowerCase() == 'lease';
                const isFinance = this.fimenu.dealType.toLowerCase() == 'finance';
                const store = this.fimenu.store;
                const storeState = store.storeState;
                let skipToVideo7 = false;
                const isVideoRecordingEnabled = this.fimenu.store.storeSettings.isVideoRecordingEnabled

                //VIDEO 0 Disclaimer
                if (isVideoRecordingEnabled) {
                    this.videoList.push(allVideos.videoDisclaimer(this.fimenu.language));
                }

                //VIDEO 1
                if (!bumper2Bumper && !drivetrain) {
                    this.videoList.push(allVideos.videoNoCoverageLeft(this.fimenu.language));
                    skipToVideo7 = true;
                }
                else if (!bumper2Bumper && drivetrain) {
                    this.videoList.push(allVideos.videoPowerTrainOnly(this.fimenu.language));
                }
                else if (bumper2Bumper && drivetrain) {
                    this.videoList.push(allVideos.videoWithBumperToBumperLeft(this.fimenu.language));
                }

                if (!skipToVideo7) {

                    //VIDEO 2
                    if (roadside && loaner) this.videoList.push(allVideos.videoWithRoadsideAndLoaners(this.fimenu.language));
                    else if (roadside && !loaner) this.videoList.push(allVideos.videoWithRoadsideButWithoutLoaners(this.fimenu.language));
                    else if (!roadside && loaner) this.videoList.push(allVideos.videoWithoutRoadsideButWithLoaners(this.fimenu.language));
                    else if (!roadside && !loaner) this.videoList.push(allVideos.videoWithoutRoadsideAndWithoutLoaners(this.fimenu.language));

                    //VIDEO 3
                    if (loaner)
                        this.videoList.push(allVideos.videoWithLoaners(this.fimenu.language));
                    else
                        this.videoList.push(allVideos.videoWithoutLoaners(this.fimenu.language))

                    //VIDEO 4
                    this.videoList.push(allVideos.videoWhatCanYouControl(this.fimenu.language))

                    //VIDEO 5
                    this.videoList.push(allVideos.videoWhatCanManufacturerControl(this.fimenu.language))

                    //VIDEO 6
                    this.videoList.push(allVideos.videoManufacturerCantControlAnything(this.fimenu.language))

                }

                //VIDEO 7
                this.videoList.push(allVideos.videoMachincalFailureProtection(this.fimenu.language))

                //VIDEO 8
                if (isLease && storeState != "NY")
                    this.videoList.push(allVideos.videoFutureResaleValueProtectionForLease(this.fimenu.language))
                else
                    this.videoList.push(allVideos.videoFutureResaleValueProtection(this.fimenu.language))

                //VIDEO 9
                this.videoList.push(allVideos.videoKeepingYouOnTheMove(this.fimenu.language))

                //VIDEO 10
                if (isCash)
                    this.videoList.push(allVideos.videoTotalVehicleLossProtectionCash(this.fimenu.language))
                else
                    this.videoList.push(allVideos.videoTotalVehicleLossProtection(this.fimenu.language))


                //VIDEO 11
                this.videoList.push(allVideos.videoForProtectionConclusion(this.fimenu.language))

                //ALLOW SKIPPING IN DEV MODE
                //this.development = this.$route.query.debug == 'true';
                if (this.development) {
                    this.videoList.forEach(video => video.watchedTime = 620);
                }


                //START WITH THE FIRST VIDEO
                this.currVideo = this.videoList[0];



                // console.log('this.videoList', this.videoList);


            },
            setupVideoListeners() {

                const doc = this.$global.customerWindow ? this.$global.customerWindow.document : window.document;



                const video = doc.getElementById(this.vid);
                this.video = video;

                this.removeAllListeners(video, "timeupdate");
                this.removeAllListeners(video, "seeking");
                this.removeAllListeners(video, "ended");

                this.addListener(video, "timeupdate", () => {
                    if (!video.seeking) {
                        if (video.currentTime > this.currVideo.watchedTime) {
                            this.currVideo.watchedTime = video.currentTime;
                        }

                        this.currVideo.currentTime = video.currentTime;
                    }

                    this.showOverlay(video.currentTime, false);

                });

                if (this.getWindowRef) {
                    const windowRef = this.getWindowRef();
                    if (video.readyState >= 1) {
                        windowRef.document.getElementsByClassName("video-container")[0].style.height = "fit-content";
                    }
                }

                this.addListener(video, "seeking", () => {

                    //ENABLES ALL OVERLAYS
                    if (this.currVideo.overlays)
                        this.currVideo.overlays.forEach(o => o.enabled = true);

                    this.currVideo.delta = video.currentTime - this.currVideo.watchedTime;
                    if (this.currVideo.delta > 0) {
                        video.pause();
                        video.currentTime = this.currVideo.watchedTime;
                        video.play();
                    }
                });
                this.addListener(video, "ended", () => {
                    // eslint-disable-next-line no-console
                    console.log('VIDEO ENDED', video)

                    if (this.getWindowRef) {
                        const windowRef = this.getWindowRef();
                        windowRef.document.getElementsByClassName("video-container")[0].style.height = "100%";
                    }
                    if (!this.firstVideoViewed) {
                        if (!this.fimenu.customerViewed.find(cv => [7, 8, 9].includes(cv.viewedType))) {
                            //not counting videos watched after any payment sheet window action, 7 8 9 its the payment sheet actions code
                            this.fimenu.customerViewed.push(new FIMenuCustomerViewed({ viewedType: 12, viewedDateTime: moment() }));
                        }
                        this.firstVideoViewed = true
                    }

                    if (!this.fimenu.customerViewed.find(cv => [7, 8, 9].includes(cv.viewedType))) {
                        //not counting videos watched after any payment sheet window action, 7 8 9 its the payment sheet actions code
                        this.fimenu.customerViewed.push(new FIMenuCustomerViewed({ viewedType: 13, viewedDateTime: moment(), videosSeen: this.currVideo.videosSeen }));
                    }

                    this.currVideo.isEnded = true;

                    //PLAY NEXT VIDEO.. UNLESS THERES AN ENDING OVERLAY
                    if (!this.showOverlay(this.currVideo.currentTime, true)) {
                        // eslint-disable-next-line no-console
                        console.log('PLAYING NEXT VIDEO');
                        this.playNextVideo()
                    }


                });

                this.addListener(video, "loadeddata", () => {
                    video.play();
                })
                //START PLAYING VIDEO
                video.play();

            },
            showOverlay(currentTime, atEnd) {
                let showingSomeOverlay = false;
                let showOverlaysPausesAtEnd = false;
                if (this.currVideo.overlays) {

                    //SHOW or HIDE overlays
                    this.currVideo.overlays.forEach(o => {
                        if (atEnd && o.atEnd) {
                            o.show = true;
                            showOverlaysPausesAtEnd = true;
                        }
                        else if (o.enabled && currentTime >= o.startTime && currentTime <= o.endTime) {
                            o.show = true;
                        }
                        else {
                            o.show = false;
                        }

                        if (o.show) {
                            showingSomeOverlay = true;
                        }

                        if (o.show && o.pauseVideo) {
                            this.video.pause();
                        }

                        if (o.uid == 10) {
                            o.componentProps.divs.forEach(div => {
                                if (currentTime >= o.startTime + div.delay) {
                                    div.show = true;
                                } else {
                                    div.show = false
                                }
                            })
                        }

                    });



                    if (atEnd && showOverlaysPausesAtEnd)
                        return true;
                    else if (atEnd && !showOverlaysPausesAtEnd)
                        return false;
                    else if (showingSomeOverlay)
                        return true;
                    else
                        return false;

                }
                else {
                    return false;
                }
            },
            playNextVideo() {
                /*eslint-disable no-console*/
                const videoThatHasntBeenWatchedAllTheWayYet = this.videoList.find(
                    v => v.isEnded == false
                );

                console.log("videoThatHasntBeenWatchedAllTheWayYet", JSON.stringify(videoThatHasntBeenWatchedAllTheWayYet))

                if (videoThatHasntBeenWatchedAllTheWayYet) {
                    console.log("videoThatHasntBeenWatchedAllTheWayYet", !!videoThatHasntBeenWatchedAllTheWayYet)
                    this.playVideo(videoThatHasntBeenWatchedAllTheWayYet);
                } else {

                    console.log('ALL VIDEOS COMPLETED');
                    console.log(this.fimenu.customerViewed.filter(vid => vid.videosSeen != null))

                    if (!this.fimenu.customerViewed.find(cv => [7, 8, 9].includes(cv.viewedType))) {
                        //not counting videos watched after any payment sheet window action, 7 8 9 its the payment sheet actions code
                        this.fimenu.customerViewed.push(new FIMenuCustomerViewed({ viewedType: 10, viewedDateTime: moment() }));
                    }

                    if (this.goToSection) {
                        console.log('ALL VIDEOS COMPLETED - GOING TO NEXT SECTION');
                        if (this.jumpDirectlyToPaymentSheet()) {
                            this.goToSection('payment-sheet');
                        } else {
                            this.fimenu.customerViewText = 'information.'
                            this.goToSection('information');
                        }

                    }
                }
                /*eslint-enable*/
            },
            jumpDirectlyToPaymentSheet() {
                let returnValue = false
                switch (this.fimenu.dealType) {
                    case 'Cash':
                        returnValue = this.fimenu.otd
                        break;
                    case 'Finance':
                        returnValue = (/*this.fimenu.loanTerms.term1.buyrate &&*/ this.fimenu.loanTerms.term1.sellrate && this.fimenu.loanTerms.term1.term) || (/*this.fimenu.loanTerms.term2.buyrate &&*/ this.fimenu.loanTerms.term2.sellrate && this.fimenu.loanTerms.term2.term) || (/*this.fimenu.loanTerms.term3.buyrate &&*/ this.fimenu.loanTerms.term3.sellrate && this.fimenu.loanTerms.term3.term)
                        break;
                    case 'Lease':
                        returnValue = (this.fimenu.leaseTerms.term1.moneyfactor && this.fimenu.leaseTerms.term1.residual && this.fimenu.leaseTerms.term1.term) || (this.fimenu.leaseTerms.term2.moneyfactor && this.fimenu.leaseTerms.term2.residual && this.fimenu.leaseTerms.term2.term) || (this.fimenu.leaseTerms.term3.moneyfactor && this.fimenu.leaseTerms.term3.residual && this.fimenu.leaseTerms.term3.term)
                        break;
                    default:
                        returnValue = false
                }
                return returnValue;
            },
            playVideo(selectedVideo) {
                // console.log("Current video was updated from: ", this.currVideo.fileName, "To ", selectedVideo.fileName)
                // console.log("Current video was updated from: ", this.currVideo, "To ", selectedVideo)
                this.currVideo = selectedVideo;

            },
            addListener(node, event, handler) {
                this.eventHandlers[event] = handler;
                node.addEventListener(event, handler);
            },
            removeAllListeners(node, event) {
                if (this.eventHandlers[event]) {
                    const handler = this.eventHandlers[event];
                    node.removeEventListener(event, handler);
                }
            }
        },
        components: {

        }

    };
</script>
<style>
    .overlay-container {
        position: absolute;
        top: 0;
        left: 0;
        height: 10px;
        height: 10px;
        visibility: hidden;
    }
</style>