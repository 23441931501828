<template>
    <div class="fimenupresentationpage" id="scaleContainer">
        <div class="product-video-modal" v-if="showVideoModal && currVideo">
            <div class="product-video-container">
                <div class="product-video-player">
                    <video class="vid_player"
                           controls="true"
                           :id="vid"
                           :name="vid"
                           :src="'/dist/vid/videosWithCaptions/' + currVideo.fileName"
                           oncontextmenu="return false;"
                           disablePictureInPicture
                           controlsList="nodownload"></video>
                </div>
                <div>
                    <button class="button" @click="closeModal()">{{ localization.close[fimenu.language] }}</button>
                </div>
            </div>

        </div>
        <div class="carouselclass" v-if="fimenu && fimenu.language === 'English'">
            <carousel-3d :width="800" :height="700" :controls-visible="true" :loop="false" :count="4" :display="3">
                <slide :index="0">
                    <div class="presentation-title presesntation-title-with-video">
                        <div>
                            <i class="far fa-check-square"></i> Mechanical Failure Protection
                        </div>
                        <div class="text-left presentation-title-video">
                            <span @click="playVideo('Mechanical Failure Protection')"><i class="fab fa-youtube"></i></span>
                        </div>
                    </div>
                    <div class="presentation-content">
                        <p><b>Service Contract</b> - Our vehicle service contract <b>eliminates all the gray areas and pays for parts and labor on all covered repairs.</b>  Coverage is <b>not limited to the repair of manufacturer’s defects</b> (items that are mis-designed, mis-built or mis-installed).  If a covered part fails due to wear, tear or use (even if the part fails due to climate, road conditions, personal driving habits, etc.)  <b>We will fix it!</b> In addition, if your vehicle has to stay over night for a covered repair we will <b>provide you with a vehicle to use.</b></p>
                        <p><b>Maintenance</b> - The basic scheduled maintenance <b>ensures you properly maintain your vehicle according to the manufacturer recommended schedule.</b> Following the manufacturer recommended <b>scheduled maintenance is important to avoid denied claims,</b> improved fuel economy, keep your vehicle running longer and safer and prevent the need for big repairs and vehicle down time.</p>
                    </div>
                    <div class="presentation-image1"><img src="https://www.autotrainingcentre.com/wp-content/uploads/2016/03/auto-mechanic-apprenticeship.jpg" /></div>
                </slide>
                <slide :index="1" class="carousel-slide">
                    <div class="presentation-title presesntation-title-with-video">
                        <div>
                            <i class="far fa-check-square"></i> Future Resale Value Protection
                        </div>
                        <div class="text-left presentation-title-video">
                            <span @click="playVideo('Future Resale Value Protection')"><i class="fab fa-youtube"></i></span>
                        </div>
                    </div>
                    <div class="presentation-content">
                        <p><b>NADA states that the exterior and interior condition of your vehicle is a major factor in determining the future re-sale value of your vehicle.</b>  The main <b>areas of concern are dents, dings, paint, and interior condition.</b></p>
                        <p><b>Ding & Dent Coverage</b> - Picture this, you just bought a new vehicle and you needed to do some shopping. While you were in the store, the person that parked next to you put a dent in your door with their door or with their shopping cart. That dent is all you will notice about your new vehicle every time you get into it! With Ding & Dent Coverage you <b>protect your vehicle’s appearance and the future resale value</b> by providing you with <b>unlimited repairs</b> of dings and dents. The paintless repair process time is usually <b>less than 30 minutes, repairs can be performed at the dealership, your home, office, etc. - use it as much as you need.</b></p>
                        <p><b>Paint & Interior Protection</b> - There are things you can do today to help you get a higher trade-in value. <b>Each year your vehicle’s paint is affected by the elements.</b> For example, rain water contains calcium and other metals. When the water evaporates, those chemicals are left behind and they etch themselves into your vehicle paint. <b>Items such as: Love bugs, bird droppings, tree sap, acid rain, hard water, and other elements</b> are highly acidic, and they also etch into your paint. <b>A state-of-the-art coating is applied to the exterior and interior</b> to guard the paint, fabric, vinyl, and leather from the elements. <b>It is guaranteed! We will repair or replace damage that occurs to your paint</b> from the elements as well as <b>repair or replace any interior damage caused by staining</b> (even from blood, vomit, urine, etc.), <b>fading, cracks, rips, tears, burns, and even punctures! You are also entitled to a re-application every 4th scheduled maintenance visit to our service department.</b> This will <b>guarantee your vehicle will maintain its showroom condition inside and out for years to come.</b></p>
                        <p><b>Cosmetic Wheel Curb Repair – Keep your vehicle wheels looking like new with repairs or replacement from almost any damage.</b> Such as damage caused by road hazards or by contact with medians, curbs, parking stops, or other hazards or conditions on the roadway.</p>
                        <p v-show="fimenu.dealType==='Lease'"><b>Lease Vehicle Excess Wear & Tear</b> – When leasing a new vehicle <b>you are responsible at lease-end to return a vehicle to the manufacturer that is free of any wear and tear damage</b> for items such as worn tires, missing owner’s manuals, keys, lenses (head lamps, rear lamps, fog lights), glass, exterior damage such as scratches, chipped paint, stone bruises, sand damage & pits, interior damage and more. <b>With the Future Resale Value Protection you may have equity at the lease-end, but if you don’t</b> have equity the Lease Vehicle Excess Wear & Tear <b>may waive up to $5,000 in lease-end wear and tear expense.</b></p>
                    </div>
                    <div class="presentation-image2">
                        <img src="https://www.suburbanautobody.com/Portals/0/SunBlogNuke/63/56692923_m.jpg" />
                        <img src="https://www.compare.com/wp-content/uploads/2016/08/spill-coffee-on-your-seat.jpg" />
                        <img src="https://cdn.shopify.com/s/files/1/0258/5159/2803/products/04_720x_790df6e8-f25e-4679-a0d7-f602920aa5ef_1024x1024.png?v=1564457542" />
                    </div>
                </slide>
                <slide :index="2" class="carousel-slide">
                    <div class="presentation-title presesntation-title-with-video">
                        <div>
                            <i class="far fa-check-square"></i> Keeping you on the Move
                        </div>
                        <div class="text-left presentation-title-video">
                            <span @click="playVideo('Keeping you on the Move')"><i class="fab fa-youtube"></i></span>
                        </div>
                    </div>
                    <div class="presentation-content">
                        <p>When purchasing a new automobile, <b>you expect the vehicle to always be reliable and keep you on the move.</b> Certain events sometimes occur that prevent that from happening, such as: <b>flat tires, lost, stolen, or broken keys, drained batteries, depleted gas tank, etc.</b></p>
                        <p><b>Nitro-Fill and Tire & Rim Coverage</b> – Proper inflation on your <b>tires is the most important safety feature on your new vehicle.</b>  Under inflation of your tire causes more surface area of your tire to contact the ground, which makes them run hotter and suffer from tread separation. <b>Nitrogen is an inert gas which has been used by NASCAR, Air Force One, and commercial airlines for the last 30 years.</b> Nitrogen molecules are <b>larger than air molecules, which makes them less likely to seep out of the sidewalls, ensuring more consistent tire pressure.</b> With Nitro-Fill you will <b>receive free Nitrogen refills</b> when rotating tires or replacing tires at our dealership. <b>The Tire & Rim Coverage includes repair to or replacement of any wheel or tire damage.</b> This is <b>not limited to road hazards and can be due to any damage from items such as nails, screws, bolts, glass, metal, pot-holes, curb impact, medians, parking stops, etc.</b></p>
                        <p><b>Key and Remote Coverage</b> – With technology advancements nowadays, <b>keys are essentially wireless computers</b> with special codes that match codes located within the ignition. Because of this technology, replacing a lost or stolen key <b>can be extremely expensive, as much as $800 per key!</b> With the Key and Remote Coverage Protection you can rest assured that <b>if your keys are lost, stolen or broken, we will provide you with the new keys and re-program your other keys. We will also re-program your vehicle ignition codes to disable the lost or stolen keys from operating your vehicle.</b></p>
                        <p><b>Towbuster Motor Club</b> – Don’t ever worry about being <b>stuck on the side of the road with no one to call for help.</b> With Towbuster Motor Club, you can be confident that you are covered and protected by an organization that has been caring about <b>getting you back on the road since 1962!</b> We provide <b>24 hours a day, 7 days a week with an average response time of less than 32 minutes</b> of emergency towing service, road service, emergency fuel delivery, battery assistance, auto rental reimbursement, map routing service, and more. Service is <b>available anywhere in the US, Canada, Puerto Rico & Hawaii</b>. We <b>also include identity theft restoration with up to $25,000 towards any expenses to restore your identity and credit.</b> We even <b>include an insurance deductible waiver.</b> This means if you must make an insurance claim, <b>we will reimburse you your $500 deductible.</b></p>
                    </div>
                    <div class="presentation-image3"></div>
                </slide>
                <slide :index="3" class="carousel-slide">
                    <div class="presentation-title presesntation-title-with-video">
                        <div>
                            <i class="far fa-check-square"></i> Total Vehicle Loss Protection
                        </div>
                        <div class="text-left presentation-title-video">
                            <span @click="playVideo('Total Vehicle Loss Protection')"><i class="fab fa-youtube"></i></span>
                        </div>
                    </div>
                    <div class="presentation-content">
                        <p><b>The National Insurance Crime Bureau (NICB) recommends a Layered Approach to prevent auto theft. The layers are: Warning & Deterrent, Tracking & Recovery & Total Loss Protection.</b> If auto theft were listed on the Fortune 500 list, it would be ranked #56 - that is larger than Coca Cola!</p>
                        <p><b>Traceable Theft Protection Program (Warning & Deterrent)</b> – A unique identification code that is <b>permanently affixed to your vehicle.</b> This process deters professional thieves from stealing your vehicle and encourages them to move on to an easier target. <b>Professional thieves know that vehicles equipped with such protection systems are easily traced by police</b>; they frequently will seek out an easier vehicle. Insurance companies know that this is an effective theft deterrent, and that is why this program meets many insurance carriers requirement for anti-theft system premium discounts. <b>In the event that your vehicle is stolen, you are entitled to benefits up to $7,500 to help offset unexpected costs.</b></p>
                        <p><b>Kahu (Tracking & Recovery)</b> - Kahu is a <b>GPS device that is hidden in your vehicle.</b> When your vehicle is stolen, Kahu will <b>track your vehicle and coordinate the recovery of your vehicle with the police department. Their average recovery time is less than 14 minutes.</b> This is important for 2 reasons. First of all, <b>the faster the recovery time, the less damage that can happen to your vehicle.</b> Second, <b>you are less likely to have your personal items stolen out of your vehicle, which can result in identity theft or home invasions.</b> Auto thefts are one of the biggest contributors to identity theft, which is the fastest growing crime in America. Kahu is so sure that they can recover your vehicle quickly that <b>they guarantee it. If your vehicle is stolen and not recovered within 24 hours, they will refund you the system purchase price.</b></p>
                        <p v-show="fimenu.dealType==='Finance'"><b>Guaranteed Asset Protection G.A.P (Total Loss)</b> - Edmunds.com estimates that a new car will <b>depreciate between 30%-35% in the first 12 months of ownership</b> and then depreciate 20% every year after that. This equates to thousands of dollars in depreciation on your vehicle every year. <b>Because of this, many of our customers find themselves upside down in their automobiles.</b> If you experience a total loss, <b>your insurance company will pay the current value of your vehicle, regardless of what you still owe, leaving you still making payments worth thousands on your totaled vehicle.</b> G.A.P will make sure you never have to come out of pocket for the difference. If your insurance company deems your vehicle a total loss, <b>the G.A.P program steps in and makes up the difference between what you actually owe on your vehicle and what your insurance company deems the actual cash value of your vehicle to be.</b></p>
                    </div>
                    <div class="presentation-image4"></div>
                </slide>
            </carousel-3d>
        </div>
        <div class="carouselclass" v-if="fimenu && fimenu.language === 'Spanish'">
            <carousel-3d :width="800" :height="700" :controls-visible="true" :loop="false" :count="4">
                <slide :index="0">
                    <div class="presentation-title presesntation-title-with-video">
                        <div>
                            <i class="far fa-check-square"></i> Proteccion Contra Fallo Mecanico
                        </div>
                        <div class="text-left presentation-title-video">
                            <span @click="playVideo('Mechanical Failure Protection')"><i class="fab fa-youtube"></i></span>
                        </div>
                    </div>
                    <div class="presentation-content">
                        <p>
                            <b>Contrato De Servicio</b> - Nuestra cobertura <b>
                                elimina todas las áreas dudosas y cubre piezas y labor en todas las reparaciones cubiertas en
                                la póliza.
                            </b> La cobertura <b>no está limitada a defectos de fábrica</b> táles como piezas mal diseñadas, mal construidas ó mai instaladas. Si una
                            pieza cubierta por la póliza falla debido al uso (bien sea por el clima, condiciones de la carretera ó
                            hábitos individuales del conductor, etc.) <b>Nosotros lo arreglaremos!</b>
                        </p>
                        <p>
                            <b>Mantenimiento</b> - El mantenimiento básico incluye <b>
                                las visitas de servicio para chequear su vehículo según lo recomendado por el
                                fabricante.
                            </b> Seguir esta norma es importante para <b>
                                evitar a negacion de reclamaciones, mejorar el ahorro de combustible, mantener su
                                vehículo funcionando correctamente y prevenir la necesidad de reparaciones mayores.
                            </b>
                        </p>
                    </div>
                    <div class="presentation-image1"><img src="https://www.autotrainingcentre.com/wp-content/uploads/2016/03/auto-mechanic-apprenticeship.jpg" /></div>
                </slide>
                <slide :index="1" class="carousel-slide">
                    <div class="presentation-title presesntation-title-with-video">
                        <div>
                            <i class="far fa-check-square"></i> Proteccion Del Valor De Reventa En El Futuro
                        </div>
                        <div class="text-left presentation-title-video">
                            <span @click="playVideo('Future Resale Value Protection')"><i class="fab fa-youtube"></i></span>
                        </div>
                    </div>
                    <div class="presentation-content">
                        <p>
                            <b>Según el N.A.D.A. la condición del exterior e interior de su vehículo es un gran factor al determinar su valor en el momento de revenderlo.</b>
                            Las areas <b>más importantes son, abolladuras y golpes, pintura y condición de su interior.</b>
                        </p>
                        <p>
                            <b>Cobertura Contra Golpes Y Abolladuras</b> - Imaginese esta situación: Ud. Acaba de comprar su vehículo y decide irse de compras y la
                            persona que se estaciona a su lado le da un golpe a su puerta. De ahi en adelante esa pequeña abolladura le va a estar molestando cada vez
                            que usa su vehículo. Con esta cobertura Ud <b>proteje no solo la apariencia de su vehículo sino su valor futuro en el momento de revenderlo</b>, ya
                            que le provee <b>arreglos ilimitados</b> de abolladuras y golpes. <b>
                                El proceso de arregarlo, sin pintura, dura usualmente menos de 30 minutos y puede
                                usar este servicio cúantas veces sea necesario. Este servicio se puede hacer en su casa, oficina ó el concesionarío de auto.
                            </b>
                        </p>
                        <p>
                            <b>Pintura Y Proteccion Del Interior</b> - <b>Una capa protectora es aplicada sobre la pintura e interior de su vehículo</b>, esto le asegura
                            protección contra los elementos del clima o cualquier eventualidad provocada por animales ó plantas. Este producto mantendra el brillo
                            originalde su vehículo. <b>
                                Esta póliza tambien incluye reparaciones de daños causados por manchas (sangre, vómito orine, etc.) decoloración,
                                roturas, quemaduras ó hasta pinchazos! Después de la 4ta. visita a nuestro departamento de servicio Ud. tiene derecho a una re-aplicación
                                de este maravilloso producto, así se garantiza el que su vehículo se mantenga en óptimas condiciones
                            </b>
                        </p>
                        <p>
                            <b>Reparacion Cosmetica De Sus Llantas</b> – <b>
                                Mantenga las llantas de su vehiculo como nuevas con la reparación ó reemplace en casi todos
                                los casos.
                            </b> Como daños causados en la carretera ó por contacto con aceras, barras de parqueo ó cualquier otro daño causado por
                            condiciónes de la carretera.
                        </p>
                        <p v-show="fimenu.dealType==='Lease'">
                            <b>Exceso En Desgaste En Su Vehículo Arrendado</b> – Cuando usted arrenda un vehículo, <b>
                                es responsable por el desgate del mismo al entregarlo.
                                Usted es responsable por el desgaste extremo
                            </b> de las gomas (cauchos), libros ó CD de la garrantia, llaves,
                            luces (traseras-delanteras) rayones en la carroseria, falta de pintura, daños interiors (asientos, alfombras) golpes en la carrocería, etc.
                            <b>Con la protección de la reventa en el futuro puede que tenga dinero a su haber</b> al terminar el contrato. Pero si no tuviera ese dinero a su haber,
                            con la poliza de “exceso en desgaste” pudiera representarle una <b>protección de hasta $5000 en desagastes al final del contrato de arrendamiento.</b>
                        </p>
                    </div>
                    <div class="presentation-image2">
                        <img src="https://www.suburbanautobody.com/Portals/0/SunBlogNuke/63/56692923_m.jpg" />
                        <img src="https://www.compare.com/wp-content/uploads/2016/08/spill-coffee-on-your-seat.jpg" />
                        <img src="https://cdn.shopify.com/s/files/1/0258/5159/2803/products/04_720x_790df6e8-f25e-4679-a0d7-f602920aa5ef_1024x1024.png?v=1564457542" />
                    </div>
                </slide>
                <slide :index="2" class="carousel-slide">
                    <div class="presentation-title presesntation-title-with-video">
                        <div>
                            <i class="far fa-check-square"></i> Manteniendole A Ud. En Movimiento
                        </div>
                        <div class="text-left presentation-title-video">
                            <span @click="playVideo('Keeping you on the Move')"><i class="fab fa-youtube"></i></span>
                        </div>
                    </div>
                    <div class="presentation-content">
                        <p>
                            Cuando Ud. Compra un automóvil, <b>espera que éste funcione bién y lo mantenga a Ud. en movimiento.</b> Sin embargo ciertas eventualidades
                            pueden ocurrir como <b>ponche de gomas ó cauchos, llaves robadas, rotas ó perdídas, baterías muertas, quedarse sin gasolina, etc.</b>
                        </p>
                        <p>
                            <b>Relleno De Nitrogeno Y Cobertura De Llantas Y Gomas/o Cauchos</b> – La presion de aire correcta de <b>sus gomas es lo más importante
                            en cuánto a seguridad se refiere</b>. La falta de aire en las gomas causa que éstas se calienten más de lo debido por estar más en contacto
                            con la carretera haciendo possible el que se deshilachen. <b>El Nitrógeno es un gas inerte que ha sido usado por NASCAR, Air Force I y
                            aereolíneas comerciales por los últimos 30 años.</b> Las moléculas de Nitrógeno son <b>más grandes que las de aire, lo cuál hace menos posible
                            el desinfle de las paredes de las gomas y asegura una presión más consistente en éstas.</b> Con Nitro-Fill Ud. <b>recibirá rellenos de Nitrógeno
                            gratis</b> cada véz que rote ó reemplace sus gomas en nuestro Dept. de Sevicio. <b>La cobertura de llantas y gomas ó</b> cauchos <b>incluye
                            reparaciones ó reemplazo de cualquier daño a ruedas y/o gomas. Esto no está limitado a problemas causados por la carretera sino también
                            daños hechos por tornillos, uñas, cristal, metal, baches, golpes de contén, etc.</b>
                        </p>
                        <p>
                            <b>Reemplace De Llaves Y Control Remoto</b> – Con los avances de la tecnología <b>las llaves son esencialmente computadoras con códigos</b>
                            especiales que juegan con otros códigos situados en el motor. Debido a ésta tecnología el <b>reemplazo de llaves es algo extremadamente
                            costoso, hasta $800.00 cada una.</b> Con ésta cobertura Ud. <b>puede estar completamente seguro que si sus llaves son robadas, se pierden ó
                            se rompen ó Ud, se queda fuera de su auto, éstas serán reemplazadas y reprogramadas incluyendo programacion de el systema de arranque
                            para asi desarmar las llaves perdidas o rabadas para que no funcionen en su vehiculo.</b>
</p>
                        <p>
                            <b>Club De Remolque</b> – No mas preocupación de <b>quedarse barado en ningún lugar!</b> Con este club Ud. Puede estar confiado de que estará
                            cubierto y protegido por una organización que <b>brinda éste servicio desde 1962</b>. Le proveemos con <b>servicio de remolque de emergencia
                            24 horas, los 7 dias de la semana</b>, asistencia en la carretera, entrega de combustible, carga de batería, reembolso del costo de auto
                            rentado, rutas de viajes por carretera y mucho más. Este servicio está <b>disponible en Estados Unidos, Canadá y Puerto Rico</b>. Nosotros
                            tambien incluimos una poliza de <b>restoracion de identidad y credito, con una convertura de hasta $25,000</b>. hacia gastos de restauración
                            de ésta y de su crédito. Hasta incluímos un <b>seguro de exoneración de deducible</b>. Esto quiere decir que si Ud. Tiene que hacer una
                            reclamación al seguro, nosotros le <b>reembolsaremos los $500 del deducible.</b>
                        </p>
                    </div>
                    <div class="presentation-image3"></div>
                </slide>
                <slide :index="3" class="carousel-slide">
                    <div class="presentation-title presesntation-title-with-video">
                        <div>
                            <i class="far fa-check-square"></i> Proteccion Sobre Perdida De Su Vehiculo
                        </div>
                        <div class="text-left presentation-title-video">
                            <span @click="playVideo('Total Vehicle Loss Protection')"><i class="fab fa-youtube"></i></span>
                        </div>
                    </div>
                    <div class="presentation-content">
                        <p>
                            <b>El Buró Nacional del Crímen recomienda un enfoque de capas de protecciones para prevenir el robo de autos: Aviso y Rastreo,
                            y Recuperación, y Protección Sobre Pérdida Total.</b> Si el robo de autos estuviera en la lista de Fortune 500 ocuparia el lugar
                            #56 – eso es mayor que la Coca Cola!
                        </p>
                        <p>
                            <b>Programa De Protección Localizable Contra Robo (aviso Y Disuasión)</b> – Un código de identificación único que <b>es instalado
                            permanentemente en su vehículo</b>. Este proceso disuade a ladrones profesionales a robar su vehículo e ir en busca de otro más fácil.
                            Los ladrones profesionales saben que <b>los vehículos equipados con éste sistema de protección son fácilmente localizados por la policía</b>. Las
                            compañias de Seguros saben que eso es un buen medio disuasivo contra robos, y es por eso
                            que <b>éste programa llena los requerimientos exigidos por muchas compañias Aseguradoras para descuentos por Sistemas Anti-Robo</b>. En la
                            eventualidad de que su auto fuera robado, <b>Ud tendría derecho a beneficios de hasta $7,500.00 como máximo para ayudarle a contrarestar
                            gastos inesperados como también reembolso por renta de auto.</b>
</p>
                        <p>
                            <b>Kahu (rastreo Y Recuperación)</b> - Kahu es un <b>dispositivo GPS que va Escondido en su vehículo</b>. Si su vehículo es robado, Kahu <b>lo
                            rastreará y coordinará con la policia su recuperación. El tiempo average de recuperación es de 14 minutos</b>. Esto es importante por dos
                            razones, primero que todo, <b>cúanto más rápido se recupere menor sera el daño a su vehículo</b>. Segundo, Ud. <b>Tiene así menos chance de
                            que le roben sus artículos personales, lo cúal podría resultar en robo de su identidad ó robo en su residencia</b>. El robo de autos es <b>uno de
                            los factores que más contribuyen al robo de identidad</b>, el cuál se ha convertido en el crímen de mayor crecimiento en América. Kahu tiene
                            tanta seguridad de que pueden recuperar su vehículo rápidamente que <b>lo garantizan. Si su vehículo es robado y no es recuperado en 24
                            horas, ellos le devuelven lo que pagó por el sistema</b>.
                        </p>
                        <p v-show="fimenu.dealType==='Finance'">
                            <b>Garantía De Protección Sobre Su Auto (pérdida Total)</b> - Edmunds.com estima <b>que un carro Nuevo depreciará entre un 30%-35%
                            en los primeros 12 meses después de su compra</b> y depreciará un 20% cada año después . Esto significa miles de dólares en depreciación
                            de su vehículo cada año. Es por esta razón que <b>muchos de nuestros clientes se encuentran debiendo más del valor del auto</b>. Si Ud.
                            experimenta una pérdida total, su <b>compañía aseguradora pagará el valor actual de su auto, y no lo que Ud. aún debe por él, dejándole
                            con un balance del cúal Ud. es responsable</b>. Si su compañía aseguradora declara su vehículo pérdida total, <b>el programa G.P.A. cubrirá la
                            diferencia entre lo que Ud. debe y lo que la compañía de seguros estime que es el valor actual de su auto en el mercado.</b>
</p>
                    </div>
                    <div class="presentation-image4"></div>
                </slide>
            </carousel-3d>
        </div>
    </div>
</template>


<script>
    import { Carousel3d, Slide } from '@nanoandrew4/vue3-carousel-3d'
    import FIMenuVideoList from '@core/classes/FIMenuVideoList'

    export default {
        name: "FIMenuPresentation",
        emits: ['sectionInformationChanged'],
        props: ['fimenu', 'sectionName', 'language'],
        data() {
            return {
                isBusy: false,
                showVideoModal: false,
                currVideo: null,
                vid: null,
                localization: {
                    close: {
                        English: 'Close',
                        Spanish: 'Cerca'
                    }
                }
            };
        },
        validations() {
            const validations = {
            }
            return validations;
        },
        computed: {

        },
        created() {
            this.loadData();
            this.$emit('sectionInformationChanged', { sectionName: this.sectionName, indicator: this.getSectionIndicator() });
        },
        mounted() {
            this.vid = 'vid' + this._uid;
        },
        methods: {
            getSectionIndicator() {
                return { icon: "fa-file-invoice", class: "none" };
            },
            loadData() {
                this.isBusy = true;
                this.isBusy = false;
            },
            playVideo(videoName) {

                //GET ALL VIDEOS
                const allVideos = new FIMenuVideoList(this.fimenu, {
                    playNextVideo: this.playNextVideo
                });
                const isLease = this.fimenu.dealType.toLowerCase() == 'lease';
                const withGap = this.fimenu.dealType.toLowerCase() == 'finance';
                const store = this.fimenu.store;
                const storeState = store.storeState;


                switch (videoName) {
                    case 'Mechanical Failure Protection':
                        this.currVideo = allVideos.videoMachincalFailureProtection(this.fimenu.language);
                        break;
                    case 'Future Resale Value Protection':

                        if (isLease)
                            this.currVideo = allVideos.videoFutureResaleValueProtectionForLease(this.fimenu.language);
                        else
                            this.currVideo = allVideos.videoFutureResaleValueProtection(this.fimenu.language);

                        break;
                    case 'Keeping you on the Move':
                        this.currVideo = allVideos.videoKeepingYouOnTheMove(this.fimenu.language);
                        break;
                    case 'Total Vehicle Loss Protection':

                        if (withGap && storeState == "NY")
                            this.currVideo = allVideos.videoTotalVehicleLossProtectionWithGapInNY(this.fimenu.language);
                        else if (withGap)
                            this.currVideo = allVideos.videoTotalVehicleLossProtectionWithGap(this.fimenu.language);
                        else
                            this.currVideo = allVideos.videoTotalVehicleLossProtectionWithoutGap(this.fimenu.language);

                        break;
                    default:
                }
                /*eslint-disable no-console*/
                console.log('currVideo', this.currVideo);

                this.showVideoModal = true;

                setTimeout(() => {
                    const doc = this.$global.customerWindow ? this.$global.customerWindow.document : window.document;
                    const d_video = doc.getElementById(this.vid);


                    console.log('this.vid', this.vid);
                    console.log('video', d_video);

                    d_video.play();
                }, 1000);
                /*eslint-enable*/
            },
            closeModal() {

                const doc = this.$global.customerWindow ? this.$global.customerWindow.document : window.document;
                const video = doc.getElementById(this.vid);
                video.pause();

                this.showVideoModal = false;
                this.currVideo = null;
            }
        },
        components: {
            //InputTextbox: InputTextbox,
            //InputCurrency: InputCurrency,
            //InputNumber: InputNumber,
            //InputDate: InputDate,
            //InputCheckbox: InputCheckbox,
            //Panel: Panel
            Carousel3d: Carousel3d,
            Slide: Slide
        }

    };
</script>
<style>


</style>