export class ApiExchange<T, Z> {
    request: TimestampedData<T>;
    response: TimestampedData<Z>;

    constructor(init?: Partial<ApiExchange<T, Z>>) {
      this.request = init.request;
      this.response = init.response;
    }

    getRequestTimestamp(): Date {
      return this.request.timestamp;
    }

    getRequestData(): T {
      return this.request.data;
    }

    getResponseTimestamp(): Date {
      return this.response.timestamp;
    }

    getResponseData(): Z {
      return this.response.data;
    }
 }
  
export interface TimestampedData<T> {
  timestamp: Date;
  data: T;
}