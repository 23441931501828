<template>
    <div class="popup-container">
        <slot></slot>
    </div>
</template>


<script>

    export default {
        name: "Popup",
        emits:['close'],
        props: ['onCloseHandler', 'windowName', 'popupInfo', 'customerViewAlert'],
        data() {
            return {
                windowRef: null,
                gotOpened: false,
            };
        },
        computed: {

        },


        beforeUnmount() {
            if (this.windowRef) {
                this.closePortal();
            }
        },
        methods: {
            init() {
                this.openPopup();
            },
            openPopup() {

                //IF POPUP IS NOT OPEN, OPEN IT
                if (this.gotOpened == false) {

                    //OPENS THE WINDOW
                    this.windowRef = window.open("", (this.windowName ? this.windowName : ""), "width=600,height=400,left=200,top=200");
                    this.$global.customerWindow = this.windowRef;
                    this.customerViewAlert.noExist = false
                    this.customerViewAlert.noVisible = false
                    this.windowRef.document.addEventListener('visibilitychange', () => {
                        if (this.windowRef && this.windowRef.document.visibilityState === 'visible') {
                            this.customerViewAlert.noVisible = false
                        } else {
                            this.customerViewAlert.noVisible = true
                        }
                    })

                    if (this.windowRef.outerWidth < screen.availWidth || this.windowRef.outerHeight < screen.availHeight) {
                        this.windowRef.moveTo(0, 0);
                        this.windowRef.resizeTo(screen.availWidth, screen.availHeight);
                    }




                    //THIS IS A CHECK IF THE WINDOW HAS BEEN CLOSED (THIS IS A WAY TO CHECK IF THE WINDOW HAS BEEN CLOSED)
                    const timer = setInterval(() => {

                        if (!this.windowRef) {
                            this.gotOpened = false;
                            //console.log('timer - CLEAR INTERVAL1');
                            clearInterval(timer);
                        }
                        else if (this.windowRef.closed) {
                            this.gotOpened = false;
                            //console.log('timer - CLEAR INTERVAL2');
                            clearInterval(timer);

                            // AFTER WINDOW IS CLOSED
                            this.closePortal();
                        }
                        //console.log('timer - STILL LOOPING');

                    }, 1000);

                    let iterations = 0;
                    const maxIterations = 100;
                    const openTimer = setInterval(() =>
                    {
                        iterations++;

                        if (this.windowRef && this.windowRef.closed) {
                            clearInterval(openTimer);
                            this.closePortal();
                        }

                        if (this.windowRef && this.windowRef.document.body) {
                            // console.log('ready');
                            //PUTS OUR COMPONENT INTO THE POPUP
                            this.windowRef.document.body.innerHTML = "";
                            this.windowRef.document.body.appendChild(this.$el);
                            window.dispatchEvent(new Event('resize'));
                            clearInterval(openTimer);
                        }
                        else {
                            // console.log('not ready')
                            if (iterations > maxIterations) {
                                clearInterval(openTimer);
                            }
                        }

                    }, 100);


                    //TAKES EVERYTHING THAT IS NOT IN THE POPUP (head tag) AND LOADS IT INTO THE POPUP
                    const headContent = document.getElementsByTagName('head')[0].innerHTML;
                    const html = `<html><head>${headContent}</head><body></body></html>`;
                    this.windowRef.document.write(html);
                    const link = this.windowRef.document.createElement("link");
                    link.rel = "stylesheet";
                    link.href = "/css/customerResponsive.css"; // Replace with the path to your CSS file
                    this.windowRef.document.head.appendChild(link);

                    //RESCALE WHEN RESIZING SCREEN
                    const scaleContainer = document.getElementById("scaleContainer")
                    this.windowRef.addEventListener('resize', this.scaleCustomerScreen);

                    this.gotOpened = true;

                }

                setTimeout(this.scaleCustomerScreen, 200);
                this.scaleCustomerScreen();

            },
            scaleCustomerScreen() {

                if (this.windowRef) {

                    //let body = this.windowRef.document.querySelector('body');
                    //let box = this.windowRef.document.querySelector('#scaleContainer')

                    //if (body && box) {
                    //    let contentWidth = 1024;
                    //    let availableWidth = body.clientWidth;

                    //    let contentHeight = 768;
                    //    let availableHeight = body.clientHeight;

                    //    var rescale = Math.min(
                    //        availableWidth / contentWidth,
                    //        availableHeight / contentHeight
                    //    );
                    //    //let rescale = this.windowRef.document.body.clientWidth / 1920.00;

                    //    box.setAttribute("style", "transform: scale(" + rescale + "); transform-origin: center center;")
                    //    body.setAttribute("style", "overflow: hidden");
                        this.customerViewAlert.noFullScreen = (this.windowRef.innerWidth <= 1880) || (this.windowRef.innerHeight <= 1050)

                }
            },
            closePortal() {
                if (this.onCloseHandler) this.onCloseHandler();
                this.$global.customerWindow = null;
                this.customerViewAlert.noExist = true
                this.customerViewAlert.noFullScreen = true
                this.customerViewAlert.noVisible = true
                if (this.windowRef) {
                    this.windowRef.close();
                    this.windowRef = null;
                    this.$emit('close');
                }

            }
        },
        components: {}
    };
</script>
<style>

</style>