import EnumHelper, { ToListItems } from "@core/helpers/enum-helper";
import { CustomersRelationship } from "@core/classes/SharedEnums";

export default class CustomersRelationshipHelper extends EnumHelper<CustomersRelationship>
{ 
   public static toList: ToListItems<CustomersRelationship>[] = [
    {
        display: 'Other',
        value: CustomersRelationship.Other,
     },
    {
        display: 'Spouse',
        value: CustomersRelationship.Spouse,
     },
    {
        display: 'Resides with',
        value: CustomersRelationship.ResidesWith,
     },
    {
        display: 'Parent',
        value: CustomersRelationship.Parent,
     },
    {
        display: 'Guarantor',
        value: CustomersRelationship.Guarantor,
     },
    {
        display: 'CoApplicant',
        value: CustomersRelationship.CoApplicant,
    },
   ];
   
   constructor()
   { 
       super(CustomersRelationshipHelper.toList);
   }
   
   /**
    * @param isBusiness boolean
    * @returns Gets a filtered list based on if it's a business as one of the customers
    */
   public static getFilteredList(isBusiness: boolean): ToListItems<CustomersRelationship>[] {
    return isBusiness 
        ? CustomersRelationshipHelper.toList.filter(item => 
            [CustomersRelationship.Guarantor, CustomersRelationship.CoApplicant, CustomersRelationship.Other].includes(item.value))
        : CustomersRelationshipHelper.toList.filter(item => 
            [CustomersRelationship.Other, CustomersRelationship.Spouse, CustomersRelationship.ResidesWith, CustomersRelationship.Parent].includes(item.value));
}
} 