<template>
    <div class="canvas-container">
        <canvas ref="canvasRef" class="video-recording-canvas"></canvas>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { LOG_HOW, LOG_WHAT, LOG_WHERE, VIDEO_SOURCE_TYPE } from '@core/classes/Enums';
import util, { EventBusCore } from '@core/services/util';
import api from '@core/services/api';
import auth from '@core/services/auth';
import CanvasRecordingConfig from '@core/classes/VideoRecording/CanvasRecordingConfig';
import ModalVideoRecordingSourceStop from '@core/modals/modalVideoRecordingSourceStop.vue';
import moment from 'moment';
import { VideoRecordingsInfo } from '@core/classes/VideoRecording/VideoRecordingsInfo';
import VideoRecordingSourcesConfig from '@core/classes/VideoRecording/VideoRecordingSourcesConfig';
import VideoRecordSystem from '@core/classes/VideoRecording/VideoRecordSystem';

//------------------------------------//
//------- Components Reference -------//
//------------------------------------//
//Reference to canvas object rendering the video outputs
const canvasRef = ref<HTMLCanvasElement>(null)

//-------------------------//
//------- Variables -------//
//-------------------------//
// Canvas Object configuration 
const canvasConfig = ref<CanvasRecordingConfig>(null)

// Configuration for each Video Source 
// Note: It may be a better option to have an array when the amount of source increase later on 
const staffScreenConfig = ref<VideoRecordingSourcesConfig>(null)
const staffCameraConfig = ref<VideoRecordingSourcesConfig>(null)
const customerCameraConfig = ref<VideoRecordingSourcesConfig>(null)
const cocustomerCameraConfig = ref<VideoRecordingSourcesConfig>(null)

// MediaStream combining Video / Audio used for MediaRecorder
const combinedStreams = ref<MediaStream>(null)

// Used to space objects inside the canvas
const canvasVerSpacing = ref<number>(null)

//  Info of the video being recorded, API elastic save
const videoRecordingInfo = ref<VideoRecordingsInfo>(new VideoRecordingsInfo())

// Variable used to know if recording needs to start right away after AudioContext is resumed
// Note: More info at audioContext's notes
const startRecordingWhenPossible = ref(null)

// Auth payload computed
const payload = computed(() => auth.getTokenPayload())

// Logged in user settings
const userSettings = ref(null)

//-----------------------------------//
//------- Media Recording API -------//
//-----------------------------------// 
// Variable handling MediaRecorder API object
const videoRecorder = ref<MediaRecorder>(null)
// settings for MediaRecorder
const videoRecorderOptions = ref(null)
// Request Data from video recorder interval
const chunkTimeSlice = ref<number>(5000)

// Frames Skip feature (frame skip is intended to lower cpu usage by lowering the frequency of drawCanvas calls)
const frameVariation = ref<number>(1)
// Count of frames from last skip
const frameSkipped = ref<number>(0)
// Max amount of frames allow to be skipped at once, max value of frameVariation
const maxFrameVariation = ref<number>(3)

// VideoRecordSystem Class, handle all chunk processing and upload
const videoRecordSystem = ref<VideoRecordSystem>()

// Audio Context API
// Note: The audioContext variable here is used to manage audio tracks dynamically during recording with MediaRecorder, 
// enabling the addition or removal of tracks mid-session. However, modern browsers require the audioContext to be initialized 
// by a user interaction before it can be used. If the startRecording event is triggered before the audioContext is initialized,
// use <startRecordingWhenPossible> to ensure recording starts only when the audioContext is ready.
const audioContext = ref<AudioContext>(null);

// Create a MediaStreamDestination (output MediaStream)
const audioContextDestination = ref<MediaStreamAudioDestinationNode>(null);
// Creates a silent audio node that generates an audio stream with no sound. 
// This is used when no external audio is added to the recording. Without this,
// if MediaRecorder fails to recognize audio data, it can stall the entire recording process, including the video.
const silentSource = ref<AudioBufferSourceNode>(null)


//--------------------------//
//------- Vue Events -------//
//--------------------------//
onMounted(async () => {
    try{
        // Catch all errors to prevent production to be stuck if any bug happen
        setupEventBusListeners()
        init()
        document.addEventListener("click", () => {
            // start recording if needs to be started after first user interaction (click)
            if (startRecordingWhenPossible.value){
                setupVideoRecording(startRecordingWhenPossible.value);
            }
        })
    } catch(error){
        console.error("MultiStreamRecorder Error: ",error);
    }
})

onUnmounted(() => {
    EventBusCore.off('startVideoRecording', startVideoRecordingHandler)
    EventBusCore.off('stopVideoRecording', stopVideoRecordingHandler)
    EventBusCore.off('setCustomersCameraStream', setCustomersCameraStreamHandler)
})

//------------------------------//
//------- Event Handlers -------//
//------------------------------//

const setupEventBusListeners = () => {
    // Event Setup registration
    EventBusCore.on('startVideoRecording', startVideoRecordingHandler)
    EventBusCore.on('stopVideoRecording', stopVideoRecordingHandler)
    EventBusCore.on('setCustomersCameraStream', setCustomersCameraStreamHandler)
}

const stopVideoRecordingHandler = (callback: any) => {
    // If MediaRecorder exist stop it and reset Settings
    if (videoRecorder.value) {
        // Call function sent from the event Emitter if we want to do something if video actually finish
        if(typeof callback == "function") callback();

        // Request last piece of data recorded after the last slice
        videoRecorder.value.requestData();

        //Set Video Recorder info settings
        videoRecordSystem.value.setFimenuId(null)
        videoRecordSystem.value.setVideoId(null)

        if(staffScreenConfig.value.stream) {
            //staffScreenConfig reset
            staffScreenConfig.value.stream.getTracks().forEach(track => {
                track.stop()
            })
            staffScreenConfig.value.stream = null
            staffScreenConfig.value.video.srcObject = null
            staffScreenConfig.value.isStreamActive = false;
        }
        
        if(staffCameraConfig.value.stream){
            //staffCameraConfig reset
            staffCameraConfig.value.stream.getTracks().forEach(track => {
                track.stop()
            })
            staffCameraConfig.value.stream = null
            staffCameraConfig.value.video.srcObject = null
            staffCameraConfig.value.isStreamActive = false;
        }


        if(customerCameraConfig.value.stream){
            //customerCameraConfig reset
            customerCameraConfig.value.stream.getTracks().forEach(track => {
                track.stop()
            })
            customerCameraConfig.value.stream = null
            customerCameraConfig.value.video.srcObject = null
            customerCameraConfig.value.isStreamActive = false;
        }

        if(cocustomerCameraConfig.value.stream){
            //cocustomerCameraConfig reset
            cocustomerCameraConfig.value.stream.getTracks().forEach(track => {
                track.stop()
            })
            cocustomerCameraConfig.value.stream = null
            cocustomerCameraConfig.value.video.srcObject = null
            cocustomerCameraConfig.value.isStreamActive = false;
        }

        // canvasConfig reset
        canvasConfig.value.stream.getTracks().forEach(track => {
            track.stop()
        })
        canvasConfig.value.stream = null;
        combinedStreams.value = null

        // Reset MediaRecorder API object
        videoRecorder.value.stop();
        videoRecorder.value = null
    }
}

const startVideoRecordingHandler = async (data: any) => {
    if(!userSettings.value.disableRecording) {
        // User settings recording not disabled
        if(!audioContext.value || (audioContext.value && audioContext.value.state == "suspended")) {
            // Request is sent before First USer Interaction, so we prevent the recording to start until
            // AudioContext resume, but we sabe the data sent for the event
            startRecordingWhenPossible.value = data
            return;
        }
        
        // Setup video and start if AudioContext is running
        setupVideoRecording(data);
    }
}

const setCustomersCameraStreamHandler = (data: any) => {
    // Event for when user joins to a Meeting, get stream of video and audio and add it to the MediaRecorder
    let videoSource = document.createElement('video')
    if(data.details.role.toLowerCase() == "customer") {
        // set Up all Costumer data config
        customerCameraConfig.value.video = videoSource
        customerCameraConfig.value.video.srcObject = data.stream
        customerCameraConfig.value.stream = data.stream
        customerCameraConfig.value.video.autoplay = true;
        customerCameraConfig.value.video.muted = true;
        customerCameraConfig.value.isStreamActive = true;
        customerCameraConfig.value.video.onloadedmetadata = () => {
            customerCameraConfig.value.video.play();
        };

        customerCameraConfig.value.stream.getTracks().forEach((track) => {
            if (track.readyState == 'live') {
                if(track.kind == 'video') {
                    // If video track is interrupted in the middle of the recording react to it
                    track.onended = () => onStreamTrackEnded(VIDEO_SOURCE_TYPE.CUSTOMER_CAMERA);
                }
                if(track.kind == 'audio') {
                    // Add audio track to the AudioContext stream
                    addAudioContextTrack(track)
                }
            }
        });
    } 
    if(data.details.role.toLowerCase() == "cocustomer") {
        // set Up all Costumer data config
        cocustomerCameraConfig.value.video = videoSource
        cocustomerCameraConfig.value.video.srcObject = data.stream
        cocustomerCameraConfig.value.stream = data.stream
        cocustomerCameraConfig.value.video.autoplay = true;
        cocustomerCameraConfig.value.video.muted = true;
        cocustomerCameraConfig.value.isStreamActive = true;
        cocustomerCameraConfig.value.video.onloadedmetadata = () => {
            cocustomerCameraConfig.value.video.play();
        };

        cocustomerCameraConfig.value.stream.getTracks().forEach((track) => {
            if (track.readyState == 'live'){ 
                if(track.kind == 'video') {
                    // If video track is interrupted in the middle of the recording react to it
                    track.onended = () => onStreamTrackEnded(VIDEO_SOURCE_TYPE.COCUSTOMER_CAMERA);
                }
                if(track.kind == 'audio') {
                    // Add audio track to the AudioContext stream
                    addAudioContextTrack(track)
                }
            }
        });
    } 
}

//-------------------------//
//------- Functions -------//
//-------------------------//

const init = async () => {
    // Executed when component loads, initialize all required objects and variables

    // Gets logged user's settings
    let response = await api.users.getByCode(payload.value.EmployeeCode);
    userSettings.value = response.data

    canvasVerSpacing.value = 30

    videoRecordSystem.value = new VideoRecordSystem()
    videoRecordSystem.value.getNextFromQueue()

    if(!audioContext.value) {
        audioContext.value = new AudioContext();

        // Create a MediaStreamDestination (output MediaStream)
        audioContextDestination.value = new MediaStreamAudioDestinationNode(audioContext.value);
    }

    staffScreenConfig.value = new VideoRecordingSourcesConfig()
    staffCameraConfig.value = new VideoRecordingSourcesConfig()
    customerCameraConfig.value = new VideoRecordingSourcesConfig()
    cocustomerCameraConfig.value = new VideoRecordingSourcesConfig()
    canvasConfig.value = new CanvasRecordingConfig({ ctx: canvasRef.value.getContext("2d") })

}

const updateSaveVideoInfo = (data: any) => {
    // Save Information of the video being Recorded to be sent to API/Elastic
    videoRecordingInfo.value.updateVideoInfo({
        id: videoRecordSystem.value.getVideoId(),
        fimenuId: videoRecordSystem.value.getFimenuId(),
        fimenuDealNumber: data.dealNumber,
        fiManagerName: payload.value.EmployeeName,
        fiManagerId: payload.value.id,
        storeCode: data.store.storeCode,
        storeName: data.store.storeName,
        startTimestamp: Date.now(),
        endTimestamp: null,
        VideoSourcesConfig: [
            {
                sourceType: VIDEO_SOURCE_TYPE.STAFF_SCREEN,
                dimensionWidth: staffScreenConfig.value.width,
                dimensionHeight: staffScreenConfig.value.height,
                positionTop: staffScreenConfig.value.top,
                positionLeft: staffScreenConfig.value.left
            },
            {
                sourceType: VIDEO_SOURCE_TYPE.STAFF_CAMERA,
                dimensionWidth: staffCameraConfig.value.width,
                dimensionHeight: staffCameraConfig.value.height,
                positionTop: staffCameraConfig.value.top,
                positionLeft: staffCameraConfig.value.left
            }
        ]
    })
}

const setStreamsSizeAndPosition = () => {
    //Set setting related to dimensions and position
    const camerasWidth = 560;
    const camerasHeight = 400;
    staffScreenConfig.value.width = window.innerWidth
    staffScreenConfig.value.height = window.innerHeight
    
    staffCameraConfig.value.width = camerasWidth
    staffCameraConfig.value.height = camerasHeight
    staffCameraConfig.value.left = 0
    staffCameraConfig.value.top = (staffScreenConfig.value.height + canvasVerSpacing.value)

    customerCameraConfig.value.width = camerasWidth
    customerCameraConfig.value.height = camerasHeight
    customerCameraConfig.value.left =  (camerasWidth + canvasVerSpacing.value)
    customerCameraConfig.value.top = (staffScreenConfig.value.height + canvasVerSpacing.value)

    cocustomerCameraConfig.value.width = camerasWidth
    cocustomerCameraConfig.value.height = camerasHeight
    cocustomerCameraConfig.value.left = (customerCameraConfig.value.left + customerCameraConfig.value.width + canvasVerSpacing.value)
    cocustomerCameraConfig.value.top = (staffScreenConfig.value.height + canvasVerSpacing.value)

    canvasConfig.value.width = Math.max(staffScreenConfig.value.width, (cocustomerCameraConfig.value.left + cocustomerCameraConfig.value.width))
    canvasConfig.value.height = customerCameraConfig.value.top + customerCameraConfig.value.height
}



//------- Audio Context API -------//
const addAudioContextTrack = (audioTrack: MediaStreamTrack) => {
    // Add new track to AudioContext
    let mediaStream = new MediaStream([audioTrack])
    const mediaStreamAudioSourceNode = new MediaStreamAudioSourceNode(
        audioContext.value,
        { mediaStream: mediaStream }
    );

    mediaStreamAudioSourceNode.connect(audioContextDestination.value);
}

//------- Audio Context API end -------//


//------- MediaRecording API -------//

const setupVideoRecording = async (data: any) => {
    // Setup all Data needed to start the MediaRecorder API
    
    // Reset variable to not trigger on next user interaction
    startRecordingWhenPossible.value = null

    // Video Info setup
    videoRecordSystem.value.setFimenuId(data.fimenuId)
    videoRecordSystem.value.setVideoId(data.videoId)

    // video Recorder Options setup
    videoRecorderOptions.value = {
        config: {
            mimeType: "video/webm;codecs=vp8",
            bitsPerSecond: data.store?.storeSettings?.videoRecordingSettings?.videoRecordingBitrate || 2140000,
        },
    }

    try{
        // Request staff screen stream
        staffScreenConfig.value.stream = await navigator.mediaDevices.getDisplayMedia({
            video: {
                mediaSource: "tab",
                displaySurface: 'browser',
            },
            preferCurrentTab: true,
            selfBrowserSurface: "include",
            audio: true
        } as any)
        
        // Request staff camera stream
        staffCameraConfig.value.stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true
        });
    } catch(error) {
        console.log("Request Screen recording and Camera Stream: ",error);
    }


    if (staffScreenConfig.value.stream) {
        // setup config object for staff screen
        if(!staffScreenConfig.value.video) {
            staffScreenConfig.value.video = document.createElement('video')
        }
        staffScreenConfig.value.video.srcObject = staffScreenConfig.value.stream
        staffScreenConfig.value.video.autoplay = true;
        staffScreenConfig.value.video.muted = true;
        staffScreenConfig.value.isStreamActive = true

        staffScreenConfig.value.video.onloadedmetadata = () => {
            staffScreenConfig.value.video.play();
        };

        staffScreenConfig.value.stream.getTracks().forEach((track) => {
            if (track.readyState == 'live') {
                if(track.kind == 'video') {
                     // If video track is interrupted in the middle of the recording react to it
                   track.onended = () => onStreamTrackEnded(VIDEO_SOURCE_TYPE.STAFF_SCREEN);
                }
            }
        });

        // Set silenceComponent to prevent stall if no audio track is added later
        const silenceBuffer = audioContext.value.createBuffer(
            1, // Mono channel
            audioContext.value.sampleRate, // 1 second length
            audioContext.value.sampleRate // Sample rate
        );

        // Fill the buffer with silence
        silentSource.value = audioContext.value.createBufferSource();
        silentSource.value.buffer = silenceBuffer;
        silentSource.value.loop = true; // Keep looping to simulate continuous silence

        // Connect the silent source to the audioContext destination
        silentSource.value.connect(audioContextDestination.value);
        silentSource.value.start();
    } else {
        // If no stream captured for this source, react to it
        onSourceNotLoaded(VIDEO_SOURCE_TYPE.STAFF_SCREEN)
    }

    if(staffCameraConfig.value.stream) {
        // setup config object for customer camera
        if(!staffCameraConfig.value.video) {
            staffCameraConfig.value.video = document.createElement('video')
        }
        staffCameraConfig.value.video.srcObject = staffCameraConfig.value.stream
        staffCameraConfig.value.video.autoplay = true;
        staffCameraConfig.value.video.muted = true;
        staffCameraConfig.value.isStreamActive = true;

        staffCameraConfig.value.video.onloadedmetadata = () => {
            staffCameraConfig.value.video.play();
        };
        staffCameraConfig.value.stream.getTracks().forEach((track) => {
            if (track.readyState == 'live'){
                if(track.kind == 'video') {
                    // If video track is interrupted in the middle of the recording react to it
                    track.onended = () => onStreamTrackEnded(VIDEO_SOURCE_TYPE.STAFF_CAMERA);
                }
                if (track.kind == 'audio') {
                    // Add audio track to the AudioContext stream
                    addAudioContextTrack(track)
                }
            }  
        });
    } else {
         // If no stream captured for this source, react to it
        onSourceNotLoaded(VIDEO_SOURCE_TYPE.STAFF_CAMERA)
    }

    if(staffScreenConfig.value.stream || staffCameraConfig.value.stream) {
        // If at least one source exist start MediaRecorder
        // TODO: when exception for sources is implemented we may want to update how this works
        setStreamsSizeAndPosition()
        updateSaveVideoInfo(data);
        requestAnimationFrame(loop);
        initVideoRecording()
    }
}

const initVideoRecording = () => {
    console.log('Video Recording Available to Start');
    // Setup canvas object with canvas settings
    canvasRef.value.width = canvasConfig.value.width
    canvasRef.value.height = canvasConfig.value.height

    // Save canvas stream (15 fps seems to be a good balance between quality and performance)
    canvasConfig.value.stream = canvasRef.value.captureStream(15);

    // Resume AudioContext, 
    audioContext.value.resume()

    //Combine stream, Canvas and audioCOntext
    combinedStreams.value = new MediaStream([
        ...canvasConfig.value.stream.getTracks(),
        ...audioContextDestination.value.stream.getAudioTracks(),
    ]);

    combinedStreams.value.getTracks().forEach((track) => {
        console.log(`Track kind: ${track.kind}, ID: ${track.id}, ReadyState: ${track.readyState}`);
    })

    //Start MediaRecorder
    videoRecorder.value = new MediaRecorder(combinedStreams.value, videoRecorderOptions.value.config)
    videoRecorder.value.onstart = () => {
        console.log('MediaRecorder started, state:', videoRecorder.value.state);
    };

    //Set MediaRecorder events
    videoRecorder.value.ondataavailable = onVideoRecordingDataAvailable

    // when recorder stops (via recorder.stop()), handle blobs
    videoRecorder.value.onstop = onVideoRecordingStop
 
    // Starr MediaRecorder API
    videoRecorder.value.start(chunkTimeSlice.value)
    console.log('MediaRecorder state after start:', videoRecorder.value.state);
    console.log('Chunk Time Slice:', chunkTimeSlice.value);

    //Save videoRecordingInfo API/Elastic
    api.videos.saveVideoDetails(videoRecordingInfo.value)
}

const onVideoRecordingDataAvailable = (evt: BlobEvent) => {
    console.log('onDataavailable', evt.data);
    // Process VideoData chunk
    videoRecordSystem.value.queueVideoChunk(evt.data)
}

const onVideoRecordingStop = async () => {
    console.log("stopping recording");
}

const onSourceNotLoaded = (videoSource: number) => {
    // Send Fimenu Log when a video source is not loaded
    let logInfo = {
        when: moment.utc(),
        what: `${VIDEO_SOURCE_TYPE.getDisplay(videoSource)} ${LOG_WHAT.FAILED}`,
        where: LOG_WHERE.VIDEO_RECORDING,
        how: LOG_HOW.VIDEO_RECORDING.STARTED
    }
    EventBusCore.emit('VideoRecordingsFImenuLog', logInfo)
}

const onStreamTrackEnded = (videoSource: number) => {
    // Send Fimenu Log when a video source is interrupted mid session
    let logInfo = {
        when: moment.utc(),
        what: `${VIDEO_SOURCE_TYPE.getDisplay(videoSource)} ${LOG_WHAT.DISCONNECTED}`,
        where: LOG_WHERE.VIDEO_RECORDING,
        how: LOG_HOW.VIDEO_RECORDING.ONGOING
    }
    EventBusCore.emit('VideoRecordingsFImenuLog', logInfo)
    
    //TODO: use this code when the exception for video continue is created
    // videoRecorder.value?.pause()
    // $modal.open(ModalVideoRecordingSourceStop, {
    //     name: "ModalVideoRecordingSourceStop",
    //     videoSource,
    //     postFunction: () => {
    //         return;
    //     },
    //     backdrop: false,
    // });
    
}

const recoverCameraStream = async () => {
    let newStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true
    });

    console.log(newStream.id)
    staffCameraConfig.value.stream = newStream;
    staffCameraConfig.value.video.srcObject = newStream;
    await staffCameraConfig.value.video.play()

    videoRecorder.value.resume()

}

//------- MediaRecording API End -------//


//------- Canvas -------//

const calculateOptimalFrameTime = (desiredFrameRate: number) => {
    return 1000 / desiredFrameRate;
}

const loop = () => {
    // timestamp of the render process start
    const start = performance.now();

    if (frameVariation.value == 0) frameVariation.value = 2
    if (frameVariation.value == frameSkipped.value) {
        // if frame needs to be render
        drawCanvas()
        frameSkipped.value = 0
    }
    frameSkipped.value++

    // duration of the render process
    const renderTime = performance.now() - start;
 
    // skip frames if the process is to large for the desire framerate
    if (renderTime > calculateOptimalFrameTime(60)) {
        frameVariation.value = Math.min(frameVariation.value + 1, maxFrameVariation.value); // Skip more frames
    } else {
        frameVariation.value = Math.max(frameVariation.value - 1, 1); // Skip fewer frames
        if (frameVariation.value < frameSkipped.value) {
            frameSkipped.value = 0
        }
    }

    if(videoRecorder.value) {
        // Render next frame if MediaRecorder is active
        requestAnimationFrame(loop);
    } else {
        // Clean Canvas after MediaRecorder ends
        drawCanvas(true)
    }
}

const drawCanvas = (justClear = false) => {
    //console.log("DrawCanvas Function");
    const { width, height } = canvasRef.value;
    canvasConfig.value.ctx.clearRect(0, 0, width, height);

    if(justClear) return;

    // TODO: if needed later we would need to do real math to get proper aspect ratio.

    // draw staff screen share in top-left
    if(staffScreenConfig.value.stream) {
        canvasConfig.value.ctx.drawImage(staffScreenConfig.value.video,
            staffScreenConfig.value.left,
            staffScreenConfig.value.top,
            staffScreenConfig.value.width,
            staffScreenConfig.value.height);
    }

    // draw staff webcam in bottom right.
    if(staffCameraConfig.value.stream) {
        canvasConfig.value.ctx.drawImage(staffCameraConfig.value.video,
            staffCameraConfig.value.left,
            staffCameraConfig.value.top,
            staffCameraConfig.value.width,
            staffCameraConfig.value.height);
    }

    // draw customer webcam right of the staff camera.
    if(customerCameraConfig.value.stream) {
        canvasConfig.value.ctx.drawImage(customerCameraConfig.value.video,
        customerCameraConfig.value.left,
        customerCameraConfig.value.top,
        customerCameraConfig.value.width,
        customerCameraConfig.value.height);
    }

    // draw cocustomer webcam right of the customer camera.
    if(cocustomerCameraConfig.value.stream) {
        canvasConfig.value.ctx.drawImage(cocustomerCameraConfig.value.video,
        cocustomerCameraConfig.value.left,
        cocustomerCameraConfig.value.top,
        cocustomerCameraConfig.value.width,
        cocustomerCameraConfig.value.height);
    }

}
//------- Canvas End -------//

</script>
<style scoped>
.canvas-container {
    position: absolute;
    border: 1px solid pink;
    z-index: -1;
    width: 1;
    height: 1;
    top: -200000px;
    pointer-events: none;
}
.canvas-container canvas {
    width: 500px;
    height: 200px;
}

</style>
