<template>
    <div class="modal-container modal-large">
        <div class="modal-header">
            MS Vehicle matches for {{modal.passedData.vin}}
        </div>
        <div class="modal-body">
            <RichTable v-if="vinMatches"
                       :tableData="vinMatches"
                       :selectable="'radio'"
                       :selectionKey="row => row.id"
                       @selectionChanged="richTableItemSelected"
                       :headers="headers">
            </RichTable>
            <div v-else :style="$grid('1')">
                <span>There are no vehicle matches. Please select Make, Model, Year, and Style using the dropdowns below:</span>
                <div :style="$grid('2-2-1')">
                    <InputDropdown label="Make"
                                   v-model:saturn="make"
                                   :list="marketScanMakesModels.makes"
                                   :valueMap="(p) => p.id"
                                   :text="(p) =>  p.name.replaceAll('_',' ')"
                                   @change="updateDropdowns('make')">
                    </InputDropdown>
                    <InputDropdown label="Model"
                                   v-model:saturn="model"
                                   :list="filteredModels"
                                   :disabled="!filteredModels"
                                   :valueMap="(p) => p.id"
                                   :text="(p) =>  p.name.replaceAll('_',' ')"
                                   @change="updateDropdowns('model')">
                    </InputDropdown>
                    <InputDropdown label="Year"
                                   v-model:saturn="year"
                                   :list="filteredYears"
                                   :disabled="!filteredYears"
                                   :valueMap="(p) => p.yearDisplay"
                                   :text="(p) =>  p.yearDisplay"
                                   @change="updateDropdowns('year')">
                    </InputDropdown>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div>
                <button class="button-save" @click="save()" :disabled="id == null || isBusy">OK</button>
            </div>
        </div>
    </div>
</template>


<script>
    import $modal from '@core/services/modal'
    import RichTable from '@core/components/RichTable.vue'
    import InputDropdown from '@core/components/InputDropdown.vue'
    import util from '@core/services/util'
    import ENUMS from "@core/classes/Enums"
    import api from '@core/services/api'

    export default {
        name: "modalMSvehicles",
        props: ['modal'],
        data() {
            return {
                id: null,
                vinMatches: null,
                vehicle: null,
                marketScanMakesModels: null,
                filteredModels: null,
                filteredYears: null,
                make: null,
                model: null,
                year: null,
                style: null,
                headers: [
                    { name: 'id', display: "ID" },
                    { name: 'yearDisplay', display: "Year" },
                    { name: 'shortDescription', display: "Description" },
                ]
            }
        },
        computed: {
            ENUMS() {
                return ENUMS;
            },
            util() {
                return util;
            },
        },
        validations() {
            const validations = {
            }
            return validations;

        },
        async created() {
            this.isBusy = true;
            this.vinMatches = this.modal.passedData.vinMatches;
            this.vehicle = this.modal.passedData.vehicle;
            this.marketScanMakesModels = this.modal.passedData.marketScanMakesModels;
            this.isBusy = false;
        },
        methods: {
            updateFilteredModels() {
                this.filteredModels = this.make && this.marketScanMakesModels && this.marketScanMakesModels.models.find(m => m.makeId = this.make);
            },
            save() {
                $modal.close(this.id);
            },
            richTableItemSelected(selectedData) {
                console.log('selectedData', selectedData);
                this.id = selectedData.id;
            },
            updateDropdowns(source) {
                //do something to update the dropdowns based on selection
                //source will be the string name of the calling input
                console.log('source', source);
                if (source === 'make') {
                    this.id = null;
                    this.vinMatches = null;
                    this.filteredYears = null;
                    this.filteredModels = this.marketScanMakesModels.models.filter(m => m.makeID == this.make);
                    console.log('filteredModels', this.filteredModels);
                } else if (source === 'model') {
                    this.id = null;
                    this.vinMatches = null;
                    api.marketScan.getYearsForMakeModel(this.modal.passedData.mscanAccount, this.make, this.model, true).then((response) => {
                        if (response && response.data) {
                            this.filteredYears = util.clone(response.data);
                        }
                    });

                    console.log('filteredYears', this.filteredYears);
                } else if (source === 'year') {
                    api.marketScan.getVehiclesModelYear(this.modal.passedData.mscanAccount, this.model, this.year, true).then((response) => {
                        if (response && response.data) {
                            this.vinMatches = util.clone(response.data);
                        }
                    })
                }

            }
        },
        components: {
            RichTable: RichTable,
            InputDropdown: InputDropdown,
        }
    };
</script>
<style>
</style>
