import settings from 'settings'
import { reactive } from 'vue';

export default {

    install(app) {

        app.config.globalProperties.$static = {
            adminPages: [
                { isActive: false, isAdmin: true, name: 'Global Settings', stateName: 'admin-global-settings' },
                { isActive: false, isAdmin: false, name: 'Users', stateName: 'admin-users' },
                { isActive: false, isAdmin: true, name: 'Stores', stateName: 'admin-stores' },
                { isActive: false, isAdmin: true, name: 'Products', stateName: 'admin-products' },
                { isActive: false, isAdmin: true, name: 'Lenders', stateName: 'admin-lenders' },
                { isActive: false, isAdmin: true, name: 'Reserve Profit Calculations', stateName: 'admin-reserve-profit-calculations' },
                { isActive: false, isAdmin: true, name: 'Surcharges', stateName: 'admin-surcharges' },
                { isActive: false, isAdmin: true, name: 'Providers', stateName: 'admin-providers' },
                { isActive: false, isAdmin: true, name: 'Warranty Expiration Rules', stateName: 'admin-warranty-expiration-rules' },
                { isActive: false, isAdmin: true, name: 'Deal Checklist Items', stateName: 'admin-deal-checklist-items' },
                { isActive: false, isAdmin: true, name: 'Term Spreads', stateName: 'admin-term-spreads' },
                { isActive: false, isAdmin: true, name: 'Deviation Reasons', stateName: 'admin-deviation-reasons' },
                { isActive: false, isAdmin: true, name: 'Contract In Transit Statuses', stateName: 'admin-contract-in-transit-statuses' },
                { isActive: false, isAdmin: true, name: 'Deal Flow Statuses', stateName: 'admin-deal-flow-statuses' },
                { isActive: false, isAdmin: true, name: 'Positions', stateName: 'admin-positions' },
                { isActive: false, isAdmin: true, name: 'Sale Types', stateName: 'admin-sale-types' },
                { isActive: false, isAdmin: true, name: 'Deal Types', stateName: 'admin-deal-types' },
                { isActive: false, isAdmin: true, name: 'Vehicle Types', stateName: 'admin-vehicle-types' },
                { isActive: false, isAdmin: true, name: 'Document Types', stateName: 'admin-document-types' },
                { isActive: false, isAdmin: true, name: 'Advertisement Types', stateName: 'admin-advertisement-types' },
                { isActive: false, isAdmin: true, name: 'Advertisement Sources', stateName: 'admin-advertisement-sources' },
                { isActive: false, isAdmin: true, name: 'Manage CashBash', stateName: 'admin-manage-cashbash' },
                { isActive: false, isAdmin: true, name: 'General Mappings', stateName: 'admin-general-mapping' },
                { isActive: false, isAdmin: true, name: 'Legal Terms', stateName: 'admin-legal-terms' },
                { isActive: false, isAdmin: false, name: 'Saturn Applications', stateName: 'admin-saturn-applications' },
                { isActive: false, isAdmin: true, name: 'Credit Bureaus', stateName: 'admin-credit-bureaus' },
                { isActive: false, isAdmin: true, name: 'CarFax Credentials', stateName: 'admin-carfax-credentials' },
                { isActive: false, isAdmin: true, name: 'OAuth Users', stateName: 'admin-oauth' },
            ],
            utilitiesPages:
                settings.environmentName != 'PRODUCTION' ?
                    [
                        { isActive: false, name: 'Active Deals', stateName: 'utilities-active-deals' },
                        { isActive: false, name: 'Copy From Production', stateName: 'utilities-copy-from-prod' },
                        { isActive: false, name: 'Error Logs', stateName: 'utilities-error-logs' },
                        { isActive: false, name: 'Custom Variables', stateName: 'custom-variables' },
                        { isActive: false, name: 'Manage Base Paperwork', stateName: 'utilities-manage-base-paperwork' },
                        { isActive: false, name: 'Code Editor Themes', stateName: 'utilities-code-editor-themes' },
                        { isActive: false, name: 'Vehicle Records', stateName: 'utilities-vehicle-records' },
                        { isActive: false, name: 'Data Definitions', stateName: 'utilities-data-definitions-page'}

                    ] : [
                        { isActive: false, name: 'Active Deals', stateName: 'utilities-active-deals' },
                        { isActive: false, name: 'Error Logs', stateName: 'utilities-error-logs' },
                        { isActive: false, name: 'Vehicle Records', stateName: 'utilities-vehicle-records' },
                        { isActive: false, name: 'Data Definitions', stateName: 'utilities-data-definitions-page'}
                    ],
            reportPages: [
                { isActive: false, name: 'Products', stateName: 'products-report' },
                //{ isActive: false, name: 'My-First-Report', displayName: 'My First Report', stateName: 'report1' },
            ]
        };

        app.config.globalProperties.$global = reactive({
            selectedStore: settings.userStores?.[0],
            dealNumber: null,
            isAdminView: false,
            isManageOverridesEnabled: false,
            customerWindow: null,
            maxMilesToBeConsideredNewCar: null
        });

        app.config.globalProperties.$grid = (colTemplate, gap = "10px") => {
            let templateValues = colTemplate.toString().split('-');
            let columnTemplate = ""
            templateValues.forEach(value => {
                columnTemplate += ' '
                if (value.includes('px') ||
                    value.includes('%') ||
                    value.includes('cm') ||
                    value.includes('mm') ||
                    value.includes('in') ||
                    value.includes('pt') ||
                    value.includes('pc') ||
                    value.includes('em') ||
                    value.includes('ex') ||
                    value.includes('ch') ||
                    value.includes('rem') ||
                    value.includes('vw') ||
                    value.includes('vh') ||
                    value.includes('vmin') ||
                    value.includes('vmax')) {

                    columnTemplate += value
                } else {
                    columnTemplate += value + 'fr'
                }
            })
            let gapOutside = gap.split(' ');
            gapOutside = parseInt(gapOutside[0])

            return {
                width: '100%',
                display: 'grid',
                gridTemplateColumns: columnTemplate,
                gridGap: gap,
                marginBottom: gapOutside + 'px',
            }
        };


        app.config.globalProperties.$sandbox = {
            someValue: 1
        };

        settings.globalProperties = app.config.globalProperties
    }

}