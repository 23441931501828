<template>
    <div class="modals-container" ref="modalContainer">
        <Modal v-for="(m, index) in modals" :key="index" :data="m.data" :src="m.src"></Modal>
    </div>
</template>


<script>
    import Modal from '@core/components/Modal.vue'
    import $modal from '@core/services/modal'

    export default {
        name: "ModalManager",
        data() {
            return {
                modals: $modal.modals
            }
        },
        computed: {},
        methods: {},
        components: { Modal }
    };
</script>

<style></style>