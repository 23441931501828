<template>
    <TooltipComponent :tooltip direction="right" maxWidth="450">
        <template #showFromOutside>
            <button v-if="ENUMS.docTypesThatWeActivate.includes(doc.type)" :class="{'contract-numbers-container': true, 'button-span': true, 'admin-access': $global.isAdminView || doc.memberNumber || doc.contractNumber}" @click="openEditContractNumber(doc)" style="width: 100%; min-height: 25px">
                <div v-if="doc.status == ENUMS.PaperworkDocumentStatus.Voided" class="text-warning bold-text align-start">
                    <i class="fas fa-info-circle iconSpacing"></i>
                    <span>Voided</span>

                </div>
                <div v-else-if="doc.status == ENUMS.PaperworkDocumentStatus.ErrorVoiding" class="text-error bold-text align-start">
                    <i class="fas fa-info-circle iconSpacing"></i>
                    <span>Error Voiding</span>
                </div>
                <div v-else-if="doc.memberNumber || doc.contractNumber" :class="{'text-success': !doc.cancellationInfo, 'text-warning': doc.cancellationInfo, 'contract': true, 'bold-text': true}">
                    <i class="fas fa-info-circle iconSpacing"></i>
                    <div style="display:flex; flex-direction:column; align-items:flex-start">
                        <span class="bold-text" v-if="doc.memberNumber">{{ doc.memberNumber }}</span>
                        <!-- <span v-if="doc.memberNumber && doc.contractNumber">{{'\u00A0' + "/" + '\u00A0'}}</span> -->
                        <span class="bold-text" v-if="doc.contractNumber">{{ doc.contractNumber }}</span>
                    </div>
                </div>
                <div v-else-if="dateToActivate" class="text-success bold-text align-start" >
                    <i class="fas fa-info-circle iconSpacing"></i>
                    <span>Activates on {{ dateToActivate }}</span>
                </div>
                <div v-else-if="doc.activationInfo && doc.activationInfo.activationError" class="text-error  bold-text align-start" >
                    <i class="fas fa-info-circle iconSpacing"></i>
                    <span>Error Activating</span>
                </div>
                <div v-else-if="$global.isAdminView">
                    <span class="bold-text">Add #s?</span>
                </div>
            </button>
            <div v-else class="contract-numbers-container defaultCursor">
                <div v-if="doc.status == ENUMS.PaperworkDocumentStatus.Voided" class="text-warning bold-text align-start">
                    <i class="fas fa-info-circle iconSpacing"></i>
                    <span>Voided</span>
                </div>
                <!-- Assume it's uploaded paperwork -->
                <!-- If the document is a DDI Document -->
                <button
                   v-else-if="isDDIDocument && (contractNumber || controlNumber)"
                   :class="{'contract-numbers-container': true, 'button-span': true, 'admin-access': $global.isAdminView || doc.memberNumber || doc.contractNumber}"
                   @click="openEditContractNumber(doc)" style="width: 100%; min-height: 25px"
               >
                <div :class="{'contract': true, 'bold-text': true, 'text-success': true}">
                    <div style="display:flex; flex-direction:column; align-items:flex-start">
                        <span class="bold-text" v-if="contractNumber">{{ contractNumber }}</span>
                        <!-- <span v-if="doc.memberNumber && doc.contractNumber">{{'\u00A0' + "/" + '\u00A0'}}</span> -->
                        <span class="bold-text" v-if="controlNumber">{{ controlNumber }}</span>
                    </div>
                </div>

                </button>
                <span class="text-success bold-text" v-else>N/A</span>

            </div>

        </template>
    </TooltipComponent>
</template>

<script>
    import ENUMS from "@core/classes/Enums"
    import TooltipComponent from '@core/components/TooltipComponent.vue'
    import moment from "moment";

    export default {
        name: "DocumentPacketDocumentActivation",
        props: ["doc", "paperworkBusy", "user", "openEditContractNumber", "tooltipInformation", "fimenu"],
        computed:{
            ENUMS() {
                return ENUMS
            },
            contractNumber() {
                const documentAssociationDealId = this.doc.associations.find(x => x.type == ENUMS.DOC_ASSOCIATION.DDIDealId);

                return this.fimenu.vehicle.registrationInfo.plateTransactions.find(x => {
                    return x.providerDealId == documentAssociationDealId.id
                })?.providerDealId ?? null
            },
            controlNumber() {
                if(this.doc.docInfo.docType != "TEMPTAG") return null;

                const documentAssociationDealId = this.doc.associations.find(x => x.type == ENUMS.DOC_ASSOCIATION.DDIDealId);

                return this.fimenu.vehicle.registrationInfo.plateTransactions.find(x => {
                    return x.providerDealId == documentAssociationDealId.id
                })?.controlNumber ?? null
            },
            isDDIDocument(){
                // doc could be either uploadedDocument or document from document pakcet
                return !!this.doc?.associations?.some(x => x.type == ENUMS.DOC_ASSOCIATION.DDIDealId);
            },
            tooltip() {
                return this.tooltipInformation(this.doc);
            },
            dateToActivate() {
                if (!this.doc.activationInfo?.dateToActivate) return null;

                return moment
                    .utc(this.doc.activationInfo.dateToActivate)
                    .format("MM/DD/YYYY");
            },
        },
        methods: {
            documentAssociation() {
                const isUploadedDocument = !ENUMS.docTypesThatWeActivate.includes(this.doc.type)
                if(!isUploadedDocument) return null;

                return this.doc.associations.find(x => x.type == ENUMS.DOC_ASSOCIATION.Deal)
            }
        },
        components: {
            TooltipComponent
        }
    };
</script>

<style>


    .contract {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

    .align-start{
        display:flex;
        flex-direction: flex-start;
    }

    .contract-numbers-container.admin-access:hover{
        background-color: rgba(0,0,0,0.1)
    }

</style>