<template>
    <div class="modal-container raffle">
        <div class="modal-header">
            Cash Bash
            <div v-if="activeRaffle">Cards: {{ ticketsSelected }} of {{ totalTickets }}</div>

        </div>

        <div class="modal-body" :style="{minHeight: '710px'}" v-if="endSelection == null || activeRaffle">
            <div class="loading" v-if="savingTicket">
                <img v-if="!alreadySelected" src="@static/img/SaturnLogoAnim.svg" />
                <div v-if="alreadySelected" class="card-was-selected">
                    <span>The card that you tried to select was already selected by another person after the last refresh. Please select another one.</span>
                    <button @click="closeNotification">Close</button>
                </div>
            </div>
            <div class="card-container" v-if="activeRaffle">
                <div class="card" v-for="(card,index) in cardsOnPage[pageActive]" :key="index">
                    <div @click="unselectCard(card)" :class="(card.selectedBy == selfUser.id) ? 'card-selected green' : 'card-selected red'" v-if="isSelected(card.winnerLive)">
                        <div v-for="(name, index) in splitName(card.winnerName)" :key="index">{{ name ? name.toUpperCase() : "" }}</div>
                    </div>
                    <div class="back face">
                        <div :class="index % 2 ? 'inside-container red' : 'inside-container blue' " @click="selectCard(card)">
                            <div class="inside-background">
                                <img :src="index % 2 ? 'dist/img/raffle/red.png' : 'dist/img/raffle/blue.png' " />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-counter" v-if="activeRaffle">
                <button @click="setPage(pageActive - 1)" class="nav-button" :disabled="pageActive == 0">Previous</button>
                <div @click="setPage(index)" :class="index == pageActive ? pageType(cardsOnPage[index]) + ' selected' : pageType(cardsOnPage[index])" :key="index" v-for="(page,index) in cardsOnPage"></div>
                <button @click="setPage(pageActive + 1)" class="nav-button next" :disabled="pageActive == (cardsOnPage.length - 1)">Next</button>
            </div>
        </div>
        <div class="modal-body center congrats" v-if="cantSelect">
            <div class="congratulation">Congratulations</div>
            <div v-if="!isLive">Your {{ totalTickets }} cards have been selected.</div>
            <div class="money-group-label">You have the potential of earning between:</div>
            <div class="money-group">
                <div class="money-min"><i class="fas fa-arrow-alt-circle-down"></i>{{ util.formatPrice(minMoney, 0) }}</div>
                <div class="money-apart"> - </div>
                <div class="money-max"><i class="fas fa-arrow-alt-circle-up"></i>{{ util.formatPrice(maxMoney, 0) }}</div>
            </div>
            <div class="result-notes" v-if="isLive">
                You will be able to do your selection via an online event.  <br />
                <router-link :to="{name: 'cashbashplayer'}">
                    Go To Player Page
                </router-link>
            </div>
            <div class="result-notes" v-else>The results of your selection will be via an online event.  <br />The link will be sent by Avidan via email.</div>
        </div>
        <div class="raffle-results" v-if="showResults">

            <div class="employee-results">
                <div v-for="employees in allUsers" class="list-container" :key="employees.id">
                    <div class="outter-container">
                        <div class="info-section">
                            <div class="name">{{ employees.name.toUpperCase() }}</div>
                            <div class="dealer">{{ employees.store }} </div>
                        </div>
                        <div class="money"><div>{{ util.formatPrice(employees.amount, 0) }}</div></div>
                    </div>
                </div>
                <div v-if="allFlatUsers.length != 0" class="flat-envelopes">Flat Envelopes</div>
                <div v-for="employees in allFlatUsers" class="list-container" :key="employees.id">
                    <div class="outter-container">
                        <div class="info-section">
                            <div class="name">{{ employees.name.toUpperCase() }}</div>
                            <div class="dealer">{{ employees.store }} </div>
                        </div>
                        <div class="money"><div>{{ util.formatPrice(employees.envelopes * flatAmount, 0) }}</div></div>
                    </div>
                </div>
            </div>
            <div class="divider-results "></div>
            <div class="employee-positions">
                <div class="top-positions">
                    <div class="third-position">
                        <div :class="{'container': allUsers.length != 0}">
                            <div v-if="allUsers.length != 0" class="money-amount">{{ util.formatPrice(allUsers[2].amount, 0) }}</div>
                            <div v-if="allUsers.length != 0" class="winner-name">{{ allUsers[2].name.toUpperCase() }}</div>
                        </div>
                    </div>
                    <div class="first-position">
                        <div :class="{'container': allUsers.length != 0}">
                            <div v-if="allUsers.length != 0" class="money-amount">{{ util.formatPrice(allUsers[0].amount, 0) }}</div>
                            <div v-if="allUsers.length != 0" class="winner-name">{{ allUsers[0].name.toUpperCase() }}</div>
                        </div>
                    </div>
                    <div class="second-position">
                        <div :class="{'container': allUsers.length != 0}">
                            <div v-if="allUsers.length != 0" class="money-amount">{{ util.formatPrice(allUsers[1].amount, 0) }}</div>
                            <div v-if="allUsers.length != 0" class="winner-name">{{ allUsers[1].name.toUpperCase() }}</div>
                        </div>
                    </div>
                </div>

                <div class="personal-position" v-if="allUsers.length != 0 && selfUser">
                    <div class="employee-name">{{ selfUser.name.toUpperCase() }}</div>
                    <div class="stats">
                        <div class="position">Position: {{ getPosition }}</div>
                        <div class="envelopes">Envelopes: {{ selfUser.envelopesInit }}</div>
                    </div>
                    <div class="employee-money">{{ totalMoney }}</div>
                </div>
            </div>
        </div>
        <div class="modal-body no-data-to-show" v-if="noDataToShow">
            <h2>No Data To Show</h2>
        </div>
        <div class="modal-footer">
            <button class="button-unwind" @click="cancel()">Close</button>
        </div>
    </div>
</template>


<script>
    import $modal from '@core/services/modal'
    import api from '@core/services/api'
    import moment from 'moment'
    import util from '@core/services/util'

    export default {
        name: "modalTicket",
        props: ['modal'],
        data() {
            return {
                endSelection: null,
                showResults: null,
                cardsOnPage: [],
                pageActive: 0,
                totalPages: 0,
                savingTicket: false,
                ticketsSelected: 0,
                alreadySelected: false,
                allUsers: [],
                allCards: [],
                minMoney: 0,
                maxMoney: 0,
                isLive: false,
                selfUser: {
                    id: -1,
                    envelopesInit: 0,
                    name: ''
                },
                cashId: null,
                allFlatUsers: [],
                flatAmount: 0,
                noDataToShow: false,

            };
        },
        computed: {

            util() {
                return util;
            },
            cantSelect() {
                return this.endSelection && !this.showResults
            },
            activeRaffle() {
                return this.endSelection == false
            },
            getEmployee() {
                return this.allUsers.filter(aU => aU.id == this.selfUser.id)
            },
            getPosition() {
                return this.allUsers.findIndex(aU => aU.id == this.selfUser.id) + 1
            },
            totalTickets() {
                return this.selfUser.envelopesInit
            },
            totalMoney() {
                const user = this.allUsers.find(u => u.id == this.selfUser.id)

                if (user) {
                    return util.formatPrice(user.amount, 0)
                }
                return ''
            }
        },
        mounted(){
            import('@static/css/cashBash.css');
        },
        created() {

            if (this.endSelection == null && this.showResults == null) {
                this.savingTicket = true
                api.users.cashBash.getActualToPlay().then(response => {
                    if (response.data) {
                        const cashBash = response.data
                        this.cashId = cashBash.id
                        this.allUsers = cashBash.employeesContestInfo
                        this.allFlatUsers = cashBash.flatEmployeeContestInfo.flatEmployeesContestInfo
                        this.flatAmount = cashBash.flatEmployeeContestInfo.flatAmount
                        this.isLive = cashBash.isLive
                        const user = this.allUsers.find(u => u.id == this.modal.passedData.userInfo.id)
                        if (user) {
                            this.selfUser = this.allUsers.find(u => u.id == this.modal.passedData.userInfo.id)
                        }
                        if (cashBash.isLive) {
                            if (cashBash.finishAt) {
                                this.endSelection = true
                                this.showResults = true
                            } else if (cashBash.closedAt) {
                                this.endSelection = true
                                this.showResults = false
                            }
                        } else {
                            if (cashBash.finishAt) {
                                this.endSelection = true
                                this.showResults = true
                            } else if (cashBash.closedAt) {
                                this.endSelection = true
                                this.showResults = false
                            } else {
                                this.endSelection = false
                                this.showResults = false
                            }
                        }

                        this.allCards = cashBash.cardsContestInfo

                        if (this.activeRaffle) {
                            this.totalPages = this.allCards ? Math.ceil(this.allCards.length / 25) : 0
                            this.allCards.sort((a, b) => a.index - b.index)
                            this.ticketsSelected = this.allCards.filter(r => r.winnerLive == this.selfUser.id).length

                            for (let i = 1; i <= this.totalPages; i++) {
                                this.cardsOnPage.push(this.allCards.slice(((i - 1) * 25), i * 25))
                            }

                        } else if (this.showResults) {
                            this.allUsers = this.allUsers.sort((a, b) => b.amount - a.amount)

                        } else if (this.cantSelect) {
                            this.allCards.sort((a, b) => a.price - b.price)

                            this.allCards.forEach((c, index) => {
                                if (index < this.selfUser.envelopesInit) {
                                    this.minMoney += c.price
                                }
                                if (index >= (this.allCards.length - this.selfUser.envelopesInit)) {
                                    this.maxMoney += c.price
                                }
                            })
                        }

                    } else {
                        this.noDataToShow = true;
                    }

                }).catch(error => {
                    console.error(error)
                }).finally(() => {
                    this.savingTicket = false
                })
            }
        },
        methods: {
            closeNotification() {
                this.alreadySelected = false;
                this.savingTicket = false;
            },
            splitName(name) {
                return name ? name.trim().split(' ') : []
            },
            setPage(index) {
                this.pageActive = index
            },
            pageType(cardArray) {
                if (cardArray.some(cA => cA.winnerLive == util.getDefaultGuid())) {
                    return 'pages'
                } else {
                    if (cardArray.some(cA => cA.winnerLive == this.selfUser.id)) {
                        return 'pages mix'
                    } else {
                        return 'pages red'
                    }
                }
            },
            unselectCard(card) {
                if (card.winnerLive == this.selfUser.id) {
                    this.savingTicket = true
                    const temp = { ...card }
                    temp.selectedBy = util.getDefaultGuid()
                    temp.whoSelected = null

                    api.users.cashBash.setCard({ cashId: this.cashId, ticket: { ...temp } }).then(response => {
                        if (response.data) {
                            response.data.sort((a, b) => a.index - b.index)
                            this.ticketsSelected = response.data.filter(r => r.winnerLive == this.selfUser.id).length
                            this.cardsOnPage = []
                            for (let i = 1; i <= this.totalPages; i++) {
                                this.cardsOnPage.push(response.data.slice(((i - 1) * 25), i * 25))
                            }
                        }
                    }).catch(err => {
                         
                        console.error(err)
                    }).finally(() => {
                        if (!this.alreadySelected) {
                            setTimeout(() => {
                                this.savingTicket = false
                            }, 1500)
                        }
                    })
                }
            },
            selectCard(card) {
                if ((this.selfUser.envelopes > this.ticketsSelected) && (!this.isSelected(card.selectedBy) || card.selectedBy == this.selfUser.id)) {
                    this.savingTicket = true
                    const temp = { ...card }
                    temp.selectedBy = this.selfUser.id
                    temp.whoSelected = this.selfUser.name

                    api.users.cashBash.setCard({ cashId: this.cashId, ticket: { ...temp } }).then(response => {
                        // console.log(response.data)
                        if (response.data) {
                            response.data.sort((a, b) => a.index - b.index)
                            this.ticketsSelected = response.data.filter(r => r.winnerLive == this.selfUser.id).length
                            if (response.data[card.index].winnerLive != temp.selectedBy) {
                                this.alreadySelected = true
                            }
                            this.cardsOnPage = []
                            for (let i = 1; i <= this.totalPages; i++) {
                                this.cardsOnPage.push(response.data.slice(((i - 1) * 25), i * 25))
                            }
                        }

                    }).catch(err => {
                         
                        console.error(err)
                    }).finally(() => {
                        if (!this.alreadySelected) {
                            setTimeout(() => {
                                this.savingTicket = false
                            }, 1500)
                        }
                    })
                }

            },
            isSelected(value) {

                return value ? value != "00000000-0000-0000-0000-000000000000" : false
            },
            close() {
                $modal.close();
            },
            cancel() {
                $modal.cancel();
            }
        },
        components: {

        }
    };
</script>