<template>
    <Panel v-bind="{...panelObject, class: 'deal-terms'}">
        <template #HeaderComponent>
            <div class="panel-buttons">
                <InputRichDropdown v-if="!hideTerms"
                                   v-model:saturn="priceProfit"
                                   :list="profitPriceItems"
                                   :valueMap="(p) => p.value"
                                   :display="(p) => p.text"
                                   :height="30"
                                   class="ignore-all-locks"
                                   @change="changePriceProfit" />
            </div>
        </template>

        <div class="collapse-all-stack" @click="collapseAll">
            <span class="fa-stack fa-1x stack-container">
                <i class="far fa-square fa-stack-1x stack-border" />
                <i class="fas fa-square fa-stack-1x stack-background" />

                <i :class="['fas', 'fa-stack-1x', 'stack-sign', (!allCollapse) ? 'fa-plus-square' : 'fa-minus-square']" />
            </span>
        </div>

        <!--CASH DEAL-->
        <div v-if="data.isCash() && !data.isDraft() && data.hasCoverageTerms">
            <!--HEADERS-->
            <div class="terms-grid">
                <span style="grid-column: span 3" />

                <span class="spansubtitle">Base Payment</span>

                <template v-if="!hideTerms">
                    <span v-for="(coverageTerm, index) in data.coverageTerms" :key="coverageTerm.term" class="spantitle ignore-deal-lock">
                        <button v-if="coverageTerm.selected && !areDealTermsLocked" class="button-span unselect-coverage ignore-deal-lock" @click="unselectCoverage(index)">
                            <i class="fas fa-times-circle" />
                        </button>

                        <span v-if="coverageTerm.selected || coverageTerm.preferred"
                              :class="{'selected-coverage-column': coverageTerm.selected, 'preferred-coverage-column' : coverageTerm.preferred}"
                              :style="moveSelectedCoverageColumn(index)" />

                        {{ (coverageTerm.term == 0) ? 'Custom Coverage' : coverageTerm.term + ' Month Coverage' }}

                        <span style="position: relative; display: block;">
                            <button v-if="coverageTerm.term == 0 && !anyTermSelected && !data.isSpectator" class="button-span clear-custom-coverage" @click="deleteCustomCoverage(index)">
                                <i class="fas fa-trash-alt" />
                            </button>
                        </span>
                    </span>

                    <div v-if="data.hasCoverageTerms && !hasCustomCoverage" class="custom-selector">
                        <InputRichDropdown v-model:saturn="customCoveragePicker"
                                           :height="30"
                                           :list="availableCoverageTerms"
                                           :disabled="!(isPaymentSheetPrinted || hasCustomerSeenCarousel) || isBusy" />
                        <button :disabled="isAddCustomDisabled" @click="addCustomTerm(customCoveragePicker)">
                            <i class="fas fa-copy" />
                        </button>
                    </div>
                </template>
            </div>

            <!--PRICES-->
            <div class="terms-grid">
                <span style="grid-column: span 3" />

                <span :style="{textAlign: 'right', marginTop: '3px', marginLeft: '-20px', justifySelf: 'flex-end'}">
                    <TooltipComponent class="disabled"
                                      :tooltip="tooltipTextCreation(null, allTerms[0])"
                                      :text="getBasePrice(allTerms[0])">
                    </TooltipComponent>
                </span>

                <template v-if="!hideTerms">
                    <div v-for="coverageTerm in data.coverageTerms" :key="coverageTerm.term">
                        <div class="termprice">
                            <TooltipComponent :class="{'disabled': true, 'displaypriceselected': coverageTerm.selected, 'displayprice': !coverageTerm.selected}"
                                              :tooltip="tooltipTextCreation(coverageTerm, allTerms[0])"
                                              :text="getFinalPrice(allTerms[0], coverageTerm)">
                            </TooltipComponent>
                        </div>
                    </div>
                </template>

            </div>
        </div>

        <!--FINANCE/DRAFT DEAL-->
        <div v-else-if="(data.isFinance() || data.isDraft()) && data.hasCoverageTerms()" :style="{'display': 'flex', 'flex-direction': 'column', 'gap': '5px'}">
            <!--HEADERS-->
            <div class="terms-grid">
                <span>Mos.</span>

                <div :style="{'display': 'flex', 'justifyContent': 'space-around'}">
                    <span class="money-factor-rate">
                        Sell Rate
                    </span>
                    <span class="money-factor-rate">
                        Buy Rate
                    </span>
                </div>

                <span />

                <span class="spansubtitle">Base Payment</span>

                <template v-if="!hideTerms">
                    <span v-for="(coverageTerm, index) in data.coverageTerms" :key="coverageTerm.term" class="spantitle">
                        <button v-if="coverageTerm.selected && !areDealTermsLocked" class="ignore-deal-lock button-span unselect-coverage ignore-deal-lock" @click="unselectCoverage(index)">
                            <i class="fas fa-times-circle" />
                        </button>

                        <span v-if="coverageTerm.selected || coverageTerm.preferred"
                              :class="{'selected-coverage-column': coverageTerm.selected, 'preferred-coverage-column': coverageTerm.preferred}"
                              :style="moveSelectedCoverageColumn(index)" />

                        {{ (coverageTerm.term == 0) ? 'Custom Coverage' : coverageTerm.term + ' Month Coverage' }}

                        <span v-if="coverageTerm.term == 0 && !anyTermSelected && !data.isSpectator" style="position: relative; display: block;">
                            <button class="button-span clear-custom-coverage" @click="deleteCustomCoverage(index)">
                                <i class="fas fa-trash-alt" />
                            </button>
                        </span>
                    </span>

                    <div v-if="data.hasCoverageTerms && !hasCustomCoverage" class="custom-selector">
                        <InputRichDropdown v-model:saturn="customCoveragePicker"
                                           :list="availableCoverageTerms"
                                           :width="60"
                                           :disabled="!(isPaymentSheetPrinted || hasCustomerSeenCarousel) || isBusy">
                        </InputRichDropdown>
                        <button :disabled="isAddCustomDisabled" @click="addCustomTerm(customCoveragePicker)">
                            <i class="fas fa-copy" />
                        </button>
                    </div>
                </template>
            </div>

            <!--PRICES-->
            <div :class="{'terms-grid': true, 'hide-term': hideTerms}" v-for="(term, index) in allTerms" :key="index">
                <span v-if="index <= 1 && data.isDraft()" style="grid-column: 1 / -1;">
                    {{ (index === 0) ? 'Customer Terms' : 'Finance Terms' }}
                </span>

                <InputNumber v-model:saturn="term.term"
                             :disabled="isBusy || inputsDisabled"
                             placeholder="Months"
                             :showIcon="false"
                             max="999"
                             :height="40"
                             @blur="(e) => onDealTermBlur(term, e.target.value, index)"
                             @focus="onFocusSetRatingStatus"
                             @change="recalculate(term)"
                             :invalid="validation.loanTerms['term' + (index + 1)].term.$invalid">
                    <template #validation>
                        <div v-if="validation.loanTerms['term' + (index + 1)].term.minValue?.$invalid">Term must be at least 1.</div>
                        <div v-else-if="validation.loanTerms['term' + (index + 1)].term.required?.$invalid">Term is required</div>
                    </template>
                </InputNumber>

                <div style="display: flex;">
                    <InputNumber v-model:saturn="term.sellrate"
                                 placeholder="Sell Rate"
                                 :class="{'flash': isReady}"
                                 label="%"
                                 :showIcon="false"
                                 :precision="2"
                                 :disabled="!term.term || inputsDisabled"
                                 :isPercent="true"
                                 :height="40"
                                 @change="recalculate(term)"
                                 :invalid="validation.loanTerms['term' + (index + 1)].sellrate.$invalid">
                        <template #validation>
                            <div v-if="validation.loanTerms['term' + (index + 1)].sellrate.minValue?.$invalid">Sell Rate must be equal or greater than {{ term.buyrate }}</div>
                            <div v-else-if="validation.loanTerms['term' + (index + 1)].sellrate.required?.$invalid">Sell Rate is required</div>
                        </template>
                    </InputNumber>
                    <InputNumber v-model:saturn="term.buyrate"
                                 placeholder="Buy Rate"
                                 :class="{'flash': isReady}"
                                 label="%"
                                 :showIcon="false"
                                 :precision="2"
                                 :disabled="!term.term || inputsDisabled"
                                 :isPercent="true"
                                 :height="40"
                                 @change="recalculate(term)"
                                 :invalid="validation.loanTerms['term' + (index + 1)].buyrate.$invalid">
                        <template #validation>
                            <div v-if="validation.loanTerms['term' + (index + 1)].buyrate.minValue?.$invalid">Buy Rate must be at least {{ term.sellrate - 3 }}%</div>
                            <div v-else-if="validation.loanTerms['term' + (index + 1)].buyrate.maxValue?.$invalid">Buy Rate must be equal or less than {{ term.sellrate }}%</div>
                            <div v-else-if="validation.loanTerms['term' + (index + 1)].buyrate.required?.$invalid">Buy Rate is required</div>
                        </template>
                    </InputNumber>
                </div>

                <span />

                <span v-if="term.term && term.sellrate >= 0" class="displaypricebold">
                    <TooltipComponent class="disabled"
                                      :tooltip="tooltipTextCreation(null, term)"
                                      :text="getBasePrice(term)">
                    </TooltipComponent>
                </span>

                <template v-if="!hideTerms">
                    <div v-for="coverageTerm in data.coverageTerms" :key="coverageTerm.term">
                        <div v-if="term.term && term.sellrate >= 0" class="termprice">
                            <TooltipComponent v-if="term.term > 0 && getFinalPrice(term, coverageTerm, true) != 0"
                                              :class="{'disabled': true, 'displaypriceselected': (data.loanTerms.acceptedTerm == (index + 1) && coverageTerm.selected), 'displayprice': (data.loanTerms.acceptedTerm != (index + 1) || !coverageTerm.selected)}"
                                              :tooltip="tooltipTextCreation(coverageTerm, term)"
                                              :text="getFinalPrice(term, coverageTerm)">
                            </TooltipComponent>
                        </div>
                    </div>
                    <button v-if="$global.isAdminView && term.term && !data.isDraft()" title="Select term" @click="data.selectDealTerm(index + 1)" class="admin-actions" :disabled="selectedDealTerm === term">
                        <i class="fas fa-check-circle"></i>{{ selectedDealTerm !== term ? " ?" : "" }}
                    </button>
                </template>
            </div>
        </div>

        <!--LEASE DEAL-->
        <div v-else-if="data.isLease() && data.hasCoverageTerms">
            <!--HEADERS-->
            <div class="terms-grid">
                <span>Mos.</span>

                <div style="display: flex; justify-content: space-around;">
                    <span class="money-factor-rate">
                        Sell {{ (isMoneyFactorAsRate) ? 'Rate' : 'MF' }}
                    </span>
                    <span class="money-factor-rate">
                        Buy {{ (isMoneyFactorAsRate) ? 'Rate' : 'MF' }}
                    </span>
                </div>

                <span class="money-factor-rate">
                    Residual
                    <span class="fa-stack fa-1x" @click="changeResidualPercentage">
                        <i class="fas fa-square fa-stack-2x stack-background" />
                        <i class="fas fa-exchange-alt fa-stack-1x" style="color: white" />
                    </span>
                </span>

                <span class="spansubtitle">Base Payment</span>

                <template v-if="!hideTerms">
                    <span v-for="(coverageTerm, index) in data.coverageTerms" :key="coverageTerm.term" class="spantitle">
                        <button v-if="coverageTerm.selected && !areDealTermsLocked" class="button-span unselect-coverage ignore-deal-lock" @click="unselectCoverage(index)">
                            <i class="fas fa-times-circle" />
                        </button>

                        <span v-if="coverageTerm.selected || showPreferedLease(coverageTerm)"
                              :class="{'selected-coverage-column': coverageTerm.selected, 'preferred-coverage-column': showPreferedLease(coverageTerm)}"
                              :style="moveSelectedCoverageColumn(index)" />

                        {{ (coverageTerm.term == 0) ? 'Custom Coverage ' : coverageTerm.term + ' Month Coverage' }}

                        <span v-if="coverageTerm.term == 0 && !anyTermSelected && !data.isSpectator" style="position: relative; display: block">
                            <button class="button-span clear-custom-coverage" @click="deleteCustomCoverage(index)">
                                <i class="fas fa-trash-alt" />
                            </button>
                        </span>
                    </span>
                    <div v-if="data.hasCoverageTerms && !hasCustomCoverage" class="custom-selector">
                        <InputRichDropdown v-model:saturn="customCoveragePicker"
                                           :list="availableCoverageTerms"
                                           :width="60"
                                           :disabled="!(isPaymentSheetPrinted || hasCustomerSeenCarousel) || isBusy">
                        </InputRichDropdown>

                        <button :disabled="isAddCustomDisabled" @click="addCustomTerm(customCoveragePicker)">
                            <i class="fas fa-copy" />
                        </button>
                    </div>
                </template>

            </div>

            <!--PRICES-->
            <div class="terms-grid" v-for="(term, index) in allTerms" :key="index">
                <InputNumber v-model:saturn="term.term"
                             :disabled="isBusy ||inputsDisabled"
                             placeholder="Months"
                             :showIcon="false"
                             max="999"
                             :height="40"
                             @blur="(e) => onDealTermBlur(term, e.target.value, index)"
                             @focus="onFocusSetRatingStatus"
                             :invalid="validation.leaseTerms['term' + (index + 1)].term?.$invalid">
                    <template #validation>
                        <div v-if="validation.leaseTerms['term' + (index + 1)].term.required?.$invalid">Term is required</div>
                    </template>
                </InputNumber>

                <div style="display: flex;">
                    <InputNumber v-show="isMoneyFactorAsRate"
                                 v-model:saturn="term.sellrate"
                                 label="%"
                                 placeholder="Sell Rate"
                                 :class="{'flash': isReady}"
                                 :clearable="false"
                                 :showIcon="false"
                                 :precision="2"
                                 :disabled="!term.term || inputsDisabled"
                                 :isPercent="true"
                                 :height="40"
                                 @blur="sellRateOnBlurHandlerRewrite(term)"
                                 :invalid="validation.leaseTerms['term' + (index + 1)].moneyfactor?.$invalid">
                        <template #validation>
                            <div v-if="validation.leaseTerms['term' + (index + 1)].moneyfactor.minValue?.$invalid">Sell Rate must be at least {{ term.buyrate }}</div>
                            <div v-else-if="validation.leaseTerms['term' + (index + 1)].moneyfactor.required?.$invalid">Sell Rate is required</div>
                        </template>
                    </InputNumber>

                    <InputNumber v-show="!isMoneyFactorAsRate"
                                 v-model:saturn="term.moneyfactor"
                                 label="#"
                                 placeholder="Sell MF"
                                 :class="{'flash': isReady}"
                                 :clearable="false"
                                 :showIcon="false"
                                 :precision="5"
                                 :disabled="!term.term || inputsDisabled"
                                 :height="40"
                                 @blur="LMFOnBlurHandlerRewrite(term)"
                                 :invalid="validation.leaseTerms['term' + (index + 1)].moneyfactor?.$invalid">
                        <template #validation>
                            <div v-if="validation.leaseTerms['term' + (index + 1)].moneyfactor.required?.$invalid">Money Factor is required</div>
                        </template>
                    </InputNumber>

                    <!--Lease Buy Rate Inputs Term 1-->
                    <InputNumber v-show="isMoneyFactorAsRate"
                                 v-model:saturn="term.buyrate"
                                 label="%"
                                 placeholder="Buy Rate"
                                 :class="{'flash': isReady}"
                                 :clearable="false"
                                 :showIcon="false"
                                 :precision="2"
                                 :disabled="!term.term || inputsDisabled"
                                 :isPercent="true"
                                 :height="40"
                                 @blur="buyRateOnBlurHandlerRewrite(term)"
                                 :invalid="validation.leaseTerms['term' + (index + 1)].buyrate.$invalid">
                        <template #validation>
                            <div v-if="validation.leaseTerms['term' + (index + 1)].buyrate.maxValue?.$invalid">Buy Rate must be equal or less than {{ term.sellrate }}%</div>
                            <div v-else-if="validation.leaseTerms['term' + (index + 1)].buyrate.minValue?.$invalid">Buy Rate must be equal or greater than {{ term.sellrate - 3 }}%</div>
                            <div v-else-if="validation.leaseTerms['term' + (index + 1)].buyrate.required?.$invalid">Buy Rate is required</div>
                        </template>
                    </InputNumber>

                    <InputNumber v-show="!isMoneyFactorAsRate"
                                 v-model:saturn="term.buyRateMoneyFactor"
                                 label="#"
                                 placeholder="Buy MF"
                                 :class="{'flash': isReady}"
                                 :clearable="false"
                                 :showIcon="false"
                                 :precision="5"
                                 :disabled="!term.term || inputsDisabled"
                                 :height="40"
                                 @blur="buyRateMoneyFactorBlurHandlerRewrite(term)"
                                 :invalid="validation.leaseTerms['term' + (index + 1)].buyrate.$invalid">
                        <template #validation>
                            <div v-if="validation.leaseTerms['term' + (index + 1)].buyrate.maxValue?.$invalid">Buy Rate must be equal or less than {{ term.moneyfactor }}</div>
                            <div v-else-if="validation.leaseTerms['term' + (index + 1)].buyrate.minValue?.$invalid">Buy Rate must be equal or greater than {{ term.moneyfactor - 0.00125 }}</div>
                            <div v-else-if="validation.leaseTerms['term' + (index + 1)].buyrate.required?.$invalid">Buy Rate MF is required</div>
                        </template>
                    </InputNumber>
                </div>

                <InputNumber v-show="isResidualPercentage"
                             v-model:saturn="term.residual"
                             placeholder="Residual Pct"
                             :clearable="false"
                             :precision="2"
                             :isPercent="true"
                             :disabled="!term.term || inputsDisabled"
                             max="100"
                             :height="40"
                             @blur="residualPercentageOnBlurHandlerRewrite(term)"
                             :invalid="validation.leaseTerms['term' + (index + 1)].residual?.$invalid">
                    <template #validation>
                        <div v-if="validation.leaseTerms['term' + (index + 1)].residual.required?.$invalid">Residual is required</div>
                        <div v-else-if="validation.leaseTerms['term' + (index + 1)].residual.minValue?.$invalid">Residual must be greater than {{ validation.leaseTerms['term' + (index + 1)].residual.minValue?.$params.min }}%</div>
                    </template>
                </InputNumber>

                <InputNumber v-show="!isResidualPercentage"
                             v-model:saturn="term.residualAmount"
                             placeholder="Residual Amount"
                             :clearable="false"
                             :precision="2"
                             customIcon="fas fa-dollar-sign"
                             :disabled="!term.term || inputsDisabled"
                             :max="data.getMSRPOrHighestOrDefaultRetailBookValue()?.retail ?? data.getMSRP() ?? 0"
                             :height="40"
                             @blur="residualAmountOnBlurHandlerRewrite(term)"
                             :invalid="validation.leaseTerms['term' + (index + 1)].residual.$invalid">
                    <template #validation>
                        <div v-if="validation.leaseTerms['term' + (index + 1)].residual.required?.$invalid">Residual is required</div>
                        <div v-else-if="validation.leaseTerms['term' + (index + 1)].residual.minValue?.$invalid">Residual must be greater than {{ validation.leaseTerms['term' + (index + 1)].residual.minValue?.$params.min }}%</div>
                    </template>
                </InputNumber>

                <span>
                    <span v-if="term.term != null && term.moneyfactor != null && term.residual != null" class="displaypricebold">
                        <TooltipComponent class="disabled"
                                          :tooltip="tooltipTextCreation(null, term)"
                                          :text="getBasePrice(term)">
                        </TooltipComponent>
                    </span>
                </span>

                <template v-if="!hideTerms">
                    <div v-for="coverageTerm in data.coverageTerms" :key="coverageTerm.term">
                        <div class="termprice" v-if="term.term != null && term.moneyfactor != null && term.residual != null">
                            <!--if it's a multiple of 12, then return the same term, if not, return the one above and below-->
                            <TooltipComponent v-if="term.term > 0 && getFinalNumbers(term, coverageTerm) && getFinalNumbers(term, coverageTerm).finalPayment != 0"
                                              :class="{
                                                     'disabled': true,
                                                     'displaypriceselected': (data.leaseTerms.acceptedTerm == (index + 1) && coverageTerm.selected),
                                                     'displayprice': (data.leaseTerms.acceptedTerm != (index + 1) || !coverageTerm.selected)
                                                    }"
                                              :tooltip="tooltipTextCreation(coverageTerm, term)"
                                              :text="getFinalPrice(term, coverageTerm)">
                            </TooltipComponent>
                        </div>
                    </div>

                    <button v-if="$global.isAdminView && term.term" title="Select term" @click="data.selectDealTerm(index + 1)" class="admin-actions" :disabled="selectedDealTerm === term">
                        <i class="fas fa-check-circle"></i>{{ selectedDealTerm !== term ? " ?" : "" }}
                    </button>
                </template>
            </div>
        </div>
    </Panel>
</template>

<script>
    import util, { EventBusCore } from '@core/services/util'
    import CoverageTerm from '@core/classes/CoverageTerm'
    import CoverageTermInfo from '@core/classes/CoverageTermInfo'
    import CoverageTermPricing from '@core/classes/CoverageTermPricing'
    import { CreditApplicationsEnabled } from '@/helpers/finance-channels-helper'
    import ENUMS from "@core/classes/Enums"
    import FIMenu from '@core/classes/FIMenu';
    import InputNumber from '@core/components/InputNumber.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import LenderHelper from '@core/helpers/lender-helper'
    import Panel from '@core/components/Panel.vue'
    import TooltipComponent from '@core/components/TooltipComponent.vue'

    export default {
        name: "PanelDealTerms",
        props: {
            data: FIMenu,
            panelObject: Object, // Panel props: { title: '', action: [] }
            validation: Object, // Vuelidate object
            setGapPricing: Function,
            dealSave: Function,
            hideTerms: Boolean,
            dealTermsChangedHandler: Function
        },
        data() {
            return {
                leaseMF: true,
                buyRate: false,
                previousDealterms: [null, null, null],
                priceProfit: 0,
                customCoveragePicker: null,
                isBusy: false,
                allCollapse: true,
                profitPriceItems: [
                    { text: 'Price', value: 0 },
                    { text: 'Profit', value: 1 },
                    { text: 'Cost', value: 2 }
                ],
                allTerms: [],
                cachedFinalNumbers: [],
                iconClass: {
                    iconClass: 'fa-stack fa-1x ',
                    icon: 'fas fa-exchange-alt iconColor',
                },
                isReady: false,
                timeoutId: null,
                isResidualPercentage: null,
            }
        },
        computed: {
            util() {
                return util;
            },
            ENUMS() {
                return ENUMS;
            },
            selectedDealTerm() {
                return this.data.getSelectedDealTerm()
            },
            hasPaperwork() {
                const documents = this.data?.paperwork?.currentPacket()?.documents;
                return (documents && Array.isArray(documents) && documents.length > 0);
            },
            areDealTermsLocked() {
                return this.hasPaperwork || this.data.isSpectator;
            },
            availableCoverageTerms() {
                return this.data.coverageTerms.map(c => { return c.term });
            },
            hasCustomerSeenCarousel() {
                return this.data.customerViewed.some(c => c.viewedType == 9);
            },
            isPaymentSheetPrinted() {
                return this.data.customerViewed.some(c => c.viewedType == 11);
            },
            hasCustomCoverage() {
                return this.data.coverageTerms.some((t) => t.term === 0);
            },
            isAddCustomDisabled() {
                return !(this.isPaymentSheetPrinted || this.hasCustomerSeenCarousel) || this.customCoveragePicker == null || this.isBusy;
            },
            inputsDisabled() {

                if (CreditApplicationsEnabled(this.data)) return false;

                if (this.data.buyersOrderEnabled) {
                    return !this.data.lender.lenderCode
                }
                return false;
            },
            anyTermSelected() {
                return this.data.coverageTerms.some(c => c.selected == true);
            },
            isBuyRatesInvalid() {
                if (this.data.isCash() && !this.data.isDraft()) return false;

                if (this.data.isLease()) {
                    return (this.data.leaseTerms.term1.term && this.validation.leaseTerms.term1.buyrate.$invalid)
                        || (this.data.leaseTerms.term2.term && this.validation.leaseTerms.term2.buyrate.$invalid)
                        || (this.data.leaseTerms.term3.term && this.validation.leaseTerms.term3.buyrate.$invalid);
                }

                return (this.data.loanTerms.term1.term && this.validation.loanTerms.term1.buyrate.$invalid)
                    || (this.data.loanTerms.term2.term && this.validation.loanTerms.term2.buyrate.$invalid)
                    || (this.data.loanTerms.term3.term && this.validation.loanTerms.term3.buyrate.$invalid);
            },
            isMoneyFactorsInvalid() {
                if (this.data.isCash() && !this.data.isDraft()) return false;

                if (this.data.isLease()) {
                    return (this.data.leaseTerms.term1.term && this.validation.leaseTerms.term1.moneyfactor.$invalid)
                        || (this.data.leaseTerms.term2.term && this.validation.leaseTerms.term2.moneyfactor.$invalid)
                        || (this.data.leaseTerms.term3.term && this.validation.leaseTerms.term3.moneyfactor.$invalid);
                }

                return (this.data.loanTerms.term1.term && this.validation.loanTerms.term1.sellrate.$invalid)
                    || (this.data.loanTerms.term2.term && this.validation.loanTerms.term2.sellrate.$invalid)
                    || (this.data.loanTerms.term3.term && this.validation.loanTerms.term3.sellrate.$invalid);
            },
            selectedLender() {
                if (this.data.lender.lenderCode) {
                    return this.$global.Lenders.find(l => l.lenderCode == this.data.lender.lenderCode)
                }
                else {
                    return null;
                }
            },
            isMoneyFactorAsRate() {
                return this.selectedLender?.versions[0].lease.isMoneyFactorAsRate ?? false;
            },
        },
        created() {
            this.init();

            const dealTerms = (this.data.isFinance() || this.data.isDraft())
                ? "loanTerms"
                : (this.data.isLease() ? "leaseTerms" : null);

            if (dealTerms) {
                this.previousDealterms = [this.data[dealTerms].term1.term, this.data[dealTerms].term2.term, this.data[dealTerms].term3.term];
            }
            this.iconClass = {
                iconClass: 'fa-stack fa-1x ',
                icon: 'fas fa-exchange-alt iconColor',
            }

            this.EventBus.on("recalculate", this.recalculate)
            EventBusCore.on("recalculate", this.recalculate)

            this.$watch(() => this.selectedLender, function (newValue) {
                this.isResidualPercentage = this.selectedLender?.versions[0].lease.isDefaultResidualAsRate;
                this.recalculate()
            });

        },
        beforeUnmount() {
            this.EventBus.off("recalculate", this.recalculate)
            EventBusCore.off("recalculate", this.recalculate)
        },
        updated() {
            this.isReady = true;
        },
        methods: {
            init() {
                if (this.data.isLease()) {
                    this.allTerms = [this.data.leaseTerms.term1, this.data.leaseTerms.term2, this.data.leaseTerms.term3];
                    this.allTerms.forEach(term => {
                        term.residualAmount = util.isNull(term.residual) ? null : (term.residual / 100) * (this.data.getMSRPOrHighestOrDefaultRetailBookValue()?.retail ?? this.data.getMSRP() ?? 0);
                        term.sellrate = util.isNull(term.moneyfactor) ? null : term.moneyfactor * 2400;
                        term.buyRateMoneyFactor = util.isNull(term.buyrate) ? null : term.buyrate / 2400;
                    });
                }
                else if (this.data.isFinance() || this.data.isDraft()) {
                    this.allTerms = [this.data.loanTerms.term1, this.data.loanTerms.term2, this.data.loanTerms.term3];
                }

                this.recalculate();
            },
            onFocusSetRatingStatus() {
                this.data.ratingStatus = this.ENUMS.RATING_STATUS.REQUIRED;
            },
            async onDealTermBlur(term, termValue, index) {

                //THIS WILL CHECK IF WE NEED TO REPRICE AND THEN REPRICE IF NECESSARY
                await this.checkReprice(termValue, index, this.setGapPricing, () => { term.term = null })

                //IF THE DEAL TERM HAS CHANGED, RECALCULATE AFTER WE REPRICE
                //THIS PREVENTS UN-NECESSARY RECALCUATE CALLS
                if (this.previousDealterms[index] != termValue) {
                    this.recalculate(term);
                }
            },
            async checkReprice(newValue, index, repriceFunc, resetValue) {
                if (this.dealTermsChangedHandler) this.dealTermsChangedHandler();

                if (this.hideTerms) return;

                if (!newValue || newValue == 0) {
                    resetValue()
                }

                if (this.previousDealterms[index] != newValue) {
                    this.isBusy = true
                    this.previousDealterms[index] = newValue
                    await repriceFunc();
                    this.isBusy = false
                }

                this.data.ratingStatus = ENUMS.RATING_STATUS.UP_TO_DATE;




            },
            changePriceProfit() {
                this.EventBus.emit('priceProfitUpdated', this.priceProfit);
            },
            collapseAll() {
                this.allCollapse = !this.allCollapse
                this.EventBus.emit('collapseAllProducts', this.allCollapse);
            },
            showPreferedLease(coverage) {
                if (coverage.term == 0) {
                    return false
                }
                if (!util.isNull(this.data.leaseTerms.term1.term)) {
                    return coverage.term == this.data.leaseTerms.term1.term
                }
                if (!util.isNull(this.data.leaseTerms.term2.term)) {
                    return coverage.term == this.data.leaseTerms.term2.term
                }
                if (!util.isNull(this.data.leaseTerms.term3.term)) {
                    return coverage.term == this.data.leaseTerms.term3.term
                }
            },
            getBasePrice(term, returnRawValue = false) {
                if (!term.finalNumbersPerCoverageTerm) return;
                const finalNumbers = this.getFinalNumbers(term, null);
                if (!finalNumbers) return;

                const isDealJustCash = this.data.isCash() && !this.data.isDraft();
                const basePrice = isDealJustCash ? finalNumbers.baseAmount : finalNumbers.basePayment;

                return returnRawValue ? basePrice : util.formatPrice(basePrice);
            },
            getFinalPrice(term, coverage, returnRawValue = false) {
                if (!term.finalNumbersPerCoverageTerm) return;
                const finalNumbers = this.getFinalNumbers(term, coverage);
                if (!finalNumbers) return;

                const isDealJustCash = this.data.isCash() && !this.data.isDraft();
                const finalPrice = isDealJustCash ? finalNumbers.finalAmount : finalNumbers.finalPayment;

                return returnRawValue ? finalPrice : util.formatPrice(finalPrice);
            },

            //WHEN YOU CHANGE THE SELL MoneyFactor, UPDATE THE SELL Rate
            LMFOnBlurHandlerRewrite(term) {
                term.sellrate = util.isNull(term.moneyfactor) ? null : term.moneyfactor * 2400;
                this.recalculate(term);
                if (this.dealTermsChangedHandler) this.dealTermsChangedHandler();
            },

            //WHEN YOU CHANGE THE BUY MoneyFactor, UPDATE THE BUY Rate
            buyRateMoneyFactorBlurHandlerRewrite(term) {
                term.buyrate = util.isNull(term.buyRateMoneyFactor) ? null : term.buyRateMoneyFactor * 2400;
                this.recalculate(term);
            },

            //WHEN YOU CHANGE THE SELL Rate, UPDATE THE SELL MoneyFactor
            sellRateOnBlurHandlerRewrite(term) {
                term.moneyfactor = util.isNull(term.sellrate) ? null : term.sellrate / 2400;
                this.recalculate(term);
                if (this.dealTermsChangedHandler) this.dealTermsChangedHandler();
            },

            //WHEN YOU CHANGE THE BUY rate, UPDATE THE BUY MoneyFactor
            buyRateOnBlurHandlerRewrite(term) {
                term.buyRateMoneyFactor = util.isNull(term.buyrate) ? null : term.buyrate / 2400;
                this.recalculate(term);
                if (this.dealTermsChangedHandler) this.dealTermsChangedHandler();
            },

            //WHEN YOU CHANGE THE RESIDUAL percent, UPDATE THE RESIDUAL amount
            residualPercentageOnBlurHandlerRewrite(term) {
                term.residualAmount = (term.residual / 100) * (this.data.getMSRPOrHighestOrDefaultRetailBookValue()?.retail ?? this.data.getMSRP() ?? 0);
                this.recalculate(term);
                if (this.dealTermsChangedHandler) this.dealTermsChangedHandler();
            },

            //WHEN YOU CHANGE THE RESIDUAL amount, UPDATE THE RESIDUAL percent
            residualAmountOnBlurHandlerRewrite(term) {
                if (term.residualAmount == null) term.residual = null
                else term.residual = (term.residualAmount / (this.data.getMSRPOrHighestOrDefaultRetailBookValue()?.retail ?? this.data.getMSRP() ?? 1)) * 100;

                this.recalculate(term);
                if (this.dealTermsChangedHandler) this.dealTermsChangedHandler();
            },

            rateOnBlurHandler(termNum, rateVar, rateType) {
                if (this[rateVar] == null) {
                    this.data.leaseTerms['term' + termNum][rateType] = null
                } else {
                    this.data.leaseTerms['term' + termNum][rateType] = this[rateVar] / 2400
                }
            },
            residualOnBlurHandler(residualVar) {
                this.data.leaseTerms['term' + residualVar.replace("residual", "")].residual = (this[residualVar] / (this.data.getMSRPOrHighestOrDefaultRetailBookValue()?.retail ?? this.data.getMSRP() ?? 1)) * 100
            },
            changeLeaseMF() {
                this.leaseMF = !this.leaseMF
            },
            changeResidualPercentage() {
                this.isResidualPercentage = !this.isResidualPercentage
            },
            changeBuyRate() {
                this.buyRate = !this.buyRate;
            },
            addCustomTerm(copyFromTerm) {
                if (this.hasCustomerCoverage || !copyFromTerm) return;

                let coverageTermCustom = new CoverageTerm();

                coverageTermCustom.wear = true;
                coverageTermCustom.wearPricing = new CoverageTermPricing();
                coverageTermCustom.wearInfo = new CoverageTermInfo();
                coverageTermCustom.gapPricing = new CoverageTermPricing();
                coverageTermCustom.gapInfo = new CoverageTermInfo();

                const copyFrom = this.data.coverageTerms.find((t) => t.term === copyFromTerm)
                if (copyFrom) {
                    coverageTermCustom = new CoverageTerm(copyFrom);
                }

                coverageTermCustom.preferred = false;
                coverageTermCustom.selected = false;
                coverageTermCustom.term = 0;
                coverageTermCustom.originalTerm = copyFromTerm;

                //If a custom coverage is missing a provider, add it
                this.ENUMS.productTypes.filter(pt => pt.fimenuDesc).forEach(product => {
                    if (util.isNull(coverageTermCustom[product.fimenuDesc + 'Info'].provider)) {

                        const coverageTerm = this.data.coverageTerms.find(ct => ct[product.fimenuDesc + 'Info'].provider)
                        if (coverageTerm) {
                            coverageTermCustom[product.fimenuDesc + 'Info'].provider = coverageTerm[product.fimenuDesc + 'Info'].provider
                        }
                    }
                })

                this.data.coverageTerms.push(coverageTermCustom);
                this.recalculate()
                this.dealSave()
            },
            deleteCustomCoverage(index) {
                if (this.$dealRoomHub.connected && this.data.isSpectator) return;

                this.data.coverageTerms.splice(index, 1)
                this.customCoveragePicker = null
                this.recalculate()
                this.dealSave()
            },

            recalculate(changedLeaseOrLoanTerm) {

                //SET THE FINAL NUMBERS FOR EACH TERM AND COVERAGE TERM COMBO
                if (this.data.isLease()) {
                    this.allTerms = [this.data.leaseTerms.term1, this.data.leaseTerms.term2, this.data.leaseTerms.term3];
                    this.allTerms.filter(term => changedLeaseOrLoanTerm ? term == changedLeaseOrLoanTerm : true).forEach(leaseOrLoanTerm => {
                        //PER COVERAGE TERM
                        leaseOrLoanTerm.finalNumbersPerCoverageTerm = this.data.coverageTerms.map(coverageTerm =>
                        ({
                            term: coverageTerm.term,
                            finalNumbers: leaseOrLoanTerm.term ? this.calculateAndSetFinalNumbers(leaseOrLoanTerm, coverageTerm) : null
                        }));

                        //BASE TERM
                        leaseOrLoanTerm.finalNumbersPerCoverageTerm.push(
                            {
                                term: null,
                                finalNumbers: leaseOrLoanTerm.term ? this.calculateAndSetFinalNumbers(leaseOrLoanTerm, null) : null
                            }
                        );
                    });
                    this.allTerms = [this.data.leaseTerms.term1, this.data.leaseTerms.term2, this.data.leaseTerms.term3];
                }
                else if (this.data.isFinance() || this.data.isDraft()) {
                    this.allTerms = [this.data.loanTerms.term1, this.data.loanTerms.term2, this.data.loanTerms.term3];
                    this.allTerms.filter(term => changedLeaseOrLoanTerm ? term == changedLeaseOrLoanTerm : true).forEach(leaseOrLoanTerm => {
                        //PER COVERAGE TERM
                        leaseOrLoanTerm.finalNumbersPerCoverageTerm = this.data.coverageTerms.map(coverageTerm =>
                        ({
                            term: coverageTerm.term,
                            finalNumbers: this.calculateAndSetFinalNumbers(leaseOrLoanTerm, coverageTerm)
                        }));

                        //BASE TERM
                        leaseOrLoanTerm.finalNumbersPerCoverageTerm.push(
                            {
                                term: null,
                                finalNumbers: this.calculateAndSetFinalNumbers(leaseOrLoanTerm, null)
                            }
                        );
                    });
                    this.allTerms = [this.data.loanTerms.term1, this.data.loanTerms.term2, this.data.loanTerms.term3];
                }
                else if (this.data.isCash()) {
                    const cashTerm = {
                        finalNumbersPerCoverageTerm: this.data.coverageTerms.map(ct => ({
                            term: ct.term,
                            finalNumbers: this.calculateAndSetFinalNumbers(null, ct)
                        }))
                    }
                    cashTerm.finalNumbersPerCoverageTerm.push(
                        {
                            term: null,
                            finalNumbers: this.calculateAndSetFinalNumbers(null, null)
                        }
                    )
                    this.allTerms = [cashTerm]
                }

                //RESERVE PROFIT RECALCULATE
                if (!this.data.hasPaperworkPacket()) this.getReserveProfitResult()
            },
            getPaymentAmounts() {
                const paymentAmounts = [];
                this.allTerms.filter(term => term.term).forEach(term => {
                    term.finalNumbersPerCoverageTerm?.forEach(fnpct => {
                        let paymentAmount = null;
                        if (fnpct.finalNumbers !== null) {
                            paymentAmount = LenderHelper.getTermFIMenuPaymentAmount(term.term, fnpct.term, fnpct.finalNumbers, term.sellrate, term.buyrate)
                            paymentAmounts.push(paymentAmount)
                        }
                    })
                });
                return paymentAmounts;
            },
            getFinalNumbers(leaseOrLoanTerm, coverageTerm) {

                //if (leaseOrLoanTerm && leaseOrLoanTerm.finalNumbersPerCoverageTerm) {
                const finalNumbersForTerm = leaseOrLoanTerm.finalNumbersPerCoverageTerm.find(ct => ct.term == coverageTerm?.term);
                return finalNumbersForTerm?.finalNumbers;
                //}
                //else {
                //    let finalNumbersForTerm = this.allTerms[0].finalNumbersPerCoverageTerm.find(ct => ct.term == coverageTerm?.term);
                //    return finalNumbersForTerm?.finalNumbers;
                //}


            },

            calculateAndSetFinalNumbers(leaseOrLoanTerm, coverageTerm) {
                const isCDK = false
                const fimenu = this.data;
                const state = this.$global.selectedStore.storeState;

                //IF WE ARE HIDING TERMS, ONLY CONTINUE FOR BASE TERM
                if (this.hideTerms && coverageTerm != null) return null

                //IF WE ARE HIDING TERMS, ONLY CONTINUE IF WE HAVE A DEAL TERM
                if (this.hideTerms && !fimenu.isCash() && leaseOrLoanTerm.term == null) return null

                //IF WE ARE HIDING TERMS, FOR DRAFT DEALS, ONLY CONTINUE IF WE HAVE A DEAL TERM
                if ((fimenu.isDraft() || fimenu.isLease() || fimenu.isFinance()) && leaseOrLoanTerm.term == null) return null

                const finalNumbers = fimenu.finalNumbers(state, leaseOrLoanTerm, coverageTerm, isCDK);

                return finalNumbers;
            },
            moveSelectedCoverageColumn(index) {
                return {
                    left: 366 + (index * 100) + 'px'
                }
            },
            unselectCoverage(index) {
                if (this.$dealRoomHub.connected && this.data.isSpectator) return;

                this.data.coverageTerms[index].selected = false
                this.data.leaseTerms.acceptedTerm = null
                this.data.loanTerms.acceptedTerm = null
            },
            getReserveProfitResult() {

                if (this.timeoutId !== null) {
                    clearTimeout(this.timeoutId)
                }

                this.timeoutId = setTimeout(async () => {
                    if (!this.data.lender.lenderCode) return;
                    const paymentAmounts = this.getPaymentAmounts()
                    const filteredPaymentAmounts = paymentAmounts.filter(pa => pa.buyRate != null && pa.sellRate != null)
                    if (filteredPaymentAmounts.length > 0) {
                        const paymentAmountsWithProfits = await LenderHelper.getReserveProfitResults(this.data, this.$global.Lenders, this.data.lender.lenderCode, filteredPaymentAmounts)
                        this.savePaymentAmounts(paymentAmountsWithProfits)
                    }
                    else {
                        this.savePaymentAmounts(paymentAmounts)
                    }
                    this.timeoutId = null;
                }, 1500)
            },
            savePaymentAmounts(paymentAmounts) {
                if (paymentAmounts) {
                    this.allTerms.filter(term => term.term).forEach(dealTerm => {
                        dealTerm.paymentAmounts = paymentAmounts.filter(pa => pa.dealTerm == dealTerm.term && pa.sellRate == dealTerm.sellrate && pa.buyRate == dealTerm.buyrate);
                    });
                }
            },
            tooltipTextCreation(coverageTerm, dealTerm) {

                const toolTipLines = [];

                let line3 = null;
                let totalCoverageWithTaxes = null;
                let line5 = null;
                let totalCoverage = null; //TOTAL COVERAGE BASED ON WHAT WE WANT TO DISPLAY

                if (this.data.buyersOrderEnabled) {

                    //GET BUYERS ORDER SO WE CAN GET ALL THE TOTALS
                    let finalNumbers = this.getFinalNumbers(dealTerm, coverageTerm);
                    const buyersOrder = finalNumbers?.bo;

                    if (buyersOrder && buyersOrder.finalNumbers) {
                        totalCoverageWithTaxes = buyersOrder.finalNumbers.productsSubtotal + buyersOrder.finalNumbers.productsTaxTotal;
                        line3 = buyersOrder.finalNumbers.total - totalCoverageWithTaxes;
                        totalCoverage = buyersOrder.finalNumbers.productsSubtotal;
                        line5 = buyersOrder.finalNumbers.total;
                    }

                }
                else {

                    const theFinalNumbers = this.data.finalNumbers(this.data.store.storeState, dealTerm, coverageTerm, false);

                    line3 = this.data.otdBase;
                    totalCoverage = theFinalNumbers.productsSubtotal;
                    totalCoverageWithTaxes = theFinalNumbers.totalPrice;
                    line5 = theFinalNumbers.finalAmount;
                }
                // parse through result to get payment amounts
                const currentPaymentAmount = this.data.isCash() ? null : this.allTerms.find(term => term.term == dealTerm?.term).paymentAmounts.find(pa => pa.coverageTerm == coverageTerm?.term);
                const reserveProfitSplit = currentPaymentAmount?.reserveProfitSplit;
                const reserveProfitFlat = currentPaymentAmount?.reserveProfitFlat;

                toolTipLines.push("Line 3: " + util.formatPrice(line3)); //OTD without Products
                toolTipLines.push("")
                toolTipLines.push("Total Coverage: " + util.formatPrice(totalCoverage))
                toolTipLines.push("(excluding tax)")
                //toolTipLines.push("Coverage Tax: " + util.formatPrice(totalCoverageWithTaxes - totalCoverage)) // //Total Coverage Tax
                //toolTipLines.push("Coverage with Tax: " + util.formatPrice(totalCoverageWithTaxes))
                toolTipLines.push("")

                //IF NOT CASH, SHOW FINAL OTD AS WELL
                if (this.data.dealType != "Cash") {
                    toolTipLines.push("Line 5: " + util.formatPrice(line5))
                }

                if (reserveProfitSplit) {
                    toolTipLines.push("")
                    toolTipLines.push("Reserve Profit Split: " + util.formatPrice(reserveProfitSplit))
                }
                if (reserveProfitFlat) {
                    toolTipLines.push("")
                    toolTipLines.push("Reserve Profit Flat: " + util.formatPrice(reserveProfitFlat))
                }

                return toolTipLines;
            }
        },
        components: {
            Panel,
            InputNumber,
            InputRichDropdown,
            TooltipComponent,
        }
    };
</script>
<style>
    .panel.deal-terms .panel-header-title {
        padding-left: 25px;
    }

    .panel.deal-terms .hide-term {
        grid-template-columns: 40px 140px 70px 100px !important;
        grid-row-gap: 10px;
    }

    .panel.deal-terms .admin-actions {
        margin-left: 5px;
        width: calc(50% - 5px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .panel.deal-terms .panel-header-button-array {
        position: absolute;
        right: 140px;
    }

    .iconColor {
        color: var(--background-color);
    }
</style>