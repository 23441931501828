
<template>
    <div class="video-recorded" ref="videoPlayerContainer">
        <IsBusySectionComponent class="loading-video" v-if="needSetUp !== null"/>
        <div class="video-container">
            <div class="video-player-container">
                <video id="videoPlayer" ref="videoPlayer"></video>
            </div>
            <div class="video-controls">
                <div class="video-track" @click="($e) => seekVideoPosition($e)">
                    <div class="video-progress" ref="videoProgress"></div>
                </div>
                <div class="player-player-buttons-container">
                    <div class="video-player-button" @click="playPauseVideo">
                        <i class="fas fa-pause" v-if="playingController"></i>
                        <i class="fas fa-play" v-else></i>
                    </div>
                    <div class="video-player-timer">
                        {{ videoPlayerTimer }} / {{ videoPlayerDuration }}
                    </div>
                    <div class="video-player-button" @click="muteVideo">
                        <i class="fas fa-volume-mute" v-if="muteController"></i>
                        <i class="fas fa-volume-up" v-else></i>
                    </div>
                    <div class="video-player-button" @click="fullScreenVideo">
                        <i class="fas fa-compress" v-if="fullScreenController"></i>
                        <i class="fas fa-expand" v-else></i>
                    </div>
                </div>
            </div>
        </div>
        <div :class="{'video-bookmarks-container': true, 'open': bookmarksOpen}">
            <div class="video-bookmarks-toggle" @click="setBookmarksOpen">
                <i class="fas fa-chevron-right" v-if="bookmarksOpen"></i>
                <i class="fas fa-chevron-left" v-else></i>
            </div>
            <div class="bookmarks-title">Video Bookmarks</div>
            <div class="bookmarks-list">
                <Card class="video-bookmark" v-for="(bookmark, index) in bookmarks" :key="bookmark.timestamp+index" onHoverReact @click="() => moveVideoToBookmark(bookmark)">
                    <div class="bookmark-action">
                        <span>{{ bookmark.how }}</span>
                        <div>{{ getBookmarkVideoTime(bookmark) }}</div>
                    </div>
                    <div class="bookmark-section">{{ bookmark.where }}</div>
                </Card>
            </div>
        </div>

    </div>
</template>
<script lang="ts">

    import api from '@core/services/api';
    import moment from 'moment';
    import { defineComponent, PropType } from 'vue';
    import ENUMS from '@core/classes/Enums';
    import Card from '@core/components/Card.vue';
    import IsBusySectionComponent from '@core/components/IsBusySectionComponent.vue';

    interface Bookmark {
        timestamp: number,
        how: string,
        where: string
    }

    interface Logs {
        how: string,
        what: string,
        when: string,
        where: string,
    }

    export default defineComponent({
        name: "VideosRecorded",
        props: {
            logs:{
                type: Array as PropType<Logs[]>,
                default: (): Logs[] => []
            },
            video: {
                type: String,
                default: '',
            },
            fimenuId: {
                type: String,
                default: '',
            }
        },
        data() {
            return {
                videoPlayer: null,
                needSetUp: true,
                bookmarks: [],
                duration: 0,
                playingController: false,
                muteController: false,
                fullScreenController: false,
                videoStartTimestamp: 0,
                currentTime: 0,
                timerFormat: 'mm:ss',
                bookmarksOpen: false
            };
        },
        computed: {
            videoPlayerTimer(): string {
                return moment.utc((this.currentTime || 0) * 1000).format(this.timerFormat)
            },
            videoPlayerDuration(): string {
                return moment.utc(this.duration * 1000).format(this.timerFormat)
            }
        },
        mounted() {
            this.videoStartTimestamp = parseInt(this.video.split(".")[0])
            this.videoPlayer = this.$refs.videoPlayer
            this.videoPlayer.src = api.azureMediaServices.videoStream(this.video, this.fimenuId);

            this.videoEventListeners();
            (this.$refs.videoPlayerContainer as HTMLDivElement).addEventListener("fullscreenchange", (e) => {
                if(document.fullscreenElement == this.$refs.videoPlayerContaine) {
                    this.fullScreenController = false
                } else {
                    this.fullScreenController = true
                }
                this.bookmarksOpen = false
            })
        },
        methods: {
            setBookmarksOpen() {
                this.bookmarksOpen = !this.bookmarksOpen
            },
            getBookmarkVideoTime(bookmark: Bookmark) {
                return moment.utc(bookmark.timestamp - this.videoStartTimestamp).format(this.timerFormat)
            },
            getVideoTimerFormat() {
                return this.duration >= 60 * 60 ? 'HH:mm:ss' : 'mm:ss'
            },
            moveVideoToBookmark(bookmark: Bookmark) {
                this.videoPlayer.currentTime = ((bookmark.timestamp - this.videoStartTimestamp) / 1000).toFixed(2);
            },
            getVideoBookmarks() {
                this.logs.forEach(log => {
                    const logTimestamp = moment(log.when).unix() * 1000

                    if(logTimestamp > this.videoStartTimestamp &&
                        logTimestamp < this.videoStartTimestamp + this.duration * 1000 &&
                        (log.how == ENUMS.LOG_HOW.SECTION.CHANGED || log.how == ENUMS.LOG_HOW.SECTION.OPENED)) {
                        this.bookmarks.push({
                            timestamp: logTimestamp,
                            how: log.how,
                            where: log.where
                        })
                    }

                })
            },
            videoEventListeners() {
                this.videoPlayer.addEventListener('loadeddata', () => {
                    if (this.needSetUp === true) {
                        this.videoPlayer.currentTime = 999999999999999
                        this.needSetUp = false
                    }
                });

                this.videoPlayer.addEventListener('seeked', () => {
                    if (this.needSetUp === false) {
                        this.videoPlayer.currentTime = 0
                        this.duration = this.videoPlayer.duration
                        this.needSetUp = null
                        this.getVideoBookmarks()
                        this.timerFormat = this.getVideoTimerFormat()
                    }
                });

                this.videoPlayer.addEventListener('play', () => {
                    if(!this.playingController) {
                        this.playingController = true;
                    }
                });

                this.videoPlayer.addEventListener('pause', () => {
                    if(this.playingController) {
                        this.playingController = false;
                    }
                });

                this.videoPlayer.addEventListener("timeupdate", (e: {target: HTMLVideoElement}) => {
                    const videoTrack = (this.$refs.videoProgress as HTMLElement)
                    if(videoTrack) {
                        videoTrack.style.width = `${e.target.currentTime / this.duration * 100}%`;
                    }
                    this.currentTime = e.target.currentTime
                });
            },
            playPauseVideo() {
                if(this.playingController) {
                    this.videoPlayer.pause();
                } else {
                    this.videoPlayer.play()
                }
                this.playingController = !this.playingController;
            },
            fullScreenVideo() {
                if(!this.fullScreenController) {
                    (this.$refs.videoPlayerContainer as any).requestFullscreen();
                } else {
                    document.exitFullscreen()
                }
                this.fullScreenController = !this.fullScreenController;
            },
            muteVideo() {
                if(this.muteController) {
                    this.videoPlayer.muted = false;
                } else {
                    this.videoPlayer.muted = true;
                }
                this.muteController = !this.muteController;
            },
            seekVideoPosition(e: MouseEvent) {
                const left = (e.target as HTMLDivElement).getClientRects()[0].left
                const width = (e.target as HTMLDivElement).getClientRects()[0].width
                const seekPositionClick = (e as MouseEvent).clientX

                this.videoPlayer.currentTime = ((seekPositionClick - left) / width) * this.duration
            }


        },
        components: {
            // TooltipComponent
            Card,
            IsBusySectionComponent
        }
    });
</script>
<style>
.video-recorded {
    height: 550px;
    display: flex;
    gap: 10px;
    position: relative;
    margin-bottom: 0;
}

    .video-recorded .video-container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 1050px;
        flex-grow: 1;
    }

        .video-recorded .loading-video {
            position: absolute;
            top:0;
            bottom:0;
            left: 0;
            right: 0;
            background: var(--black-10percent);
            height: auto;
            z-index:99
        }

        .video-recorded img.loading-anim {
            filter: invert(1);
        }

    .video-recorded .video-container .video-player-container {
        flex-grow: 1;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
        max-height: 510px;
    }

    .video-recorded .video-container .video-player-container video {
        height: auto;
        width: 100%;
    }

    .video-recorded .video-container .video-controls {
        background: var(--main-color);
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        justify-self: flex-end;
    }

    .video-recorded .video-container .video-controls .player-player-buttons-container {
        display: flex;
        gap: 5px;
        flex-direction: row;
        height: 40px;
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        align-items: center;
    }

    .video-recorded .video-container .video-controls .player-player-buttons-container .video-player-timer {
        flex-grow: 1;
        padding: 0 10px;
        color: white;
    }

    .video-recorded .video-container .video-controls .player-player-buttons-container .video-player-button {
        color: white;
        font-size: 14px;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--black-20percent);
    }

    .video-recorded .video-container .video-controls .player-player-buttons-container .video-player-button:hover {
        cursor: pointer;
        background-color: var(--black-30percent);
    }

    .video-recorded .video-container .video-track {
        flex-grow: 1;
        height: 7px;
        width: 100%;
        background-color: var(--black-30percent);
        position: relative;
        display: flex;
    }

    .video-recorded .video-container .video-track .video-progress{
        height: 100%;
        width: 0;
        background-color: var(--white-50percent);
        transition: all .1s ease-in-out;
        pointer-events: none;
    }


    .video-recorded .video-bookmarks-container{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        border: 1px solid var(--black-20percent);
        border-top: none;
        position: relative;
        width: 250px;
    }

    .video-recorded .video-bookmarks-container .video-bookmarks-toggle {
        height: 100px;
        width: 40px;
        background-color: var(--main-color);
        opacity: .3;
        position: absolute;
        top:50%;
        left: 0;
        transform: translate(-100%, -50%);
        transition: opacity .3s ease-in-out;
        display: none;
    }

    .video-recorded .video-bookmarks-container .video-bookmarks-toggle:hover {
        opacity: 1;
        transition: opacity .3s ease-in-out;
        cursor: pointer;
    }


    .video-recorded .video-bookmarks-container .bookmarks-title{
        color: white;
        padding: 10px;
        font-size: 18px;
        background: var(--main-color);
        margin-bottom: 5px;
        font-weight: 500;
        text-transform: uppercase;
    }

    .video-recorded .video-bookmarks-container .bookmarks-list{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        padding: 0 5px 5px;
        gap: 5px;
    }

    .video-recorded .video-bookmarks-container .bookmarks-list .video-bookmark{
        gap: 5px;
        padding: 10px;
    }

    .video-recorded .video-bookmarks-container .bookmarks-list .video-bookmark:hover{
        cursor: pointer;
    }

    .video-recorded .video-bookmarks-container .bookmarks-list .video-bookmark .bookmark-action{
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        font-size: 14px;
    }

    .video-recorded .video-bookmarks-container .bookmarks-list .video-bookmark .bookmark-section{
        text-transform: uppercase;
    }

    .video-recorded:fullscreen .video-container {
        height: 100%;
    }

    .video-recorded:fullscreen .video-container .video-player-container {
        flex-grow: 1;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
        max-height: 100%;
    }

    .video-recorded:fullscreen .video-bookmarks-toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        color: white;
    }

    .video-recorded:fullscreen .video-bookmarks-container {
        position: fixed;
        width: 300px;
        right: 0;
        top: 0;
        bottom: 0;
        background: white;
        padding: 0;
        transform: translateX(100%);
        transition: transform .3s ease-in-out;
    }

    .video-recorded:fullscreen .video-bookmarks-container.open {
        transform: translateX(0);
        transition: transform .3s ease-in-out;
    }


</style>
