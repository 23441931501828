<template>
    <Panel v-bind="panelObject">
        <div class="coverage-accepted">
            <div class="titles" :style="$grid('7-1-1-1', '5px 10px')">
                <span class="boldedspan product">Description</span>
                <span class="boldedspan">Price</span>
                <span class="boldedspan">Cost</span>
                <span class="boldedspan">Profit</span>
            </div>

            <hr />

            <div v-for="(product, index) in backEndProfitProducts" :key="product.name + index" :style="$grid(data.buyersOrderEnabled ? '5.5-1.5-1-1-1' : '7-1-1-1', '5px 10px') ">
                <span class="product">{{ product.name }}</span>
                <SwitchButton v-if="product.toggleItemized"
                              class="ignore-deal-lock"
                              :disabled="product.disabled || data.hasPaperworkPacket()"
                              :active="product.itemized"
                              :keepActiveSynced="true"
                              leftLabel="Line 1"
                              rightLabel="Line 3"
                              @update="product.toggleItemized" />
                <span class="displayprice">{{ util.formatPrice(product.price) }}</span>
                <span class="displayprice">{{ util.formatPrice(product.cost) }}</span>
                <span class="displayprice">{{ util.formatPrice(product.profit) }}</span>
            </div>

            <hr />

            <div class="total" :style="$grid('7-1-1-1', '5px 10px')">
                <span class="boldedspan program">
                    Total Coverage
                </span>
                <span class="displaypricebold">{{ totalCovPrice }}</span>
                <span class="displaypricebold">{{ totalCovCost }}</span>
                <span class="displaypricebold">{{ totalCovProfit }}</span>
            </div>

            <hr />

            <div v-if="data.dealType !== 'Cash'" class="total-w-tax" :style="$grid('10-2', '5px 10px')">
                <span class="program">
                    <span class="">Reserve Profit </span> {{ getTermDetails() }}
                </span>
                <InputCurrency
                    v-model:saturn="data.reserveProfit"
                    placeholder="Reserve Profit"
                    :precision="2"
                    iconSide="left"
                    :min="0"
                    :disabled="data.lockByDealStatus() || data.dealStatus >= ENUMS.DEAL_STATUS.ACCOUNTING_IN_PROCESS || isReserveUpdating"
                    :class="{
                        'ignore-deal-lock': true,
                        'flash-green' : isReserveUpdating && isNewValueGreater && !isSameValue,
                        'flash-red' : isReserveUpdating && !isNewValueGreater && !isSameValue
                    }"
                    @blur="() => onBlurReserveProfit()"
                />
            </div>
            <div class="items-list" v-for="(item, index) in items" :key="item.name + index" :style="$grid('7-1-1-1', '5px 10px')">
                <span class="program">{{ item.name }}</span>
                <span>{{ item.price }}</span>
                <span>{{ item.cost }}</span>
                <span>{{ item.profit }}</span>
            </div>

            <hr />

            <div class="total no-margin-grid" :style="$grid('7-1-1-1', '5px 10px')">
                <span class="boldedspan program">
                    Total Back-End Profit
                </span>
                <span class="displaypricebold"></span>
                <span class="displaypricebold"></span>
                <span class="displaypricebold">{{ totalProfit }}</span>
            </div>
        </div>
    </Panel>
</template>

<script>
    import util from '@core/services/util'

    import ENUMS from "@core/classes/Enums"
    import FIMenu from '@core/classes/FIMenu'
    
    import InputCurrency from '@core/components/InputCurrency.vue'
    import Panel from '@core/components/Panel.vue'
    import SwitchButton from '@/components/SwitchButton.vue'

    export default {
        name: "PanelBackEndGross",
        props: {
            data: FIMenu, 
            acceptedProducts: Array, 
            getProfitProducts: Function, 
            panelObject: Object, 
            isMoneyFactorAsRate: Boolean, 
            isReserveUpdating: Boolean
        },
        data() {
            return {
                coverageTerm: null,
            }
        },
        computed: {
            ENUMS() {
                return ENUMS;
            },
            util() {
                return util;
            },
            selectedTerm() {
                return this.data.getSelectedDealTerm()?.term
            },
            leaseLoanTerm() {
                return this.data.dealType == this.ENUMS.DEAL_TYPES.getDisplay(this.ENUMS.DEAL_TYPES.FINANCE) 
                ? this.data.loanTerms 
                : (this.data.dealType == this.ENUMS.DEAL_TYPES.getDisplay(this.ENUMS.DEAL_TYPES.LEASE)) ? this.data.leaseTerms : null;
            },
            isNewValueGreater() {
                //is same will return false
                if (!this.data.reserveProfit) return false;
                return this.data.reserveProfit > this.data.nonBuyersOrderFinalNumbers.previousReserveToShow;
            },
            isSameValue() {
                if (!this.data.reserveProfit) return true;
                return this.data.reserveProfit === this.data.nonBuyersOrderFinalNumbers.previousReserveToShow;
            },
            backEndProfitProducts() {
                const productsToInclude = this.acceptedProducts
                    .filter(p => this.data.storeProducts.find(sp => sp.productType === p.productType)
                    ?.getPricingDetails(p.productPricingDetailsId)?.profitFlag === ENUMS.PROFIT_FLAGS.BACK);

                return this.getProfitProducts(productsToInclude);
            },
            items() {
                const items = []

                const bo = this.data.getCurrentBuyersOrder()
                if (!bo) return items

                bo.getAllItemsForBucketTypeAndProfitFlag("Fees", ENUMS.PROFIT_FLAGS.BACK).forEach(i => {
                    items.push({
                        name: i.name,
                        price: i.amount != null ? util.formatPrice(i.amount) : null,
                        cost: i.cost != null ? util.formatPrice(i.cost) : null,
                        profit: i.profit != null ? util.formatPrice(i.profit) : null,
                    })
                })

                bo.getAllItemsForBucketTypeAndProfitFlag("Additions", ENUMS.PROFIT_FLAGS.BACK).forEach(i => {
                    items.push({
                        name: i.name,
                        price: i.amount != null ? util.formatPrice(i.amount) : null,
                        cost: i.cost != null ? util.formatPrice(i.cost) : null,
                        profit: i.profit != null ? util.formatPrice(i.profit) : null,
                    })
                })

                return items.filter(i => i.profit != null)
            },
            totalCovPrice() {
                return util.formatPrice(this.backEndProfitProducts.map(i => i.price ?? 0).reduce((a, b) => a + b, 0))
            },
            totalCovCost() {
                return util.formatPrice(this.backEndProfitProducts.map(i => i.cost ?? 0).reduce((a, b) => a + b, 0))
            },
            totalCovProfit() {
                return util.formatPrice(this.backEndProfitProducts.map(i => (i.profit ?? 0)).reduce((a, b) => a + b, 0))
            },
            totalProfit() {
                const bo = this.data.getCurrentBuyersOrder()
                if (!bo || !this.data.buyersOrderEnabled) return util.formatPrice(this.backEndProfitProducts.map(i => (i.profit ?? 0)).reduce((a, b) => a + b, 0) + this.data.reserveProfit);

                return util.formatPrice((bo.finalNumbers.totalProfitBack ?? bo.getTotalProfitForBucketTypeAndProfitFlag(null, ENUMS.PROFIT_FLAGS.BACK)) + this.data.reserveProfit)
            }
        },
        created() {
            if (this.data.reserveProfit == null) {
                this.data.setReserveProfit();
            }
        },
        methods: {
            getTermDetails() {
                let details = "";
                if (this.data.dealType == 'Finance') {
                    details = `(Term: ${this.selectedTerm} | Sell Rate: ${this.leaseLoanTerm.getAcceptedTerm().sellrate}% | Buy Rate ${this.leaseLoanTerm.getAcceptedTerm().buyrate}%)`
                }
                else if (this.data.dealType == 'Lease' && this.isMoneyFactorAsRate) {
                    details = `(Term: ${this.selectedTerm} | Sell Rate: ${(this.leaseLoanTerm.getAcceptedTerm().moneyfactor * 2400).toFixed(2)}% | Buy Rate ${(this.leaseLoanTerm.getAcceptedTerm().buyrate).toFixed(2)}%)`
                }
                else if (this.data.dealType == 'Lease' && !this.isMoneyFactorAsRate) {
                    details = `(Term: ${this.selectedTerm} | Sell Money Factor: ${this.leaseLoanTerm.getAcceptedTerm().moneyfactor.toFixed(5)} | Buy Money Factor: ${(this.leaseLoanTerm.getAcceptedTerm().buyrate / 2400).toFixed(5)})`
                }

                return details;
            },
            formatNumber(value) {
                if (value != null && typeof value !== 'undefined' && !Number.isNaN(parseInt(value))) {
                    return parseInt(value).toLocaleString();
                } else {
                    return '0';
                }
            },
            onBlurReserveProfit() {
                if (this.data.reserveProfit == null) {
                    this.data.reserveProfit = 0;
                }

                if (this.data.isDealLocked()) {
                    this.data.save();
                }
            },
        },
        components: {
            InputCurrency,
            Panel,
            SwitchButton
        }
    };
</script>

<style>
    .flash-red {
        box-shadow: 0 0 8px 2px var(--error-color);
        animation: blinking 1s infinite;
    }

    .flash-green {
        box-shadow: 0 0 8px 2px var(--success-color);
        animation: blinking 1s infinite;
    }
</style>