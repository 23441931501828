<template>
    <div class="IconWithCount-container">
        <TooltipComponent :tooltip="getTooltipInfoToDisplay()" :direction="tooltipdirection ? tooltipdirection : 'top'">
            <template #showFromOutside>
                <div :style="{fontSize: fontSize ? fontSize + 'px' : '18px'}" class="stack-body">
                    <i :class="icon"></i>
                    <span class="count">{{ flagsCount }}</span>
                </div>
            </template>
        </TooltipComponent>
    </div>
</template>

<script>
    import TooltipComponent from '@core/components/TooltipComponent.vue'
    export default {
        name: "IconWithCount",
        props: ['icon', 'fontSize', 'showTooltip', 'tooltipInfo', 'tooltipdirection', 'maxtooltipCount', 'flagsCount'],
        data() {
            return {
            }
        },
        computed: {

        },
        created() {
            if (this.maxtooltipCount && this.tooltipInfo.length > this.maxtooltipCount) {
                this.tooltipInfoToDisplay = [...this.tooltipInfo.slice(0, this.maxtooltipCount), "..."];
            }
            else {
                this.tooltipInfoToDisplay = this.tooltipInfo
            }
        },

        methods: {
            getTooltipInfoToDisplay() {
                if (this.showTooltip == false) {
                    return null
                }
                if (this.maxtooltipCount && this.tooltipInfo.length > this.maxtooltipCount) {
                    return [...this.tooltipInfo.slice(0, this.maxtooltipCount), "..."];
                }
                else {
                    return [this.tooltipInfo]
                }
            }
        },
        components: {
            TooltipComponent
        }
    };
</script>
<style>
    .IconWithCount-container .stack-body {
        font-weight: 700;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 5px;
    }

        .IconWithCount-container .stack-body span, .IconWithCount-container .stack-body i {
            text-align: center;
        }
</style>