<template>
    <div class="info-modals-container">
        <div v-if="!productGroupSelected" class="modals-container">
            <div class="modal-container" v-for="(pg, index) in productGroups" :key="index">
                <div class="modal-header">
                    <span>{{ localization[pg.name][language] }}</span>
                    <button @click="openVideo(pg.name)" class="play-video-button">Play Video <i class="fas fa-play"></i> </button>
                </div>
                <div class="modal-body">
                    <p v-if="localization[pg.name].desc">{{ localization[pg.name].desc[language] }}</p>
                    <p v-for="(product, index) in pg.products" :key="index">
                        <span>{{ displayDescription(product) }}</span>
                    </p>
                </div>
            </div>
        </div>
        <div v-else-if="productGroupSelected && !videoPlayer" class="modals-container-mobile">
            <div class="modal-container">
                <div class="modal-header">
                    <span>{{ localization[productGroupSelected][language] }}</span>
                    <button @click="openVideo(productGroupSelected)" class="play-video-button">Play Video <i class="fas fa-play"></i> </button>
                    <button @click="handleClickClose" class="close-modal-button"><i class="fas fa-times"></i></button>
                </div>
                <div class="modal-body">
                    <p v-if="localization[productGroupSelected].desc"> {{ localization[productGroupSelected].desc[language] }} </p>
                    <p v-for="(product, index) in getSelectedProductGroupProducts(productGroupSelected)" :key="index">
                        <span>{{ displayDescription(product) }}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="video-container" v-if="videoPlayer">
            <video class="vid_player"
                   controls
                   autoplay="true"
                   :src="'/dist/vid/videosWithCaptions/' + currVideo.fileName"
                   oncontextmenu="return false;"
                   disablePictureInPicture
                   controlsList="nodownload noplaybackrate nofullscreen"></video>
        </div>
        <div :class="{'button-container': true, 'button-container-absolute': productGroupSelected}">
            <button @click="handleClickClose">{{ videoPlayer ? 'Close Video' : 'Close Descriptions' }}</button>
        </div>
    </div>
</template>


<script>
    import util from '@core/services/util'
    import FIMenuVideoList from "@core/classes/FIMenuVideoList"
    import settings from 'settings'

    export default {
        name: "infoModalsContainer",
        props: ['stopAudioHandler', 'localization', 'language', 'closeInfoModalsContainer', 'dealType', 'fimenu', 'productGroupSelected'],
        data() {
            return {
                videoPlayer: false,
                currVideo: null,
                productGroups: [{
                    name: 'mechanicalFailureProt',
                    //products: ["service", "maintenance"],
                },
                {
                    name: this.dealType == 'Lease' ? 'resaleValueProtLease' : 'resaleValueProt',
                    //products: this.dealType == 'Lease' ? ["dingDent", "paintInterior", "cosmeticWheels", "wearTear"] : ["dingDent", "paintInterior", "cosmeticWheels", "wearTear"],
                },
                {
                    name: 'keepMoveProt',
                    //products: ["nitroTireRim", "keyRemote", "towbuster"],
                },
                {
                    name: this.dealType == 'Cash' ? 'totalLossProtCash' : 'totalLossProt',
                    //products: this.dealType == 'Finance' ? ["theftProtection", "lojack", 'gap'] : ["theftProtection", "lojack"],
                }]
            }
        },
        computed: {
            util() {
                return util
            },
        },

        methods: {
            getSelectedProductGroupProducts(productGroupSelected) {
                const productGroup = this.productGroups.find(p => p.name == productGroupSelected);
                return productGroup['products'];
            },
            displayDescription(product) {
                // console.log(this.localization[product])
                return '<i class="fas fa-circle"></i><b>' + this.localization[product][this.language] + '</b> - ' + this.localization[product].desc[this.language]
            },
            handleClickClose() {
                if (this.videoPlayer) {
                    this.videoPlayer = false;
                    this.currVideo = null
                } else {
                    this.closeInfoModalsContainer();
                }
            },
            openVideo(videoName) {
                const allVideos = new FIMenuVideoList(this.fimenu, {
                    playNextVideo: false
                });
                const isCash = this.dealType.toLowerCase() == 'cash';
                const isLease = this.dealType.toLowerCase() == 'lease';
                const isFinance = this.dealType.toLowerCase() == 'finance';
                const store = this.fimenu.store;
                const storeState = store.storeState;

                switch (videoName) {
                    case 'mechanicalFailureProt':
                        this.currVideo = allVideos.videoMachincalFailureProtection(this.language);
                        break;
                    case 'resaleValueProt':
                    case 'resaleValueProtLease':
                        if (isLease)
                            this.currVideo = allVideos.videoFutureResaleValueProtectionForLease(this.language);
                        else
                            this.currVideo = allVideos.videoFutureResaleValueProtection(this.language);

                        break;
                    case 'keepMoveProt':
                        this.currVideo = allVideos.videoKeepingYouOnTheMove(this.language);
                        break;
                    case 'totalLossProt':
                    case 'totalLossProtCash':
                        if (isCash)
                            this.currVideo = allVideos.videoTotalVehicleLossProtectionCash(this.fimenu.language)
                        else
                            this.currVideo = allVideos.videoTotalVehicleLossProtection(this.fimenu.language)

                        break;
                    default:
                }
                this.videoPlayer = true
                this.stopAudioHandler()
            }
        },
        components: {
        }
    };
</script>
<style>
    .info-modals-container {
        top: 0;
    }
    /*.info-modals-container {
        position: absolute;
        background-color: rgba(0,0,0,0.7);
        z-index: 999999;
        width: 100vw;
        top: -107px;
        display: flex;
        flex-direction: column;
        opacity: 1
    }
        .info-modals-container.close {
            opacity: 0;
            transition: opacity .3s ease-in-out
        }

        .info-modals-container .video-container {
            background-color: rgba(0,0,0,0.7);
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 9999;
            display: flex;
            justify-content: center;
            align-items: center;
        }

            .info-modals-container .video-container .vid_player {
                width: 1630px;
                margin-top: -82px;
            }

        .info-modals-container .modal-container {
            border-radius: 0px !important;
            flex-grow: 1;
        }

        .info-modals-container .modals-container {
            flex-grow: 1;
            display: flex;
            row-gap: 60px;
            column-gap: 60px;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 40px 40px 10px;
        }

            .info-modals-container .modals-container .modal-header {
                font-size: 26px;
                padding: 10px 15px;
            }

                .info-modals-container .modals-container .modal-header i {
                    font-size: 35px;
                    color: var(--error-color);
                    margin-right: 10px;
                }

            .info-modals-container .modals-container .modal-body {
                height: 360px;
                overflow: auto;
                font-size: 19px;
            }

                .info-modals-container .modals-container .modal-body p {
                    margin-bottom: 25px;
                }

                .info-modals-container .modals-container .modal-body i {
                    font-size: 11px;
                    margin-right: 10px;
                }

        .info-modals-container .button-container {
            height: 80px;
            display: flex;
            align-items: center;
            height: 140px;
            justify-content: center;
            z-index: 99999;
        }

            .info-modals-container .button-container button {
                font-size: 36px;
                height: auto;
                width: auto;
                padding: 15px 30px;
            }*/
</style>
