<template>
    <div class="document-packet-list" :style="$grid('1')">
        <div v-for="packet in sortedPackets" :key="packet.packetDateStamp">
            <div class="packet-list-open-close" @click="() => toggle(packet)">
                <div class="packet-list-title">
                    <span>
                        Packet #{{ packet.packetDateStamp }}
                    </span>
                    <span class="text-light">
                        Packet generated at {{ packet.packetDate }}
                    </span>
                </div>
                <div class="packet-list-open-close-icon">
                    <span v-show="packet.isOpen"><i class="fa fa-angle-down" aria-hidden="true"></i></span>
                    <span v-show="!packet.isOpen"><i class="fa fa-angle-right" aria-hidden="true"></i></span>
                </div>
            </div>
            <div v-if="packet.isOpen" class="collapsible-packet">
                <DocumentPacket :fimenu="fimenu" :restorePacket="restorePacket" :packet="packet" :showTitle="false" :showButtons="false"></DocumentPacket>
            </div>
        </div>
    </div>
</template>
<script>
    import api from '@core/services/api'
    import DocumentPacket from '@/components/DocumentPacket.vue'
    import _ from 'underscore';
    import moment from 'moment'

    export default {
        name: "DocumentPacketList",
        props: ['fimenu', 'packets', 'restorePacket'],
        data() {
            return {

            };
        },
        computed: {
            sortedPackets() {
                return _.sortBy(this.packets, p => p.packetDateStamp).reverse();
            }
        },
        created() {
            if (this.packets && this.packets.length > 0) {
                this.packets.forEach(packet => {
                    packet.packetDate = this.convertDateStringToDate(packet.packetDateStamp);
                    packet.isOpen = false;
                });
            }
        },

        methods: {
            toggle(packet) {
                this.packets.forEach(p => {
                    p.isOpen = !p.isOpen && p === packet
                })
            },
            convertDateStringToDate(packetDateStamp) {
                //take in a formatted UTC date and output a local date & time
                return moment.utc(packetDateStamp, 'YYYYMMDD_HHmmss').local().format('MM/DD/YYYY hh:mm:ssa');
            },
            downloadDocument(path, type) {
                api.fimenu.downloadDocument(path, type + ".pdf");
            }
        },
        components: {
            DocumentPacket
        }
    };
</script>
<style>
</style>
