<template>
    <div class="modal-container modal-small">
        <div class="modal-header">
            Information
        </div>
        <div class="modal-body">
            <div>{{ info }}</div>
            <div class="input-box-3column">
                <InputRichDropdown label="Oil Type"
                               v-model:saturn="modal.passedData.fimenu.vehicleOil"
                               :list="oilTypes"
                               :valueMap="(o) => o.code"
                               :display="(o) => o.name"
                               @change="oilTypeChangeHandle"
                               :invalid="v$.modal.passedData.fimenu.vehicleOil.$invalid">
                    <template #validation>
                        <div v-if="v$.modal.passedData.fimenu.vehicleOil.required.$invalid">Oil Type</div>
                    </template>
                </InputRichDropdown>
            </div>
        </div>
        <div class="modal-footer">
            <button v-if="cancelText" class="button-unwind" @click="cancel()">{{ cancelText }}</button>
            <button v-if="acceptText" :disabled="v$.$invalid" class="button-save" @click="close()">{{ acceptText }}</button>
        </div>
    </div>
</template>


<script>
    import $modal from '@core/services/modal'
    import { required, minValue, maxValue } from '@vuelidate/validators'
    import { useVuelidate } from '@vuelidate/core';
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import ENUMS from "@core/classes/Enums"

    export default {
        name: "modalOil",
        props: ['modal'],
        setup(){
            // New setup for vuelidate. Swap $v with v$
            return {v$: useVuelidate({$scope: false})}
        },
        data() {
            return {
                info: null,
                cancelText: null,
                acceptText: null,
                oilTypeActual: null,
                oilTypes: [
                    { name: '-- Select Value --' },
                    { name: 'Regular' },
                    { name: 'Blend' },
                    { name: 'Synthetic' },
                    { name: 'Diesel' }
                ],
            };
        },
        computed: {
            ENUMS() {
                return ENUMS;
            },
        },
        validations() {
            const validations = {
                modal: {
                    passedData: {
                        fimenu: {
                            vehicleOil: { required, minValue: minValue(1), maxValue: maxValue(2) }
                        }
                    }
                }
            }
            return validations;
        },
        created() {
            this.info = this.modal.passedData.info;
            this.acceptText = this.modal.passedData.acceptText;
            this.cancelText = this.modal.passedData.cancelText;

            this.setOilTypeList()
        },
        methods: {
            oilTypeChangeHandle() {
                if (this.oilTypeActual != this.modal.passedData.fimenu.vehicleOil) {
                    this.oilTypeActual = this.modal.passedData.fimenu.vehicleOil;
                }
            },
            setOilTypeList() {
                if (this.modal.passedData.fimenu.vehicle.fuelType == this.ENUMS.fuelTypes.find(ft => ft.description == "Diesel").code) {
                    this.oilTypes.forEach(o => {
                        o.code = this.ENUMS.OIL_TYPES[o.name.toUpperCase()]
                        if (o.name != '-- Select Value --' && o.name != "Diesel") {
                            o.disabled = true
                        }
                        else {
                            o.disabled = false
                        }
                    })
                }
                else {
                    this.oilTypes.forEach(o => {
                        o.code = this.ENUMS.OIL_TYPES[o.name.toUpperCase()]
                        if (this.modal.passedData.oilTypeNotAvailable.includes(o.code) || o.name == "Diesel") {
                            o.disabled = true
                        }
                        else {
                            o.disabled = false
                        }
                    })
                }

                if (this.oilTypes.filter(ot => ot.code != null && !ot.disabled).length === 1) {
                    this.modal.passedData.fimenu.vehicleOil = this.oilTypes.find(ot => ot.code != null && !ot.disabled).code
                }

            },
            close() {
                $modal.close();
            },
            cancel() {
                $modal.cancel();
            }
        },
        components: {
            InputRichDropdown: InputRichDropdown
        }
    };
</script>
<style>
</style>
