// importing web worker with web
//import VideoRecordingWorker from 'services/video-recording-worker';

export default {
    install(app, options) {
        //FIXME: Get with angel
        const videoRecordingProcessorSingleton = {
            data: {
                isProcessRunningInThisTab: false,
                storageKey: "_VRS_" + options.storageKey,
            },
            methods: {
                start() {
                    //START PROCESS
                    queueDB.init();
                },
                stop() {
                    console.log(this.storageKey + " : BACKGROUND PROCESSING SERVICE STOPPED");
                    this.isProcessRunningInThisTab = false;
                    localStorage.removeItem(this.storageKey);
                },
                handleFocus() {
                    if (!this.isProcessRunningInThisTab) {
                        this.start();
                    }
                },
                handleUnload() {
                    if (this.isProcessRunningInThisTab) {
                        this.stop();
                    }
                },
            },
            created() {
                window.addEventListener("focus", this.handleFocus);
                window.addEventListener("beforeunload", this.handleUnload);
                window.addEventListener("unload", this.handleUnload);

                this.start();
            },
        };

        const videoRecordingService = {
            data: {
                processor: videoRecordingProcessorSingleton,
                queue: null,
                notFoundRetries: 0,
                videoInfo: {
                    fimenuId: null,
                    videoId: null,
                },
            },
        };

        const dbPromise = new Promise((resolve, reject) => {
            const request = indexedDB.open("chunks", 1);

            request.onupgradeneeded = event => {
                const db = event.target.result;
                db.createObjectStore("chunks", { autoIncrement: true });
            };

            request.onsuccess = event => {
                const db = event.target.result;
                resolve(db);
            };

            request.onerror = event => {
                reject(event.target.error);
            };
        });

        const queueDB = {
            blobBuffer: [],
            blobsToProcess: [],
            bufferIndex: 0,
            init: () => {
                queueDB.getNextFromQueue();
            },
            addToQueue: async blob => {
                console.log("queueDB PUSH");
                try {
                    const db = await dbPromise;
                    const transaction = db.transaction("chunks", "readwrite");
                    const queueStore = transaction.objectStore("chunks");
                    queueStore.add({
                        blob,
                        fimenuId: videoRecordingService.videoInfo.fimenuId,
                        videoId: videoRecordingService.videoInfo.videoId,
                    });
                    //FIXME: IS this a function - preston
                    // transaction.complete;
                } catch (error) {
                    console.error("Error in addToQueue:", error);
                }
            },
            getNextFromQueue: async () => {
                let retriesForNotFound = 5;
                const db = await dbPromise;
                const transaction = db.transaction("chunks", "readwrite");
                const queueStore = transaction.objectStore("chunks");
                queueStore.openCursor().onsuccess = async event => {
                    const cursor = event.target.result;
                    console.log("CURSOR", cursor);
                    if (cursor) {
                        console.log("queueDB getNextFromQueue - CHUNK FOUND");
                        const chunkInfo = cursor.value;

                        try {
                            /* Works without seeking */
                            cursor.delete();
                            await options.processQueueItem(chunkInfo);
                        } catch (error) {
                            console.error("Error processing chunk:", error);
                        }

                        videoRecordingService.notFoundRetries = 0;
                        // Continue processing next tasks recursively
                        return queueDB.getNextFromQueue();
                    } else {
                        // No more chunks in the db
                        if (videoRecordingService.notFoundRetries < retriesForNotFound) {
                            console.log("queueDB PULL - CHUNK NOT FOUND. Sleeping for 5 seconds.");
                            setTimeout(queueDB.getNextFromQueue, 5000);
                            videoRecordingService.notFoundRetries += 1;
                        } else {
                            console.log(
                                "queueDB PULL - CHUNK NOT FOUND. Sleeping permanently until next video recording start."
                            );
                        }
                        return;
                    }
                };
            },
            // getSeekableBlob(inputBlob, callback) {
            //     console.log("GET SEEKABLE BLOB!!");
            //     // This function was copied from RecordRTC (getSeekableBlob) and edited to bypass EBML error
            //     // https://github.com/legokichi/ts-ebml/issues/33

            //     if (typeof EBML === "undefined") {
            //         throw new Error("Please link: https://cdn.webrtc-experiment.com/EBML.js");
            //     }

            //     var reader = new EBML.Reader();
            //     var decoder = new EBML.Decoder();
            //     var tools = EBML.tools;

            //     var fileReader = new FileReader();
            //     fileReader.onload = function (e) {
            //         try {
            //             var ebmlElms = decoder.decode(this.result);
            //             ebmlElms.forEach(function (element) {
            //                 if (element.type !== "unknown") {
            //                     // this if allows us to bypass error
            //                     reader.read(element);
            //                 }
            //             });
            //             reader.stop();
            //             //console.log('reader.metadatas, reader.duration, reader.cues', reader.metadatas, reader.duration, reader.cues);
            //             var refinedMetadataBuf = tools.makeMetadataSeekable(
            //                 reader.metadatas,
            //                 reader.duration,
            //                 reader.cues
            //             );
            //             console.log("refinedMetadataBuf", refinedMetadataBuf);
            //             var body = this.result.slice(reader.metadataSize);
            //             var newBlob = new Blob([refinedMetadataBuf, body], {
            //                 type: "video/x-matroska;codecs=avc1",
            //             });

            //             callback(newBlob);
            //         } catch (error) {
            //             console.error("Could not decode file to make it seekable", { error });
            //             callback(null);
            //         }
            //     };
            //     fileReader.onerror = function (error) {
            //         console.error("An error occurred attempting to load file", { error });
            //         callback(null);
            //     };
            //     fileReader.readAsArrayBuffer(inputBlob);
            // },
        };
        videoRecordingService.queueDB = queueDB;
        app.config.globalProperties.$videoRecordingService = videoRecordingService;
    }
}