import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import ApplicationsSettingsLoader from "@core/classes/Applications/ApplicationsSettingsLoader";
import FIMenuCustomerScreen from "@/components/fimenu/FIMenuCustomerScreen.vue";
import Customer from "@/views/Customer.vue";
import Customers from "@/views/Customers.vue";
import auth from "@core/services/auth";
import { USER_ACCESSES } from "@core/classes/Enums";

const UA = USER_ACCESSES;

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            name: "dashboard",
            component: Dashboard,
        },
        {
            path: "",
            name: "default",
            component: Dashboard,
        },
        {
            path: "/deal/:dealNumber/:storeCode",
            name: "deal",
            component: () => import(`@/views/Deal.vue`),
        },
        {
            path: "/sales",
            name: "sales",
            component: () => import(`@/views/Sales.vue`),
        },
        {
            path: "/fandi",
            name: "fandi",
            component: () => import(`@/views/FAndI.vue`),
        },
        // {
        //     path: '/fimenu',
        //     name: 'fimenu',
        //     component: () => import(`@/views/FIMenu.vue`),
        //     beforeEnter: (to, from, next) => {
        //         if (!auth.hasAccessToPermission("SalesPerson")) next("/")
        //         // Loads customer applications questions into the class. Optimization.
        //         ApplicationsSettingsLoader.init().then(() => {
        //             next();
        //         });
        //     }
        // },
        {
            path: "/fimenudeals",
            name: "fimenudeals",
            component: () => import(`@/views/FIMenuDeals.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("SalesPerson")) next();
                else next("/");
            },
        },
        {
            path: "/cashbash",
            name: "cashbash",
            component: () => import(`@/views/CashBash/CashbashAdmin.vue`),
        },
        {
            path: "/cashbash/player",
            name: "cashbashplayer",
            component: () => import(`@/views/CashBash/CashbashPlayer.vue`),
        },
        {
            path: "/customers",
            name: "customers",
            component: Customers,
            beforeEnter: (to, from, next) => {
                if (!auth.hasAccessToPermission("SalesPerson")) next("/");
                // Loads customer applications questions into the class. Optimization.
                ApplicationsSettingsLoader.init().then(() => {
                    next();
                });
            },
        },
        {
            path: "/customer",
            name: "customer",
            component: Customer,
            beforeEnter: (to, from, next) => {
                if (!auth.hasAccessToPermission("SalesPerson")) next("/");
                // Loads customer applications questions into the class. Optimization.
                ApplicationsSettingsLoader.init().then(() => {
                    next();
                });
            },
        },
        {
            path: "/accounting",
            name: "accounting",
            component: () => import(`@/views/Accounting.vue`),
        },
        {
            path: "/cit",
            name: "cit",
            component: () => import(`@/views/CITs.vue`),
        },
        {
            path: "/reports",
            name: "reports",
            component: () => import(`@/views/Reports.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("SalesPerson")) next();
                else next("/");
            },
        },
        {
            path: "/admin",
            name: "admin",
            component: () => import(`@/views/Admin.vue`),
        },
        {
            path: "/admin/global-settings",
            name: "admin-global-settings",
            component: () => import(`@/views/admin/GlobalSettings.vue`),
        },
        { path: "/admin/users", name: "admin-users", component: () => import(`@/views/admin/Users.vue`) },
        { path: "/admin/stores", name: "admin-stores", component: () => import(`@/views/admin/Stores.vue`) },
        { path: "/admin/products", name: "admin-products", component: () => import(`@/views/admin/Products.vue`) },
        { path: "/admin/lenders", name: "admin-lenders", component: () => import(`@/views/admin/Lenders.vue`) },
        {
            path: "/admin/reserve-profit-calculations",
            name: "admin-reserve-profit-calculations",
            component: () => import(`@/views/admin/ReserveProfitCalculations.vue`),
        },
        {
            path: "/admin/surcharges",
            name: "admin-surcharges",
            component: () => import(`@/views/admin/Surcharges.vue`),
        },
        { path: "/admin/providers", name: "admin-providers", component: () => import(`@/views/admin/Providers.vue`) },
        {
            path: "/admin/warranty-expiration-rules",
            name: "admin-warranty-expiration-rules",
            component: () => import(`@/views/admin/WarrantyExpirationRules.vue`),
        },
        {
            path: "/admin/deal-checklist-items",
            name: "admin-deal-checklist-items",
            component: () => import(`@/views/admin/SettingsChecklistItems.vue`),
        },
        { path: "/admin/positions", name: "admin-positions", component: () => import(`@/views/admin/Positions.vue`) },
        {
            path: "/admin/term-spreads",
            name: "admin-term-spreads",
            component: () => import(`@/views/admin/TermSpreads.vue`),
        },
        {
            path: "/admin/deviation-reasons",
            name: "admin-deviation-reasons",
            component: () => import(`@/views/admin/DeviationReasons.vue`),
        },
        {
            path: "/admin/deal-flow-statuses",
            name: "admin-deal-flow-statuses",
            component: () => import(`@/views/admin/DealFlowStatuses.vue`),
        },
        {
            path: "/admin/contract-in-transit-statuses",
            name: "admin-contract-in-transit-statuses",
            component: () => import(`@/views/admin/ContractInTransitStatuses.vue`),
        },
        { path: "/admin/sale-types", name: "admin-sale-types", component: () => import(`@/views/admin/SaleTypes.vue`) },
        { path: "/admin/deal-types", name: "admin-deal-types", component: () => import(`@/views/admin/DealTypes.vue`) },
        {
            path: "/admin/vehicle-types",
            name: "admin-vehicle-types",
            component: () => import(`@/views/admin/VehicleTypes.vue`),
        },
        {
            path: "/admin/document-types",
            name: "admin-document-types",
            component: () => import(`@/views/admin/DocumentTypes.vue`),
        },
        {
            path: "/admin/advertisement-types",
            name: "admin-advertisement-types",
            component: () => import(`@/views/admin/AdvertisementTypes.vue`),
        },
        {
            path: "/admin/advertisement-sources",
            name: "admin-advertisement-sources",
            component: () => import(`@/views/admin/AdvertisementSources.vue`),
        },
        {
            path: "/admin/manage-cashbash",
            name: "admin-manage-cashbash",
            component: () => import(`@/views/admin/CashBashSetupComponent.vue`),
        },
        {
            path: "/admin/general-mapping",
            name: "admin-general-mapping",
            component: () => import(`@/views/admin/GeneralMappings.vue`),
        },
        {
            path: "/admin/legal-terms",
            name: "admin-legal-terms",
            component: () => import(`@/views/admin/LegalTerms.vue`),
        },
        {
            path: "/admin/carfax-credentials",
            name: "admin-carfax-credentials",
            component: () => import(`@/views/admin/CarFaxCredentials.vue`),
        },
        { path: "/admin/oauth", name: "admin-oauth", component: () => import(`@/views/utilities/OauthUsers.vue`) },
        {
            path: "/admin/saturn-applications",
            name: "admin-saturn-applications",
            component: () => import(`@/views/admin/SaturnApplications.vue`),
        },
        {
            path: "/admin/credit-bureaus",
            name: "admin-credit-bureaus",
            component: () => import(`@/views/admin/CreditBureaus.vue`),
        },
        {
            path: "/utilities",
            name: "utilities",
            component: () => import(`@/views/Utilities.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("SalesManager")) next();
                else next("/");
            },
        },
        {
            path: "/customer-screen",
            name: "customer-screen",
            component: FIMenuCustomerScreen,
        },
        {
            path: "/utilities/active-deals",
            name: "utilities-active-deals",
            component: () => import(`@/views/utilities/ActiveDeals.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("SalesManager")) next();
                else next("/");
            },
        },
        {
            path: "/utilities/copy-from-prod",
            name: "utilities-copy-from-prod",
            component: () => import(`@/views/utilities/CopyFromProd.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("SalesManager")) next();
                else next("/");
            },
        },
        {
            path: "/utilities/error-logs",
            name: "utilities-error-logs",
            component: () => import(`@/views/utilities/ErrorLogs.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("SalesManager")) next();
                else next("/");
            },
        },
        {
            path: "/utilities/custom-variables",
            name: "custom-variables",
            component: () => import(`@/views/utilities/CustomVariables.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("SalesManager")) next();
                else next("/");
            },
        },
        {
            path: "/utilities/manage-base-paperwork",
            name: "utilities-manage-base-paperwork",
            component: () => import(`@/views/utilities/ManageBasePaperwork.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("SalesManager")) next();
                else next("/");
            },
        },
        {
            path: "/utilities/code-editor-themes",
            name: "utilities-code-editor-themes",
            component: () => import(`@/views/utilities/CodeEditorThemeManager.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("Admin")) next();
                else next("/");
            },
        },
        {
            path: "/utilities/vehicle-records",
            name: "utilities-vehicle-records",
            component: () => import(`@/views/utilities/VehicleRecords.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("Admin")) next();
                else next("/");
            },
        },
        {
            path: "/utilities/data-definitions",
            name: "utilities-data-definitions-page",
            component: () => import(`@/views/utilities/DataDefinitionPage.vue`),
        },
        {
            path: "/utilities/data-definitions/:id",
            name: "utilities-data-definitions-builder",
            component: () => import(`@/views/utilities/DataDefinitionBuilder.vue`),
        },
        {
            path: "/sandbox",
            name: "sandbox",
            component: () => import(`@/sandbox/Sandbox.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("SalesManager")) next();
                else next("/");
            },
        },
        {
            path: "/cancel-coverage",
            name: "cancelCoverage",
            component: () => import(`@/views/cancel-coverage/CancelCoverage.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("SalesManager")) next();
                else next("/");
            },
        },
        {
            path: "/cancel-coverage/:id",
            name: "cancelCoverageDetails",
            component: () => import(`@/views/cancel-coverage/CancelCoverageDetails.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("SalesManager")) next();
                else next("/");
            },
        },
        {
            path: "/service",
            name: "service",
            component: () => import(`@/views/SaturnServiceLookup.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("ServiceManager")) next();
                else next("/");
            },
        },
    ],
});

//router.afterEach((to, from) => {
//
//});

export default router;
