<template>
    <div class="modal-container modal-medium">
        <div class="modal-header">
            Lender
        </div>
        <div class="modal-body">
            <PanelLender :data="modal.passedData.fimenu"
                         :validation="modal.passedData.validation"
                         :panelObject="{title: 'Lender', subtext: [{label: 'Name', value: modal.passedData.fimenu.lender.lenderName}]}">
            </PanelLender>
        </div>
        <div class="modal-footer">
            <button class="button-save" @click="close()">Save</button>
        </div>
    </div>
</template>


<script>
    import api from '@core/services/api'
    import $modal from '@core/services/modal'
    import PanelLender from '@/components/PanelLender.vue'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import InputDropdown from '@core/components/InputDropdown.vue'
    import InputCurrency from '@core/components/InputCurrency.vue'
    import InputCheckbox from '@core/components/InputCheckbox.vue'
    import InputNumber from '@core/components/InputNumber.vue'
    import Panel from '@core/components/Panel.vue'
    import RichTable from '@core/components/RichTable.vue'
    import { required, between, minValue, maxValue } from '@vuelidate/validators'
    import { useVuelidate } from '@vuelidate/core';
    import moment from 'moment'
    import util from '@core/services/util'
import modal from '@core/services/modal'

    export default {
        name: "modalLender",
        props: ['modal'],
        setup(){
            // New setup for vuelidate. Swap $v with v$
            return {v$: useVuelidate({$scope: false})}
        },
        data() {
            return {
            }
        },
        computed: {

        },


        methods: {
            close() {
                $modal.close();
            },
            cancel() {
                $modal.cancel();
            }
        },
        components: {
            //InputTextbox: InputTextbox,
            //InputDropdown: InputDropdown,
            //RichTable: RichTable,
            //Panel: Panel,
            //InputNumber: InputNumber,
            //InputCurrency: InputCurrency,
            PanelLender: PanelLender,
            //InputDropdown: InputDropdown,
            //InputCheckbox: InputCheckbox
        }
    };
</script>
<style>
</style>
