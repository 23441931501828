<template>
    <div class="modal-container modal-large preloads">
        <div class="modal-header">
            <h3 style="margin: 0">Preloads</h3>
        </div>
        <div class="modal-body" v-if="!hasPreloads" style="text-align: center;">
            No Preloads available
        </div>
        <div v-else class="modal-body">
            <div class="no-margin-grid">
                <div class="preloads-list" v-for="(preload, index) in fimenu.preloads" :key="index">
                    <div class="preload-details" :invalid="util.isNull(validation) ? false : validation.preloads[index].isSelected.$invalid">
                        <p>{{ preload.desc || preloadQuestion(preload.productType) }}</p>
                        <div class="preload-options">
                            <InputRadio label="Yes" :valueMatch="true" v-model="preload.isSelected" :isButton="true" />
                            <InputRadio label="No" :valueMatch="false" v-model="preload.isSelected" :isButton="true" />
                        </div>
                    </div>
                    <InputCurrency v-model:saturn="preload.price"
                    placeholder="Price"
                    :disabled="preload.isSelected && preload.isEditableAmount ? false : true"
                    :invalid="util.isNull(validation) ? false : validation.preloads[index].price.$invalid"
                    :precision="2"
                    iconSide="left"
                    @blur="onBlurHandler(preload.price, index)">
                    <template #validation>
                        <div v-if="validation && validation.preloads[index].price.maxValue.$invalid">Value must be a maximum of {{ validation.preloads[index].price.$params.maxValue.max }}</div>
                    </template>
                </InputCurrency>
            </div>
            </div>
        </div>

        <div class="modal-footer">
            <button class="button-unwind" @click="cancel">Close</button>

        </div>
    </div>
</template>
<script>
    import $modal from '@core/services/modal';
    import FIMenuBuyersOrder from '@core/classes/FIMenuBuyersOrder'
    import ENUMS from "@core/classes/Enums"

    import InputRadio from '@core/components/InputRadio.vue'

    import InputCurrency from '@core/components/InputCurrency.vue'
    import util from '@core/services/util'


    export default {
        name: "modalAddEditPreloads",
        props: ['modal'],
        data() {
            return {
                bo: null,
                validation: null,
            }
        },
        computed: {
            ENUMS() {
                return ENUMS;
            },
            hasPreloads() {
                return this.fimenu.preloads.length > 0;
            },
            util() {
                return util;
            }
        },
        async created() {
            this.fimenu = this.modal.fimenu;
            this.bo = new FIMenuBuyersOrder(this.modal.bo);

            this.validation = this.modal.validation

            if (this.preselected) {
                this.fimenu.preloads.forEach(p => {
                    p.isSelected = true;
                })
            }
        },
        methods: {
            preloadQuestion(preloadType) {
                const preloadText = ENUMS.productTypes.find(pt => pt.code == preloadType).description
                return preloadText;
            },
            onBlurHandler(price, index) {
                if (price == null) {
                    this.fimenu.preloads[index].price = 0
                }
            },
            cancel() {
                $modal.close();
            }
        },
        components: {
            InputCurrency,
            InputRadio,
        }
    };
</script>

<style>
    .preloads .preload-options {
        display: flex;
        gap: 15px;
        align-items: center;
        position: relative;
        left: -10px;
        flex-direction: row;
    }

    .preloads .preloads-list {
        display: flex;
        align-items: center;
    }

    .preloads .preload-details {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
    }
</style>