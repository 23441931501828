<template><div></div></template>
<script>
    import ENUMS from '@core/classes/Enums';
    import RecordRTC from 'recordrtc'
    import util from "@core/services/util"
    import settings from 'settings';
    import auth from '@core/services/auth';

    export default {
        name: 'MultiStreamRecorder',
        props: [],
        data() {
            return {
                isRecording: false,
                recorder: null,
                blobs: [],
                blobId: 0,
                cameraStream: null,
                screenStream: null,
                defaultBitrate: 2140000,
                defaultMimetype: 'video/webm;codecs=vp9',
                recordingsConfig: null,
                recorderSources: []
            }
        },
        beforeUnmount() {
            this.stopRecording()
        },
        created() {
            this.EventBus.on('stopVideoRecording', this.stopRecording)
            this.EventBus.on('startVideoRecording', this.startRecording)
            this.recordingsConfig = settings.lookups.videoRecordingsConfig
            if (this.recordingsConfig?.mimetype) {
                console.log('Settings Found', this.recordingsConfig);
            }
        },
        computed: {
            payload() {
                return auth.getTokenPayload();
            },
        },
        methods: {
            async startRecording(fimenuId, storeVideoConfig) {
                if(!this.recorder && !this.payload.DisableRecording && storeVideoConfig?.isRecordingEnabled) {
                    console.log("Video Recording Starting");
                    let staffScreen = {}
                    let staffCamnera = {}
                    this.recordingsConfig = {
                        ...this.recordingsConfig,
                        ...storeVideoConfig
                    }

                    try {
                        this.screenStream = await navigator.mediaDevices.getDisplayMedia({
                            video: {
                                mediaSource: "tab",
                                displaySurface: 'browser'
                            },
                            preferCurrentTab: true,
                            selfBrowserSurface: "include",
                            audio: true
                        })
                        this.cameraStream = await navigator.mediaDevices.getUserMedia({
                            video: true,
                            audio: true
                        });

                    } catch (error) {
                        console.log(error)
                    }

                    if(this.screenStream || this.cameraStream) {
                        this.recorder = RecordRTC(this.recorderSources, {
                            type: 'video',
                            mimeType: this.recordingsConfig.mimetype || this.defaultMimetype,
                            timeSlice: 6000,
                            bitsPerSecond: this.recordingsConfig.videoRecordingBitrate || this.defaultBitrate,
                            ondataavailable: (event) => this.handleDataAvailable(event),
                        });
                    }

                    if(this.screenStream) {
                        this.recorderSources.push(this.screenStream)

                        //Get config from Settings or from default Values Staff Screen
                        let staffScreenData = this.recordingsConfig?.videoSourcesConfig?.filter(vsc => vsc.sourceType == ENUMS.VIDEO_SOURCE_TYPE.STAF_SCREEN)
                        if(staffScreenData && staffScreenData.length == 1) {
                            staffScreen = staffScreenData[0]
                            if(staffScreen.dimensionHeight == 0) {
                                staffScreen.dimensionHeight = window.innerHeight
                            }
                            if(staffScreen.dimensionWidth == 0) {
                                staffScreen.dimensionWidth = window.innerWidth
                            }
                        } else {
                            staffScreen = {
                                dimensionWidth: window.innerWidth,
                                dimensionHeight: window.innerHeight,
                                positionLeft: 0,
                                positionTop: 0
                            }
                        }

                        //Staff Screen Setup
                        this.screenStream.width = staffScreen.dimensionWidth;
                        this.screenStream.height = staffScreen.dimensionHeight;

                        // makes the video take set specified width/height or full screen if not values are set
                        if(staffScreen.positionLeft && staffScreen.positionTop) {
                            this.screenStream.top = staffScreen.positionTop;
                            this.screenStream.left = staffScreen.positionLeft;
                        } else {
                            this.screenStream.fullcanvas = true;
                        }
                        console.log('screenStream.width ', this.screenStream.width);
                        console.log('screenStream.height ', this.screenStream.height);

                        //Recorder Setup
                        this.recorder.screen = this.screenStream;

                    }

                    if(this.cameraStream) {
                        this.recorderSources.push(this.cameraStream)
                        //Get config from Settings or from default Values Staff Camera
                        let staffCameraData = this.recordingsConfig?.videoSourcesConfig?.filter(vsc => vsc.sourceType == ENUMS.VIDEO_SOURCE_TYPE.STAFF_CAMERA)
                        if(staffCameraData && staffCameraData.length == 1) {
                            staffCamnera = staffCameraData[0]
                        } else {
                            staffCamnera = {
                                dimensionWidth: 280,
                                dimensionHeight: 200,
                                positionLeft: 70,
                                positionTop: 737
                            }
                        }



                        //Staff Camera Setup
                        this.cameraStream.width = staffCamnera.dimensionWidth;
                        this.cameraStream.height = staffCamnera.dimensionHeight;

                        this.cameraStream.top = this.screenStream.height - staffCamnera.dimensionHeight - 10;
                        this.cameraStream.left = staffCamnera.positionLeft;

                        console.log('cameraStream.width ', this.cameraStream.width);
                        console.log('cameraStream.height ', this.cameraStream.height);

                        //Recorder Setup
                        this.recorder.camera = this.cameraStream;
                    }


                    this.$videoRecordingService.videoInfo.fimenuId = fimenuId
                    this.$videoRecordingService.videoInfo.videoId = Date.now()
                    this.$videoRecordingService.notFoundRetries = 0
                    this.$videoRecordingService.queue.getNextFromQueue()

                    this.recorder.startRecording();
                } else {
                    return false
                }
            },
            stopRecording() {
                if (this.recorder) {
                    console.log("Video Recording Ending");
                    this.recorder.stopRecording(() => {
                        if(this.cameraStream) {
                            this.cameraStream.getTracks().forEach((track) => {
                                if (track.readyState == 'live') {
                                    track.stop();
                                }
                            });
                            this.cameraStream = null
                        }
                        if(this.screenStream) {
                            this.screenStream.getTracks().forEach((track) => {
                                if (track.readyState == 'live') {
                                    track.stop();
                                }
                            });
                            this.screenStream = null
                        }
                    })
                    this.recorder.reset()
                    this.recorder = null
                }
            },

            handleDataAvailable(blob) {
                console.log('handleDataAvailable', blob);
                this.$videoRecordingService.queue.addToQueue(blob);

            },

        },
    }
</script>

<style>
</style>