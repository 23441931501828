<template>
    <div class="modal-container modal-medium">
        <div class="modal-header">
            Tax Rates
        </div>
        <div class="modal-body">
            <div :style="$grid('1')" class="no-margin-grid">
                <Panel title="General">
                    <div :style="$grid('1-1-1')" class="no-margin-grid">
                        <InputNumber label="State Tax Rate"
                                     v-model:saturn="stateTaxRate"
                                     :precision="2"
                                     :isPercent="true">
                        </InputNumber>
                        <InputNumber label="County Tax Rate"
                                     v-model:saturn="countyTaxRate"
                                     :precision="2"
                                     :isPercent="true">
                        </InputNumber>
                        <InputRichDropdown label="Lease Tax"
                                       v-model:saturn="isLeasePaymentTaxed"
                                       :valueMap="(p) => p.code"
                                       :display="(p) => p.description"
                                       :list="leaseTypes">
                        </InputRichDropdown>
                    </div>
                </Panel>
                <Panel title="Products">
                    <div :style="$grid('1-1-1')" class="no-margin-grid">
                        <InputNumber v-for="(productTaxRule, index) in taxRules.productTaxRules" :key="index"
                                     :label="ENUMS.productTypes.find(pt => pt.code === productTaxRule.productType).description"
                                     v-model:saturn="productTaxRule.taxRate"
                                     :precision="2"
                                     :isPercent="true">
                        </InputNumber>
                    </div>
                </Panel>
            </div>
        </div>
        <div class="modal-footer">
            <button class="button-unwind" @click="cancel()">Cancel</button>
            <button class="button-save" @click="close()">Save</button>
        </div>
    </div>
</template>


<script>
    import $modal from '@core/services/modal'
    import InputNumber from '@core/components/InputNumber.vue'
    import Panel from '@core/components/Panel.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import $util from '@core/services/util'
    import ENUMS from "@core/classes/Enums";
    import FIMenuTaxRules from '@core/classes/FIMenuTaxRules';

    export default {
        name: "modalTaxRates",
        props: ['modal'],
        data() {
            return {
                stateTaxRate: null,
                countyTaxRate: null,
                isLeasePaymentTaxed: null,
                leaseTypes: [{ code: false, description: 'Upfront Tax' }, { code: true, description: 'Monthly Tax' }],
                taxRules: null,
            };
        },
        computed: {
            ENUMS() {
                return ENUMS
            }
        },
        created() {
            this.stateTaxRate = this.modal.passedData.fimenu.stateTaxRate;
            this.countyTaxRate = this.modal.passedData.fimenu.countyTaxRate;
            this.isLeasePaymentTaxed = this.modal.passedData.fimenu.isLeasePaymentTaxed;
            this.taxRules = new FIMenuTaxRules(this.modal.passedData.fimenu.taxRules);
        },
        methods: {
            close() {
                this.modal.passedData.fimenu.stateTaxRate = this.stateTaxRate;
                this.modal.passedData.fimenu.countyTaxRate = this.countyTaxRate;
                this.modal.passedData.fimenu.isLeasePaymentTaxed = this.isLeasePaymentTaxed;

                this.modal.passedData.fimenu.taxRules = this.taxRules;
                if (this.modal.passedData.fimenu.coverageTerms) {
                    this.modal.passedData.fimenu.coverageTerms.forEach(ct => {
                        this.taxRules.productTaxRules.forEach(r => {
                            if (r.productType === ENUMS.PRODUCT_TYPES.GAP) {
                                if (ct.gapInfoAndPricing.length > 0) {
                                    ct.gapInfoAndPricing.forEach(infoAndPricing => {
                                        infoAndPricing.termPricing.taxRate = r.taxRate;
                                    });
                                }
                                else if (ct.gapPricing) {
                                    ct.gapPricing.taxRate = r.taxRate;
                                }
                            } else {
                                const product = ENUMS.productTypes.find(pt => pt.code === r.productType).fimenuDesc
                                if (ct[product + "Pricing"]) {
                                    ct[product + "Pricing"].taxRate = r.taxRate
                                }
                            }
                        })
                    });
                }
                this.EventBus.emit('recalculate');
                $modal.close();
            },
            cancel() {
                $modal.cancel();
            }
        },
        components: {
            Panel,
            InputNumber,
            InputRichDropdown,
        }
    };
</script>
<style>
</style>
