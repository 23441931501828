import api from '@core/services/api'
import $modal from '@core/services/modal'
import modalInfo from '@core/modals/modalInfo.vue'

import FIMenuBuyersOrder from '@core/classes/FIMenuBuyersOrder'
import FIMenuBuyersOrderRequest from '@core/classes/FIMenuBuyersOrderRequest'
import ENUMS from '@core/classes/Enums'
import { EventBusCore } from '@core/services/util'

const buyersOrderHelper = {
    isBusy: false,
    isBusy2: () => buyersOrderHelper.isBusy,
    getPropsToCompare: (fimenu) => {

        let propsToCompare = {
            dealType: fimenu.dealType,
            dealDate: fimenu.dealDate,
            msrp: fimenu.getMSRPOrHighestOrDefaultRetailBookValue()?.retail,
            vin: fimenu.vin,
            firstTradeInBodyType: fimenu.tradeIns?.[0]?.primaryBodyType,
            registrationAddress: fimenu.dealAddresses.find((address) => address.addressType == ENUMS.addressTypeEnum.REGISTRATION_ADDRESS),
            buyersOrderUpdateRequiredRightAway: fimenu.buyersOrderUpdateRequiredRightAway,
            buyersOrderRecalculateRequired: fimenu.buyersOrderRecalculateRequired
        };

        //ADD CUSTOMER FIELDS
        if (fimenu.customer) {
            propsToCompare = {
                ...propsToCompare,
                customerAddress: fimenu.customer.address,
                customerCity: fimenu.customer.city,
                customerCounty: fimenu.customer.county,
                customerState: fimenu.customer.state,
                customerZip: fimenu.customer.zip
            }
        }

        //ADD VEHICLE FIELDS
        if (fimenu.vehicle) {
            propsToCompare = {
                ...propsToCompare,
                vehicleModelYear: fimenu.vehicle.modelYear
            }
        }

        return propsToCompare;
    },
    onFIMenuChanged: async (fimenu, newValue, oldValue) => {

        //CHECK IF WE HAVE ALL THE REQUIRED DATA TO ACTUALLY CALL CARCALCS
        if (buyersOrderHelper.doWeHaveAllTheRequiredInfoToGetBuyersOrder(fimenu) && !fimenu.isDealLocked()) {
            fimenu.buyersOrderUpdateRequired = true;
            fimenu.isBuyersOrderVerified = false
        }

        //SOMETIMES WE WANT TO TRIGGER THE UPDATE RIGHT AWAY
        //IF fimenu.buyersOrderUpdateRequiredRightAway is true, then we trigger the update right away
        //example: dealtype dropdown in the buyers order

        if (newValue.buyersOrderUpdateRequiredRightAway) {
            await buyersOrderHelper.updateBuyersOrder(fimenu);
        }


        if (newValue.buyersOrderRecalculateRequired) {
            fimenu.recalculateBuyersOrder();
        }
    },
    doWeHaveAllTheRequiredInfoToGetBuyersOrder(fimenu) {
        return fimenu.buyersOrderEnabled
            && fimenu.dealType
            && fimenu.dealDate
            && fimenu.customer
            && fimenu.customer.address
            && fimenu.customer.city
            //&& fimenu.customer.county
            && fimenu.customer.state
            && fimenu.customer.zip
            && fimenu.getMSRPOrHighestOrDefaultRetailBookValue()?.retail
            && fimenu.vin
            && fimenu.vehicle
            && fimenu.vehicle.modelYear
            && fimenu.tradeIns;
    },
    updateBuyersOrder: async (fimenu) => {
        console.log('TRIED TO DO A BUYERS ORDER RESET')
        if (!fimenu.isDealLocked()) {
            await buyersOrderHelper.getBuyersOrderFromServer(fimenu);
        }

    },
    getBuyersOrderFromServer: async (fimenu) => {
        fimenu.buyersOrderUpdateRequiredRightAway = false;
        
        //CHECK IF WE HAVE ALL THE REQUIRED DATA TO ACTUALLY CALL CARCALCS
        if (buyersOrderHelper.doWeHaveAllTheRequiredInfoToGetBuyersOrder(fimenu)) {
            try {
                buyersOrderHelper.isBusy = true;
                fimenu.buyersOrderIsBusy = true;
                let fiMenuBuyersOrderRequest = new FIMenuBuyersOrderRequest(fimenu);

                let response = await api.fimenu.getBuyersOrder(fiMenuBuyersOrderRequest)
                let bo = new FIMenuBuyersOrder(response.data.success ? response.data.buyersOrder : { dealType: fimenu.dealType, errorCode: response.data.errorCode, errorMessage: response.data.errorDesc })

                fimenu.buyersOrderUpdateRequired = false;
                bo.isManuallyChanged = false;

                let coverageTerm = fimenu.getSelectedCoverageTerm();
                let dealTerm = fimenu.getSelectedDealTerm();

                bo.recalculateBuyersOrder(fimenu, dealTerm, coverageTerm, 'FINAL');
                console.log('BO from SERVER', bo);

                // Check if anything has been overwritten
                let areAnyChangesOverwritten = fimenu.getBuyersOrderByDealType(bo.dealType)?.isManuallyChanged ?? false;

                // Update existing buyer's order
                fimenu.upsertBuyersOrder(bo);

                if (areAnyChangesOverwritten) {
                    $modal.open(modalInfo, {
                        name: 'modalInfo',
                        passedData: {
                            title: 'NOTICE: Deal Structure Update',
                            info: 'We\'ve detected a change in the deal structure which requires us to reconfigure and recalculate the figures for the buyer\'s order.',
                            additionalInfo: 'Any manual adjustments you\'ve made to any fee (not just state fees) might be reset to their default values due to this update. '
                                + 'After the recalculation is complete, please ensure to review and, if necessary, readjust these figures to ensure accuracy.',
                            acceptText: 'Close'
                        }
                    });
                }
            }
            catch (error) {
                console.log('BO from SERVER error', error)
            }
            finally {
                buyersOrderHelper.isBusy = false;
                fimenu.buyersOrderIsBusy = false;
                EventBusCore.emit('buyersOrderUpdated');
            }

        } else {
            return new Promise((resolve) => resolve());
        }
    }
}

export default buyersOrderHelper;
