<template>
    <div v-if="coverageTerm" class="fi-final-step-section">
        <!-- <Panel title="E-Contract" v-if="displayCreditApplication">
            <template v-if="!selectedCreditApplication">
                <div>Select a Credit application:</div>
            </template>

            <RouteOneEContractPanel v-else :fimenu="fimenu" :dealSave="dealSave" />
        </Panel> -->

        <Panel title="Deal Employees">
            <fieldset class="employee-dropdowns ignore-deal-lock" :disabled="fimenu.hasPaperworkPacket() || userPosition === ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.SALES_PERSON1">
                <DealEmployeeDropdown :employees="availableEmployees"
                                      :FIMenu="fimenu"
                                      :employeeType="ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.SALES_PERSON1"
                                      :invalid="!salesPerson1" />

                <DealEmployeeDropdown :employees="availableEmployees"
                                      :FIMenu="fimenu"
                                      :employeeType="ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.SALES_PERSON2" />

                <DealEmployeeDropdown :employees="availableEmployees"
                                      :FIMenu="fimenu"
                                      :employeeType="ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.SALES_MANAGER"
                                      :invalid="!salesManager" />

                <DealEmployeeDropdown :employees="availableEmployees"
                                      :FIMenu="fimenu"
                                      :employeeType="ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.FI_MANAGER"
                                      :invalid="!fiManager" />
            </fieldset>
        </Panel>
        <div v-if="fimenu.buyersOrderEnabled">
            <!-- <AccordionSection :disabled="!canOpenBOAccordion" :isValid="canOpenBOAccordion">
                <template #accordionHeader>
                    <div style="margin-left: 20px">
                        <h1 style="margin: 0">Buyers Order</h1>
                        <span style="font-size: 0.8rem">{{ fimenu.dealType.toUpperCase() }}</span>
                    </div>
                </template>

                <template #accordionBody>
                    <PanelBuyersOrder :fimenu="fimenu" :fimenuValidation="validation" />
                </template>
            </AccordionSection> -->
            <PanelBuyersOrder :fimenu="fimenu" :fimenuValidation="validation" :submitToFinanceChannel="canSubmitToR1" />
        </div>

        <!--<Panel v-if="fimenu.buyersOrderEnabled && fimenu.dealType !== 'Cash'" title="Payment Amounts">
            <div class="no-margin-grid coverage-accepted" :style="$grid('1')">
                <div v-for="(pa, index) in paymentAmounts" :key="index" class="no-margin-grid" :style="$grid('7-3', '5px 10px')">
                    <span class="program">{{pa.display}}</span>
                    <span class="">{{pa.value}}</span>
                </div>
            </div>
        </Panel>-->

        <PanelFrontEndGross :data="fimenu"
                            :validation="validation"
                            :acceptedProducts="acceptedProducts"
                            :getProfitProducts="getProfitProducts">
        </PanelFrontEndGross>

        <PanelBackEndGross v-if="coverageTerm"
                           :data="fimenu"
                           :isMoneyFactorAsRate="isMoneyFactorAsRate"
                           :panelObject="{ title: 'Back-End Gross' }"
                           :isReserveUpdating="isReserveUpdating"
                           :acceptedProducts="acceptedProducts"
                           :getProfitProducts="getProfitProducts">
        </PanelBackEndGross>

        <PanelFinalInfo v-if="(fimenu.dealType === 'Finance' && coverageTerm) || (fimenu.dealType === 'Lease' && coverageTerm) || fimenu.dealType === 'Cash'"
                        :data="fimenu"
                        :panelObject="{ title: 'Final Information' }"
                        :validation="validation"
                        @update="(val) => (isReserveUpdating = val)"
                        class="ignore-deal-lock">
        </PanelFinalInfo>

        <Panel v-if="fimenu.buyersOrderEnabled" title="Totals">
            <div class="no-margin-grid" :style="$grid('99-1')">
                <span class="boldedspan program">
                    Total Gross Profit
                </span>
                <span class="displaypricebold">{{ totalProfit }}</span>
            </div>
        </Panel>

        <div v-if="syncResponse" :class="{ 'sync-response': true, open: syncResponse }">
            <div class="sync-container">
                <div class="sync-close" @click="closeSyncResponse"><i class="fas fa-times-circle"></i></div>

                <h3>CDK Push Responses</h3>
                <div class="sync-answer-container">
                    <div v-for="(sync, index) in syncResponse.syncOperations" :key="index" class="sync-answer">
                        <div v-if="sync.success" class="sync-success"><i class="fas fa-check"></i></div>
                        <div v-if="!sync.success" class="sync-error"><i class="fas fa-times"></i></div>
                        <div class="sync-message">{{ sync.message }}</div>
                    </div>
                </div>
            </div>
        </div>

        <!--Vehicle Delivery by Customer to be updated later with enums and may move question to another section-->
        <div class="radio-button-inline ignore-deal-lock mb-10"
             :invalid="validation.isVehicleDelivered.$invalid">
            <span class="just-bold">Is the customer taking delivery of vehicle now?</span>

            <InputRadio :disabled="fimenu.hasPaperworkPacket()"
                        label="Yes"
                        :valueMatch="true"
                        v-model="fimenu.isVehicleDelivered"
                        :isButton="true" />

            <InputRadio :disabled="fimenu.hasPaperworkPacket()"
                        label="No"
                        :valueMatch="false"
                        v-model="fimenu.isVehicleDelivered"
                        :isButton="true" />
        </div>

        <div class="radio-button-inline ignore-deal-lock mb-10"
             :invalid="validation.isEContracting.$invalid">
            <span class="just-bold">Is this deal going to be e-contracted?</span>

            <InputRadio :disabled="fimenu.hasPaperworkPacket()"
                        label="Yes"
                        :valueMatch="true"
                        v-model="fimenu.isEContracting"
                        :isButton="true" />

            <InputRadio :disabled="fimenu.hasPaperworkPacket()"
                        label="No"
                        :valueMatch="false"
                        v-model="fimenu.isEContracting"
                        :isButton="true" />
        </div>
    </div>
</template>

<script>
    import { _ } from 'vue-underscore'
    import api from '@core/services/api'
    import auth from '@core/services/auth';
    import CoverageTerm from '@core/classes/CoverageTerm'
    import DealEmployeeDropdown from '@core/components/DealEmployeeDropdown.vue'
    import { DisplayCreditApplication } from '@/helpers/finance-channels-helper'
    import ENUMS from "@core/classes/Enums"
    import FIMenu from '@core/classes/FIMenu';
    import FIMenuLender from '@core/classes/FIMenuLender';
    import InputRadio from '@core/components/InputRadio.vue'
    import Panel from '@core/components/Panel.vue';
    import PanelBackEndGross from '@/components/PanelBackEndGross.vue'
    import PanelBuyersOrder from '@/components/PanelBuyersOrder.vue'
    import PanelFinalInfo from '@/components/PanelFinalInfo.vue'
    import PanelFrontEndGross from '@/components/PanelFrontEndGross.vue'
    import util from '@core/services/util'

    export default {
        name: 'PanelFIFinalStep',
        components: {
            Panel,
            PanelFinalInfo,
            PanelFrontEndGross,
            PanelBackEndGross,
            PanelBuyersOrder,
            InputRadio,
            DealEmployeeDropdown
        },
        props: {
            fimenu: {
                required: true,
                type: FIMenu
            },
            passedSyncResponse: Object,
            products: Array,    // fimenu.storeProducts
            validation: Object
        },
        data() {
            return {
                isBusy: false,
                responseSeen: false,
                syncResponse: false,
                variation: 0.1,
                controlNumber: '',
                isReserveUpdating: false,
                availableEmployees: []
            }
        },
        computed: {
            util() {
                return util
            },
            userPosition() {
                const position = auth.getTokenPayload()?.Position;
                return util.convertPositionToDealRole(position);
            },
            ENUMS() {
                return ENUMS;
            },
            showFinalInfoPanel() {
                const dealIsFinanceOrLease = (this.fimenu.dealType === 'Finance') || (this.fimenu.dealType === 'Lease');
                return this.fimenu.dealType === 'Cash' || (dealIsFinanceOrLease && this.coverageTerm);
            },
            selectedTerm() {
                return this.fimenu.getSelectedDealTerm()
            },
            minOtdFinal() {
                const finalvalue = this.getFinalNumbers(false).finalAmount
                const variationAmount = 1000 //Math.abs(finalvalue * this.variation)

                return Math.max(0, finalvalue - variationAmount)
            },
            maxOtdFinal() {
                const finalvalue = this.getFinalNumbers(false).finalAmount
                const variationAmount = 1000 // Math.abs(finalvalue * this.variation)

                return finalvalue + variationAmount
            },
            minPayment() {
                const finalvalue = this.getFinalNumbers(false).finalPayment
                const variationAmount = 10 //Math.abs(finalvalue * this.variation)

                return Math.max(0, finalvalue - variationAmount)
            },
            maxPayment() {
                const finalvalue = this.getFinalNumbers(false).finalPayment
                const variationAmount = 10 // Math.abs(finalvalue * this.variation)

                return finalvalue + variationAmount
            },
            requiresControlNumber() {
                const plateTransactionsEnabled = !!this.fimenu.plateTransactionsEnabled
                const registrationInfo = this.fimenu.vehicle.registrationInfo
                const actualDeal = this.fimenu.getActualDealPlateTransactions()
                const driversLicense = this.fimenu.customer.getIdentification(
                    ENUMS.CUSTOMER_IDENTIFICATION_TYPES.DriversLicense
                )?.idNumber
                const infoChanged =
                    !!actualDeal?.id &&
                    (actualDeal.otherInfo?.driversLicense != driversLicense || // Drivers License changed
                        actualDeal.otherInfo?.vehicleVin != this.fimenu.vin) //|| // Vehicle vin number changed
                //(new FIMenuAddress(actualDeal.otherInfo?.registrationAddress).getFullAddress() != this.fimenu.getRegistrationAddress().getFullAddress())  // Registration address changed

                return (
                    this.fimenu.buyersOrderEnabled && // If buyes orders enabeled AND
                    plateTransactionsEnabled && // if plate transactions enabled AND
                    this.fimenu.vehicle?.registrationInfo?.transactionType ==
                    ENUMS.PLATE_TRANSACTIONS_TYPES.NewTempPlate && // If we want to issue a temporary plate AND
                    (registrationInfo.plateTransactions == null ||
                        registrationInfo.plateTransactions?.length <= 0 || // if we don't have any transactions OR
                        infoChanged)
                ) // Drivers license or vin changed.
            },
            isMoneyFactorAsRate() {
                if (this.fimenu.dealType == 'Lease' && this.fimenu.lender.lenderCode) {
                    const { isMoneyFactorAsRate } = this.$global.Lenders.find(
                        (l) => l.lenderCode == this.fimenu.lender.lenderCode
                    ).versions[0].lease
                    return isMoneyFactorAsRate
                } else return null
            },
            canOpenBOAccordion() {
                const isOTDOkay = util.isNull(this.validation) ? true : !this.validation.otdFinal.$invalid
                const isLeasePaymentOkay = util.isNull(this.validation)
                    ? true
                    : !this.validation.loanTerms.finalMonthlyPayment.$invalid
                const isRetailPaymentOkay = util.isNull(this.validation)
                    ? true
                    : !this.validation.leaseTerms.finalMonthlyPayment.$invalid

                return isOTDOkay && isLeasePaymentOkay && isRetailPaymentOkay
            },
            creditApplications() {
                const returnvalue = this.fimenu.dealJacket.applications.filter(
                    (ca) =>
                        ENUMS.DEAL_TYPES.getDisplay(ca.dealType)?.toLowerCase() == this.fimenu.dealType.toLowerCase()
                )
                return _.sortBy(returnvalue, (r) => r.dateCreated).reverse()
            },
            selectedCreditApplication() {
                return this.fimenu.dealJacket.applications.find( x => x.selected);
            },
            displayCreditApplication() {
                return DisplayCreditApplication(this.fimenu)
            },
            coverageTerm() {
                const ct = this.fimenu?.coverageTerms?.filter((c) => c.selected == true)[0]
                return new CoverageTerm(ct)
            },
            paymentAmounts() {
                const paymentAmounts = []

                paymentAmounts.push({
                    display: "Lender name",
                    value: this.fimenu.lender.lenderName
                })

                if (this.fimenu.dealType != 'Cash') {
                    paymentAmounts.push({
                        display: "Term",
                        value: this.selectedTerm?.term ?? 1
                    })
                }

                //if (this.data.dealType == 'Finance') {
                //    details = `(Sell Rate: ${this.leaseLoanTerm.getAcceptedTerm().sellrate}% | Buy Rate ${this.leaseLoanTerm.getAcceptedTerm().buyrate}%)`
                //}
                //else if (this.data.dealType == 'Lease' && this.isMoneyFactorAsRate) {
                //    details = `(Sell Rate: ${(this.leaseLoanTerm.getAcceptedTerm().moneyfactor * 2400).toFixed(2)}% | Buy Rate ${(this.leaseLoanTerm.getAcceptedTerm().buyrate).toFixed(2)}%)`
                //}
                //else if (this.data.dealType == 'Lease' && !this.isMoneyFactorAsRate) {
                //    details = `(Sell Money Factor: ${this.leaseLoanTerm.getAcceptedTerm().moneyfactor.toFixed(5)} | Buy Money Factor: ${(this.leaseLoanTerm.getAcceptedTerm().buyrate / 2400).toFixed(5)})`
                //}

                //final montly

                if (this.selectedTerm?.buyrate) {
                    paymentAmounts.push({
                        display: "Buy rate",
                        value: this.selectedTerm.buyrate.toFixed(2) + "%"
                    })
                }

                if (this.selectedTerm?.sellrate) {
                    paymentAmounts.push({
                        display: "Sell rate",
                        value: this.selectedTerm.sellrate.toFixed(2) + "%"
                    })
                }

                //Days to 1st payment
                //Date of 1st payment
                //lender name


                return paymentAmounts
            },
            totalProfit() {
                const bo = this.fimenu.getCurrentBuyersOrder()
                if (!bo || !this.fimenu.buyersOrderEnabled) return util.formatPrice((this.coverageTerm.totalPrice(this.fimenu.dealType, null, this.selectedTerm?.value) - this.coverageTerm.totalCost(this.fimenu.dealType, this.selectedTerm?.value)) + (this.fimenu.reserveProfit) + (this.fimenu.nonBuyersOrderFinalNumbers.frontEndProfit));

                return util.formatPrice((bo.finalNumbers.totalProfitFront ?? bo.getTotalProfitForBucketTypeAndProfitFlag(null, ENUMS.PROFIT_FLAGS.FRONT)) + bo.sellingPriceProfit() + (bo.finalNumbers.totalProfitBack ?? bo.getTotalProfitForBucketTypeAndProfitFlag(null, ENUMS.PROFIT_FLAGS.BACK)) + this.fimenu.reserveProfit)
            },
            acceptedProducts() {
                let acceptedProducts = []

                if (this.coverageTerm) {
                    acceptedProducts = this.coverageTerm.getAcceptList(this.fimenu.dealType, this.fimenu.getSelectedDealTerm()?.term, this.fimenu.storeProducts)
                }

                //update NY bundle pieces
                const titaniumProducts = acceptedProducts.filter(p => p.productType === ENUMS.PRODUCT_TYPES.TITANIUM_BUNDLE && p.productPricing.meta && p.productPricing.meta.bundledProducts)
                titaniumProducts.forEach(titaniumProduct => {

                    const descriptionPieces = titaniumProduct.productInfo.program.description.split(',')
                    const tireAndWheelDescription = `Tire and Wheel - ${descriptionPieces[0]} ${titaniumProduct.productInfo.termLimit != null ? util.displayMonthsYears(titaniumProduct.productInfo.termLimit) : ""}`;
                    const dingAndDentDescription = `Ding and Dent - ${descriptionPieces[1]} ${titaniumProduct.productInfo.termLimit != null ? util.displayMonthsYears(titaniumProduct.productInfo.termLimit) : ""}`;
                    const keyAndRemoteDescription = `Key Replacement - ${descriptionPieces[2]} ${titaniumProduct.productInfo.termLimit != null ? util.displayMonthsYears(titaniumProduct.productInfo.termLimit) : ""}`;

                    const titaniumMetadata = JSON.parse(titaniumProduct.productPricing.meta["bundledProducts"]);
                    const tireAndWheelDecimalPrice = titaniumMetadata.find(d => d.productType === ENUMS.PRODUCT_TYPES.TIRE_AND_WHEEL)?.price ?? 0;
                    const dingAndDentDecimalPrice = titaniumMetadata.find(d => d.productType === ENUMS.PRODUCT_TYPES.DING_AND_DENT)?.price ?? 0;
                    const keyAndRemoteDecimalPrice = titaniumMetadata.find(d => d.productType === ENUMS.PRODUCT_TYPES.KEY_AND_REMOTE)?.price ?? 0;

                    const tireAndWheelPrice = tireAndWheelDecimalPrice != null ? (titaniumProduct.productPricing.price - dingAndDentDecimalPrice - keyAndRemoteDecimalPrice - 100) : 0;
                    const dingAndDentPrice = dingAndDentDecimalPrice != null ? dingAndDentDecimalPrice : 0;
                    const keyAndRemotePrice = keyAndRemoteDecimalPrice != null ? (keyAndRemoteDecimalPrice + 100) : 0;

                    const tireAndWheelCost = titaniumProduct.productPricing.cost - titaniumMetadata.find(d => d.productType == ENUMS.PRODUCT_TYPES.DING_AND_DENT).cost - titaniumMetadata.find(d => d.productType == ENUMS.PRODUCT_TYPES.KEY_AND_REMOTE).cost
                    const dingAndDentCost = titaniumMetadata.find(d => d.productType == ENUMS.PRODUCT_TYPES.DING_AND_DENT).cost
                    const keyAndRemoteCost = titaniumMetadata.find(d => d.productType == ENUMS.PRODUCT_TYPES.KEY_AND_REMOTE).cost

                    acceptedProducts = util.clone(acceptedProducts)

                    const tireAndWheelProduct = {
                        description: tireAndWheelDescription,
                        productPricing: { price: tireAndWheelPrice, cost: tireAndWheelCost }
                    }
                    const dingAndDentProduct = {
                        description: dingAndDentDescription,
                        productPricing: { price: dingAndDentPrice, cost: dingAndDentCost }
                    }
                    const keyAndRemoteProduct = {
                        description: keyAndRemoteDescription,
                        productPricing: { price: keyAndRemotePrice, cost: keyAndRemoteCost }
                    }

                    const titaniumIndex = acceptedProducts.findIndex(p => p.productType === ENUMS.PRODUCT_TYPES.TITANIUM_BUNDLE && p.productPricing.meta && p.productPricing.meta.bundledProducts)
                    acceptedProducts.splice(titaniumIndex, 1, tireAndWheelProduct, dingAndDentProduct, keyAndRemoteProduct)
                });

                return acceptedProducts
            },
            salesPerson1() {
                return this.fimenu.getEmployeeByRole(ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.SALES_PERSON1);
            },
            salesManager() {
                return this.fimenu.getEmployeeByRole(ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.SALES_MANAGER);
            },
            fiManager() {
                return this.fimenu.getEmployeeByRole(ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.FI_MANAGER);
            },
            canSubmitToR1() {
                // If the deal is cash. We cannot submit the deal to the lenders.
                if (this.fimenu.isCash()) return false;

                // Wether we can send deals or not based on the store settings.
                return !!(this.fimenu.store?.storeSettings?.submitCustomersToFinanaceInstitutions == true) || this.fimenu.creditApplicationsEnabled
            },
            lenderValidation() {
                return this.fimenu.lender.validation(this.fimenu, FIMenuLender.validationTypes.FINAL);
            },
            customerValidation() {
                return this.fimenu.customer.validation(this.fimenu, this.fimenu.customer.validationTypes.FINAL);
            }
        },
        async created() {
            this.syncResponse = (this.passedSyncResponse && this.passedSyncResponse.syncOperations) ? true : null;
            if (util.isNull(this.syncResponse)) {
                this.responseSeen = true;
            }

            if (this.fimenu.buyersOrderEnabled) {
                this.fimenu.otdFinal = this.fimenu.getCurrentBuyersOrder().finalNumbers.total;

                if (this.fimenu.isLease()) {
                    this.fimenu.leaseTerms.finalMonthlyPayment = this.fimenu.getCurrentBuyersOrder().finalNumbers.monthlyPaymentWithTax;
                }
                else {
                    this.fimenu.loanTerms.finalMonthlyPayment = this.fimenu.getCurrentBuyersOrder().finalNumbers.monthlyPaymentWithTax;
                }
            }

            await this.getAvailableEmployees();
        },
        methods: {
            openSyncResponse() {
                this.syncResponse = true
            },
            closeSyncResponse() {
                this.responseSeen = true
                this.syncResponse = false
            },
            getFinalNumbers(isFinal) {
                const state = this.$global.selectedStore.storeState
                const leaseOrLoanTerm = this.fimenu.isFinance()
                    ? this.fimenu.loanTerms.getAcceptedTerm()
                    : this.fimenu.leaseTerms.getAcceptedTerm()
                const coverageTerm = this.fimenu.getSelectedCoverageTerm()

                return this.fimenu.finalNumbers(state, leaseOrLoanTerm, coverageTerm, isFinal)
            },
            getProfitProducts(productsToInclude) {
                const backEndProfitProducts = productsToInclude.map(p => {
                    const backEndProfitProduct = {
                        name: p.description,
                        price: p.productPricing.price,
                        cost: p.productPricing.cost,
                        profit: p.productPricing.price - p.productPricing.cost,
                        disabled: true,
                        itemized: true
                    }

                    if (this.fimenu.buyersOrderEnabled) {
                        const productItem = this.fimenu.getCurrentBuyersOrder()
                            ?.getAllItemsForBucketTypeAndProfitFlag("Products")
                            .find(i => i.metadata && i.metadata.productType === p.productType && i.metadata.productPricingDetailsId === p.productPricingDetailsId)

                        if (productItem?.metadata?.disabled != null) {
                            backEndProfitProduct.disabled = productItem.metadata.disabled
                        }

                        if (this.fimenu.isLease() && productItem.isFront) {
                            backEndProfitProduct.disabled = true;
                        }

                        if (productItem?.metadata?.itemized != null) {
                            backEndProfitProduct.itemized = productItem.metadata.itemized
                        }

                        backEndProfitProduct.toggleItemized = () => {
                            if (!productItem) return
                            productItem.metadata.itemized = !productItem.metadata.itemized;
                        }
                    }

                    return backEndProfitProduct
                });
                
                return backEndProfitProducts.filter(i => i.profit != null);
            },
            async getAvailableEmployees() {
                this.isBusy = true;

                try {
                    const response = await api.settings.GetEmployeesDropdown("storeCode", this.fimenu.storeCode);

                    if (response?.data)
                        this.availableEmployees = response.data;
                }
                catch (err) {

                    console.error(err);
                }
                finally {
                    this.isBusy = false;
                }
            }
        },
    }
</script>
<style scoped>
    .fi-final-step-section {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }

        .fi-final-step-section .employee-dropdowns {
            width: 100%;
            display: flex;
            gap: 10px;
        }

            .fi-final-step-section .employee-dropdowns > * {
                flex: 1;
            }

    .mb-10 {
        margin-bottom: 10px
    }
</style>
