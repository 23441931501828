<template>
    <div class="modal-container modal-xx-large streamingModal">
        <!--<div class="modal-header">
        </div>-->
        <div class="modal-body">
            <StreamSynchronizer style="margin-bottom: 0" :videos="videosData"/>
        </div>
        <div class="modal-footer">
            <button @click="close()">Close</button>
        </div>
    </div>
</template>


<script>
    import $modal from '@core/services/modal'
    import StreamSynchronizer from 'multi-stream-video-player/StreamSynchronizer'

    export default {
        name: "modalForVideoStreams",
        props: ['modal'],
        data() {
            return {
                videosData: null,
                someData: 'Initial value'
            };
        },
        computed: {

        },
        created() {
            this.videosData = this.modal.passedData.videosData;
        },
        methods: {
            close() {
                $modal.close();
            },
            cancel() {
                $modal.cancel();
            }
        },
        components: {
            StreamSynchronizer
        }
    };
</script>
<style>
</style>