<template>
    <div class="dropdown-menu-container">
        <div class="dropdown-menu-button">
            <button class="button button-text-icon button-primary" @click="toggleDropdownMenu()" :disabled="disableButtonForFIManagers || fimenu.isSpectator">
                {{ meetingButtonText }}
                <i :class="{ 'fas': true, 'fa-caret-down': !dropDownShowing, 'fa-caret-up': dropDownShowing }" />
            </button>
        </div>
        <div :class="{ 'dropdown-menu': true, 'up': !dropDownShowing }">
            <div v-if="isReconnecting" id="reconnecting">
                <h3>
                    Reconnecting<EllipsisAnimated />
                </h3>
            </div>
            <div class="meeting-user-container">
                <div v-if="!hideInStoreConnection" @click="handleInStoreConnection" class="meeting-user"
                    id="in-store-connection">
                    In Store Connection
                    <i class="fas fa-store-alt" />
                </div>
                <MeetingUser v-if="fimenu.customer.email && fimenu.customer.cell && allowRemoteDealMeetings && meetingHelper.inStore !== true"
                    :key="'customer'"
                    :fimenu="fimenu" 
                    :role="'customer'"
                    :peopleInLobby="peopleInLobby"
                    @allowUserIntoMeeting="allowUserIntoMeeting" 
                    @handleMeetingInvitation="handleMeetingInvitation" 
                    @handleDisconnectParticipant="handleDisconnectParticipant" 
                    />
                <MeetingUser v-if="fimenu.coCustomer.email && fimenu.coCustomer.cell && allowRemoteDealMeetings && meetingHelper.inStore !== true"
                    :key="'coCustomer'"
                    :fimenu="fimenu" 
                    :role="'coCustomer'"
                    :peopleInLobby="peopleInLobby"
                    @allowUserIntoMeeting="allowUserIntoMeeting" 
                    @handleMeetingInvitation="handleMeetingInvitation" 
                    @handleDisconnectParticipant="handleDisconnectParticipant" 
                    />
            </div>
            <div v-if="meetingActive && !(customerInLobby && coCustomerInLobby)" class="meeting-actions">
                <button @click="handleVideoBtnClick" :class="videoBtnStyle" :disabled="!isVideoButtonEnabled" :data-title="`Please select a deal type and \n ensure vehicle information \n is correct.`">
                    <span>
                        <i class="fas fa-photo-video" />
                        Video
                    </span>
                </button>
                <button @click="confirmVideosFinished" :class="paymentSheetBtnStyle" :disabled="paymentSheetDisabled">
                    <span>
                        <i class="fas fa-money-check-alt" />
                        Payment Sheet
                    </span>
                </button>
            </div>
            <div v-if="(videoActive || paymentSheetActive) && meetingHelper.inStore" class="volume-controls">
                <div class="volume-slider-container">
                    <i class="fas fa-volume-up"></i>
                    <input type="range" min="0" max="100" v-model="volumeLevel" ref="volumeSlider" id="volume-slider"
                        @mouseup="changeCustomerVideoVolume" @input="handleVolumeProgress"
                        :style="{ background: `linear-gradient(to right, var(--main-color) ${volumeLevel}%, #ccc ${volumeLevel}%)` }" />
                </div>
            </div>
            <button v-if="$meetingHub.connected" @click="handleEndMeeting" class="button button-icon-text button-unwind"
                id="end-meeting-button">
                End Meeting
            </button>

        </div>
    </div>
</template>

<script>
import LogRocket from "logrocket";
import $modal from '@core/services/modal'
import settings from 'settings'
import MeetingUser from '@/components/MeetingUser.vue'
import modalUseSendVerificationCode from '@core/modals/modalUseSendVerificationCode.vue'
import modalCustomerMeetingInvitation from '@/modals/modalCustomerMeetingInvitation.vue'
import modalInfo from '@core/modals/modalInfo.vue'
import { userHasAccessToEditDeal } from '@core/helpers/user-helper'
import auth from '@core/services/auth';
import util from '@core/services/util'
import EllipsisAnimated from '@core/components/UI/EllipsisAnimated'
import FIMenu from "@core/classes/FIMenu";

export default {
    name: 'CustomerMeetingDropdownMenu',
    props: {
        fimenu: FIMenu,
        customerScreenActions: Object, // Expects: {openCustomerPopup: Function, openPrintPaymentSheetModal: Function, openPaperworkErrorsModal: Function}
        paymentSheetDisabled: Boolean,
        peopleInLobby: Array
    },
    data() {
        return {
            dropDownShowing: false,
            videoActive: null,
            paymentSheetActive: null,
            volumeLevel: 50,
            meetingInvitation: null,
        }
    },
    created() {
        // Checking last customer viewed type to see which step is active (video/paymentsheet)
        this.$watch(() => this.fimenu.customerViewed, (newValue) => {
            if (this.$meetingHub.connected) {
                let latestCustomerViewed = newValue.toReversed();
                for (let cv of latestCustomerViewed) {

                    if (this.videoActive == null && this.paymentSheetActive == null) {
                        break;
                    }
                    if (cv.viewedType === 2 || cv.viewedType === 12 || cv.viewedType === 13) {
                        // Latest Customer View - videos
                        this.videoActive = true
                        this.paymentSheetActive = false
                        break;
                    } else if (cv.viewedType === 7 || cv.viewedType === 8 || cv.viewedType === 9) {
                        // Latest Customer View - payment sheet
                        this.videoActive = false
                        this.paymentSheetActive = true
                        break;

                    }
                }
            }
        }, { deep: true, immediate: true })

        this.$watch(() => this.meetingHasEnded, (newValue) => {
            if (newValue) {
                this.videoActive = null
                this.paymentSheetActive = null
                this.$meetingHub.volumeLevel = 0.5;
                this.volumeLevel = 50;
            }
        })

        this.$watch(() => this.peopleInLobby, (newValue) => {
            if (newValue.length > 0) {
                this.dropDownShowing = true;
            }
        })

        this.$watch(() => this.$meetingHub.connection._connectionState, (newValue, oldValue) => {
            console.log(`Meeting Hub Connection State has changed from ${oldValue} to ${newValue}`)
            // If we were connected and our connection state changed to a different state and we didn't manually end the meeting or disconnect then open the connection lost modal
            if ((oldValue === 'Connected' || oldValue === "Reconnecting") && newValue === "Disconnected" && !this.$meetingHub.manuallyClosed) {
                this.openConnectionIssueModal();
                return
            }
            
        });

        // this.$watch(() => this.customerInLobby, (newValue) => {
        //     if (newValue) {
        //         this.dropDownShowing = true;
        //     }
        // })

        // this.$watch(() => this.coCustomerInLobby, (newValue) => {
        //     if (newValue) {
        //         this.dropDownShowing = true;
        //     }
        // })
    },
    computed: {
        settings() {
            return settings
        },
        isVideoButtonEnabled() {
            const hasInventoryType              = !util.isNullOrEmpty(this.fimenu.inventoryType);
            const hasVehicleWarrantyStartDate   = this.fimenu.vehicleWarranty.warrantyStartDate != null;

            if (!hasInventoryType) return false;
            if (hasInventoryType && this.fimenu.inventoryType != "New" && !hasVehicleWarrantyStartDate ) return false;

            const hasLoaner                     = this.fimenu?.vehicleWarranty?.isLoanerIncluded != null;
            const hasSelectedDealType           = !util.isNullOrEmpty(this.fimenu.dealType);
            const hasVehicleMiles               = this.fimenu.vehicleMiles != null;
            const dealHasLanguage               = !util.isNullOrEmpty(this.fimenu.language);

            return hasLoaner && hasSelectedDealType && hasVehicleMiles && dealHasLanguage
        },
        meetingButtonText() {
            return this.$meetingHub.connected ? 'Meeting Actions' : 'Start Meeting';
        },
        customerInLobby() {
            if (this.customerConnected) {
                const customer = this.meetingHelper.currentMeetingUsers.find(user => user.role === "customer")
                return customer?.isUserWaiting;
            }
            return false
        },
        coCustomerInLobby() {
            if (this.coCustomerConnected) {
                const coCustomer = this.meetingHelper.currentMeetingUsers.find(user => user.role === "coCustomer")
                return coCustomer?.isUserWaiting;
            }
            return false
        },
         //isCustomerInPeopleInLobby(){
         //    // isCustomerInPeopleInLobby - used for when the fimanager is not yet in the meeting and needs to know if customer is already in the meeting
         //    if (!this.peopleInLobby || this.peopleInLobby.length <= 0) return false;
         //    return this.peopleInLobby.some(p => p.role === "customer")
         //},
         //isCoCustomerInPeopleInLobby(){
         //    // isCustomerInPeopleInLobby - used for when the fimanager is not yet in the meeting and needs to know if customer is already in the meeting
         //    if (!this.peopleInLobby || this.peopleInLobby.length <= 0) return false;
         //    return this.peopleInLobby.some(p => p.role === "coCustomer")
         //},
        customerConnected() {
            if (this.meetingHelper.currentMeetingUsers) {
                return this.meetingHelper.currentMeetingUsers.some(user => user.role === "customer");
            }
            return false;
        },
        coCustomerConnected() {
            if (this.meetingHelper.currentMeetingUsers) {
                return this.meetingHelper.currentMeetingUsers.some(user => user.role === "coCustomer");
            }
            return false;
        },
        meetingActive() {
            return this.$meetingHub.connected && this.meetingHelper.currentMeetingUsers?.length > 1;
        },
        hideInStoreConnection() {
            return this.$meetingHub.connected
                || (
                    this.customerConnected
                    || this.coCustomerConnected
                    || this.customerInLobby
                    || this.coCustomerInLobby
                    || this.peopleInLobby.length > 0
                   );
        },
        videoBtnStyle() {
            return { 'button button-icon-text': true, 'active': this.videoActive, 'complete': this.fimenu.hasCustomerSeenAllVideos(), 'hovered-desc': !this.isVideoButtonEnabled }
        },
        paymentSheetBtnStyle() {
            return { 'button button-icon-text': true, 'active': this.paymentSheetActive, 'complete': this.fimenu.coverageTerms.some(term => term.selected) }
        },
        allowRemoteDealMeetings() {
            //return this.fimenu.store.storeSettings.allowRemoteDealMeetings; // allowing remote deals based on store settings
            return this.user.AllowRemoteDealMeetings;
        },
        meetingHasEnded() {
            return this.meetingHelper.currentMeetingUsers.length < 1;
        },
        disableButtonForFIManagers() {
            return !userHasAccessToEditDeal(this.fimenu.dealStatus, auth.getTokenPayload().EmployeeAccess);
        },
        user() {
            return auth?.getTokenPayload();
        },
        isReconnecting() {
            return this.$meetingHub.connection._connectionState === "Reconnecting";
        },
        isAdminView() {
            return this.$global.isAdminView
        },
        

    },
    methods: {
        toggleDropdownMenu() {
            this.dropDownShowing = !this.dropDownShowing;
        },
        handleJoinMeeting(requestedValidationCode) {
            this.meetingHelper.startInStoreMeeting(requestedValidationCode);
        },
        preconnectionNotFound() {
            return this.meetingHelper.preConnectionFound === false;
        },
        customerWindowButtonEnabled() {
            return this.fimenu.store.storeSettings.isCustomerWindowButtonEnabled || this.isAdminView
        },
        openConnectToMeetingWindow() {
            window.open(`${this.settings.appDomain}/meetingroom/InStoreConnection?CustomerWindow=true`, '_blank', 'location=no,toolbar=no');
        },
        resetConnectionFailed() {
            this.meetingHelper.connectionFailed = false;
        },
        connectionFailed() {
            return this.meetingHelper.connectionFailed
        },
        closeModalEvent() {
            return this.meetingHelper.currentMeetingUsers?.length >= 2
        },
        handleInStoreConnection() {
            $modal.open(modalUseSendVerificationCode, {
                name: 'modalUseSendVerificationCode',
                passedData: {
                        title: "Deal Meeting Connection",
                        contentText: 'Enter the customer screen code below:',
                        onInputHandler: this.resetConnectionFailed,
                        loadingPage: this.preconnectionNotFound,
                        onCreated: this.resetConnectionFailed,
                        externalClose: this.closeModalEvent,
                        // sendCodeButton: {
                        //     label: 'Request Code',
                        //     onClick: this.handleJoinMeeting,
                        //     codeSent: false
                        // },
                        mainButton: {
                            label: 'Join Meeting',
                            invalid: this.preconnectionNotFound,
                            onClick: this.handleJoinMeeting,
                            onFail: this.connectionFailed
                        },
                        headerButton: {
                            label:'Open Customer Window',
                            onClick: this.openConnectToMeetingWindow,
                            isShown: this.customerWindowButtonEnabled,
                    }
                }, backdrop: false
            });
        },
        handleMeetingInvitation(who) {
            let payload = auth.getTokenPayload();
            LogRocket.track('Remote Meeting Started', {
                dealNumber: this.fimenu.dealNumber,
                user: payload.EmployeeLogin
            });
            $modal.open(modalCustomerMeetingInvitation, {
                name: 'modalCustomerMeetingInvitation',
                //passedData: { who: who },
                passedData: { who: who, customer: this.fimenu.customer, coCustomer: this.fimenu.coCustomer },
                postFunction: (meetingInvitation) => this.sendMeetingInvitation(meetingInvitation),

            });
        },
        sendMeetingInvitation(meetingInvitation) {
            // console.log('sendMeetingInvitation', meetingInvitation);
            this.meetingInvitation = meetingInvitation;

            this.meetingHelper.sendLinkToMeetingRoomParticipant(this.meetingInvitation, this.fimenu);

            if (this.meetingInvitation.startMeetingNow) {
                this.meetingHelper.startRemoteMeeting(this.fimenu)

                // Close dropdown after connecting to customer so fimanagers camera not blocked
                setTimeout(() => {
                    this.dropDownShowing = false;
                }, 2500)
            }


        },
        async allowUserIntoMeeting(role) {
            this.meetingHelper.isBusyConnecting = true;

            this.meetingHelper.cameraAccessAcknowledged = true
            this.meetingHelper.cameraAccessAllowed = true

            let userToAllow;
            // if the fimanager is not in the meeting, we need to check peopleInLobby (peopleInLobby will be empty once the fimanager joins the meeting)
            if (this.peopleInLobby.length > 0) {
                userToAllow = this.peopleInLobby.find(p => p.role === role);
            } else {
                userToAllow = this.meetingHelper.currentMeetingUsers.find(user => user.role === role)
            }
            // console.log('userToAllow', userToAllow);

            await this.meetingHelper.allowUserIntoMeeting(this.fimenu, userToAllow);

            setTimeout(() => {
                this.dropDownShowing = false;
            }, 2500)

        },
        confirmVideosFinished() { 
            if (!this.fimenu.hasCustomerSeenAllVideos() && !this.fimenu.hasCustomerSeenPaymentSheet()) {
                $modal.open(modalInfo, {
                    name: 'modalInfo',
                    passedData: {
                        info: 'Are you sure you want to show the payment sheet before showing the Customer the full video presentation?',
                        acceptText: 'OK',
                        cancelText: 'Cancel'
                    },
                    backdrop: false,
                    postFunction: () => {
                        this.customerScreenActions.openCustomerPopup('payment-sheet')
                        this.videoActive = false;
                        this.paymentSheetActive = true;
                    }
                });
            }
            else {
                this.customerScreenActions.openCustomerPopup('payment-sheet')
                this.videoActive = false;
                this.paymentSheetActive = true;
            }
        },
        handleVideoBtnClick() {    
            if (this.videoActive) {
                return;
            }
            this.videoActive = true;
            this.paymentSheetActive = false;
            this.customerScreenActions.openCustomerPopup('video-presentation');
        },
        changeCustomerVideoVolume(e) {
            const volumeLevel = e.target.value;
            this.$meetingHub.adjustCustomerVideoVolume(parseInt(volumeLevel));
        },
        handleVolumeProgress() {

            if (this.volumeLevel < 15) {
                this.volumeLevel = 15;
            }
            const sliderEl = this.$refs.volumeSlider

            sliderEl.style.background = `linear-gradient(to right, var(--main-color) ${this.volumeLevel}%, #ccc ${this.volumeLevel}%)`;
        },
        handleDisconnectParticipant(role) {
            const user = this.meetingHelper.currentMeetingUsers.find(user => user.role === role)
            this.meetingHelper.customerScreenConnected = false;
            this.meetingHelper.disconnectParticipant(user.userId);
        },
        handleEndMeeting() {
            this.videoActive = false;
            this.paymentSheetActive = false;

            this.meetingHelper.endMeeting();
        },
        openConnectionIssueModal() {
            $modal.open(modalInfo, {
                name: 'modalInfo',
                passedData: {
                    title: 'Connection Lost',
                    info: 'There seems to be an issue with your meeting connection. Please try to reconnect to the meeting',
                    acceptText: 'OK'
                },
                postFunction: async() => {
                    this.meetingHelper.customerScreenConnected = false;
                    this.meetingHelper.currentMeetingUsers = null;

                    LogRocket.track("MeetingHub - Lost Socket Connection");


                    //this.handleEndMeeting();
                }
            });
        },

    },
    components: {
        MeetingUser,
        EllipsisAnimated,
    },
}
</script>

<style>
.dropdown-menu {
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 1;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    background-color: var(--button-dropdown-list-background-color);
    padding: 3px 8px 8px;
    border: 1px solid var(--load-color);
    border-radius: var(--default-border-radius);
    box-shadow: 4px 6px 4px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-menu,
.dropdown-menu button {
    font-size: 14px;
}

.dropdown-menu.up {
    display: none;
}

.dropdown-menu #reconnecting {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    width: 100%;
    background: #d8d8d857;
    color: var(--third-color);
    backdrop-filter: blur(1px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown-menu-container {
    /*position: relative;*/
    /* padding: 5px; */

    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    box-sizing: border-box;
    background: var(--background-color);
    padding: 5px 0;
}

.dropdown-menu-container #end-meeting-button {
    height: 50px;
}



.meeting-user-container {
    margin-bottom: 2px;
}

.meeting-user-container .fas.fa-store-alt {
    padding: 5px;
}
.meeting-user-container button:disabled {
    border: none !important;
}
.meeting-user-container button:hover i {
    transform: scale(1.2);
}
.meeting-user-container button:hover i.disabled {
    transform: none;
}

.meeting-user-container .meeting-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--main-color);
    border-radius: var(--default-border-radius);
    padding: 8px;
    margin: 6px 0;
}

.meeting-user-container .meeting-user.waiting {
    animation: waitingPulse 1.5s infinite;
}

.meeting-user-container .meeting-user.presenting {
    animation: presentingPulse 2.5s infinite;
    border: 2px solid rebeccapurple;
}

.meeting-user-actions button {
    background-color: unset;
    padding: unset;
    border: unset;
    box-shadow: unset;
    height: fit-content;
    min-height: unset;
    padding: 5px;
}

.meeting-user-actions button:hover {
    box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.35);
}


.meeting-user-container .meeting-user .meeting-user-actions i.requesting-to-present {
    animation: waitingPulse 1.5s infinite;
}

.meeting-user-container .meeting-user#in-store-connection {
    padding-left: 30px;

}

.meeting-user-container .meeting-user#in-store-connection:hover {
    cursor: pointer;
    background: var(--main-color);
    color: white;
}

.meeting-user-container .meeting-user#in-store-connection:hover i {
    color: white;
}

.meeting-user-container .meeting-user .meeting-user-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    text-transform: capitalize;
}

.meeting-user-container .far.fa-circle,
.meeting-user-container .fas.fa-circle {
    cursor: auto;
    font-size: 12px;
    padding: 5px;
}

.meeting-user-container .far.fa-circle {
    color: #d8d8d8;
}

.meeting-user-container .fas.fa-circle.waiting,
.meeting-user-container .fas.fa-user-plus.waiting {
    color: rgb(243 205 0);
    /*color: var(--print-color);*/
    animation: userConnectedPulse 3s infinite;
}

.meeting-user-container .fas.fa-circle {
    color: #38ac2078;
    filter: blur(1px);
    animation: userConnectedPulse 3s infinite;
}

.meeting-user-container .far.fa-circle:hover,
.meeting-user-container .fas.fa-circle:hover,
.meeting-user-container .fas.fa-user-check:hover {
    cursor: auto;
    transform: none;
}

.meeting-user-container .far.fa-envelope {
    color: black;
}

.meeting-user-container .fas.fa-user-check {
    padding: 5px;
    color: rebeccapurple;
}

.meeting-user-container .fas.fa-store-alt {
    color: var(--main-color);
}

.meeting-user-actions {
    display: flex;
    justify-content: flex-end;
    gap: 4px;

}

.meeting-user-container .meeting-user-actions .fas.fa-key {
    color: rgb(243 205 0);
}
.meeting-user-container .meeting-user-actions .fas.fa-key.disabled {
    border: none;
    color: #ddd;
}
.meeting-user-container .meeting-user-actions .fas.fa-key.disabled:hover {
    transform: none;
    cursor: not-allowed;
}

.meeting-user-container .meeting-user-actions .fas.fa-phone {
    color: var(--success-color);
}

.meeting-user-container .meeting-user-actions .fas.fa-phone.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.meeting-user-container .meeting-user-actions .fas.fa-window-close,
.meeting-user-container .meeting-user-actions .fas.fa-window-close {
    color: var(--error-color);
}



.meeting-actions {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-bottom: 10px;
}

.meeting-actions button {
    background-color: #d8d8d8;
    color: var(--fimenubasic-title-color);
    border: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    height: 50px;
}

.meeting-actions button span {
    display: flex;
    justify-content: center;
    align-items: center;
}

.meeting-actions button span i {
    margin-right: 8px;
}

.meeting-actions button.active {
    color: var(--main-color);
    border: var(--main-color) 1px solid;
    /*color: var(--success-color);
                    border: var(--success-color) 1px solid;*/
}

.meeting-actions button.complete {
    color: var(--button-color);
    background-color: var(--success-color);
    border: var(--success-color) 1px solid;
    flex-wrap: wrap;
}

.meeting-actions button.complete span {
    min-width: 120px;
}

.meeting-actions button .fas.fa-check {
    display: none;
}

.meeting-actions button.complete .fas.fa-check {
    display: unset;
    flex: 1;
}


.volume-controls {
    /*position: absolute;
        top: -62px;
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;*/
}

.volume-controls .fas.fa-volume-up {
    margin-right: 8px;
}

.volume-slider-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin: 8px 0;
}

.volume-controls h4 {
    margin: 0;
    margin-bottom: 5px;
}

.volume-controls input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 210px;
    cursor: pointer;
    outline: none;
    border-radius: 15px;
    height: 6px;
    background: #ccc;
    border: 0;
    padding: 0;
}

.volume-controls input[type="range"]::-webkit-slider-thumb {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    height: 20px;
    width: 10px;
    background-color: var(--main-color);
    border-radius: 15%;
    border: none;
    transition: .2s ease-in-out;
}

.volume-controls input[type="range"]::-moz-range-thumb {
    height: 20px;
    width: 8px;
    background-color: var(--main-color);
    border-radius: 15%;
    border: none;
    transition: .2s ease-in-out;
}

/* Hover, active & focus Thumb: Webkit */

input[type="range"]::-webkit-slider-thumb:hover {
    box-shadow: 0 0 0 10px rgba(40, 128, 144, .1);
}

input[type="range"]:active::-webkit-slider-thumb {
    box-shadow: none;
}

input[type="range"]:focus::-webkit-slider-thumb {
    box-shadow: none;
}

/* Hover, active & focus Thumb: Firfox */

input[type="range"]::-moz-range-thumb:hover {
    box-shadow: 0 0 0 10px rgba(40, 128, 144, .1);
}

input[type="range"]:active::-moz-range-thumb {
    box-shadow: none;
}

input[type="range"]:focus::-moz-range-thumb {
    box-shadow: none;
}


@keyframes userConnectedPulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes waitingPulse {
    0% {
        /*transform: scale(0.99);*/
        box-shadow: 0 0 0 0 rgba(245, 137, 0, 0.5);
    }

    70% {
        /*transform: scale(1);*/
        box-shadow: 0 0 0 10px rgba(245, 137, 0, 0);
    }

    100% {
        /*transform: scale(0.99);*/
        box-shadow: 0 0 0 0 rgba(245, 137, 0, 0);
    }
}

@keyframes presentingPulse {
    0% {
        /*transform: scale(0.99);*/
        box-shadow: 0 0 0 0 rgba(110, 137, 245, 0.5);
    }

    70% {
        /*transform: scale(1);*/
        box-shadow: 0 0 0 10px rgba(110, 137, 245, 0);
    }

    100% {
        /*transform: scale(0.99);*/
        box-shadow: 0 0 0 0 rgba(110, 137, 245, 0);
    }
}
</style>