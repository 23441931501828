<template>
    <div class="modal-container modal-medium modal-vehicle">
        <div class="modal-header">
           Badges
        </div>
        <div class="modal-body">


        </div>
        <div class="modal-footer">
            <button class="button-unwind" @click="cancel()">Close</button>
        </div>
    </div>
</template>


<script>
    import $modal from '@core/services/modal'


    export default {
        name: "modalBadges",
        props: ['modal'],
        data() {
            return {

            }
        },
        computed: {

        },
        methods: {

        },
        components: {

        }
    };
</script>
<style>

</style>
