<template>
    <Panel title="Customer Interactions" :action="panelActions">
        <div v-if="signalRNotes.length != 0" class="customer-actions-container">
            <div v-for="(note, index) in signalRNotes" :key="index" class="customer-actions" ref="customerActions">
                <div class="action-icon">
                    <i :class="note.icon"></i>
                </div>

                <span>{{ note.text }}</span>
            </div>
        </div>

        <div v-else>
            No Interactions to Show
        </div>
    </Panel>
</template>

<script>
    import Panel from '@core/components/Panel.vue'

    export default {
        name: "PanelCustomerConnectionHistory",
        emits: {
            disconnect() {
                return true;
            }
        },
        props: {
            isCustomerScreenConnected: Boolean,
            signalRNotes: Array
        },
        data() {
            return {
                disconnectAction: {
                    text: 'Disconnect',
                    class: 'button-unwind',
                    onClick: () => this.$emit('disconnect')
                }
            }
        },
        computed: {
            panelActions() {
                return (this.isCustomerScreenConnected) ? [this.disconnectAction] : [];
            },
            notesLength() {
                return this.signalRNotes.length;
            }
        },
        watch: {
            notesLength: {
                handler(newVal) {
                    if (newVal < 1 || !this.$refs.customerActions) return;

                    this.$nextTick(() => {
                        const actionList = this.$refs.customerActions;
                        if (actionList.length < 1) return;

                        const lastAction = actionList[actionList.length - 1];
                        lastAction.scrollIntoView({ block: 'end', inline: 'nearest' });
                    });
                },
                deep: true
            }
        },
        components: {
            Panel,
        }
    };
</script>