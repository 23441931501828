<template>
    <div class="panel-deal-jacket" :style="$grid('1')">
        <TabMenu :tabs="tabs" :initialTab="initialTab" ref="tabMenu">
            <template v-slot:[`tab-head-documents`]>
                Active
            </template>
            <template v-slot:[`tab-panel-documents`]>
                <div>
                    <DocumentPacket :packet="packet"
                                    :showTitle="true"
                                    :showButtons="true"
                                    :fimenu="fimenu"
                                    :setVoidedPacket="setVoidedPacket"
                                    :modalStatus="modalStatus"
                                    ref="documentPacket"></DocumentPacket>
                </div>
            </template>

            <template v-slot:[`tab-head-alldocs`]>
                Voided
            </template>
            <template v-slot:[`tab-panel-alldocs`]>
                <div class="folder-list">
                    <DocumentPacketList v-if="packetList && packetList.length > 0"
                                        :restorePacket="restorePacket"
                                        :packets="packetList.filter(pList => pList.isCurrent == false)"
                                        :fimenu="fimenu"></DocumentPacketList>
                </div>
            </template>

           <!--  <template slot="tab-head-files">
                Files
            </template>
            <template slot="tab-panel-files">
                <div class="folder-list">
                    <DirectoryItem :item="documents.data"
                                   :getContents="loadContents"
                                   :rootName="rootName"></DirectoryItem>
                </div>
            </template> -->

            <template v-slot:[`tab-head-otherdocs`]>
                Other Documents
            </template>
            <template v-slot:[`tab-panel-otherdocs`]>
                <div>
                    <PanelCustomerDocuments :fimenu="fimenu"></PanelCustomerDocuments>
                </div>
            </template>
        </TabMenu>
    </div>
</template>

<script>
    import api from "@core/services/api";
    import auth from "@core/services/auth";
    import util from "@core/services/util";
    import ENUMS from "@core/classes/Enums";
    import Panel from "@core/components/Panel.vue";
    import TabMenu from "@/components/TabMenu.vue";
    import DocumentPacket from "@/components/DocumentPacket.vue";
    import DocumentPacketList from "@/components/DocumentPacketList.vue";
    import PanelCustomerDocuments from '@/components/PanelCustomerDocuments.vue';

    export default {
        name: "PanelDealJacket",
        props: ["fimenu", "rootName"],
        data() {
            return {
                voidedPacket: null,
                packet: null,
                packetList: null,
                headers: null,
                voidedDocuments: null,
                voidedHeaders: null,
                tabs: ["documents", "alldocs", "otherdocs"],
                initialTab: "documents",
                documents: {
                    packetId: null,
                    path: null,
                    data: null,
                    isError: false,
                    isBusy: false,
                },
                modalStatus: {
                    modalBusy: false,
                },
                documentUploadEnabled: false,
            };
        },
        computed: {
            api() {
                return api;
            },
            ENUMS() {
                return ENUMS;
            },
            user() {
                return auth.getTokenPayload();
            },
        },
        created() {
            this.init();
            this.documentUploadEnabled =
                this.fimenu?.store?.storeSettings?.isDealDocumentUploadEnabled ?? false;

            this.documents.packetId = this.fimenu.id;
            this.documents.path = this.documents.packetId;

            this.loadPaperworkDirectory(this.documents.path);

            this.EventBus.on("paperworkReset", this.init);
        },
        beforeUnmount(){
            this.EventBus.off("paperworkReset", this.init);
        },
        methods: {
            setVoidedPacket() {
                this.voidedPacket = true;
            },
            init() {
                if (this.fimenu.paperwork && this.fimenu.paperwork.packets)
                    this.packetList = this.fimenu.paperwork.packets;
                else this.packetList = null;

                if (this.packetList)
                    this.packet = this.packetList.find((p) => p.isCurrent);
                else this.packet = null;

                if (util.isNull(this.packet) && this.voidedPacket) {
                    this.packet =
                        this.fimenu.paperwork.packets[
                        this.fimenu.paperwork.packets.length - 1
                        ];
                }

                if(!this.fimenu.paperwork.uploadedDocuments) this.fimenu.getUploadedDocuments()
            },
            restorePacket(packet) {
                packet.documents.forEach((d) => {
                    d.voidInfo = null;
                    if (d.signedDoc) {
                        d.status = ENUMS.PaperworkDocumentStatus.Signed;
                    } else if (d.errorActivating) {
                        d.status = ENUMS.PaperworkDocumentStatus.ErrorActivating;
                    } else if (d.error) {
                        d.status = ENUMS.PaperworkDocumentStatus.ErrorGenerating;
                    } else {
                        d.status = ENUMS.PaperworkDocumentStatus.ReadyToBeSigned;
                    }
                });
                packet.isCurrent = true;
                this.packet = packet;
                this.$refs.tabMenu.switchTab(this.tabs[0]);
                this.EventBus.emit("paperworkEndedActivating");
            },
            loadContents(item) {
                if (item.isDirectory) return api.fimenu.getPaperworkDirectory(item.path);
                else return api.fimenu.downloadDocument(item.path, item.name);
            },
            loadPaperworkDirectory(path) {
                this.documents.isBusy = true;
                this.documents.isError = false;
                return api.fimenu
                    .getPaperworkDirectory(path)
                    .then((response) => {
                        //console.log('getPaperworkDirectory', response)
                        if (response && response.data) {
                            response.data.contents.forEach((content) => {
                                content.name = this.formatTimestamp(content.name);
                            });
                            this.documents.data = response.data;
                        }
                    })
                    .catch((error) => {
                        this.documents.isError = true;
                    })
                    .finally(() => {
                        this.documents.isBusy = false;
                    });
            },
            formatTimestamp(timestamp) {
                let formatedDate = timestamp.split("_")[0];
                formatedDate =
                    formatedDate.substring(4, 6) +
                    "/" +
                    formatedDate.substring(6, 8) +
                    "/" +
                    formatedDate.substring(0, 4);
                let formatedTime = timestamp.split("_")[1];
                formatedTime =
                    formatedTime.substring(0, 2) +
                    ":" +
                    formatedTime.substring(2, 4) +
                    ":" +
                    formatedTime.substring(4, 6);

                const time = new Date(formatedDate + "-" + formatedTime).toLocaleString(
                    "en-US",
                    { hour: "numeric", minute: "numeric", hour12: true }
                );

                return formatedDate + " @ " + time;
            },
        },
        components: {
            TabMenu,
            DocumentPacket,
            DocumentPacketList,
            PanelCustomerDocuments,
        },
    };
</script>
<style>
    .paperwork-info-top {
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        font-size: 14px;
    }

    .headerinfo {
        font-weight: bold;
    }
</style>
