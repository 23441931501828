<template>
    <div class="customer-footer">
        <button
            v-if="customer && customer.id && displayPanelCustomer"
            class="button button-icon-text button-save create-quote"
            style="max-width: 150px"
            :disabled="isButtonDisabled"
            @click="handleCreateDeal"
        >
            <i class="fas fa-plus" ></i>
            <!-- {{buyersOrdersEnabled ? "Create Quote" : "Create Deal"}} -->
            Create Quote
        </button>
    </div>
</template>
<script lang="ts">
import {PropType, defineComponent} from 'vue';
import FIMenuCustomer from "@core/classes/FIMenuCustomer.js";
import settings from 'settings';
import util from '@core/services/util';

export default defineComponent ({

    name: 'CustomerDetailsFooter',
    components: {
    },
    data() {
        return {
            legalTermsToBeAgreedTo: []
        }
    },
    props: {
        customer: {
            type: Object as PropType<FIMenuCustomer>,
            default: () => new FIMenuCustomer()
        },
        selectedStoreCode: {
            type: String || null,
            default: null
        },
        selectedStore: {
            type: Object,
            default: null
        },
        isCreatingDeal: {
            type: Boolean,
            required: true
        },
        deals: {
            type: Array as PropType<object[]>,
            required: true,
            default: ():object[] => []
        },
        maxDealsInTab: {
            type: Number,
            default: 5
        },
        createDeal: {
            type: Function,
            required: true
        }


    },

    computed: {
        buyersOrdersEnabled():boolean {
            return this.selectedStore?.storeSettings?.buyersOrderEnabled;
        },
        userStores(): string[] {
            return settings.userStores;
        },
        displayPanelCustomer(): boolean {
            return !!this.selectedStoreCode;
        },
        isButtonDisabled(): boolean {
            return this.customer.isThereAnyUnsavedCustomerChanges || this.isCreatingDeal
        }

    },
    methods: {

        handleCreateDeal():void {
            if (this.deals.length >= this.maxDealsInTab) {
                return util.modalInformation(
                    `You have reached the maximum tabs limit (${this.maxDealsInTab}). Please close one before creating a new deal.`
                );
            } else {
                this.createDeal();
            }
        }
    },
})
</script>
<style scoped>

</style>