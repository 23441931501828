<template>
    <div class="esign-mobile">
        <div class="esign-drawing">
            <swiper class="swiper" :options="swiperOption" ref="swiper" @slideChange="handleSlideChange" @swiper="onSwiper">
                <swiper-slide>
                    <div class="esign-pad" :class="{invalid: signatureInvalid}">
                        <div class="pad-title">Signature</div>
                        <div class="pad-wrapper">
                            <!-- <VueSignaturePad ref="signature" :options="{ dotSize, minWidth, maxWidth }"
                                class="pad-container" :style="{ aspectRatio: '5 / 2'}" /> -->
                            <div :class="{invalid: signatureInvalid, 'pad-container': true}" ref="signatureCanvas">
                                <canvas ></canvas>
                            </div>
                            <button class="button-clear" :disabled="!signaturePoints" @click="clearPad('signature')">
                                <i class="fas fa-trash"></i>
                            </button>
                            <div class="signature-line">
                                <div class="mark"><i class="fas fa-signature"></i></div>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="esign-pad" :class="{invalid: initialsInvalid}">
                        <div class="pad-title">Initials</div>
                        <div class="pad-wrapper">
                            <!-- <VueSignaturePad ref="initials" :options="{ dotSize, minWidth, maxWidth }"
                                class="pad-container" :style="{ aspectRatio: '223 / 214'}" /> -->
                            <div :class="{invalid: initialsInvalid, 'pad-container': true}" ref="initialsCanvas">
                                <canvas ></canvas>
                            </div>
                            <button class="button-clear" :disabled="!initialsPoints" @click="clearPad('initials')">
                                <i class="fas fa-trash"></i>
                            </button>
                            <div class="signature-line">
                                <div class="mark"><i class="fas fa-signature"></i></div>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-footer">
                        <!-- <button @click="cancelSignature" id="cancel-btn">Cancel</button> -->
                        <button @click="submitSignature" class="fade-in" id="continue-btn"
                            v-if="!signatureInvalid && !initialsInvalid">Submit</button>
                    </div>
                </swiper-slide>

                <!-- <div class="custom-button-prev" slot="button-prev">
                    <i class="fas fa-chevron-left"></i>
                </div>
                <div class="custom-button-next" :class="{ 'disabled': nextDisabled() }" slot="button-next">
                    <i class="fas fa-chevron-right"></i>
                </div> -->
                <div class="custom-button-prev button-prev" @click="swipePrev">
                    <i class="fas fa-chevron-left"></i>
                </div>
                <div class="custom-button-next button-next" @click="swipeNext">
                    <i class="fas fa-chevron-right"></i>
                </div>
                </swiper>

        </div>

        <button class="button-cancel" @click="cancelSignature">
            <i class="fas fa-times"></i>
        </button>
    </div>
</template>


<script lang="ts">
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import SignaturePad from 'signature_pad'
    import 'swiper/swiper.css'
    import api from '@core/services/api';
    import util from '@core/services/util';
    import { defineComponent } from "vue";
    import { adjustSignatureToBaseline } from '@core/helpers/esign-helper';

    export default defineComponent({
        name: "ESignMobile",
        props: ['name', 'cancelHandler', 'submitHandler', 'userInfo'],
        data() {
            return {
                eSignId: null,

                signatureData: {
                    _isEmpty: true
                },
                initialsData: {
                    _isEmpty: true
                },

                swiper: null,
                signaturePad: null,
                initialsPad: null,

                dotSize: (1.25 + 5) / 2,
                minWidth: 1.25,
                maxWidth: 5,

                signature: null,
                initials: null,

                currentSlideIndex: 0,

                isFullscreen: false,
                isFinished: false,
                uploadedSuccessfully: false,
                signaturePoints: 0,
                initialsPoints: 0,
                swiperOption: {
                    noSwiping: true,
                    allowTouchMove: true,
                    initialSlide: 0,
                    grabCursor: true,
                    centeredSlides: true,
                    slidesPerView: 'auto',
                    navigation: {
                        nextEl: '.custom-button-next',
                        prevEl: '.custom-button-prev'
                    },
                },
            };
        },
        async created() {
            this.eSignId = (this as any).$route.query.id;

            const canStartSigning = await api.users.eSignMobile.startSigning(this.eSignId);

            if (!canStartSigning?.data) {
                this.uploadedSuccessfully = false;
                this.isFinished = true;
                return;
            }
        },
        mounted() {
            const canvases = document.querySelectorAll('.pad-container canvas');

            canvases.forEach(c => {
                c.classList.add('swiper-no-swiping');
            });

            window.addEventListener("resize", this.recalculateDotSize);
            this.recalculateDotSize()

            // this.$nextTick(() => {
            //     this.$refs.signature.resizeCanvas();
            //     this.$refs.initials.resizeCanvas();

            //     this.signatureData = this.$refs.signature.signaturePad;
            //     this.initialsData = this.$refs.initials.signaturePad;
            // })

            this.$nextTick(() =>{
                // console.log('This is refs', this.$refs)
                const signatureCanvas = (this.$refs?.signatureCanvas as any)?.firstChild;
                const initialsCanvas = (this.$refs?.initialsCanvas as any)?.firstChild;

                const sigOptions = {dotSize: (1.25 + 5)/2, minWidth: 1.25, maxWidth: 5 }
                // console.log("This is the pad vars", signatureCanvas, initialsCanvas)
                if (signatureCanvas && initialsCanvas){
                    this.signaturePad = new SignaturePad(signatureCanvas, sigOptions);
                    this.initialsPad = new SignaturePad(initialsCanvas, sigOptions)

                    this.signaturePad.addEventListener('afterUpdateStroke', () => {
                        this.updatePad('signature');
                    })
                    this.initialsPad.addEventListener('afterUpdateStroke', () => {
                        this.updatePad('initials');
                    })
                    window.addEventListener('resize', this.resizeCanvas);
                    this.resizeCanvas();
                }
            })


        },
        computed: {
            // signaturePoints() {
            //     if (this.signatureData._isEmpty || !this.signatureData._data) return 0;

            //     return this.signatureData._data.reduce((total, stroke) => total + stroke.points.length, 0);
            // },
            // initialsPoints() {
            //     if (this.initialsData._isEmpty || !this.initialsData._data) return 0;

            //     return this.initialsData._data.reduce((total, stroke) => total + stroke.points.length, 0);
            // },
            signatureInvalid(): boolean {
                return this.signaturePoints < 20;
            },
            initialsInvalid(): boolean {
                return this.initialsPoints < 20;
            },
            uploadButtonDisabled(): boolean {
                return this.signatureInvalid || this.initialsInvalid;
            },
            // esignPadWidthStyles() {
            //     return this.currentSlideIndex === 0 ? {width: 'fit-content'} : 'fit-content'
            // },
        },
        methods: {
            onSwiper(swiper: any){
                this.swiper = swiper;
                // console.log("New swiper", swiper, this.terms);
            },
            isFullscreenAPISupported() {
                return document.documentElement.requestFullscreen
                    // document.documentElement.mozRequestFullScreen || // Firefox
                    // document.documentElement.webkitRequestFullscreen || // Chrome, Safari, Opera
                    // document.documentElement.msRequestFullscreen; // IE/Edge
            },
            toggleFullscreen() {
                this.isFullscreen = !util.isNull(document.fullscreenElement);

                if (this.isFullscreen) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    }
                    // } else if (document.mozCancelFullScreen) { // Firefox
                    //     document.mozCancelFullScreen();
                    // } else if (document.webkitExitFullscreen) { // Chrome, Safari, Opera
                    //     document.webkitExitFullscreen();
                    // } else if (document.msExitFullscreen) { // IE, Edge
                    //     document.msExitFullscreen();
                    // }
                }
                else {
                    if (document.documentElement.requestFullscreen) {
                        document.documentElement.requestFullscreen();
                    // } else if (document.documentElement.mozRequestFullScreen) { // Firefox
                    //     document.documentElement.mozRequestFullScreen();
                    // } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari, Opera
                    //     document.documentElement.webkitRequestFullscreen();
                    // } else if (document.documentElement.msRequestFullscreen) { // IE, Edge
                    //     document.documentElement.msRequestFullscreen();
                    }
                }
            },
            resizeCanvas(){
                // console.log('Did we resize?')
                // Handle resize of canvas
                if (this.$refs.signatureCanvas && this.$refs.initialsCanvas){
                    (this.$refs.signatureCanvas as any).firstChild.width = (this.$refs.signatureCanvas as any).offsetWidth;
                    (this.$refs.signatureCanvas as any).firstChild.height = (this.$refs.signatureCanvas as any).offsetHeight;
                    (this.$refs.initialsCanvas as any).firstChild.width = (this.$refs.initialsCanvas as any).offsetWidth;
                    (this.$refs.initialsCanvas as any).firstChild.height = (this.$refs.initialsCanvas as any).offsetHeight;

                    this.clearPad('signature');
                    this.clearPad('initials');
                }
            },
            clearPad(pad: any) {
                if(this.signaturePad && pad == 'signature'){
                    this.signaturePad.clear();
                    this.signaturePoints = 0;
                } else if(this.initialsPad && pad == 'initials'){
                    this.initialsPad.clear();
                    this.initialsPoints = 0;
                }
            },
            updatePad(pad: any){
                if (pad == 'signature' && this.signaturePad._isEmpty === false && this.signaturePad._data.length > 0){
                    this.signaturePoints = this.signaturePad._data.reduce((acc: any, curr: any) => acc + curr.points.length, 0)
                } else if (pad == 'initials' && this.initialsPad._isEmpty === false && this.initialsPad._data.length > 0){
                    this.initialsPoints = this.initialsPad._data.reduce((acc: any, curr: any) => acc + curr.points.length, 0)
                }
            },
            recalculateDotSize() {
                const basePadWidth = 1200;
                const basePadHeight = 240;

                const actualPadWidth = (this.$refs.signature as any)?.$el?.offsetWidth;
                const actualPadHeight = (this.$refs.signature as any)?.$el?.offsetHeight;

                const scaleFactor = Math.min(actualPadWidth / basePadWidth, actualPadHeight / basePadHeight);

                const baseDotSize = 3.125;
                const baseMinDotSize = 1.25;
                const baseMaxDotSize = 5;

                this.dotSize = Math.max(baseDotSize * scaleFactor, 0.5);
                this.minWidth = Math.max(baseMinDotSize * scaleFactor, 0.5);
                this.maxWidth = Math.max(baseMaxDotSize * scaleFactor, 0.5);
            },
            nextDisabled() {
                const nextDisabled = (this.currentSlideIndex === 0 && this.signatureInvalid) ||
                    (this.currentSlideIndex === 1 && this.initialsInvalid);

                if (!util.isNull((this.$refs.swiper as any)?.$swiper)) {
                    (this.$refs.swiper as any).$swiper.allowSlideNext = !nextDisabled;
                }

                return nextDisabled;
            },
            swipeNext() {
                this.swiper?.slideNext(500)
            },
            swipePrev() {
                // console.log("Refs in swipe", this.swiper)
                this.swiper?.slidePrev(500)
            },
            handleSlideChange() {
                this.currentSlideIndex = (this.$refs.swiper as any)?.$swiper?.realIndex ?? 0;
            },
            clearInitials() {
                (this.$refs.initials as any).clearSignature()
            },
            cancelSignature() {
                console.log('cancel button clicked');
                if (typeof this.cancelHandler == 'function') {
                    this.cancelHandler()
                }
            },
            submitSignature() {
                if (typeof this.submitHandler == 'function') {
                    const signatureCanvas = (this.$refs.signatureCanvas as HTMLElement)?.firstChild as HTMLCanvasElement;
                    const initialsCanvas = (this.$refs.initialsCanvas as HTMLElement)?.firstChild as HTMLCanvasElement;

                    const signature = adjustSignatureToBaseline(signatureCanvas);
                    const initials = adjustSignatureToBaseline(initialsCanvas);

                    this.submitHandler(signature, initials);
                }
            },
        },
        components: {
            Swiper,
            SwiperSlide,
        }
    })
</script>

<style>

    .esign-mobile {
        position: absolute;
        background: white;
        z-index: 3;
    }

    .esign-mobile .button-cancel,
    .esign-mobile .esign-uploaded,
    .esign-mobile .esign-drawing .button-clear {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none !important;
    }

    .esign-mobile .button-cancel {
        color: white;
        background: var(--error-color);
        position: fixed;
        top: 0;
        left: 0;
        width: 14dvh;
        height: 14dvh;
        max-height: 55px;
        max-width: 55px;
        padding: 0;
        border-radius: 0 0 50% 0;
        font-size: 8dvh;
        font-size: clamp(2.2em, 6cqh, 4.3em);
        z-index: 15;
    }

    .esign-uploaded, .esign-drawing {
        width: 100dvw;
        height: 100dvh;
        color: var(--pdf-signature-pad-container-color);
    }

    .esign-uploaded {
        flex-direction: column;
        text-align: center;
        font-weight: 500;
        animation: lds-ellipsis1 0.6s ease 1;
    }

        .esign-uploaded .esign-uploaded-text {
            margin: 2vmin;
            font-size: 15vmin;
        }

            .esign-uploaded .esign-uploaded-text.smaller {
                font-size: 8vmin;
            }

        .esign-uploaded .error {
            color: var(--error-color);
        }

    .esign-drawing {
        display: flex;
    }

        .esign-drawing .swiper {
            width: 100%;
            height: 100%;
        }

            .esign-drawing .swiper .swiper-slide {
                display: flex;
                justify-content: center;
                align-items: center;
            }

        .esign-drawing .esign-pad {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 2dvh;
            margin: calc(max(1dvh, env(safe-area-inset-bottom)) + 1dvh) 0;
            height: 90%;
            width: fit-content;
        }

            .esign-drawing .esign-pad .pad-title {
                font-size: 6dvh;
            }

            .esign-drawing .esign-pad .pad-wrapper {
                position: relative;
                user-select: none;
            }

                .esign-drawing .esign-pad .pad-wrapper .pad-container {
                    --display-width: calc(100dvw - (env(safe-area-inset-left) + env(safe-area-inset-right)));
                    --display-height: calc(100dvh - (env(safe-area-inset-top) + env(safe-area-inset-bottom)));

                    max-height: min(calc(.85 * var(--display-width) * (2 / 5)), calc(.8 * var(--display-height)));
                    width: auto !important;
                    height: auto !important;
                    border: 2px solid var(--pdf-signature-initials-pad-color);
                    transition: 0.25s ease-in-out border;
                    box-sizing: border-box;
                }

                    .esign-drawing .esign-pad.invalid .pad-wrapper .pad-container {
                        border: 2px solid var(--error-color);
                    }

                .esign-drawing .esign-pad .pad-wrapper .button-clear {
                    position: absolute;
                    top: 0;
                    right: 0;
                    transform: translate(50%, -50%);
                    border-radius: 50%;
                    aspect-ratio: 1;
                    width: auto;
                    height: 12dvh;
                    padding: 0;
                }

                    .esign-drawing .esign-pad .pad-wrapper .button-clear i {
                        font-size: 6dvh;
                    }

            .esign-drawing .esign-pad .pad-wrapper .signature-line {
                display: flex;
                align-items: flex-end;
                position: absolute;
                left: 50%;
                right: 50%;
                bottom: 15%;
                transform: translateX(-50%);
                width: 80%;
                z-index: -1;
            }

                .esign-drawing .esign-pad .pad-wrapper .signature-line .mark {
                    color: var(--pdf-signature-initials-pad-color);
                    margin-right: 15px;
                    font-size: 20px;
                }

                .esign-drawing .esign-pad .pad-wrapper .signature-line .line {
                    background-color: var(--pdf-signature-initials-pad-background-color);
                    width: 100%;
                    height: 2px;
                    margin-bottom: 4px;
                }

        .esign-drawing .upload-button {
            font-size: 10dvh;
            font-weight: 900;
            max-width: 70dvw;
            width: unset;
            height: auto;
            padding: 5dvh 10dvh;
            border-radius: 100000px;
        }

    .swiper .custom-button-next,
    .swiper .custom-button-prev {
        color: var(--main-color);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 900;
        font-size: 6dvw;
        z-index: 10;
        transition: var(--transition);
        transition-duration: 0.15s;
    }

    .swiper .custom-button-prev {
        left: max(2dvw, env(safe-area-inset-left));
        right: auto;
    }

    .swiper .custom-button-next {
        right: max(2dvw, env(safe-area-inset-right));
        left: auto;
    }

        .swiper .custom-button-next i,
        .swiper .custom-button-prev i {
            transform-origin: right;
            transform: scale(0.75, 1.25);
        }

        .swiper .custom-button-next.swiper-button-disabled,
        .swiper .custom-button-prev.swiper-button-disabled {
            opacity: 0;
            pointer-events: none;
        }

        .swiper .custom-button-next.disabled,
        .swiper .custom-button-prev.disabled {
            opacity: 0.4;
            pointer-events: none;
        }

        #continue-btn {
            position: absolute;
            bottom: 3%;
            right: 5%;
            width: 5%;

            height: auto;
            width: auto;
            padding: 10px 20px;
            background: var(--success-color);
            color: var(--paymentsheet-ownership-button-color-text);
            border: solid 2px var(--success-color);
            cursor: pointer;
            font-size: clamp(2.2em, 6cqh, 4.3em);
            z-index: 10;
        }

        #cancel-btn {
            position: absolute;
            bottom: 3%;
            left: 7%;
            width: 5%;

            height: auto;
            width: auto;
            padding: 10px 20px;
            background: var(--error-color);
            color: var(--paymentsheet-ownership-button-color-text);
            border: solid 2px var(--error-color);
            cursor: pointer;
            font-size: clamp(2.2em, 6cqh, 4.3em);
            z-index: 10;

        }

</style>