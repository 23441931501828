<template>
    <Panel title="Vehicle Recall Details" class="panel-vehicle-recall" :action="panelActions">
        <IsBusySectionComponent v-show="isBusy" />

        <p v-if="!isBusy && recallResults.length < 1">
            <span v-if="!hasEnoughSearchData">
                Not enough data to perform a search.
            </span>
            <span v-else>
                No recalls have been found for this vehicle.
            </span>
        </p>

        <p v-show="recallResults.length > 0">
            <b class="recall-count">{{recallResults.length}}</b> recall{{(recallResults.length !== 1) ? 's are' : ' is'}}
            associated with this vehicle model. Perform a VIN lookup to check whether this vehicle has any open recalls on it.
        </p>

        <p v-show="showingHistoricalRecalls" class="historical-warning">
            <i class="fas fa-exclamation-circle" />
            Below is a list of historical recalls found for this vehicle model. These recalls may not be applicable for this specific VIN.
        </p>

        <div class="recall-accordion-group">
            <AccordionSection v-for="recall in recallResults" :key="recall.NHTSACampaignNumber" :isValid="showingHistoricalRecalls ? null : false">
                <template #accordionHeader>
                    <div class="recall-header">
                        <div>
                            <h1>{{recall.getVehicleName()}}</h1>
                            <p>
                                Recall Issued:
                                <b>{{recall.getFormattedReportDate()}}</b>
                            </p>
                            <p>
                                NHTSA #:
                                <b>{{recall.nhtsaCampaignNumber}}</b>
                            </p>
                        </div>

                        <Pill v-if="recall.parkOutSide || recall.parkIt"
                              :label="recall.parkOutSide ? 'Severe Fire Risk' : 'Do Not Drive'"
                              :status="recall.parkOutSide ? 'error' : 'warning'" />
                    </div>
                </template>

                <template #accordionActions>
                    <div class="affected-components">
                        <Pill v-for="component in recall.getComponentList()"
                              :key="component"
                              :label="component"
                              status="info">
                            {{component}}
                        </Pill>
                    </div>
                </template>

                <template #accordionBody>
                    <p class="alert-text">
                        <i class="fas fa-exclamation-circle" />
                        {{recall.consequence}}
                    </p>

                    <p>{{recall.summary}}</p>
                    <p>{{recall.remedy}}</p>
                    <p>{{recall.notes}}</p>
                </template>
            </AccordionSection>
        </div>
    </Panel>
</template>
<script lang="ts">
    import { defineComponent } from 'vue';
    import util from '@core/services/util';
    import { validVIN } from '@core/services/custom-validators';
    import { NHTSARecallResponse } from '@core/classes/NHTSARecallResponse';
    import Panel from '@core/components/Panel.vue';
    import Pill from '@core/components/Pill.vue';
    import IsBusySectionComponent from '@core/components/IsBusySectionComponent.vue';
    import AccordionSection from '@core/components/AccordionSection.vue';

    export default defineComponent({
        name: 'PanelVehicleRecallInfo',
        components: {
            IsBusySectionComponent,
            Panel,
            Pill,
            AccordionSection
        },
        props: {
            noOpenRecalls: Boolean,     // When this is set to true, will not perform any lookups and will assume that the vehicle has no recalls.
            vin: {
                type: String,
                required: true
            },
            make: String,
            model: String,
            modelYear: [String, Number],
            nhtsaCampaigns: {
                type: Array<string>,
                default: (): Array<string> => []
            },
        },
        data() {
            return {
                isBusy: false,
                recallResults: [],
            }
        },
        computed: {
            isVinValid(): boolean {
                return this.vin && validVIN(this.vin);
            },
            showingHistoricalRecalls(): boolean {
                return !this.noOpenRecalls && this.nhtsaCampaigns.length < 1 && this.recallResults.length > 0;
            },
            canDoVehicleLookup(): boolean {
                return this.make && this.model && !!this.modelYear;
            },
            canDoCampaignLookup(): boolean {
                return this.nhtsaCampaigns && this.nhtsaCampaigns.length > 0;
            },
            hasEnoughSearchData(): boolean {
                return this.canDoVehicleLookup || this.canDoCampaignLookup;
            },
            nhtsaVinLookupUrl(): string {
                if (!this.isVinValid) return null;
                return `https://www.nhtsa.gov/recalls?vin=${this.vin}`;
            },
            panelActions(): object[] {
                if (!this.isVinValid) return null;

                return [
                    {
                        text: 'VIN Lookup',
                        icon: 'fas fa-external-link-alt',
                        class: 'vin-lookup-button ignore-all-locks',
                        onClick: this.openNHTSALink
                    }
                ];
            }
        },
        watch: {
            nhtsaCampaigns() {
                this.getRecallInfo();
            },
            noOpenRecalls(newVal: boolean) {
                if (!newVal)
                    this.getRecallInfo();
                else
                    this.recallResults = [];
            }
        },
        async created() {
            this.$watch(() => `${this.make}_${this.model}_${this.modelYear}`, () => {
                this.getRecallInfo();
            }, { immediate: true });
        },
        methods: {
            getRecallInfo(): void {
                util.debounce(this.getRecallInfoDebounced, 250);
            },
            async getRecallInfoDebounced(): Promise<void> {
                if (!this.hasEnoughSearchData || this.noOpenRecalls) return;

                this.isBusy = true;
                try {
                    if (this.canDoCampaignLookup)
                        this.fetchAndFilterAllCampaigns()
                    else
                        this.fetchVehicleRecall()
                }
                catch (err) {
                    console.error(err);
                    util.toastr('error', 'Error', 'An error occurred while trying to lookup vehicle recalls.');
                }
                finally {
                    this.isBusy = false;
                }
            },
            async fetchVehicleRecall(): Promise<void> {
                if (!this.canDoVehicleLookup) return;

                this.recallResults = [];
                const recallResponse = await NHTSARecallResponse.lookupRecalls(this.make, this.model, this.modelYear.toString());

                const filteredResults = (this.canDoCampaignLookup)
                    ? recallResponse.results.filter(r => this.nhtsaCampaigns.some(c => r.isCampaignMatch(c)))
                    : recallResponse.results;

                this.recallResults = filteredResults;
            },
            async fetchAndFilterAllCampaigns(): Promise<void> {
                if (!this.canDoCampaignLookup) return;

                this.recallResults = [];
                this.nhtsaCampaigns.forEach(async (c) => {
                    const recallResponse = await NHTSARecallResponse.lookupCampaign(c);

                    const filteredResults = (this.canDoVehicleLookup)
                        ? recallResponse.results.filter(r => r.isVehicleMatch(this.make, this.model, this.modelYear.toString()))
                        : recallResponse.results;

                    this.recallResults.push(...filteredResults);
                });
            },
            openNHTSALink(): void {
                if (!this.nhtsaVinLookupUrl) return;
                window.open(this.nhtsaVinLookupUrl, '_blank');
            }
        }
    })
</script>
<style>
    .panel-vehicle-recall .recall-count {
        color: var(--error-color);
    }

    .panel-vehicle-recall .panel-header-button-array .vin-lookup-button {
        gap: 5px;
    }

    .panel-vehicle-recall .IsBusySectionComponent-container {
        height: unset;
    }

    .panel-vehicle-recall .historical-warning {
        width: fit-content;
        margin: 1rem auto;
        padding: 5px 10px;
        border-radius: 10px;
        background-color: var(--print-color);
        color: var(--background-color);
    }

    .panel-vehicle-recall .recall-accordion-group .AccordionSection {
        margin: 1rem 0;
    }

        .panel-vehicle-recall .recall-accordion-group .AccordionSection:first-child {
            margin-top: 0;
        }

        .panel-vehicle-recall .recall-accordion-group .AccordionSection:last-child {
            margin-bottom: 0;
        }

        .panel-vehicle-recall .recall-accordion-group .AccordionSection .actions-container {
            height: unset;
        }

    .panel-vehicle-recall h1 {
        margin: 0;
    }

    .panel-vehicle-recall section {
        background-color: var(--card-background-color);
        border-radius: var(--default-border-radius);
        border: 1px solid var(--border-color);
        padding: 1rem;
    }

    .panel-vehicle-recall .recall-header {
        margin-left: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }

        .panel-vehicle-recall .recall-header p {
            margin: 0;
        }

        .panel-vehicle-recall .recall-header b {
            color: var(--main-color);
        }

    .panel-vehicle-recall .affected-components {
        width: 100%;
        padding: 10px 0;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        border-bottom: 1px solid var(--border-color);
    }

    .panel-vehicle-recall .alert-text {
        width: fit-content;
        padding: 5px 10px;
        margin: 0 auto;
        background-color: var(--error-color);
        color: var(--background-color);
        border-radius: 10px;
    }
</style>