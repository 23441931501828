<template>
    <div :style="$grid('1')">
        <div :style="$grid('2-.5', '5px 10px')" v-if="deal.isFinance()">
            <div class="panel-lender-row">
                <span> Max Days to 1st Payment </span>
            </div>
            <InputNumber v-model:saturn="deal.loanTerms.maxDaysToFirstPayment" placeholder="Days" :precision="0"
                :isPercent="false"
            :invalid="util.isNull(validation) ? false : validation.loanTerms.maxDaysToFirstPayment.$invalid"
                v-if="deal.isFinance()"></InputNumber>
        </div>
        <div>

            <div class="thick-border-top" :style="$grid('1')" v-if="!disableInputs">
                <fieldset :disabled="!disableInputs">
                    <AddressComponent :data="deal.lender" v-model:saturn="deal.lender"
                        :objectStructure="{ 'address': 'lenderAddress', 'addressExtra': 'lenderAddressExtra', 'zip': 'lenderZip', 'city': 'lenderCity', 'state': 'lenderState', 'usingAddressExtra': 'lenderAddressExtra' }"
                        :panelView="false">
                    </AddressComponent>
                </fieldset>
            </div>
            <div class="thick-border-top" :style="$grid('1')" v-if="disableInputs && currentLenderDivision">
                <InputRichDropdown v-model:saturn="currentLenderDivision"
                    :list="lenderByDealType ? lenderByDealType.divisions : []"
                    :valueMap="valuePredicate"
                    :display="getDivisionTypeDisplay"
                    label="Choose Type to Display" style="width: 45%; margin-top: 10px;" class="ignore-deal-lock" />
                <fieldset v-if="currentLenderDivision" :disabled="disableInputs">
                    <InputTextbox label="Name" style="margin-bottom: 10px;" v-model:saturn="currentLenderDivision.name"
                        :disabled="disableInputs">
                    </InputTextbox>
                    <AddressComponent :data="currentLenderDivision" v-model:saturn="deal.lender"
                        :objectStructure="{ 'address': 'address', 'addressExtra': 'addressExtra', 'zip': 'zip', 'city': 'city', 'state': 'state', 'usingAddressExtra': 'addressExtra' }"
                        :panelView="false">
                    </AddressComponent>
                </fieldset>
            </div>
            <div class="thick-border-top" v-if="disableInputs && currentLenderContact" :style="$grid('1')">
                <InputRichDropdown v-model:saturn="currentLenderContact"
                    :list="lenderByDealType ? lenderByDealType.lenderContacts : []"
                    :valueMap="valuePredicate"
                    :display="getContactTypeDisplay"
                    label="Choose Contact to Display" style="width: 45%; margin-top: 10px;" />
                <fieldset v-if="currentLenderContact" :disabled="disableInputs" :style="$grid('4-1')">
                    <InputPhone :label="currentLenderContact.name" v-model:saturn="currentLenderContact.phoneNumber"
                        :readonly="true" type="tel" placeholder="Phone Number" />
                    <InputNumber type="tel" v-model:saturn="currentLenderContact.phoneNumberExt" label="Ext" />
                    <InputPhone label="Fax Number" v-model:saturn="currentLenderContact.faxNumber" :readonly="true"
                        type="tel" placeholder="Fax Number" />
                    <InputNumber type="tel" v-model:saturn="currentLenderContact.faxNumberExt" label="Fax Ext" />
                    <InputTextbox v-model:saturn="currentLenderContact.emailAddress" label="Email"
                        placeholder="Email Address" :disabled="true" />
                </fieldset>
            </div>
            <div v-if="lenderByDealType" class="thick-border-top" :style="$grid('1-1-1')">
                <InputTextbox label="DMV Account #" :disabled="disableInputs"
                    v-model:saturn="lenderByDealType.dmvAccountId" />
                <InputTextbox label="Electronic Title #" :disabled="disableInputs"
                    v-model:saturn="lenderByDealType.electronicTitleNum" />
                <InputTextbox label="Tax ID #" :disabled="disableInputs" v-model:saturn="lenderByDealType.taxId" />
            </div>
            <div v-if="(lenderByDealType) && deal.dealType == 'Lease'">
                <div v-for="(sc, index) in lease.mileageSurchargePre" :key="index" :style="$grid('1-1-1')">
                    <InputTextbox :disabled="disableInputs" label="Odometer Range Start"
                        v-model:saturn="sc.odometerRangeStart">
                    </InputTextbox>
                    <InputTextbox :disabled="disableInputs" label="Odometer Range End"
                        v-model:saturn="sc.odometerRangeEnd">
                    </InputTextbox>
                    <InputCurrency v-model:saturn="sc.mileRate" label="Rate Per Mile (USD)" :disabled="disableInputs"
                        :precision="2" iconSide="left" :negative="false" />
                </div>
                <fieldset :disabled="disableInputs" :style="$grid('1-1-1')">
                    <InputCurrency v-model:saturn="lease.feeTypeAcquisistion" label="Acquisition Fee"
                        :precision="2" iconSide="left" :negative="false" />
                    <InputCurrency v-model:saturn="lease.mileageSurchargePost" placeHolder="Rate Per Mile (USD)"
                        label="Surcharge Post" :disabled="false" :precision="2" iconSide="left" :negative="false" />
                    <InputCurrency v-model:saturn="lease.mileageSurchargeExcess" placeHolder="Rate Per Mile (USD)"
                        label="Surcharge Excess" :disabled="false" :precision="2" iconSide="left" :negative="false" />
                </fieldset>
            </div>
            <div v-if="lenderByDealType && lease.reserveProfitCalcGuids" :style="$grid('1-1')">
                <InputTextbox v-if="reserveProfFlat" label="Flat" v-model:saturn="reserveProfFlat.description"
                    :disabled="true" />
                <InputTextbox v-if="reserveProfSplit" label="Split" v-model:saturn="reserveProfSplit.description"
                    :disabled="true" />
            </div>
        </div>
    </div>
</template>
<script lang="ts">

import { defineComponent, PropType } from 'vue'
import AddressComponent from '@/components/AddressComponent.vue';
import ENUMS from '@core/classes/Enums';
import FIMenu from '@core/classes/FIMenu';
import InputCurrency from '@core/components/InputCurrency.vue';
import InputNumber from '@core/components/InputNumber.vue';
import InputPhone from '@core/components/InputPhone.vue';
import InputRichDropdown from '@core/components/InputRichDropdown.vue'
import InputTextbox from '@core/components/InputTextbox.vue';
import IReserveProfitCalc from '@core/classes/IReserveProfitCalc';
import LenderContact from '@core/classes/LenderContact';
import LenderDivision from '@core/classes/LenderDivision';
import LenderDraft from '@core/classes/LenderDraft';
import LenderLease from '@core/classes/LenderLease';
import LenderRetail from '@core/classes/LenderRetail';
import util from '@core/services/util';
import VersionsLender from '@core/classes/LenderVersionClass';

export default defineComponent({
    name: 'LenderInformation',
    components: {InputTextbox, InputNumber, InputPhone, InputCurrency, AddressComponent, InputRichDropdown},
    props: {
        deal: Object as PropType<FIMenu>,
        validation: Object as PropType<any>,
        selectedLender: Object as PropType<VersionsLender>,
        reserveProfitCalcs: Array as PropType<IReserveProfitCalc[]>,
    },
    data() {
        return {
            currentLenderDivision: null as any,
            currentLenderContact: null as any,
            lenderByDealType: null as LenderLease|LenderRetail|LenderDraft|null,
            retail: null as LenderRetail | null,
            lease: null as LenderLease | null,
            draft: null as LenderDraft | null,
        }
    },
    created() {
        this.$watch(() => this.currentLenderDivision, () => {
            this.updateLenderContact()
        }, { deep: true })

        this.$watch(() => this.selectedLender, (newLender) => {
            this.handleLenderChange(newLender)
        }, { deep: true })

        this.init()

    },
    computed: {
        util(): any {
            return util;
        },
        disableInputs() {
            return true;
        },
        reserveProfFlat(): any {
            if (this.lenderByDealType instanceof LenderLease)
                return this.reserveProfitCalcs.find(rpc => rpc.id == this.lenderByDealType.reserveProfitCalcGuids.flat)
            else
                return null
        },
        reserveProfSplit(): any {
            return this.reserveProfitCalcs.find(rpc => rpc.id == this.lenderByDealType.reserveProfitCalcGuids.split)
        },
        ENUMS(): any {
            return ENUMS;
        }
    },
    methods: {
        init() {
            if (this.deal.lender != null) {
                const lender = this.deal.lender;
                this.currentLenderDivision = lender.getDefaultDivision();
                this.currentLenderContact = lender.getDefaultContact();
            }

            this.handleLenderChange(this.selectedLender)
        },
        handleLenderChange(newLender: VersionsLender ) {
            this.lenderByDealType = newLender.getLenderByDealType(this.deal.dealType);

            // const fimenuLender = new FIMenuLender({
            //     divisions: this.lenderByDealType.divisions,
            //     lenderContacts: this.lenderByDealType.lenderContacts,
            //     lenderCode: newLender.lenderCode,
            //     lenderName: newLender.lenderName,
            // });

            // this.deal.lender = fimenuLender;

            this.lease = newLender.versions[0].lease;
            this.retail = newLender.versions[0].retail;
            this.draft = newLender.versions[0].draft;

            this.updateLenderContact()
            this.updateLenderDivision()
        },
        updateLenderContact() {
            this.currentLenderContact = this.lenderByDealType?.lenderContacts?.find((p:LenderContact) => p.type == ENUMS.LenderContactType.CustomerService)
        },
        updateLenderDivision() {
            this.currentLenderDivision = this.lenderByDealType?.divisions.find((d:LenderDivision) => d.type == ENUMS.LenderDivisionType.Lienholder || d.type == ENUMS.LenderDivisionType.Lessor)
        },
        /// Predicates
        valuePredicate(pn: any) {
            return pn; // Returns true if pn is truthy
        },

        // Predicate to get the display name based on the contact type
        getContactTypeDisplay(pn: any) {
            const contactType = ENUMS.LENDER_CONTACT_TYPES.find(lpt => lpt.value === pn.type);
            return contactType ? contactType.display : null; // Returns the display name or null if not found
        },
        // Predicate to get the display name based on the division type
        getDivisionTypeDisplay(dv: any) {
            const divisionType = ENUMS.LENDER_DIVISION_TYPES.find(dt => dt.value === dv.type);
            return divisionType ? divisionType.display : null; // Returns the display name or null if not found
        }
    },
})
</script>
<style scoped>

</style>