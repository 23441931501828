<template>
    <!-- No lender, no applications, and cannot submit -->
    <span v-if="showSelectLenderMessage"> Please select a lender to continue </span>

    <!-- Can submit application, but no applications available -->
    <span v-if="showSubmitApplicationMessage"> Please press send to submit a credit application </span>

    <!-- New message for selecting an application or lender -->
    <span v-else-if="showSelectApplicationOrLenderMessage"> Please select an application or a lender to continue </span>
    
    <!-- Applications available, but none selected -->
    <span v-else-if="showSelectApplicationMessage"> Please select an application to continue </span>
</template>

<script setup lang="ts">
    import VersionsLender from "@core/classes/LenderVersionClass";
    import util from "@core/services/util";
    import { computed } from "vue";

    interface CreditApplication {
        selected: boolean;
    }

    // Props declaration
    const props = defineProps<{
        selectedLender: VersionsLender | null | undefined;
        fimenuLenderCode: string | null | undefined;
        canSubmitCreditApplication: boolean;
        creditApplications: CreditApplication[];
    }>();

    // Computed properties for conditional rendering
    const showSelectLenderMessage = computed(() => {
        return (
            util.isNullOrEmpty(props.fimenuLenderCode) &&
            !props.selectedLender &&
            !props.canSubmitCreditApplication &&
            props.creditApplications.length <= 0 &&
            !props.creditApplications.some(a => a.selected)
        );
    });

    const showSubmitApplicationMessage = computed(() => {
        return (
            props.selectedLender &&
            props.canSubmitCreditApplication &&
            props.creditApplications.length <= 0 &&
            !props.creditApplications.some(a => a.selected)
        );
    });

    const showSelectApplicationMessage = computed(() => {
        return (
            !props.fimenuLenderCode &&
            props.creditApplications.length > 0 &&
            !props.creditApplications.some(a => a.selected)
        );
    });

    // New computed property for selecting an application or lender
    const showSelectApplicationOrLenderMessage = computed(() => {
        return (
            util.isNullOrEmpty(props.fimenuLenderCode) &&
            !props.selectedLender &&
            props.creditApplications.length > 0 &&
            !props.creditApplications.some(a => a.selected)
        );
    });
</script>
