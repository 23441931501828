/**
 * Translates a canvas signature down to a baseline if the signature is above it.
 *
 * @param canvas The HTML canvas with the drawn signature.
 * @param threshold A percentage of the canvas height that determines where the baseline is.
 * @returns The final canvas with adjusted signature.
 */
export function adjustSignatureToBaseline(canvas: HTMLCanvasElement, threshold: number = 0.78): HTMLCanvasElement {
    const { width, height } = canvas;

    const ctx = canvas.getContext("2d");
    const imageData = ctx.getImageData(0, 0, width, height);

    // Find the maximum Y coordinate of pixels in signature
    let yMax = 0;
    for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
            // ImageData.data array is a flat list of RGBA values, so we need to calculate the true index
            const index = (y * width + x) * 4;
            // Index represents location of red channel, we need to add 3 to get to alpha channel
            const opacity = imageData.data[index + 3];

            if (opacity > 0 && y > yMax) {
                yMax = y;
            }
        }
    }

	// Check if maximum Y coordinate is above the baseline
    const baseline = height * threshold;
    if (yMax < baseline) {
		// Create an empty canvas with the same dimensions as the provided one.
        const finalCanvas = document.createElement("canvas");
        finalCanvas.width = width;
        finalCanvas.height = height;

		// Draw the original canvas onto the final canvas, offsetting it vertically by baseline - yMax
        const finalCtx = finalCanvas.getContext("2d");
        finalCtx.drawImage(canvas, 0, baseline - yMax, width, height);

        return finalCanvas;
    }

    return canvas;
}
