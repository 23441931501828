<template>
    <div :class="getModalContainerClass">
        <div class="modal-header">
            User Settings
        </div>
        <div v-if="!changePassword" class="modal-body">
            <div :style="$grid('1')">
                <Panel :title="name" :action="[{text: 'Change Password', onClick:()=>{changePassword = true}}]">
                    <div :style="$grid('3-2')">
                        <InputTextbox label="Email"
                                      type="email"
                                      v-model:saturn="email"
                                      placeholder="Email"
                                      icon="fas fa-at"
                                      :disabled="true"
                                      :class="{'grid-2-column': isLoginSameAsEmail}">
                        </InputTextbox>

                        <InputTextbox v-if="!isLoginSameAsEmail"
                                      label="Login"
                                      type="text"
                                      v-model:saturn="login"
                                      placeholder="Login"
                                      :disabled="true">
                        </InputTextbox>

                        <InputTextbox label="User Code"
                                      type="text"
                                      v-model:saturn="userCode"
                                      placeholder="User Code"
                                      :disabled="true">
                        </InputTextbox>
                        <InputPhone label="Cell Phone"
                                    type="tel"
                                    v-model:saturn="phone"
                                    placeholder="Cell Phone"
                                    maxlength="10"
                                    minlength="10">
                        </InputPhone>
                    </div>
                </Panel>

                <Panel title="Signature" :action="[{text: 'Re-Sign', onClick: () =>{modal.passedData.userInfo.updateSignature = true}}]">
                    <div :style="$grid('2.18-.9', '5px 20px')" class="e-sign-container">
                        <div class="signature">
                            <div class="caption">Signature</div>
                            <div class="reference-line">
                                <i class="fas fa-signature"></i>
                                <div class="line"></div>
                            </div>
                            <img id="employeeSignature" :src="modal.passedData.userInfo.employeeSignature" />
                        </div>
                        <div class="initials">
                            <div class="caption">Initials</div>
                            <div class="reference-line">
                                <i class="fas fa-signature"></i>
                                <div class="line"></div>
                            </div>
                            <img id="employeeInitials" :src="modal.passedData.userInfo.employeeInitials" />
                        </div>
                    </div>
                </Panel>
                <Panel title="Theme">
                    <InputRichDropdown @change="changeTheme()" label="Theme"
                                   v-model:saturn="theme"
                                   :list="themes"
                                   :valueMap="(type) => type.value"
                                   :display="(type) => type.text"
                                   :overflow="true">
                    </InputRichDropdown>
                </Panel>
                <Panel title="Partners Credentials" :style="$grid('1')">
                   <DDIUserSetCredentials
                    :ddiCredentials="ddiCredentials"
                    :submitBusy="submitBusy"
                    :modal="modal"
                    :employeeEmail="modal.passedData.userInfo.employeeLogin"
                    ref="DDIComponent"

                   />
                </Panel>
            </div>
        </div>
        <div v-else :class="getModalBodyClass">
            <div class="no-margin-grid" :style="$grid('1')">
                <InputTextbox label="Current Password"
                            type="password"
                            :invalid="v$.currPass.$invalid"
                            v-model:saturn="currPass"
                            placeholder="Current Password">
                    <template #validation>
                        <div v-if="v$.currPass.required.$invalid">Current Password is required</div>
                    </template>
                </InputTextbox>
                <InputTextbox label="New Password"
                            type="password"
                            :invalid="isChangePasswordDisabled"
                            v-model:saturn="newPass"
                            placeholder="New Password">
                    <template #validation>
                        <div v-if="v$.newPass.required.$invalid">New Password is required</div>
                        <div v-else-if="v$.confirmPass.sameAs.$invalid">Confirm Password should be the same as New Password</div>
                        <div v-else-if="isChangePasswordDisabled">Must meet password requirements below</div>
                    </template>
                </InputTextbox>
                <InputTextbox label="Repeat Password"
                            type="password"
                            :invalid="isChangePasswordDisabled"
                            v-model:saturn="confirmPass"
                            placeholder="Repeat new Password">
                    <template #validation>
                        <div v-if="v$.newPass.required.$invalid">New Password is required</div>
                        <div v-else-if="v$.confirmPass.sameAs.$invalid">Confirm Password should be the same as New Password</div>
                        <div v-else-if="isChangePasswordDisabled">Must meet password requirements below</div>
                    </template>
                </InputTextbox>
                <div class="requirements">
					<p :class="v$.newPass.meetsMinChars ? 'success' : 'failure'" style="font-weight: 600">
						Must contain:
					</p>
					<p>
						<i :class="v$.newPass.meetsMinChars ? 'fas fa-check success' : 'fas fa-times failure'"></i>
                        at least 8 characters
					</p>
					<p :class="v$.newPass.meetsThree ? 'success' : 'failure'" style="font-weight: 600">
						Must meet three of the following:
					</p>
					<p>
						<i :class="v$.newPass.hasLowerCase ? 'fas fa-check success' : 'fas fa-times failure'"></i>
                        at least one lowercase letter
					</p>
					<p>
						<i :class="v$.newPass.hasUpperCase ? 'fas fa-check success' : 'fas fa-times failure'"></i>
                        at least one uppercase letter
					</p>
					<p>
						<i :class="v$.newPass.hasNumbers ? 'fas fa-check success' : 'fas fa-times failure'"></i>
                        at least one number
					</p>
					<p>
						<i :class="v$.newPass.hasNonalphas ? 'fas fa-check success' : 'fas fa-times failure'"></i>
                        at least one special character
					</p>
                </div>
            </div>
        </div>
        <div v-if="!changePassword" class="modal-footer">
            <button class="button-unwind" @click="cancel()">Cancel</button>
            <button class="button-save" @click="close()" :disabled="disabledSave"><span v-if="!submitBusy">Save</span><i v-if="submitBusy" class="fas fa-cog fa-spin"></i></button>
        </div>
        <div v-else class="modal-footer">
            <button class="button-unwind" @click="changePassword = false">Cancel</button>
            <button class="button-save" @click="saveChangePassword" :disabled="isChangePasswordDisabled"><span v-if="!submitBusy">Save</span><i v-if="submitBusy" class="fas fa-cog fa-spin"></i></button>
        </div>
    </div>
</template>
<script>
    import $modal from '@core/services/modal'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import Panel from '@core/components/Panel.vue'
    import InputPhone from '@core/components/InputPhone.vue'
    import util from '@core/services/util'
    import api from '@core/services/api'
    import settings from 'settings'
    import { required, sameAs } from '@vuelidate/validators'
    import {UserProvideMappingType} from "@core/classes/SharedEnums";
    import UserProviderMappings from "@core/classes/UserProviderMappings";
    import DDIUserSetCredentials from '@/components/DDIUserSetCredentials.vue'
    import { useVuelidate } from '@vuelidate/core'

    export default {
        name: "modalUserSettings",
        props: ['modal'],
        setup(){
            return { v$: useVuelidate({$scope: false}) }
        },
        data() {
            return {
                submitBusy: false,
                stores: null,
                email: null,
                phone: null,
                userCode: null,
                name: null,
                login: null,
                currentSignature: null,
                currentInitials: null,
                currentTheme: null,
                changePassword: false,
                currPass: null,
                newPass: null,
                confirmPass: null,
                theme: "light-theme",
                themes: [
                    { text: "Light (Default)", value: "light-theme" },
                    { text: "Dark", value: "dark-theme" },
                    { text: "Saturn", value: "saturn-theme" },
                    { text: "Lucky", value: "lucky-theme" },
                    { text: "Solarized-Light", value: "solarized-light" },
                ],
                ddiCredentials: {
                    username: "",
                    password: ""
                }
            }
        },
        computed: {
            util() {
                return util
            },
            isLoginSameAsEmail() {
                return this.modal.passedData.userInfo.employeeLogin == this.modal.passedData.userInfo.employeeEmail;
            },
            disabledSave() {
                const DDIComponentRef = this.$refs?.DDIComponent;

                if (this.submitBusy) return true

                // If DDI component is loading.
                if(DDIComponentRef && (DDIComponentRef.busyValidatingDDICredentials || DDIComponentRef.encryptingPassword)){
                    return true;
                }

                return this.phone == this.modal.passedData.userInfo.employeePhoneNumber &&
                    this.currentSignature == this.modal.passedData.userInfo.employeeSignature &&
                    this.currentInitials == this.modal.passedData.userInfo.employeeInitials &&
                    this.theme == this.modal.passedData.userInfo.employeeTheme &&
                    this.ddiCredentials.username == this.modal.passedData.userInfo.providerMappings?.find(x => x.type === UserProvideMappingType.DDIUsername)?.value &&
                    this.ddiCredentials.password == this.modal.passedData.userInfo.providerMappings?.find(x => x.type === UserProvideMappingType.DDIPassword)?.value

            },
            isChangePasswordDisabled(){
                return !this.v$.newPass.meetsThree || !this.v$.newPass.meetsMinChars || this.v$.confirmPass.$invalid || this.v$.currPass.$invalid;
            },
            getModalBodyClass(){
                let className = "modal-body";

                if(this.changePassword)
                    className += " test";

                return className;
            },
            getModalContainerClass(){
                let className = "modal-user-settings modal-container modal-medium cobrowsing-censor";

                if(this.changePassword)
                    className += " modal-change-password";

                return className;
            },
        },
        created() {
            this.loadData()
        },
        validations() {
            const validation = {
                currPass: { required },
                newPass: {
                    required,
                    hasUpperCase: ()=>{
                        return /[A-Z]/.test(this.newPass);
                    },
                    hasLowerCase: ()=>{
                        return /[a-z]/.test(this.newPass ?? '');
                    },
                    hasNumbers: ()=>{
                        return /\d/.test(this.newPass);
                    },
                    hasNonalphas: ()=>{
                        return /\W/.test(this.newPass);
                    },
                    meetsThree: ()=>{
                        return /[A-Z]/.test(this.newPass) + /[a-z]/.test(this.newPass) + /\d/.test(this.newPass) + /\W/.test(this.newPass) >= 3;
                    },
                    meetsMinChars: ()=>{
                        return this.newPass?.length >= 8;
                    },
                },
                confirmPass: { required,sameAs: sameAs(function () { return this.newPass }) },
            }
            return validation
        },
        methods: {
            close() {
                this.submitBusy = true
                this.modal.passedData.userInfo.employeePhoneNumber = this.phone
                this.modal.passedData.userInfo.employeeTheme = this.theme

                this.setDDICredentials()

                api.users.setUser(this.modal.passedData.userInfo, []).then(response => {
                    util.toastr('success', 'User Settings Update', 'Settings Changed Successfully')
                    localStorage.setItem('Saturn-Theme', this.theme)
                    $modal.close();
                }).catch((err) => {
                    util.toastr('error', 'User Settings Update', err)
                }).finally(() => {
                    this.submitBusy = false
                })


            },
            cancel() {
                this.modal.passedData.userInfo.employeeSignature = this.currentSignature
                this.modal.passedData.userInfo.employeeInitials = this.currentInitials
                this.theme = this.currentTheme;
                this.changeTheme();
                $modal.cancel();
            },
            loadData() {
                const userInfo = this.modal.passedData.userInfo;

                this.name = userInfo.employeeName
                this.login = userInfo.employeeLogin
                this.email = userInfo.employeeEmail
                this.userCode = userInfo.employeeCode
                this.stores = settings.userStores
                this.phone = userInfo.employeePhoneNumber
                this.currentSignature = userInfo.employeeSignature
                this.currentInitials = userInfo.employeeInitials
                this.theme = userInfo.employeeTheme ?? "light-theme"
                this.currentTheme = this.theme

                this.ddiCredentials.username = userInfo.providerMappings?.find(x => x.type === UserProvideMappingType.DDIUsername)?.value;
                this.ddiCredentials.password = userInfo.providerMappings?.find(x => x.type === UserProvideMappingType.DDIPassword)?.value;
            },
            async saveChangePassword(){
                this.submitBusy = true;
                const response = await api.users.updateUserPassword(this.email, this.currPass, this.newPass);
                if(response.data == "Incorrect Credentials"){
                    util.toastr('error', 'Error!', 'Current Password is incorrect')
                }
                else{
                    this.changePassword = false
                    util.toastr('success', 'Success!', 'Successfully changed Password')
                }
                this.submitBusy = false;
            },
            changeTheme() {
                document.body.setAttribute("class", this.theme);

                const colorScheme = window.getComputedStyle(document.body)?.getPropertyValue('color-scheme');
                document.body.classList.add((colorScheme === 'dark') ? 'dark-palette' : 'light-palette');
            },
            setDDICredentials() {
                const userInfo = this.modal.passedData.userInfo;

                const hasUsername = !util.isNullOrEmpty(this.ddiCredentials.username);
                const hasPassword = !util.isNullOrEmpty(this.ddiCredentials.password);

                // Filter out username mapping if username is missing
                if (!hasUsername) {
                    userInfo.providerMappings = userInfo.providerMappings.filter(x => x.type !== UserProvideMappingType.DDIUsername);
                }

                // Update or add username mapping if username is present
                if (hasUsername) {
                    const usernameIndex = userInfo.providerMappings.findIndex(x => x.type === UserProvideMappingType.DDIUsername);
                    if (usernameIndex >= 0) {
                        // Update existing username mapping
                        userInfo.providerMappings[usernameIndex].value = this.ddiCredentials.username;
                        userInfo.providerMappings[usernameIndex].dateUpdated = new Date();
                    } else {
                        // Add new username mapping if not found
                        const usernameMapping = new UserProviderMappings({
                            value: this.ddiCredentials.username,
                            type: UserProvideMappingType.DDIUsername
                        });
                        userInfo.providerMappings.push(usernameMapping);
                    }
                }

                // Filter out password mapping if password is missing
                if (!hasPassword) {
                    userInfo.providerMappings = userInfo.providerMappings.filter(x => x.type !== UserProvideMappingType.DDIPassword);
                }

                // Update or add password mapping if password is present
                if (hasPassword) {
                    const passwordIndex = userInfo.providerMappings.findIndex(x => x.type === UserProvideMappingType.DDIPassword);
                    if (passwordIndex >= 0) {
                        // Update existing password mapping
                        userInfo.providerMappings[passwordIndex].value = this.ddiCredentials.password;
                        userInfo.providerMappings[passwordIndex].dateUpdated = new Date();
                    } else {
                        // Add new password mapping if not found
                        const passwordMapping = new UserProviderMappings({
                            value: this.ddiCredentials.password,
                            type: UserProvideMappingType.DDIPassword
                        });
                        userInfo.providerMappings.push(passwordMapping);
                    }
                }
            },
        },
        components: {
            InputTextbox,
            InputRichDropdown,
            InputPhone,
            DDIUserSetCredentials,
            Panel
        }
    };
</script>
<style>
  .modal-user-settings.modal-container.modal-medium.modal-change-password .modal-body{
        height: 330px;
        min-height: 330px;
    }
    .modal-user-settings.modal-container.modal-medium .modal-body{
        min-height: 545px;
        max-height: 600px;
        transition: var(--transition);
    }
    .modal-user-settings.modal-container.modal-medium.modal-change-password {
        width: 325px;
    }
    .modal-user-settings.modal-container.modal-medium{
        transition: var(--transition);
    }
    .requirements .success {
        color: var(--success-color);
    }

    .requirements .failure {
        color: var(--error-color);
    }
    .requirements p{
        margin-block-start: 2px;
        margin-block-end: 2px;
    }
    .requirements i{
        width: 30px;
        text-align: center ;
    }
    .modal-user-settings .panel-header-button-array button{
        white-space: nowrap;
    }
</style>
