<template>
    <div class="section-container ownership">
        <div class="section-title">{{ localization.termOfCoverage[fimenu.language] }}</div>
        <div class="section-options">
            <div :class="{'options-spaces': true, 'center': true }" v-for="(option, i) in filteredCoverageTerms" :key="i">
                <div :class="{'section-button': true, 'selected': optionSelected && option.term == optionSelected.term}" @click="selectOption(option)">
                    <div class="button-value">{{ option.term/12 }}</div>
                    <div class="button-desc">{{ localization.years[fimenu.language] }}</div>
                </div>

            </div>

        </div>
        <div :class="{ 'continue-btn-overlay': true, 'active': optionSelected && optionSelected.term }">
            <button :class="{'continue-button': true, 'active': optionSelected && optionSelected.term}" @click="saveAndContinue">Continue</button>
        </div>
    </div>

</template>

<script>
    export default {
        name: "PaymentSheetPreferredCoverage",
        props: ['stopAudioHandler', 'openSectionHandler', 'frozen', 'fimenu', 'saveFunction','localization'],
        data() {
            return {
                optionSelected: {
                    term: null
                }
            }
        },
        computed: {
            filteredCoverageTerms() {
                return this.frozen.coverageTerms.filter(c => c.term > 0);
            },
            preferredCoverage() {
                return this.frozen.coverageTerms.find(c => c.preferred);
            },

        },
        created() {
            this.selectOption(this.frozen.coverageTerms.find(c => c.preferred))

            this.$watch(() => this.frozen.coverageTerms, function () {
                this.selectOption(this.frozen.coverageTerms.find(c => c.preferred))
            });
        },
        methods: {
            selectOption(option) {
                this.optionSelected = option;
            },
            saveAndContinue() {
                this.saveFunction(this.optionSelected.term)
                this.openSectionHandler()
            },

        },
        components: {

        }
    };
</script>
<style>
   /* .section-container {
        position: relative;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


        .section-container .section-title {
            position: absolute;
            top: 50px;
            color: var(--main-color);
            font-size: 46px;
        }

        .section-container.ownership .section-options {
            width: 1260px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            row-gap: 100px;
        }

            .section-container.ownership .section-options .options-spaces {
                width: 33%;
                display: flex;
            }

                .section-container.ownership .section-options .options-spaces.center {
                    justify-content: center
                }

                .section-container.ownership .section-options .options-spaces.left {
                    justify-content: flex-start
                }

                .section-container.ownership .section-options .options-spaces.right {
                    justify-content: flex-end
                }

                .section-container.ownership .section-options .options-spaces .section-button {
                    color: var(--main-color);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 285px;
                    border: 1px solid var(--main-color);
                    height: 156px;
                    justify-content: center;
                    background: var(--section-container-button-background-color);
                    cursor: pointer;
                }

                    .section-container.ownership .section-options .options-spaces .section-button.selected {
                        background: var(--main-color);
                        color: var(--section-container-button-selected-color);
                    }

                    .section-container.ownership .section-options .options-spaces .section-button .button-value {
                        font-size: 100px;
                        line-height: 0.9;
                    }

                    .section-container.ownership .section-options .options-spaces .section-button .button-desc {
                        font-size: 30px;
                    }

        .section-container .continue-button {
            position: absolute;
            bottom: 40px;
            right: 40px;
            height: 92px;
            width: 92px;
            background: var(--success-color);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 56px;
            color: var(--continue-button-color);
            opacity: 0;
            cursor: pointer;
            pointer-events: none
        }

            .section-container .continue-button.active {
                opacity: 1;
                transition: opacity .5s ease-in-out;
                pointer-events: all;
                z-index: 99999;
            }*/
</style>