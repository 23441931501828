import { createRouter, createWebHistory } from "vue-router";
import ApplicationsSettingsLoader from "@core/classes/Applications/ApplicationsSettingsLoader";
import auth from "@core/services/auth";
import Customer from "@/views/Customer.vue";
import Customers from "@/views/Customers.vue";
import Dashboard from "@/views/Dashboard.vue";
import FIMenuCustomerScreen from "@/components/fimenu/FIMenuCustomerScreen.vue";
import { USER_ACCESSES } from "@core/classes/Enums";

const UA = USER_ACCESSES;

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            name: "dashboard",
            component: Dashboard,
        },
        {
            path: "",
            name: "default",
            component: Dashboard,
        },
        {
            path: "/fimenudeals",
            name: "fimenudeals",
            component: () => import(`@/views/FIMenuDeals.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("SalesPerson") || auth.hasAccessToPermission("TitlingClerk")) next();
                else next("/");
            },
        },
        {
            path: "/cashbash",
            name: "cashbash",
            component: () => import(`@/views/CashBash/CashbashAdmin.vue`),
        },
        {
            path: "/cashbash/player",
            name: "cashbashplayer",
            component: () => import(`@/views/CashBash/CashbashPlayer.vue`),
        },
        {
            path: "/customers",
            name: "customers",
            component: Customers,
            beforeEnter: (to, from, next) => {
                if (!(auth.hasAccessToPermission("SalesPerson") || auth.hasAccessToPermission("TitlingClerk"))) next("/");
                // Loads customer applications questions into the class. Optimization.
                ApplicationsSettingsLoader.init().then(() => {
                    next();
                });
            },
        },
        {
            path: "/customer",
            name: "customer",
            component: Customer,
            beforeEnter: (to, from, next) => {
                if (!(auth.hasAccessToPermission("SalesPerson") || auth.hasAccessToPermission("TitlingClerk"))) next("/");
                // Loads customer applications questions into the class. Optimization.
                ApplicationsSettingsLoader.init().then(() => {
                    next();
                });
            },
        },
        {
            path: "/reports",
            name: "reports",
            component: () => import(`@/views/Reports.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("SalesPerson")) next();
                else next("/");
            },
        },
        {
            path: "/customer-screen",
            name: "customer-screen",
            component: FIMenuCustomerScreen,
        },
        {
            path: "/admin",
            name: "admin",
            component: () => import(`@/views/admin/Admin.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("UserMgt")) next();
                else next("/");
            },
        },
        {
            path: "/utilities/",
            name: "utilities",
            component: () => import(`@/views/utilities/Utilities.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("Admin")) next();
                else next("/");
            },
        },
        // {
        //     path: "/utilities/active-deals",
        //     name: "utilities-active-deals",
        //     component: () => import(`@/views/utilities/sections/ActiveDeals.vue`),
        //     beforeEnter: (to, from, next) => {
        //         if (auth.hasAccessToPermission("SalesManager")) next();
        //         else next("/");
        //     },
        // },
        // {
        //     path: "/utilities/copy-from-prod",
        //     name: "utilities-copy-from-prod",
        //     component: () => import(`@/views/utilities/sections/CopyFromProd.vue`),
        //     beforeEnter: (to, from, next) => {
        //         if (auth.hasAccessToPermission("SalesManager")) next();
        //         else next("/");
        //     },
        // },
        // {
        //     path: "/utilities/error-logs",
        //     name: "utilities-error-logs",
        //     component: () => import(`@/views/utilities/sections/ErrorLogs.vue`),
        //     beforeEnter: (to, from, next) => {
        //         if (auth.hasAccessToPermission("SalesManager")) next();
        //         else next("/");
        //     },
        // },
        // {
        //     path: "/utilities/custom-variables",
        //     name: "custom-variables",
        //     component: () => import(`@/views/utilities/sections/CustomVariables.vue`),
        //     beforeEnter: (to, from, next) => {
        //         if (auth.hasAccessToPermission("SalesManager")) next();
        //         else next("/");
        //     },
        // },
        // {
        //     path: "/utilities/saturn-expressions",
        //     name: "utilities-saturn-expressions",
        //     component: () => import(`@/views/utilities/sections/SaturnExpressions.vue`),
        //     beforeEnter: (to, from, next) => {
        //         if (auth.hasAccessToPermission("Admin")) next();
        //         else next("/");
        //     },
        // },
        // {
        //     path: "/utilities/manage-base-paperwork",
        //     name: "utilities-manage-base-paperwork",
        //     component: () => import(`@/views/utilities/sections/ManageBasePaperwork.vue`),
        //     beforeEnter: (to, from, next) => {
        //         if (auth.hasAccessToPermission("SalesManager")) next();
        //         else next("/");
        //     },
        // },
        // {
        //     path: "/utilities/code-editor-themes",
        //     name: "utilities-code-editor-themes",
        //     component: () => import(`@/views/utilities/sections/CodeEditorThemeManager.vue`),
        //     beforeEnter: (to, from, next) => {
        //         if (auth.hasAccessToPermission("Admin")) next();
        //         else next("/");
        //     },
        // },
        // {
        //     path: "/utilities/vehicle-records",
        //     name: "utilities-vehicle-records",
        //     component: () => import(`@/views/utilities/sections/VehicleRecords.vue`),
        //     beforeEnter: (to, from, next) => {
        //         if (auth.hasAccessToPermission("Admin")) next();
        //         else next("/");
        //     },
        // },
        // {
        //     path: "/utilities/data-definitions",
        //     name: "utilities-data-definitions-page",
        //     component: () => import(`@/views/utilities/sections/DataDefinitionPage.vue`),
        // },
        // {
        //     path: "/utilities/broadcast-system",
        //     name: "utilities-broadcast-system-page",
        //     component: () => import(`@/views/utilities/sections/BroadcastSystemPage.vue`),
        // },
        // {
        //     path: "/utilities/data-definitions/:id",
        //     name: "utilities-data-definitions-builder",
        //     component: () => import(`@/views/utilities/sections/DataDefinitionBuilder.vue`),
        // },
        // {
        //     path: "/utilities/plaid-lookup",
        //     name: "utilities-plaid-lookup",
        //     component: () => import(`@/views/utilities/sections/PlaidLookup.vue`),
        //     beforeEnter: (to, from, next) => {
        //         if (auth.hasAccessToPermission("Admin")) next();
        //         else next("/");
        //     },
        // },
        {
            path: "/sandbox",
            name: "sandbox",
            component: () => import(`@/sandbox/Sandbox.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("SalesManager")) next();
                else next("/");
            },
        },
        {
            path: "/cancel-coverage",
            name: "cancelCoverage",
            component: () => import(`@/views/cancel-coverage/CancelCoverage.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("SalesManager")) next();
                else next("/");
            },
        },
        {
            path: "/cancel-coverage/:id",
            name: "cancelCoverageDetails",
            component: () => import(`@/views/cancel-coverage/CancelCoverageDetails.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("SalesManager")) next();
                else next("/");
            },
        },
        {
            path: "/service",
            name: "service",
            component: () => import(`@/views/SaturnServiceLookup.vue`),
            beforeEnter: (to, from, next) => {
                if (auth.hasAccessToPermission("ServiceManager")) next();
                else next("/");
            },
        },
    ],
});

// router.afterEach((to, from) => {
//     modalService.closeAll()
// });

export default router;
