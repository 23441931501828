import { StateCode, StateDescription } from "@core/classes/SharedEnums"

type StateDetail = {
    value: StateCode, display: string
}
export default class StateHelper {
    static getStateDescription(code: StateCode): string {
        return StateDescription[code];
    }

    static getStateCode(description: string): StateCode | undefined {
        const entry = Object.entries(StateDescription).find(([code, desc]) => desc === description);
        return entry ? (entry[0] as StateCode) : undefined;
    }

    static getAllStates(): StateDetail[] {
        return Object.entries(StateDescription).map(([code, description]) => ({
            value: code as StateCode,
            display: description
        }));
    }
}