<template>
    <div class="modalTradeInLien-container modal-container modal-large">
        <div class="modal-header">
            Lien
        </div>
        <div class="modal-body">
            <IsBusySectionComponent v-if="isBusyPOQ"></IsBusySectionComponent>
            <div v-else :style="$grid('1')" class="no-margin-grid fade-in-fast">
                <Panel title="Lien Information">
                    <fieldset :style="$grid('1-1')" class="no-margin-grid" >

                        <InputRichDropdown 
                            label="Lender"
                            :valueMap="predicate"
                            :saturn="selectedLender.lenderName"
                            :list="lenderList"
                            :display="predicate"
                            :containerHeight="440"
                            @change="lenderCodeChange"
                            :customHandling="customHandling"
                            :invalid="util.isNull(validation) ? false : validation.lienName.$invalid"
                            :multiSelect="false"
                            search
                                    
                        >
                            <template #validation>
                                <div v-if="!util.isNull(validation) && validation.lienName.required.$invalid">Lender is required</div>
                            </template>
                        </InputRichDropdown>


                        <!-- <InputTextbox label="Lender Name"
                                      :saturn.sync="lien.lienName"
                                      :invalid="util.isNull(validation) ? false : validation.lienName.$invalid"
                                      placeholder="Lender Name">
                            <template #validation>
                                <div v-if="!util.isNull(validation) && validation.lienName.required.$invalid">Lender is required</div>
                            </template>
                        </InputTextbox> -->

                        <InputPhone label="Phone Number"
                                    v-model:saturn="lien.phoneNumber"
                                    :disabled="false"
                                    :invalid="util.isNull(validation) ? false : validation.phoneNumber.$invalid"
                                    placeholder="Lender Phone Number">
                            <template #validation>
                                <div v-if="!util.isNull(validation) && validation.phoneNumber.required.$invalid">Phone Number is required</div>
                            </template>
                        </InputPhone>
                        <fieldset class="grid-2-column" :disabled="disableInputs">

                            <AddressComponent 
                                :data="lien"
                                :validation="validation"
                                :objectStructure="{'address': 'lienAddress', 'addressExtra': 'lienAddressExtra', 'zip': 'lienZip', 'city': 'lienCity', 'state': 'lienState'}"
                                :panelView="true"                   
                            />
                        </fieldset>
                    </fieldset>
                </Panel>
                <Panel title="Lien Details">

                    <div v-if="modal.passedData.isWalkAway"
                         :style="$grid('1-1-1-1')"
                         class="centered">

                        <InputRichDropdown label="Walk Away Payee Type"
                                           :list="walkAwayTypeList"
                                           v-model:saturn="lien.walkAwayPayee"
                                           :display="(payeeType) => payeeType.display"
                                           :valueMap="(payeeType) => payeeType.value"
                                           :invalid="util.isNull(validation) ? false : validation.walkAwayPayee.$invalid"
                                           class="full-width">
                            <template #validation>
                                <div v-if="util.isNull(validation) ? false : validation.walkAwayPayee.required.$invalid">Walk Away Payee Type is required</div>
                            </template>
                        </InputRichDropdown>

                        <InputTextbox label="Account Number"
                                      v-model:saturn="lien.accNumber"
                                      :invalid="util.isNull(validation) ? false : validation.accNumber.$invalid"
                                      placeholder="Acc. Number">
                            <template #validation>
                                <div v-if="util.isNull(validation) ? false : validation.accNumber.required.$invalid">Account Number is required</div>
                            </template>
                        </InputTextbox>

                    </div>

                    <div v-if="modal.passedData.isWalkAway"
                         :style="$grid('1-1-1-1-1')"
                         class="centered">



                        <InputNumber label="Months Remain"
                                     v-model:saturn="lien.monthsRemaining"
                                     :invalid="util.isNull(validation) ? false : validation.monthsRemaining.$invalid"
                                     placeholder="Months Remain"
                                     :precision="0"
                                     @change="calculateLeaseWalkAwayAmount()">
                            <template #validation>
                                <div v-if="util.isNull(validation) ? false : validation.monthsRemaining.required.$invalid">Months Remaining is required</div>
                            </template>
                        </InputNumber>


                        <InputCurrency label="Payment"
                                       v-model:saturn="lien.monthlyPayment"
                                       :invalid="util.isNull(validation) ? false : validation.monthlyPayment.$invalid"
                                       placeholder="Monthly Payment"
                                       :precision="2"
                                       @change="calculateLeaseWalkAwayAmount()">
                            <template #validation>
                                <div v-if="util.isNull(validation) ? false : validation.monthlyPayment.required.$invalid">Monthly Payment is required</div>
                            </template>
                        </InputCurrency>


                        <InputNumber label="Miles Over"
                                     v-model:saturn="lien.milesOver"
                                     :invalid="util.isNull(validation) ? false : validation.milesOver.$invalid"
                                     placeholder="Miles Over"
                                     :precision="0"
                                     @change="calculateLeaseWalkAwayAmount()">
                            <template #validation>
                                <div v-if="util.isNull(validation) ? false : validation.milesOver.required.$invalid">Miles Over is required</div>
                            </template>
                        </InputNumber>


                        <InputCurrency label="Cost Per Mile"
                                       v-model:saturn="lien.costPerMileOver"
                                       :invalid="util.isNull(validation) ? false : validation.costPerMileOver.$invalid"
                                       placeholder="Cost Per Mile Over"
                                       :precision="2"
                                       @change="calculateLeaseWalkAwayAmount()">
                            <template #validation>
                                <div v-if="util.isNull(validation) ? false : validation.costPerMileOver.required.$invalid">Cost Per Mile Over is required</div>
                            </template>
                        </InputCurrency>


                        <InputCurrency label="Disposition Fee"
                                       v-model:saturn="lien.dispositionFee"
                                       :invalid="util.isNull(validation) ? false : validation.dispositionFee.$invalid"
                                       placeholder="Disposition Fee"
                                       :precision="2"
                                       @change="calculateLeaseWalkAwayAmount()">
                            <template #validation>
                                <div v-if="util.isNull(validation) ? false : validation.dispositionFee.required.$invalid">Disposition Fee is required</div>
                            </template>
                        </InputCurrency>

                        </div>
                        <div v-if="modal.passedData.isWalkAway"
                             :style="$grid('1-3-1')"
                             class="centered">

                            <InputCurrency label="Other Charge Amount"
                                           v-model:saturn="lien.otherChargeAmount"
                                           :invalid="util.isNull(validation) ? false : validation.otherChargeAmount.$invalid"
                                           placeholder="Other Charge Amount"
                                           @change="calculateLeaseWalkAwayAmount()"
                                           :precision="2"
                                           :min="0">
                                <template #validation>
                                    <div v-if="util.isNull(validation) ? false : validation.otherChargeAmount.required.$invalid">Other Charge Amount is required.</div>
                                </template>
                            </InputCurrency>

                            <InputTextbox label="Other Charge Description"
                                          v-model:saturn="lien.otherChargeDescription"
                                          :invalid="isMissingOtherChargeDescription"
                                          :disabled="!lien.otherChargeAmount"
                                          class="full-width"
                                          placeholder="What is the other charge?">

                                <template #validation>
                                    <div v-if="isMissingOtherChargeDescription">Other Charge Amount Description is required.</div>
                                </template>
                            </InputTextbox>

                            <InputCurrency label="Walk Away Amount"
                                           v-model:saturn="lien.walkAwayAmount"
                                           :invalid="util.isNull(validation) ? false : validation.walkAwayAmount.$invalid"
                                           placeholder="Walk Away Amount"
                                           :precision="2"
                                           :readonly="true">
                                <template #validation>
                                    <div v-if="util.isNull(validation) ? false : validation.walkAwayAmount.required.$invalid">Walk Away Amount is required</div>
                                </template>
                            </InputCurrency>


                        </div>
                        <!-- not walk-away -->
                        <div v-else :style="$grid('1-1-1-1')" class="no-margin-grid">
                            <InputTextbox label="Account Number"
                                          v-model:saturn="lien.accNumber"
                                          placeholder="Account Number"
                                          :invalid="util.isNull(validation) ? false : validation.accNumber.$invalid">
                                <template #validation>
                                    <div v-if="util.isNull(validation) ? false : validation.accNumber.required.$invalid">Account Number is required</div>
                                </template>
                            </InputTextbox>



                            <InputDate label="Good Thru"
                                       v-model:saturn="lien.lienGoodThrough"
                                       placeholder="Good Thru"
                                       :isDateDisabled="util.isPastDate"
                                       class="tradeIn-lienGoodThrough-shift-left"
                                       :invalid="util.isNull(validation) ? false : validation.lienGoodThrough.$invalid">
                                <template #validation>
                                    <div v-if="util.isNull(validation) ? false : validation.lienGoodThrough.required.$invalid">Lien Good Through is required</div>
                                </template>
                            </InputDate>


                            <InputTextbox label="Payoff Given By"
                                          v-model:saturn="lien.payoffGivenBy"
                                          placeholder="Payoff Given By"
                                          :invalid="util.isNull(validation) ? false : validation.payoffGivenBy.$invalid">
                                <template #validation>
                                    <div v-if="util.isNull(validation) ? false : validation.payoffGivenBy.required.$invalid">Payoff Given By is required</div>
                                </template>
                            </InputTextbox>

                            <div></div>

                            <InputCurrency label="Payoff Amount"
                                           v-model:saturn="lien.amount"
                                           :precision="2"
                                           placeholder="Payoff Amount"
                                           @change="() => calcPayoffAmount()"
                                           :invalid="util.isNull(validation) ? false : validation.amount.$invalid">
                                <template #validation>
                                    <div v-if="!util.isNull(validation) && validation.amount.required.$invalid">Payoff Amount is required</div>
                                </template>
                            </InputCurrency>

                            <InputCurrency label="Per Diem Amount"
                                           v-model:saturn="lien.perDiem"
                                           :precision="2"
                                           placeholder="Per Diem"
                                           :invalid="util.isNull(validation) ? false : validation.perDiem.$invalid"
                                           @change="() => calcPayoffAmount()">
                                <template #validation>
                                    <div v-if="!util.isNull(validation) && validation.perDiem.required.$invalid">Per Diem is required</div>
                                </template>
                            </InputCurrency>

                            <InputNumber label="Per Diem Days"
                                         v-model:saturn="lien.perDiemNumberOfDays"
                                         placeholder="Per Diem Number of Days"
                                         :invalid="(util.isNull(validation) ? false : validation.perDiemNumberOfDays.$invalid)"
                                         @change="() => calcPayoffAmount()">
                                <template #validation>
                                    <div v-if="!util.isNull(validation) && validation.perDiemNumberOfDays.required.$invalid">Per Diem Number of Days is required</div>
                                    <div v-else-if="!util.isNull(validation) && validation.perDiemNumberOfDays.minValue.$invalid">Must be at least 10 days</div>
                                </template>
                            </InputNumber>


                            <InputCurrency label="Base Payoff Amount"
                                           v-model:saturn="lien.basePayoffAmount"
                                           :precision="2"
                                           placeholder="Base Payoff Amount"
                                           :invalid="util.isNull(validation) ? false : validation.basePayoffAmount.$invalid"
                                           @change="() => calcPayoffAmount(true)">
                                <template #validation>
                                    <div v-if="!util.isNull(validation) && validation.basePayoffAmount.required.$invalid">Base Payoff Amount is required.</div>
                                    <div v-if="!util.isNull(validation) && validation.basePayoffAmount.minValue.$invalid">Base Payoff Amount cannot be negative.</div>
                                </template>
                            </InputCurrency>

                        </div>
                </Panel>
                <div :style="$grid('1')" v-if="poqResponse" class="poq-results-wrapper no-margin-grid">
                    <div v-if="poqResponse.payoffQuoteInquiryResults">
                        <div v-for="(item, index) in poqResponse.payoffQuoteInquiryResults.items" :key="index"  style="padding: 10px;">
                            <AccordionSection>
                                <template #accordionHeader>
                                    <div style="display: flex; flex-direction: row; padding-left: 15px;">
                                        <div style="display:flex; flex-direction: column; gap: 0">
                                            <h2 style="margin: 0"> Pay Off Quote </h2>
                                            <span v-if="item.accountNumber" style="font-size: 12px">Account #{{item.accountNumber}}</span>
                                        </div>
                                    </div>
                                </template>

                                <template #accordionBody>
                                    <fieldset :style="$grid('1')">
                                        <div :style="$grid('1')">
                                            <DisplayData :displayColumn="true" label="Account Number" :saturn="item.accountNumber"></DisplayData>
                                        </div>
                                        <div v-if="item.payment" :style="$grid('1-1-1-1')">
                                            <DisplayData :displayColumn="true" v-if="item.payment.nextPaymentAmount" label="Next Payment Amount" :saturn="item.payment.nextPaymentAmount.value" :isCurrency="true"></DisplayData>
                                            <DisplayData :displayColumn="true" label="Next Payment Date" :saturn="formatDate(item.payment.nextPaymentDate)"></DisplayData>
                                            <DisplayData :displayColumn="true" label="Next Payments Remaining" :saturn="item.payment.paymentsRemaining" :precision="2"></DisplayData>
                                            <DisplayData :displayColumn="true" v-if="item.payment.term" label="Payment Term" :saturn="item.payment.term.value" ></DisplayData>
                                            <DisplayData :displayColumn="true" v-if="item.payment.balloonAmount" label="Balloon Amount" :saturn="item.payment.balloonAmount.value" :isCurrency="true" :precision="2" ></DisplayData>
                                            <DisplayData :displayColumn="true" label="Last Date Paid" :saturn="formatDate(item.payment.lastDatePaid)"></DisplayData>
                                        </div>
                                        <div v-if="item.vehicle" :style="$grid('1-1-1-1')">
                                            <DisplayData :displayColumn="true" label="Vehicle VIN" :saturn="item.vehicle.vin"></DisplayData>
                                            <DisplayData :displayColumn="true" label="Vehicle Year" :saturn="item.vehicle.year"></DisplayData>
                                            <DisplayData :displayColumn="true" label="Vehicle Make" :saturn="item.vehicle.make"></DisplayData>
                                            <DisplayData :displayColumn="true" label="Vehicle Model" :saturn="item.vehicle.model"></DisplayData>
                                            <DisplayData v-if="item.vehicle.residualValue" :displayColumn="true" label="Vehicle Residual" :saturn="item.vehicle.residualValue.value" :isCurrency="true" :precision="2"/>
                                        </div>
                                        <div :style="$grid('1-1-1-1')">
                                            <DisplayData v-if="item.quote.netPayoffAmount" :displayColumn="true" label="Net Payoff Amount" :saturn="item.quote.netPayoffAmount.value" :isCurrency="true" :precision="2"></DisplayData>
                                            <DisplayData :displayColumn="true" label="Good Through Date" :saturn="formatDate(item.quote.goodThroughDate)"></DisplayData>
                                            <DisplayData :displayColumn="true" label="Per Diem" :saturn="item.quote.perDiem.value"></DisplayData>
                                            <DisplayData :displayColumn="true"  v-if="item.quote.grossPayoffAmount" label="Gross Payoff Amount" :saturn="item.quote.grossPayoffAmount.value" :isCurrency="true" :precision="2"/>
                                            <DisplayData :displayColumn="true"  v-if="item.quote.securityDepositAmount" label="Security Deposit Amount" :saturn="item.quote.securityDepositAmount.value" :isCurrency="true" :precision="2"/>
                                            <DisplayData :displayColumn="true" label="Quote Description" :saturn="item.quote.quoteDescription"></DisplayData>
                                            <DisplayData :displayColumn="true" label="Quote Details" :saturn="item.quote.quoteDetails"></DisplayData>
                                            <DisplayData :displayColumn="true" label="Contract Start Date" :saturn="formatDate(item.quote.contractStartDate)"></DisplayData>
                                            <DisplayData :displayColumn="true" label="Scheduled End Date" :saturn="formatDate(item.quote.scheduledEndDate)"></DisplayData>
                                        </div>
                                        <div :style="$grid('1')">
                                            <DisplayData :displayColumn="true" label="Payoff Instructions" class="poq-comments" :saturn="item.consumerPayoffInstruction" disabled></DisplayData>
                                            <DisplayData :displayColumn="true" label="Comments" class="poq-comments" :saturn="item.comments.join('\n') ?? null" disabled></DisplayData>
                                        </div>
                                        
                                    </fieldset>
                                </template>
                            </AccordionSection>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="button-delete" @click="cancel()">Cancel</button>
            <button class="button-save"
                    @click="close()"
                    :disabled="!canSave">
                Save
            </button>
        </div>
    </div>
</template>


<script>

    import { WalkAwayPayeeType, enumToList } from "@core/classes/SharedEnums"
    import AccordionSection from '@core/components/AccordionSection.vue'
    import AddressComponent from '@/components/AddressComponent.vue'
    import api from '@core/services/api'
    import DisplayData from '@/components/DisplayData.vue'
    import ENUMS from '@core/classes/Enums'
    import FIMenuVehicleLien from '@core/classes/FIMenuVehicleLien'
    import InputCurrency from '@core/components/InputCurrency.vue'
    import InputDate from '@core/components/InputDate.vue'
    import InputNumber from '@core/components/InputNumber.vue'
    import InputPhone from '@core/components/InputPhone.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import IsBusySectionComponent from '@core/components/IsBusySectionComponent.vue'
    import modal from '@core/services/modal'
    import Panel from '@core/components/Panel.vue'
    import RouteOnePOQResponse from '@core/classes/Providers/RouteOnePOQResponse'
    import settings from 'settings'
    import util from '@core/services/util'
    import VersionsLender from '@core/classes/LenderVersionClass'

    export default {
        name: "modalTradeInLien",
        props: ['modal'],
        data() {
            return {
                isBusyPOQ: false,
                lenderList: [],
                selectedLender: new VersionsLender(),
                poqResponse: null,
                lien: new FIMenuVehicleLien({}),
                isNotListed: true,
                validation: null,
                lenderDealTypeFilter: null,
                lienTypeFilter: null
            }
        },
        computed: {
            util() {
                return util;
            },
            ENUMS() {
                return ENUMS;
            },
            disableInputs() {
                return !this.isNotListed && this.lenderList.some(x =>(x.lenderCode  != null) && (x.lenderCode == this.lien?.lienCode)) 
            },
            walkAwayTypeList() {
                return enumToList(WalkAwayPayeeType);
            },
            isMissingOtherChargeDescription() {
                return this.lien.otherChargeAmount > 0 && (!this.lien.otherChargeDescription || this.lien.otherChargeDescription.length < 3)
            },
            canSave() {
                return !this.isBusyPOQ && !this.isMissingOtherChargeDescription && (util.isNull(this.validation) ? true : !this.validation.$invalid)
            },

            
        },
        async created() {
            await this.loadData();
        },
        methods: {
            formatDate(date){
                if(!date) return date;
                try {
                    return util.toMoment(date).format("YYYY-MM-DD")
                } catch(err) {
                    console.error(err)
                    return date;
                }
            },
            predicate(x) {
                return x.lenderName ?? "";
            },
            async loadData() {
                this.lienTypeFilter = this.modal.passedData.type == 0 ? 'Finance' : 'Lease';
                this.lenderDealTypeFilter = this.lienTypeFilter == 'Finance' ? 'retail' : 'lease';
                this.lenderList = this.$global.Lenders
                    .filter(l => l.lenderCode !== 'UNLISTED' && l.versions[0].getLenderByDealType(this.lienTypeFilter).getDefaultDivision())
                    .map(lender => { // Make all the lender names uppercase.
                        lender.lenderName = lender.lenderName.toUpperCase()
                        return lender
                    });

                this.validation = this.modal.passedData.validation
                this.lien = this.modal.passedData.lien ?? {}
                this.selectedLender = this.lenderList.find(l => l.lenderCode === this.lien.lienCode) ?? new VersionsLender();

                if (this.selectedLender) {
                    await this.getPayoffQuote()
                }
            },
            compareLender(l1, l2) {
                return l1?.lenderCode === l2?.lenderCode
            },
            customHandling(name) {
                return new VersionsLender({ lenderName: name, id: "", lenderCode: "" })
            },
            lenderCodeChange(lenderName, lenderObjResult) {

                const lender = lenderObjResult?.obj;
                this.selectedLender = lender
                this.poqResponse = null;

                if (lender != null && typeof lenderObjResult != "string") {
                    const lenderVersion = lender?.getLenderByDealType(this.lienTypeFilter);
                    var lenderAddress = lenderVersion?.getDefaultDivision();

                    if (lender.lenderCode === "UNLISTED") {
                        this.lien.lienName = ""
                        this.isNotListed = true;
                    }
                    else {
                        this.lien.lienName = lender.lenderName
                        this.isNotListed = false;
                        // If Payoff is available.
                        this.getPayoffQuote()
                    }

                    this.lien.lienCode = lender.lenderCode
                    this.lien.lienAddress = lenderAddress?.address ?? "";
                    this.lien.lienAddressExtra = lenderAddress?.addressExtra ?? "";
                    this.lien.lienCity = lenderAddress?.city ?? "";
                    this.lien.lienState = lenderAddress?.state ?? "";
                    this.lien.lienZip = lenderAddress?.zip ?? "";
                } 
            },
            async getPayoffQuote() {
                try {
                    
                   if (!this.modal?.passedData?.poqRequestDetails?.currentOdometer) return;

                    //get POQ if the lender has a mapped r1Lender for the specfic type(lease/finance) that offers POQ as a service for a inquiry type we have data for
                    const r1LenderId =  this.selectedLender.getR1Code(this.lienTypeFilter);
                    const POQInquiryType = this.getPOQInquiryType(r1LenderId)
                    
                    if (POQInquiryType != null || (settings.environmentName != 'PRODUCTION' && this.modal.passedData.poqRequestDetails.vin == "JT3VN39W8L8009779")) {
                        this.isBusyPOQ = true
    
                        const poqRequest = this.modal.passedData.poqRequestDetails
                        poqRequest.financeSourceID = r1LenderId //"F000ZM"
                        poqRequest.productType = this.modal.passedData.type == 0 ? ENUMS.PayoffQuoteInquiryTypeProductType.Retail : ENUMS.PayoffQuoteInquiryTypeProductType.Lease //2 ally?
                        poqRequest.inquiryType = POQInquiryType
                        poqRequest.accNumber = this.lien.accNumber // "2215874465"
    
                        // Test Data for Staging.
                        if (settings.environmentName != 'PRODUCTION' && this.modal.passedData.poqRequestDetails.vin == "JT3VN39W8L8009779") {
                            poqRequest.inquiryType = ENUMS.PayoffQuoteInquiryTypeInquiryType.VIN;
                            poqRequest.financeSourceID = "F001PQ";   
                        }
    
                        const response = await api.routeOne.getPayoffQuote(poqRequest, {timeout: 30000})
                        
                        if (response && response.data) {
                            this.poqResponse = new RouteOnePOQResponse(response.data.payoffQuoteResponse)
                        }
                        
                    }
                    else {
                        this.poqResponse = null
                    }
                } catch(err) {
                    console.error(err)
                } finally {
                    this.isBusyPOQ = false
                }
            },
            getPOQInquiryType(r1LenderId) {
                
                let POQInquiryType = null;
                
                let POQOptions = this.$global.r1CreditAppField.financeSources.find(fs => fs.fsId == r1LenderId)?.services.find(s => s.name === "POQ")?.options
                if (!POQOptions) {
                    return null;
                }

                POQOptions = POQOptions.filter(o => o.optionType === "InquiryType")
                if (POQOptions.some(o => o.optionValue === "VIN") && this.modal.passedData.poqRequestDetails.vin) {
                    POQInquiryType = ENUMS.PayoffQuoteInquiryTypeInquiryType.VIN
                }
                else if (POQOptions.some(o => o.optionValue === "SSNTXID") && this.modal.passedData.poqRequestDetails.ssn) {
                    POQInquiryType = ENUMS.PayoffQuoteInquiryTypeInquiryType.SSN
                }
                else if (POQOptions.some(o => o.optionValue === "ACC") && this.lien.accNumber) {
                    POQInquiryType = ENUMS.PayoffQuoteInquiryTypeInquiryType.ACC
                }

                return POQInquiryType
            },
            calcPayoffAmount(isBaseInput = false) {
                this.lien.recalculatePayOffAmount(isBaseInput);
            },
            calculateLeaseWalkAwayAmount() {
                this.lien.recalculateWalkAwayAmount();
            },
            close() {
                modal.close();
            },
            cancel() {
                modal.cancel();
            },
        },
        components: {
            Panel,
            IsBusySectionComponent,
            //AutocompleteComponent,
            InputRichDropdown,
            AddressComponent,
            InputTextbox,
            InputNumber,
            InputCurrency,
            InputPhone,
            InputDate,
            DisplayData,
            AccordionSection
        }
    };
</script>
<style>
    .modalTradeInLien-container {
        min-height: 500px;
    }

        .modalTradeInLien-container .IsBusySectionComponent-container img {
            margin-top: 0;
        }

        .modalTradeInLien-container .poq-results-wrapper > div {
            height: 340px;
            overflow-y: auto;
        }

        .modalTradeInLien-container .poq-results-wrapper fieldset {
            row-gap: 20px;
        }



        .modalTradeInLien-container .centered {
            place-items: center;
        }
</style>
