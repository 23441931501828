<template>
    <div class="checklist-review-modal modal-container modal-large">
        <div class="modal-header">
            {{ itemName }}
        </div>
        <div class="modal-body">
            <p>{{ checklistItem.description }}</p>

            <div class="review-field-match">
                <div class="review-doc-preview">
                    <h3>Uploaded Document</h3>

                    <DocumentPreview :document="document" expandOnClick style="min-height:200px;" />
                </div>
                <div v-if="fieldsToMatch.length > 0" class="review-field-list">
                    <h3>Information on Deal</h3>

                    <div v-for="field in fieldsToMatch" :key="field.id" class="field-item" @click="toggleFieldCheckbox(field)">
                        <InputCheckbox v-model="field.isSelected" label="_" style="pointer-events: none;" />

                        <label class="field-item-label">
                            <span style="font-weight:bold;">{{ field.name }}</span>
                            <span style="text-transform:capitalize;">{{ field.fieldValue }}</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="button" @click="cancel()">Cancel</button>
            <button class="button-unwind" @click="close(false)">Reject</button>
            <button class="button-save" @click="close(true)" :disabled="!allFieldsSelected">Accept</button>
        </div>
    </div>
</template>


<script>
    import $modal from '@core/services/modal';
    import { defineAsyncComponent } from 'vue';
    import InputCheckbox from '@core/components/InputCheckbox.vue';

    export default {
        name: "modalDealChecklistReview",
        props: ['modal'],
        data() {
            return {
                documentViewed: false,
                checklistItem: null,
                checkboxUpdate: 0,
            }
        },
        computed: {
            itemName() {
                if (this.checklistItem.namePrefix) return `${this.checklistItem.namePrefix}: ${this.checklistItem.name}`
                return this.checklistItem.name;
            },
            fieldsToMatch() {
                if (this.checkboxUpdate !== null)
                    return this.checklistItem.fieldsToMatch ?? [];
                return []
            },
            allFieldsSelected() {
                return this.fieldsToMatch.every(f => f.isSelected);
            },
            document() {
                return this.checklistItem.status.document;
            }
        },
        created() {
            this.checklistItem = this.modal.passedData.checklistItem;
            this.fieldsToMatch.forEach(f => f.isSelected = false);
        },
        methods: {
            close(isApproved) {
                $modal.close(isApproved);
            },
            cancel() {
                $modal.cancel();
            },
            toggleFieldCheckbox(checkbox) {
                checkbox.isSelected = !checkbox.isSelected;
                this.checkboxUpdate++;
            },
        },
        components: {
            InputCheckbox,
            DocumentPreview: defineAsyncComponent(() => import("@core/components/DocumentPreview.vue")),
        }
    };
</script>
<style>
    .checklist-review-modal .review-field-match {
        display: flex;
        gap: 10px;
    }

        .checklist-review-modal .review-field-match > * {
            flex: 1;
        }

    .checklist-review-modal .review-field-list .field-item {
        display: flex;
        align-items: center;
        margin: 10px 0;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid var(--border-color);
        background-color: var(--button-dropdown-list-background-color);
        cursor: pointer;
        transition: all 0.2s ease-out;
    }
    .checklist-review-modal .review-field-list .field-item:hover {
        color: var(--main-color);
        letter-spacing: 0.5px;
    }

        .checklist-review-modal .review-field-list .field-item .field-item-label span {
            display: block;
        }
</style>
