<template>
    <div>
        <div class="user-info-container" v-if="userInfo">
            <p class="text-container">
                Hi, <span class="cobrowsing-censor" :style="{marginLeft: '5px'}">{{ employeeName }}</span>
            </p>
            <FloatingMenu closeOnClick>
                <template #action-button>
                    <!--<i class="fas fa-cog" />-->
                    <i class="fas fa-bars" />
                </template>

                <template #body>
                    <div class="menu-list" @click="openSettingModal()">
                        <div class="inner-container">
                            <span class="fa-stack fa-1x">
                                <i class="fas fa-user-cog fa-stack-1x menu-icon" />
                            </span>
                            <span class="option-text">User Settings</span>
                        </div>
                    </div>
                    <div class="menu-list" @click="openTicketModal()" v-if="user.EmployeeAccess.IsAdmin || user.EmployeeAccess.IsFIManager">
                        <div class="inner-container">
                            <span class="fa-stack fa-1x">
                                <i class="fas fa-ticket-alt fa-stack-1x menu-icon" />
                            </span>
                            <span class="option-text">Cash Bash</span>
                        </div>
                    </div>
                    <div class="menu-list" @click="openPlayCashBash()" v-if="userInfo.employeeAccess.isAdmin">
                        <div class="inner-container">
                            <span class="fa-stack fa-1x">
                                <i class="fas fa-ticket-alt fa-stack-1x menu-icon" />
                            </span>
                            <span class="option-text">Play Cash Bash</span>
                        </div>
                    </div>
                    <div class="menu-list">
                        <div class="inner-container" @click="logout()">
                            <span class="fa-stack fa-1x">
                                <i class="fas fa-sign-out-alt fa-stack-1x menu-icon" />
                            </span>
                            <span class="option-text">Logout</span>
                        </div>
                    </div>
                </template>
            </FloatingMenu>
        </div>
        <PanelESign v-if="userInfo && hasUpdateSignature"
                    :name="employeeName"
                    :userInfo="employeeInfo"
                    :cancelHandler="!needFirstSign ? cancelSignature : null"
                    :submitHandler="submitSignature" />
    </div>
</template>


<script>

    import api from '@core/services/api'
    import auth from '@core/services/auth'
    import util from '@core/services/util'
    import $modal from '@core/services/modal'
    import settings from 'settings'

    import modalBadges from '@/modals/modalBadges.vue'
    import modalTicket from '@/modals/modalTicket.vue'
    import modalUserSettings from '@/modals/modalUserSettings.vue'

    import PanelESign from '@core/components/PanelESign.vue'
    import FloatingMenu from '@/components/FloatingMenu.vue'

    export default {
        name: "UserInfo",
        data() {
            return {
                today: null,
                openMenu: false,
                userInfo: null,
                needFirstSign: false
            };
        },
        computed: {
            employeeName() {
                if (!this.userInfo?.employeeName.includes(",")) return this.userInfo?.employeeName

                const names = this.userInfo?.employeeName.split(",");
                if (names.length > 1)
                    return `${names[1]} ${names[0]}`

                return this.userInfo?.employeeName;
            },
            hasUpdateSignature() {
                return this.userInfo?.updateSignature
            },
            util() {
                return util
            },
            user() {
                return auth.getTokenPayload();
            },
            employeeInfo() {
                return this.userInfo
                    ? {
                        id: this.userInfo.id,
                        storeCode: this.userInfo.employeeAccess.storeCodes[0],
                        cellNumber: this.userInfo.employeeWorkNumber ?? this.userInfo.employeePhoneNumber,
                    }
                    : null;
            },
        },
        async created() {
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = today.getFullYear();

            this.today = mm + '/' + dd + '/' + yyyy;

            await this.setUserInfo();
        },
        methods: {
            async setUserInfo() {
                try {
                    const payload = auth.getTokenPayload();
                    const { data: userInfoTemp } = await api.users.getByCode(payload.EmployeeCode);

                    if (util.isNullOrEmpty(userInfoTemp.employeeSignature) || util.isNullOrEmpty(userInfoTemp.employeeInitials)) {
                        this.needFirstSign = true
                        userInfoTemp.updateSignature = true;
                    } else {
                        userInfoTemp.updateSignature = false;
                    }
                    userInfoTemp.employeeStores = settings.userStores
                    this.userInfo = userInfoTemp
                } catch (error) {
                     
                    console.error(error);
                    util.toastr("error", "Get User By Code", error);
                }
            },
            logout() {
                localStorage.setItem('Saturn-Theme', '')
                auth.logout();
            },
            openPlayCashBash() {
                this.$router.push(`/cashbash/`)
            },
            openSettingModal() {
                $modal.open(modalUserSettings, { name: 'modalUserSettings', passedData: { userInfo: this.userInfo }, backdrop: false });
            },
            openTicketModal() {
                $modal.open(modalTicket, { name: 'modalTicket', passedData: { userInfo: this.userInfo }, backdrop: false });
            },

            openBadgesModal() {
                $modal.open(modalBadges, { name: 'modalBadges', passedData: {}, backdrop: false });
            },
            submitSignature(signature, initials) {
                this.userInfo.employeeSignature = typeof signature == 'string' ? signature : signature.toDataURL();
                this.userInfo.employeeInitials = typeof initials == 'string' ? initials : initials.toDataURL();

                // console.log("This is the saved data", this.userInfo.employeeSignature, this.userInfo.employeeInitials)
                if (this.needFirstSign) {
                    this.updateSignature();
                } else {
                    this.userInfo.updateSignature = false;
                }
            },
            async updateSignature() {
                try {
                    await api.users.setUser(this.userInfo);
                    util.toastr('success', 'User First Signature', 'Successfully Created')
                    this.needFirstSign = false;
                    this.userInfo.updateSignature = false;
                    $modal.close()
                } catch (error) {
                    util.toastr('error', 'User First Signature', error)
                }
            },
            cancelSignature() {
                this.userInfo.updateSignature = false;
            },
        },
        components: {
            PanelESign,
            FloatingMenu,
        }
    };
</script>