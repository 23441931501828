interface PayoffQuoteInquiryResults {
    payoffQuoteReferenceID: string;
    requestDate: string;
    items: PayoffQuoteItem[];
}

interface PayoffQuoteItem {
    accountNumber: string;
    productType: number;
    productTypeSpecified: boolean;
    payment: PaymentDetails;
    vehicle: VehicleDetails;
    customer: any; // Assuming customer can be any type or null
    quote: QuoteDetails;
    consumerPayoffInstruction: any; // Assuming this can be any type or null
    comments: string[];
}

interface PaymentDetails {
    nextPaymentAmount: AmountDetails;
    nextPaymentDate: string;
    paymentsRemainingSpecified: boolean;
    term: number | null; // Assuming term can be a number or null
    balloonAmount: number | null; // Assuming balloonAmount can be a number or null
    lastDatePaid: string | null; // Assuming lastDatePaid can be a string or null
}

interface VehicleDetails {
    vin: string;
    year: string;
    make: string;
    model: string;
    residualValue: number | null; // Assuming residualValue can be a number or null
}

interface QuoteDetails {
    netPayoffAmount: AmountDetails;
    goodThroughDate: string;
    perDiem: AmountDetails;
    grossPayoffAmount: AmountDetails;
    securityDepositAmount: number | null; // Assuming securityDepositAmount can be a number or null
    quoteDescription: string | null; // Assuming quoteDescription can be a string or null
    quoteDetails: string;
    contractStartDate: string | null; // Assuming contractStartDate can be a string or null
    scheduledEndDate: string | null; // Assuming scheduledEndDate can be a string or null
}

interface AmountDetails {
    currencySpecified: boolean;
    value: number;
}

export default class RouteOnePOQResponse {
    transactionID: string;
    item: string;
    itemElementName: number;
    payoffQuoteInquiryResults: PayoffQuoteInquiryResults;

    constructor(data: Partial<RouteOnePOQResponse>) {
       Object.assign(this, data)
    }

    // You can add methods here as needed
}
