<template>
    <div class="checklist-review-modal modal-container modal-large">
        <div class="modal-header">
            {{ itemName }}
        </div>
        <div class="modal-body">
            <p>{{ checklistItem.description }}</p>

            <div class="review-field-match">
                <div class="review-doc-preview">
                    <h3>Uploaded Document</h3>

                    <DocumentPreview :document="document" 
                        expandOnClick 
                        style="min-height: 200px;"
                    />
                </div>
                <div v-if="fieldsToMatch.length > 0" class="review-field-list">
                    <h3>Information on Deal</h3>

                    <div v-for="field in fieldsToMatch" :key="field.id" class="field-item" @click="toggleFieldCheckbox(field)">
                        <InputCheckbox v-model="field.isSelected"
                            label="_"
                            style="pointer-events: none;"
                        />

                        <label class="field-item-label">
                            <span style="font-weight:bold;">{{ field.name }}</span>
                            <span style="text-transform:capitalize;">{{ field.fieldValue }}</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="button" :disabled="isBusy" @click="cancel()">
                Cancel
            </button>
            <ButtonLoading :isLoading="isBusy" class="button-unwind" @click="close(false)">
                Reject
            </ButtonLoading>
            <ButtonLoading :isLoading="isBusy" class="button-save" @click="close(true)" :disabled="!allFieldsSelected">
                Accept
            </ButtonLoading>
        </div>
    </div>
</template>
<script lang="ts">
    export interface IModalDealChecklistReviewProps {
        modal: {
            passedData: {
                checklistItem: DealChecklistItem,
            }
        }
    }
</script>
<script setup lang="ts">
    import { computed, onBeforeMount, ref } from 'vue';

    import $modal from '@core/services/modal';
    import ChecklistHelper from '@/helpers/checklist-helper';

    import DealChecklistItem from '@core/classes/Checklist/DealChecklistItem';

    import ButtonLoading from '@core/components/ButtonLoading.vue';
    import DocumentPreview from '@core/components/DocumentPreview.vue';
    import InputCheckbox from '@core/components/InputCheckbox.vue';

    type FieldToMatch = DealChecklistItem['fieldsToMatch'][0];
    interface IFieldCheckbox extends FieldToMatch {
        isSelected: boolean;
    }

    const props = defineProps<IModalDealChecklistReviewProps>();


    const checklistItem = computed((): DealChecklistItem => props.modal.passedData.checklistItem);
    const itemName = computed((): string => checklistItem.value.name);
    const document = computed(() => checklistItem.value.status.document);


    const isBusy = ref(false);
    const fieldsToMatch = ref<IFieldCheckbox[]>([]);

    const allFieldsSelected = computed((): boolean => {
        return fieldsToMatch.value.every(f => f.isSelected);
    });


    onBeforeMount(() => {
        fieldsToMatch.value = checklistItem.value.fieldsToMatch.map(f => ({ isSelected: false, ...f }));
    });


    const close = async (isApproved: boolean) => {
        const updatedItem = await ChecklistHelper.addManualVerification(isApproved, checklistItem.value, (newValue) => isBusy.value = newValue);

        if (updatedItem)
            $modal.close(isApproved);
    };

    const cancel = () => {
        $modal.cancel();
    };


    const toggleFieldCheckbox = (field: IFieldCheckbox) => {
        field.isSelected = !field.isSelected;
    };
</script>
<style>
    .checklist-review-modal .review-field-match {
        display: flex;
        gap: 10px;
    }

        .checklist-review-modal .review-field-match > * {
            flex: 1;
        }

    .checklist-review-modal .review-field-list .field-item {
        display: flex;
        align-items: center;
        margin: 10px 0;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid var(--border-color);
        background-color: var(--button-dropdown-list-background-color);
        cursor: pointer;
        transition: all 0.2s ease-out;
    }
    .checklist-review-modal .review-field-list .field-item:hover {
        color: var(--main-color);
        letter-spacing: 0.5px;
    }

        .checklist-review-modal .review-field-list .field-item .field-item-label span {
            display: block;
        }
</style>
