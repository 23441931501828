<template>
    <button v-if="canSalesManagerSendToSalesPerson" @click="moveDealToSalesPersonInProcess" class="button-delete">
        Take Customer Back to Sales Person
    </button>

    <button v-else-if="canFISendToSalesManager" @click="moveDealToSalesManagerInProcess" class="button-delete">
        Take Deal Jacket Back to Sales Manager
    </button>

    <button v-else-if="canAccountingSendToFI" @click="moveDealToFIProcess" class="button-delete">
        Take Deal Jacket Back to F&I
    </button>
</template>

<script>
    import ENUMS from "@core/classes/Enums";
    import auth from '@core/services/auth';

    export default {
        name: "ButtonMoveDeal",
        props: {
            fimenu: {
                type: Object,
                required: true,
            }
        },
        components: {},
        data() {
            return {
            }
        },
        computed: {
            canFISendToSalesManager() {
                return auth.hasAccessToPermission("SalesManager") &&
                    !this.fimenu.hasPaperworkPacket() &&
                    !this.fimenu.hasCoverageSelected() &&
                    this.fimenu.isDealInFinance() &&
                    this.fimenu.dealStatus < ENUMS.DEAL_STATUS.FI_FINISHED
            },
            canSalesManagerSendToSalesPerson() {
                return auth.hasAccessToPermission("SalesManager") &&
                    (this.fimenu.dealStatus === ENUMS.DEAL_STATUS.SALES_MANAGER_FINISHED) || (this.fimenu.dealStatus === ENUMS.DEAL_STATUS.SALES_MANAGER_IN_PROCESS)
                //return true;
            },
            canAccountingSendToFI() {
                return this.fimenu.buyersOrderEnabled &&
                    auth.hasAccessToPermission("Accounting") &&
                    (this.fimenu.isDealInAccounting() && this.fimenu.dealStatus < ENUMS.DEAL_STATUS.ACCOUNTING_FINISHED)
            },
            canFIRecoverDeal() {
                return this.fimenu.buyersOrderEnabled &&
                    auth.hasAccessToPermission("FIManager") &&
                    !auth.hasAccessToPermission("Accounting") &&
                    (this.fimenu.dealStatus == ENUMS.DEAL_STATUS.FI_FINISHED)
            },
            Deal() {
                return this.fimenu
            },
        },
        methods: {
            moveDealToSalesManagerInProcess() {
                this.fimenu.moveDealToSalesManagerInProcess()
            },
            moveDealToSalesPersonInProcess() {
                this.fimenu.moveDealToSalesPersonInProcess()
            },
            moveDealToFIProcess() {
                this.fimenu.moveDealToFIProcess()
            },
        },
    };
</script>

<style>
    .DealStatusIcon {
        position: relative;
        font-size: 1.80em;
        color: var(--print-color);
    }

        .DealStatusIcon .status-dot {
            position: relative;
        }

            .DealStatusIcon .status-dot svg {
                position: absolute;
                top: 40%;
                left: 60%;
                transform: translate(-50%, -50%);
                height: 1.25em;
                width: 1.25em;
            }

        /*.DealStatusIcon .fi-finalized {
                position: absolute;
                height: 1.25em;
                top: 50%;
                left: 10%;
                transform: translate(-25%, -50%);
            }
    */
        .DealStatusIcon.final-step {
            color: var(--success-color);
        }

        .DealStatusIcon.dead-deal {
            color: var(--error-color);
        }

        .DealStatusIcon .spinner {
            transform-origin: center;
            animation: rotate 2.5s linear infinite
        }

            .DealStatusIcon .spinner circle {
                stroke-linecap: round;
                animation: spinner-offset 2s ease-out infinite
            }

    @keyframes spinner-offset {
        0% {
            stroke-dasharray: 0 150;
            stroke-dashoffset: 0
        }

        47.5% {
            stroke-dasharray: 42 150;
            stroke-dashoffset: -16
        }

        95%, 100% {
            stroke-dasharray: 42 150;
            stroke-dashoffset: -59
        }
    }
</style>