import { 
    AutoCompleteLeadRequest, 
    AutoCompleteLeadResponse,
    AutoCompleteRequest, 
    AutoCompleteResponse, 
    AutoCompleteVerificationRequest, 
    AutoCompleteVerificationResponse 
} from "@core/classes/Insurance/AutoCompleteResponses";
import { ApiExchange } from "@core/classes/Responses/SharedClasses";
import DocumentAssociation from "@core/classes/DocumentAssociation";


export enum AutoCompleteExchange {
    Unknown = -1,
    Verification, 
    Lead
}

export class AutoCompleteData {
    /** The ElasticSearch ID of this record. Matches the AutoComplete identifier contained in the API response objects (`verifications.verificationId` or `lead.leadId`) */
    id: string;
  
    /** A list of `DocumentAssociation`s used to look up this record. This record may be associated with more items than listed here. */
    associations: DocumentAssociation[];
  
    /** The type of exchange included in this record. If all exchange types are populated, this field will equal `AutoCompleteExchange.Unknown` (-1) */
    exchangeType: AutoCompleteExchange;
  
    /** When true, indicates that this record contains debug test data. */
    containsTestData: boolean;
  
    /** Populated when `exchangeType` equals `AutoCompleteExchange.Verification` (0). Contains the API exchange for an insurance verification. */
    verification?: ApiExchange<AutoCompleteVerificationRequest, AutoCompleteVerificationResponse>;
  
    /** Populated when `exchangeType` equals `AutoCompleteExchange.Lead` (1). Contains the API exchange for lead (quote) generation. */
    lead?: ApiExchange<AutoCompleteLeadRequest, AutoCompleteLeadResponse>;

    constructor(init?: Partial<AutoCompleteData>) {
        this.id = init.id;
        this.associations = init.associations;
        this.exchangeType = init.exchangeType;
        this.containsTestData = init.containsTestData;

        if (init.verification)
            this.verification = new ApiExchange(init.verification);

        if (init.lead)
            this.lead = new ApiExchange(init.lead);
    }

    getExchange(): ApiExchange<AutoCompleteRequest, AutoCompleteResponse> {
        switch (this.exchangeType) {
            case AutoCompleteExchange.Verification: 
                return this.verification;
            case AutoCompleteExchange.Lead: 
                return this.lead;
            default: 
                throw new Error('Unknown exchange type. Cannot get request.', { cause: this.exchangeType });
        }
    }

    getRequest(): AutoCompleteRequest {
        return this.getExchange()?.getRequestData();
    }

    getRequestTimestamp(): Date {
        return this.getExchange()?.getRequestTimestamp();
    }

    getResponse(): AutoCompleteResponse {
        return this.getExchange()?.getResponseData();
    }

    getResponseTimestamp(): Date {
        return this.getExchange()?.getResponseTimestamp();
    }
}