import "@nanoandrew4/vue3-carousel-3d/dist/style.css";
import { createApp, ref } from "vue";
import { getRecordingDetails, getRecordingFunctions, recordingPermissions } from "@/services/VideoRecordingHelper";
import api from "@core/services/api";
import App from "./App.vue";
import { install as InstallDealRoomHub }  from "@core/plugins/dealRoomHub";
import filters from "@core/services/filters";
import LogRocket from "logrocket";
import MeetingHelper from "@core/plugins/MeetingHelper";
import MeetingHub from "@core/plugins/meetingHub";
import mitt from "mitt";
import Notifications from "@kyvg/vue3-notification";
import { piniaInstance } from "@core/stores/global-store";
import ProductClass from "@core/classes/ProductClass";
import router from "@/router";
import settings from "settings";
import Static from "@core/plugins/static";
import { useTestStore } from "@core/stores/test-store";
import util from "@core/services/util";
import VersionsLender from "@core/classes/LenderVersionClass";
import VideoRecordingService from "@core/plugins/video-recording-service";
import VueGoogleMaps from "@fawmi/vue-google-maps";

const app = createApp(App);
app.use(piniaInstance);

const eventBus = mitt();
const meetingHub = mitt();
const dealRoomHub = mitt();

// Global Properties
app.config.globalProperties.EventBus = eventBus;
app.config.globalProperties.MeetingHubBus = meetingHub;
app.config.globalProperties.DealRoomBus = dealRoomHub;
app.config.globalProperties.$filters = filters;
app.config.globalProperties.recordingDetails = getRecordingDetails();
app.config.globalProperties.recordingFunctions = getRecordingFunctions();
app.config.globalProperties.recordingPermissions = recordingPermissions();

app.use(Notifications).use(MeetingHub).use(MeetingHelper).use(InstallDealRoomHub);

app.use(VideoRecordingService, {
    storageKey: "CameraRecording",
    processQueueItem: async (chunk: any) => {
        try {
            console.log("inside processQueueItem - chunk", chunk);
            const response = await chunk?.blob?.arrayBuffer();
            const bytes = new Uint8Array(response);
            let binary = "";
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            delete chunk["blob"];
            const processedChunk = {
                blobContent: window.btoa(binary),
                ...chunk,
            };
            console.log("----- \n\n  Processed chunk:", processedChunk, "\n\n ----- ");

            await api.azureMediaServices.uploadVideoChunk(processedChunk);
        } catch (e) {
            console.log("Error processing chunk", e);
        }
    },
});

app.use(VueGoogleMaps, {
    load: {
        key: settings.googleMapsApiKey,
        libraries: "places",
    },
});

//GET GLOBAL SETTINGS
settings.refreshCache = async () =>
    await api.settings.getGlobalSettings().then(async (response: any) => {
        const globalSettings = response.data;

        //SAVE USER STORES (FULL) TO SETTINGS
        settings.userStores = globalSettings.userStores;

        //SAVE PRODUCTS (FULL) TO SETTINGS
        const getProducts = api.settings
            .getProducts()
            .then((response: any) => {
                settings.products = response.data.map((p: any) => new ProductClass(p));
            })
            .catch((err: any) => {
                console.error("error", err);
            });

        //SAVE LENDERS (FULL) TO SETTINGS
        const getLenders = api.settings
            .getLenders()
            .then((response: any) => {
                settings.lenders = response.data.map((l: any) => new VersionsLender(l));
            })
            .catch((err: any) => {
                console.error("error", err);
            });

        //SETINGS DATA MODEL
        const getSettingsModels = api.fimenu
            .getEmptySettingsDocumentModel()
            .then((emptyModelResponse: any) => {
                settings.sdm = emptyModelResponse.data;
            })
            .catch((err: any) => {
                //util.toastr('error', "Error", 'Error loading test model');
                console.error("error", err);
            });

        //SETTINGS PLAID ENVIRONMENT
        const getPlaidEnvironment = api.settings.getPlaidEnvironment().then((response: any) => {
            if (!response.data) console.error("ERROR: Could not get Plaid Environment");
            else settings.plaidEnvironment = response.data;
        });

        //SETTINGS LOG ROCKET ENVIRONMENT
        const getLogRocketVars = api.settings.getLogRocketEnvironmentVars().then((response: any) => {
            if (!response.data) console.error("ERROR: Could not get Log Rocket Environment");
            else settings.logRocketEnvironment = response.data;
        });

        const dataFetchList = [
            Promise.resolve(getProducts),
            Promise.resolve(getLenders),
            Promise.resolve(getSettingsModels),
            Promise.resolve(getPlaidEnvironment),
            Promise.resolve(getLogRocketVars),
        ];

        await Promise.all(dataFetchList);

        //SET LOOKUPS
        settings.lookups = globalSettings.lookups;

        //Add paperwork categories
        api.utilities
            .getPaperworkCategories()
            .then((response: any) => {
                settings.lookups.paperworkCategories = response.data.sort(
                    (a: any, b: any) => a.orderIndex - b.orderIndex,
                );
            })
            .catch(() => {
                settings.lookups.paperworkCategories = [];
            });

        api.settings
            .getDocumentTypes()
            .then((response: any) => (settings.lookups.uploadedDocumentTypes = response.data))
            .catch((err: any): any[] => (settings.lookups.uploadedDocumentTypes = []));

        //SET LOOK UP FUNCTIONS
        settings.lookups.getProviderDescription = (provider: any) => {
            if (provider) {
                const foundProvider = settings.lookups.providers.find(
                    (p: any) => p.providerCode.toLowerCase() == provider.toLowerCase(),
                );
                return foundProvider ? foundProvider.providerName : provider;
            } else {
                return provider;
            }
        };

        //UPDATE PRODUCTS IN SETTINGS
        settings.updateProducts = (updatedProducts: any) => {
            settings.products.forEach((currentProduct: any, index: any) => {
                const updatedProduct = updatedProducts.find((p: any) => currentProduct.id === p.id);
                if (updatedProduct) {
                    const newProduct = new ProductClass(updatedProduct);
                    newProduct.productPricingDetails.forEach((newPpd: any) => {
                        const currentPpd = currentProduct?.getPricingDetails(newPpd.productPricingDetailsId);
                        if (currentPpd) {
                            newPpd.pricedConfig = currentPpd.pricedConfig;
                            newPpd.repriceOptions = currentPpd.repriceOptions;
                        }
                    });

                    settings.products[index] = newProduct;
                }
            });
        };
        const shouldRecordSession = () => {
            const subdomain = window.location.href.split(settings.logRocketEnvironment.rootHostname)[1]; // Extracts the subdomain
            if (!subdomain) return true;
            const excludedSubdomains = settings.lookups.logRocketExclusionsList; // Subdomains to exclude

            // Returns true if the current exludedSubDomains is not included in the subdomain
            return !excludedSubdomains.some((sb: string) => subdomain.includes(sb));
        };

        //INIT LOG ROCKET
        if (shouldRecordSession()) {
            //LogRocket.init(settings.logRocketEnvironment.organization);
            LogRocket.init(settings.logRocketEnvironment.organization, {
                rootHostname: settings.logRocketEnvironment.rootHostName,
            });
            //Set Cookie to create expiration of session if recording lasts more than 24 hours
            if (util.getCookie("_lr_saturn_init") === null) {
                util.setCookie("_lr_saturn_init", "true", 1); // Expires in 1 day
            }
        }

        api.$cache.init(settings.lookups);
    });

await settings.refreshCache();

//NOW WE CAN LOAD STATIC B/C WE HAVE STORES LOADED
app.use(Static);

app.use(router).mount("#app");

//SHOW FOOTER
const footer = document.querySelector("footer");
footer?.classList.remove("hidden");

//eslint-disable-next-line no-console
console.log("This is saturn app", app);

// Defining user stores;
// const defaultStore = useTestStore();
// defaultStore.setTestStore({prop1: "test", prop2: 0, prop3: false});
