<template>
    <div ref="navContainerRef" class="nav" :class="{active: navOpen}" @mouseover="toggleMenu(true)" @mouseleave="toggleMenu(false)" @click="toggleMenu(false)">
        <div :class="{'menu-item': true, 'show-text': displayMenuItemText}" v-if="auth.hasAccessToPermission('SalesPerson')">
            <router-link :to="{name: 'customers'}">
                <span class="menu-item-icon">
                    <i class="fas fa-users" title="Customers"></i>
                </span>
                <span class="menu-item-text">Customers</span>
            </router-link>
        </div>
        <div :class="{'menu-item': true, 'show-text': displayMenuItemText}" v-if="auth.hasAccessToPermission('SalesPerson') || auth.hasAccessToPermission('TitlingClerk')">
            <router-link :to="{name: 'fimenudeals'}">
                <span class="menu-item-icon">
                    <i class="fas fa-comments-dollar" title="Deals"></i>
                </span>
                <span class="menu-item-text">Deals</span>
            </router-link>
        </div>
        <div :class="{'menu-item': true, 'show-text': displayMenuItemText}" @click="toggleMenu(false)" v-if="auth.hasAccessToPermission('SalesManager')">
            <router-link :to="{name: 'cancelCoverage'}">
                <span class="menu-item-icon">
                    <CancelCoverageIcon />
                </span>
                <span class="menu-item-text">Cancel Coverage</span>
            </router-link>
        </div>
        <div v-if="user.EmployeeAccess.IsAdmin || user.EmployeeAccess.IsServiceManager" :class="{'menu-item': true, 'show-text': displayMenuItemText}">
            <router-link :to="{name: 'service'}">
                <span class="menu-item-icon"><i class="fas fa-search-dollar" /></span>
                <span class="menu-item-text">Lookup</span>
            </router-link>
        </div>
        <div :class="{'menu-item': true, 'show-text': displayMenuItemText}" v-if="user.EmployeeAccess.IsAccounting || user.EmployeeAccess.IsAdmin">
            <router-link :to="{name: 'reports'}">
                <span class="menu-item-icon">
                    <i class="fas fa-chart-line" title="Reports"></i>
                </span> 
                <span class="menu-item-text">Reports</span>
            </router-link>
        </div>
        <div :class="{'menu-item': true, 'show-text': displayMenuItemText}" v-if="auth.hasAccessToPermission('UserMgt')">
            <router-link :to="{name: 'admin'}">
                <span class="menu-item-icon">
                    <i class="fas fa-cog" title="Settings"></i>
                </span> 
                <span class="menu-item-text">Settings</span>
            </router-link>
        </div>
        <div :class="{'menu-item': true, 'show-text': displayMenuItemText}" v-if="auth.hasAccessToPermission('Admin')" @mouseover="openUtilities()" @mouseleave="closeUtilities()">
            <router-link :to="{name: 'utilities'}">
                <span class="menu-item-icon">
                    <i class="fas fa-puzzle-piece" title="utilities"></i>
                </span>
                <span class="menu-item-text">Utilities</span>
            </router-link>
        </div>
        <div :class="{'menu-item': true, 'show-text': displayMenuItemText}" v-if="auth.hasAccessToPermission('Admin')">
            <router-link :to="{name: 'sandbox'}">
                <span class="menu-item-icon">
                    <i class="fas fa-layer-group" title="Sandbox"></i>
                </span>
                <span class="menu-item-text">Sandbox</span>
            </router-link>
        </div>
    </div>
</template>


<script setup lang="ts">
    import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
    import auth from '@core/services/auth';
    import CancelCoverageIcon from '@/components/CancelCoverageIcon.vue';
    
    //-----------------------//
    //------ Variables ------//
    //-----------------------//
    const navOpen = ref(false)
    const isUtilitiesOpen = ref(false)
    const displayMenuItemText = ref(true)
    const menuMaxWidthPercentage = ref(30)
    const menuWidthShowingText = ref(0)

    const navContainerRef = ref()


    //----------------------//
    //------ Computed ------//
    //----------------------//
    const user = computed(() =>{
        return auth.getTokenPayload();
    })

    //------------------------//
    //------ Vue Events ------//
    //------------------------//
    onBeforeUnmount(() => {
        document.removeEventListener('resize', calculateMenuWidthPercentage)
    })

    onMounted(() => {
        menuWidthShowingText.value = navContainerRef.value.clientWidth
        calculateMenuWidthPercentage()
        window.addEventListener('resize', calculateMenuWidthPercentage);  
    })

    //-----------------------//
    //------ Functions ------//
    //-----------------------//
    const calculateMenuWidthPercentage = () => {
        const screenWidth = window.innerWidth
        const menuWidthPercentage = menuWidthShowingText.value / screenWidth * 100;
        displayMenuItemText.value = menuMaxWidthPercentage.value > menuWidthPercentage
    } 

    const openUtilities = () => {
        isUtilitiesOpen.value = true;
    }

    const closeUtilities = () => {
        isUtilitiesOpen.value = false;
    }

    const toggleMenu = (val: any) => {
        if (val == null) {
            navOpen.value = !navOpen.value;
        }
        else {
            navOpen.value = val;
        }
    }


</script>
<style scoped>
    @import url("MainNav.css");
    
    
</style>