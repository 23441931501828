<template>
    <div class="modal-container modal-dynamic-component">
        <div class="modal-header">
            {{ title }}
        </div>
        <div class="modal-body">
            <component :is="importedComponent"
                v-bind="componentProps"
                v-on="componentEvents"
            />
        </div>
        <div class="modal-footer">
            <button class="button-unwind" @click="close">
                Close
            </button>
        </div>
    </div>
</template>
<script lang="ts">
    export interface IModalDynamicComponent {
        modal: {
            passedData: {
                title?: string,
                component: DefineComponent<unknown, unknown, unknown>,
                componentProps?: { [key: string]: unknown },
                componentEvents?: { [key: string]: (...args: unknown[]) => unknown }
            }
        }
    }
</script>
<script setup lang="ts">
    import { computed, DefineComponent, toRaw } from 'vue';
    import $modal from '@core/services/modal';

    const props = defineProps<IModalDynamicComponent>();

    const importedComponent = toRaw(props.modal.passedData.component);

    const title = computed((): string => props.modal.passedData.title ?? '');
    const componentProps = computed(() => props.modal.passedData.componentProps ?? {});
    const componentEvents = computed(() => props.modal.passedData.componentEvents ?? {});

    const close = () => $modal.close();
</script>