<template>
    <div class="modal-add-edit-checklist modal-container modal-large">
        <div class="modal-header">
            Custom Deal Checklist Item
        </div>

        <div class="modal-body">
            <div :style="$grid('1')">
                <Panel title="Checklist Information">
                    <div class="name-selector">
                        <SwitchButton v-if="preBuiltItems.length > 0"
                            :active="usingCustomItem"
                            keepActiveSynced
                            leftLabel="Pre-Built"
                            rightLabel="Custom"
                            @update="onUpdateUsingCustom"
                        />

                        <InputRichDropdown v-if="preBuiltItems.length > 0 && !usingCustomItem" 
                            label="Name"
                            v-model:saturn="item.name"
                            :list="preBuiltItems"
                            :valueMap="(t: SettingsChecklistItem) => t?.name"
                            :display="(t: SettingsChecklistItem) => t?.name"
                            :invalid="v$.item.name.$invalid"
                            search
                            :customHandling="onCustomItemSelected"
                            @change="onItemSelected"
                        />

                        <InputTextbox v-else 
                            label="Name"
                            v-model:saturn="item.name"
                            :disabled="!usingCustomItem"
                            :invalid="v$.item.name.$invalid"
                        />
                    </div>

                    <InputTextbox label="Description"
                        v-model:saturn="item.description"
                        :disabled="!isSomethingSelected"
                        :invalid="v$.item.description.$invalid"
                        multiline
                    />
                </Panel>

                <Panel title="Configuration">
                    <fieldset :style="$grid('1-1')" :disabled="!usingCustomItem || !isSomethingSelected">
                        <InputRichDropdown label="Type"
                            v-model:saturn="item.type"
                            :invalid="v$.item.type.$invalid"
                            :list="checklistTypes"
                            :display="(t: any) => t.display"
                            :valueMap="(t: any) => t.value"
                        />

                        <InputRichDropdown label="Subtype" 
                            class="subtype-autocomplete"
                            v-model:saturn="item.subtype"
                            :invalid="v$.item.subtype.$invalid"
                            :disabled="!isDocumentAction"
                            :list="subtypeList"
                            :display="(t: any) => t.display"
                            :valueMap="(t: any) => t.code"
                            search
                            :customHandling="onSubtypeSelected"
                        />

                        <InputRichDropdown label="Send To"
                            v-model:saturn="item.sendTo"
                            :disabled="!usingCustomItem || !isSomethingSelected"
                            :list="VALID_PERSON_TYPES"
                            :display="(v: any) => v.display"
                            :valueMap="(v: any) => v.value"
                            multiSelect
                        />

                        <InputRichDropdown label="Associations"
                            v-model:saturn="item.associations"
                            :invalid="v$.item.associations.$invalid"
                            :disabled="!usingCustomItem || !isSomethingSelected"
                            :list="VALID_ASSOCIATION_TYPES"
                            :display="(v: any) => v.display"
                            :valueMap="(v: any) => v.value"
                            multiSelect
                        >
                            <template #validation>
                                <div v-if="v$.item.associations.required.$invalid">
                                    List cannot be empty.
                                </div>
                            </template>
                        </InputRichDropdown>
                    </fieldset>
                </Panel>
            </div>
        </div>

        <div class="modal-footer">
            <button class="button-unwind" @click="cancel()">
                Cancel
            </button>
            <button :disabled="v$.$invalid || !isSomethingSelected" class="button-save" @click="close()">
                Save
            </button>
        </div>
    </div>
</template>
<script lang="ts">
    export interface IModalAddEditChecklistItemLocalProps {
        modal: {
            passedData: {
                isAddAction: boolean,
                preBuiltUsed: boolean,
                customSubtypes: string[],
                preBuiltItems: SettingsChecklistItem[],
                item: SettingsChecklistItem,
            }
        }
    }

    export interface IModalAddEditChecklistItemLocalOnClose {
        item: SettingsChecklistItem;
        isAddAction: boolean;
        preBuiltUsed: boolean;
    }
</script>
<script setup lang="ts">
    import { computed, onBeforeMount, ref, watch } from 'vue';
    import settings from 'settings';

    import $modal from '@core/services/modal';
    import util from '@core/services/util';

    import { required, requiredIf } from '@vuelidate/validators';
    import { useVuelidate } from '@vuelidate/core';

    import { DOC_ASSOCIATION, PERSON_TYPES } from '@core/classes/Enums';
    import { DealChecklistType } from '@core/classes/SharedEnums';
    import DocumentType from '@core/_interfaces/DocumentType';
    import SettingsChecklistItem from '@core/classes/Checklist/SettingsChecklistItem';

    import InputRichDropdown from '@core/components/InputRichDropdown.vue';
    import InputTextbox from '@core/components/InputTextbox.vue';
    import Panel from '@core/components/Panel.vue';
    import SwitchButton from '@/components/SwitchButton.vue';

    const props = defineProps<IModalAddEditChecklistItemLocalProps>();

    // =========
    // CONSTANTS
    // =========
    // #region Constants
    const DOCUMENT_TYPES = ref<Partial<DocumentType>[]>(settings.lookups.uploadedDocumentTypes);

    const VALID_PERSON_TYPES = [
        { display: 'Customer', value: PERSON_TYPES.CUSTOMER },
        { display: 'Co-Customer', value: PERSON_TYPES.COCUSTOMER }
    ];

    const VALID_ASSOCIATION_TYPES = [
        { display: 'Deal', value: DOC_ASSOCIATION.Deal },
        { display: 'Customer', value: DOC_ASSOCIATION.Customer },
        { display: 'Customer Application', value: DOC_ASSOCIATION.CustomerApplication }
    ];
    // #endregion

    const isAddAction = computed((): boolean => props.modal.passedData.isAddAction);
    const preBuiltItems = computed((): SettingsChecklistItem[] => props.modal.passedData.preBuiltItems);
    const customSubtypes = computed((): string[] => props.modal.passedData.customSubtypes);

    const isSomethingSelected = ref(false);

    // ========================
    // USING CUSTOM ITEM TOGGLE
    // ========================
    // #region Using Custom Item Toggle
    const usingCustomItem = ref(!props.modal.passedData.preBuiltUsed);

    const onUpdateUsingCustom = () => {
        usingCustomItem.value = !usingCustomItem.value;
        isSomethingSelected.value = usingCustomItem.value;

        item.value.resetItem({ isCustomItem: usingCustomItem.value });
    };
    // #endregion
    
    const item = ref<SettingsChecklistItem>(null);

    const isDocumentAction = computed((): boolean => item.value.isDocumentAction());
    const isPlaidAction = computed((): boolean => item.value.isPlaidAction());

    // ===================
    // CHECKLIST ITEM TYPE
    // ===================
    // #region Checklist Item Type
    const checklistTypes = computed((): { display: string, value: number }[] => {
        // If not using pre-built, filter out Plaid actions
        let formatted = Object.keys(DealChecklistType)
            .filter(k => Number.isNaN(Number(k)))
            .map(k => {
                const enumKey = k as keyof typeof DealChecklistType;
                return { display: util.formatEnumKey(enumKey), value: DealChecklistType[enumKey] };
            });

        if (usingCustomItem.value)
            formatted = formatted.filter(f => SettingsChecklistItem.isDocumentAction(f.value));

        return formatted;
    });

    const onCustomItemSelected = (customName: string): Partial<SettingsChecklistItem> => {
        item.value.resetItem({ name: customName, isCustomItem: true });
        return item.value;
    };

    const onItemSelected = (selectedName: string, selectedItem: { obj: SettingsChecklistItem }) => {
        usingCustomItem.value = selectedItem.obj.isCustomItem;
        isSomethingSelected.value = true;

        // If this is a custom item, it already has been reset by onCustomItemSelected
        if (!selectedItem.obj.isCustomItem)
            item.value.resetItem(selectedItem.obj);
    };
    // #endregion

    // ======================
    // CHECKLIST ITEM SUBTYPE
    // ======================
    // #region Checklist Item Subtype
    const subtypeList = computed((): Partial<DocumentType>[] => {
        if (isDocumentAction.value) return DOCUMENT_TYPES.value;
        return [];
    });

    const onSubtypeSelected = (selected: DocumentType) => {
        item.value.subtype = selected.code;
    };
    // #endregion

    // ===========
    // VALIDATIONS
    // ===========
    // #region Validations
    const v$ = useVuelidate({
        item: {
            name: { required },
            description: { required },
            type: { required },
            subtype: {
                required: requiredIf(() => isDocumentAction.value),
                isUnique: (value: string) => {
                    if (!usingCustomItem.value || !value) return true;

                    const valueToCode = `CUSTOM_${value.toUpperCase().replace(' ', '_')}`;
                    const result = customSubtypes.value.every(d => d !== valueToCode);
                    return result;
                }
            },
            associations: { 
                required: requiredIf(() => isDocumentAction.value || isPlaidAction.value)
            },
        } as any
    }, 
    { item }, { $scope: false });
    // #endregion

    // ===============
    // LIFECYCLE HOOKS
    // ===============
    // #region Lifecycle Hooks
    onBeforeMount(async () => {
        if (isAddAction.value && preBuiltItems.value.length < 1) {
            usingCustomItem.value = true;
            isSomethingSelected.value = true;
        }

        item.value = new SettingsChecklistItem(props.modal.passedData.item);
    
        if (!isAddAction.value)
            isSomethingSelected.value = true;

        //If subtype wasn't in document list, it was probably a custom subtype
        if (!isAddAction.value && DOCUMENT_TYPES.value.every(d => d.code !== item.value.subtype)) {
            const subtypeDisplay = item.value.subtype.replace('CUSTOM_', '').replaceAll('_', ' ');

            DOCUMENT_TYPES.value.push({
                code: item.value.subtype,
                display: subtypeDisplay,
                id: crypto.randomUUID()
            });
        }

        watch(() => item.value?.type, () => {
            if (!isDocumentAction.value) item.value.subtype = null;
        });
    });
    // #endregion

    // ================
    // MODAL MANAGEMENT
    // ================
    // #region Modal Management
    const close = () => {
        if (v$.value.$invalid) return;

        // In case a custom subtype was added
        let fixedSubtype = item.value.subtype;
        if (!fixedSubtype.startsWith('CUSTOM_') && DOCUMENT_TYPES.value.every(d => d.code !== item.value.subtype))
            fixedSubtype = `CUSTOM_${fixedSubtype.replaceAll('_', ' ')}`;

        $modal.close({ item: item.value, isAddAction: isAddAction.value, preBuiltUsed: !usingCustomItem.value } as IModalAddEditChecklistItemLocalOnClose);
    };

    const cancel = () => {
        $modal.cancel();
    };
    // #endregion
</script>
<style>
    .modal-add-edit-checklist .modal-body .name-selector {
        display: flex;
        gap: 10px;
        margin-bottom: 10px;
    }

        .modal-add-edit-checklist .modal-body .name-selector > *:not(.switch-container) {
            flex: 1;
        }

        .modal-add-edit-checklist .modal-body .name-selector > .switch-container {
            padding: 0 10px;
            border-radius: 10px;
            border: 1px solid var(--border-color);
            background-color: var(--card-background-color);
        }

    .modal-add-edit-checklist .subtype-autocomplete input {
        text-transform: uppercase;
    }
</style>
