<template>
    <div class="role-icon" ref="roleIcon">
        <i v-if="role === 'UNKNOWN'" class="fas fa-question"></i>

        <span v-else-if="role === 'Spectator'">
            <i class="fas fa-eye spectating-icon"></i>
            <i v-if="isFollowingEditor" class="fas fa-exchange-alt following-icon"></i>
        </span>

        <i v-else class="fas fa-pencil-alt editing-icon"></i>

        <span class="role-info">
            <small>{{ roleInfoText }}</small>
        </span>
    </div>
</template>

<script>
    export default {
        // Meant to be used with <FIMenuViewerToolbar />
        name: 'FIMenuViewerIcon',
        props: ['role', 'isFollowingEditor'],
        data() {
            return {};
        },
        computed: {
            roleInfoText() {
                if (this.role === 'Editor' || this.role === 'UNKNOWN')
                    return this.role;

                const flagText = this.isFollowingEditor ? '- Following Editor' : '';

                return `Viewer ${flagText}`;
            }
        },

        methods: {},
        components: {}
    };
</script>

<style>
    .role-icon {
        --icon-background-color: var(--main-color);

        position: relative;
        display: inline-block;
        width: 100%;
    }

        .role-icon i:not(.following-icon) {
            font-size: 1.5em;
        }

        .role-icon i.following-icon {
            position: absolute;
            font-size: 0.85em;
            bottom: 0;
            right: 0;
            text-shadow: -1px -1px var(--icon-background-color);
        }

        .role-icon .role-info {
            visibility: hidden;
            position: absolute;
            left: 110%;
            width: max-content;
            padding: 5px;
            border-radius: 6px;
            opacity: 1 !important;
            background-color: black;
            color: white;
            text-align: center;
            z-index: 9999999;
            pointer-events: none;
        }
            .role-icon .role-info small {
                color: white !important;
            }

            .role-icon:hover .role-info {
                visibility: visible;
            }

            .role-icon .role-info::after {
                content: "";
                position: absolute;
                top: 50%;
                right: 100%;
                margin-top: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: transparent black transparent transparent;
            }
</style>