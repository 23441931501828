<template>
    <div class="checklist-modal modal-container modal-x-large">
        <PanelDealChecklist v-if="hasChecklistItems" :fimenu="fimenu" ref="dealChecklist" />

        <div v-else>
            <div class="modal-header">
                Deal Checklist
            </div>

            <div class="modal-body">
                <p>No pending items.</p>
            </div>
        </div>

        <div class="modal-footer">
            <button class="button-unwind" @click="close()" :disabled="!isDevelopmentEnv && checklistIsLoading">Close</button>
        </div>
    </div>
</template>

<script>
    import $modal from '@core/services/modal';
    import PanelDealChecklist from '@/components/PanelDealChecklist.vue';

    export default {
        name: "modalDealChecklist",
        props: ['modal'],
        data() {
            return {
                checklistRef: null,
                settings: this.modal.passedData.settings,
            }
        },
        computed: {
            fimenu() {
                return this.modal.passedData.fimenu;
            },
            isDevelopmentEnv() {
                return this.settings.environmentName === "DEVELOPMENT";
            },
            hasChecklistItems() {
                if (!this.checklistRef || this.checklistRef.isLoading) return true;
                return this.checklistRef.checklist.length > 0;
            },
            checklistIsLoading() {
                if (!this.checklistRef) return false;
                return this.checklistRef.isLoading;
            }
        },
        mounted() {
            if (this.$refs.dealChecklist)
                this.checklistRef = this.$refs.dealChecklist;
        },
        methods: {
            close() {
                $modal.close();
            },
        },
        components: {
            PanelDealChecklist
        }
    };
</script>
<style>
    .checklist-modal > .panel-deal-checklist.panel {
        border: unset;
    }

        .checklist-modal > .panel-deal-checklist.panel .panel-header {
            height: unset;
            padding: 15px;
            color: unset;
            border-bottom: 1px solid var(--main-color);
            border-radius: 5px 5px 0 0;
            font-size: 16pt;
            font-weight: bold;
            background-color: var(--modal-background-color);
        }

        .checklist-modal > .panel-deal-checklist.panel .panel-body {
            padding: 15px;
            overflow-y: auto;
        }
</style>
