<template>
    <Card>
        <div class="card-header">
            <h3>{{quote.carrierName.toUpperCase()}}</h3>
        </div>

        <img v-if="!cardImageError" class="card-image" :src="`/static/img/${toSnakeCase(quote.carrierName)}.png`" :alt="`${quote.carrierName} Logo`" @error="handleCardImageError">
        <span v-else class="default-insurance-icon">
            <span class="fa-stack fa-2x">
                <i class="fas fa-shield-alt fa-stack-2x" />
                <i class="fas fa-check fa-stack-1x fa-inverse" />
            </span>
            <i class="fas fa-hand-holding fa-3x" />
        </span>

        <div class="card-large-text-wrapper">
            <span class="title">{{centsFormatted(quote.effectiveMonthly)}}</span>
            <span class="subtitle">per month</span>
        </div>

        <button class="card-button" :disabled="buttonDisabled" @click="buttonAction()">
            Send Quote Link
        </button>
    </Card>
</template>
<script lang="ts">
    import { defineComponent, PropType } from 'vue'
    import { AutoCompleteQuote } from '@core/classes/Insurance/AutoCompleteModels';
    import Card from '@core/components/Card.vue';

    export default defineComponent({
        name: 'CardInsurance',
        components: {
            Card
        },
        props: {
            quote: Object as PropType<AutoCompleteQuote>,
            buttonDisabled: Boolean,
            buttonAction: Function
        },
        data() {
            return {
                cardImageError: false,
             };
        },
        methods: {
            handleCardImageError() {
                this.cardImageError = true;
            },
            toSnakeCase(str: string): string {
                return str.toLowerCase().replace(' ', '_');
            },
            centsFormatted(priceCents: number): string {
                return `$${Number(priceCents / 100).toFixed(2)}`;
            },
        },
    });
</script>
<style scoped>
    .card-wrapper .card-header > * {
        text-align: center;
        margin: 0;
    }

    .card-wrapper img {
        width: 150px;
        height: 80px;
        margin: auto;
    }

    .card-wrapper .card-large-text-wrapper {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

        .card-wrapper .card-large-text-wrapper .title {
            margin: 0;
            font-weight: bolder;
            font-size: 35px;
        }

        .card-wrapper .card-large-text-wrapper .subtitle {
            font-size: 12px;
        }

    .card-wrapper .card-button {
        max-width: 200px;
    }

    .card-wrapper .default-insurance-icon {
        position: relative;
        margin: auto;
        padding-bottom: 25px;
        color: var(--main-color);
    }

    .card-wrapper .default-insurance-icon .fa-check {
            text-shadow: 1px 1px 1px var(--main-color),
                         -1px -1px 1px var(--main-color),
                         1px -1px 1px var(--main-color);
        }

        .card-wrapper .default-insurance-icon .fa-hand-holding {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
</style>