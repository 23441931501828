<template>
    <div class="checklist-modal modal-container modal-x-large">
        <PanelDealChecklist v-if="hasChecklistItems"
            :fimenu="fimenu"
            @loading="(newValue) => isChecklistLoading = newValue"
            @checklistUpdate="(newValue) => checklistItems = newValue"
        />

        <div v-else>
            <div class="modal-header">
                Deal Checklist
            </div>

            <div class="modal-body">
                <p>No pending items.</p>
            </div>
        </div>

        <div class="modal-footer">
            <button class="button-unwind" :disabled="!isDevelopmentEnv && isChecklistLoading" @click="close()">
                Close
            </button>
        </div>
    </div>
</template>

<script lang="ts">
    export interface IModalDealChecklistProps {
        modal: {
            passedData: {
                fimenu: FIMenu,
            }
        }
    }
</script>
<script setup lang="ts">
    import { computed, ref } from 'vue';
    import settings from 'settings';

    import $modal from '@core/services/modal';
    import DealChecklistItem from '@core/classes/Checklist/DealChecklistItem';
    import FIMenu from '@core/classes/FIMenu';
    import PanelDealChecklist from '@/components/PanelDealChecklist.vue';

    const props = defineProps<IModalDealChecklistProps>();

    const isChecklistLoading = ref(true);
    const checklistItems = ref<DealChecklistItem[]>([]);

    const fimenu = computed((): FIMenu => {
        return props.modal.passedData.fimenu;
    });

    const isDevelopmentEnv = computed((): boolean => {
        return settings.environmentName === "DEVELOPMENT";
    });

    const hasChecklistItems = computed((): boolean => {
        if (isChecklistLoading.value) return true;
        return checklistItems.value.length > 0;
    });

    const close = () => {
        $modal.close();
    };
</script>
<style>
    .checklist-modal > .panel-deal-checklist.panel {
        border: unset;
    }

        .checklist-modal > .panel-deal-checklist.panel .panel-header {
            height: unset;
            padding: 15px;
            color: unset;
            border-bottom: 1px solid var(--main-color);
            border-radius: 5px 5px 0 0;
            font-size: 16pt;
            font-weight: bold;
            background-color: var(--modal-background-color);
        }

        .checklist-modal > .panel-deal-checklist.panel .panel-body {
            padding: 15px;
            overflow-y: auto;
        }
</style>
