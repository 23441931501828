<template>
    <div class="modal-container modal-large">
        <div class="modal-header">
            Paperwork
        </div>
        <div class="modal-body">
            <div class="paperwork-info-top">
                <div :style="$grid('3-1-3-1')" class="paperwork-info-line">
                    <span class="headerinfo">Customer Name</span>
                    <span class="headerinfo">VIN</span>
                    <span class="headerinfo">STORE</span>
                    <span class="headerinfo">Deal #</span>
                    <span>{{ modal.passedData.fimenu.customer.fullName }}</span>
                    <span>{{ modal.passedData.fimenu.vin }}</span>
                    <span>{{ modal.passedData.fimenu.store.storeName }}</span>
                    <span>{{ modal.passedData.fimenu.dealNumber }}</span>
                </div>
            </div>
            <TabMenu :tabs="tabs" :initialTab="initialTab">
                <template v-slot:tab-head-documents>
                    Documents
                </template>
                <template v-slot:tab-panel-documents>
                    <div>
                        <DocumentPacket :packet="packet" :showTitle="true" :showButtons="true" :fimenu="modal.passedData.fimenu" :setVoidedPacket="setVoidedPacket" :modalStatus="modalStatus" ref="documentPacket"></DocumentPacket>
                    </div>
                </template>
                <template v-slot:tab-head-alldocs>
                    Archive
                </template>
                <template v-slot:tab-panel-alldocs>
                    <div class="folder-list">
                        <DocumentPacketList :packets="packetList" :fimenu="modal.passedData.fimenu"></DocumentPacketList>
                    </div>
                </template>
                <template v-slot:tab-head-files>
                    Files
                </template>
                <template v-slot:tab-panel-files>
                    <div class="folder-list">
                        <DirectoryItem :item="documents.data" :getContents="loadContents" :rootName="modal.passedData.rootName"></DirectoryItem>
                    </div>
                </template>
            </TabMenu>
        </div>
        <div class="modal-footer">
            <button class="button-unwind" @click="close()" :disabled="modalStatus.modalBusy || modal.passedData.fimenu.isFinalizingPaperwork">Close</button>
        </div>
    </div>
</template>


<script>
    import $modal from '@core/services/modal'
    import TabMenu from '@/components/TabMenu.vue'
    import api from '@core/services/api'
    import DirectoryItem from '@/components/DirectoryItem.vue'
    import DocumentPacket from '@/components/DocumentPacket.vue'
    import DocumentPacketList from '@/components/DocumentPacketList.vue'
    import util from '@core/services/util'

    export default {
        name: "modalPaperworkErrors",
        props: ['modal'],
        data() {
            return {
                voidedPacket: null,
                packet: null,
                packetList: null,
                headers: null,
                voidedDocuments: null,
                voidedHeaders: null,
                tabs: ['documents', 'alldocs', 'files'],
                initialTab: 'documents',
                documents: {
                    packetId: null,
                    path: null,
                    data: null,
                    isError: false,
                    isBusy: false
                },
                modalStatus: {
                    modalBusy: false
                },
            };
        },
        computed: {
            api() {
                return api;
            }
        },
        created() {
            this.init();

            this.documents.packetId = this.modal.passedData.fimenu.id;
            this.documents.path = this.documents.packetId;

            this.loadPaperworkDirectory(this.documents.path);

            this.EventBus.on('paperworkReset', this.init);
        },
        beforeUnmount(){
            this.EventBus.off('paperworkReset', this.init);
        },
        methods: {
            setVoidedPacket() {
                this.voidedPacket = true
            },
            init() {
                if (this.modal.passedData.fimenu.paperwork && this.modal.passedData.fimenu.paperwork.packets)
                    this.packetList = this.modal.passedData.fimenu.paperwork.packets;
                else
                    this.packetList = null;

                if (this.packetList)
                    this.packet = this.packetList.find(p => p.isCurrent);
                else
                    this.packet = null;

                if (util.isNull(this.packet) && this.voidedPacket) {
                    this.packet = this.modal.passedData.fimenu.paperwork.packets[this.modal.passedData.fimenu.paperwork.packets.length - 1]
                }
            },
            close() {
                $modal.close();
            },
            loadContents(item) {
                if (item.isDirectory)
                    return api.fimenu.getPaperworkDirectory(item.path)
                else
                    return api.fimenu.downloadDocument(item.path, item.name);
            },
            loadPaperworkDirectory(path) {
                this.documents.isBusy = true;
                this.documents.isError = false;
                return api.fimenu.getPaperworkDirectory(path)
                    .then(response => {
                        //console.log('getPaperworkDirectory', response)
                        if (response && response.data) {
                            response.data.contents.forEach(content => {
                                content.name = this.formatTimestamp(content.name)
                            })
                            this.documents.data = response.data;
                        }
                    })
                    .catch(error => {
                        this.documents.isError = true;
                    })
                    .finally(() => {
                        this.documents.isBusy = false;
                    });
            },
            formatTimestamp(timestamp) {
                let formatedDate = timestamp.split("_")[0];
                formatedDate = formatedDate.substring(4, 6) + '/' + formatedDate.substring(6, 8) + '/' + formatedDate.substring(0, 4)
                let formatedTime = timestamp.split("_")[1];
                formatedTime = formatedTime.substring(0, 2) + ':' + formatedTime.substring(2, 4) + ':' + formatedTime.substring(4, 6)

                const time = new Date(formatedDate + "-" + formatedTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })

                return formatedDate + " @ " + time
            }
        },
        components: {
            TabMenu,
            DirectoryItem,
            DocumentPacket,
            DocumentPacketList
        }
    };
</script>
<style>
    .paperwork-info-top {
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        font-size: 14px;
    }

    .headerinfo {
        font-weight: bold;
    }
</style>
