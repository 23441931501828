<template>
    <Panel v-bind="{title: 'Lender Applications'}" class="panel-routeOneDealerTrack">
        <IsBusyScreenComponent v-if="isLoading" />
        <div :style="$grid('1')">
            <div :style="$grid(canSubmitCreditApplication? '5-1' : '1')" class="credit-application-lender-wrapper no-margin-grid">
                <InputRichDropdown label="Select a lender"
                                    lazyLoad
                                   :list="sortedLenderList"
                                   :valueMap="valuePredicate"
                                   :display="displayPredicate"
                                   :desc="descriptionPredicate"
                                   v-model:saturn="dropdownSelectedLender"
                                   :sortMode="ENUMS.DROPDOWN_SORT_MODE.ORIGINAL_ORDER"
                                   :invalid="!fimenu.dealType"
                                   :disabled="!fimenu.dealType"
                                   search
                                   class="ignore-all-locks"
                                   @change="handleLenderChange"
                >
                    <template #validation>
                        <span>You must select a deal type before selecting a lender.</span>
                    </template>
                </InputRichDropdown>

                <button v-if="canSubmitCreditApplication" :disabled="!dropdownSelectedLender" class="button-block" @click="openSendModal">Send</button>
            </div>
            <div style="height: 15px; color:var(--error-color); font-weight: bold; text-align: center">

                <PanelCreditApplicationErrorMessage
                    :canSubmitCreditApplication
                    :creditApplications
                    :fimenu-lender-code="fimenu.lender?.lenderCode"
                    :selected-lender="dropdownSelectedLender ?? null"
                />

            </div>
            <RichTable v-if="canSubmitCreditApplication || (fimenu.dealJacket.applications.length &&!dealLenderInformation) || hasSelectedApplication()"
                       :hidePaging="true"
                       :hideHeader="true"
                       :tableData="creditApplications"
                       :headers="header"
                       :paging="true">
                <template #checkbox="row">
                    <InputCheckbox :useSquare="true"
                                   :isControlled="true"
                                   :modelValue="row.selected"
                                   cssClass="button-nothing button-nothing-green"
                                   @click="() => richTableItemSelected(row)"
                                   :invalid="!fimenu.dealJacket.applications.some(x => x.selected)"
                                    :disabled="row.lender.lenderCode == null"
                                   >
                   
                </InputCheckbox>
                </template>

                <template #creditAppData="row">
                    <div style="display: flex; flex-direction: column; gap: 1px">
                        <span style="font-size: 14px; font-weight: 600">{{ lenderName(row) }}</span>
                        <span style="font-size: 12px;">{{ CreditApplicationType[row.type] }}</span>
                        <span style="font-size: 12px;">{{ row.dateCreated ? util.toMoment(row.dateCreated).format('MM/DD/YYYY hh:mma') : "" }}</span>
                    </div>
                </template>
            </RichTable>
            <LenderInformation v-else-if="reserveProfitCalcs && reserveProfitCalcs.length && dealLenderInformation && !canSubmitCreditApplication"
                :deal="fimenu"
                :selectedLender="dealLenderInformation"
                :validation="validation"
                :lendersList="lendersList"
                :reserveProfitCalcs="reserveProfitCalcs"
            />

        </div>
    </Panel>
</template>

<script lang="ts">
    import { BookValue, DealType } from '@core/classes/SharedEnums'
    import {CreditApplicationDecision, CreditApplicationType} from '@core/classes/CreditApplication'
    import { defineComponent, PropType } from 'vue'
    import $modal from '@core/services/modal'
    import _ from 'underscore'
    import api from '@core/services/api'
    import CreditApplication from '@core/classes/CreditApplication'
    import {CreditApplicationsEnabled} from '@/helpers/finance-channels-helper'
    import ENUMS from "@core/classes/Enums"
    import FIDealJacketAdditionalInformation from '@core/classes/FIDealJacketAdditionalInformation';
    import FIMenu from '@core/classes/FIMenu'
    import FIMenuLender from '@core/classes/FIMenuLender'
    import InputCheckbox from "@core/components/InputCheckbox.vue"
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import IReserveProfitCalc from '@core/classes/IReserveProfitCalc'
    import IsBusyScreenComponent from '@core/components/IsBusyScreenComponent.vue'
    import LenderInformation from '@/components/LenderInformation.vue'
    import modalRouteOneSend from '@/modals/modalRouteOneSend.vue'
    import modalRouteOneView from '@/modals/modalRouteOneView.vue'
    import Panel from '@core/components/Panel.vue'
    import PanelCreditApplicationErrorMessage from './PanelCreditApplicationErrorMessage.vue'
    import PartnerCode from '@core/classes/Store/PartnerCode'
    import RichTable from '@core/components/RichTable.vue'
    import SaturnResponse from '@core/classes/Responses/SaturnResponse'
    import util from '@core/services/util'
    import VersionsLender from '@core/classes/LenderVersionClass'

    export default defineComponent( {
        name: "PanelCreditApplications",
        //['fimenu', 'data', 'panelObject', 'validation'],
        props: {
            fimenu: {
                type: Object as PropType<FIMenu | null>
            },
            validation: {
                type: Object as PropType<any>
            },
        },
        data() {
            return {
                buttonDisabled: true,
                dropdownSelectedLender: null as VersionsLender,
                dealerTrackEnabled: false,
                lendersList: [] as VersionsLender[],
                header: [
                    {
                        name: 'selected', display: "", cssClass: "action-column1", slot: "checkbox"
                    },
                    {
                        name: 'data', display: "", slot: "creditAppData"
                    }
                    //    {name:'selected',display:"Selected"},
                ] as any[],
                creditAppFieldsResponse: null as any,
                r1DealerId: null  as null | string,
                r1PartnerId: null as null | string,
                associatedFinanceSources: [] as any[],
                isLoading: false,
                reserveProfitCalcs: [] as IReserveProfitCalc[],

            }
        },
        computed: {
            ENUMS(): any {
                return ENUMS;
            },
            canSubmitCreditApplication(): boolean {
                const r1Code = this.dropdownSelectedLender?.getLenderByDealType(this.fimenu.dealType)?.getR1Code();
                if (r1Code == null) return false;

                return this.associatedFinanceSources.includes(r1Code) ;
            },
            CreditApplicationType(): typeof CreditApplicationType{
                return CreditApplicationType;
            },
            util(): any {
                return util;
            },
            sortedLenderList(): VersionsLender[] {
                return [...this.filteredLenderList].sort((l1, l2) => {
                    // Get the lenderExpressionVersion for both lenders
                    const version1 = l1.versions[0].getLenderByDealType(this.fimenu.dealType);
                    const version2 = l2.versions[0].getLenderByDealType(this.fimenu.dealType);

                    // Get R1Codes
                    const r1Code1 = version1?.getR1Code();
                    const r1Code2 = version2?.getR1Code();

                    // Handle cases where both R1Codes are null
                    if (r1Code1 === null && r1Code2 === null) {
                        return l1.lenderName.localeCompare(l2.lenderName); // Fallback to lenderName sorting
                    }

                    // Handle cases where only one R1Code is null
                    if (r1Code1 === null) return 1; // Put l1 after l2
                    if (r1Code2 === null) return -1; // Put l1 before l2

                    // Handle cases where R1Codes match the associated finance sources
                    const isAssociated1 = this.associatedFinanceSources.includes(r1Code1);
                    const isAssociated2 = this.associatedFinanceSources.includes(r1Code2);

                    if (isAssociated1 && !isAssociated2) return -1; // Place associated lender first
                    if (!isAssociated1 && isAssociated2) return 1; // Place associated lender second

                    // If both or neither are associated, fallback to lenderName sorting
                    return l1.lenderName.localeCompare(l2.lenderName);
                });
            },
            filteredLenderList() : VersionsLender[] {
                return this.lendersList.filter(x =>{
                    const lenderByDealType = x.getLenderByDealType(this.dealType);

                    // Add the lenders that have R1 code setup for this store, regardless of the divisions.
                    if(this.associatedFinanceSources.includes(lenderByDealType.getR1Code())){
                        return true;
                    }

                    // Filter lenders without division.
                    // We should use getDefaultDivision when we use proper division types.
                    if (lenderByDealType.divisions.length <= 0) return false;
                    if (x.lenderCode == "UNLISTED") return false;

                    return true;
                });
            },
            creditApplications(): CreditApplication[] {
                const returnvalue = this.fimenu.dealJacket.applications.filter((ca: CreditApplication) => ca.MatchesDealType(this.fimenu.dealType));
                return _.sortBy(returnvalue, (r:CreditApplication) => r.dateCreated).reverse();
            },
            dealType(): DealType {
                return this.fimenu.isFinance() ? DealType.Retail : this.fimenu.isLease() ? DealType.Lease : DealType.Draft
            },
            displayRouteOneImage(): boolean {
                return true
            },
            dealLenderInformation(): VersionsLender {
                return this.filteredLenderList.find(x => x.lenderCode == this.fimenu.lender.lenderCode);
            },
        },
        created() {
            this.header.push({
                name: 'action', display: "", sortable: false, cssClass: "open-credit-app", actions:
                [
                    { name: 'error', type: (c: any) => c.errorResponse != null ? "button" : null, icon: 'fa-exclamation-triangle', cssClass: 'button button-nothing clickable button-nothing-red ignore-all-locks', action: (row: any) => { this.openViewModal(row) } },
                    { name: 'approved', type: (c: any) => c.errorResponse == null && c.status == CreditApplicationDecision.Approved ? "button" : null, icon: 'fa-thumbs-up', cssClass: 'button button-nothing clickable button-nothing-green ignore-all-locks', action: (row: any) => { this.openViewModal(row) } },
                    { name: 'denied', type: (c: any) => c.errorResponse == null && c.status == CreditApplicationDecision.Denied ? "button" : null, icon: 'fa-thumbs-down', cssClass: 'button button-nothing clickable button-nothing-red ignore-all-locks', action: (row: any) => { this.openViewModal(row) } },
                    { name: 'conditional', type: (c: any) => c.errorResponse == null && c.status == CreditApplicationDecision.Condition ? "button" : null, icon: 'fa-exchange-alt', cssClass: 'button button-nothing clickable button-nothing-orange ignore-all-locks', action: (row: any) => { this.openViewModal(row) } },
                    { name: 'preliminary', type: (c: any) => c.errorResponse == null && c.status == CreditApplicationDecision.Preliminary ? "button" : null, icon: 'fas fa-spinner fa-spin', cssClass: 'button button-nothing clickable button-nothing-orange ignore-all-locks', action: (row: any) => { this.openViewModal(row) } },
                    { name: 'withdrawn', type: (c: any) => c.errorResponse == null && c.status == CreditApplicationDecision.Withdrawn ? "button" : null, icon: 'fa-trash', cssClass: 'button button-nothing clickable button-nothing-red ignore-all-locks', action: (row: any) => { this.openViewModal(row) } },
                    { name: 'booked', type: (c: any) => c.errorResponse == null && c.status == CreditApplicationDecision.Booked ? "button" : null, icon: 'fa-solid fa-coins', cssClass: 'button button-nothing clickable button-nothing-green ignore-all-locks', action: (row: any) => { this.openViewModal(row) } },
                    { name: 'waiting', type: (c: any) => c.errorResponse == null && (c.status == null || c.status == CreditApplicationDecision.Pending) ? "button" : null, icon: 'fas fa-spinner fa-spin', cssClass: 'button button-nothing button-nothing-red ignore-all-locks', action: (row: any) => { this.openViewModal(row) } },
                    { name: 'new', type: (c: any) => c.errorResponse == null && (c.status == null || c.status == CreditApplicationDecision.New) ? "button" : null, icon: 'fas fa-spinner fa-spin', cssClass: 'button button-nothing clickable button-nothing-red ignore-all-locks', action: (row: any) => { this.openViewModal(row) } },
                    { name: 'contract_received', type: (c: any) => c.errorResponse == null && (c.status == null || c.status == CreditApplicationDecision.ContractRecevied) ? "button" : null, icon: 'fa-solid fa-file-contract', cssClass: 'button button-nothing clickable button-nothing-green ignore-all-locks', action: (row: any) => { this.openViewModal(row) } },
                ]
            })

            this.r1DealerId = this.fimenu.store.partnerCodes.find((p: PartnerCode) => p.type === ENUMS.PARTNER_CODES.ROUTEONE_DEALER_ID)?.code;
            this.r1PartnerId = this.fimenu.store.partnerCodes.find((p: PartnerCode) => p.type === ENUMS.PARTNER_CODES.ROUTEONE_PARTNER_ID)?.code;
            this.getRouteOneLenders();
            this.getDealerTrackLenders();
            this.getLatestCreditAppFieldsResponse();
            // Get finance sources that allow AutoSubmit
            const dealersThatAllowAutosubmit = this.creditAppFieldsResponse?.financeSources ?? []
            .filter((source: any) =>
                source.services.some((service: any) => service.name === "AutoSubmit")
            )
            .map((source: any) => source.fsId);

            // Find the dealer that matches the current routeOneDealerId
            const dealer = this.creditAppFieldsResponse?.dealers.find((d: any) => d.routeOneDealerId === this.r1DealerId);

            // Get associated finance sources that are allowed for AutoSubmit
            if (dealer) {
                this.associatedFinanceSources = dealer.associatedFinanceSources
                    .filter((fsId: string) => {
                        return dealersThatAllowAutosubmit.some((obj: any) => obj.fsId == fsId);
                    });
            }

            if (!this.hasSelectedApplication())
                this.dropdownSelectedLender = this.lendersList.find(x => x.lenderCode == this.fimenu.lender.lenderCode);

            this.fimenu.loadLatestestCreditDecision();
            this.getReserveProfitCalcs();
        },
        mounted() {

        },
        methods: {
            async getReserveProfitCalcs(){
                const { data } = await api.settings.getGetReserveProfitCalcs();
                if (data && Array.isArray(data)) this.reserveProfitCalcs = data;
                else console.error("Couldn't get reserve profit calcs. Wrong Data?")
            },
            lenderName(ca: CreditApplication) {
                return ca.lender.lenderName ?? "Unknown lender"
            },
            async openSendModal() {
                if (await this.fimenu.checkForFraud()) return

                $modal.open(modalRouteOneSend, { name: 'modalRouteOneSend', passedData: { fimenu: this.fimenu, lender: this.dropdownSelectedLender }, backdrop: false, postFunction: (bookValue: BookValue) => this.send(this.dropdownSelectedLender, bookValue) });
            },
            openViewModal(ca: CreditApplication) {
                if (ca.status == null || ca.status == CreditApplicationDecision.Pending) return

                $modal.open(modalRouteOneView, { name: 'modalRouteOneView', passedData: { row: ca, fimenu: this.fimenu, }, backdrop: false });
            },
            async fetchDealFromQuote(dealId: string) {
                try {
                    this.isLoading = true;
                    const response = await api.fimenu.getDeal(dealId);

                    if (response.data) {
                        this.fimenu.copyFromQuote(response.data.fimenu);
                    }

                } finally {
                    this.isLoading = false;
                }
            },
            hasSelectedApplication() : boolean {
                return !!this.fimenu?.dealJacket?.applications?.some(x => x.selected);
            },
            async richTableItemSelected(selectedData: Partial<CreditApplication>) {
                this.fimenu.dealJacket.providerId = selectedData.dealJacketId;

                this.fimenu.dealJacket.applications.forEach(c => {
                    c.selected = c.id === selectedData.id;
                });

                const selectedLender = this.lendersList.find(x => x.lenderCode == selectedData.lender.lenderCode)
                const selectedLenderInformationForTheDeal = selectedLender.versions[0].getLenderByDealType(this.fimenu.dealType);

                this.fimenu.lender = new FIMenuLender({
                    divisions: selectedLenderInformationForTheDeal.divisions,
                    lenderCode: selectedLender.lenderCode,
                    lenderContacts: selectedLenderInformationForTheDeal.lenderContacts,
                    lenderName: selectedLender.lenderName,
                });

                this.fimenu.dealJacket.purchaseVehicleBookValue = selectedData.vehicleBookValue;

                //// This will preload the current deal with the quote deal information.
                //await this.fetchDealFromQuote(selectedData.dealId);
            },
            handleLenderChange(lender: VersionsLender) {
                // If we selected a lender that is not available for submission.
                if (!this.canSubmitCreditApplication) {
                    this.updateLenderOnFIMenu(lender);   // Update FIMenu as it goes without credit applications
                    this.setAllPaperworkToMustRepopulate()  // Since the lender changed. IF we have paperwork; they must repopulate.
                    this.setMaxDaysToFirstPayment(lender) // Set the days for max payment.
                    this.creditApplications.forEach(x => x.selected = false)// Clear credit applications selection.
                    this.saveDealIfNeeded() // Will save the deal if required.

                } else { // If we select a lender that is available for submission
                    this.fimenu.lender = new FIMenuLender(); // Clear FIMenuLender.
                    this.creditApplications.forEach(x => x.selected = false)// Clear credit applications selection.
                }
            },
            updateLenderOnFIMenu(lender: VersionsLender) {
                // Copy from PanelLEnder.
                const lenderVersion = lender.getLenderByDealType(this.dealType); // Get the version of the lender. Either Retail, Lease or Cash.
                const selectedLenderIsDifferent = lender.lenderCode !== this.fimenu.lender.lenderCode; // wether the lender is the same.

                if (selectedLenderIsDifferent) {
                    // Create FImenuLender and assign it to the deal.
                    this.fimenu.lender =  new FIMenuLender({
                        divisions: lenderVersion.divisions,
                        lenderContacts: lenderVersion.lenderContacts,
                        lenderCode: lender.lenderCode,
                        lenderName: lender.lenderName,
                    })
                }
            },
            saveDealIfNeeded() {
                const isLenderInvalid = util.isNull(this.validation) ? false : this.validation.lender.lenderCode.$invalid;

                if (!isLenderInvalid && this.fimenu.isDealLocked()) {
                    this.fimenu.save();
                }
            },
            setMaxDaysToFirstPayment(lender: VersionsLender){
                const lenderVersion = lender.getLenderByDealType(this.dealType);

                if (lenderVersion && "maxDaysToFirstPayment" in lenderVersion && lenderVersion.maxDaysToFirstPayment) {
                    this.fimenu.loanTerms.maxDaysToFirstPayment = lenderVersion.maxDaysToFirstPayment
                } else this.fimenu.loanTerms.maxDaysToFirstPayment = 45
            },
            setAllPaperworkToMustRepopulate() {
                //set all current packet document to must repopulate
                if (this.fimenu.hasPaperworkPacket()) {
                    this.fimenu.paperwork.currentPacket().documents.forEach((d: any) => {
                        d.mustRepopulate = true
                    })
                }
            },
            async getDealerTrackLenders() {
                //future functionality
            },
            getRouteOneLenders() {
                //get the list of lenders we have for RouteOne
                this.lendersList = (this.$global as any).Lenders ?? []
            },
            getLatestCreditAppFieldsResponse() {
                //FIXME: Add property to $global object
                if(!((this.$global as any).r1CreditAppField)) return;
                //FIXME: Add property to $global object
                this.creditAppFieldsResponse = util.clone((this.$global as any).r1CreditAppField);
            },
            async send(lender: VersionsLender, bookValue: BookValue) {
                // You could still see this component if you have previous credit applications. But cannot send new one if we
                // dont have credentials or is disabled.
                if (!CreditApplicationsEnabled(this.fimenu))
                    return util.toastr("error", "Error", "Cannot send credit applications. Currently disabled.")

                this.isLoading = true;
                try {

                    const additionalInformation = new FIDealJacketAdditionalInformation({
                        selectedBookValue: bookValue
                    })
                    const rawResponse =  await api.financeChannels.sendCreditApplication(this.fimenu, lender, additionalInformation)
                    const response = new SaturnResponse<CreditApplication>(rawResponse.data);

                    if (response?.statusCode < 400) {
                        const creditApp = new CreditApplication(response.payload);
                        this.fimenu.dealJacket.applications.push(creditApp);
                        this.fimenu.dealJacket.providerId  = creditApp.dealJacketId;

                        util.toastr("success", "Credit Application", response.messages.pop() ?? "Submitted successfully.")
                        this.dropdownSelectedLender = null;
                    } else {
                        util.toastr("error", "Credit Application", response.messages.pop() ?? "There was an error submitting your credit application")
                        console.error(JSON.stringify(response))
                    }
                }catch(err) {
                    console.error(err)
                } finally {
                    this.isLoading = false;
                }
            },
            valuePredicate(l: VersionsLender) {
                return l
            },
            comparerPredicate(a: VersionsLender, b: VersionsLender) {
                return a && b && a.lenderCode === b.lenderCode
            },
            displayPredicate(l: VersionsLender) {
                return  l.lenderName
            },
            descriptionPredicate(l: VersionsLender) {
                const version = l.getLenderByDealType(this.fimenu.dealType);

                // Get R1Codes
                const hasR1Code = !!version?.getR1Code();


                if (this.associatedFinanceSources.includes(version.getR1Code())) return "R1"

                return null
            }
        },
        components: {
            Panel: Panel,
            RichTable: RichTable,
            InputRichDropdown,
            InputCheckbox,
            IsBusyScreenComponent,
            LenderInformation,
            PanelCreditApplicationErrorMessage
            //InputCurrency: InputCurrency,
            //InputDate: InputDate,
            //InputRadio: InputRadio
        }
    });
</script>
<style>

    .panel-routeOneDealerTrack {
        height: 100%;
    }
    .panel-routeOneDealerTrack .action-column1 {
        width: 28px;
    }

    .panel-routeOneDealerTrack .open-credit-app {
        width: 43px;
    }

    .credit-application-lender-wrapper {
        align-items: center;
    }

    .panel-routeOneDealerTrack .rich-table-rows .action-cell button {
        width: 40px;
        height: 40px;
        justify-content: space-between;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .lender-selector {
        font-size: 25px;
    }

    .input-checkbox-container {
        display: block;
        max-width: 28px;
    }
</style>