<template>
	<Panel title="Vehicle Registration Information" class="PanelVehicleRegistration">
		<div class="two-sided">
			<InputRichDropdown
				label="Vehicle Delivery Method"
				v-model:saturn="registrationInfo.deliveryMethod"
				:list="getDeliveryMethodsToList()"
				:valueMap="predicateValues"
				:display="predicateDisplays"
				nothingSelected="-- Select Value --"
				:invalid="v.deliveryMethod.$invalid">
				<template #validation>
					<div v-if="v.deliveryMethod.required.$invalid">Delivery Method is required</div>
				</template>
			</InputRichDropdown>
			<InputRichDropdown
				label="Primary Use"
				v-model:saturn="registrationInfo.primaryUsage"
				:list="VehiclePrimaryUsageEnumToList()"
				:valueMap="predicateValues"
				:display="predicateDisplays"
				:invalid="v.primaryUsage.$invalid"
				nothingSelected="-- Select Value --">
				<template #validation>
					<div v-if="v.primaryUsage.required.$invalid">Primary usage is required</div>
				</template>
			</InputRichDropdown>
		</div>
		<div :style="$grid('8-9-10')" class="no-margin-grid">
			<div class="radio-button-inline" :invalid="v.isPlateTransfer.$invalid">
				<span>Is the plate being transfered?</span>
				<InputRadio
					label="Yes"
					:valueMatch="true"
					v-model="registrationInfo.isPlateTransfer"
					:isButton="true" />
				<InputRadio
					label="No"
					:valueMatch="false"
					v-model="registrationInfo.isPlateTransfer"
					:isButton="true" />
				<div></div>
			</div>


			<div :style="$grid('3-2')" v-if="plateTransactionsEnabled && registrationInfo.isPlateTransfer" class="no-margin-grid" >
				<SelectVehicle
					:fimenu="fimenu"
					label="Load Plate From Trade-In"
					ref="vehicleSelector"
				/>

				<button @click="handleOpenDDiResponseModal" :disabled="loadingVehicleInquiryModal || v.plateNumber.$invalid" class="ignore-all-locks">
					<i v-if="loadingVehicleInquiryModal" class="fas fa-spinner fa-spin" />
					<span v-else>View Vehicle Inquiry</span>
				</button>
			</div>

		</div>
		<!-- if  transferType === PlateTransactionType.NewTempPlate-->
		<fieldset :style="$grid('1-1-1-1')" class="no-margin-grid" :disabled="!registrationInfo.isPlateTransfer">
			<InputTextbox
				label="Plate Number"
				v-model:saturn="registrationInfo.plateNumber"
				:invalid="v.plateNumber.$invalid"
				placeholder="Plate Number">
				<template #validation>
					<div v-if="v.plateNumber.required.$invalid">Plate Number is required</div>
				</template>
			</InputTextbox>

			<InputRichDropdown
				label="Plate State"
				v-model:saturn="registrationInfo.plateState"
				:list="StateHelper.getAllStates()"
				:invalid="v.plateState.$invalid"
				:valueMap="predicateValues"
				:display="predicateDisplays"
				search>
				<template #validation>
					<div v-if="v.plateState.required.$invalid">Plate State is required</div>
				</template>
			</InputRichDropdown>

			<InputDate
				label="Current Plate Expiration Date"
				v-model:saturn="registrationInfo.currentPlateExpirationDate"
				:invalid="v.currentPlateExpirationDate.$invalid"
				icon="fas fa-calendar">
				<template #validation>
					<div v-if="v.currentPlateExpirationDate.required.$invalid">Current Plate Expiration Date is Required</div>
				</template>
			</InputDate>

			<InputNumber
				label="Registration Duration (months)"
				v-model:saturn="registrationInfo.registrationLengthInMonths"
				:invalid="v.registrationLengthInMonths.$invalid"
				placeholder="Registration Length In Months"
				:precision="0">
				<template #validation>
					<div v-if="v.registrationLengthInMonths.required.$invalid">Registration Length is required</div>
				</template>
			</InputNumber>
		</fieldset>

		<PanelDealAddresses
			:fimenu="fimenu"
			:validation="validation"
			:disabled="fimenu.isDealLocked()"
			class="address-container" />
	</Panel>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue'
	import { GetDeliveryMethodsToList, VehiclePrimaryUsageEnumToList } from '@/helpers/plate-transactions-helper'
	import ENUMS from "@core/classes/Enums"
	import FIMenu from '@core/classes/FIMenu'
	import FIMenuPlateTransactions from '@core/classes/FIMenuPlateTransactions'
	import FIMenuTradeIn from '@core/classes/FIMenuTradeIn'
	import FIMenuVehicleRegistrationInfo from '@core/classes/FIMenuVehicleRegistrationInfo'
	import InputDate from '@core/components/InputDate.vue'
	import InputNumber from '@core/components/InputNumber.vue'
	import InputRadio from '@core/components/InputRadio.vue'
	import InputRichDropdown from '@core/components/InputRichDropdown.vue'
	import InputTextbox from '@core/components/InputTextbox.vue'
	import {openDDiResponseModal} from '@/helpers/plate-transactions-helper'
	import Panel from '@core/components/Panel.vue'
	import PanelDealAddresses from '@/components/PanelDealAddresses.vue'
	import { PlateTransactionType } from '@core/classes/SharedEnums'
	import SelectVehicle from '@/components/SelectVehicle.vue'
	import StateHelper from '@core/classes/StateHelper'
	import util from '@core/services/util'

	type InputRichDropdownRecord = {
		display: string
		value: any
	}
	export default defineComponent({
		name: 'PanelVehicleRegistration',
		props: {
			fimenu: {
				type: Object as PropType<FIMenu>,
			},
			validation: {
				type: Object,
			},
		},
		data() {
			return {
				registrationInfo: this.fimenu.vehicle.registrationInfo as FIMenuVehicleRegistrationInfo,
				StateHelper: StateHelper,
				plateTransactionType: PlateTransactionType,
				allPlateTransactions: this.fimenu.vehicle.registrationInfo
					.plateTransactions as FIMenuPlateTransactions[],
				plateTypes: [
					{ text: 'Regular', value: 1 },
					{ text: 'Commercial', value: 2 },
				],
				transferTypesList: [
					{
						display: 'Generate a new one',
						value: PlateTransactionType.NewTempPlate,
					},
					{
						display: 'Transfer existing one',
						value: PlateTransactionType.Transfer,
					},
					{
						display: 'Nothing',
						value: PlateTransactionType.None,
					},
				] as InputRichDropdownRecord[],
				loadingVehicleInquiryModal: false
			}
		},
		methods: {
			predicateValues(value: any): any {
				return value.value ?? value
			},
			predicateDisplays(value: any): string {
				return value.display
			},
			getDeliveryMethodsToList() {
				return GetDeliveryMethodsToList()
			},
			VehiclePrimaryUsageEnumToList() {
				return VehiclePrimaryUsageEnumToList()
			},
			resetRegistrationInfo() {
				this.registrationInfo.plateNumber = null
				this.registrationInfo.plateState = null
				this.registrationInfo.currentPlateExpirationDate = null
				this.registrationInfo.registrationLengthInMonths = null
			},
			async handleOpenDDiResponseModal() {
				this.loadingVehicleInquiryModal = true;
				await openDDiResponseModal(null, this.fimenu.id, this.registrationInfo.plateNumber, this.registrationInfo.plateState)
				this.loadingVehicleInquiryModal = false;
			}
		},
		computed: {
			util(): typeof util {
				return util
			},
			tradeIns(): FIMenuTradeIn[] {
				return this.fimenu.tradeIns as FIMenuTradeIn[]
			},
			dealHasTradeIns(): boolean {
				return this.tradeIns.length > 0
			},
			vehiclesList(): FIMenuTradeIn[] {
				return this.tradeIns.filter((veh) => veh.plateNumber && veh.plateState)
			},
			ENUMS(): typeof ENUMS {
				return ENUMS
			},
			v(): any {
				return this.validation.vehicle.registrationInfo
			},
			plateTransactionsEnabled(): boolean {
                return !!this.fimenu.plateTransactionsEnabled
            }
		},

		async created() {
			this.$watch(
				() => this.registrationInfo.isPlateTransfer,
				(newVal, oldVal) => {
					if (newVal === false) {
						this.resetRegistrationInfo()
					}
				},
				{ deep: false, immediate: true }
			)
		},
		components: {
			Panel,
			InputTextbox,
			InputDate,
			InputNumber,
			InputRichDropdown,
			PanelDealAddresses,
			InputRadio,
			SelectVehicle,
		},
	})
</script>
<style>
	.PanelVehicleRegistration .panel-body {
		display: flex;
		flex-direction: column;
		gap: 3%;
		height: 100%;
		padding-bottom: 30px;
	}

	.PanelVehicleRegistration .two-sided,
	.PanelVehicleRegistration .questions-container {
		display: grid;
		place-items: center;
		column-gap: 10px;
		row-gap: 15%;
	}

	.PanelVehicleRegistration .two-sided {
		grid-template-columns: 1fr 1fr;
	}

	.PanelVehicleRegistration .questions-container {
		grid-template-columns: 1fr 1fr 1fr;
		height: auto;
	}

	.PanelVehicleRegistration .two-sided > *,
	.PanelVehicleRegistration .questions-container > * {
		width: 100%;
	}

	.PanelVehicleRegistration .address-container {
		margin-top: 10px;
	}

	.PanelVehicleRegistration .radio-button-inline {
		min-height: 40px;
	}
</style>
