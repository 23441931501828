<template>
    <div class="nav" :class="{active: navOpen}" @mouseover="toggleMenu(true)" @mouseleave="toggleMenu(false)" @click="toggleMenu(false)">
        <!--<div class="menu-item" v-if="user.EmployeeAccess.IsAdmin || user.EmployeeAccess.IsSalesManager">
            <router-link :to="{name: 'sales'}">
                <span>
                    <i class="fas fa-car-side" title="Sales"></i>
                </span>
                Sales Log
            </router-link>
        </div>-->
        <!--<div class="menu-item" v-if="user.EmployeeAccess.IsAdmin || user.EmployeeAccess.IsFIManager">
            <router-link :to="{name: 'fandi'}">
                <span>
                    <i class="fas fa-funnel-dollar" title="F & I Log"></i>
                </span>
                F & I Log
            </router-link>
        </div>-->
        <div class="menu-item" v-if="user.EmployeeAccess.IsAdmin || user.EmployeeAccess.IsAccounting || user.EmployeeAccess.IsDirector || user.EmployeeAccess.IsFIManager || user.EmployeeAccess.IsGeneralManager || user.EmployeeAccess.IsSalesPerson || user.EmployeeAccess.IsSalesManager || user.EmployeeAccess.IsSalesPerson ">
            <router-link :to="{name: 'customers'}">
                <span>
                    <i class="fas fa-users" title="Customers"></i>
                </span>
                Customers
            </router-link>
        </div>
        <div class="menu-item" v-if="user.EmployeeAccess.IsAdmin || user.EmployeeAccess.IsAccounting || user.EmployeeAccess.IsDirector || user.EmployeeAccess.IsFIManager || user.EmployeeAccess.IsGeneralManager || user.EmployeeAccess.IsSalesPerson || user.EmployeeAccess.IsSalesManager">
            <router-link :to="{name: 'fimenudeals'}">
                <span>
                    <i class="fas fa-comments-dollar" title="Deals"></i>
                </span>
                Deals
            </router-link>
        </div>
        <!--<div class="menu-item" v-if="user.EmployeeAccess.IsAdmin || user.EmployeeAccess.IsAccounting">
            <router-link :to="{name: 'accounting'}">
                <span>
                    <i class="fas fa-dollar-sign" title="Accounting"></i>
                </span>
                Accounting
            </router-link>
        </div>
        <div class="menu-item" v-if="user.EmployeeAccess.IsAdmin || user.EmployeeAccess.IsGeneralManager">
            <router-link :to="{name: 'cit'}">
                <span>
                    <i class="fas fa-money-bill-wave" title="CIT Log"></i>
                </span>
                CIT Log
            </router-link>
        </div>-->
        <div class="menu-item" @click="Mibell ? toggleMenu(false) : redirectToCancellationForm()" v-if="user.EmployeeAccess.IsAdmin || user.EmployeeAccess.IsAccounting || user.EmployeeAccess.IsDirector || user.EmployeeAccess.IsFIManager || user.EmployeeAccess.IsGeneralManager || user.EmployeeAccess.IsSalesPerson || user.EmployeeAccess.IsSalesManager">
            <router-link :to="{name: 'cancelCoverage'}">
                <span>
                    <CancelCoverageIcon />
                </span>
                {{ Mibell ? 'Cancel Coverage' : 'New Cancellation' }}
            </router-link>
        </div>
        <div v-if="user.EmployeeAccess.IsAdmin || user.EmployeeAccess.IsServiceManager" class="menu-item">
            <router-link :to="{name: 'service'}">
                <span><i class="fas fa-search-dollar" /></span>
                Service Lookup
            </router-link>
        </div>

        <div class="menu-item" v-if="user.EmployeeAccess.IsAccounting || user.EmployeeAccess.IsAdmin">
            <router-link :to="{name: 'reports'}">
                <span>
                    <i class="fas fa-chart-line" title="Reports"></i>
                </span> Reports
            </router-link>
        </div>
        <div class="menu-item" v-if="user.EmployeeAccess.IsAdmin || user.EmployeeAccess.IsUserManager">
            <router-link :to="{name: 'admin'}">
                <span>
                    <i class="fas fa-cog" title="Settings"></i>
                </span> Settings
            </router-link>
        </div>
        <div :class="{'menu-item': true, 'open': isUtilitiesOpen}" v-if="user.EmployeeAccess.IsAdmin" @mouseover="openUtilities()" @mouseleave="closeUtilities()">
            <router-link :to="{name: 'utilities'}">
                <span>
                    <i class="fas fa-puzzle-piece" title="utilities"></i>
                </span>
                Utilities
            </router-link>
            <!--<ul class="sub-menu">
                <li>
                    <span>Copy from Production</span>
                </li>
                <li>
                    <router-link :to="{name: 'utilities/LogsHistory'}">
                        <span>Logs History</span>
                    </router-link>
                </li>
            </ul>-->
        </div>
        <div class="menu-item sandbox" v-if="user.EmployeeAccess.IsAdmin">
            <router-link :to="{name: 'sandbox'}">
                <span>
                    <i class="fas fa-layer-group" title="Sandbox"></i>
                </span>
                Sandbox
            </router-link>
        </div>
    </div>
</template>


<script>
    import auth from '@core/services/auth';

    import $modal from '@core/services/modal';
    import modalNewCancellation from '@/modals/modalNewCancellation.vue';

    import CancelCoverageIcon from '@/components/CancelCoverageIcon.vue';

    export default {
        name: "TheNav",
        data() {
            return {
                navOpen: false,
                isUtilitiesOpen: false,
                Mibell: true,
            };
        },
        computed: {
            user() {
                return auth.getTokenPayload();
            }
        },
        created() {
            this.EventBus.on('toggleMenu', this.onToggleMenu);
        },
        beforeUnmount(){
            this.EventBus.off('toggleMenu', this.onToggleMenu);
        },
        methods: {
            openUtilities() {
                this.isUtilitiesOpen = true;
            },
            closeUtilities() {
                this.isUtilitiesOpen = false;
            },
            toggleMenu(val) {
                if (val == null) {
                    this.navOpen = !this.navOpen;
                }
                else {
                    this.navOpen = val;
                }
            },
            onToggleMenu(val) {
                this.toggleMenu(val);
            },
            redirectToCancellationForm() {
                $modal.open(modalNewCancellation, { name: 'modalNewCancellation', passedData: { info: [], acceptText: 'Submit', cancelText: 'Cancel' }, backdrop: false, postFunction: this.saveConfirmed });

                this.toggleMenu(false);
                // console.log("Redirected to New Cancellation Form.")
            },
            saveConfirmed() {
                // TODO:
                // eslint-disable-next-line no-console
                console.log("Saved Successfully.");
            }
        },
        components: {
            CancelCoverageIcon,
        }
    };
</script>
<style>
    .conditional-menu-item {
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid var(--border-color);
        color: var(--main-color);
    }

        .conditional-menu-item i {
            margin: 0 25px;
        }

        .conditional-menu-item:hover {
            background-color: var(--main-color);
            color: white;
        }

    .nav .menu-item:hover .times,
    .nav .menu-item:hover .circle-1 {
        color: var(--main-color);
    }

    .nav .cancelCoverageIcon .cancel .times {
        top: calc(50% + 0.5px);
    }
</style>