<template>
    <div class="buyers-order-grid PanelPreloads">
        <div class="no-content" v-if="!hasPreloads">
            No Preload for this Store
        </div>
        <div v-else>
            <div class="buyers-order-row preloads-list" v-for="(preload, index) in fimenu.preloads" :key="index">
                <div class="preload-details" :invalid="util.isNull(validation) ? false : validation.preloads[index].isSelected.$invalid">
                    <p>{{ preload.desc || preloadQuestion(preload.productType) }}</p>
                    <div class="preload-options">
                        <InputRadio label="Yes" :valueMatch="true" v-model="preload.isSelected" :isButton="true" />
                        <InputRadio label="No" :valueMatch="false" v-model="preload.isSelected" :isButton="true" />
                    </div>
                </div>
                <div class="amt">
                    <InputCurrency v-model:saturn="preload.price"
                                   placeholder="Price"
                                   :disabled="preload.isSelected && preload.isEditableAmount ? false : true"
                                   :invalid="util.isNull(validation) ? false : validation.preloads[index].price.$invalid"
                                   :precision="2"
                                   iconSide="left"
                                   @blur="onBlurHandler(preload.price, index)">
                        <template #validation>
                            <div v-if="validation.preloads[index].price.maxValue.$invalid">Value must be a maximum of {{ validation.preloads[index].price.$params.maxValue.max }}</div>
                        </template>
                    </InputCurrency>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import InputCurrency from '@core/components/InputCurrency.vue'
    import InputRadio from '@core/components/InputRadio.vue'

    import ENUMS from "@core/classes/Enums"
    import util from '@core/services/util'

    export default {
        name: "PanelPreloads",
        props: ['fimenu', 'validation', 'preselected'],
        data() {
            return {
            }
        },
        computed: {
            hasPreloads() {
                return this.fimenu.preloads.length > 0;
            },
            util() {
                return util;
            }
        },
        created() {
            if (this.preselected) {
                this.fimenu.preloads.forEach(p => {
                   p.isSelected = true;
                })
            }
        },
        methods: {
            preloadQuestion(preloadType) {
                const preloadText = ENUMS.productTypes.find(pt => pt.code == preloadType).description
                return preloadText;
            },
            onBlurHandler(price, index) {
                if (price == null) {
                    this.fimenu.preloads[index].price = 0
                }
            }
        },
        components: {
            InputCurrency,
            InputRadio
        }
    };
</script>
<style>
    .PanelPreloads .preload-options {
        display: flex;
        gap: 15px;
        align-items: center;
        position: relative;
        left: -10px;
    }

    .PanelPreloads .buyers-order-row.preloads-list {
        display: flex;
    }

    .PanelPreloads .preload-details {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
</style>