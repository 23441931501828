<template>
    <Panel title="Front-End Gross">
        <div>
            <div class="front-end-gross no-margin-grid" :style="$grid('1')">
                <div v-if="!fimenu.buyersOrderEnabled" class="titles no-margin-grid ignore-deal-lock" :style="$grid('5-1-1', '5px 10px')">
                    <span class="boldedspan name">Front-End Profit</span>
                    <span class="boldedspan"></span>
                    <InputCurrency v-model:saturn="data.nonBuyersOrderFinalNumbers.frontEndProfit"
                                   placeholder="Front-End Profit"
                                   :invalid="util.isNull(validation) ? false : validation.nonBuyersOrderFinalNumbers.frontEndProfit.$invalid"
                                   :precision="2"
                                   :negative="true"
                                   iconSide="left"
                                   :disabled="data.hasPaperworkPacket()">
                        <template #validation>
                            <div v-if="!util.isNull(validation) && validation.nonBuyersOrderFinalNumbers.frontEndProfit.required.$invalid">Front End Profit is required</div>
                        </template>
                    </InputCurrency>
                </div>

                <div class="titles no-margin-grid" :style="$grid('7-1-1-1', '5px 10px')">
                    <span class="boldedspan name">Description</span>
                    <span class="boldedspan">Price</span>
                    <span class="boldedspan">Cost</span>
                    <span class="boldedspan">Profit</span>
                </div>

                <hr v-if="items.length > 0" class="divider-line" />

                <div class="items-list" v-for="(item, index) in items" :key="index" :style="$grid('5.5-1.5-1-1-1', '0px 10px')">
                    <span class="name">{{ item.name }}</span>
                    <SwitchButton v-if="item.toggleItemized"
                                  class="ignore-deal-lock"
                                  :disabled="item.disabled || data.hasPaperworkPacket()"
                                  :active="item.itemized"
                                  :keepActiveSynced="true"
                                  leftLabel="Line 1"
                                  rightLabel="Line 3"
                                  @update="item.toggleItemized" />
                    <span v-else></span>
                    <span>{{ util.formatPrice(item.price) }}</span>
                    <span>{{ util.formatPrice(item.cost) }}</span>
                    <span v-if="item.profit >= 0">{{ util.formatPrice(item.profit) }}</span>
                    <span v-else class="error-text">({{ util.formatPrice(item.profit*-1) }})</span>
                </div>

                <hr class="divider-line" />

                <div class="no-margin-grid" :style="$grid('99-1')">
                    <span class="boldedspan name">
                        Total Front-End Profit
                    </span>
                    <span class="displaypricebold">{{ totalProfit }}</span>
                </div>
            </div>
        </div>
    </Panel>

</template>

<script>
    import SwitchButton from '@/components/SwitchButton.vue'
    import Panel from '@core/components/Panel.vue'
    import InputCurrency from '@core/components/InputCurrency.vue'
    import util from '@core/services/util'
    import { PROFIT_FLAGS } from "@core/classes/Enums"

    export default {
        name: "PanelFrontEndGross",
        props: ['data', 'acceptedProducts', 'getProfitProducts', 'panelText', 'hide', 'validation'],
        data() {
            return {
                panelObjectToShow: {},
                fimenu: null
            }
        },
        computed: {
            util() {
                return util;
            },
            frontEndProfitProducts() {
                const productsToInclude = this.acceptedProducts.filter(p => this.data.storeProducts.find(sp => sp.productType === p.productType)?.getPricingDetails(p.productPricingDetailsId)?.profitFlag === PROFIT_FLAGS.FRONT)
                return this.getProfitProducts(productsToInclude)
            },
            items() {
                const items = []

                const bo = this.data.getCurrentBuyersOrder()
                if (bo && this.data.buyersOrderEnabled) {
                    items.push({
                        name: "Selling Price",
                        price: bo.sellingPrice,
                        cost: bo.invoiceAmount,
                        profit: bo.sellingPriceProfit()
                    })

                    bo.getAllItemsForBucketTypeAndProfitFlag("Trades", PROFIT_FLAGS.FRONT).forEach(i => {
                        items.push({
                            name: i.name,
                            price: i.amount,
                            cost: i.cost,
                            profit: i.profit
                        })
                    })

                    bo.getAllItemsForBucketTypeAndProfitFlag("Fees", PROFIT_FLAGS.FRONT).forEach(i => {
                        items.push({
                            name: i.name,
                            price: i.amount,
                            cost: i.cost,
                            profit: i.profit
                        })
                    })

                    bo.getAllItemsForBucketTypeAndProfitFlag("Additions", PROFIT_FLAGS.FRONT).forEach(i => {
                        items.push({
                            name: i.name,
                            price: i.amount,
                            cost: i.cost,
                            profit: i.profit
                        })
                    })
                }

                items.push(...this.frontEndProfitProducts)

                return items.filter(i => i.profit != null)
            },
            totalProfit() {
                const bo = this.data.getCurrentBuyersOrder()
                if (!bo || !this.data.buyersOrderEnabled) return util.formatPrice(this.items.map(i => i.profit).reduce((a, b) => a + b, 0) + this.data.nonBuyersOrderFinalNumbers.frontEndProfit)

                return util.formatPrice((bo.finalNumbers.totalProfitFront ?? bo.getTotalProfitForBucketTypeAndProfitFlag(null, PROFIT_FLAGS.FRONT)) + bo.sellingPriceProfit())
            }
        },
        created() {
            this.loadData()
        },

        methods: {
            loadData() {
                this.fimenu = this.data;
            },

        },
        components: {
            Panel,
            InputCurrency,
            SwitchButton
        }
    };
</script>
<style>
    .front-end-gross .divider-line {
        margin: 0;
        width: 100%;
    }
</style>