<template>
    <div class="modal-container modal-richtable modal-editcoveragetermproduct">
        <div class="modal-header">
            <div>Coverage Term Product Edit</div>
            <div v-if="modal.passedData.showRegulationLabel && !pricedConfig" class="isregulated">
                <span v-if="productDetails.isRegulated" class="regulated">REGULATED</span>
                <span v-else class="notregulated">NOT REGULATED</span>
            </div>
        </div>
        <div class="modal-body">
            <fieldset :disabled="modal.passedData.isDealLocked">
                <div class="product-name-provider">
                    <div>
                        <span class="product-name">{{ defaultPricingDetails ? defaultPricingDetails.name : "" }}</span>

                        <InputRichDropdown v-if="providersPricingConfigDropdownList && providersPricingConfigDropdownList.length > 1"
                                           v-model:saturn="providerCode"
                                           :list="providersPricingConfigDropdownList"
                                           :display="(p) => p.description"
                                           :valueMap="(p) => p.code"
                                           :disabled="isBusyRepricing"
                                           @change="repriceProduct">
                        </InputRichDropdown>

                        <span v-else class="provider-name">Provider: {{ providerDescription }}</span>
                    </div>
                    <div v-if="termLimit !== originalTermLimit || milesLimit !== originalMilesLimit">
                        <span class="product-name">{{ planTypeText }}</span>
                        <span class="provider-name">{{ getPlanTermText() }}</span>
                    </div>
                </div>
                <div :style="$grid('1')">
                    <InputCheckbox v-model="included" label="Include this coverage?" v-if="term == 0"></InputCheckbox>
                    <div v-else-if="modal.passedData.matchDealTermView">{{ (term + ' months ') + 'coverage term' }}</div>
                </div>

                <div :style="$grid(product == 'maintenance' ? '1-1-1-1-1-1' : '1-1-1-1-1')">
                    <InputRichDropdown v-if="dealTerms.length === 0"
                                       label="Term"
                                       v-model:saturn="termLimit"
                                       :list="termsList"
                                       :disabled="(termsList == null || term > 0)"
                                       :invalid="v$.termLimit.$invalid"
                                       @change="updateCostAndPriceBasedOnCurrentDropdowns()">
                        <template #validation>
                            <div v-if="v$.termLimit.required.$invalid">Term Limit is required.</div>
                        </template>
                    </InputRichDropdown>
                    <InputTextbox v-else
                                  label="Term"
                                  saturn="Deal term"
                                  :disabled="true" />

                    <InputRichDropdown v-if="programsList != null"
                                       label="Program"
                                       v-model:saturn="program"
                                       :list="programsList"
                                       :valueMap="(p) => p.code"
                                       :display="(p) => p.description"
                                       :disabled="programsList.length === 1 && coverageTerm.term !== 0"
                                       @change="updateCostAndPriceBasedOnCurrentDropdowns()"
                                       class="grid-2-column"
                                       search>
                    </InputRichDropdown>
                    <InputTextbox v-else
                                  label="Program"
                                  saturn="N/A"
                                  class="grid-2-column"
                                  :disabled="true" />

                    <InputRichDropdown v-if="milesList != null"
                                       label="Miles Limit"
                                       v-model:saturn="milesLimit"
                                       :list="milesList"
                                       :valueMap="(m) => m.code"
                                       :display="(m) => milesLimit !== -1 ? m.description : ''"
                                       :disabled="milesList.length === 1 && coverageTerm.term !== 0 && !v$.milesLimit.$invalid"
                                       @change="updateCostAndPriceBasedOnCurrentDropdowns()"
                                       :invalid="v$.milesLimit.$invalid">
                        <template #validation>
                            <div v-if="v$.milesLimit.required.$invalid">Miles Limit is required.</div>
                        </template>
                    </InputRichDropdown>
                    <InputTextbox v-else
                                  label="Miles Limit"
                                  saturn="N/A"
                                  :disabled="true" />

                    <InputRichDropdown v-if="subprogramList != null"
                                       :label="product=='service'?'Deductible':'Interval'"
                                       v-model:saturn="subprogram"
                                       :list="subprogramList"
                                       :valueMap="(s) => s.code"
                                       :display="(s) => s.description"
                                       :disabled="subprogramList.length === 1 && coverageTerm.term !== 0 && !v$.subprogram.$invalid"
                                       @change="updateCostAndPriceBasedOnCurrentDropdowns()"
                                       :invalid="v$.subprogram.$invalid">
                    </InputRichDropdown>
                    <InputTextbox v-else
                                  label="Subprogram"
                                  saturn="N/A"
                                  :disabled="true" />

                    <InputRichDropdown v-if="product=='maintenance'"
                                       label="Oil Type"
                                       v-model:saturn="vehicleOil"
                                       :list="oilTypes"
                                       :valueMap="(o) => o.code"
                                       :display="(o) => o.name"
                                       :disabled="true"
                                       @change="updateCostAndPriceBasedOnCurrentDropdowns()">
                    </InputRichDropdown>
                </div>

                <!-- special section for deal term -->
                <div v-if="dealTerms.length > 0" :style="$grid('1')">
                    <div v-for="(dt, index) in dealTerms" :key="index">
                        <hr :class="{'divider': true, 'no-bundle': !showBundleView}" />

                        <!-- bundle pieces -->
                        <div v-if="showBundleView" class="bundle-pieces-wrapper">
                            <div class="bundle-piece-row" :style="$grid('1-1-1-1-1-1')" v-for="(p, pIndex) in dt?.bundleProducts" :key="pIndex">
                                <div class="label grid-2-column">
                                    <span :class="{'product-name': true, 'not-sold': !p.isIncluded }">{{ p.description }}</span>
                                </div>

                                <InputTextbox v-if="p.isIncluded"
                                              label="Term"
                                              v-model:saturn="p.term"
                                              :disabled="true" />
                                <InputCurrency v-if="p.isIncluded"
                                               v-model:saturn="p.price"
                                               @change="p.onPriceChange"
                                               label="Price"
                                               placeholder="Price"
                                               :precision="2"
                                               :disabled="p.minimum === p.maximum || (!bundleLists[dt.selectedRate.meta.configListIndex].allowIndividualAdjustment && dealTerms[index].bundleProducts.filter(bp => bp.isIncluded).length > 1)"
                                               :invalid="v$.dealTerms[index].bundleProducts?.[pIndex].price.$invalid">
                                    <template #validation>
                                        <div v-if="v$.dealTerms[index].bundleProducts?.[pIndex].price.required.$invalid">Price is required.</div>
                                        <div v-if="p.minimum && v$.dealTerms[index].bundleProducts?.[pIndex].price.minValue.$invalid">Price must be a minimum of {{ p.minimum }}</div>
                                        <div v-if="p.maximum && v$.dealTerms[index].bundleProducts?.[pIndex].price.maxValue.$invalid">Price must be a maximum of {{ p.maximum }}</div>
                                    </template>
                                </InputCurrency>

                                <InputCurrency v-if="p.isIncluded" v-model:saturn="p.minimum" label="Minimum" placeholder="Minimum" :precision="2" :disabled="true">
                                    <template #action>
                                        <button :disabled="p.minimum === p.maximum" class="button-error" @click="p.setToMinimum"><i class="fas fa-arrow-down" aria-hidden="true"></i></button>
                                    </template>
                                </InputCurrency>

                                <InputCurrency v-if="p.isIncluded" v-model:saturn="p.maximum" label="Maximum" placeholder="Maximum" :precision="2" :disabled="true">
                                    <template #action>
                                        <button :disabled="p.minimum === p.maximum" class="button-success" @click="p.setToMaximum"><i class="fas fa-arrow-up" aria-hidden="true"></i></button>
                                    </template>
                                </InputCurrency>

                                <InputTextbox v-if="!p.isIncluded"
                                              class="grid-4-column"
                                              saturn="NOT SOLD"
                                              :disabled="true" />
                            </div>
                        </div>

                        <div :style="$grid('1-1-1-1-1-1', '5px 10px') ">
                            <div v-if="showBundleView" class="label grid-2-column">
                                <span class="product-name"><strong>Total</strong></span>
                            </div>

                            <InputTextbox v-if="!showBundleView"
                                          label="Deal Term"
                                          :saturn="`${dt.months} months`"
                                          :disabled="true" />
                            <div v-else></div>

                            <InputCurrency v-model:saturn="dt.price"
                                           @change="(price) => dt.onPriceChange(price, dealTerms?.[index]?.bundleProducts)"
                                           label="Price"
                                           placeholder="Price"
                                           :precision="2"
                                           :invalid="v$.dealTerms[index].$invalid"
                                           :disabled="dt.minimum === dt.maximum || (showBundleView && bundleLists[dt.selectedRate.meta.configListIndex].allowIndividualAdjustment) || dt.bundleProducts.length > 1">
                                <template #validation>
                                    <div v-if="v$.dealTerms[index].$invalid">Invalid Input</div>
                                    <div v-if="v$.dealTerms[index].price?.required.$invalid">Price is required.</div>
                                    <div v-if="dt.minimum && v$.dealTerms[index].price?.minValue.$invalid">Price must be a minimum of {{ dt.minimum }}</div>
                                    <div v-if="dt.maximum && v$.dealTerms[index].price?.maxValue.$invalid">Price must be a maximum of {{ dt.maximum }}</div>
                                </template>
                            </InputCurrency>

                            <InputCurrency label="Cost"
                                           :saturn="dt.cost"
                                           :disabled="true"
                                           :precision="2" />

                            <InputCurrency label="Profit"
                                           :saturn="dt.price - dt.cost"
                                           :disabled="true"
                                           :precision="2" />

                            <InputCurrency v-if="!showBundleView" v-model:saturn="dt.minimum" label="Minimum" placeholder="Minimum" :precision="2" :disabled="true">
                                <template #action>
                                    <button :disabled="dt.minimum === dt.maximum" class="button-error" @click="setToMinimum(index, () => dt.onPriceChange(dt.minimum, dt.bundleProducts))"><i class="fas fa-arrow-down" aria-hidden="true"></i></button>
                                </template>
                            </InputCurrency>

                            <InputCurrency v-if="!showBundleView" v-model:saturn="dt.maximum" label="Maximum" placeholder="Maximum" :precision="2" :disabled="true">
                                <template #action>
                                    <button :disabled="dt.minimum === dt.maximum" class="button-success" @click="setToMaximum(index, () => dt.onPriceChange(dt.maximum, dt.bundleProducts))"><i class="fas fa-arrow-up" aria-hidden="true"></i></button>
                                </template>
                            </InputCurrency>
                        </div>
                    </div>
                </div>
                <!-- end of special section for deal term view -->
                <div v-else>
                    <!-- bundle pieces -->
                    <div v-if="showBundleView" class="bundle-pieces-wrapper">
                        <div class="bundle-piece-row" :style="$grid('1-1-1-1-1')" v-for="(p, pIndex) in bundleProducts" :key="pIndex">
                            <div class="label grid-2-column">
                                <span :class="{'product-name': true, 'not-sold': !p.isIncluded }">{{ p.description }}</span>
                            </div>

                            <InputCurrency v-if="p.isIncluded"
                                           v-model:saturn="p.price"
                                           @change="p.onPriceChange"
                                           label="Price"
                                           placeholder="Price"
                                           :precision="2"
                                           :disabled="p.minimum === p.maximum || (!bundleLists[selectedRate.meta.configListIndex].allowIndividualAdjustment && bundleProducts.filter(bp => bp.isIncluded).length > 1)"
                                           :invalid="v$.bundleProducts[pIndex].price.$invalid">
                                <template #validation>
                                    <div v-if="v$.bundleProducts[pIndex].price.required.$invalid">Price is required.</div>
                                    <div v-if="p.minimum && v$.bundleProducts[pIndex].price.minValue.$invalid">Price must be a minimum of {{ p.minimum }}</div>
                                    <div v-if="p.maximum && v$.bundleProducts[pIndex].price.maxValue.$invalid">Price must be a maximum of {{ p.maximum }}</div>
                                </template>
                            </InputCurrency>

                            <InputCurrency v-if="p.isIncluded" v-model:saturn="p.minimum" label="Minimum" placeholder="Minimum" :precision="2" :disabled="true">
                                <template #action>
                                    <button :disabled="p.minimum === p.maximum" class="button-error" @click="p.setToMinimum"><i class="fas fa-arrow-down" aria-hidden="true"></i></button>
                                </template>
                            </InputCurrency>

                            <InputCurrency v-if="p.isIncluded" v-model:saturn="p.maximum" label="Maximum" placeholder="Maximum" :precision="2" :disabled="true">
                                <template #action>
                                    <button :disabled="p.minimum === p.maximum" class="button-success" @click="p.setToMaximum"><i class="fas fa-arrow-up" aria-hidden="true"></i></button>
                                </template>
                            </InputCurrency>

                            <InputTextbox v-if="!p.isIncluded"
                                          class="grid-3-column"
                                          saturn="NOT SOLD"
                                          :disabled="true" />
                        </div>
                    </div>

                    <div :style="$grid('1-1-1-1-1') ">
                        <div v-if="showBundleView" class="label grid-2-column">
                            <span class="product-name"><strong>Total</strong></span>
                        </div>

                        <InputCurrency v-model:saturn="price" @change="(price) => onPriceChange(price, bundleProducts)" label="Price" placeholder="Price" :precision="2" :disabled="(minimum === maximum) || (showBundleView && bundleLists[selectedRate.meta.configListIndex].allowIndividualAdjustment) || bundleProducts.length > 1" :invalid="v$.price.$invalid">
                            <template #validation>
                                <div v-if="v$.price.required?.$invalid">Price is required.</div>
                                <div v-if="minimum && v$.price.minValue?.$invalid">Price must be a minimum of {{ minimum }}</div>
                                <div v-if="maximum && v$.price.maxValue?.$invalid">Price must be a maximum of {{ maximum }}</div>
                            </template>
                        </InputCurrency>

                        <InputCurrency label="Cost"
                                       :saturn="cost"
                                       :disabled="true"
                                       :precision="2" />

                        <InputCurrency label="Profit"
                                       :saturn="profit()"
                                       :disabled="true"
                                       :precision="2" />

                        <InputCurrency v-if="!showBundleView" v-model:saturn="minimum" label="Minimum" placeholder="Minimum" :precision="2" :disabled="true">
                            <template #action>
                                <button :disabled="minimum === maximum" class="button-error" @click="setToMinimum(null, () => onPriceChange(minimum, bundleProducts))"><i class="fas fa-arrow-down" aria-hidden="true"></i></button>
                            </template>
                        </InputCurrency>

                        <InputCurrency v-if="!showBundleView" v-model:saturn="maximum" label="Maximum" placeholder="Maximum" :precision="2" :disabled="true">
                            <template #action>
                                <button :disabled="minimum === maximum" class="button-success" @click="setToMaximum(null, () => onPriceChange(maximum, bundleProducts))"><i class="fas fa-arrow-up" aria-hidden="true"></i></button>
                            </template>
                        </InputCurrency>
                    </div>
                </div>

                <Panel title="Add-on Coverages">
                    <div v-if="displaySurcharges.length > 0 && displaySurcharges.some(ds => ds.isVisibleInModal)" :style="$grid('1-1-1-1')" class="no-margin-grid">

                        <InputCheckbox v-for="s in displaySurcharges.filter(ds => ds.isVisibleInModal)"
                                       :key="s.label"
                                       :disabled="(s.saturnCode == null ? s.isDisabledInModal : false)"
                                       @update:modelValue="val => updateCostAndPriceBasedOnCurrentDropdowns()"
                                       v-model="s.isChecked"
                                       :label="s.label" />

                    </div>
                    <div v-else class="grid-4-column" :style="{paddingTop: '5px', paddingBottom: '5px'}">This Program does not have surcharges.</div>
                </Panel>
            </fieldset>
        </div>
        <div class="modal-footer footer-details">
            <div class="gapped">
                <span class="plan-code">Plan Code: <strong>{{ program }}</strong></span>
            </div>
            <div class="gapped">
                <button class="button-unwind"
                        @click="cancel()">
                    Cancel
                </button>
                <button :disabled="v$.$invalid || isBusyRepricing"
                        class="button-save"
                        @click="close()">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { required, minValue, maxValue } from '@vuelidate/validators'
    import $modal from '@core/services/modal'
    import _ from 'underscore';
    import CoverageTerm from '@core/classes/CoverageTerm'
    import CoverageTermProgram from '@core/classes/CoverageTermProgram'
    import ENUMS from "@core/classes/Enums"
    import InputCheckbox from '@core/components/InputCheckbox.vue'
    import InputCurrency from '@core/components/InputCurrency.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import modalInfo from '@core/modals/modalInfo.vue'
    import Panel from '@core/components/Panel.vue'
    import ProductClass from '@core/classes/ProductClass'
    import {reactive } from 'vue'
    import settings from 'settings'
    import { useVuelidate } from '@vuelidate/core';
    import util from '@core/services/util'

    export default {
        name: "modalEditCoverageTermProduct",
        props: ['modal'],
        setup() {
            // New setup for vuelidate. Swap $v with v$
            return { v$: useVuelidate({ $scope: false }) }
        },
        data() {
            return {
                isBusy: false,
                isBusyRepricing: false,
                needsRecoveryOnCancel: false,
                coverageTerm: null,
                product: null,
                productDetails: null,
                surchargeConfigs: null,
                pricedConfig: null,
                apiResponse: null,
                term: null,
                included: null,
                price: null,
                cost: null,
                minimum: null,
                maximum: null,
                provider: null,
                providerCode: null,
                currentProviderCode: null,
                program: null,
                termLimit: null,
                milesLimit: null,
                originalTermLimit: null,
                originalMilesLimit: null,
                subprogram: null,
                surcharges: [],
                displaySurcharges: [],
                vehicleOil: null,
                termsList: null,
                programsList: null,
                milesList: null,
                subprogramList: null,
                previousProgram: null,
                selectedRate: null,
                bundleProducts: [],
                dealTerms: [],
                oilTypes: [
                    { code: null, name: '-- Select Value --' },
                    { code: 0, name: 'Regular', disabled: false },
                    { code: 2, name: 'Blend', disabled: false },
                    { code: 1, name: 'Synthetic', disabled: false },
                    { code: 3, name: 'Diesel', disabled: false }
                ]
            };
        },
        created() {
            this.product = this.modal.passedData.product
            this.surchargeConfigs = this.modal.passedData.surchargeConfigs
            this.apiResponse = util.clone(this.modal.passedData.apiResponse);
            this.coverageTerm = new CoverageTerm(this.modal.passedData.coverageTerm);
            this.term = this.coverageTerm.term;

            this.productDetails = new ProductClass(this.modal.passedData.productDetails);
            const defaultPricingDetails = this.modal.passedData.productDetails.getDefaultPricingDetails()
            if (defaultPricingDetails?.pricedConfig) {
                this.coverageTerm.setProductWithBundle(this.productDetails.productType)
                this.provider = this.coverageTerm.getDealDefaultProduct(this.productDetails.productType).getInfo()?.provider
                this.pricedConfig = defaultPricingDetails.pricedConfig
            } else {
                this.provider = this.modal.passedData.provider
            }

            this.loadData();
        },
        computed: {
            ENUMS() {
                return ENUMS;
            },
            settings() {
                return settings;
            },
            planTypeText() {
                let text = this.modal.passedData.inventoryType
                if (text === "Used") {
                    if (this.modal.passedData.isWarrantyValid) {
                        text += " w/ Warranty"
                    }
                    else {
                        text += " w/o Warranty"
                    }
                }

                return text
            },
            defaultPricingDetails() {
                return this.productDetails.getDefaultPricingDetails()
            },
            showBundleView() {
                return this.pricedConfig ? this.pricedConfig.configDetails.length > 1 : false
            },
            bundleLists() {
                return this.pricedConfig ? this.defaultPricingDetails.getPricingConfig(this.pricedConfig.configId).pricingLists : []
            },
            providerDescription() {
                return settings.lookups.getProviderDescription(this.provider)
            },
            providersPricingConfigDropdownList() {
                const providersPricingConfigDropdownList = []

                const repriceOptions = this.modal.passedData.productDetails.getDefaultPricingDetails()?.repriceOptions
                if (repriceOptions) {
                    repriceOptions.forEach(o => {
                        providersPricingConfigDropdownList.push({ code: o.provider, description: settings.lookups.getProviderDescription(o.provider) })
                    })
                }

                return providersPricingConfigDropdownList
            },
        },
        validations() {
            const validations = {
                price: {},
                dealTerms: {},
                termLimit: { required }
            }
            if (this.product == 'service' || this.product == 'maintenance') {
                validations.milesLimit = { required }
                validations.subprogram = { required }
            }

            if (this.modal.passedData.matchDealTermView) {
                validations.dealTerms = this.dealTerms.map((dTerm) => {
                    let bundleValidation = {
                        price: {
                            required,
                            minValue: (value, term) => value >= term.minimum,
                            maxValue: (value, term) => value <= term.maximum
                        }
                    }

                    if (this.pricedConfig) {
                        bundleValidation.bundleProducts = dTerm.bundleProducts.map(() => ({
                            price: {
                                required,
                                minValue: (value, p) => value >= p.minimum,
                                maxValue: (value, p) => value <= p.maximum
                            }
                        }))
                    }

                    return bundleValidation
                })
            }
            else {
                if (this.cost && this.minimum && this.maximum) {
                    validations.price = {
                        required,
                        minValue: minValue(this.minimum),
                        maxValue: maxValue(this.maximum)
                    }
                }
                else if (this.cost && this.minimum) {
                    validations.price = {
                        required,
                        minValue: minValue(this.minimum)
                    }
                }
                else if (this.cost && this.maximum) {
                    validations.price = {
                        required,
                        maxValue: maxValue(this.maximum)
                    }
                }
                else {
                    validations.price = {
                        required
                    }
                }

                if (this.pricedConfig) {
                    validations.bundleProducts = this.bundleProducts.map(() => ({
                        price: {
                            required,
                            minValue: (value, p) => value >= p.minimum,
                            maxValue: (value, p) => value <= p.maximum
                        }
                    }));
                }
            }
            return validations;
        },
        methods: {
            profit() {
                let profit = null;
                if (this.price && this.cost) profit = this.price - this.cost;
                return profit;
            },
            getPlanTermText() {
                const planDetails = [this.originalTermLimit + " Months"]
                if (this.originalMilesLimit) {
                    planDetails.push(this.originalMilesLimit.toLocaleString() + " Miles")
                }

                return "Plan Term: " + planDetails.join(" or ")
            },
            repriceProduct() {
                if (this.providerCode != this.currentProviderCode) {
                    this.isBusyRepricing = true

                    //if the new pricing failed, keep current provider selected, otherwise, update current
                    this.modal.passedData.repriceProduct({ productType: this.modal.passedData.productDetails.productType, providerCodeOverride: this.providerCode })
                        .then((repriceResponse) => {
                            this.needsRecoveryOnCancel = true
                            if (repriceResponse.success) {

                                //if the current term option for the product is no longer available, alert the user and close the modal right here
                                if (!repriceResponse.newCoverageTerm || !repriceResponse.newCoverageTerm[this.product]) {
                                    this.openTermNoLongerAvailableModal()
                                }

                                repriceResponse.newCoverageTerm[this.product + "Info"].provider = this.providerCode
                                this.coverageTerm = new CoverageTerm(repriceResponse.newCoverageTerm);

                                this.apiResponse = util.clone(repriceResponse.getProductProviderApiResponse(this.coverageTerm))
                                this.term = this.coverageTerm.term;
                                this.provider = this.providerCode
                                this.productDetails.getDefaultPricingDetails().repriceOptions = repriceResponse.repriceOptions
                                this.surchargeConfigs = repriceResponse.repriceOptions.find(o => o.provider === this.provider).providerProduct.surchargeConfigs
                                this.loadData()
                            }
                            else {
                                //revert dropdown selection
                                this.providerCode = this.currentProviderCode
                                util.toastr('error', ENUMS.productTypes.find(pt => pt.code == this.productDetails.productType).description, repriceResponse.statusDescription ?? "Failed to price product.");
                            }
                        }).finally(() => {
                            this.isBusyRepricing = false
                        })
                }
            },
            openTermNoLongerAvailableModal() {
                $modal.open(modalInfo, {
                    name: 'modalInfo',
                    passedData: {
                        info: `This term is no longer available for ${this.defaultPricingDetails?.name}.`,
                        acceptText: 'Close',
                    },
                    backdrop: false,
                    postFunction: $modal.close
                });
            },
            onPriceChange(price, bundleProducts) {
                if (bundleProducts && bundleProducts.length === 1) {
                    bundleProducts.forEach(bp => {
                        bp.price = price
                    })
                }
            },
            getBundleProducts(price, selectedRate, onPriceChange) {
                const selectedSurchagres = this.surcharges.filter(s => s.isChecked).map(s => s.code)
                const bundledInfoDetails = this.defaultPricingDetails.getBundledInfoDetails(this.provider, selectedRate, selectedSurchagres)
                const bundledPricingDetails = this.defaultPricingDetails.getBundledPricingDetails(price, this.modal.passedData.preloads, selectedRate, selectedSurchagres, this.modal.passedData.storeProducts, true)

                const bundleProducts = this.pricedConfig.configDetails.map(cd => {
                    const product = this.modal.passedData.storeProducts.find(p => p.productType === cd.productType)
                    const ppd = product.getPricingDetails(cd.productPricingDetailsId)

                    const bundleProduct = reactive({
                        isIncluded: false,
                        productType: cd.productType,
                        productPricingDetailsId: cd.productPricingDetailsId,
                        description: ppd.name,
                        price: 0,
                        minimum: 0,
                        maximum: 0
                    })

                    const productInfo = bundledInfoDetails.find(bd => bd.productType === cd.productType && bd.productPricingDetailsId === cd.productPricingDetailsId)?.productDetails
                    const productPricing = bundledPricingDetails.find(bd => bd.productType === cd.productType && bd.productPricingDetailsId === cd.productPricingDetailsId)?.productDetails
                    if (productInfo && productPricing) {
                        const preload = this.modal.passedData.preloads.find(preload => preload.productType === cd.productType)

                        bundleProduct.isIncluded = true
                        bundleProduct.isRegulated = product.isRegulated
                        bundleProduct.term = productInfo.netTerm
                        bundleProduct.price = productPricing.price
                        bundleProduct.minimum = ppd.getMinimumProductPrice(productPricing.price, productPricing.cost, preload, product.isRegulated)
                        bundleProduct.maximum = ppd.getMaximumProductPrice(productPricing.price, productPricing.cost, product.isRegulated)
                        bundleProduct.onPriceChange = onPriceChange
                        bundleProduct.setToMinimum = () => {
                            bundleProduct.price = bundleProduct.minimum
                            onPriceChange()
                        }
                        bundleProduct.setToMaximum = () => {
                            bundleProduct.price = bundleProduct.maximum
                            onPriceChange()
                        }
                    }

                    return bundleProduct
                })

                return bundleProducts
            },
            getRateDetails(program, termLimit, milesLimit, subprogram) {
                let selectedRate = null
                let originalTermLimit = null
                let originalMilesLimit = null
                let cost = null
                let price = null

                //filter down to the right term and program
                let filteredRates = this.apiResponse.filter(t => {
                    const isBundledRate = `${t.netTerm}`.includes("|")
                    const isBundledTerm = `${termLimit}`.includes("|")

                    if (isBundledRate === isBundledTerm) {
                        return t.netTerm == termLimit
                    }
                    else if (isBundledRate) {
                        const ratePieces = `${t.netTerm}`.split("|");
                        return ratePieces.includes(`${termLimit}`)
                    }
                    else {
                        const termPieces = `${termLimit}`.split("|");
                        return termPieces.includes(`${t.netTerm}`)
                    }
                }).filter(t => t.program === program);

                //filter down to the right mileage
                if (this.milesList) {
                    filteredRates = filteredRates.filter(t => t.netMiles == milesLimit);
                }

                //filter down to the right subprogram
                if (this.subprogramList) {
                    filteredRates = filteredRates.filter(t => t.subprogram.trim() == subprogram.trim());
                }

                selectedRate = filteredRates[0];
                if (selectedRate) {
                    originalTermLimit = selectedRate.termLimit
                    originalMilesLimit = selectedRate.milesLimit

                    cost = selectedRate.cost
                    price = selectedRate.msrp ?? selectedRate.cost

                    //uncheck surcharges that are not present in the selected rate
                    this.surcharges.filter(s => !selectedRate.surcharges.some(rs => rs.code == s.code)).forEach(s => {
                        s.isChecked = false
                    })

                    //update displaySurcharges to only keep add-ons that are present in the selected rate
                    this.displaySurcharges = this.surcharges.filter(s => selectedRate.surcharges.some(rs => rs.code == s.code))

                    //on program change, reset the relevant surcharges
                    if (this.previousProgram !== selectedRate.program) {
                        this.displaySurcharges.filter(ds => this.surchargeConfigs.some(sc => sc.code == ds.code && sc.defaultValue)).forEach(ds => {
                            ds.isChecked = true
                        });
                    }

                    //add surcharges to values
                    this.displaySurcharges.forEach(ds => {
                        const rateSur = selectedRate.surcharges.find(s => s.code == ds.code)
                        if (rateSur) {
                            if (ds.code == ds.label) {
                                ds.label = rateSur.description
                            }

                            if (ds.isChecked) {
                                cost += rateSur.cost
                                price += rateSur.msrp ?? rateSur.cost
                            }
                        }
                    });

                    this.previousProgram = selectedRate.program
                }

                if (this.pricedConfig) {
                    this.provider = selectedRate.meta.bundledProvider
                    const priceParts = `${selectedRate.meta.bundledMsrp}`.split("|")
                    const costParts = `${selectedRate.meta.bundledCost}`.split("|")

                    let bundledPrice = 0
                    this.defaultPricingDetails.getConfigListOptions(selectedRate).forEach((o, i) => {
                        const productDetails = this.modal.passedData.storeProducts.find(p => p.productType === o.productType)
                        const pricingDetails = productDetails.getPricingDetails(o.productPricingDetailsId)
                        const optionSurcharges = this.pricedConfig.configDetails.find(cd => cd.productType === productDetails.productType && cd.productPricingDetailsId === pricingDetails.productPricingDetailsId).providerProduct.surchargeConfigs.map(sc => sc.code)
                        let partCost = parseInt(costParts[i])
                        let partPrice = parseInt(priceParts[i])

                        //add surcharges
                        this.surcharges.filter(ds => ds.isChecked && optionSurcharges.includes(ds.code)).forEach(ds => {
                            const rateSur = selectedRate.surcharges.find(s => s.code == ds.code)
                            if (rateSur) {
                                partCost += rateSur.cost
                                partPrice += rateSur.msrp ?? rateSur.cost
                            }
                        })

                        bundledPrice += this.getUpdatedPrice(partPrice, partCost, productDetails.isRegulated, pricingDetails)
                    })
                    price = bundledPrice
                }
                else {
                    //apply the multiplier and price and cost separeted and isRegulated
                    price = this.getUpdatedPrice(price, cost, this.productDetails.isRegulated, this.defaultPricingDetails)
                }

                const rateDetails = { selectedRate, originalTermLimit, originalMilesLimit, cost, price }
                // console.log("rateDetails", rateDetails)
                return rateDetails
            },
            getUpdatedPrice(price, cost, isRegulated, pricingDetails) {
                if (!isRegulated && pricingDetails.maxMultiplier > 0) {
                    const newPrice = cost * pricingDetails.maxMultiplier
                    return pricingDetails.maxRetail > 0 && pricingDetails.maxRetail < newPrice ? pricingDetails.maxRetail : newPrice
                } else {
                    return price
                }
            },
            updateCostAndPriceBasedOnCurrentDropdowns(setupInitialPrices) {
                this.$nextTick(() => {
                    this.setTermsList();

                    this.setProgramsList();
                    if (this.programsList != null && this.programsList.length > 0 && !this.programsList.some(p => p.code == this.program)) {
                        this.program = this.programsList[0].code;
                    }


                    this.setMilesList();
                    if (this.milesList != null && !this.milesList.some(m => m.code == this.milesLimit)) {
                        this.milesLimit = null;
                    }

                    this.setSubprogramList();
                    if (this.subprogramList != null && !this.subprogramList.some(s => s.code == this.subprogram)) {
                        this.subprogram = this.subprogramList.length > 0 ? this.subprogramList[0].code : null;
                    }


                    if (this.dealTerms.length > 0) {
                        this.dealTerms.forEach(lt => {
                            const distinctTerms = [...new Set(this.apiResponse.map(r => r.netTerm))]
                            const termLimit = distinctTerms.find(dt => `${dt}`.split("|").every(t => parseInt(t) >= lt.months));
                            const rateDetails = this.getRateDetails(this.program, termLimit, this.milesLimit, this.subprogram)
                            const selectedSurcharges = this.surcharges.filter(s => s.isChecked).map(s => s.code)

                            if (rateDetails.selectedRate) {
                                lt.price = rateDetails.price
                                lt.cost = rateDetails.cost
                                lt.selectedRate = rateDetails.selectedRate
                                lt.onPriceChange = this.onPriceChange

                                if (this.pricedConfig) {
                                    lt.bundleProducts = this.getBundleProducts(lt.price, lt.selectedRate, () => {
                                        lt.price = lt.bundleProducts.reduce((acc, cur) => acc + cur.price, 0)
                                    })
                                }
                            }
                            lt.minimum = this.defaultPricingDetails.getMinimumPrice(lt.price, lt.cost, this.modal.passedData.preloads, this.productDetails, this.modal.passedData.storeProducts, lt.selectedRate, selectedSurcharges)
                            lt.maximum = this.defaultPricingDetails.getMaximumPrice(lt.price, lt.cost, this.productDetails.isRegulated, this.modal.passedData.storeProducts, lt.selectedRate, selectedSurcharges)
                        })
                    }
                    else {
                        const rateDetails = this.getRateDetails(this.program, this.termLimit, this.milesLimit, this.subprogram)
                        this.originalTermLimit = rateDetails.originalTermLimit
                        this.originalMilesLimit = rateDetails.originalMilesLimit
                        this.cost = rateDetails.cost
                        this.price = rateDetails.price
                        this.selectedRate = rateDetails.selectedRate
                        if (this.pricedConfig) {
                            this.bundleProducts = this.getBundleProducts(this.price, this.selectedRate, () => {
                                this.price = this.bundleProducts.reduce((acc, cur) => acc + cur.price, 0)
                            })

                            //update the selected term to match the bundle option
                            this.termLimit = this.selectedRate.netTerm
                        }
                        this.setMinimumAndMaximum();
                    }

                    if (setupInitialPrices) {
                        //fix onload price and cost
                        if (this.dealTerms.length > 0) {
                            this.dealTerms.forEach((lt, i) => {
                                lt.price = this.coverageTerm[this.product + "InfoAndPricing"][i].termPricing.price
                                lt.cost = this.coverageTerm[this.product + "InfoAndPricing"][i].termPricing.cost
                                if (this.pricedConfig) {
                                    this.updateBundleWithDetails(lt.bundleProducts, this.coverageTerm[this.product + "InfoAndPricing"][i].termPricing.getBundleDetails())
                                }
                            })
                        }
                        else {
                            this.price = this.coverageTerm[this.product + "Pricing"].price
                            this.cost = this.coverageTerm[this.product + "Pricing"].cost
                            if (this.pricedConfig) {
                                this.updateBundleWithDetails(this.bundleProducts, this.coverageTerm[this.product + "Pricing"].getBundleDetails())
                            }
                        }
                    }
                });
            },
            setProgramsList() {
                let response = this.apiResponse;

                if (this.termLimit !== 0) {
                    response = response.filter(t => {
                        const isBundledRate = `${t.netTerm}`.includes("|")
                        const isBundledTerm = `${this.termLimit}`.includes("|")
                        const ratePieces = `${t.netTerm}`.split("|");
                        const termPieces = `${this.termLimit}`.split("|");

                        if (isBundledRate === isBundledTerm) {
                            return ratePieces.some(rp => termPieces.some(tp => parseInt(rp) >= parseInt(tp)))
                        }
                        else if (isBundledRate) {
                            return ratePieces.includes(`${this.termLimit}`)
                        }
                        else {
                            return termPieces.includes(`${t.netTerm}`)
                        }
                    })
                }

                this.programsList = _.uniq(response, rate => rate.program).map(rate => ({
                    code: rate.program,
                    description: rate.programDescription
                }));

                if (this.programsList && this.programsList.length == 0) {
                    this.programsList = null
                }
            },
            setTermsList() {
                let tmpTerms = this.apiResponse;
                if (this.pricedConfig) {
                    tmpTerms = this.apiResponse.filter(r => r.program === this.program);
                }
                const tmpTermsMapped = tmpTerms.map(r => r.netTerm);
                const tmpTermSet = new Set(tmpTermsMapped);
                this.termsList = [...tmpTermSet].sort((a, b) => a - b);
            },
            setMilesList() {
                //console.log('In setMilesList: program = ', this.program);
                let response = this.apiResponse;
                this.milesList = null;
                if (this.product === "service" || this.product === "maintenance") {
                    //console.log('response before program filter', response);
                    if (this.program != null) {
                        response = response.filter(r => r.program === this.program);
                    }

                    if (this.termLimit !== 0) {
                        response = response.filter(r => r.netTerm == this.termLimit)
                    }

                    const tmpMilesList = [...new Set(response.map(term => term.netMiles))].sort((a, b) => a - b);
                    this.milesList = tmpMilesList.map(m => ({
                        code: m,
                        description: m.toLocaleString()
                    }));
                }
            },
            setSubprogramList() {
                //console.log('in setSubprogramList')
                let response = this.apiResponse;
                this.subprogramList = null;
                if (this.product === "service" || this.product === "maintenance") {
                    //console.log("response not filtered: ", response)
                    if (this.program != null) {
                        response = response.filter(r => r.program === this.program);
                    }
                    //console.log("response filtered => Program: ", response)
                    if (this.termLimit !== 0) {
                        response = response.filter(r => r.netTerm == this.termLimit)
                    }

                    //console.log("response filtered => Term: ", response)
                    if (this.milesLimit !== 0) {
                        response = response.filter(r => r.netMiles == this.milesLimit)
                    }
                    //console.log("response filtered => Miles: ", response)

                    this.subprogramList = _.uniq(response, rate => rate.subprogram).map(r => ({
                        code: r.subprogram.trim(),
                        description: r.subprogramDescription
                    }));
                }
                //console.log("this.subprogramList", this.subprogramList);
            },
            loadProductData(productInfo) {
                this.termLimit = productInfo.netTerm;
                this.originalTermLimit = productInfo.termLimit;
                this.surcharges = this.surchargeConfigs.map(s => ({
                    code: s.code,
                    label: s.altDescription ? s.altDescription : s.code,
                    isVisibleInModal: s.isVisible,
                    isDisabledInModal: s.isDisabled,
                    isChecked: productInfo.surcharges?.includes(s.code) ?? false
                }))

                this.program = productInfo.program.code;

                this.milesLimit = productInfo.netMiles;
                this.originalMilesLimit = productInfo.milesLimit;

                this.subprogram = productInfo.subprogram?.trim();
            },
            loadData() {
                this.included = this.coverageTerm[this.product];

                if (this.modal.passedData.matchDealTermView) {
                    this.loadProductData(this.coverageTerm[this.product + "InfoAndPricing"][0].termInfo)

                    this.dealTerms = this.coverageTerm[this.product + "InfoAndPricing"].map(g => ({
                        months: g.termMonths.toString(),
                        price: g.termPricing.price,
                        cost: g.termPricing.cost,
                        minimum: this.minimum,
                        maximum: this.maximum,
                        selectedRate: null,
                        bundleProducts: []
                    }));
                }
                else {
                    this.loadProductData(this.coverageTerm[this.product + "Info"])
                    this.cost = this.coverageTerm[this.product + "Pricing"].cost;
                    this.price = this.coverageTerm[this.product + "Pricing"].price;

                    if (this.productDetails.productType === ENUMS.PRODUCT_TYPES.MAINTENANCE) {
                        this.vehicleOil = this.modal.passedData.vehicleOil;
                        this.updateOilTypes();
                    }
                }

                this.previousProgram = this.program
                this.providerCode = this.provider
                this.currentProviderCode = this.providerCode

                this.updateCostAndPriceBasedOnCurrentDropdowns(true);





            },
            updateBundleWithDetails(bundleProducts, bundleDetails) {
                bundleProducts.forEach(bp => {
                    const bundle = bundleDetails.find(bd => bd.productType === bp.productType && bd.productPricingDetailsId === bp.productPricingDetailsId)
                    if (bundle) {
                        bp.price = bundle.productDetails.price
                    }
                })
            },
            setMinimumAndMaximum() {
                const selectedSurcharges = this.surcharges.filter(s => s.isChecked).map(s => s.code)

                this.minimum = this.defaultPricingDetails.getMinimumPrice(this.price, this.cost, this.modal.passedData.preloads, this.productDetails, this.modal.passedData.storeProducts, this.selectedRate, selectedSurcharges)
                this.maximum = Math.max(this.minimum, this.defaultPricingDetails.getMaximumPrice(this.price, this.cost, this.productDetails.isRegulated, this.modal.passedData.storeProducts, this.selectedRate, selectedSurcharges))
            },
            setToMinimum(index, postFunc) {
                //console.log('in setToMinimum before', this.price, this.minimum);
                //console.log('in setToMinimum index', index);
                if (index != null && typeof index !== 'undefined') {
                    this.dealTerms[index].price = this.dealTerms[index].minimum;
                }
                else {
                    this.price = this.minimum;
                }

                if (postFunc)
                    postFunc();

                //console.log('in setToMinimum after', this.price, this.minimum);
            },
            setToMaximum(index, postFunc) {
                //console.log('in setToMaximum before', this.price, this.maximum);
                if (index != null && typeof index !== 'undefined') {
                    this.dealTerms[index].price = this.dealTerms[index].maximum;
                }
                else {
                    this.price = this.maximum;
                }

                if (postFunc)
                    postFunc();

                //console.log('in setToMaximum after', this.price, this.maximum);
            },
            saveProductData(productInfo, productPricing, price, cost, selectedRate, bundleProducts) {
                const foundProgram = this.programsList?.find(p => p.code == this.program);
                const programObj = new CoverageTermProgram(foundProgram?.code, foundProgram?.description);

                if (this.pricedConfig) {
                    const selectedSurchagres = this.surcharges.filter(s => s.isChecked).map(s => s.code)
                    productInfo.setBundleWithInfo(this.provider, selectedRate, selectedSurchagres, this.defaultPricingDetails)
                    productPricing.setBundleWithPricing(price, cost, this.modal.passedData.preloads, selectedRate, selectedSurchagres, this.modal.passedData.storeProducts, this.defaultPricingDetails, true, bundleProducts)
                } else {
                    productInfo.netTerm = this.termLimit;
                    productInfo.termLimit = this.originalTermLimit;
                    productInfo.surcharges = this.surcharges.filter(s => s.isChecked).map(s => s.code)

                    productInfo.program = programObj

                    productInfo.netMiles = this.milesLimit;
                    productInfo.milesLimit = this.originalMilesLimit;
                    productInfo.subprogram = this.subprogram;

                    productPricing.price = price;
                    productPricing.cost = cost;
                }
            },
            close() {
                this.modal.passedData.coverageTerm[this.product] = this.included;

                if (this.dealTerms.length > 0) {
                    this.dealTerms.forEach((lt, index) => {
                        this.saveProductData(this.modal.passedData.coverageTerm[this.product + "InfoAndPricing"][index].termInfo, this.modal.passedData.coverageTerm[this.product + "InfoAndPricing"][index].termPricing, lt.price, lt.cost, lt.selectedRate, lt.bundleProducts)
                    });
                }
                else {
                    this.saveProductData(this.modal.passedData.coverageTerm[this.product + "Info"], this.modal.passedData.coverageTerm[this.product + "Pricing"], this.price, this.cost, this.selectedRate, this.bundleProducts)

                    if (this.vehicleOil) {
                        this.modal.passedData.setVehicleOil(this.vehicleOil);
                    }
                }
                $modal.close();
            },
            cancel() {
                $modal.cancel(this.needsRecoveryOnCancel);
            },
            formatPrice(value) {
                if (value != null && typeof value !== 'undefined' && !Number.isNaN(parseFloat(value))) {
                    return '$' + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,").toString()
                } else {
                    return '$0.00';
                }
            },
            updateOilTypes() {
                let surcharge = null;
                if (this.provider == "USW" && this.apiResponse[0]?.meta['Surcharge']) {
                    surcharge = JSON.parse(this.apiResponse[0].meta['Surcharge']);
                }

                if (surcharge) {
                    //console.log('surcharge', surcharge);
                    if (surcharge.syntheticOilBlend == null && surcharge.syntheticOil != null) {
                        this.oilTypes.forEach(o => {
                            if (o.name == "Regular") {
                                o.disabled = true;
                            }
                        });
                    }
                    if (surcharge.syntheticOilBlend == null && surcharge.syntheticOil == null) {
                        this.oilTypes.forEach(o => {
                            if (o.name == "Regular" || o.name == "Blend") {
                                o.disabled = true;
                            }
                        });
                    }
                    if (this.modal.passedData.vehicleOil == this.ENUMS.OIL_TYPES.DIESEL) {
                        this.oilTypes.forEach(o => {
                            if (o.name != "Diesel" && o.name != '-- Select Value --') {
                                o.disabled = true;
                            }
                        });
                    } else {
                        this.oilTypes.forEach(o => {
                            if (o.name == "Diesel") {
                                o.disabled = true;
                            }
                        });
                    }
                }
            }
        },
        components: {
            Panel,
            InputCurrency,
            InputRichDropdown,
            InputCheckbox,
            InputTextbox,
        }
    };
</script>
<style>
    .modal-editcoveragetermproduct {
    }

        .modal-editcoveragetermproduct .modal-footer {
            justify-content: space-between;
        }

            .modal-editcoveragetermproduct .modal-footer .plan-code {
                padding: 6px 15px;
                font-weight: 700;
                font-size: 0.85em;
                color: var(--disabled-color);
                background-color: var(--black-10percent);
                border: 2px dashed var( --black-30percent);
            }

                .modal-editcoveragetermproduct .modal-footer .plan-code strong {
                    font-weight: 900;
                    color: var( --black-70percent);
                }

        .modal-editcoveragetermproduct .gapped {
            display: flex;
            align-items: center;
            gap: 15px;
        }

        .modal-editcoveragetermproduct .divider {
            margin-top: 0px;
            margin-bottom: 5px;
        }

            .modal-editcoveragetermproduct .divider.no-bundle {
                margin-bottom: 15px;
            }

        .modal-editcoveragetermproduct .bundle-pieces-wrapper {
            padding: 10px 0px 5px 0;
            background-color: var(--collapseitem-coveragegrid-background-color);
        }

        .modal-editcoveragetermproduct .label {
            display: flex;
            align-items: center;
            padding-left: 10px;
        }

        .modal-editcoveragetermproduct .bundle-piece-row .not-sold {
            text-decoration: line-through;
        }

        .modal-editcoveragetermproduct .footer-details {
            justify-content: space-between !important;
        }
</style>
