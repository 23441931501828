export class StockNumberLookupResponse {
  actualCost?: number = null;
  comissionCost?: number = null;
  invoice?: number = null;
  msrp?: number = null;
  vin?: string = null;
  actualcashvalue?: number = null;
  baseinvoiceprice?: number = null;
  basreretailprice?: number = null;
  certified?: number = null;
  comissionprice?: number = null;
  holdbackamount?: number = null;
  makename?: string = null;
  mileage?: number = null;
  modelname?: string = null;
  stockno?: string = null;
  stocktype?: string = null;
  totaloptionscost?: number = null;
  totaloptionsinvoice?: number = null;
  totaloptionsretail?: number = null;
  year?: number = null;

  constructor(init?: StockNumberLookupResponse) {
    if (init) {
      // This should only be used on object shapes;
      Object.assign(this, init)
    }
  }
}