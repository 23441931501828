<template>

        <div class="sync-backdrop">
            <div class="sync-container">
                <img src="@static/img/cdkPush.svg" v-if="CDKPushing" />
                <img src="@static/img/cdkConnecting.svg" v-else />
                <div class="img-caption" v-if="CDKPushing">We are saving this Deal Information into CDK <br />Please wait until the process is complete.</div>
                <div class="img-caption" v-else>Establishing Connection with CDK <br />Please wait until the process is complete.</div>
            </div>

        </div>

</template>


<script>
    import api from '@core/services/api'
    import $modal from '@core/services/modal'
    import PanelLender from '@/components/PanelLender.vue'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import InputCurrency from '@core/components/InputCurrency.vue'
    import InputCheckbox from '@core/components/InputCheckbox.vue'
    import InputNumber from '@core/components/InputNumber.vue'
    import Panel from '@core/components/Panel.vue'
    import RichTable from '@core/components/RichTable.vue'
    import { required, between, minValue, maxValue } from '@vuelidate/validators'
    import moment from 'moment'
    import util from '@core/services/util'
    import modal from '@core/services/modal'
    import {useVuelidate} from '@vuelidate/core'

    export default {
        name: "CDKPushComponent",
        props: ['data', "postFunction", "CDKPushing"],
        setup(){
            // New setup for vuelidate. Swap $v with v$
            return {v$: useVuelidate({$scope: false})}
        },
        data() {
            return {
                infoToShow: []
            }
        },
        computed: {
            noResponse() {
                return this.infoToShow.syncOperations ? this.infoToShow.syncOperations.length > 0 : false
            }
        },
        created() {
            this.$watch(() => this.CDKPushing, (newValue) => {
                if (newValue) {
                    this.cdkSync()
                } else {
                    this.EventBus.emit("CDKPushFinish")
                    this.postFunction({})
                }
            })

        },

    methods: {
            cdkSync() {
                api.dart.sync(this.data).then((response) => {

                    if (response && response.data) {
                        //TODO show all responses
                        this.EventBus.emit("CDKPushFinish")
                        this.postFunction(response.data)
                    }

                }).catch(err => {
                     
                    console.error(err)
                });
            }
        },
        components: {
            //InputTextbox: InputTextbox,
            //RichTable: RichTable,
            //Panel: Panel,
            //InputNumber: InputNumber,
            //InputCurrency: InputCurrency,
            //PanelLender: PanelLender,
            //InputCheckbox: InputCheckbox
        }
    };
</script>
<style>
</style>
