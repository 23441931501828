import { VehicleInquiryReason, MessageType, DDIFlagsType } from '../SharedEnums'

export default class DDIResponseGetDealsPayload {
	public vin: string | null = null
	public reasonCode?: VehicleInquiryReason
	public plateNumber: string = ''
	public titleNumber: string = ''
	public documentId: string = ''
	public deal: DealResponse[] = []
	public message: Message[] = []
	public document: DDIDocument[] = []
	public documentType: DDIDocumentType[] = []
	public vehicleInquiryResult: DDIVehicleInquiryResponse[] = []

	constructor(init?: Partial<DDIResponseGetDealsPayload>) {
		Object.assign(this, init)
	}
}

class DealResponse {
	public dealId: string = ''
	public status: string = ''

	constructor(init?: Partial<DealResponse>) {
		Object.assign(this, init)
	}
}

class Message {
	public messageId: null = null
	public messageType?: MessageType
	public loanNumber: string = ''
	public dealId?: number
	public vin: string = ''
	public documentId: string = ''
	public documentType: string = ''
	public messageContent: string = ''
	public eventDate: Date = new Date()
	public responseExpected: boolean = false
	public stateFee?: number

	constructor(init?: Partial<Message>) {
		Object.assign(this, init)
	}
}
class DDIDocument {
	public documentId: string = ''
	public docmentType: string = '' // It's not a typo. It's how is in their API Response.
	public eventDescription: string = ''

	constructor(init?: Partial<DDIDocument>) {
		Object.assign(this, init)
	}
}

class DDIDocumentType {
	public documentTypeCode: string = ''
	public documentTypeDescription: string = ''

	constructor(init?: Partial<DDIDocumentType>) {
		Object.assign(this, init)
	}
}

export class DDIVehicleInquiryResponse {
	public type: string = ''
	public year: string = ''
	public make: string = ''
	public model: string = ''
	public vehicleType: string = ''
	public bodyStyle: string = ''
	public titleNumber: string = ''
	public controlNumber: string = ''
	public titleIssueDate: string = ''
	public titleStatus: string = ''
	public titleStatusReason: string = ''
	public titleType: string = ''
	public lienNumber: string = ''
	public odometer: string = ''
	public odometerBrands: string = ''
	public plateNumber: string = ''
	public plateExpiration: string = ''
	public registrationDate: string = ''
	public vehicleCounty: string = ''
	public purchasePrice: string = ''
	public rawOwnerName: string = ''
	public owner: DDIOwnerResponse[] = []
	public ownerCount: number = 0
	public lien: DDILienholderResponse[] = []
	public brands: string = ''
	public lienCount: string = ''
	public lienFlag?: DDIFlagsType
	public brandFlag?: DDIFlagsType
	public titleStatusFlag?: DDIFlagsType
	public errorCode: string = ''
	public message: string = ''
	public isETitle: string = ''
	public logNumber: string = ''
	public netWeight: string = ''
	public grossWeight: string = ''
	public majorColor: string = ''
	public minorColor: string = ''
	public plateType: string = ''
	public registrationExpirationDate: string = ''
	public registrationUse: string = ''
	public purchaseDate: string = ''

	constructor(init?: Partial<DDIVehicleInquiryResponse>) {
		Object.assign(this, init)
	}
}
class DDIOwnerResponse {
	public ownerName: string = ''
	public address1: string = ''
	public address2: string = ''
	public city: string = ''
	public state: string = ''
	public zipCode: string = ''
	public mailingAddress1: string = ''
	public mailingAddress2: string = ''
	public mailingCity: string = ''
	public mailingState: string = ''
	public mailingZipCode: string = ''
	public relationshipType: string = ''
	public ownerType: string = ''

	constructor(init?: Partial<DDIOwnerResponse>) {
		Object.assign(this, init)
	}
}

class DDILienholderResponse {
	public lienNumber: number = 0
	public lienholderNumber: number = 0
	public lienholderName: string = ''
	public lienDate: Date = new Date()
	public lienSatisfactionDate: Date = new Date()

	constructor(init?: Partial<DDILienholderResponse>) {
		Object.assign(this, init)
	}
}
