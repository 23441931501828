<template>
    <div class="customer-view-alert" :class="{'small': minimized, 'full-screen': !development}">
        <div class="container">
            <div v-if="!minimized">
                <div class="close" @click="minimizeHandler" v-if="development"><i class="fas fa-times"></i></div>
                <div class="title">Customer View Alerts:</div>
                <div v-if="customerViewAlert.noExist" class="dont-exist">
                    <span>
                        <i class="fas fa-window-close"></i>
                        Customer View does not exist
                    </span>
                    <button @click="openCustomerPopup('welcome')">Open</button>
                </div>
                <div class="movement-note">
                    <div>Use <i class="fab fa-windows"></i> <i class="fas fa-plus fa-xs"></i> <b>Shift</b> <i class="fas fa-plus fa-xs"></i> <i class="fas fa-arrow-right"></i> , <i class="fas fa-arrow-left"></i> to move the window between Screens</div>
                </div>
                <div v-if="customerViewAlert.noFullScreen" class="not-fullscreen">
                    <span>
                        <i class="fas fa-compress"></i>
                        Customer View is not on FullScreen Mode (Press F11)
                    </span>
                </div>
            </div>
            <div class="small-notification" v-else>
                <span class="fa-stack fa-2x exclamation-stack">
                    <i class="fas fa-circle fa-stack-2x"></i>
                    <i class="fas fa-exclamation fa-stack-1x fa-inverse"></i>
                </span>
                <div>
                    <i class="fas fa-desktop"></i>
                </div>
                <div class="expand-notification" @click="minimizeHandler">
                    <i class="fas fa-caret-up"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import settings from 'settings'

    export default {
        name: "PanelCustomerViewAlert",
        props: ["customerViewAlert", "openCustomerPopup"],
        data() {
            return {
                minimized: true
            }
        },
        computed: {
            development() {
                return settings.environmentName == 'DEVELOPMENT'
            }
        },

        mounted() {
            if (this.development) {
                this.minimized = true;
            } else {
                this.minimized = false;
            }

        },
        methods: {
            minimizeHandler() {
                this.minimized = !this.minimized
            }
        },
        components: {
        }
    };
</script>