<template>
    <div>
        <div :style="$grid('1.25-1.75')">
            <div :style="$grid('1')">
                <DealEmployeeRoles :fimenu="fimenu" :validation="validation"></DealEmployeeRoles>
            </div>
            <div :style="$grid('1')">
                <PanelMSVehicles :fimenu="fimenu" v-if="mscanAccount != null" :mscanAccount="mscanAccount"></PanelMSVehicles>
                <Panel title="Preloads" v-if="mscanAccount == null">
                    <PanelPreloads :fimenu="fimenu" :validation="validation"></PanelPreloads>
                </Panel>
                <PanelRebates :fimenu="fimenu" :validation="validation" :mscanAccount="mscanAccount"></PanelRebates>
            </div>
        </div>
        <div :style="$grid('1')">
            <PanelDesking :fimenu="fimenu" :validation="validation" v-if="mscanAccount != null" :mscanAccount="mscanAccount"></PanelDesking>
        </div>
    </div>

</template>

<script>
    import api from '@core/services/api'
    import util from '@core/services/util';
    import ENUMS from "@core/classes/Enums"
    import Panel from '@core/components/Panel.vue'
    import PanelPreloads from '@/components/PanelPreloads.vue'
    import DealEmployeeRoles from '@/components/DealEmployeeRoles.vue'
    import PanelRebates from '@/components/PanelRebates.vue'
    import PanelDesking from '@/components/PanelDesking.vue'
    import PanelMSVehicles from '@/components/PanelMSVehicles.vue'

    export default {
        name: "PanelDealDetails",
        props: ['fimenu','validation', 'mscanAccount'],
        data() {
            return {
            }
        },
        computed: {
            ENUMS() {
                return ENUMS;
            },
            util() {
                return util;
            },
        },


        methods: {

        },
        components: {
            Panel,
            PanelPreloads,
            DealEmployeeRoles,
            PanelRebates,
            PanelDesking,
            PanelMSVehicles
        }
    };
</script>