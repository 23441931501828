<template>
    <div v-if="readyToLoad" :class="{'section-container': true, 'carousel': true, 'animation': paymentAnimation}">
        <button @click="showPrevSection" class="back-button">Back</button>
        <infoModalsContainer ref="infoModalsContainer" v-if="infoModalsContainerOpen" :language="fimenu.language" :localization="localization" :closeInfoModalsContainer="closeInfoModalsContainer" :dealType="fimenu.dealType" :fimenu="fimenu" :productGroupSelected="productGroupSelected" :stopAudioHandler="stopAudioHandler"></infoModalsContainer>
        <terms-and-conditions-modal v-if="termsAndConditionsModalOpen" :localization="localization" :closeTermsAndConditionsModal="closeTermsAndConditionsModal" :fimenu="fimenu"></terms-and-conditions-modal>
        <!--<div v-if="infoModalsContainerOpen" class="coverage-info">
            <div class="modal-container">
                <div class="modal-header">
                    <span>Mechanical Failure Protection</span>
                </div>
                <div class="modal-body">
                    <div v-for="p in ['service', 'maintenance']" :key="p">
                        <div v-if="fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p]">
                            <div><span>Product Name: {{p.toUpperCase()}}</span></div>
                            <div><span>Provider Name: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].provider}}</span></div>
                            <div><span>Program Name: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].program.description}}</span></div>
                            <div><span>Month/Miles: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].termLimit}} / {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].milesLimit}}</span></div>
                            <div><span>Deductible: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].subprogram}}</span></div>
                            <div><span>Surcharges: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].surcharges && fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].surcharges.length != 0 ? fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].surcharges.join(', ') : 'Nothing'}}</span></div>

                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-container">
                <div class="modal-header">
                    <span>Keeping you on the Move</span>
                </div>
                <div class="modal-body">
                    <div v-for="p in ['titanium', 'towbuster']" :key="p">
                        <div v-if="fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p]">
                            <div><span>Product Name: {{p.toUpperCase()}}</span></div>
                            <div><span>Provider Name: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].provider}}</span></div>
                            <div><span>Program Name: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].program.description}}</span></div>
                            <div><span>Month/Miles: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].termLimit}} / {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].milesLimit}}</span></div>
                            <div><span>Deductible: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].subprogram}}</span></div>
                            <div><span>Surcharges: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].surcharges && fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].surcharges.length != 0 ? fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].surcharges.join(', ') : 'Nothing'}}</span></div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-container">
                <div class="modal-header">
                    <span>Future Resale Value Protection</span>
                </div>
                <div class="modal-body">
                    <div v-for="p in ['titanium', 'paint', 'wear']" :key="p">
                        <div v-if="fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p]">
                            <div><span>Product Name: {{p.toUpperCase()}}</span></div>
                            <div><span>Provider Name: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].provider}}</span></div>
                            <div><span>Program Name: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].program.description}}</span></div>
                            <div><span>Month/Miles: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].termLimit}} / {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].milesLimit}}</span></div>
                            <div><span>Deductible: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].subprogram}}</span></div>
                            <div><span>Surcharges: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].surcharges && fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].surcharges.length != 0 ? fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].surcharges.join(', ') : 'Nothing'}}</span></div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-container">
                <div class="modal-header">
                    <span>Total Loss Protection</span>
                </div>
                <div class="modal-body">
                    <div v-for="p in ['theft', 'skylink']" :key="p">
                        <div v-if="fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p]">
                            <div><span>Product Name: {{p.toUpperCase()}}</span></div>
                            <div><span>Provider Name: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].provider}}</span></div>
                            <div><span>Program Name: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].program.description}}</span></div>
                            <div><span>Month/Miles: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].termLimit}} / {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].milesLimit}}</span></div>
                            <div><span>Deductible: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].subprogram}}</span></div>
                            <div><span>Surcharges: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].surcharges && fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].surcharges.length != 0 ? fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex][p + 'Info'].surcharges.join(', ') : 'Nothing'}}</span></div>

                        </div>
                    </div>
                    <div v-if="fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex].gap">
                        <div><span>Product Name: GAP</span></div>
                        <div><span>Provider Name: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex].gapInfoAndPricing[0].termInfo.provider}}</span></div>
                        <div><span>Program Name: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex].gapInfoAndPricing[0].termInfo.program.description}}</span></div>
                        <div><span>Month/Miles: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex].gapInfoAndPricing[0].termInfo.termLimit}} / {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex].gapInfoAndPricing[0].termInfo.milesLimit}}</span></div>
                        <div><span>Deductible: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex].gapInfoAndPricing[0].termInfo.subprogram}}</span></div>
                        <div><span>Surcharges: {{fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex].gapInfoAndPricing[0].termInfo.surcharges && fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex].gapInfoAndPricing[0].termInfo.surcharges.length != 0 ? fimenu.coverageTerms[$refs.swiper.$swiper.activeIndex].gapInfoAndPricing[0].termInfo.surcharges.join(', ') : 'Nothing'}}</span></div>

                    </div>
                </div>
            </div>
            <div class="button-container">
                <button @click="closeInfoModalsContainer">Close</button>
            </div>

        </div>-->
        <div class="top-disclaimer">
            <p>{{ localization.topLegalDisclosure[fimenu.language] + disclaimerPaymentAndMonth[fimenu.language] }}</p>
            <p class="terms-and-conditions" @click="openTermsAndConditionsModal">TERMS AND CONDITIONS</p>
        </div>
        <div class="swiper-container">
            <div class="button-prev" @click="swipePrev">
            </div>
            <div class="button-next" @click="swipeNext">
            </div>
            <div class="arrow-prev" v-if="showPrevSliderButton">
                <i class="fas fa-chevron-left"></i>
            </div>
            <div class="arrow-next" v-if="showNextSliderButton">
                <i class="fas fa-chevron-right"></i>
            </div>
            <swiper class="swiper" :options="swiperOptions" ref="swiper" grab-cursor @slideChange="onSlideChange" @swiper="onSwiper">
                <swiper-slide v-for="(term,index) in terms" :key="term.terms.term">
                    <div class="card">
                        <div class="header">
                            <div class="main-header">{{ term.terms.term == 0 ? localization.customCoverage[fimenu.language] : (term.terms.term/12) + ' ' + localization.years[fimenu.language] }}</div>
                            <div class="alt-header" @click="openInfoModalsContainer"><i class="fas fa-info-circle"></i> {{ localization.coverageIncludes[fimenu.language] }}</div>
                        </div>
                        <div class="main">
                            <div :class="{ 'products-container': true, 'custom': (term.terms.term == 0) }">
                                <div class="product-group" v-for="pg in term.productGroups" :key="pg.title">
                                    <button v-if="term.terms.term != 0" class="mobile-coverage-modal-btn" @click="showCoverageModal(pg.name)"></button>
                                    <div class="title">{{ localization[pg.name][fimenu.language] }}</div>
                                    <div class="divider"></div>
                                    <div class="product-container" v-if="term.terms.term == 0 || pg.showBullets">
                                        <div class="product-list" v-for="product in pg.products" :key="product.line1">
                                            <div class="check">
                                                <i class="far fa-check-square" v-if="product.selected === true"></i>
                                                <i class="far fa-square" v-if="product.selected === false"></i>
                                            </div>
                                            <div class="product-text">
                                                <div class="product">{{ util.isNull(product.selected) ? '\u00A0' : localization[product.product] ? localization[product.product][fimenu.language] : product.product }}</div>
                                                <div class="program">{{ util.isNull(product.selected) ? '\u00A0' : localization[product.program] ? localization[product.program][fimenu.language] : product.program }}</div>
                                            </div>
                                            <div class="term-text">
                                                <div class="term" v-if="product.term">{{ typeof product.term == 'function' ? product.term() : product.term }}</div>
                                                <div class="miles" v-if="product.miles">{{ typeof product.miles == 'function' ? product.miles() : product.miles }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="{'group-icon': true, custom: term.terms.term == 0 || pg.showBullets}">
                                        <object id="productGroupSVG" :data="'@static/img/productGroupIcons/' + (term.terms.term == 0 ? pg.iconName + '_alt' : pg.iconName) + '.svg'" type="image/svg+xml"></object>
                                    </div>
                                </div>
                            </div>
                            <div class="footer">
                                <hr :class="term.terms.term == 0 ? 'custom-mobile-divider' : 'custom-mobile-divider-hidden'" />
                                <div class="title">{{ localization.selectPayment[fimenu.language] }}</div>
                                <div class="prices" :style="{justifyContent: coverageTermPrices(term.terms).length == 3 ? 'space-between' : 'space-around' }">
                                    <div :class="{'price-button': true, 'active': selectedPayment.term == term.terms && selectedPayment.paymentIndex == index,}" v-for="(ct, index) in coverageTermPrices(term.terms)" :key="ct.term" @click="selectPayment(term.terms, index)">
                                        <div class="price">{{ ct.price }}</div>
                                        <div class="payments" v-if="fimenu.dealType != 'Cash'">{{ ct.term == 0 ? localization.singlePayment[fimenu.language] : ct.term + ' ' + localization.payments[fimenu.language] }} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
        <div :class="{'bottom-disclaimer': true, 'large': fimenu.language == 'Spanish'}">
            <span v-if="frozen.dealType == 'Finance' && frozen.loanTerms.term1.term && frozen.loanTerms.term1.sellrate">{{ localization.termBasedFront[fimenu.language] + frozen.loanTerms.term1.term + localization.termBasedMiddle[fimenu.language] + frozen.loanTerms.term1.sellrate }}%. </span>
            <span v-if="frozen.dealType == 'Finance' && frozen.loanTerms.term2.term && frozen.loanTerms.term2.sellrate">{{ localization.termBasedFront[fimenu.language] + frozen.loanTerms.term1.term + localization.termBasedMiddle[fimenu.language] + frozen.loanTerms.term1.sellrate }}%. </span>
            <span v-if="frozen.dealType == 'Finance' && frozen.loanTerms.term3.term && frozen.loanTerms.term3.sellrate">{{ localization.termBasedFront[fimenu.language] + frozen.loanTerms.term1.term + localization.termBasedMiddle[fimenu.language] + frozen.loanTerms.term1.sellrate }}%. </span><br />
            {{ localization.legalDisclosure[fimenu.language] }}
        </div>
        <div :class="{'continue-btn-overlay': true, 'active': !util.isNull(selectedPayment.term) && !util.isNull(selectedPayment.paymentIndex)}">
            <button :class="{'continue-button': true, 'active': !util.isNull(selectedPayment.term) && !util.isNull(selectedPayment.paymentIndex)}" @click="nextSectionHandler">
                Confirm
            </button>
        </div>
    </div>

</template>

<script lang="ts">
    import { Virtual } from 'swiper';
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import 'swiper/swiper.css'
    // import 'swiper/css/virtual'
    import util from '@core/services/util'
    import infoModalsContainer from '@/modals/infoModalsContainer.vue'
    import TermsAndConditionsModal from '@/modals/TermsAndConditionsModal.vue'
    import CoverageTerm from "@core/classes/CoverageTerm"
import { defineComponent } from 'vue';



    export default defineComponent({
        name: 'PaymentSheetCarousel',
        props: ['stopAudioHandler', 'openSectionHandler', 'showPrevSection', 'showNextSection', 'frozen', 'fimenu', 'saveFunction', 'localization', 'paymentAnimation'],
        data() {
            return {
                readyToLoad: false,
                showNextSliderButton: false,
                showPrevSliderButton: false,
                infoModalsContainerOpen: false,
                termsAndConditionsModalOpen: false,
                productGroupSelected: null,
                terms: [],

                selectedPayment: {
                    term: null,
                    paymentIndex: null,
                },
                swiper: null,
                swiperOptions: {
                    modules:[Virtual],
                    virtual:true,
                    allowTouchMove: false,
                    effect: 'coverflow',
                    initialSlide: 0,
                    grabCursor: true,
                    centeredSlides: true,
                    slidesPerView: 'auto',
                    navigation: {
                        nextEl: '.button-next',
                        prevEl: '.button-prev',
                    },
                    loop: true,
                    coverflowEffect: {
                        rotate: 50,
                        stretch: -500,
                        depth: 50,
                        modifier: 1,
                        slideShadows: true,
                        transformEl: '.card'
                    },
                }
            }
        },
        computed: {

            util(): typeof util {
                return util
            },
            disclaimerPaymentAndMonth(): {[key:string]: string} {
                if (this.frozen.dealType === 'Lease') {
                    return {
                        English: util.formatPrice(this.getFinalNumbers(this.frozen.leaseTerms.term1, null).basePayment, 0) + ' FOR ' + this.frozen.leaseTerms.term1.term + ' MONTHS',
                        Spanish: util.formatPrice(this.getFinalNumbers(this.frozen.leaseTerms.term1, null).basePayment, 0) + ' POR ' + this.frozen.leaseTerms.term1.term + ' MESES'
                    }
                } else if (this.frozen.dealType === 'Finance') {
                    return {
                        English: util.formatPrice(this.getFinalNumbers(this.frozen.loanTerms.term1, null).basePayment, 0) + ' FOR ' + this.frozen.loanTerms.term1.term + ' MONTHS',
                        Spanish: util.formatPrice(this.getFinalNumbers(this.frozen.loanTerms.term1, null).basePayment, 0) + ' POR ' + this.frozen.loanTerms.term1.term + ' MESES',
                    }
                } else {
                    return {
                        English: util.formatPrice(this.frozen.otd, 0),
                        Spanish: util.formatPrice(this.frozen.otd, 0),
                    }
                }

            },

            shownCoverageTerms(): any {
                return this.frozen.coverageTerms.filter((c: any) => this.showSlide(c)).map((ct: any) => new CoverageTerm(ct));
            }


        },
        created() {
            this.init()

        },
        watch:{
            "frozen.coverageTerms"(){
                this.closeInfoModalsContainer()
                this.init()
            }
        },
        mounted() {
            setTimeout(() => {
                this.readyToLoad = true
            }, 300);
        },
        methods: {
            onSwiper(swiper: any){
                this.swiper = swiper;
                this.selectInitialSlide();
                // console.log("New swiper", swiper, this.terms);
            },
            closeTermsAndConditionsModal() {
                this.termsAndConditionsModalOpen = false;
            },
            showCoverageModal(productGroup: any) {
                this.productGroupSelected = productGroup
                this.openInfoModalsContainer();
            },
            getProductsDescriptionForMobile(products: any[]) {
                const productsDescriptions: any[] = []

                products.forEach((p: any, index: number) => {
                    const product: any = new Object();
                    product['productsDescriptionToShow'] = ''
                    product['style'] = p.selected ? 'product-selected' : 'product-not-selected'

                    //product description
                    product['productsDescriptionToShow'] += ' ' + (this.localization[p.product] ? this.localization[p.product][this.fimenu.language] : p.product)

                    //product program
                    if (p.selected) {
                        product['productsDescriptionToShow'] += ' ' + (this.localization[p.program] ? this.localization[p.program][this.fimenu.language] : p.program)

                        //product termLimit
                        if (p.term) {
                            product['productsDescriptionToShow'] += ' (' + (typeof p.term == 'function' ? p.term() : p.term)
                        }
                        //product milesLimit
                        if (p.miles) {
                            if (p.term) {
                                product['productsDescriptionToShow'] += ' / '
                            } else {
                                product['productsDescriptionToShow'] += ' ( '
                            }
                            product['productsDescriptionToShow'] += (typeof p.miles == 'function' ? p.miles() : p.miles)
                        }
                        if (p.term || p.miles) product['productsDescriptionToShow'] += ')'
                    }

                    if (index < products.length - 1) {
                        product['productsDescriptionToShow'] += ','
                    }

                    if (product.productsDescriptionToShow !== ' ') {
                        productsDescriptions.push(product)
                    }
                })


                return productsDescriptions
            },
            checkToShowSliderButton(activeIndex: number) {
                this.showPrevSliderButton = true;
                this.showNextSliderButton = true;
                if (activeIndex == 0) {
                    this.showPrevSliderButton = false;
                }
                if (activeIndex == this.terms.length - 1) {
                    this.showNextSliderButton = false;
                }

            },
            init() {
                this.terms = []
                this.shownCoverageTerms.map((ct: any) => {
                    ct.setAllProductsWithBundles()

                    const productGroups: any[] = [{
                        name: 'mechanicalFailureProt',
                        iconName: 'mechanicalFailureProt',
                        products: [],
                        showBullets: null
                    },
                    {
                        name: this.fimenu.dealType == 'Lease' ? 'resaleValueProtLease' : 'resaleValueProt',
                        iconName: 'resaleValueProt',
                        products: [],
                        showBullets: null
                    },
                    {
                        name: 'keepMoveProt',
                        iconName: 'keepMoveProt',
                        products: [],
                        showBullets: null
                    },
                    {
                        name: this.fimenu.dealType == 'Cash' ? 'totalLossProtCash' : 'totalLossProt',
                        iconName: 'totalLossProt',
                        products: [],
                        showBullets: null
                    }]

                    productGroups[0].products = [{
                        product: "service",
                        program: ct.serviceInfo.program.description,
                        term: () => ct.serviceInfo.netTerm ? ct.serviceInfo.netTerm + ' ' + this.localization.months[this.fimenu.language] : '-',
                        miles: () => ct.serviceInfo.netMiles && ct.serviceInfo.netMiles !== -1 ? util.formatUSNumbers(ct.serviceInfo.netMiles) + ' ' + this.localization.miles[this.fimenu.language] : '-',
                        selected: ct.service
                    },
                    {
                        product: "maintenance",
                        program: ct.maintenanceInfo.program.description,
                        term: () => ct.maintenanceInfo.netTerm ? ct.maintenanceInfo.netTerm + ' ' + this.localization.months[this.fimenu.language] : '-',
                        miles: () => ct.maintenanceInfo.netMiles && ct.maintenanceInfo.netMiles !== -1 ? util.formatUSNumbers(ct.maintenanceInfo.netMiles) + ' ' + this.localization.miles[this.fimenu.language] : '-',
                        selected: ct.maintenance
                    },
                    {
                        product: "",
                        program: "",
                        selected: null
                    }]
                    productGroups[0].showBullets = !(ct.service && ct.maintenance)
                    productGroups[1].products = this.frozen.dealType == 'Lease' ? [
                        {
                            product: "dingDent",
                            program: '',
                            term: () => ct.titaniumInfo.netTerm ? ct.titaniumInfo.netTerm + ' ' + this.localization.months[this.fimenu.language] : '-',
                            miles: '',
                            selected: ct.titanium
                        },
                        {
                            product: "paintInterior",
                            program: '',
                            term: () => ct.paintInfo.netTerm ? ct.paintInfo.netTerm + ' ' + this.localization.months[this.fimenu.language] : '-',
                            miles: '',
                            selected: ct.paint
                        },
                        {
                            product: "cosmeticWheels",
                            program: '',
                            term: () => ct.titaniumInfo.netTerm ? ct.titaniumInfo.netTerm + ' ' + this.localization.months[this.fimenu.language] : '-',
                            miles: '',
                            selected: ct.titanium
                        },
                        {
                            product: "wearTear",
                            program: '',
                            term: () => this.localization.termLease[this.fimenu.language],
                            miles: '',
                            selected: ct.wear
                        }] : [{
                            product: "dingDent",
                            program: '',
                            term: () => ct.titaniumInfo.netTerm ? ct.titaniumInfo.netTerm + ' ' + this.localization.months[this.fimenu.language] : '-',
                            miles: '',
                            selected: ct.titanium
                        },
                        {
                            product: "paintInterior",
                            program: '',
                            term: () => ct.paintInfo.netTerm ? ct.paintInfo.netTerm + ' ' + this.localization.months[this.fimenu.language] : '-',
                            miles: '',
                            selected: ct.paint
                        },
                        {
                            product: "cosmeticWheels",
                            program: '',
                            term: () => ct.titaniumInfo.netTerm ? ct.titaniumInfo.netTerm + ' ' + this.localization.months[this.fimenu.language] : '-',
                            miles: '',
                            selected: ct.titanium
                        }]
                    productGroups[1].showBullets = this.frozen.dealType == 'Lease' ? !(ct.titanium && ct.paint && ct.wear) : !(ct.titanium && ct.paint)
                    productGroups[2].products = [
                        {
                            product: "nitroTireRim",
                            program: "",
                            term: () => ct.titaniumInfo.netTerm ? ct.titaniumInfo.netTerm + ' ' + this.localization.months[this.fimenu.language] : '-',
                            miles: '',
                            selected: ct.titanium
                        },
                        {
                            product: "keyRemote",
                            program: "",
                            term: () => ct.titaniumInfo.netTerm ? ct.titaniumInfo.netTerm + ' ' + this.localization.months[this.fimenu.language] : '-',
                            miles: '',
                            selected: ct.titanium
                        },
                        {
                            product: "towbuster",
                            program: "",
                            term: () => ct.towbusterInfo.netTerm ? ct.towbusterInfo.netTerm + ' ' + this.localization.months[this.fimenu.language] : '-',
                            miles: '',
                            selected: ct.towbuster
                        },
                    ]
                    productGroups[2].showBullets = !(ct.titanium && ct.towbuster)
                    productGroups[3].products = [
                        {
                            product: "theftProtection",
                            program: "warning",
                            term: ct.theftInfo.program.description ? ct.theftInfo.program.description : "-",
                            miles: () => ct.theftInfo.netTerm ? ct.theftInfo.netTerm + ' ' + this.localization.months[this.fimenu.language] : '-',
                            selected: ct.theft
                        },
                        {
                            product: "lojack",
                            program: "tracking",
                            term: () => ct.skylinkInfo.netTerm ? ct.skylinkInfo.netTerm + ' ' + this.localization.months[this.fimenu.language] : '-',
                            miles: ct.skylinkInfo.program.description ? ct.skylinkInfo.program.description : '-',
                            selected: ct.skylink
                        },
                        {
                            product: "gap",
                            program: "gapDesc",
                            term: () => ct.gapInfoAndPricing.length > 0 ? this.localization[this.fimenu.isFinance() ? "termLoan" : "termLease"][this.fimenu.language] : ct.gapInfo.netTerm ? ct.gapInfo.netTerm + ' ' + this.localization.months[this.fimenu.language] : '-',
                            miles: '',
                            selected: ct.gap
                        }
                    ]
                    productGroups[3].showBullets = !(ct.theft && ct.skylink && ct.gap)
                    this.terms.push({ terms: ct, productGroups: [...productGroups] })
                })

                this.selectInitialSlide()
            },
            openTermsAndConditionsModal() {
                this.termsAndConditionsModalOpen = true;
            },
            openInfoModalsContainer() {
                this.infoModalsContainerOpen = true
            },
            closeInfoModalsContainer() {
                if (this.$refs.infoModalsContainer) {
                    (this.$refs.infoModalsContainer as any).$el.classList.add('close')
                    setTimeout(() => {
                        this.infoModalsContainerOpen = false;
                        this.productGroupSelected = null;
                    }, 400)
                }
            },
            swipeNext() {
                this.swiper?.slideNext(500)
            },
            swipePrev() {
                // console.log("Refs in swipe", this.swiper)
                this.swiper?.slidePrev(500)
            },
            selectInitialSlide() {
                let slider = 0
                if (this.frozen.dealType != 'Lease') {
                    const customCTIndex = this.frozen.coverageTerms.findIndex((ct: any) => ct.term === 0)
                    if (customCTIndex >= 0) {
                        slider = customCTIndex
                    } else {
                        const tempSlider = this.terms.findIndex(t => t.terms.preferred)
                        if (tempSlider >= 0) {
                            slider = tempSlider
                        }
                    }
                }

                if (this.swiper) {
                    this.swiper.slideTo(slider, 500)
                } else {
                    this.swiperOptions.initialSlide = slider
                }
                this.checkToShowSliderButton(slider)

            },
            coverageTermPrices(term: any) {
                const output: any[] = []
                if (this.frozen.dealType == "Lease") {
                    this.getLeaseAvailableTerms(term).forEach((leaseAT: any) => {
                        output.push({ term: leaseAT.term, price: util.formatPrice(this.getFinalNumbers(leaseAT, term).finalPayment, 0) })
                    })
                    return output;
                } else if (this.frozen.dealType == "Finance") {
                    this.frozen.loanTerms.availableTerms().forEach((loanAT: any) => {
                        output.push({ term: loanAT.term, price: util.formatPrice(this.getFinalNumbers(loanAT, term).finalPayment, 0) })
                    })
                    return output;
                } else {
                    return [{ term: 0, price: util.formatPrice(this.getFinalNumbers(null, term).finalAmount, 0) }]
                }
            },
            selectPayment(term: any, paymentIndex: number) {
                //this.selectedPayment.term = term
                //this.selectedPayment.paymentIndex = paymentIndex

                if (this.selectedPayment.term == term && this.selectedPayment.paymentIndex == paymentIndex) {
                    this.selectedPayment.term = null;
                    this.selectedPayment.paymentIndex = null;

                } else {
                    this.selectedPayment.term = term
                    this.selectedPayment.paymentIndex = paymentIndex
                }
            },
            getFinalNumbers(leaseOrLoanTerm: any, coverageTerm: any) {
                const isCDK = false
                const fimenu = this.frozen;
                const state = this.$global.selectedStore.storeState;
                return fimenu.finalNumbers(state, leaseOrLoanTerm, coverageTerm, isCDK);
            },
            getLeaseAvailableTerms(coverageTerm: any) {
                if (coverageTerm.term == 0) {
                    //for custom coverage, return all lease terms
                    return this.frozen.leaseTerms.availableTerms();
                }
                else {
                    //if it's a multiple of 12, then return the same term, if not, return the one above and below
                    return this.frozen.leaseTerms.availableTerms().filter((t: any) => t.term % 12 === 0 ? t.term === coverageTerm.term : Math.floor(t.term / 12) * 12 === coverageTerm.term || Math.ceil(t.term / 12) * 12 === coverageTerm.term);
                }
            },
            showSlide(coverageTerm: any) {
                return (this.frozen.dealType != "Lease" ||
                    this.getLeaseAvailableTerms(coverageTerm).length > 0
                )
            },
            nextSectionHandler() {
                this.saveFunction(this.selectedPayment.term.term, this.selectedPayment.paymentIndex + 1)
                this.stopAudioHandler()
            },
            onSlideChange() {
                this.selectedPayment.term = null;
                this.selectedPayment.paymentIndex = null;
                this.checkToShowSliderButton(this.swiper.activeIndex)
            }
        },
        components: {
            Swiper,
            SwiperSlide,
            infoModalsContainer,
            TermsAndConditionsModal
        }
    })


</script>
<style>

</style>