<template>
    <div class="header">

        <div class="header-left-container">
            <router-link to="/">
                <svg id="eZ3SfCc5ejg1"
                     class="logo"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink"
                     viewBox="0 0 350 280"
                     shape-rendering="geometricPrecision"
                     text-rendering="geometricPrecision">
                    <g id="eZ3SfCc5ejg2"
                       transform="matrix(1.117437 0 0 1.117437 -20.514875 5.575581)">
                        <g id="eZ3SfCc5ejg3"
                           transform="matrix(1 0 0 1 -105.311647 -26.135736)">
                            <path id="eZ3SfCc5ejg4" d="M206.015051,106.336332C220.404966,79.369544,248.82405,61,281.5,61C313.129798,61,340.770942,78.212136,355.560008,103.772986"
                                  transform="matrix(0.931401 -0.363995 0.363995 0.931401 -34.014604 112.514199)"
                                  fill="none"
                                  stroke="var(--saturn-icon-color)"
                                  stroke-width="9"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="3"
                                  stroke-dasharray="0,0" />
                        </g>
                        <g id="eZ3SfCc5ejg5"
                           transform="matrix(1 0 0 1 -105.311647 -26.135737)">
                            <path id="eZ3SfCc5ejg6" d="M225.834518,212.281032C174.869578,225.028648,136.24095,224.474202,129.594,208.296C120.074,185.125,179.74,138.623,262.751,104.517C345.762,70.411,420.886,61.533,430.406,84.704C436.903712,100.518967,411.171057,127.202704,367.783573,153.395505"
                                  fill="none"
                                  stroke="var(--saturn-icon-color)"
                                  stroke-width="9"
                                  stroke-linecap="round"
                                  stroke-miterlimit="3" />
                            <path id="eZ3SfCc5ejg7"
                                  d=""
                                  fill="none"
                                  stroke="var(--saturn-icon-color)"
                                  stroke-width="9"
                                  stroke-linecap="round"
                                  stroke-miterlimit="3" />
                            <path id="eZ3SfCc5ejg8"
                                  d=""
                                  fill="none"
                                  stroke="var(--saturn-icon-color)"
                                  stroke-width="9"
                                  stroke-linecap="round"
                                  stroke-miterlimit="3" />
                        </g>
                        <g id="eZ3SfCc5ejg9"
                           transform="matrix(0.999992 -0.003927 0.003927 0.999992 -105.884838 -25.032991)">
                            <path id="eZ3SfCc5ejg10" d="M211.262044,148.269465C228.092922,138.870761,248.015527,129.268474,269.751,120.338C292.013338,111.191517,313.418732,103.88334,332.328704,98.710571"
                                  fill="none"
                                  stroke="var(--saturn-icon-color)"
                                  stroke-width="9"
                                  stroke-linecap="round"
                                  stroke-miterlimit="3" />
                            <path id="eZ3SfCc5ejg11"
                                  d=""
                                  fill="none"
                                  stroke="var(--saturn-icon-color)"
                                  stroke-width="9"
                                  stroke-linecap="round"
                                  stroke-miterlimit="3" />
                            <path id="eZ3SfCc5ejg12"
                                  d="M352.670272,93.76436C356.70916,92.919404,360.567541,92.20465,364.222577,91.62424"
                                  fill="none"
                                  stroke="var(--saturn-icon-color)"
                                  stroke-width="9"
                                  stroke-linecap="round"
                                  stroke-miterlimit="3" />
                            <path id="eZ3SfCc5ejg13"
                                  d=""
                                  fill="none"
                                  stroke="var(--saturn-icon-color)"
                                  stroke-width="9"
                                  stroke-linecap="round"
                                  stroke-miterlimit="3" />
                            <path id="eZ3SfCc5ejg14"
                                  d=""
                                  fill="none"
                                  stroke="var(--saturn-icon-color)"
                                  stroke-width="9"
                                  stroke-linecap="round"
                                  stroke-miterlimit="3" />
                            <path id="eZ3SfCc5ejg15" d="M381.556392,89.829062C393.363214,89.445624,401.033818,91.466732,402.971,96.182C406.301973,104.289876,391.977703,118.650382,366.807929,134.602171"
                                  fill="none"
                                  stroke="var(--saturn-icon-color)"
                                  stroke-width="9"
                                  stroke-linecap="round"
                                  stroke-miterlimit="3" />
                            <path id="eZ3SfCc5ejg16"
                                  d=""
                                  fill="none"
                                  stroke="var(--saturn-icon-color)"
                                  stroke-width="9"
                                  stroke-linecap="round"
                                  stroke-miterlimit="3" />
                            <path id="eZ3SfCc5ejg17"
                                  d=""
                                  fill="none"
                                  stroke="var(--saturn-icon-color)"
                                  stroke-width="9"
                                  stroke-linecap="round"
                                  stroke-miterlimit="3" />
                            <path id="eZ3SfCc5ejg18" d="M212.638431,198.300199C182.518638,205.070471,161.43126,205.099393,158.029,196.818C154.738668,188.809047,168.675387,174.69911,193.275645,158.980989"
                                  fill="none"
                                  stroke="var(--saturn-icon-color)"
                                  stroke-width="9"
                                  stroke-linecap="round"
                                  stroke-miterlimit="3" />
                        </g>
                        <g id="eZ3SfCc5ejg19"
                           transform="matrix(1 0 0 1 -105.311647 -26.135737)">
                            <path id="eZ3SfCc5ejg20" d="M367,146.5C367,193.689,328.689,232,281.5,232C234.311,232,196,193.689,196,146.5C195.617338,133.210694,366.607657,132.048891,367,146.5"
                                  transform="matrix(0.925464 -0.378835 0.378835 0.925464 -34.517495 117.561391)"
                                  fill="none"
                                  stroke="var(--saturn-icon-color)"
                                  stroke-width="9"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="3"
                                  stroke-dasharray="0,0" />
                            <path id="eZ3SfCc5ejg21"
                                  d="M302.866404,221.160142C308.765347,219.291475,314.058617,216.910404,318.801321,214.1645"
                                  transform="matrix(0.990378 -0.138386 0.138386 0.990378 -23.248908 35.348814)"
                                  fill="none"
                                  stroke="var(--saturn-icon-color)"
                                  stroke-width="9"
                                  stroke-linecap="round" />
                            <path id="eZ3SfCc5ejg22"
                                  d=""
                                  transform="matrix(0.990378 -0.138386 0.138386 0.990378 -23.248908 35.348814)"
                                  fill="none"
                                  stroke="var(--saturn-icon-color)"
                                  stroke-width="9"
                                  stroke-linecap="round" />
                            <path id="eZ3SfCc5ejg23"
                                  d="M331.417484,204.993684C345.18185,192.608916,352.081219,177.211422,354.957166,166.410573"
                                  transform="matrix(0.990378 -0.138386 0.138386 0.990378 -23.248908 35.348814)"
                                  fill="none"
                                  stroke="var(--saturn-icon-color)"
                                  stroke-width="9"
                                  stroke-linecap="round" />
                            <path id="eZ3SfCc5ejg24"
                                  d=""
                                  transform="matrix(0.990378 -0.138386 0.138386 0.990378 -23.248908 35.348814)"
                                  fill="none"
                                  stroke="var(--saturn-icon-color)"
                                  stroke-width="9"
                                  stroke-linecap="round" />
                        </g>
                    </g>
                </svg>
            </router-link>
            <!--<span @click.stop="toggleMenu" class="menu-icon"><span><i class="fas fa-bars"></i></span> Menu</span>-->
        </div>

        <div v-if="payload.EmployeeAccess.IsAdmin && (isAdminViewRoute || isAdminRoute)" class="extra-settings slide-in">
            <TooltipComponent v-if="isAdminViewRoute"
                              :tooltip="adminViewToolTip"
                              direction="bottom">
                <template #showFromOutside>
                    <InputCheckbox v-model="$global.isAdminView" label="Admin view" />
                </template>
            </TooltipComponent>

            <InputCheckbox v-if="isAdminRoute" v-model="$global.isManageOverridesEnabled" label="Managing settings overrides" />
        </div>

        <div class="header-bar">
            <ConnectionSpeedTester />
            <!--<StoreSelector/>-->
            <UserInfo />
        </div>
    </div>
</template>

<script>
    import auth from '@core/services/auth'
    import InputCheckbox from '@core/components/InputCheckbox.vue'
    import TooltipComponent from "@core/components/TooltipComponent.vue";
    import UserInfo from '@/components/UserInfo.vue'
    import ConnectionSpeedTester from '@/components/ConnectionSpeedTester.vue'
    import settings from 'settings'

    export default {
        name: "TheHeader",
        data() {
            return {
                defaultStore: null,
            };
        },
        computed: {
            payload() {
                return auth.getTokenPayload();
            },
            isAdminRoute() {
                return ["admin", "utilities"].some(routeName => this.$route.name?.includes(routeName))
            },
            isAdminViewRoute() {
                return ["customer", "fimenu", "cancelCoverage", "reports", "customers"].some(routeName => this.$route.name === routeName)
            },
            adminViewToolTip() {
                return [
                    "Rating and pricing will not affect numbers as if deal was locked"
                ]
            }
        },
        created() {
            this.$global.stores = settings.userStores;

            //SELECT DEFAULT STORE
            if (this.$route.query.storeCode && this.$route.query.storeCode != '') {
                this.defaultStore = this.$global.stores.find(s => s.storeCode == this.$route.query.storeCode);
            }
            else if (this.payload.EmployeeAccess?.StoreCodes?.length) {
                this.defaultStore = this.$global.stores.find(s => s.storeCode == this.payload.EmployeeAccess.StoreCodes[0]);
            }
            else {
                this.defaultStore = this.$global.stores[0];
            }
            this.$global.selectedStore = this.defaultStore;
        },
        methods: {
            toggleMenu() {
                this.EventBus.emit("toggleMenu", null);
            }
        },
        components: {
            InputCheckbox,
            UserInfo,
            ConnectionSpeedTester,
            TooltipComponent
        }
    }
</script>
<style>
    .header .extra-settings {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        background-color: var(--background-color);
        width: fit-content;
        padding: 10px;
        z-index: 999999999999999;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: 0 2px 4px 0px var(--black-50percent);
        align-self: start;
    }

    .header .slide-in {
        animation: 0.4s ease slidein;
    }

    @keyframes slidein {
        from { transform: translateY(-100%); }
    }
</style>
