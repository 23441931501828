<template>
    <div class="paperwork-creation">
        <div>
            <img class="paperwork-image" src="@static/img/PAPERWORK-ANIM.svg" alt="Creating PaperWork Icon" />
            <div class="paperwork-caption">We are {{ paperworkActionMessage }} the paperwork.<br />Please wait until the process is complete.</div>
            <button v-if="false">Cancel Paperwork Creation</button>
        </div>

    </div>
</template>

<script>

    export default {
        name: "PaperWorkCreation",
        props: ['paperworkActionMessage'],
        data() {
            return {
            }
        },
        computed: {

        },

        methods: {

        },
        components: {
        }
    };
</script>