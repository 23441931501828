<template>
    <section class="PanelAccounting accounting">
        <IsBusySectionComponent v-if="isBusy" :text="getIsBusyText" delayToShowText="100" />

        <div v-else class="accounting-container" id="accountingContainer" :key="hasUnmappedAmount ? 'test': 'fafa'">

            <div class="container-header">
                <h3 class="type-text" :style="{'margin': 0}">{{ getHeaderMessage }}</h3>
                <div v-if="!dealPosted" class="header-buttons">
                    <button :class="{
                                    'button-edit' : true,
                                    'revert-status' : true,
                                    'hint-click' : !isDealInAccounting && statusMessageIndex === null,
                                    'button-unwind' : isDealInAccounting || statusMessageIndex === 0,
                                    'primary': statusMessageIndex === 1,
                                }"
                            @click="changeStatusHandler"
                            :disabled="isBusyChangingStatus">
                        {{ reverseButtonMessage }}
                    </button>
                    <button class="button button-edit"
                            :disabled="!isDealInAccounting"
                            @click="() => init(true)">
                        Reevaluate Deal
                    </button>


                </div>
                <button v-if="isUserInDev && hasUnmappedAmount" class="button-load" @click="mapAccountingItems()">
                    Map Accounting items
                </button>
            </div>

            <div class="content-helper-container">
                <InputCheckbox v-model="showHidden" label="Show Zero Amounts" />
                <input v-model="searchText" placeholder="Search" class="search-input" @input="handleSearchQuery" />

            </div>
            <template v-if="categorizedDealValues.length">
                <AccordionList :list="categorizedDealValues"
                               :closeOtherOnOpen="!hasSearchText"
                               :defaultExpanded="hasSearchText"
                               listLabel="name"
                               ref="accordionList"
                               @change="(index) => updateSelectedAccordionState(index)">

                    <template #listHeader="{ headerItem: category }">
                        <button v-if="getUnmappedInCategory(category) && !isIgnoredList(category)"
                                class="button-unwind hint-click"
                                @click.stop="goToNextErrorForAccordion(category)">
                            {{ getUnmappedInCategory(category) }}
                        </button>
                    </template>

                    <template #listBody="{bodyItem: category, index: categoryIndex}">

                        <div class="category-details" v-if="hasAccountingItems(category)" :ref="categoryIndex">
                            <RichTable v-if="category.items && category.items.length"
                                       :headers="accountingNumbersHeader"
                                       :tableData="itemsToShowForCategory(category)"
                                       class="smaller-text">

                                <template #accountNumber="account">
                                    <div class="account-number" :id="getErrorElementId(account)">
                                        <span v-if="account.accountNumber">{{ account.accountNumber }}</span>
                                        <span v-else>
                                            <i class="fas fa-exclamation-circle" />
                                        </span>
                                    </div>
                                </template>

                                <template #type="account">
                                    <div class="amount-container">
                                        <span>{{ ENUMS.ACCOUNTING_ACCOUNT_TYPE.getDisplay(account.type) }}</span>
                                    </div>
                                </template>

                                <template #items="account">
                                    <div class="amount-container">
                                        <span>{{ typeof account.items }}</span>
                                    </div>
                                </template>

                                <template #amount="account">
                                    <div class="amount-container">
                                        <span>{{ formatAmount(account.finalAmount) }}</span>
                                    </div>
                                </template>

                            </RichTable>

                            <div v-else-if="searchText" class="warning-container warning-unmapped">
                                <span class="pulsate">No items match your search.</span>
                            </div>

                            <div v-else class="warning-container warning-unmapped">
                                <span class="pulsate">Not Mapped</span>
                            </div>

                            <div class="auto-scroller" v-seen="(observer) => showMoreItemsForCategory(category, observer)"></div>

                        </div>
                        <div v-else class="no-items-container">
                            <span>No mapped items have been found.</span>
                        </div>

                        <div v-if="showHidden" class="hidden-items-container">
                            <h3 class="zero-amounts">Zero Amounts</h3>

                            <RichTable v-if="category.zeroValuesList.length"
                                       :headers="zeroValuesTableHeaders"
                                       :tableData="category.zeroValuesList">

                                <template #amount="account">
                                    <div class="amount-container">
                                        <span>{{ formatAmount(account.finalAmount) }}</span>
                                    </div>
                                </template>

                            </RichTable>
                            <div v-else class="no-items-container">
                                <span>There are no zero amount values for this category.</span>
                            </div>
                        </div>

                    </template>

                </AccordionList>

                <div :class="feedbackClassName">
                    <span class="pulsate" v-if="selectedAccordion == null && hasUnmappedAmount">
                        {{ hoveredMenuFeedbackMessage }}
                    </span>
                    <div v-else-if="isUserReadyToFinalize">
                        <InputCheckbox v-model="hasUserConfirmedPaste"
                                       label="I confirm that I pasted these EXACT values into CDK"
                                       :cssClass="confirmationCheckboxClass" />
                    </div>
                </div>

                <div class="buttons-container">

                    <button v-if="!isPosted"
                            @click="() => copyToClipboard()"
                            :class="{'post-btn hint-click': true, 'button-save' : copied ,'button-edit' : !copied}"
                            :disabled="!isPostReady || hasUserConfirmedPaste">

                        <i v-if="!copied" class="fas fa-vote-yea" />
                        <span v-if="!copied">Post & Copy</span>
                        <span v-else>Copied!</span>

                    </button>
                    <button v-else
                            :class="{'unwind': true, 'button-save' : copied, 'button-unwind' : !copied}"
                            :disabled="hasUserConfirmedPaste"
                            @click="() => copyToClipboard(true)">

                        <div v-if="!copied" class="reverse-icon-container">
                            <div class="reverse-icon">
                                <i class="fas fa-vote-yea" />
                                <span><i class="fas fa-times" /></span>
                            </div>
                        </div>

                        <span v-if="!copied">Reverse & Copy</span>
                        <span v-else>Copied!</span>
                    </button>

                    <button class="button-edit"
                            @click="downloadPostingTemplate()"
                            :disabled="hasUnmappedAmount || !hasUserConfirmedPaste">
                        <i class="fas fa-download" />
                        <span>Download</span>
                    </button>

                    <button v-if="hasNotFinalizedAccounting"
                            @click="() => handleFinalize()"
                            class="button-save hint-click"
                            :disabled="!hasUserConfirmedPaste || isBusyToggleFinalize">

                        <i class="fas fa-check-double" />
                        <span v-if="!isBusyToggleFinalize">Finalize</span>
                        <span v-else>Finalizing</span>
                    </button>
                    <button v-else
                            @click="() => handleFinalize(true)"
                            class="button-unwind unwind"
                            :disabled="!hasUserConfirmedPaste || isBusyToggleFinalize">
                        <i class="fas fa-history" />
                        <span v-if="!isBusyToggleFinalize">Unwind</span>
                        <span v-else>Unwinding</span>
                    </button>

                </div>
            </template>
            <template v-else>
                <h2 class="not-found-title">
                    No accounting items found under '<span class="searchText">{{ searchText }}</span>'
                </h2>
            </template>
        </div>
    </section>
</template>

<script>
    import ENUMS from "@core/classes/Enums";
    import api from '@core/services/api';
    import auth from '@core/services/auth';
    import util from '@core/services/util';
    import settings from "settings";
    import { _ } from 'vue-underscore';

    import RichTable from '@core/components/RichTable.vue';
    import IsBusySectionComponent from '@core/components/IsBusySectionComponent.vue';
    import AccordionList from '@core/components/AccordionList.vue';
    import InputCheckbox from '@core/components/InputCheckbox.vue';
    import IntersectionObserverDirective from '@core/directives/intersection-observer-directive';

    class Category {
        constructor(init) {
            this.items = [];
            this.zeroValuesList = [];

            if (init) {
                this.name = ENUMS.ACCOUNTING_VALUE_CATEGORIES.getDisplay(init.category);
                this.pageSize = init.pageSize;
                this.category = init.category;
                this.isIncluded = init.isIncluded ?? true;
            }

        }
    }
    export default {
        name: "PanelAccounting",
        props: {
            fimenu: {
                required: true,
                type: Object,
            },
            dealSave: {
                required: true,
                type: Function,
            }
        },
        data() {
            return {
                DEFAULT_PAGE_SIZE: 10,
                //isBusy: Boolean | String;
                isBusy: false,
                isPosting: false,
                isBusyToggleFinalize: false,
                isUserReadyToFinalize: false,
                isBusyChangingStatus: false,
                hasUserConfirmedPaste: false,
                showHidden: false,
                dealPosted: false,
                copied: false,

                categorizedDealValues: [],
                filteredList: null,
                selectedAccordion: null,
                searchText: null,
                statusMessageIndex: null,
                statusButtonMessages: ["Reversing To F&I..", "Bringing Back To Accounting..."],
                accountingNumbersHeader: [
                    {
                        name: "name",
                        display: "Name",
                        value: (row) => this.formatPath(row.name),
                        sortable: true,
                        cssClass: "name-cell",
                    },
                    {
                        name: "accountNumber",
                        display: "Acct. Number",
                        sortable: true,
                        slot: 'accountNumber',
                        cssClass: "smaller-cell",
                    },
                    {
                        name: "type",
                        display: "Acct. Type",
                        sortable: true,
                        slot: 'type',
                        cssClass: "smaller-cell",
                    },
                    {
                        name: "companyResult",
                        display: "Company",
                        cssClass: "smaller-cell",
                    },
                    {
                        name: "control1Result",
                        display: "Control 1",
                        cssClass: "",
                    },
                    {
                        name: "control2Result",
                        display: "Control 2",
                        cssClass: "",
                    },
                    {
                        name: "descriptionResult",
                        display: "Description",
                        cssClass: "",
                    },
                    {
                        name: "finalAmount",
                        display: "Amount",
                        slot: "amount",
                        cssClass: "",
                    },
                    //{
                    //    name: 'edit',
                    //    display: '',
                    //    slot: 'editIcons',
                    //    class: "edit-button"
                    //},
                ],
                zeroValuesTableHeaders: [
                    {
                        name: "name",
                        display: "Name",
                        value: (row) => this.formatPath(row.name),
                        sortable: true,
                        class: "",
                    },
                    {
                        name: "finalAmount",
                        display: "Amount",
                        slot: "amount",
                        class: "wide-column-75",
                    },
                ],
            }
        },

        computed: {
            ENUMS() {
                return ENUMS;
            },
            util() {
                return util;
            },
            payload() {
                return auth.getTokenPayload();
            },
            storeReferenceNumber() {
                return this.fimenu.store.storeAccountingInfo.referenceNumber;
            },
            feedbackClassName() {
                let className = "feedback";

                if (this.selectedAccordion == null && this.hasUnmappedAmount && !this.hasSearchText)
                    return className + " expanded errors-found";

                if (this.isUserReadyToFinalize && !this.hasSearchText)
                    className += " expanded"

                return className;
            },
            confirmationCheckboxClass() {
                if (!this.hasUserConfirmedPaste) return "invalid"

                return "";
            },
            reverseButtonMessage() {
                if (this.statusMessageIndex !== null) return this.statusButtonMessages[this.statusMessageIndex];
                if (this.isDealInAccounting) return "Reverse Back To F&I"
                else return "Bring Back To Accounting"
            },
            getHeaderMessage() {
                //Status Change Header Messages
                if (this.statusMessageIndex === 0) return "Reversing this deal back to the F&I Manager...";
                else if (this.statusMessageIndex === 1) return "Retrieving this deal back from the F&I Manager...";
                else if (!this.isDealInAccounting) return "This deal has been reversed";

                //Accounting Status Header Messages
                else if (this.searchText) return "Searching through the deal values...";
                else if (this.hasUnmappedAmount) return "Accounting work pending";
                else if (this.isPostReady) return "This deal is ready for posting";
                else return "Deal Is Posted and Finalized";
            },
            hoveredMenuFeedbackMessage() {
                const unmappedCount = this.getTotalUnmapped();

                return `You have ${unmappedCount} unmapped item${unmappedCount === 1 ? '' : "s"}. To post, map all the remaining items.`
            },

            hasUnmappedAmount() {
                return this.categorizedDealValues.filter(cat => cat.isIncluded).some(cat => cat.items.some(i => !i.accountNumber));
            },
            hasSearchText() {
                return this.searchText && this.searchText.length >= 3;
            },
            hasNotFinalizedAccounting() {
                return this.fimenu.dealStatus < ENUMS.DEAL_STATUS.ACCOUNTING_FINISHED;
            },
            isDealInAccounting() {
                return this.fimenu.dealStatus >= ENUMS.DEAL_STATUS.ACCOUNTING_IN_PROCESS;
            },
            isPostReady() {
                return !this.dealPosted && !this.hasUnmappedAmount && this.isDealInAccounting;
            },
            isPosted() {
                return this.dealPosted && !this.hasUnmappedAmount;
            },
            isUserInDev() {
                return settings.environmentName === "DEVELOPMENT";
            },
            getIsBusyText() {
                return typeof this.isBusy === "string" ? this.isBusy : "Please wait while load the accounting details...";
            },
        },
        async created() {
            await this.init();
        },

        methods: {
            async init(reevaluate = false) {
                try {
                    this.isBusy = true;
                    if (!this.fimenu.accountingItems || reevaluate) {
                        const { data: accountingValues } = await api.fimenu.getDealAccountingValues(this.fimenu);
                        this.fimenu.storeAccountingItems(accountingValues);
                        util.toastr('success', 'Retrieving Accounting Value', 'Successfully retrieved accounting values for this deal.')
                        // eslint-disable-next-line no-console
                        console.log("Accounting Values =>", accountingValues);
                    }

                    this.populateCategories(this.fimenu.accountingItems);
                    this.isBusy = false;
                } catch (error) {
                     
                    console.error(error);
                    util.toastr('error', 'Retrieving Accounting Value', error)
                }

                this.dealPosted = this.fimenu.isAccountingFinalized();
            },
            async toggleStatus() {
                const originalStatus = this.fimenu.dealStatus;
                try {
                    if (this.isDealInAccounting)
                        this.fimenu.dealStatus = ENUMS.DEAL_STATUS.FI_IN_PROCESS;
                    else
                        this.fimenu.dealStatus = ENUMS.DEAL_STATUS.ACCOUNTING_IN_PROCESS;
                    this.isBusyChangingStatus = true;
                    await this.dealSave();
                } catch (error) {
                     
                    console.error(error);
                    this.fimenu.dealStatus = originalStatus;
                }
                finally {
                    this.isBusyChangingStatus = false;
                }
            },
            async changeStatusHandler() {
                if (this.isDealInAccounting) {
                    this.isBusy = "Reversing Deal Back to F&I..."
                    this.statusMessageIndex = 0;
                }
                else {
                    this.isBusy = "Bringing Deal Back To Accounting..."
                    this.statusMessageIndex = 1;
                }

                await this.toggleStatus();
                this.isBusy = false;
                this.statusMessageIndex = null
            },
            async handleFinalize(isReversed = false) {
                const originalStatus = this.fimenu.dealStatus;
                this.isBusyToggleFinalize = true;

                const objToSave = {
                    accountingItems: this.fimenu.accountingItems,
                    id: this.fimenu.id,
                    isReversed: isReversed,
                    employeeCode: this.payload.EmployeeCode
                }
                try {
                    if (!isReversed) {
                        this.isBusy = "Posting this deal...";
                        this.isPosting = true;
                        //POST ACCOUNTING NUMBERS
                        this.fimenu.dealStatus = ENUMS.DEAL_STATUS.ACCOUNTING_FINISHED;
                        await api.fimenu.saveDealAccountingValues(objToSave);
                        this.isPosting = false;
                        this.dealPosted = true;
                    } else {
                        this.dealPosted = false;
                        this.fimenu.dealStatus = ENUMS.DEAL_STATUS.ACCOUNTING_IN_PROCESS;
                    }
                    const message = `Successfully ${isReversed ? 'reversed' : 'posted'} accounting items.`;
                    this.isBusy = false;
                    this.dealSave();

                    util.toastr('success', 'Post Status:', message);
                } catch (err) {
                    this.fimenu.dealStatus = originalStatus;
                    util.toastr('error', 'Post Status:', err)
                }
                finally {
                    if (isReversed) {
                        this.fimenu.accountingItems = null;
                        await this.init();
                    }
                    this.hasUserConfirmedPaste = false;
                    this.isUserReadyToFinalize = false;
                    this.isBusyToggleFinalize = false;
                }
            },
            async downloadPostingTemplate() {
                try {
                    //is deal is already posted, we're reversing
                    const isReversed = this.dealPosted;

                    const accountingItems = []
                    this.categorizedDealValues.forEach((category) => accountingItems.push(...category.items));
                    const postReq = {
                        accountingItems,
                        isReversed: isReversed,
                        //add field for company column. Company will be the reference accounting number for that store (Aventura-123).
                        storeReferenceNumber: this.storeReferenceNumber,
                    }
                    // eslint-disable-next-line no-console
                    console.log("accountingItems", accountingItems); // FIXME:
                    await api.fimenu.downloadPostTemplate(postReq);
                    util.toastr("success", "Downloading", "Successfully downloaded Posting Template PDF.")
                }
                catch (err) {
                     
                    console.error(err);
                    util.toastr("error", "Downloading", "Unable to generate a downloadable PDF.")
                }
            },

            populateCategories(accountingValues) {
                const categoriesSet = new Set();
                const categorizedAccountingValues = [];
                const ignoredCategory = new Category({
                    name: "Ignored",
                    pageSize: this.DEFAULT_PAGE_SIZE,
                    isIncluded: false,
                    category: ENUMS.ACCOUNTING_VALUE_CATEGORIES.IGNORED,
                });


                //GET UNIQUE SET OF CATEGORIES
                accountingValues.forEach((c) => {
                    categoriesSet.add(c.category);
                });
                const catArr = Array.from(categoriesSet);

                //FOR EACH, CREATE A CATEGORY OBJECT
                catArr.forEach((c) => {
                    const categoryObject = new Category({
                        name: ENUMS.ACCOUNTING_VALUE_CATEGORIES.getDisplay(c),
                        category: c,
                        pageSize: this.DEFAULT_PAGE_SIZE,
                        isIncluded: true,
                    });

                    const accountingItems = accountingValues.filter(item => item.category === c);
                    accountingItems.forEach((item) => {

                        const accountingObj = {
                            name: item.name,
                            category: c,
                            finalAmount: 0,
                        }

                        //ACCOUNTING ITEM IS IGNORED, CHECK IF IT HAS AN AMOUNT AND PUSH TO CORRESPONDING LIST
                        if (item.isIgnored) {
                            if (item.amount) {
                                accountingObj.finalAmount = item.amount
                                ignoredCategory.items.push(accountingObj);
                            }
                            else {
                                ignoredCategory.zeroValuesList.push(accountingObj)
                            }
                        }
                        //NO AMOUNT, ADD TO ZERO-VALUES-LIST
                        else if (!item.amount) {
                            categoryObject.zeroValuesList.push(accountingObj);
                        }
                        // WE HAVE ACCOUNT ITEMS (these contain the account number)
                        else if (item.itemAccounts) {
                            item.itemAccounts.forEach((account) =>
                                categoryObject.items.push(
                                    {
                                        name: item.name,
                                        category: c,
                                        ...account
                                    }
                                )
                            );
                        }
                        //NO ACCOUNT ITEMS, NO ACCOUNT NUMBER MAPPED TO THIS.
                        else {
                            accountingObj.finalAmount = item.amount;
                            accountingObj.accountNumber = null;
                            categoryObject.items.push(accountingObj)
                        }
                    });

                    categorizedAccountingValues.push(categoryObject)

                })

                categorizedAccountingValues.sort((a, b) => {
                    const categoryA = ENUMS.ACCOUNTING_VALUE_CATEGORIES.toList().find(category => category.value === a.category);
                    const categoryB = ENUMS.ACCOUNTING_VALUE_CATEGORIES.toList().find(category => category.value === b.category);

                    return categoryA.orderInList - categoryB.orderInList;
                });

                categorizedAccountingValues.push(ignoredCategory)

                this.categorizedDealValues = categorizedAccountingValues;
            },
            mapAccountingItems() {
                this.fimenu.accountingItems.forEach(item => {
                    const isMissingAccounts = !item.itemAccounts;
                    if (isMissingAccounts) {
                        const newItemAccount = item.getFakeItemAccount();
                        //preserve the amount if it's there, otherwise, default to 0
                        newItemAccount.finalAmount = item.amount ?? 0;
                        item.itemAccounts = [newItemAccount];
                    }
                })

                this.populateCategories(this.fimenu.accountingItems);
            },
            createPostString(isReversed) {
                const toStringAmountValue = (amountValue) => {
                    const val = parseInt(amountValue);
                    if (!val) return "";

                    let toReturn;
                    if (isReversed) toReturn = `${-amountValue.toFixed(2)}`
                    else toReturn = amountValue.toFixed(2);

                    toReturn = toReturn.split(".").join("");
                    return toReturn;
                };
                let postString = "";
                this.categorizedDealValues.forEach(category => {
                    category.items.forEach(item => {
                        postString += `${item.companyResult ?? this.storeReferenceNumber}.${item.accountNumber}.${toStringAmountValue(item.finalAmount)}.${item.control1Result ?? ""}.${item.control2Result ?? ""}.${item.descriptionResult ?? ""}.${item.count ?? ""}\n`;
                    })
                });

                return postString;
            },
            copyToClipboard(isReversed = false) {
                try {
                    navigator.clipboard.writeText(this.createPostString(isReversed));
                    this.isUserReadyToFinalize = true;

                    this.copied = true;
                    setTimeout(() => { this.copied = false; }, 1000);


                    util.toastr('success', 'Copied!', 'You have successfully copied to your clipboard.');
                }
                catch (err) {
                     
                    console.error(err)
                    util.toastr('error', 'ERROR', err);
                }
            },
            itemsToShowForCategory(category) {
                return category.items.filter((c, idx) => idx < category.pageSize);
            },
            showMoreItemsForCategory(category, observer) {

                category.pageSize = category.pageSize + this.DEFAULT_PAGE_SIZE;

                //if we reach the end of the items for this category, stop watching for more items
                if (category.pageSize > category.items.length && observer) {
                    observer.disconnect();
                }

            },
            updateSelectedAccordionState(index) {
                if (this.selectedAccordion !== null) {
                    this.categorizedDealValues[this.selectedAccordion].pageSize = 10;
                }
                this.selectedAccordion = index;
            },
            goToNextErrorForAccordion(category) {

                //find the index of the accordion
                const indexOfAccordion = this.categorizedDealValues.findIndex(i => i == category);

                //function that will scroll to the error item
                const scrollToErrorItem = () => {
                    this.$nextTick(() => {

                        //calculate the next error in this accordion
                        const indexOfErrorItemIndex = category.items.findIndex(item => !item.accountNumber);
                        const indexOfErrorItem = category.items[indexOfErrorItemIndex];
                        const errorItemElementId = this.getErrorElementId(indexOfErrorItem);

                        //go to that error
                        this.categorizedDealValues[this.selectedAccordion].pageSize = Math.max(this.DEFAULT_PAGE_SIZE, indexOfErrorItemIndex + this.DEFAULT_PAGE_SIZE);

                        setTimeout(() => {
                            const toScrollTo = document.getElementById(errorItemElementId);
                            toScrollTo?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
                        }, 750);

                    });
                }


                if (this.selectedAccordion != indexOfAccordion) {

                    //previous one (close the previous one)
                    if (!util.isNull(this.selectedAccordion)) {
                        this.categorizedDealValues[this.selectedAccordion].pageSize = this.DEFAULT_PAGE_SIZE;
                    }

                    //set the new one
                    this.selectedAccordion = indexOfAccordion;

                    //open it
                    this.$refs.accordionList.accordions[indexOfAccordion].toggleOneWithCallback(() => {
                        scrollToErrorItem();

                    });
                }
                else {
                    scrollToErrorItem();
                }
            },
            isIgnoredList(category) {
                return category.name === ENUMS.ACCOUNTING_VALUE_CATEGORIES.getDisplay(ENUMS.ACCOUNTING_VALUE_CATEGORIES.IGNORED)
            },
            hasAccountingItems(category) {
                return category.items?.length;
            },

            handleSearchQuery() {
                let filteredList;
                const hasSearchQuery = this.searchText && this.searchText.length >= 3;

                if (hasSearchQuery) {

                    filteredList = this.fimenu.accountingItems.filter(i => {
                        if (i.isIgnored) return false;

                        const searchTextLower = this.searchText.toLowerCase();
                        const nameMatch = this.formatPath(i.name).toLowerCase().indexOf(searchTextLower) > -1;
                        const amountNumberMatch = `${i.amount}`.indexOf(searchTextLower) > -1;
                        const amountPriceMatch = `${this.formatAmount(i.amount)}`.indexOf(searchTextLower) > -1;

                        // if (nameMatch || amountNumberMatch || amountPriceMatch) {

                        //     console.log("nameMatch", nameMatch);
                        //     console.log("amountNumberMatch", amountNumberMatch);
                        //     console.log("amountPriceMatch", amountPriceMatch);
                        // }

                        return nameMatch || amountNumberMatch || amountPriceMatch;
                    });

                    //IF SEARCH JUST STARTED, ALSO DISPLAY HIDDEN VALUES
                    if (this.searchText.length === 3)
                        this.showHidden = true;

                }
                else {
                    //RESET THE LIST TO ORIGINAL AND HIDE HIDDEN VALUES
                    filteredList = this.fimenu.accountingItems;
                    this.showHidden = false;
                }

                //REPOPULATE THE ACCORDIONS WITH THE FILTERED ITEMS
                this.populateCategories(filteredList);

                if (hasSearchQuery) {
                    const ignoredCategoryIndex = this.categorizedDealValues.findIndex(cdv => cdv.category === ENUMS.ACCOUNTING_VALUE_CATEGORIES.IGNORED);

                    //If the ignored category has no items found for this filter, remove it from categorized deal values
                    if (!this.categorizedDealValues[ignoredCategoryIndex].items.length || !this.categorizedDealValues[ignoredCategoryIndex].zeroValuesList.length)
                        this.categorizedDealValues.splice(ignoredCategoryIndex, 1)
                }
            },
            getTotalUnmapped() {
                let totalUnmapped = 0;
                this.categorizedDealValues.forEach(category => {
                    if (this.isIgnoredList(category)) return;

                    const unmapped = this.getUnmappedInCategory(category);
                    if (unmapped) totalUnmapped += unmapped;
                })
                return totalUnmapped;
            },
            getErrorElementId(item) {
                return item.name.split(" ").join("-");
            },
            getUnmappedInCategory(category) {
                let totalUnmapped = 0;
                category.items.forEach(item => {
                    if (!item.accountNumber) {
                        totalUnmapped++;
                    }
                })

                return totalUnmapped ? totalUnmapped : ""
            },
            formatPath(path) {
                return util.formatForAccounting(path)
            },
            formatAmount(amount) {
                return util.formatPrice(amount);
            },
        },
        unmounted() {
            if (!this.fimenu.isAccountingFinalized()) {
                this.fimenu.accountingItems = null;
            }
        },
        directives: {
            seen: IntersectionObserverDirective
        },
        components: {
            IsBusySectionComponent,
            RichTable,
            AccordionList,
            InputCheckbox,
        }
    };
</script>
<style>
    .PanelAccounting {
        height: 100%;
        box-sizing: border-box;
        display: flex;
        padding-right: 25px;
        position: relative;
        overflow: hidden;
        --accounting-container-height: 90%;
    }

        .PanelAccounting .loading-saturn-anim.fade-in.IsBusySectionComponent-container {
            height: 100%;
            box-sizing: border-box;
        }

        .PanelAccounting .category-details {
            transition: var(--transition);
        }

        .PanelAccounting .warning-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--error-color);
            font-weight: 600;
            margin: 10px 0 15px 0;
            gap: 5px;
        }

        .PanelAccounting table.rich-table {
            border: unset;
        }

            .PanelAccounting table.rich-table thead {
                background: var(--background-color);
            }

                .PanelAccounting table.rich-table thead th {
                    border-bottom: 2px solid var(--main-color);
                }

            .PanelAccounting table.rich-table .rich-table-header.clickable:hover {
                background: var(--black-10percent);
                box-shadow: unset;
            }

            .PanelAccounting table.rich-table .rich-table-header:hover .rich-header .rich-header-options .rich-header-sort {
                color: var(--disabled-color);
            }

                .PanelAccounting table.rich-table .rich-table-header:hover .rich-header .rich-header-options .rich-header-sort div.active {
                    color: var(--main-color);
                }

        .PanelAccounting .rich-table-container {
            max-height: unset;
            width: 100%;
            font-size: 0.8rem;
        }

        .PanelAccounting .accordion-header .button-unwind {
            padding: 0 10px;
        }

        .PanelAccounting .buttons-container {
            position: absolute;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5%;
            height: 65px;
            width: 100%;
        }

            .PanelAccounting .buttons-container button {
                box-sizing: border-box;
                font-size: 0.9rem;
                font-weight: 500;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                transition: var(--transition);
                width: 25% !important;
                max-width: 350px;
            }

                .PanelAccounting .buttons-container button > i,
                .PanelAccounting .buttons-container button .reverse-icon-container {
                    position: relative;
                    right: 10px;
                }

        .PanelAccounting .container-header {
            color: var(--main-color);
            box-sizing: border-box;
            font-weight: 600;
            font-size: 1.25rem;
            padding: 10px 15px;
            border-bottom: 1px solid var(--main-color);
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .PanelAccounting .container-header,
        .PanelAccounting .accordion-list {
            width: 100%;
        }

            .PanelAccounting .container-header button {
                width: auto !important;
            }

                .PanelAccounting .container-header button.primary {
                    background: var(--main-color) !important;
                }

        .PanelAccounting .feedback,
        .PanelAccounting .hidden-items-container {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--text-color);
            overflow: hidden;
            transition: var(--transition);
            margin-top: 25px;
        }

        .PanelAccounting .feedback {
            height: 0px;
            opacity: 0;
            font-size: 1.05rem;
            font-weight: 500;
            position: absolute;
            bottom: 8%;
            width: 95%;
            box-sizing: border-box;
            background: var(--white-80percent);
            box-shadow: 0px -8px 10px 1px var(--white-80percent);
            z-index: 111;
        }

        .PanelAccounting .hidden-items-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .PanelAccounting .feedback.expanded {
            opacity: 1;
        }

        .PanelAccounting .feedback.expanded {
            height: 45px;
        }

        .PanelAccounting .feedback.errors-found {
            color: var(--error-color);
        }

        .PanelAccounting .InputCheckbox.invalid {
            color: var(--error-color);
        }

        .PanelAccounting .reverse-icon {
            position: relative;
        }


            .PanelAccounting .reverse-icon span {
                width: 50%;
                height: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -65%);
                display: flex;
                justify-content: center;
                align-items: center;
            }

                .PanelAccounting .reverse-icon span::before {
                    background: var(--error-color);
                    content: "";
                    position: absolute;
                    inset: 0;
                    z-index: -1;
                }

        .PanelAccounting button:disabled .reverse-icon span::before {
            box-shadow: inset 0 0 0 100vw var(--button-shadow) !important;
        }

        .PanelAccounting .reverse-icon span i {
            z-index: 11111;
            color: var(--background-color) !important;
            font-size: 55%;
            position: relative;
            top: 1px;
        }



        .PanelAccounting .accounting-container {
            height: var(--accounting-container-height);
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow-y: scroll;
            overflow-x: hidden;
        }

    .modal .modalEditAccountNumber .modal-footer {
        justify-content: space-between;
    }

    .PanelAccounting .pulsate {
        animation: throb 1.2s infinite;
    }

    .PanelAccounting .no-items-container {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 1.1rem;
        width: 100%;
    }

    .PanelAccounting .content-helper-container {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

        .PanelAccounting .content-helper-container input {
            width: 50%;
        }

    .PanelAccounting h3.zero-amounts {
        margin: 0;
        margin-bottom: 5px;
    }

    .PanelAccounting .smaller-cell:not(.rich-table-header) {
        max-width: 40px;
        word-break: break-word;
    }

    .PanelAccounting .name-cell {
        width: 200px;
    }

    .PanelAccounting .Accordion .children-container {
        margin: 10px 0;
    }

    .PanelAccounting .account-number {
        display: flex;
        justify-content: center;
    }

        .PanelAccounting .account-number i {
            color: var(--error-color);
            animation: throb 1.2s infinite;
            scale: 1.3;
        }

    .PanelAccounting .header-buttons {
        max-width: 40%;
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }

        .PanelAccounting .header-buttons buttons {
            white-space: nowrap;
        }
</style>