<template>
    <div class="customer-header">
        <div class="left-header">
            <InputRichDropdown v-if="!isCustomerCreating"
                :disabled="!selectedStoreCode"
                :saturn="selectedCustomerId"
                :list="availableCustomers"
                label="Customer(s)"
                placeholder="-- Select Something --"
                :valueMap="customerValuePredicate"
                :display="customerNamePredicate"
                :search="true"
                :selectAll="true"
                :single="false"
                :invalid="!selectedCustomerId"
                @change="handleChangeCustomer"
                style="min-width: 460px"
            />

            <button class="button-save add-customer" @click="openCustomerModal" v-if="!isCustomerCreating">
                <i class="fas fa-plus"/>
            </button>
        </div>

        <div class="right-header">
            <div class="top">
                <div>
                    <TooltipComponent :tooltip="storeCodesForCustomer" direction="bottom" customClass="tool-tip-edit">
                        <template #showFromOutside>
                            <i class="fas fa-info-circle" style="color: #3ca4ff;"></i>
                        </template>
                    </TooltipComponent>
                </div>
                <ReverseEnvironmentButton v-if="$global.isAdminView && selectedCustomerId"/>
                <InputRichDropdown :saturn="selectedStoreCode"
                                   :list="userStores"
                                   label="Store"
                                   placeholder="-- Select Something --"
                                   :valueMap="storeValuePredicate"
                                   :display="storeNamePredicate"
                                   :search="true"
                                   :selectAll="true"
                                   :single="false"
                                   :invalid="!selectedStoreCode"
                                   @change="handleChange"
                                   style="min-width: 460px" />

                <FloatingMenu v-show="selectedStoreCode"
                              :disabled="!displayAddStoreButton && !displayLegalTermsButton"
                              closeOnClick>
                    <template #action-button>
                        <!--<i class="fas fa-cog" />-->
                        <i class="fas fa-bars" />
                    </template>
                    <template #body>
                        <FloatingMenuItem v-if="displayAddStoreButton"
                                          icon="fas fa-plus"
                                          label="Add Store"
                                          :action="createCustomer" />
                        <FloatingMenuItem v-if="displayLegalTermsButton"
                                          icon="fas fa-file"
                                          label="Customer Documents"
                                          :action="openCustomerAgreedTermsModal" />
                    </template>
                </FloatingMenu>
            </div>

            <div class="bottom">

            </div>
        </div>
    </div>
</template>
<script lang="ts">

import { defineComponent, PropType } from 'vue'
import $modal from "@core/services/modal";
import api from '@core/services/api';
import ENUMS from "@core/classes/Enums";
import FIMenuCustomer from "@core/classes/FIMenuCustomer.js"
import FloatingMenu from './FloatingMenu.vue';
import FloatingMenuItem from './FloatingMenuItem.vue';
import InputRichDropdown from '@core/components/InputRichDropdown.vue';
import modalCustomerAgreedTerms from '@/modals/modalCustomerAgreedTerms.vue'
import { openRetrieveCustomersModal } from '@/helpers/customers-helper';
import ReverseEnvironmentButton from '@core/components/_general/ReverseEnvironmentButton.vue';
import settings from 'settings';
import TooltipComponent from '@core/components/TooltipComponent.vue'
import util from '@core/services/util';

export default defineComponent({

    name: 'CustomerDetailsHeader',
    emits:['update:isLoading', 'update:selectedCustomerId', 'update:selectedStoreCode', 'update:selectedStore'],
    components: {
        FloatingMenu,
        InputRichDropdown,
        FloatingMenuItem,
        TooltipComponent,
        ReverseEnvironmentButton
    },
    data() {
        return {
            legalTermsToBeAgreedTo: [],
        }
    },
    props: {
        customer: {
            type: Object as PropType<FIMenuCustomer>   ,
            required: true
        },
        selectedStoreCode: {
            type: String || null,
            default: null
        },
        selectedStore: {
            type: Object,
            default: null
        },
        selectedCustomerId: {
            type: String || null,
            default: null
        },
        availableCustomers: {
            type: Array as () => Array<Record<string, any>>,
            default: (): Array<Record<string, any>> => []
        },
        customerStores: {
            type: Array as () => Array<string>,
                default: (): Array<string> => []
        },
        loadData: {
            type: Function,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true
        },
        metadata: {
            type: Object,
            required: true
        },
        createCustomer: {
            type: Function,
            required: true
        },
        isCustomerCreating: {
            type: Boolean,
            required: true
        },
        addCustomersToAvailableCustomers: {
            type: Function,
            required: true
        }


    },
    computed: {
        hasAgreedTerms():boolean {
            const customerApplication = this.customer?.customerApplication;
            if (!customerApplication) return false;

            const sessionLogs = customerApplication.sessionLogs;
            if (!sessionLogs || sessionLogs.length === 0) return false;

            return sessionLogs.some(sl => sl.agreedTerms);
       },
        userHasSelectedStore(): boolean {
            return this.customerStores.includes(this.selectedStoreCode);
        },
        displayAddStoreButton(): boolean {
            return !!(this.customer && this.customer.id && this.selectedStoreCode && !this.userHasSelectedStore);
        },
        displayLegalTermsButton(): boolean {
            return !!(this.customer && this.customer.id && this.selectedStoreCode);
        },
        buyersOrdersEnabled():boolean {
            return this.selectedStore?.storeSettings?.buyersOrderEnabled;
        },
        userStores(): any[] {
            return settings.userStores;
        },
        legalTermsLabel():string {
            return this.hasAgreedTerms ? 'Legal Terms (Agreed)' : 'Legal Terms (Pending)';
        },
        storeCodesForCustomer(): string[] {
            if (this.customerStores.length > 0){
                const message = [
                    "Associated Customer Stores:",
                    "-------------------------------",
                    ...this.customerStores.map(store => this.getStoreName(store))
                ]
                return message;
            }
            return [];
        }
    },
    methods: {
        async handleChangeCustomer(customerId: string): Promise<void> {
            this.$emit('update:selectedCustomerId', customerId);
            await this.loadData();
        },
        storeValuePredicate(store: any): string {
                return store?.storeCode ?? "";
        },
        storeNamePredicate(store: any): string {
            return store.storeName;
        },
        customerValuePredicate(customer: FIMenuCustomer): string {
                return customer?.id ?? "";
        },
        customerNamePredicate(customer: FIMenuCustomer): string {
            return customer.fullName;
        },
        handleChange(selected: any) {
            this.$emit('update:selectedStoreCode', selected);

            if (selected != null) {
                this.metadata.storeCode = selected;
                this.metadata.store = settings.userStores.find((x: any) => x.storeCode == selected);
                this.metadata.buyersOrderEnabled = this.metadata.store.storeSettings.buyersOrderEnabled;

                this.$emit('update:selectedStore',  this.metadata.store);
            } else {
                this.metadata.storeCode = null;
                this.metadata.store = null;
                this.$emit('update:selectedStore',  null);
                this.metadata.buyersOrderEnabled = false;
            }
        },
        getStoreName(storeCode: string) {
            return settings?.userStores?.find((store : any) => store.storeCode == storeCode)?.storeName ?? storeCode;
        },
        async openCustomerAgreedTermsModal() {
            const headers = [
                { name: "type", slot: "type", display: "Type" },
                { name: "download", slot: "download", display: "Download" }
            ];
            let agreedTerms = null;
            if (this.hasAgreedTerms) {
                // Get latest session logs and grab the agreedTerms
                const lastSessionLog = [...this.customer.customerApplication.sessionLogs].reverse();
                agreedTerms = lastSessionLog.find(sl => sl.agreedTerms)?.agreedTerms;

            } else {
                try {
                    this.$emit('update:isLoading', true);
                    const response = await api.settings.getLegalTerms()


                    if (response && response.data) {
                        const termsTypes = [
                            ENUMS.LegalTermTypes.TERMS_AND_CONDITIONS,
                            ENUMS.LegalTermTypes.ADVERSE_ACTION_NOTICE,
                            ENUMS.LegalTermTypes.PRIVACY_NOTICE,
                            ENUMS.LegalTermTypes.SMS_CONSENT
                        ];
                        const allTerms = response.data;

                        for (let i = 0; i < termsTypes.length; i++) {
                            this.legalTermsToBeAgreedTo[i] = allTerms.find(
                                (term: any) => term.termsType === termsTypes[i]
                            );

                            if (this.legalTermsToBeAgreedTo[i].termsType === 1) {
                                // Adverse Action Notice - Updating Dealership name and address in the terms text
                                const adverseActionNotice = this.legalTermsToBeAgreedTo[i];

                                const nameRegex = new RegExp('DEALERSHIP_NAME', 'g');
                                const addressRegex = new RegExp('DEALERSHIP_ADDRESS', 'g');
                                const phoneRegex = new RegExp('DEALERSHIP_PHONE', 'g');

                                adverseActionNotice.nameTranslations.forEach((languageVersion: any) => {
                                    languageVersion.termsText = languageVersion.termsText.replace(
                                        nameRegex,
                                        this.metadata.store.storeName
                                    );
                                    languageVersion.termsText = languageVersion.termsText.replace(
                                        addressRegex,
                                        this.metadata.store.storeAddress +
                                        "<br>" +
                                        this.metadata.store.storeCity +
                                        ", " +
                                        this.metadata.store.storeState +
                                        " " +
                                        this.metadata.store.storeZip
                                    );
                                    languageVersion.termsText = languageVersion.termsText.replace(
                                        phoneRegex,
                                        util.formatPhoneNumber(this.metadata.store.storePhone)
                                    );
                                });
                            }
                        }
                    }
                } catch (err) {

                    console.error(err);
                } finally {
                    this.$emit('update:isLoading', false);
                }
            }

            $modal.open(modalCustomerAgreedTerms, {
                name: "modalCustomerAgreedTerms",
                passedData: {
                    title: "Customer Documents",
                    headers: headers,
                    data: this.hasAgreedTerms ? agreedTerms : this.legalTermsToBeAgreedTo,
                    hasAgreedTerms: this.hasAgreedTerms,
                    customer: this.customer
                },
                backdrop: false
            });
        },
        openCustomerModal() {
            const handleRetrieveCustomer = (customerSearchResult: any[]) => {
                this.addCustomersToAvailableCustomers([customerSearchResult])
            }

            openRetrieveCustomersModal({
                actionOnRetrieveCustomer : handleRetrieveCustomer,
                actionOnRetrieveCompany: handleRetrieveCustomer,
                canRetrieveCompany: true,
            }, true)

        },
    },
})
</script>
<style>
    .customer .customer-header {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin: auto;
        height: auto;
        padding: 15px;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .customer .right-header .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        min-width: 50%;
    }

    .customer .left-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        width: 'fit-content'
    }

    .customer .left-header .add-customer {
        width: fit-content
    }

    .customer .tool-tip-edit {
        padding-top: 6px;
    }


</style>