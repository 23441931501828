<template>
    <div class="modal-add-edit-checklist modal-container modal-large">
        <div class="modal-header">
            Custom Deal Checklist Item
        </div>
        <div class="modal-body">
            <div :style="$grid('1')">

                <Panel title="Checklist Information">
                    <div class="name-selector">
                        <SwitchButton v-if="preBuiltItems.length > 0"
                                      :active="!usingPreBuilt"
                                      keepActiveSynced
                                      leftLabel="Pre-Built"
                                      rightLabel="Custom"
                                      @update="onUpdateUsingPreBuilt" />

                        <AutocompleteComponent v-if="preBuiltItems.length > 0 && usingPreBuilt"
                                               label="Name"
                                               :preSelected="namePreselected"
                                               :data="preBuiltItems"
                                               :searchKeys="['name']"
                                               mainKey="id"
                                               :display="['name']"
                                               :invalid="v$.item.name.$invalid"
                                               @change="onItemChanged"
                                               @selected="onItemSelected" />

                        <InputTextbox v-else label="Name" v-model:saturn="item.name" :disabled="usingPreBuilt" :invalid="v$.item.name.$invalid" />
                    </div>

                    <InputTextbox label="Description"
                                  v-model:saturn="item.description"
                                  :disabled="!isSomethingSelected"
                                  :invalid="v$.item.description.$invalid"
                                  multiline />
                </Panel>

                <Panel title="Configuration">
                    <fieldset :style="$grid('1-1')" :disabled="usingPreBuilt || !isSomethingSelected">
                        <InputRichDropdown label="Type"
                                       v-model:saturn="item.type"
                                       :invalid="v$.item.type.$invalid"
                                       :list="checklistTypes"
                                       :display="(t) => t.display"
                                       :valueMap="(t) => t.value">
                        </InputRichDropdown>

                        <AutocompleteComponent label="Subtype"
                                               class="subtype-autocomplete"
                                               :preSelected="subtypePreselected"
                                               :invalid="v$.item.subtype.$invalid"
                                               :disabled="!isDocumentAction"
                                               :data="subtypeList"
                                               :searchKeys="['display']"
                                               :display="['display']"
                                               :dropUp="true"
                                               mainKey="code"
                                               @change="onSubtypeChanged"
                                               @selected="onSubtypeSelected" />

                        <InputRichDropdown label="Send To"
                                                  v-model:saturn="item.sendTo"
                                                  :disabled="usingPreBuilt || !isSomethingSelected"
                                                  :list="personTypes"
                                                  :display="(v) => v.display"
                                                  :valueMap="(v) => v.value"
                                                  :key="'sendto-' + sendToKey"
                                                  multiSelect />

                        <InputRichDropdown label="Associations"
                                                  v-model:saturn="item.associations"
                                                  :invalid="v$.item.associations.$invalid"
                                                  :disabled="usingPreBuilt || !isSomethingSelected"
                                                  :list="associationTypes"
                                                  :display="(v) => v.display"
                                                  :valueMap="(v) => v.value"
                                                  :key="'association-' + associationKey"
                                                  multiSelect >
                            <template #validation>
                                <div v-if="v$.item.associations.required.$invalid">
                                    List cannot be empty.
                                </div>
                            </template>
                        </InputRichDropdown>
                    </fieldset>
                </Panel>
            </div>
        </div>
        <div class="modal-footer">
            <button class="button-unwind" @click="cancel()">Cancel</button>
            <button :disabled="v$.$invalid || !isSomethingSelected" class="button-save" @click="close()">Save</button>
        </div>
    </div>
</template>
<script>
    import $modal from '@core/services/modal'
    import util from '@core/services/util';
    import { required, requiredIf } from '@vuelidate/validators'
    import { useVuelidate } from '@vuelidate/core';
    import { DealChecklistType, DOC_ASSOCIATION, PERSON_TYPES } from "@core/classes/Enums"
    import Panel from '@core/components/Panel.vue'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import AutocompleteComponent from '@core/components/AutocompleteComponent.vue';
    import SwitchButton from '@/components/SwitchButton.vue';

    export default {
        name: "modalAddEditChecklistItemLocal",
        props: ['modal'],
        setup(){
            // New setup for vuelidate. Swap $v with v$
            return {v$: useVuelidate({$scope: false})}
        },
        data() {
            return {
                notListedItem: '--ADD CUSTOM ITEM--',
                notListedSubtype: '--ADD CUSTOM SUBTYPE--',
                defaultCustomItem: {
                    id: '',
                    isCustomItem: true,
                    name: '',
                    description: '',
                    sendTo: [],
                    type: null,
                    subtype: null,
                    associations: []
                },
                item: null,
                isAddAction: null,
                documentTypes: [],
                preBuiltItems: [],
                customSubtypes: [],
                dealParticipants: [],
                usingPreBuilt: true,
                isSomethingSelected: false,
                associationKey: 0,
                sendToKey: 0,
            };
        },
        computed: {
            PERSON_TYPES() {
                return PERSON_TYPES;
            },
            checklistTypes() {
                // If not using pre-built, filter out Plaid actions
                let formatted = Object.keys(DealChecklistType).map(k => {
                    return { display: this.formatEnumKey(k), value: DealChecklistType[k] };
                });

                if (!this.usingPreBuilt)
                    formatted = formatted.filter(f => this.isItemTypeUpload(f.value));

                return formatted;
            },
            personTypes() {
                return [
                    { display: 'Customer', value: PERSON_TYPES.CUSTOMER },
                    { display: 'Co-Customer', value: PERSON_TYPES.COCUSTOMER }
                ];
            },
            associationTypes() {
                return [
                    { display: 'Deal', value: DOC_ASSOCIATION.Deal },
                    { display: 'Customer', value: DOC_ASSOCIATION.Customer },
                    { display: 'Customer Application', value: DOC_ASSOCIATION.CustomerApplication }
                ];
            },
            isDocumentAction() {
                if (!this.item?.type) return false;

                const keyName = util.getKeyFromValue(DealChecklistType, this.item.type).toUpperCase();
                return keyName.startsWith('UPLOAD');
            },
            subtypeList() {
                if (this.isDocumentAction) return this.documentTypes;
                return [];
            },
            namePreselected() {
                return (this.isAddAction) ? null : this.item;
            },
            subtypePreselected() {
                return this.documentTypes.find(d => d.code === this.item.subtype);
            },
        },
        async created() {
            this.isAddAction = this.modal.passedData.isAddAction;
            this.isSomethingSelected = !this.isAddAction;
            this.usingPreBuilt = this.modal.passedData.usedPreBuilt;

            this.customSubtypes = this.modal.passedData.customSubtypes;
            this.documentTypes = util.clone(this.modal.passedData.documentTypes);
            this.dealParticipants = this.modal.passedData.dealParticipants;

            this.preBuiltItems = this.modal.passedData.preBuiltItems.map(p => this.reduceItemData(p));
            if (this.isAddAction && this.preBuiltItems.length < 1) {
                this.usingPreBuilt = false;
                this.isSomethingSelected = true;
            }

            this.defaultCustomItem.id = (this.isAddAction) ? crypto.randomUUID() : this.modal.passedData.item.id;
            this.item = (this.modal.passedData.item) ? this.reduceItemData(this.modal.passedData.item) : util.clone(this.defaultCustomItem);

            if (!this.isAddAction && !this.subtypePreselected) {
                //If subtype wasn't in document list, it was probably a custom subtype
                const subtypeDisplay = this.item.subtype.replace('CUSTOM_', '').replaceAll('_', ' ');

                this.documentTypes.push({
                    code: this.item.subtype,
                    display: subtypeDisplay,
                    id: crypto.randomUUID()
                });
            }

            this.$watch(() => this.item?.type, () => {
                if (!this.isDocumentAction) this.item.subtype = null;
            });

            this.$watch(() => this.associationTypes, () => {
                if (this.usingPreBuilt) return;
                this.item.associations = [];
                this.associationKey++;
            });
        },
        validations() {
            return {
                item: {
                    name: { required },
                    description: { required },
                    type: { required },
                    subtype: {
                        required: requiredIf(() => this.isDocumentAction),
                        isUnique: (value) => {
                            if (this.usingPreBuilt || !value) return true;

                            const valueToCode = `CUSTOM_${value.toUpperCase().replace(' ', '_')}`;
                            const result = this.customSubtypes.every(d => d !== valueToCode);
                            return result;
                        }
                    },
                    associations: { required },
                }
            };
        },
        methods: {
            close() {
                if (this.v$.$invalid) return;
                $modal.close({ item: this.item, isAddAction: this.isAddAction, preBuiltUsed: this.usingPreBuilt });
            },
            cancel() {
                $modal.cancel();
            },
            onUpdateUsingPreBuilt(usingCustom) {
                this.usingPreBuilt = !usingCustom;
                this.associationKey++;
                this.sendToKey++;
                this.isSomethingSelected = usingCustom;

                if (usingCustom)
                    this.item = this.defaultCustomItem;
            },
            onItemChanged(nameText, filteredList) {
                if (filteredList.length < 1) {
                    filteredList.push({ name: this.notListedItem });
                }
            },
            onItemSelected(selected, updateToNotListed) {
                this.isSomethingSelected = true;
                this.usingPreBuilt = (selected.name !== this.notListedItem);

                if (!this.usingPreBuilt) {
                    this.onUpdateUsingPreBuilt(!this.usingPreBuilt);
                    this.item.name = updateToNotListed();
                }
                else {
                    this.item = selected;
                }

                //Force multi-select dropdown to re-render
                this.associationKey += 1;
                this.sendToKey += 1;
            },
            onSubtypeChanged(nameText, filteredList) {
                if (filteredList.length < 1) {
                    filteredList.push({ display: this.notListedSubtype, code: '' });
                }
            },
            onSubtypeSelected(selected, updateToNotListed) {
                this.item.subtype = (selected.display === this.notListedSubtype) ? updateToNotListed() : selected.code;
            },
            reduceItemData(item) {
                //Depending on where the item's from, associations can take different forms
                let cleanedAssociations = util.clone(item.associations);
                if (cleanedAssociations.length > 0 && typeof cleanedAssociations[0] === 'object')
                    cleanedAssociations = cleanedAssociations.map(a => a.type);

                return {
                    id: item.id,
                    name: item.customerItemName ?? item.name,
                    description: item.customerItemDescription ?? item.description,
                    sendTo: item.sendTo,
                    type: item.type,
                    subtype: item.subtype,
                    associations: cleanedAssociations,
                }
            },
            isItemTypeUpload(type) {
                const keyName = util.getKeyFromValue(DealChecklistType, type).toUpperCase();
                return keyName.startsWith('UPLOAD');
            },
            formatEnumKey(key) {
                const spacesInserted = key.replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2");
                return spacesInserted.replaceAll('_', ' - ');
            },
            getParticipantName(role) {
                return this.dealParticipants.find(p => p.role === role)?.name;
            }
        },
        components: {
            Panel,
            InputRichDropdown,
            InputTextbox,
            AutocompleteComponent,
            SwitchButton
        }
    };
</script>
<style>
    .modal-add-edit-checklist .modal-body .name-selector {
        display: flex;
        gap: 10px;
        margin-bottom: 10px;
    }

        .modal-add-edit-checklist .modal-body .name-selector > *:not(.switch-container) {
            flex: 1;
        }

        .modal-add-edit-checklist .modal-body .name-selector > .switch-container {
            padding: 0 10px;
            border-radius: 10px;
            border: 1px solid var(--border-color);
            background-color: var(--card-background-color);
        }

    .modal-add-edit-checklist .subtype-autocomplete input {
        text-transform: uppercase;
    }
</style>
