<template>
    <div class="modal-container modal-xxx-large">
        <div class="modal-header">
            MS Rebates for {{ modal.passedData.fimenu.vehicle.modelYear }} {{ modal.passedData.fimenu.vehicle.make }} {{ modal.passedData.fimenu.vehicle.model }} {{ modal.passedData.fimenu.vehicle.style }} for ZIP: {{ modal.passedData.fimenu.customer.zip }}
        </div>
        <div class="modal-body">
            <Panel title="Rebates (select all that apply)">
                <template v-slot:header>
                    <div class="panel-title panel-buttons">
                        <span v-if="customerTypes">Customer Types: &nbsp;</span>
                        <InputRichDropdown v-if="customerTypes"
                                       v-model:saturn="customerType"
                                       :list="customerTypes"
                                       :valueMap="(c) => c.id"
                                       :display="(c) => c.name"
                                       >
                        </InputRichDropdown>
                    </div>
                </template>
                <div v-if="isBusy"><i class="fas fa-cog fa-spin fa-2x"></i></div>
                <div class="richtable-container" v-else>
                    <div v-if="rebatesData && rebatesData.rebates && rebatesData.rebates.length > 0">
                        <RichTable :tableData="filteredRebates"
                                   :headers="headers">
                            <template #checkbox="row">
                                <InputCheckbox v-model="row._ref.selected" :disabled="isDisabled(row._ref)" @change="itemsChanged(selectedRebates, row._ref, row._ref.selected)"></InputCheckbox>
                            </template>
                            <template #amount="row">
                                <div>
                                    <div v-if="row.terms && row.terms.length > 0">
                                        <div v-for="term in row.terms" :key="term.start" class="term-line ">
                                            <div>
                                                {{ term.start }} - {{ term.end }} months:
                                            </div>
                                            <div v-if="term.value != null && term.value.values != null && term.value.values.length>0 && term.value.values[0].value != null">
                                                <div v-if="row.valueType === 0">
                                                    {{ $filters.currency2(term.value.values[0].value) }}
                                                </div>
                                                <div v-else>
                                                    {{ term.value.values[0].value.toString() }} %
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-if="row.manualValueInputRequired">
                                            <InputCurrency v-model:saturn="row._ref.amount"
                                                           :precision="2"
                                                           placeholder="Custom amount"
                                                           :disabled="!row._ref.selected || isDisabled(row._ref)"
                                                           iconSide="left"
                                                           :invalid="v$.filteredRebates[row._index].$invalid">
                                                <template #validation>
                                                    <div v-if="v$.filteredRebates[row._index].required.$invalid">Amount is required.</div>
                                                    <div v-if="v$.filteredRebates[row._index].maxValue.$invalid">Amount must be a maximum of {{ maxValue(row) }}</div>
                                                </template>
                                            </InputCurrency>
                                        </div>
                                        <div v-else-if="row.value != null && row._ref.value.values != null && row._ref.value.values.length > 0 && row._ref.value.values[0].value != null" class="amount-line">
                                            <div v-if="row.valueType === 0">
                                                {{ $filters.currency2(row._ref.value.values[0].value) }}
                                            </div>
                                            <div v-else>
                                                {{ row._ref.value.values[0].value.toString() }} '%'
                                            </div>
                                        </div>
                                        <div v-else>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </RichTable>
                    </div>
                    <p v-else class="empty-list">There are currently no rebates for this VIN and Zip Code.</p>
                </div>
            </Panel>
        </div>
        <div class="modal-footer">
            <div>
                <button class="button-unwind" @click="cancel()">Cancel</button>
                <button class="button-save" @click="save()" :disabled="v$.$invalid || isBusy">Save</button>
            </div>
        </div>
    </div>
</template>


<script>
    import api from '@core/services/api'
    import $modal from '@core/services/modal'
    import modalInfo from '@core/modals/modalInfo.vue'
    import Panel from '@core/components/Panel.vue'
    import RichTable from '@core/components/RichTable.vue'
    import TableItemListComponent from '@/components/TableItemListComponent.vue'
    import InputCurrency from '@core/components/InputCurrency.vue'
    import InputCheckbox from '@core/components/InputCheckbox.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import util from '@core/services/util'
    import ENUMS from "@core/classes/Enums"
    import { required, requiredIf, maxValue, between } from '@vuelidate/validators'
    import { useVuelidate } from '@vuelidate/core';
    import moment from 'moment'

    export default {
        name: "modalMSRebate",
        props: ['modal', 'mscanaccount'],
        setup(){
            // New setup for vuelidate. Swap $v with v$
            return {v$: useVuelidate({$scope: false})}
        },
        data() {
            return {
                fullResponse: null,
                rebatesData: null,
                compatibilities: null,
                customerTypes: null,
                customerType: null,
                isBusy: false,
                headers: [
                    { name: 'checkbox', slot: 'checkbox' },
                    { name: 'number', display: "Program Code" },
                    { name: 'identCode', display: "Code", value: (v) => v.number != v.identCode ? v.identCode: '' },
                    {
                        name: 'action', display: '', actions: [
                            { name: 'showDescription', type: "button", icon: 'fa-question-circle', cssClass: 'button-small button-nothing button-nothing-blue', action: (row) => this.getRebateDescription(row) },
                        ]
                    },
                    { name: 'nameDisplay', display: "Description" },
                    { name: 'receipientType', display: "Recipient", value: (v) => v.receipientType == 2 ? 'Dealer' : 'Consumer', autoFilter: true },
                    { name: 'type', display: "Type", value: (v) => this.types.filter((r) => r.code === v.type)[0].description, autoFilter: true },
                    { name: 'transactionType', display: "Transaction Type", value: (v) => this.transactionTypes.filter((r) => r.code === v.transactionType)[0].description, autoFilter: true },
                    { name: 'component', display: "Amount(s)", slot: 'amount' }
                ],
                types: [
                    { description: 'Unknown', code: 0, longDescription: "Unknown type." },
                    { description: 'CCR', code: 1, longDescription: "Cap Cost Reduction rebate, used for lease." },
                    { description: 'CCR Inc', code: 2, longDescription: "Incremental Cap Cost Reduction rebate, used for lease." },
                    { description: 'Cash', code: 3, longDescription: "Cash rebate, used for lease or retail." },
                    { description: 'Certificate', code: 4, longDescription: "Certificate rebate, used for lease or retail." },
                    { description: 'APR', code: 5, longDescription: "APR rebate, used for retail." },
                ],
                transactionTypes: [
                    {
                        code: 1,
                        description: 'Special Lease',
                        longDescription: "Rebate will be applied to lease special/ incentivized programs. This transaction type will allow a rebate to be used as a CCR or CCR - Incremental rebate."
                    },
                    {
                        code: 2,
                        description: 'Standard Lease',
                        longDescription: "Rebate will be applied to lease standard / non - incentivized programs. This transaction type will allow a rebate to be used as a CCR rebate."
                    },
                    {
                        code: 3,
                        description: 'Special Retail',
                        longDescription: "Rebate will be applied to retail special/incentivized programs. This transaction type will allow a rebate to be used as an APR rebate."
                    },
                    {
                        code: 4,
                        description: 'Standard Retail',
                        longDescription: "Rebate will be applied to retail standard/non-incentivized programs. This transaction type will allow a rebate to be used as an APR rebate."
                    },
                    {
                        code: 10,
                        description: 'Lease',
                        longDescription: "Rebate will be applied to a lease transaction. This transaction type will allow a rebate to be used as Cash or Certificate rebate."
                    },
                    {
                        code: 11,
                        description: 'Retail',
                        longDescription: "Rebate will be applied to a retail transaction. This transaction type type will allow a rebate to be used as Cash or Certificate rebate."
                    },
                    {
                        code: 1000,
                        description: 'Any',
                        longDescription: "Rebate will be applied to a lease and retail transaction. This transaction type will allow a rebate to be used used as Cash or Certificate rebate."
                    },
                ]

            }
        },
        computed: {
            ENUMS() {
                return ENUMS;
            },
            util() {
                return util;
            },
            filteredRebates() {
                return this.rebatesData && this.rebatesData.rebates && this.rebatesData.rebates.filter(r => r.customerTypes != null && r.customerTypes.length > 0);
            },
            selectedRebates() {
                return this.rebatesData && this.rebatesData.rebates && this.rebatesData.rebates.filter(r => r.selected);
            }
        },
        validations() {
            const validations = {
                filteredRebates: this.filteredRebates.map(() => ({
                    amount: {
                        required: requiredIf((item) => item.manualValueInputRequired && item.selected),
                        maxValue: (value, item) => {
                            if (item.manualValueInputRequired)
                                return value <= (item.maxValue != null ? item.maxValue : 999999);
                            else
                                return true;
                        }
                    }
                }))
            }
            return validations;

        },
        async created() {
            this.isBusy = true;
            const response = await api.marketScan.getRebateParams(this.modal.passedData.fimenu);
            // console.log('this.modal.passedData.rebates', this.modal.passedData.rebates);
            if (response && response.data) this.fullResponse = response.data;
            const filteredRebates = this.modal.passedData.rebates.map(r => r.id);
            // eslint-disable-next-line no-console
            console.log('filteredRebates', filteredRebates);
            //clean up the incoming data
            if (response && response.data && response.data.rebates && response.data.rebates.length > 0) {
                response.data.rebates.forEach(r => {
                    //clean up non-existant values
                    if (!r.selected) r.selected = false;
                    //make sure anything I'm filtering is not selected
                    if (r.customerTypes == null || (r.customerTypes != null && r.customerTypes.length == 0)) r.selected = false;
                    //make sure I have a default value for amount for anything with a manual input required
                    if (r.manualValueInputRequired) r.amount = r.value?.values[0]?.value;
                    //if it was in the selected items from the passed in values, select it
                    if (filteredRebates.includes(r.id)) {
                        r.selected = true;
                        r.amount = this.modal.passedData.rebates.find(a => a.id == r.id).amount;
                    }
                });
                this.rebatesData = response.data;
                this.compatibilities = response.data.generalCompatibilities;
                this.customerTypes = response.data.customerTypes;
                //set the default customer type based on "most common"
                const mostCommon = this.customerTypes && this.customerTypes.filter(c => c.isMostCommon);
                if (mostCommon && mostCommon.length > 0) this.customerType = mostCommon[0].id;
            }
            this.isBusy = false;
        },
        methods: {
            maxValue(row) {
                return row.maxValue ? row.maxValue : 999999;
            },
            isDisabled(row) {
                //initially set to not disabled
                let disabled = false;
                //logic to decide if it is disabled
                //check customer type
                if (!row.customerTypes.some(c => c.id == this.customerType))
                    disabled = true;
                //check stackability
                //if ()

                return disabled;
            },
            cancel() {
                $modal.cancel();
            },
            save() {
                $modal.close({ rebateData: this.selectedRebates, responseData: this.fullResponse });
            },
            itemsChanged(items, item, action) {
                // TODO:
                // console.log('items', items);
                // console.log('item', item);
                // console.log('action', action);
            },
            async getRebateDescription(item) {
                const response = await api.marketScan.getRebateDescription(this.modal.passedData.mscanAccount, item.id);
                // eslint-disable-next-line no-console
                console.log('getRebateDescription',response);
                $modal.open(modalInfo, {
                    name: 'modalInfo',
                    passedData: {
                        title: 'Program Code:' + item.number + ' Incentive Code: ' + item.identCode,
                        info: response.data,
                        additionalInfo: 'Start Date: ' + this.$filters.prettyDateTime(moment.utc(item.startDate)) + '\r\n End Date: ' + this.$filters.prettyDateTime(moment.utc(item.stopDate)),
                        acceptText: 'OK'
                    },
                    backdrop: false,
                });

            },

        },
        components: {
            //    InputTextbox: InputTextbox,
            InputRichDropdown: InputRichDropdown,
            Panel: Panel,
            RichTable: RichTable,

            //    InputNumber: InputNumber,
            InputCurrency: InputCurrency,
            InputCheckbox: InputCheckbox
        }
    };
</script>
<style>
    .richtable-container {
        overflow: auto;
        height: 700px;
    }
    .term-line {
        display: flex;
        justify-content: space-between;
    }
    .amount-line {
        display: flex;
        justify-content: flex-end;
    }
</style>
