<template>
    <Panel v-bind="panelObject">
        <template #HeaderComponent>
            <slot name="HeaderComponent"></slot>
        </template>
        <template #header>
            <img v-if="populatingVehicleDetails" class="loading-anim" src="@static/img/SaturnLogoAnim.svg" style="width: 40px; margin-right:10px" />
        </template>

        <div class="warranty-grid">
            <table>
                <tbody>
                    <tr>
                        <th />
                        <th>Manufacturer Months</th>
                        <th>Manufacturer Miles</th>
                        <th>Remaining Months</th>
                        <th>Remaining Miles</th>
                        <th />
                    </tr>
                    <tr :class="{'warranty-invalid': hasMonthsOrMiles(vehicleWarranties.basic) && !isBasicWarrantyValid}">
                        <th>Basic</th>

                        <td>
                            <InputNumber v-model:saturn="vehicleWarranties.basic.months"
                                         placeholder="Months"
                                         :invalid="util.isNull(validation) ? false : validation.vehicleWarranty.warranties.basic.months.$invalid"
                                         :precision="0"
                                         class="glow">
                                <template #validation>
                                    <div v-if="!util.isNull(validation) && validation.vehicleWarranty.warranties.basic.months.required.$invalid">Basic months is required.</div>
                                </template>
                            </InputNumber>
                        </td>
                        <td>
                            <InputNumber v-model:saturn="vehicleWarranties.basic.miles"
                                         placeholder="Miles"
                                         :invalid="util.isNull(validation) ? false : validation.vehicleWarranty.warranties.basic.miles.$invalid"
                                         :precision="0"
                                         class="glow">
                                <template #validation>
                                    <div v-if="!util.isNull(validation) && validation.vehicleWarranty.warranties.basic.miles.required.$invalid">Basic miles is required.</div>
                                </template>
                            </InputNumber>
                        </td>
                        <td>
                            {{ vehicleWarranties.basic.monthsDisplay(data.inventoryType, warrantyStartDate) }}
                        </td>
                        <td>
                            {{ vehicleWarranties.basic.milesDisplay(data.inventoryType, vehicleMiles) }}
                        </td>

                        <td v-if="hasMonthsOrMiles(vehicleWarranties.basic)" class="warranty-indicator">
                            <i :class="['fa', (isBasicWarrantyValid) ? 'fa-check' : 'fa-exclamation-circle']" />
                        </td>
                        <td v-else />
                    </tr>
                    <tr :class="{'warranty-invalid': hasMonthsOrMiles(vehicleWarranties.drivetrain) && !isDrivetrainWarrantyValid}">
                        <th>Drivetrain</th>
                        <td>
                            <InputNumber v-model:saturn="vehicleWarranties.drivetrain.months"
                                         placeholder="Months"
                                         :invalid="util.isNull(validation) ? false : validation.vehicleWarranty.warranties.drivetrain.months.$invalid"
                                         :precision="0"
                                         class="glow">
                                <template #validation>
                                    <div v-if="!util.isNull(validation) && validation.vehicleWarranty.warranties.drivetrain.months.required.$invalid">
                                        Drivetrain months is required.
                                    </div>
                                </template>
                            </InputNumber>
                        </td>
                        <td>
                            <InputNumber v-model:saturn="vehicleWarranties.drivetrain.miles"
                                         placeholder="Miles"
                                         :invalid="util.isNull(validation) ? false : validation.vehicleWarranty.warranties.drivetrain.miles.$invalid"
                                         :precision="0"
                                         class="glow">
                                <template #validation>
                                    <div v-if="!util.isNull(validation) && validation.vehicleWarranty.warranties.drivetrain.miles.required.$invalid">
                                        Drivetrain miles is required.
                                    </div>
                                </template>
                            </InputNumber>
                        </td>
                        <td>
                            {{ vehicleWarranties.drivetrain.monthsDisplay(data.inventoryType, warrantyStartDate) }}
                        </td>
                        <td>
                            {{ vehicleWarranties.drivetrain.milesDisplay(data.inventoryType, vehicleMiles) }}
                        </td>

                        <td v-if="hasMonthsOrMiles(vehicleWarranties.drivetrain)" class="warranty-indicator">
                            <i :class="['fa', (isDrivetrainWarrantyValid) ? 'fa-check' : 'fa-exclamation-circle']" />
                        </td>
                        <td v-else />
                    </tr>
                    <tr :class="{'warranty-invalid': !isRoadsideWarrantyValid}">
                        <th>Roadside</th>
                        <td>
                            <InputNumber v-model:saturn="vehicleWarranties.roadside.months"
                                         class="glow"
                                         placeholder="Months"
                                         :precision="0" />
                        </td>
                        <td>
                            <InputNumber v-model:saturn="vehicleWarranties.roadside.miles"
                                         placeholder="Miles"
                                         :precision="0"
                                         class="glow" />
                        </td>
                        <td>
                            {{ vehicleWarranties.roadside.monthsDisplay(data.inventoryType, warrantyStartDate) }}
                        </td>
                        <td>
                            {{ vehicleWarranties.roadside.milesDisplay(data.inventoryType, vehicleMiles) }}
                        </td>

                        <td v-if="hasMonthsOrMiles(vehicleWarranties.roadside)" class="warranty-indicator">
                            <i :class="['fa', (isRoadsideWarrantyValid) ? 'fa-check' : 'fa-exclamation-circle']" />
                        </td>
                        <td v-else />
                    </tr>
                    <tr :class="{'warranty-invalid': hasMonthsOrMiles(vehicleWarranties.maintenance) && !isMaintenanceWarrantyValid}">
                        <th>Maintenance</th>
                        <td>
                            <InputNumber v-model:saturn="vehicleWarranties.maintenance.months"
                                         placeholder="Months"
                                         :precision="0"
                                         class="glow" />
                        </td>
                        <td>
                            <InputNumber v-model:saturn="vehicleWarranties.maintenance.miles"
                                         placeholder="Miles"
                                         :precision="0"
                                         class="glow" />
                        </td>
                        <td>
                            {{ vehicleWarranties.maintenance.monthsDisplay(data.inventoryType, warrantyStartDate) }}
                        </td>
                        <td>
                            {{ vehicleWarranties.maintenance.milesDisplay(data.inventoryType, vehicleMiles) }}
                        </td>

                        <td v-if="hasMonthsOrMiles(vehicleWarranties.maintenance)" class="warranty-indicator">
                            <i :class="['fa', (isMaintenanceWarrantyValid) ? 'fa-check' : 'fa-exclamation-circle']" />
                        </td>
                        <td v-else />
                    </tr>
                    <tr>
                        <th>Loaner</th>
                        <td colspan="2">
                            <!--TODO maybe make a radio?-->
                            <InputRichDropdown :list="loanerDropdownList"
                                           v-model:saturn="data.vehicleWarranty.isLoanerIncluded"
                                           :valueMap="(l) => l.code"
                                           :display="(l) => l.description"
                                           :invalid="util.isNull(validation) ? false : validation.vehicleWarranty.isLoanerIncluded.$invalid"
                                           :sortMode="ENUMS.DROPDOWN_SORT_MODE.DESCENDING"
                                           nothingSelected="-- Select Value --">
                                <template #validation>
                                    <div v-if="!util.isNull(validation) && validation.vehicleWarranty.isLoanerIncluded.required.$invalid">
                                        Loaner is required.
                                    </div>
                                </template>
                            </InputRichDropdown>
                        </td>
                        <td colspan="3" />
                    </tr>
                </tbody>
            </table>
        </div>
    </Panel>
</template>

<script>
    import util from '@core/services/util'
    import ENUMS from "@core/classes/Enums"
    import Panel from '@core/components/Panel.vue'
    import InputNumber from '@core/components/InputNumber.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'

    export default {
        name: "PanelManufacturerWarranty",
        components: {
            Panel: Panel,
            InputNumber: InputNumber,
            InputRichDropdown: InputRichDropdown,
        },
        props: {
            data: Object,
            panelObject: Object,
            validation: Object,
            populatingVehicleDetails: Boolean
        },
        data() {
            return {
                loanerDropdownList: [
                    {
                        code: false,
                        description: "Not Included"
                    },
                    {
                        code: true,
                        description: "Included"
                    }
                ],
            }
        },
        computed: {
            util() {
                return util;
            },
            ENUMS() {
                return ENUMS;
            },
            vehicleWarranties() {
                return this.data.vehicleWarranty.warranties;
            },
            vehicleMiles() {
                return this.data.vehicleMiles;
            },
            warrantyStartDate() {
                return this.data.vehicleWarranty.warrantyStartDate;
            },
            isBasicWarrantyValid() {
                if (!this.vehicleWarranties.basic) return false;
                return this.vehicleWarranties.basic.valid(this.vehicleMiles, this.warrantyStartDate);
            },
            isDrivetrainWarrantyValid() {
                if (!this.vehicleWarranties.drivetrain) return false;
                return this.vehicleWarranties.drivetrain.valid(this.vehicleMiles, this.warrantyStartDate);
            },
            isRoadsideWarrantyValid() {
                if (!this.vehicleWarranties.roadside) return false;
                return this.vehicleWarranties.roadside.valid(this.vehicleMiles, this.warrantyStartDate);
            },
            isMaintenanceWarrantyValid() {
                if (!this.vehicleWarranties.maintenance) return false;
                return this.vehicleWarranties.maintenance.valid(this.vehicleMiles, this.warrantyStartDate);
            }
        },
        methods: {
            hasMonthsOrMiles(warranty) {
                return warranty?.months || warranty.miles;
            }
        },
    };
</script>
<style>
    .warranty-grid table {
        width: 100%;
        border-collapse: collapse;
    }

        .warranty-grid table th {
            text-align: left;
            padding: 10px;
        }

        .warranty-grid table td {
            padding: 10px;
        }

        .warranty-grid table tr:nth-child(2n) {
            background-color: var(--richtable-alternating-background-color);
        }

        .warranty-grid table tr:hover:not(:first-child) {
            background-color: var(--richtable-row-color-hover);
        }

        .warranty-grid table tr.warranty-invalid {
            color: var(--error-color);
        }

        .warranty-grid table .warranty-indicator .fa-check {
            color: var(--success-color);
        }

        .warranty-grid table .warranty-indicator .fa-exclamation-circle {
            color: var(--error-color);
        }

    .warranty-grid table .InputNumber .input-component.left-icon .saturn-textbox input {
        text-align: start;
    }
</style>