<template>
    <div class="modal-container modal-finalstep" v-if="coverageTerm">
        <div class="modal-header" :style="{position: 'relative'}">
            Finalize F &amp; I
            <button class="open-sync-response" v-if="syncResponse == false" @click="openSyncResponse">Sync-Response</button>
        </div>
        <div class="modal-body" :style="{position: 'relative'}">
            <div v-if="modal.passedData.fimenu.buyersOrderEnabled">
                <fieldset :disabled="true">
                    <PanelBuyersOrder :fimenu="modal.passedData.fimenu" :fimenuValidation="v$.modal.passedData.fimenu" />
                </fieldset>
            </div>
            <div v-else>
                <div class="final-modal-split-panels"
                     v-if="(modal.passedData.fimenu.dealType === 'Finance' && coverageTerm) ||
                        (modal.passedData.fimenu.dealType === 'Lease' && coverageTerm) ||
                        (modal.passedData.fimenu.dealType === 'Cash')" :style="$grid(modal.passedData.fimenu.dealType === 'Cash' ? '1-1.5' : '3-1') ">
                    <PanelFinalInfo :data="modal.passedData.fimenu"
                                    :panelObject="{title: 'Final Information'}"
                                    :validation="v$.modal.passedData.fimenu"></PanelFinalInfo>

                    <PanelFrontEndGross :data="modal.passedData.fimenu"
                                                ></PanelFrontEndGross>

                </div>
            </div>
            <div class="no-margin-grid" :style="$grid('1')">
                <PanelBackEndGross :data="modal.passedData.fimenu"
                                           :panelObject="{title: 'Accepted Coverage Term'}"
                                           v-if="coverageTerm">
                </PanelBackEndGross>
            </div>

            <div :class="{'sync-response': true, 'open': syncResponse}" v-if="modal.passedData.syncResponse">

                <div class="sync-container">
                    <div class="sync-close" @click="closeSyncResponse"><i class="fas fa-times-circle"></i></div>
                    <h3>CDK Push Responses</h3>
                    <div class="sync-answer-container">
                        <div v-for="(sync, index) in modal.passedData.syncResponse.syncOperations" :key="index" class="sync-answer">
                            <div v-if="sync.success" class="sync-success"><i class="fas fa-check"></i></div>
                            <div v-if="!sync.success" class="sync-error"><i class="fas fa-times"></i></div>
                            <div class="sync-message">{{ sync.message }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <button class="button-unwind" @click="cancel()" :disabled="isBusy">Cancel</button>
            <ButtonLoading class="button-save" :disabled="v$.$invalid || (modal.passedData.syncResponse && !responseSeen)" :isLoading="isBusy" @click="close()">
                Get Paperwork
            </ButtonLoading>
        </div>

    </div>
</template>


<script>
    import { between, required } from '@vuelidate/validators'
    import $modal from '@core/services/modal'
    import ButtonLoading from '@core/components/ButtonLoading.vue'
    import CoverageTerm from '@core/classes/CoverageTerm'
    import FIMenu from '@core/classes/FIMenu'
    import FIMenuLender from '@core/classes/FIMenuLender'
    import { FIMenuSection } from '@core/classes/SharedEnums';
    import PanelBackEndGross from '@/components/PanelBackEndGross.vue'
    import PanelBuyersOrder from '@/components/PanelBuyersOrder.vue'
    import PanelFinalInfo from '@/components/PanelFinalInfo.vue'
    import PanelFrontEndGross from '@/components/PanelFrontEndGross.vue'
    import { useVuelidate } from '@vuelidate/core';
    import util from '@core/services/util'

    export default {
        name: "modalFIFinalStep",
        props: ['modal'],
        setup(){
            // New setup for vuelidate. Swap $v with v$
            return {v$: useVuelidate({$scope: false})}
        },
        data() {
            return {
                checkChecklistFunc: null,
                isBusy: false,
                syncResponse: false,
                responseSeen: false,
                coverageTerm: null,
                fimenu: null,
                lenderValidation: null,
                customerValidation: null,
                variation: 0.1,
                controlNumber: "",
            }
        },
        computed: {
            util() {
                return util;
            },
            minOtdFinal() {
                const finalvalue = this.getFinalNumbers(false).finalAmount
                const variationAmount = 1000; //Math.abs(finalvalue * this.variation)

                return Math.max(0, finalvalue - variationAmount);
            },
            maxOtdFinal() {
                const finalvalue = this.getFinalNumbers(false).finalAmount
                const variationAmount = 1000; // Math.abs(finalvalue * this.variation)

                return finalvalue + variationAmount;
            },
            minPayment() {
                const finalvalue = this.getFinalNumbers(false).finalPayment
                const variationAmount = 10; //Math.abs(finalvalue * this.variation)

                return Math.max(0, finalvalue - variationAmount);
            },
            maxPayment() {
                const finalvalue = this.getFinalNumbers(false).finalPayment
                const variationAmount = 10;// Math.abs(finalvalue * this.variation)

                return finalvalue + variationAmount;
            },
        },
        created() {
            this.loadData();
            this.checkChecklistFunc = this.modal.passedData.checkChecklistFunc;
            this.lenderValidation = this.fimenu.lender.validation(this.fimenu, FIMenuLender.validationTypes.FINAL)
            this.customerValidation = this.fimenu.customer.validation(this.fimenu, this.fimenu.customer.validationTypes.FINAL)
            this.syncResponse = this.modal.passedData.syncResponse && this.modal.passedData.syncResponse.syncOperations ? true : null
            if (util.isNull(this.syncResponse)) {
                this.responseSeen = true
            }
        },
        validations() {
            const validations = {
                modal: {
                    passedData: {
                        fimenu: {
                            customer: {
                                ...this.customerValidation
                            },
                            lender: {
                                ...this.lenderValidation
                            },
                            leaseTerms: {
                                finalMonthlyPayment: {}
                            },
                            loanTerms: {
                                finalMonthlyPayment: {}
                            }
                        }
                    }
                }
            }

            if (!this.fimenu.buyersOrderEnabled) {
                if (this.fimenu.dealType === 'Finance') {
                    validations.modal.passedData.fimenu.loanTerms.finalMonthlyPayment = { required, between: between(this.minPayment, this.maxPayment) };
                }
                if (this.fimenu.dealType === 'Lease') {
                    validations.modal.passedData.fimenu.leaseTerms.finalMonthlyPayment = { required, between: between(this.minPayment, this.maxPayment) };
                }

                validations.modal.passedData.fimenu.otdFinal = { required, between: between(this.minOtdFinal, this.maxOtdFinal) }
            }

            return validations;
        },
        methods: {
            openSyncResponse() {
                this.syncResponse = true;
            },
            closeSyncResponse() {
                this.responseSeen = true
                this.syncResponse = false;
            },
            getFinalNumbers(isFinal) {
                const state = this.$global.selectedStore.storeState;
                const leaseOrLoanTerm = this.fimenu.isFinance() ? this.fimenu.loanTerms.getAcceptedTerm() : this.fimenu.leaseTerms.getAcceptedTerm();
                const coverageTerm = this.fimenu.getSelectedCoverageTerm();

                return this.fimenu.finalNumbers(state, leaseOrLoanTerm, coverageTerm, isFinal);
            },
            loadData() {
                this.fimenu = new FIMenu(this.modal.passedData.fimenu);
                const ct = this.fimenu.coverageTerms.filter(c => c.selected == true)[0];
                this.coverageTerm = new CoverageTerm(ct);
            },
            async close() {
                if (this.checkChecklistFunc) {
                    this.isBusy = true;
                    const canProceed = await this.checkChecklistFunc(FIMenuSection.FinalizedFI);
                    this.isBusy = false;
                    if (!canProceed) return;
                }

                $modal.close(this.controlNumber);
            },
            cancel() {
                $modal.cancel();
            },
        },
        components: {
            PanelBackEndGross,
            PanelFinalInfo,
            PanelFrontEndGross,
            PanelBuyersOrder,
            ButtonLoading
        }
    };
</script>
<style>
    .modal-finalstep {
        width: 1045px !important;
    }

    .modal-finalstep .panel-buyers-order > .panel-header {
        position: unset;
        top: unset;
        z-index: unset;
    }
</style>
