<template>
    <div class="modal-container modal-x-large modal-autocomplete-results">
        <div class="modal-header">
            AutoComplete Results
        </div>

        <div class="modal-body">
            <PanelAutoCompleteInsurance :autoCompleteVerification="autoCompleteVerification" />

            <Panel title="Subjects in Policy">
                <span v-for="(subject, index) in policySubjects" :key="index" class="autocomplete-subject">
                    {{ subject.firstName }} {{ subject.lastName }}
                </span>
                <span v-if="policySubjects.length < 1">No subjects found in policy.</span>
            </Panel>
        </div>

        <div class="modal-footer">
            <button class="button-accept" @click="close">Close</button>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { computed, onBeforeMount, ref } from 'vue';

    import $modal from '@core/services/modal';
    import util from '@core/services/util';

    import { AutoCompleteVerificationResponse } from '@core/classes/Insurance/AutoCompleteResponses';

    import Panel from '@core/components/Panel.vue';
    import PanelAutoCompleteInsurance from '@core/components/PanelAutoCompleteInsurance.vue';

    interface IModalAutoCompleteResultsProps {
        modal: {
            passedData: {
                autoCompleteVerification: Partial<AutoCompleteVerificationResponse>
            }
        }
    }

    const props = defineProps<IModalAutoCompleteResultsProps>();

    const autoCompleteVerification = ref<AutoCompleteVerificationResponse>(null);

    const policySubjects = computed((): { firstName: string, lastName: string }[] => {
        return autoCompleteVerification.value?.person?.policies[0]?.subjects;
    });

    onBeforeMount(() => {
        autoCompleteVerification.value = util.clone(props.modal.passedData.autoCompleteVerification);
    });

    const close = (): void => {
        $modal.close();
    };
</script>
<style>
    .modal-autocomplete-results .autocomplete-subject {
        display: inline-block;
        margin: 5px;
        padding: 5px 10px;
        border-radius: 10px;
        background-color: var(--main-color);
        color: var(--background-color);
    }

    .modal-autocomplete-results .input-component-wrapper input {
        text-transform: uppercase;
    }

    .modal-autocomplete-results .modal-body > * {
        margin-bottom: 10px;
    }

    .modal-autocomplete-results .vehicle-fieldset {
        margin-bottom: 20px !important;
    }

        .modal-autocomplete-results .vehicle-fieldset .vehicle-count {
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--main-color);
            border: 3px solid var(--main-color);
            border-radius: 100%;
        }
</style>