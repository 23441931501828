<template>
    <div class="modal-container modal-info">
        <div class="modal-header">
            <span>Member/Contract Number</span>
        </div>
        <div class="modal-body">
            <div :style="$grid('1')" class="no-margin-grid">
                <div :style="$grid('1-1')" class="no-margin-grid">
                    <InputTextbox label="Member Number"
                                  :disabled="!modal.user || !modal.user.EmployeeAccess.IsAccounting"
                                  v-model:saturn="memberNumber"
                                  placeholder="Member Number">
                    </InputTextbox>
                    <InputTextbox label="Contract Number"
                                  :disabled="!modal.user || !modal.user.EmployeeAccess.IsAccounting"
                                  v-model:saturn="contractNumber"
                                  placeholder="Contract Number">
                    </InputTextbox>
                </div>
                <InputTextbox v-if="activationError"
                              label="Current activation error"
                              :disabled="true"
                              :multiline="true"
                              :saturn="activationError">
                </InputTextbox>
            </div>
        </div>
        <div class="modal-footer">
            <button v-if="modal.passedData.cancelText" class="button-unwind" @click="cancel()">{{ modal.passedData.cancelText }}</button>
            <button v-if="modal.passedData.acceptText && modal.user && modal.user.EmployeeAccess.IsAccounting" :disabled="!modal.user || !modal.user.EmployeeAccess.IsAccounting" class="button-save" @click="close()">{{ modal.passedData.acceptText }}</button>
        </div>
    </div>
</template>

<script>
    import $modal from '@core/services/modal'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import util from '@core/services/util'

    export default {
        name: "modalEditContractNumber",
        props: ['modal'],
        data() {
            return {
                memberNumber: null,
                contractNumber: null,
            }
        },
        computed: {
            util() {
                return util
            },
            activationError() {
                return this.modal.passedData.doc.activationInfo?.activationError?.errorDescription
            }
        },
        created() {
            this.memberNumber = this.modal.passedData.doc.memberNumber
            this.contractNumber = this.modal.passedData.doc.contractNumber
        },

        methods: {
            close() {

                if (this.modal.passedData.doc.memberNumber != this.memberNumber ||
                    this.modal.passedData.doc.contractNumber != this.contractNumber
                ) {
                    this.modal.passedData.doc.mustRepopulate = true
                }

                this.modal.passedData.doc.memberNumber = this.memberNumber
                this.modal.passedData.doc.contractNumber = this.contractNumber

                $modal.close();
            },
            cancel() {
                $modal.cancel();
            }
        },
        components: {
            InputTextbox,
        }
    };
</script>
<style>
</style>
