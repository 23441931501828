<template>
   <TooltipComponent :tooltip="signedInformation(doc)" direction="right" maxWidth="450">
        <template #showFromOutside>
            <div v-if="doc.status == ENUMS.PaperworkDocumentStatus.ErrorGenerating" class="text-error defaultCursor signersText">
                <i class="fa fa-exclamation-circle iconSpacing"></i>
                <span>CAN'T CAPTURE SIGNATURE</span>
            </div>
            <div v-else-if="!doc.overlays || doc.overlays.length <= 0 || hasOnlyDealerSigOverlays(doc.overlays)" class="defaultCursor signersText text-success">
                <i class="fa fa-check-circle iconSpacing"></i>
                <span>N/A</span>
            </div>
            <div v-else class="text-warning splitlines defaultCursor">
                <div v-if="requiresSigner(doc.overlays, ENUMS.PERSON_TYPES.CUSTOMER) && hasSigned(doc.overlays, ENUMS.PERSON_TYPES.CUSTOMER)" class="signersText signed">
                    <i class="fa fa-check-circle iconSpacing"></i>
                    <span> {{ customerName }}</span>
                </div>
                <div v-else-if="requiresSigner(doc.overlays, ENUMS.PERSON_TYPES.CUSTOMER)" class="signersText">
                    <i class="fa fa-exclamation-circle iconSpacing"></i>
                    <span>{{ customerName }}</span>
                </div>

                <div v-if="requiresSigner(doc.overlays, ENUMS.PERSON_TYPES.COCUSTOMER) && hasSigned(doc.overlays, ENUMS.PERSON_TYPES.COCUSTOMER)" class="signersText signed">
                    <i class="fa fa-check-circle iconSpacing"></i>
                    <span> {{ coCustomerName }}</span>
                </div>
                <div v-else-if="requiresSigner(doc.overlays, ENUMS.PERSON_TYPES.COCUSTOMER)" class="signersText">
                    <i class="fas fa-exclamation-circle iconSpacing"></i>
                    <span> {{ coCustomerName }}</span>
                </div>
            </div>
        </template>
    </TooltipComponent>
</template>

<script>
    import ENUMS from "@core/classes/Enums"
    import TooltipComponent from '@core/components/TooltipComponent.vue'

    export default {
        name: "DocumentPacketDocumentSignature",
        props: [ "doc", "customerName", "coCustomerName", "hasSigned", "signedInformation", "requiresSigner"],
        computed:{
            ENUMS() {
                    return ENUMS
                },
        },
        methods: {
            hasOnlyDealerSigOverlays(overlays) {
                return overlays.every(o => {
                    if (o.overlayType === ENUMS.OVERLAY_TYPES.SIGNATURE) {
                        return o.signature.personType !== ENUMS.PERSON_TYPES.CUSTOMER && o.signature.personType !== ENUMS.PERSON_TYPES.COCUSTOMER
                    }
                    else if (o.overlayType === ENUMS.OVERLAY_TYPES.INITIALS) {
                        return o.initials.personType !== ENUMS.PERSON_TYPES.CUSTOMER && o.initials.personType !== ENUMS.PERSON_TYPES.COCUSTOMER
                    }
                    else {
                        return false
                    }
                })
            }
        },
        components: {
            TooltipComponent
        }
    };
</script>