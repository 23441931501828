<template>
    <div :class="{'live-screen-container': true, 'big': showBig && isSideMenuVisible}">
        <!-- <div :class="{'maximize': true}" @click="makeBigHandle"><i :class="{'fas fa-expand': !showBig, 'fas fa-compress': showBig}"></i></div> -->

        <div class="no-connected">
            <i class="fas fa-plug fa-2x"></i><span>Not Connected</span>
        </div>

        <div class="live-screen">
            <div v-if="meetingHelper.customerScreenConnected || isSomeoneSharingScreen" 
                 ref="liveScreen"
                 class="customer-container"
                 :style="liveScreenCssScale"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "PanelCustomerLive",
        props: {
            isSideMenuVisible: Boolean
        },
        data() {
            return {
                showBig: false,
                videoComponent: null,
                showVideoFeed: true,
                liveScreenWidth: 341,
                liveScreenHeight: 191.25,
                expandedLiveScreenWidth: 1344,
                expandedLiveScreenHeight: 756,
                customersScreenWidth: '',
                customersScreenHeight: '',
                scaleValueX: '',
                scaleValueY: '',
                currentVideoPlayTime: null,
                pageContainerList: [],
                currentPDF: null,
                prevScrollTop: null,
            }
        },
        computed: {
            isSomeoneSharingScreen() {
                if (this.meetingHelper.currentMeetingUsers) {
                    return Object.values(this.meetingHelper.currentMeetingUsers).some(user => user.isPresenting);
                }
                 return false;
            },
            liveScreenCssScale() {
                return `transform: scale(${this.scaleValueX}, ${this.scaleValueY}); height: ${this.customersScreenHeight}px; width: ${this.customersScreenWidth}px`;
            }
        },
        watch: {
            isSideMenuVisible(newValue) {
                if (newValue === false) {
                    this.showBig = false;
                }
            }
        },
        mounted() {
            this.$watch(() => this.meetingHelper.customerScreenConnected, (newValue) => {
                if (!newValue && !this.isSomeoneSharingScreen) {
                    // console.log("PANEL LIVE CUSTOMER SCREEN - WATCH TRIGGERED", newValue, this.isSomeoneSharingScreen);
                    if (this.$refs.liveScreen) {
                        this.$refs.liveScreen.innerHTML = ''
                    }
                    this.showBig = false;
                }
            }, {deep: true});

            //if (this.$meetingHub.code) {
                //this.$meetingHub.subscribeToGroupEvent("sendingHTML", this.$meetingHub.code, this.receivingHTMLFromCustomerHandler);

            //if (!this.$global.selectedStore.storeSettings.isScreenRecordingEnabled) {
                this.MeetingHubBus.on('receivingHTMLFromCustomer', this.receivingHTMLFromCustomerHandler)
                this.MeetingHubBus.on('receiveScrollData', this.receiveScrollData)
                this.MeetingHubBus.on('playVideoFromCustomer', this.playVideoFromCustomerHandler)
                this.MeetingHubBus.on('pauseVideoFromCustomer', this.pauseVideoFromCustomerHandler)
                this.MeetingHubBus.on('showLastFrameVideo', this.showLastFrameVideoHandler)
                //}
                this.showVideoFeed = this.$global.selectedStore.storeSettings.isScreenRecordingEnabled
            },
        beforeUnmount(){
            this.MeetingHubBus.off('receivingHTMLFromCustomer', this.receivingHTMLFromCustomerHandler)
            this.MeetingHubBus.off('receiveScrollData', this.receiveScrollData)
            this.MeetingHubBus.off('playVideoFromCustomer', this.playVideoFromCustomerHandler)
            this.MeetingHubBus.off('pauseVideoFromCustomer', this.pauseVideoFromCustomerHandler)
            this.MeetingHubBus.off('showLastFrameVideo', this.showLastFrameVideoHandler)
        },
        methods: {
            receivingHTMLFromCustomerHandler(customerScreenData) {
                // console.log('receivingHTMLFromCustomerHandler');
                if (this.$refs.liveScreen) {
                    this.customersScreenWidth = customerScreenData.customerContainerWidth;
                    this.customersScreenHeight = customerScreenData.customerContainerHeight;
                    this.$refs.liveScreen.style.width = this.customersScreenWidth + "px";
                    this.$refs.liveScreen.style.height = this.customersScreenHeight + "px";
                    this.calculateScaleValue();
          
                    console.log('this.$refs.liveScreen.style.width ', this.$refs.liveScreen.style.width );
                    console.log('this.$refs.liveScreen.style.height ', this.$refs.liveScreen.style.height);
                    
                    const previousLiveScreen = this.$refs.liveScreen.cloneNode(true);
                    this.$refs.liveScreen.innerHTML = customerScreenData.innerHTML

                    /**
                     * If signing, when we receive a new document, we set currentPDF and collect the page-containers that contain the pdf images.
                     * That way we only receive the newly rendered page from the customer and we can still show the previously rendered pages.
                     *
                     * */

                    // Set the currentPDF being viewed, if we receive a different pdf then reset the pageContainerList
                    if (customerScreenData.currentPDF && this.currentPDF != customerScreenData.currentPDF) {
                        this.currentPDF = customerScreenData.currentPDF
                        this.pageContainerList = [];
                    }

                    const newPdfContainer = this.$refs.liveScreen.querySelector('.pdf-container')
                    const previousPdfContainer = previousLiveScreen.querySelector('.pdf-container')

                    if (previousPdfContainer && newPdfContainer) {

                        // collect previous page-containers from the previous pdf container
                        this.pageContainerList = [...previousPdfContainer.querySelectorAll('.page-container')]

                        // get new page from new pdf container (only one page-container gets sent at a time)
                        const newPage = newPdfContainer.querySelector('.page-container')

                        // if there is a new page container that is not included in our page container list, add it to the list
                        if (newPage && !this.pageContainerList.some(el => el.id == newPage.id)) {
                            this.pageContainerList.push(newPage)
                            this.pageContainerList.sort((a, b) => a.id - b.id)

                            newPdfContainer.removeChild(newPage)

                            this.pageContainerList.forEach(el => {
                                newPdfContainer.appendChild(el)
                            })
                        }

                        // After done appending stored page-containers, lets scroll to where we left off
                        if (this.prevScrollTop) {
                            const documentContainer = this.$refs.liveScreen.querySelector('.document-container');
                            if (documentContainer) {
                                documentContainer.scrollTo({
                                    top: this.prevScrollTop,
                                    behavior: 'instant'
                                });
                            }
                        }
                    }


                    // add time
                    this.videoComponent = document.getElementsByClassName("video-element-loaded")[0];

                    //console.log("videoElement after new innerHTML: ", videoElement[0]);
                    if (this.videoComponent) {
                        this.currentVideoPlayTime = customerScreenData.currentVideoPlayTime;
                        if (this.videoComponent.readyState === 4) {
                            this.videoComponent.play()
                                .then(() => {
                                    this.videoComponent.currentTime = this.currentVideoPlayTime;
                                    this.videoComponent.muted = true;
                                })
                                .catch(err => console.error("Playing video error: ", err));

                        }
                        else {
                            this.videoComponent.addEventListener("loadeddata", () => {
                                this.videoComponent.play().then(() => {
                                    this.videoComponent.currentTime = this.currentVideoPlayTime;
                                    this.videoComponent.muted = true;
                                })
                                .catch(err => console.error("Playing video error: ", err));
                            })
                        }
                    }
                }
            },
            receiveScrollData(metadata) {
                if (this.$refs.liveScreen) {
                    this.prevScrollTop = metadata.scrollTop;
                    const documentContainer = this.$refs.liveScreen.querySelector(metadata.scrollCssSelector);
                    if (documentContainer) {
                        documentContainer.scrollTo({
                            top: metadata.scrollTop,
                            behavior: 'smooth'
                        });
                    }

                }
            },
            makeBigHandle() {
                this.showBig = !this.showBig;
                this.calculateScaleValue();
            },
            calculateScaleValue() {
                const previewWidth = (this.showBig) ? this.expandedLiveScreenWidth : this.liveScreenWidth;
                const previewHeight = (this.showBig) ? this.expandedLiveScreenHeight : this.liveScreenHeight;

                this.scaleValueX = previewWidth / this.customersScreenWidth;
                this.scaleValueY = previewHeight / this.customersScreenHeight;
            },
            playVideoFromCustomerHandler(time) {
                this.currentVideoPlayTime = time;

                this.videoComponent?.play()
                    .then(() => {
                        this.videoComponent.currentTime = this.currentVideoPlayTime;
                        this.videoComponent.muted = true
                    })
                    .catch(err => console.error("Playing video error: ", err));

            },

            pauseVideoFromCustomerHandler() {
                this.videoComponent?.play()
                    .then(() => {
                        this.videoComponent.pause()
                    })
                    .catch(err => console.error("Playing video error: ", err));


            },
            showLastFrameVideoHandler() {
                this.videoComponent = this.$refs.liveScreen.querySelector('video')

                this.videoComponent?.play()
                    .then(() => {
                        this.videoComponent.currentTime = this.videoComponent.duration - 1
                    })
                    .catch(err => console.error("Playing video error: ", err));

            },
            updated() {
                this.$refs.liveScreen.style.width = this.customersScreenWidth + "px";
                this.$refs.liveScreen.style.height = this.customersScreenHeight + "px";
            }

        },
    };
</script>
<style>
    /*This import was causing problems with PanelESign*/
    @import "@static/css/customerResponsive.css";

    .hide {
        opacity: 0;
    }

    .live-screen-container {
        max-width: 95%;
        position: absolute;
        bottom: 0%;
        right: 3%;
        z-index: 20;
        overflow: hidden;
    }

        .live-screen-container.big {
            max-width: unset;
        }

        .live-screen-container .no-connected {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .live-screen-container .live-screen {
            border: 3px solid var(--main-color);
            width: 340px;
            height: 191.25px;
            /*width: 100%;
            height: auto;
            aspect-ratio: 16 / 9;*/

            overflow: hidden;
            pointer-events: none;
            transition: width .2s ease-in-out, height .2s ease-in-out;
            background: var(--panelcustomerlive-livescreencontainer-background-color)
        }

            .live-screen-container .live-screen > div {
                transform-origin: top left;
                transition: transform .2s ease-in-out;
            }

            .live-screen-container .live-screen .customer-container {
                width: 1920px;
                height: 1080px;
            }

        .live-screen-container.big .live-screen > div {
            transform: scale(0.7);
            transform-origin: top left;
            transition: transform .2s ease-in-out;
        }

        .live-screen-container.big .live-screen {
            width: 1344px;
            height: 756px;
            transition: width .2s ease-in-out, height .2s ease-in-out;
        }

        .live-screen-container .maximize {
            position: absolute;
            padding: 3px 5px;
            margin: 8px 0 0 7px;
            background: var(--main-color);
            color: var(--panelcustomerlive-livescreencontainer-color);
            z-index: 9999;
        }

            .live-screen-container .maximize:hover {
                box-shadow: inset 80px 80px rgba(0,0,0,.1);
                cursor: pointer;
            }

        .live-screen-container .section-container.carousel .swiper-container {
            overflow: hidden;
        }
</style>