<template>
    <div class="PanelFIMenu-container">
        <IsBusySectionComponent v-if="isBusy" text="Please wait while we price the products..." delayToShowText="3000"></IsBusySectionComponent>
        <div :style="$grid('1')" v-else class="fade-in-fast flex-responsive">
            <PanelDealTerms :data="fimenu"
                            v-if="fimenu.hasCoverageTerms"
                            :panelObject="dealTermsPanelObj"
                            :validation="validation"
                            :setGapPricing="() => fimenu.priceProducts([{ productType: ENUMS.PRODUCT_TYPES.GAP }], null, true)"
                            :dealSave="dealSave">
            </PanelDealTerms>
            <PanelCoverageTerms v-if="fimenu.hasCoverageTerms()"
                                :data="fimenu"
                                :panelObject="{title:'Coverage Terms'}">
            </PanelCoverageTerms>
        </div>
    </div>
</template>

<script>
    import settings from 'settings'
    import api from '@core/services/api'
    import util from '@core/services/util';
    import ENUMS from "@core/classes/Enums"
    import IsBusySectionComponent from '@core/components/IsBusySectionComponent.vue'
    import PanelDealTerms from '@/components/PanelDealTerms.vue'
    import PanelCoverageTerms from '@/components/PanelCoverageTerms.vue'
    import $modal from '@core/services/modal'
    import modalTaxRates from '@/modals/modalTaxRates.vue'
    import modalInfo from '@core/modals/modalInfo.vue'
    import auth from '@core/services/auth';

    export default {
        name: "PanelFIMenu",
        props: ['fimenu', 'validation', 'dealSave', 'handleRerate', 'viewingRole'],
        data() {
            return {
                isBusy: false,
            }
        },
        computed: {
            util() {
                return util;
            },
            ENUMS() {
                return ENUMS;
            },
            dealTermsPanelObj() {
                let title;
                if (this.fimenu.isDraft())
                    title = 'Draft Terms';
                else if (this.fimenu.isCash())
                    title = 'Cash Terms';
                else if (this.fimenu.isFinance())
                    title = 'Loan Terms';
                else
                    title = 'Lease Terms';

                return { title: title, action: this.getPanelDealTermsActions() };
            },
        },
        async created() {
            if (this.fimenu.buyersOrderEnabled && this.viewingRole === 'Editor' && this.fimenu.dealStatus < ENUMS.DEAL_STATUS.FI_IN_PROCESS && this.fimenu.moveDealToFIProcess()) {
                await this.dealSave()
            }
            this.$watch(() => this.isBusy, () => { this.EventBus.emit('panelFimenuBusy', this.isBusy) });
            await this.updatePricesIfNeeded()

            this.$watch(() => this.fimenu.coverageTerms, (newValue) => {
                if (newValue.length === 0) {
                    this.resetCoverages()
                    this.updatePricesIfNeeded()
                }
            }, { deep: true })
        },
        methods: {
            getPanelDealTermsActions() {
                const panelDealTermsActions = []

                if (!this.fimenu.buyersOrderEnabled) {
                    panelDealTermsActions.push({
                        text: 'Tax Rates',
                        onClick: this.showTaxRatesModal
                    })
                }

                panelDealTermsActions.push({
                    text: 'Re-Rate',
                    onClick: this.handleRerate
                })

                return panelDealTermsActions
            },
            getBundledRateProperty(p1, p2, isFinalBundle) {
                let bundledRateProperty = "|"

                if (!util.isNullOrEmpty(p1) && !util.isNullOrEmpty(p2)) {
                    bundledRateProperty = p1 + "|" + p2
                }
                else if (!util.isNullOrEmpty(p1)) {
                    bundledRateProperty = `${p1}`
                }
                else if (!util.isNullOrEmpty(p2)) {
                    bundledRateProperty = "|" + p2
                }

                //if final and bundle, no need to keep the |
                if (isFinalBundle && `${bundledRateProperty}`.split("|").every(p => util.isNullOrEmpty(p))) {
                    bundledRateProperty = null
                }

                return bundledRateProperty
            },
            getBundledRate(r1, r2, configListIndex, isFinalBundle) {
                return {
                    program: this.getBundledRateProperty(r1.program, r2.program, isFinalBundle),
                    programDescription: this.getBundledRateProperty(r1.programDescription, r2.programDescription, isFinalBundle),
                    subprogram: this.getBundledRateProperty(r1.subprogram, r2.subprogram, isFinalBundle),
                    subprogramDescription: this.getBundledRateProperty(r1.subprogramDescription, r2.subprogramDescription, isFinalBundle),
                    termLimit: this.getBundledRateProperty(r1.termLimit, r2.termLimit, isFinalBundle),
                    milesLimit: this.getBundledRateProperty(r1.milesLimit, r2.milesLimit, isFinalBundle),
                    netTerm: this.getBundledRateProperty(r1.netTerm, r2.netTerm, isFinalBundle),
                    netMiles: this.getBundledRateProperty(r1.netMiles, r2.netMiles, isFinalBundle),
                    msrp: (r1.msrp ?? 0) + (r2.msrp ?? 0),
                    cost: (r1.cost ?? 0) + (r2.cost ?? 0),
                    surcharges: [...r1.surcharges, ...r2.surcharges],
                    meta: {
                        bundledMsrp: this.getBundledRateProperty(r1.meta.bundledMsrp ?? r1.msrp, r2.msrp, isFinalBundle),
                        bundledCost: this.getBundledRateProperty(r1.meta.bundledCost ?? r1.cost, r2.cost, isFinalBundle),
                        bundledProvider: this.getBundledRateProperty(r1.meta.bundledProvider ?? r1.provider, r2.provider, isFinalBundle),
                        configListIndex
                    }
                }
            },
            GetBundledTermRates(optionsTermRates, configListIndex) {
                if (optionsTermRates.length === 1) {
                    //if bundle option return, otherwise traverse it to create structure
                    if (optionsTermRates[0].some(r => r.meta.configListIndex != null)) {
                        return optionsTermRates[0]
                    }
                    else {
                        return optionsTermRates[0].map(r => this.getBundledRate(r, { surcharges: [] }, configListIndex, true))
                    }
                }
                else {
                    const termRates1 = optionsTermRates.pop()
                    if (termRates1.length === 0) {
                        return this.GetBundledTermRates(optionsTermRates, configListIndex)
                    }
                    else {
                        const termRates2 = optionsTermRates.pop()

                        const bundledTermRates = []
                        termRates1.forEach(r1 => {
                            termRates2.forEach(r2 => {
                                bundledTermRates.push(this.getBundledRate(r1, r2, configListIndex, optionsTermRates.length === 0))
                            })
                        })

                        optionsTermRates.push(bundledTermRates);
                        return this.GetBundledTermRates(optionsTermRates, configListIndex)
                    }
                }
            },
            updateRatesDesc(rates, providerProduct, provider) {
                rates.forEach(r => {
                    r.provider = provider;
                    let altprogramDescription = providerProduct.programConfigs.find(p => p.code.trim() == r.program.trim())?.altDescription;

                    //checking if alias for altDescription
                    if(!altprogramDescription){
                        if(providerProduct.programConfigs?.length != 0){
                            for (const config of providerProduct.programConfigs) {
                                if (config?.programCodeAliases?.includes(r.program.trim())) {
                                    altprogramDescription = config.altDescription;
                                }
                            }
                        }
                    }

                    if (altprogramDescription) {
                        r.programDescription = altprogramDescription
                    }

                    r.subprogramDescription = r.subprogram;
                    const altSubprogramDescription = providerProduct.subprogramConfigs.find(p => p.code.trim() == r.subprogram.trim())?.altDescription
                    if (altSubprogramDescription) {
                        r.subprogramDescription = altSubprogramDescription
                    }

                    r.surcharges.forEach(rs => {
                        const altDescription = providerProduct.surchargeConfigs.find(s => s.code == rs.code)?.altDescription
                        if (altDescription) {
                            rs.description = altDescription
                        }
                    })
                })
            },
            async updatePricesIfNeeded() {
                this.isBusy = true

                //Back to doing it every time to avoid rare issues
                //if ((!this.fimenu.store.storeSettings.isDealCoBrowsingEnabled || this.viewingRole === 'Editor') && (!this.fimenu.isDealLocked() || (this.fimenu.isDealLocked() && !this.fimenu.hasUpdatedPrice))) {
                //get new prices for all products we have rates for
                const priceList = this.fimenu.storeProducts.map(p => ({ productType: p.productType }))

                try {
                    const response = await api.fimenu.getProductsPrice(this.fimenu, this.fimenu.store, this.fimenu.storeProducts, priceList)
                    if (response.data) {
                        const productsPrice = response.data
                        settings.updateProducts(productsPrice.updatedProducts)
                        this.fimenu.storeProducts = settings.products?.filter(p => p.stateCode == this.fimenu.store.storeState);

                        //populate bundled config and reprice override options for modal
                        this.fimenu.storeProducts.forEach(p => {
                            p.productPricingDetails.forEach(ppd => {
                                const ps = response.data.pricesStatuses.find(ps => ps.productType === p.productType && ps.productPricingDetailsId === ppd.productPricingDetailsId)

                                //if included and not locked or locked and same pricing, add rules
                                if (ps?.isIncluded && (!this.fimenu.isDealLocked() || (ps.productPricingDetailsId === util.getDefaultGuid() && this.fimenu.selectedProviders.find(sp => sp.productType === ps.productType)?.provider === ps.provider) || this.fimenu.miscellaneousProviders.find(mp => mp.productPricingDetailsId === ps.productPricingDetailsId)?.provider === ps.provider)) {
                                    if (ps.pricedConfig) {
                                        const matchDealTermView = this.fimenu.hasDealTerms() && ppd.matchToDealTerm
                                        ps.pricedConfig.allListRates = []

                                        //for each list in the settings, add a list of filteredRates from the priced config, bundling options when needed
                                        ppd.getPricingConfig(ps.pricedConfig.configId).pricingLists.forEach((l, lIndex) => {
                                            const listRates = []

                                            if (matchDealTermView) {
                                                const optionsTermRates = []

                                                l.options.toReversed().forEach(o => {
                                                    const configDetails = ps.pricedConfig.configDetails.find(cd => cd.productType === o.productType && cd.productPricingDetailsId === o.productPricingDetailsId)
                                                    const optionTermRates = configDetails.filteredRates
                                                    this.updateRatesDesc(optionTermRates, configDetails.providerProduct, configDetails.provider)
                                                    optionsTermRates.push(optionTermRates)
                                                })

                                                listRates.push(...this.GetBundledTermRates(optionsTermRates, lIndex))
                                            }
                                            else {
                                                //for each unique term(and overrides) combine each option rates
                                                ps.pricedConfig.uniqueTerms.forEach(t => {
                                                    const optionsTermRates = []

                                                    l.options.toReversed().forEach(o => {
                                                        const configDetails = ps.pricedConfig.configDetails.find(cd => cd.productType === o.productType && cd.productPricingDetailsId === o.productPricingDetailsId)
                                                        const optionTermRates = configDetails.filteredRates.filter(r => r.termLimit === t || r.program === configDetails.planOverride)

                                                        if (optionTermRates.length > 0) {
                                                            this.updateRatesDesc(optionTermRates, configDetails.providerProduct, configDetails.provider)

                                                            //push to list, a filtered list of options by the term or overrides
                                                            optionsTermRates.push(optionTermRates)
                                                        }
                                                    })

                                                    if (optionsTermRates.length === l.options.length) {
                                                        listRates.push(...this.GetBundledTermRates(optionsTermRates, lIndex))
                                                    }
                                                })
                                            }

                                            ps.pricedConfig.allListRates.push(...listRates)
                                        })

                                        ppd.pricedConfig = ps.pricedConfig
                                        ppd.repriceOptions = null
                                    }
                                    else {
                                        ppd.pricedConfig = null
                                        ps.repriceOptions.forEach(o => {
                                            //only do this for standardized rates
                                            if (o.filteredRates && o.filteredRates[0]?.meta) {
                                                this.updateRatesDesc(o.filteredRates, o.providerProduct, o.provider)
                                            }
                                        })

                                        ppd.repriceOptions = ps.repriceOptions
                                    }
                                } else {
                                    ppd.repriceOptions = null
                                    ppd.pricedConfig = null
                                }
                            })
                        })

                        if (!this.fimenu.isDealLocked() && !this.$global.isAdminView) {
                            //if the new pricing brings more coverages then what we currently have, reset the coverages before pricing
                            if (productsPrice.coverageTerms.length > this.fimenu.coverageTerms.filter(ct => !ct.isCustom()).length) {
                                this.resetCoverages()
                            }

                            productsPrice.pricesStatuses = productsPrice.pricesStatuses.filter(ps => {
                                //we always replace the misc
                                const isMisc = ps.productPricingDetailsId !== util.getDefaultGuid()
                                //add anything that was priced and it is not present
                                const isMissing = !this.fimenu.selectedProviders.some(sp => sp.productType === ps.productType)
                                //update prices that were priced with a different provider
                                const isDifferent = this.fimenu.selectedProviders.some(sp => sp.productType === ps.productType && sp.provider !== ps.provider)

                                //keep only new prices that fall into our rules or any custom one
                                return isMisc || isMissing || isDifferent || this.fimenu.doesProductProviderNeedsReprice(ps.productType, ps.provider)
                            })
                            this.fimenu.priceProducts(priceList, productsPrice)
                        }
                    }
                } catch (error) {
                    if (!this.fimenu.isDealLocked()) {
                        this.fimenu.selectedProviders = []
                        this.fimenu.resetDefaultCoverageTerms()
                        $modal.open(modalInfo, { name: 'modalInfo', passedData: { title: 'Error pricing', info: "An internal issue has occurred and some products have failed to price. Please click the RE-RATE button to get new numbers", acceptText: 'Close' }, backdrop: false });
                    }
                }
                //this.fimenu.hasUpdatedPrice = true;
                //}

                this.$nextTick(() => {
                    this.isBusy = false
                })
            },

            resetCoverages() {
                //force reprice all
                this.fimenu.selectedProviders = []

                //reset coverage terms
                this.fimenu.resetDefaultCoverageTerms()
            },
            showTaxRatesModal() {
                $modal.open(modalTaxRates, { name: 'modalTaxRates', passedData: { fimenu: this.fimenu }, backdrop: true });
            },
        },
        components: {
            PanelDealTerms,
            PanelCoverageTerms,
            IsBusySectionComponent,
        }
    };
</script>
<style>
    .PanelFIMenu-container {
        height: 100%;
        width: 100%;
    }

    .fimenupage .PanelFIMenu-container .loading-saturn-anim.fade-in.IsBusySectionComponent-container {
        height: 550px;
    }

    .PanelFIMenu-container .panel {
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

        .PanelFIMenu-container .panel .panel-body {
            width: 100%;
        }
</style>