<template>
    <Panel v-bind="panelObject">
        <div class="PanelSurchargesInQuestion no-margin-grid vertical-radios">
            <div v-for="(surcharge, index) in filterdVehicleSurcharges"
                 :key="index"
                 class="radio-button-inline no-padding-grid"
                 :invalid="validation.vehicleSurcharges[index].$invalid">
                <span>{{ getSurchargeSettings(surcharge).questionInPanel }}</span>
                <div class="no-padding-grid gap">
                    <InputRadio label="Yes"
                                :valueMatch="getSurchargeSettings(surcharge).doesAnswerImpliesTrue"
                                v-model="surcharge.value"
                                :disabled="isEntity && surcharge.code === ENUMS.VEHICLE_SURCHARGES.COMMERCIAL"
                                :isButton="true" />
                    <InputRadio label="No"
                                :valueMatch="!getSurchargeSettings(surcharge).doesAnswerImpliesTrue"
                                v-model="surcharge.value"
                                :disabled="isEntity && surcharge.code === ENUMS.VEHICLE_SURCHARGES.COMMERCIAL"
                                :isButton="true" />
                </div>
            </div>
        </div>
    </Panel>
</template>

<script>
    import ENUMS from "@core/classes/Enums";
    import InputRadio from '@core/components/InputRadio.vue'
    import Panel from '@core/components/Panel.vue'
    import settings from 'settings'

    export default {
        name: 'PanelSurchargesInQuestion',
        props: ['vehicle', 'validation', 'panelObject', 'panelView', 'isEntity'],
        data() {
            return {
                surchargeList: null,
            }
        },
        computed: {
            settings() {
                return settings
            },
            ENUMS() {
                return ENUMS
            },
            filterdVehicleSurcharges() {
                return this.vehicle.vehicleSurcharges.filter((vs) =>
                    settings.lookups.surcharges.some((s) => s.code === vs.code)
                )
            }
        },
        methods: {
            getSurchargeSettings(surcharge) {
                return settings.lookups.surcharges.find((s) => s.code == surcharge.code)
            },
        },
        components: {
            Panel: Panel,
            InputRadio: InputRadio,
        },
    }
</script>

<style>
    .PanelSurchargesInQuestion.vertical-radios {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
</style>