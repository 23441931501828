<template>
    <Panel title="Deal Roles" class="deal-employee-roles">
        <template v-slot:header>
            <div class="panel-buttons">
                <button class="button panel-button button-edit" @click="addEmployee()" :disabled="adding"><i class="fas fa-plus"></i> Add</button>
            </div>
        </template>
        <div :style="$grid('1')" class="panel-body no-margin-grid local-panel-body">
            <div v-if="adding" >
                <div :style="$grid('3-3-1')" class="addRow">
                    <InputRichDropdown label="Role"
                                   v-model:saturn="tempRole"
                                   :list="rolesList"
                                   :valueMap="(p) => p.key"
                                   :display="(p) => p.description">
                    </InputRichDropdown>
                    <InputRichDropdown label="Employee"
                                       :list="allEmployees"
                                       :display="(e) => e.name"
                                       :comparer="(e1, e2) => e1.code === e2.code"
                                       v-model:saturn="tempEmployee"
                                       search lazyLoad />
                    <div :style="$grid('.5-.5')">
                        <button :disabled="tempRole == null || tempEmployee.code == null" class="button-clear" @click="saveNewEmployee()"><i class="fas fa-check"></i></button>
                        <button class="button-clear" @click="cancelNew()"><i class="fas fa-undo"></i></button>
                    </div>
                </div>
            </div>
            <div v-else class="richtable-container">
                <div v-if="employees && employees.length > 0">
                    <RichTable :tableData="employees" :headers="headers" class="rich-table">
                        <template #role="row">
                            <InputRichDropdown v-if="row._index === editingIndex"
                                           v-model:saturn="tempRole"
                                           :list="rolesList"
                                           :valueMap="(p) => p.key"
                                           :display="(p) => p.description">
                            </InputRichDropdown>
                            <span v-else>{{ displayRole(row._ref.dealRole) }}</span>
                        </template>
                        <template #employee="row">
                            <InputRichDropdown v-if="row._index === editingIndex"
                                               label="Employee"
                                               :list="allEmployees"
                                               :display="(e) => e.name"
                                               :comparer="(e1, e2) => e1.code === e2.code"
                                               v-model:saturn="tempEmployee"
                                               search lazyLoad />
                            <span v-else>{{ row._ref.name }}</span>
                        </template>
                        <template #actions="row">
                            <div :style="$grid('.5-.5')">
                                <button v-if="editingIndex < 0 && row._index !== editingIndex" class="button-clear" @click="editEmployee(row, row._index)"><i class="fas fa-pencil-alt"></i></button>
                                <button v-if="editingIndex < 0 && row._index !== editingIndex" class="button-clear" @click="removeEmployee(row, row._index)"><i class="fas fa-trash-alt"></i></button>
                                <button v-if="row._index === editingIndex" class="button-clear" @click="saveEmployee(row, row._index)"><i class="fas fa-check"></i></button>
                                <button v-if="row._index === editingIndex" class="button-clear" @click="cancelSave(row, row._index)"><i class="fas fa-undo"></i></button>
                            </div>
                        </template>
                    </RichTable>
                </div>
                <p v-else class="empty-list">There are currently no employees for this deal.</p>
            </div>
        </div>
    </Panel>
</template>

<script>
    import _ from 'underscore';
    import api from '@core/services/api'
    import util from '@core/services/util';
    import $modal from '@core/services/modal'
    import modalInfo from '@core/modals/modalInfo.vue'
    import FIMenuDealEmployee from '@core/classes/FIMenuDealEmployee'

    import ENUMS from "@core/classes/Enums"
    import Panel from '@core/components/Panel.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import RichTable from '@core/components/RichTable.vue'

    export default {
        name: "DealEmployeeRoles",
        props: ['fimenu', 'validation'],
        data() {
            return {
                headers: [
                    { name: 'role', display: "Role", slot: 'role' },
                    { name: 'employee', display: "Employee", slot: 'employee' },
                    { name: 'actions', display: "", slot: 'actions' },
                ],

                isBusy: true,
                allEmployees: [],
                newEmployee: new FIMenuDealEmployee(),
                editTempEmployee: null,
                editEmployeeIndex: null,
                tempRole: null,
                tempEmployee: { code: null, name: null },
                editingIndex: -1,
                adding: false,
            }
        },
        computed: {
            util() {
                return util;
            },
            ENUMS() {
                return ENUMS;
            },
            employees() {
                return _.sortBy(this.fimenu.employees, e => e.dealRole);
            },
            rolesList() {
                return ENUMS.dealEmployeeRoleTypes;
            },
            roles() {
                return ENUMS.DEAL_EMPLOYEE_ROLE_TYPES;
            }
        },
        async created() {
            this.isBusy = true;
            this.allEmployees = (await api.settings.getEmployeeList())?.data;
            this.isBusy = false;
        },

        methods: {
            displayRole(role) {
                return role && this.rolesList.find(r => r.key == role) ? this.rolesList.find(r => r.key == role).description : '';
            },
            editEmployee(row, index) {
                    this.editingIndex = index;
                    this.tempRole = row._ref.dealRole;
                    this.tempEmployee = { code: row._ref.code, name: row._ref.name };
            },
            addEmployee() {
                this.adding = true;
            },
            saveNewEmployee() {
                const newEmployee = util.clone(this.newEmployee);
                newEmployee.dealRole = this.tempRole;
                newEmployee.code = this.tempEmployee.code;
                newEmployee.name = this.tempEmployee.name;
                this.fimenu.employees.push(newEmployee);
                this.setTempsToNull();
                this.adding = false;
            },
            cancelNew() {
                this.setTempsToNull();
                this.adding = false;
            },
            cancelSave(row, index) {
                this.setTempsToNull();
                this.editingIndex = -1;
            },
            setTempsToNull() {
                this.tempRole = null;
                this.tempEmployee = { code: null, name: null };
            },
            saveEmployee(row, index) {
                //save
                row._ref.dealRole = this.tempRole;
                row._ref.code = this.tempEmployee.code;
                row._ref.name = this.tempEmployee.name;
                this.setTempsToNull();
                this.editingIndex = -1;
            },
            removeEmployee(row, index) {
                $modal.open(modalInfo, {
                    name: 'modalInfo',
                    passedData: {
                        info: `Are you sure you want to remove this employee from this deal?`,
                        cancelText: 'Cancel',
                        acceptText: 'Remove'
                    },
                    postFunction: () => {
                        this.fimenu.employees.splice(index, 1);
                    },
                    backdrop: true
                });
            },
        },
        components: {
            Panel,
            InputRichDropdown,
            RichTable
        }
    };
</script>
<style>
    .deal-employee-roles {
        height: 350px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

        .deal-employee-roles .local-panel-body {
            width: 100%;
            height: 100%;
        }

        .deal-employee-roles .richtable-container {
            width: 100%;
            height: 100%;
            overflow-y: auto;
        }

        .deal-employee-roles .empty-list {
            text-align: center;
            position: relative;
        }


    .button-small {
        padding: 0px;
        margin-left: 1px;
        border-radius: 5px;
        width: 35px;
    }

    .addRow {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
</style>