<template>
    <div class="modal-container modal-large">
        <div class="modal-header">
            {{ modal.passedData.header }}
        </div>
        <div class="modal-body">
            <PanelCustomer :customer="customer"
                           :fimenu="modal.passedData.fimenu"
                           :isCustomerTypeDisabled="modal.passedData.isCustomerTypeDisabled"
                           :fallbackzipcode="customer.storeZip"
                           :panelObject="modal.passedData.panelObject"
                           :validation="modal.passedData.validation"
                           :viewOnly="modal.passedData.viewOnly"
                           :displayFullSSN="modal.passedData.displayFullSSN"
                           :isRenderedInCustomerPage="true"
                           :resetCustomer="(overrideCustomerInfo) => { resetCustomer(overrideCustomerInfo)}">
            </PanelCustomer>
        </div>
        <div class="modal-footer">
            <button class="button-save" @click="close()"> {{ modal.passedData.buttonText }}</button>
        </div>
    </div>
</template>


<script>
    import api from '@core/services/api'
    import $modal from '@core/services/modal'
    import PanelCustomer from '@/components/PanelCustomer.vue'

    export default {
        name: "modalCustomer",
        props: ['modal'],
        data() {
            return {
                isZipLookupBusy: false,
                customer: null,
                personTypes: [{ code: false, description: 'Person' }, { code: true, description: 'Company' }],
            }
        },
        computed: {

        },
        async created() {
            this.loadData();
            if (this.customer.applicationId) {
                await this.customer.getAndSetCustomerApplicationByApplicationId(this.customer.applicationId, true)
            }
            else {
                await this.customer.getAndSetCustomerApplication(this.customer.id)
            }
        },
        methods: {
            loadData() {
                this.customer = this.modal.passedData.customer
                // console.log("cUSTOMER", this.customer);
            },
            lookupZip() {
                if (this.customer.zip != '') {
                    this.isZipLookupBusy = true;
                    api.zipLookup(this.customer.zip).then((response) => {
                        if (response && response.data) {
                            const cityState = response.data.split(", ");
                            this.customer.city = cityState[0];
                            this.customer.state = cityState[1];
                        }
                    }).finally(() => {
                        this.isZipLookupBusy = false;
                    });
                }
            },
            async resetCustomer(overrideCustomerInfo) {
                // console.log("TRIED TO OVERRIDE");
                const overrideArr = await overrideCustomerInfo(this.customer)
                this.customer = overrideArr[0]
                // console.log("cUSTOMER", this.customer);
            },
            close() {
                $modal.close();
            },
        },
        components: {
            PanelCustomer: PanelCustomer,
        }
    };
</script>
<style>
</style>
