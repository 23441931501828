<template>
    <div class="modal-container ModalAddEditTaxLine">
        <div class="modal-header">
            Tax Line Details
        </div>
        <div class="modal-body">
            <fieldset :disabled="modal.isModalDisabled">
                <Panel title="Basic Info" class="gapped-fields">
                    <div :style="$grid('1-1', '5px 10px')">
                        <InputTextbox label="Description"
                                      v-model:saturn="taxLine.name"
                                      :modelValue="taxLine.name"
                                      placeholder="Description">
                        </InputTextbox>
                        <InputNumber label="Rate"
                                     v-model:saturn="taxLine.rate"
                                     :modelValue="taxLine.rate"
                                     placeholder="Tax Rate"
                                     :precision="3"
                                     :isPercent="true"
                                     :invalid="v$.taxLine.rate.$invalid"
                                     @change="taxRulesChanged()">
                        </InputNumber>
                    </div>
                    <div :style="$grid('1-1-1-1', '5px 10px')">
                        <InputCurrency label="Min Tax Amount"
                                       v-model:saturn="taxLine.min"
                                       :modelValue="taxLine.min"
                                       :precision="2"
                                       iconSide="left"
                                       @change="taxRulesChanged()">
                        </InputCurrency>
                        <InputCurrency label="Max Tax Amount"
                                       v-model:saturn="taxLine.max"
                                       :modelValue="taxLine.max"
                                       :precision="2"
                                       iconSide="left"
                                       @change="taxRulesChanged()">
                        </InputCurrency>
                        <InputCurrency label="Min Taxable Amount"
                                       v-model:saturn="taxLine.taxMinBase"
                                       :modelValue="taxLine.taxMinBase"
                                       :precision="2"
                                       iconSide="left"
                                       @change="taxRulesChanged()">
                        </InputCurrency>
                        <InputCurrency label="Max Taxable Amount"
                                       v-model:saturn="taxLine.taxMaxBase"
                                       :modelValue="taxLine.taxMaxBase"
                                       :precision="2"
                                       iconSide="left"
                                       @change="taxRulesChanged()">
                        </InputCurrency>
                    </div>
                </Panel>
                <Panel title="Tax Applies To">
                    <div :style="$grid('1-125px-125px', '5px 10px')">
                        <div>

                        </div>
                        <div class="taxline-tax-header">
                            Amount
                        </div>
                        <div class="taxline-tax-header">
                            Total Tax
                        </div>
                    </div>
                    <div v-for="bucketType in bucketTypes" :key="bucketType">
                        <div class="bucket-type-title" v-show="bucketType != '_Primary'">{{ bucketType }}</div>

                        <div v-for="bucketItem in getItemsByBucketType(bucketType)" :key="bucketItem.name" class="bucket-list">
                            <div class="bucket" :style="$grid('25px-2-125px-125px', '5px 10px')">
                                <div>
                                    <div class="panel-header-collapse-button"
                                         v-show="isItemInThisTaxLine(bucketItem.bucketTaxIndex)"
                                         @click="toggleItem(bucketItem, false)">
                                        <i class="far fa-check-square" />
                                    </div>
                                    <div class="panel-header-collapse-button"
                                         v-show="!isItemInThisTaxLine(bucketItem.bucketTaxIndex)"
                                         @click="toggleItem(bucketItem, true)">
                                        <i class="far far fa-square" />
                                    </div>
                                </div>
                                <div>{{ bucketItem.name }}</div>
                                <div>
                                    <InputCurrency :saturn="bucketItem.amt"
                                                   :precision="2"
                                                   :disabled="true"
                                                   iconSide="left"
                                                   :negative="true">
                                    </InputCurrency>
                                </div>
                                <div>
                                    <InputCurrency :saturn="bucketItem.bucketTaxLineAmount"
                                                   :precision="2"
                                                   :disabled="true"
                                                   iconSide="left"
                                                   :negative="true">
                                    </InputCurrency>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div :style="$grid('1-125px', '5px 10px')" class="taxline-tax-total taxline-tax-total-first">
                        <div>
                            SUBTOTAL
                        </div>
                        <div>
                            <InputCurrency :saturn="taxLine.taxTotalRaw"
                                           :precision="2"
                                           :disabled="true"
                                           iconSide="left"
                                           :negative="true">
                            </InputCurrency>
                        </div>
                    </div>
                    <div :style="$grid('1-125px', '5px 10px')" class="taxline-tax-total">
                        <div>
                            TAX RULE ADJUSTMENT
                        </div>
                        <div>
                            <InputCurrency :saturn="taxLine.taxAdjustment"
                                           :precision="2"
                                           :disabled="true"
                                           iconSide="left"
                                           :negative="true">
                            </InputCurrency>
                        </div>
                    </div>
                    <div :style="$grid('1-125px', '5px 10px')" class="taxline-tax-total" v-if="taxLine.taxReciprocityAdjustment != 0">
                        <div>
                            OUT OF STATE RERCIPROCITY ADJUSTMENT
                        </div>
                        <div>
                            <InputCurrency :saturn="taxLine.taxReciprocityAdjustment"
                                           :precision="2"
                                           :disabled="true"
                                           iconSide="left"
                                           :negative="true">
                            </InputCurrency>
                        </div>
                    </div>

                    <div :style="$grid('1-125px', '5px 10px')" class="taxline-tax-total">
                        <div>
                            TOTAL TAX
                        </div>
                        <div>
                            <InputCurrency :saturn="taxLine.taxTotal"
                                           :precision="2"
                                           :disabled="true"
                                           iconSide="left">
                            </InputCurrency>
                        </div>
                    </div>
                </Panel>
            </fieldset>
        </div>
        <div class="modal-footer modal-footer-split">
            <div>
                <button class="button-error" @click="deleteTaxLine()" v-if="showDeleteButton" :disabled="modal.isModalDisabled">Delete Tax Line</button>
            </div>
            <div>
                <button class="button-unwind ignore-all-locks" @click="cancel()">Cancel</button>
                <button class="button-save" @click="save()" :disabled="v$.$invalid || modal.isModalDisabled">Save</button>
            </div>

        </div>
    </div>
</template>


<script>
    //import api from '@core/services/api'
    import $modal from '@core/services/modal'
    import FIMenuBuyersOrder from '@core/classes/FIMenuBuyersOrder'
    //import PanelLender from '@core/components/PanelLender.vue'
    import InputTextbox from '@core/components/InputTextbox.vue'
    //import InputDropdown from '@core/components/InputDropdown.vue'
    import InputCurrency from '@core/components/InputCurrency.vue'
    //import InputCheckbox from '@core/components/InputCheckbox.vue'
    import InputNumber from '@core/components/InputNumber.vue'
    import Panel from '@core/components/Panel.vue'
    //import RichTable from '@core/components/RichTable.vue'
    //import { required, between, minValue, maxValue } from '@vuelidate/validators'
    //import moment from 'moment'
    import util from '@core/services/util'
    import FIMenuBuyersOrderTaxGroup from '../../../vue-core/classes/FIMenuBuyersOrderTaxGroup'
    import {useVuelidate} from '@vuelidate/core'

    export default {
        name: "modalTaxLine",
        props: ['modal'],
        setup(){ return {v$: useVuelidate({$scope: false})}},
        data() {
            return {
                bo: null,
                bucketTypes: [],
                items: [],
                taxLine: null,
                taxLineIndex: null,
                showDeleteButton: true,
                subtotal: 0,
                subtotalDifference: 0,
            }
        },
        created() {

            this.bo = new FIMenuBuyersOrder(this.modal.bo);

            //IF NEW TAX LINE, CREATE IT
            if (!this.modal.taxLine) {
                this.showDeleteButton = false;
                const newTaxLine = new FIMenuBuyersOrderTaxGroup({
                    category: 'Sales',
                    limitType: 'Truncate',
                    max: null,
                    min: null,
                    name: 'New Tax Line',
                    rate: null,
                    roundToOption: 'NearestPenny',
                    taxMaxBase: null,
                    taxMinBase: null,
                    titleId: null,
                    wholeRoundOption: 'NotRoundedOnWhole',

                    totalTaxedRaw: 0,
                    totalTaxed: 0,
                    taxTotalRaw: 0,
                    taxTotal: 0

                });

                //GETS A UNIQUE titleId
                do {
                    newTaxLine.titleId = util.getRandomString(5).toUpperCase();
                } while (this.bo.taxGroups.some(tg => tg.titleId == newTaxLine.titleId));

                //ADD NEW TAX LINE TO BO
                this.bo.taxGroups.push(newTaxLine);
                this.taxLineIndex = this.bo.taxGroups.findIndex(tg => tg.titleId === newTaxLine.titleId);

            }
            else {
                this.taxLineIndex = this.modal.bo.taxGroups.findIndex(tg => tg.titleId === this.modal.taxLine.titleId);

            }

            this.taxLine = this.bo.taxGroups[this.taxLineIndex];

            this.$watch(() => this.taxLine, () => {
                this.bo.isManuallyChanged = true;
            }, { deep: true });

            //ITEMS
            this.setupItems();

            //GET A UNIQUE LIST OF BUCKET TYPES
            this.bucketTypes = this.bo.buckets.map(bucket => bucket.bucketType)
                .filter((value, index, self) => self.indexOf(value) === index)
                .filter(bt => this.getItemsByBucketType(bt).length > 0);

            this.bucketTypes.unshift('_Primary');

        },
        validations() {
            return {
                taxLine: this.taxLine.validation()
            }
        },
        methods: {
            taxRulesChanged() {
                this.$nextTick(function () {
                    this.bo.calculateFinalTaxesAndFinalFees();
                    this.setupItems();
                });
            },
            setupItems() {
                const items = [];

                //ADD PRIMARY TAX ITEM
                const isSellingPriceTaxed = this.isItemInThisTaxLine(this.bo.primaryTaxIndex);
                items.push({
                    bucketTitleId: null,
                    bucketType: '_Primary',
                    bucketTaxIndex: this.bo.isLeaseMonthlyUse ? 0 : this.bo.primaryTaxIndex,
                    name: 'Selling Price',
                    amt: this.bo.sellingPrice,
                    bucketTaxLineAmount: isSellingPriceTaxed ? (this.bo.sellingPrice * this.taxLine.rate * 0.01) : 0
                });

                //ADD ALL BUCKET ITEMS
                this.bo.buckets.forEach(bucket => {

                    bucket.items.forEach(bucketItem => {

                        let taxIndex = 0;
                        if (this.bo.isLease()) {
                            if (bucket.isFront) {
                                taxIndex = bucket.upfrontTaxIndex;
                            }
                            else {
                                taxIndex = bucket.taxIndex;
                            }
                        }
                        else {
                            taxIndex = bucket.taxIndex;
                        }


                        const itemSign = (bucketItem.sign === -1) ? -1 : 1;
                        const isThisItemTaxed = this.isItemInThisTaxLine(taxIndex);

                        items.push({
                            bucketTitleId: bucket.titleId,
                            bucketType: bucket.bucketType,
                            bucketTaxIndex: taxIndex,
                            name: bucketItem.name,
                            amt: (bucketItem.amount * itemSign),
                            bucketTaxLineAmount: isThisItemTaxed ? ((bucketItem.amount * itemSign) * this.taxLine.rate * 0.01) : 0
                        });

                    });
                });
                this.items = items;

                this.subtotal = this.items.map(i => i.bucketTaxLineAmount).reduce((a, b) => a + b, 0);
                this.subtotalDifference = this.taxLine.taxTotal - this.subtotal;

            },
            getItemsByBucketType(bucketType) {
                const sortedItems = this.items.filter(b => b.bucketType == bucketType);
                sortedItems.sort((a, b) => {
                    if (b.name > a.name)
                        return -1;
                    else if (b.name < a.name)
                        return 1;
                    else
                        return 0;
                });
                return sortedItems;
            },
            isItemInThisTaxLine(bucketTaxIndex) {
                const binaryArr = util.taxIndexToBinaryArray(bucketTaxIndex);
                return binaryArr[this.taxLineIndex] == '1';
            },

            toggleItem(item, isSelected) {
                if (this.modal.isModalDisabled) return;

                if (item.bucketType == '_Primary') {

                    //GET THE NEW TAX INDEX
                    const feeTaxBinaryArray = util.taxIndexToBinaryArray(this.bo.primaryTaxIndex);
                    feeTaxBinaryArray[this.taxLineIndex] = isSelected ? '1' : '0';
                    const newTaxIndex = util.binaryArrayToTaxIndex(feeTaxBinaryArray);

                    this.bo.primaryTaxIndex = newTaxIndex;
                }
                else {

                    //FIND ORIGINAL BUCKET
                    const originalBucket = this.bo.buckets.find(bucket => bucket.titleId == item.bucketTitleId);

                    //GET THE NEW TAX INDEX
                    const feeTaxBinaryArray = util.taxIndexToBinaryArray(originalBucket.taxIndex);
                    feeTaxBinaryArray[this.taxLineIndex] = isSelected ? '1' : '0';
                    const newTaxIndex = util.binaryArrayToTaxIndex(feeTaxBinaryArray);

                    //SET NEW TAX INDEX
                    originalBucket.taxIndex = newTaxIndex;

                }

                this.bo.calculateFinalTaxesAndFinalFees();

                //REFRESH ITEMS
                this.setupItems();
            },
            deleteTaxLine() {
                const result = confirm('Are you sure you want to delete this tax line?');
                if (result) {
                    this.bo.removeTaxLine(this.taxLineIndex);
                    this.save();
                }
            },
            cancel() {
                $modal.cancel();
            },
            save() {
                $modal.close(this.bo);
            }
        },
        components: {
            InputTextbox: InputTextbox,
            //InputDropdown: InputDropdown,
            //RichTable: RichTable,
            Panel: Panel,
            InputNumber: InputNumber,
            InputCurrency: InputCurrency,

            //InputDropdown: InputDropdown,
            //InputCheckbox: InputCheckbox
        }
    };
</script>
<style>
    .ModalAddEditTaxLine .panel.gapped-fields .panel-body {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .bucket-list {
        margin-left: 15px;
    }

        .bucket-list .bucket {
            align-items: center;
        }

    .bucket-type-title {
        height: 25px;
    }

    .taxline-tax-total-first {
        margin-top: 45px;
    }

    .taxline-tax-total {
        align-items: center;
        font-weight: bold;
    }

        .taxline-tax-total input {
            font-weight: bold;
        }

    .taxline-tax-header {
        text-align: right;
        font-weight: bold;
    }
</style>
