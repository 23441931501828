<template>
    <div class="CreditApplicationsStatus-container" v-show="displayDecisions()">
        <span>Credit Apps({{ creditApps.length }}):</span>
        <IconWithCount icon="fas fa-thumbs-up approved-lender-icon" :tooltipInfo="approvedLenders" tooltipdirection="right" maxtooltipCount="3"/>
        <IconWithCount icon="fas fa-exchange-alt conditional-lender-icon" :tooltipInfo="conditionalLenders" tooltipdirection="right" maxtooltipCount="3"/>
        <IconWithCount icon="fas fa-thumbs-down denied-lender-icon" :tooltipInfo="deniedLenders" tooltipdirection="right" maxtooltipCount="3"/>
    </div>
</template>

<script>
    import IconWithCount from '@/components/IconWithCount.vue'
    import {CreditApplicationDecision} from "@core/classes/CreditApplication"

    export default {
        name: "CreditApplicationsStatus",
        props: ['creditApps', 'displayDecisions'],
        data() {
            return {
                approvedLenders: [],
                conditionalLenders: [],
                deniedLenders: []
            }
        },
        created() {
            this.updateLenderLists()
            this.$watch(() => this.creditApps, () => {
                this.updateLenderLists()
            }, { deep: true });
        },
        computed: {

        },
        methods: {
            updateLenderLists() {
                this.approvedLenders = this.creditApps.filter(c => c.status === CreditApplicationDecision.Approved).map(c => c.lender.lenderName)
                this.conditionalLenders = this.creditApps.filter(c => c.status === CreditApplicationDecision.Condition).map(c => c.lender.lenderName)
                this.deniedLenders = this.creditApps.filter(c => c.status === CreditApplicationDecision.Denied).map(c => c.lender.lenderName)
            }
        },
        components: {
            IconWithCount
        }
    };
</script>
<style>
    .CreditApplicationsStatus-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 10px;
    }

        .CreditApplicationsStatus-container .approved-lender-icon {
            color: var(--success-color);
        }

        .CreditApplicationsStatus-container .conditional-lender-icon {
            color: var(--print-color);
        }

        .CreditApplicationsStatus-container .denied-lender-icon {
            color: var(--error-color);
        }
</style>